import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import Button from 'components/Button';
import { Container } from './styles';
import InputPassword from 'components/InputPassword';

const UpdatePassword = ({ open, handleClose, onChange, errors }) => {
  const [password, setPassword] = useState(null);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Container>
          <InputPassword 
            name='password'
            label='Senha'
            onChange={e => setPassword(e.target.value)}
            value={password}
            error={errors["password"]?.message}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onChange(password)}>
          ALTERAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePassword;
