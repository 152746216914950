import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import Typography from 'components/Typography';

import { Container, InfoTextContainer, Content, Row, IncentiveContainer, IncentiveContainerRow, ItemContainer, RadioContainer, RadioItem, ContentChip, RowCapture, ContentInputs } from './styles';
import Input from 'components/Input';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Radio, Chip, Tooltip } from '@material-ui/core';
import DatePicker from 'components/DatePicker';
import formatCurrency, { currencyMask, currencyMaskStore } from 'utils/currency';
import { useUser } from 'hooks/useUser';
import { AddCaptureModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';
import ModalInformation from 'components/ModalInformation';
import Button from 'components/Button';
import { WarningOutlined } from '@material-ui/icons';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { AddButton } from './styles'
import './style.css';
import InfoIcon from '@material-ui/icons/Info';
import { agency_bank, account_bank } from 'utils/maskUtils';
import { Autocomplete } from '@material-ui/lab';
import { v4 } from 'uuid';
import CountrySelect from 'components/SelectAutocomplete';
import updateCaptureContributionMap from 'utils/updateCaptureContributionMap';

const Step5 = ({ id, changedFields, setChangedFields }) => {
  const { control, setValue, getValues, formState: { errors } } = useFormContext();
  const [authEncouragers, setAuthEncouragers] = useState(getValues('auth_sponsors'));
  const [authPartiallyCaptured, setAuthPartiallyCaptured] = useState(getValues('auth_partially_captured'));
  const [authApprovedValue, setAuthApprovedValue] = useState(getValues('auth_approved_value'));
  const [authFundingPeriod, setAuthFundingPeriod] = useState(getValues('auth_funding_period'));
  const [authStartsAt, setAuthStartsAt] = useState(getValues('auth_starts_at'));
  const [authApprovalDate, setAuthApprovalDate] = useState(getValues('auth_approval_date'));
  const [date, setDate] = useState(getValues('funding_period'));
  const [encouragers, setEncouragers] = useState([]);
  const [banks, setBanks] = useState(null);
  const [encourager, setEncourager] = useState('');
  const [partiallyCaptured, setPartiallyCaptured] = useState(false);
  const [mainSupportersOptional, setMainSupportersOptional] = useState('');
  const [mainSupporters, setMainSupporters] = useState('');
  const [mainSupportersArray, setMainSupportersArray] = useState([]);
  const [mainSupportersArrayOptional, setMainSupportersArrayOptional] = useState([]);
  const [statePartiallyOccupied, setStatePartiallyOccupied] = useState();
  const [authMinimumValue, setAuthMinimumValue] = useState(getValues('auth_minimum_value'));
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const [valueAprovedCaptured, setValueAprovedCaptured] = useState(getValues('captured_value') ?? null);

  const [changeAuthApprovedValue, setChangeAuthApprovedValue] = useState(changedFields.approved_value);
  changeAuthApprovedValue && setValue("auth_approved_value", !changeAuthApprovedValue);
  const [changeAuthMinimumValue, setChangeAuthMinimumValue] = useState(changedFields.minimum_value);
  changeAuthMinimumValue && setValue("auth_minimum_value", !changeAuthMinimumValue);
  const [changeAuthApprovalDate, setChangeAuthApprovalDate] = useState(changedFields.approval_date);
  changeAuthApprovalDate && setValue("auth_approval_date", !changeAuthApprovalDate);
  const [changeAuthFundingPeriod, setChangeAuthFundingPeriod] = useState(changedFields.funding_period);
  changeAuthFundingPeriod && setValue("auth_funding_period", !changeAuthFundingPeriod);

  const [formattedValue, setFormattedValue] = useState(0);
  const [formattedMinimunValue, setFormattedMinimumValue] = useState(0);
  const [approvedByLaw, setApprovedByLaw] = useState(0);
  const [valueCaptured, setValueCaptured] = useState(0);
  const valueAproved = formatCurrency(getValues('approved_value'));
  const url = (window.location.href).split('/');
  const minimumValue = formatCurrency(getValues('minimum_value'), false);
  const { user } = useUser();
  const roles = [
    'Em rascunho',
    'Em ajuste',
    'Aguardando regularização'
  ];
  let isDisabled = false;
  const project_status = getValues('status');
  const opening_receipt = getValues('opening_receipt');
  const fund_opening_receipt = getValues('fund_opening_receipt');

  if ((user.adminRole === 'cs_proponent1' || user.adminRole === 'cs_proponent2' || user.adminRole === 'cs_proponent_relationship') && !roles.includes(project_status)) {
    isDisabled = true;
  } else if ((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
    isDisabled = true;
  } else if (user.adminRole === 'general' || user.adminRole === 'monitore') {
    isDisabled = true;
  }

  const handleAddMainSupportersOptional = useCallback(() => {
    if (mainSupportersOptional) {
      setMainSupportersArrayOptional([
        ...mainSupportersArrayOptional,
        mainSupportersOptional,
      ]);
      setMainSupportersOptional('');
    }
  }, [mainSupportersArrayOptional, mainSupportersOptional]);

  const handleAddMainSupporters = useCallback(() => {
    setMainSupportersArray([...mainSupportersArray, mainSupporters]);
    setMainSupporters('');
  }, [mainSupportersArray, mainSupporters]);

  const status = getValues('status');

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);
  const bank_id = getValues('bank_id');

  const isDisabledFunc = () => {
    if(!user.isAdmin){
      return isDisabledByStatus;
    }

    else {
      if(user.adminRole !== 'general'){
        return false;
      }

      else{
        return true;
      }
    }
  }

  const handleChangePartiallyOccupied = useCallback(
    value => {
      setStatePartiallyOccupied(value);
      setValue('partially_captured', value);
    },
    [setValue],
  );

  const maskDate = v => {
    if (!v) {
      return '';
    }
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{2})(\d)/, '$1/$2');
    v = v.replace(/(\d{2})(\d)/, '$1/$2');

    v = v.replace(/(\d{2})(\d{1,2,3,4})$/, '$1/$2');
    v = v.replace(/(\d{4})\d+?$/, '$1');

    return v;
  };

  const handleAddEncourager = useCallback(e => {
    if (e.code === 'Enter') {
      setEncouragers(oldValue => {
        const newValue = [...oldValue, encourager];
        setValue('encouragers', newValue.join(', '));
        setEncourager('');
        return newValue;
      });
    }
  }, [setValue, encourager]);

  useEffect(() => {
    if (getValues('funding_period')) {
      setDate(getValues('funding_period'));
    }

    if (getValues('encouragers')) {
      const encouragersList = getValues('encouragers').split(', ');
      setEncouragers(encouragersList);
    }

    if (getValues('partially_captured')) {
      setPartiallyCaptured(getValues('partially_captured'));
    }

  }, [getValues]);

  const mounted_sponsors = useRef();
  const sponsors = getValues('sponsors');
  let exploded_sponsors = sponsors ? sponsors.split(',') : [];

  const mounted_previous_sponsors = useRef();
  const previous_sponsors = getValues('previous_sponsors');
  let exploded_previous_sponsors = previous_sponsors ? previous_sponsors.split(',') : [];

  useEffect(() => {
    if (!mounted_previous_sponsors.current) {
      setMainSupportersArrayOptional(exploded_previous_sponsors);
      mounted_previous_sponsors.current = true;
    } else {
      setValue('previous_sponsors', mainSupportersArrayOptional.join(','));
    }

  }, [setValue, mainSupportersArrayOptional]);

  useEffect(() => {
    if (!mounted_sponsors.current) {
      setMainSupportersArray(exploded_sponsors);
      mounted_sponsors.current = true;
    } else {
      setValue('sponsors', mainSupportersArray.join(','));
    }
  }, [setValue, mainSupportersArray]);

  const handleDeleteMainSupportersArrayOptional = chipToDelete => {
    setMainSupportersArrayOptional(chips =>
      chips.filter(chip => chip !== chipToDelete),
    );
  };

  const handleDeleteMainSupportersArray = chipToDelete => {
    setMainSupportersArray(chips =>
      chips.filter(chip => chip !== chipToDelete),
    );
  };

  useEffect(async() => {
    const { data } = await ProjectService.listBanks();

    setBanks(data.map(bank => ({
      label: bank.name,
      value: bank.id,
      code: bank.code
    })));

    let approved_value = getValues('approved_value') ?? null;
    if (approved_value) {
      approved_value = approved_value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      setFormattedValue(approved_value);
    }

    let minimum_value = getValues('minimum_value') ?? null;
    if (minimum_value) {
      minimum_value = parseFloat(minimum_value);
      minimum_value = minimum_value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
      setFormattedMinimumValue(minimum_value);
    }
  }, []);

  const updateCapturedValueProject = useCallback(async (payload) => {
    const contributions = payload.contribuitions;
    let dataValidate = null;
    let isIncentiveCapture = true;

    if(payload.incentiveType == 1){
      dataValidate = {captured_value: payload.captured_value, date: payload.date, sponsor_id: null}
      isIncentiveCapture = false;
    }
    else{
      dataValidate = payload;
    }

    console.log('PAY carrega', payload);

    try {
      const response = await ProjectService.updateCapturedValueProject(id, dataValidate);
      const projectContribId = response.data?.capture_id;
      isIncentiveCapture && await updateCaptureContributionMap(contributions, projectContribId);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setShowModalInformation(false);
        setModalOpen('');
        toast.success('Captação atualizada com sucesso!');

        const updatedValue = Number(payload.captured_value) + getValues('captured_value');
        setValueAprovedCaptured(updatedValue)
        setValue('captured_value', updatedValue)
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar a captação do projeto');
      }
    }
  }, [modalInformationData]);

  const modalRender = useMemo(() => {
    const modal = {
      addCapture: (
        <AddCaptureModal
          open={modalOpen === 'addCapture'}
          handleClose={() => setModalOpen('')}
          onChange={(captured_value, sponsor_id, date, contribuitions, incentiveType, isSendEmail) => {
            updateCapturedValueProject({ captured_value, sponsor_id, date, contribuitions, incentiveType, isSendEmail })
          }}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);
  const theme = useTheme();

  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Valor do projeto e incentivos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Insira as informações referentes aos valores do projeto.
        </Typography>
      </InfoTextContainer>

      <Content>
        <Row>
          <AdminFormGroup
            checked={changeAuthApprovedValue ? false : authApprovedValue}
            onChange={value => {
              setAuthApprovedValue(value);
              setValue("auth_approved_value", value);
              setChangeAuthApprovedValue(!changeAuthApprovedValue);
              value && setChangedFields({ ...changedFields, })
            }}
          >
            <Controller
              name="approved_value"
              control={control}
              render={({ field }) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'red' }}>
                  {
                    (changedFields.approved_value && changeAuthApprovedValue) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{ color: 'red', width: '1.35rem' }} />
                      </Tooltip>
                    )
                  }
                  {/* *Obrigatório */}
                  <Input
                    label="Valor aprovado em lei"
                    fullWidth
                    {...field}
                    onChange={e => {
                      setValue('approved_value', currencyMaskStore(e.target.value))
                      setFormattedValue(currencyMask(e.target.value))
                    }}
                    value={url[3] === 'projetos-registro' ? `R$ ${formattedValue}` : (formattedValue !== 0 ? `R$ ${formattedValue}` : valueAproved)}
                    disabled={isDisabled}
                  />
                </div>
              )}
            />
          </AdminFormGroup>


          <AdminFormGroup
            checked={changeAuthMinimumValue ? false : authMinimumValue}
            onChange={value => {
              setAuthMinimumValue(value);
              setValue("auth_minimum_value", value);
              setChangeAuthMinimumValue(!changeAuthMinimumValue);
              value && setChangedFields({
                ...changedFields,
                approved_value: changeAuthApprovedValue,
                minimum_value: false,
                approval_date: changeAuthApprovalDate,
                funding_period: changeAuthFundingPeriod
              });
            }}
          >
            <Controller
              name="minimum_value"
              control={control}
              render={({ field }) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'red' }}>
                  {
                    (changedFields.minimum_value && changeAuthMinimumValue) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{ color: 'red', width: '1.35rem' }} />
                      </Tooltip>
                    )
                  }
                  {/* *Obrigatório */}
                  <Input
                    label="Valor mínimo de execução"
                    fullWidth
                    {...field}
                    onChange={e => {
                      setValue('minimum_value', currencyMaskStore(e.target.value))
                      setFormattedMinimumValue(currencyMask(e.target.value))
                    }}
                    value={url[3] === 'projetos-registro' ? `${formattedMinimunValue}` : (formattedMinimunValue !== 0 ? `${formattedMinimunValue}` : minimumValue)}
                    disabled={isDisabled}
                    data-tip="indique o valor mínimo de execução do projeto <br/>conforme a lei de aprovação prevê."
                    data-html={true}
                    data-for="tooltip1"
                    data-event='click focus'
                  />
                </div>
              )}
            />
            <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid" />
          </AdminFormGroup>


          <AdminFormGroup
            checked={changeAuthApprovalDate ? false : authApprovalDate}
            onChange={value => {
              setAuthApprovalDate(value);
              setValue("auth_approval_date", value);
              setChangeAuthApprovalDate(!changeAuthApprovalDate);
              value && setChangedFields({
                ...changedFields,
                approved_value: changeAuthApprovedValue,
                minimum_value: changeAuthMinimumValue,
                approval_date: false,
                funding_period: changeAuthFundingPeriod
              });
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'red' }}>
              {
                (changedFields.approval_date && changeAuthApprovalDate) && (
                  <Tooltip title="Campo alterado pelo proponente" placement="top">
                    <WarningOutlined style={{ color: 'red', width: '1.35rem' }} />
                  </Tooltip>
                )
              }
              {/* *Obrigatório */}
              <DatePicker
                label="Data de aprovação"
                onChange={value => {
                  // const dateValue = value.toISOString().substring(0, 10);
                  const dateValue = value.toLocaleDateString("en-CA");

                  setValue('approval_date', dateValue);
                }}
                defaultValue={getValues('approval_date')}
                disabled={isDisabled}
              />
            </div>
          </AdminFormGroup>


          <AdminFormGroup
            checked={changeAuthFundingPeriod ? false : authFundingPeriod}
            onChange={value => {
              setAuthFundingPeriod(value);
              setValue("auth_funding_period", value);
              setChangeAuthFundingPeriod(!changeAuthFundingPeriod);
              value && setChangedFields({
                ...changedFields,
                approved_value: changeAuthApprovedValue,
                minimum_value: changeAuthMinimumValue,
                approval_date: changeAuthApprovalDate,
                funding_period: false
              });
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'red' }}>
              {
                (changedFields.funding_period && changeAuthFundingPeriod) && (
                  <Tooltip title="Campo alterado pelo proponente" placement="top">
                    <WarningOutlined style={{ color: 'red', width: '1.35rem' }} />
                  </Tooltip>
                )
              }
              {/* *Obrigatório */}
              <DatePicker
                label="Prazo de captação"
                onChange={value => {
                  // const dateValue = value.toISOString().substring(0, 10);
                  const dateValue = value.toLocaleDateString("en-CA");

                  setValue('funding_period', dateValue);
                }}
                defaultValue={getValues('funding_period')}
                disabled={isDisabled}
              />
              {/* {getValues('funding_period_has_passed') && (
                <>
                  <InfoIcon data-for="tooltip2" data-tip="O prazo de captação expirou" multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'relative'}} />
                  <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
                </>
              )} */}
            </div>
          </AdminFormGroup>
        </Row>
        {/* <AdminFormGroup
            checked={authEncouragers}
            onChange={value => {
              setAuthEncouragers(value);
              setValue("auth_sponsors", value);
            }}
          > */}
        <div style={{ display: 'flex', marginTop: 27 }}>
          <Input
            width="100%"
            label="Principais incentivadores das edições anteriores"
            placeholder="Indicar o nome dos principais incentivadores das edições anteriores do projeto"
            id="previous_sponsors"
            value={mainSupportersOptional}
            onChange={e => setMainSupportersOptional(e.target.value)}
            disabled={isDisabled}
          />
          {/* <AddButton onClick={handleAddMainSupportersOptional} disabled={isDisabledByStatus}>+</AddButton> */}
        </div>
        <ContentChip>
          {mainSupportersArrayOptional.map(item => (
            <Chip
              variant="outlined"
              label={item}
              onDelete={() => handleDeleteMainSupportersArrayOptional(item)}
              disabled={isDisabled}
            />
          ))}
        </ContentChip>
        {/* </AdminFormGroup> */}
      </Content>
      <Content>
        {/* <AdminFormGroup
          checked={authPartiallyCaptured}
          onChange={value => {
            setAuthPartiallyCaptured(value);
            setValue("auth_partially_captured", value);
          }}
        >
          <ItemContainer>
            <Typography variant="body2">Projeto parcialmente captado?</Typography>
            <RadioContainer>
              <RadioItem>
                <Radio
                  checked={getValues('partially_captured') === 1}
                  onClick={() => handleChangePartiallyOccupied(1)}
                  name="partially_captured"
                />
                <Typography variant="body2">Sim</Typography>
              </RadioItem>
              <RadioItem>
                <Radio
                  checked={getValues('partially_captured') === 0}
                  onClick={() => handleChangePartiallyOccupied(0)}
                  name="partially_captured"
                />
                <Typography variant="body2">Não</Typography>
              </RadioItem>
            </RadioContainer>
          </ItemContainer> */}
        {/* {getValues('partially_captured') === 1 && ( */}
        <>
          <RowCapture>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'red' }}>
              {
                changedFields.captured_value && (
                  <Tooltip title="Campo alterado pelo proponente" placement="top">
                    <WarningOutlined style={{ color: 'red', width: '1.35rem' }} />
                  </Tooltip>
                )
              }
              <Input
                label="Valor captado até o momento"
                onChange={e => {
                  setValue('captured_value', currencyMaskStore(e.target.value))
                  setValueCaptured(currencyMask(e.target.value))
                }}
                value={formatCurrency(valueAprovedCaptured)}
                disabled={true}
                data-tip="indique o valor total captado pelo seu projeto até <br/>
                  o momento do cadastro. Essa informação é importante para
                  sabermos o saldo de captação disponível."
                data-html={true}
                data-for="tooltip1"
                data-event='click focus'
              />
              <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid" />

            </div>
            {/* <Button type="button" onClick={() => setModalOpen('addCapture')} disabled={isDisabled}>Adicionar captação</Button> */}
          </RowCapture>

          <div style={{ display: 'flex', marginTop: 27 }}>
            <Input
              label="Principais incentivadores"
              placeholder="Indicar o nome dos principais incentivadores"
              width="610px"
              id="sponsors"
              value={mainSupporters}
              onChange={e => setMainSupporters(e.target.value)}
              onKeyPress={handleAddEncourager}
              disabled={isDisabled}
            />
            {/* <AddButton onClick={handleAddMainSupporters} disabled={isDisabledByStatus}>+</AddButton> */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'red' }}>
            {
              changedFields.sponsors && (
                <Tooltip title="Campo alterado pelo proponente" placement="top">
                  <WarningOutlined style={{ color: 'red', width: '1.35rem' }} />
                </Tooltip>
              )
            }
            <ContentChip>
              {mainSupportersArray.map(item => (
                <Chip
                  variant="outlined"
                  label={item}
                  onDelete={() => handleDeleteMainSupportersArray(item)}
                  disabled={isDisabled}
                />
              ))}
            </ContentChip>
          </div>
          {modalRender}
          <ModalInformation
            open={showModalInformation}
            handleClose={() => setShowModalInformation(false)}
            {...modalInformationData}
          />
        </>
        {/* )} */}
        {/* </AdminFormGroup> */}

      </Content>

      {(opening_receipt !== null || fund_opening_receipt !== null) &&
        <>
          <div>
            <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
              Dados para depósito
            </Typography>
            <Typography variant="subtitle2" color="grayLight1">
              Insira as informações referentes aos dados bancários para depósito.
            </Typography>
          </div>

          <ContentInputs>
            <div style={{ width: '30%' }}>
              {banks && (
                <Autocomplete
                  id="bank-select-demo"
                  options={banks}
                  renderOption={(option) => (
                    <li key={v4()}>{option.code} - {option.label}</li>
                  )}
                  getOptionLabel={(option) => option.label}
                  disabled={isDisabledFunc()}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      height={'38px'}
                      label="Escolha um banco"
                      inputProps={{ 
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      disabled={isDisabledFunc()}
                    />
                  )}
                  defaultValue={banks?.find(bank => bank.value == bank_id)}
                  onChange={(event, newValue) => {
                    setValue('bank_id', newValue.value);
                  }}
                />
              )}

            </div>

            <div style={{ width: '30%' }}>
              <Controller
                name="agency"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Agência"
                      min={0}
                      {...field}
                      onChange={e => {
                        const value = agency_bank(e.target.value);
                        setValue('agency', value);
                      }}

                      disabled={isDisabledFunc()}
                      error={errors["agency"]?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ width: '30%' }}>
              <Controller
                name="account_bank"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Conta corrente"
                      min={0}
                      disabled={isDisabledFunc()}
                      {...field}
                      onChange={e => {
                        const value = account_bank(e.target.value);
                        setValue('account_bank', value);
                      }}                  
                    />
                  );
                }}
              />
            </div>
          </ContentInputs>
        </>
      }
    </Container>
  );
};

export default Step5;
