/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './materialStyles';
import { Container } from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CountrySelect({
  label,
  // value,
  options,
  // multipleOptions,
  // darkBackground,
  onChange,
  // width,
  // error,
  // color,
  // noValueText = 'Sem dados',
  // rounded = false,
  // ...rest
  disableCloseOnSelect = true,
  multiline = true,
}) {
  const classes = useStyles();

  return (
    <Container>
      <Autocomplete
        id="country-select-demo"
        options={options}
        multiple={multiline}
        // renderTags={() => null}
        classes={classes.option}
        disableCloseOnSelect={disableCloseOnSelect}
        displayEmpty={true}
        onChange={onChange}
        getOptionLabel={(option) => option}
        renderOption={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              type: 'search',
              autoComplete: 'off', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Container>
  );
}