import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 712px;
  flex-direction: row;
  height: max-content;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  gap: 1.5rem;
  padding: 1.5rem;

  @media (max-width: 1150px){
    gap: 0;
  }
`;
