import React, { memo } from 'react';
import Typography from 'components/Typography';
import { formatDate } from 'utils/date';
import { Container, Text } from './styles';
import { LawsService } from 'services/laws';
import { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useCallback } from 'react';
import { ProjectService } from 'services/projects';
import DeleteProject from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals/DeleteProject';
import ModalInformation from 'components/ModalInformation';
import { useHistory } from 'react-router';


const ProjectsTableDetail = (props) => {
  console.log('props', props)
  const { push } = useHistory();
  const [law, setLaw] = useState('');
  const [laws, setLaws] = useState('');
  const [open, setOpen] = useState(false);
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Erro ao excluir o projeto!',
    description: 'Ocorreu um erro durante a exclusão do projeto!',
  });

  const loadLaw = useCallback(async () => {
    try {
      const response = await LawsService.list();
      setLaws(response);

      setLaw(response.data.filter(law => law.id === props?.lawId)[0]?.description);
    } catch (err) {
      console.log(err.response);
    }
  }, [props]);

  useEffect(() => {
    if (props?.lawId) {
      loadLaw();
    }
  }, [props?.lawId, loadLaw]);

  const handleDeleteProject = async (id) =>{ 
    try {
     await ProjectService.deleteProject(id);
     setOpen(false);
     props.handleDeleteProject(id);
    } catch (err) {
      console.log(err.response);
      setShowModalInformation(true)
    }
  }

  const goToCaptureReport = useCallback(() => {
    const project_id = props.id;
    push(`/capture-report/${project_id}`);
  }, [push]);

  // const formatLaw = useMemo(() => {
  //   return laws.find(law => law.id === props?.lawId)?.description || 'Lei não encontrada';
  // }, [props]);

  return (
    <>
      <Container style={{ marginBottom: '1rem' }}>
        <Text onClick={() => goToCaptureReport()}>Relatório de captação</Text>
      </Container>
      <Container>
        <div>
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 20 }}>Resumo</Typography>
          <Typography variant="manrope" color="brown">
            {(props?.summary !== "null" && props?.summary !== "undefined") ? props?.summary : "Não informado"}
          </Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <div>
            <div>
              <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>Última atualização</Typography>
              <Typography variant="manrope" color="brown" style={{ marginBottom: 17 }}>{formatDate(props?.updatedAt, 'dd/MM/yyyy') || '-'}</Typography>
            </div>
            <div>
              <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>Lei de incentivo</Typography>
              <Typography variant="manrope" color="brown">{law || 'Lei não encontrada'}</Typography>
            </div>
          </div>
          {["Em rascunho", "Em análise"].includes(props.status) && (
            <div>
              <a style={{cursor: 'pointer'}} onClick={() => {setOpen(true)} }>
                <Typography variant="body2" color="grayDark2">Excluir Projeto</Typography>
              </a>
              <DeleteProject 
                open={open} 
                handleClose={() => {setOpen(false)}} 
                onClick={()=>{handleDeleteProject(props?.id)}} 
              />
            </div>
          )}
          <ModalInformation
              open={showModalInformation}
              onClick={()=>{setShowModalInformation(false)}}
              handleClose={() => {setShowModalInformation(false)}} 
              {...modalInformationData}
            />
        </div>
      </Container>
    </>
  );
};

export default memo(ProjectsTableDetail);