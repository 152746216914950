import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import Button from 'components/Button';
import Select from 'components/Select';
import { Container } from './styles';

const ChangeStatus = ({ open, handleClose, onChange }) => {
  const [seletcedStatus, setSelectedStatus] = useState('');
  const status = useMemo(() => {
    return [
      'Em rascunho',
      'Em análise',
      'Em ajuste',
      'Aguardando regularização',
      'Em captação',
      'Incentivado',
      'Não captado',
      'Projeto reprovado',
    ];
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Container>
          <Select 
            label="Status"
            options={status}
            onChange={e => setSelectedStatus(status[e.target.value])}
            role="admin"
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChange(seletcedStatus)} color="primary">
          ALTERAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeStatus;
