import React, { useState, useCallback, useEffect } from 'react';
import Input from 'components/Input';
import { IconFilter, IconSearch } from 'assets/icons';

import {
  Container,
  Content,
  Options,
  ButtonFilter,
  ButtonSearch,
  ContainerInputs,
  Label,
  FilterContent,
  ButtonContent,
} from './styles';
import SelectMultiple from 'components/SelectMultiple';
import { ProjectService } from 'services/projects';
import { LocationsService } from 'services/locations';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { UsersService } from 'services/users';
import Button from 'components/Button';
import Typography from 'components/Typography';
import ReactSelectMain from 'components/ReactSelectMain';
import { useFormContext } from 'react-hook-form';

const AdmProjectsTableFilter = ({ showFilter, search, onSearch, isLoading, setFilters, filters }) => {
  const [laws, setLaws] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedLaws, setSelectedLaws] = useState([]);
  const [cities, setCities] = useState([]);
  const [locationsUf, setLocationsUf] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedKA, setSelectedKA] = useState(null);
  const [selectedOnlyMonitore, setSelectedOnlyMonitore] = useState(null);
  const [users, setUsers] = useState([]);

  const loadFormInfo = useCallback(async () => {
    const [response, locationsData] = await Promise.all([
      ProjectService.getFormInfo(),
      LocationsService.list(),
    ]);
    let defaultArr = { name: 'Todos', description: 'Todos', state_code: 'Todos' };
    setLaws([defaultArr , ...response.data.laws]);
    const locationsArr = response.data.locations_uf.map(item => item.state_code).sort();
    setLocationsUf(['Todos' , ...locationsArr]);
    setCities([defaultArr , ...locationsData.data.data]);
    // setDefaultCities(locationsData.data.data);
  }, []);

  const optionsStatus = [
    'Todos',
    'Em rascunho',
    'Em análise',
    'Em ajuste',
    'Aguardando análise dos ajustes',
    'Aguardando regularização',
    'Em captação',
    'Incentivado',
    'Não captado',
    'Projeto reprovado',
];

  useEffect(() => {
    loadFormInfo();
    async function fetchUsers() {
      const { data } = await UsersService.listKeyAccounts();

      setUsers(data.map((user, idx) => ({
        label: user.name,
        value: Object.assign({}, user)
      })));
    }

    fetchUsers();
  }, [loadFormInfo]);

  const handleAddFilter = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [setFilters, filters],
  );

  useEffect(() => {
    handleAddFilter('law', selectedLaws)
  }, [selectedLaws]);

  useEffect(() => {
    handleAddFilter('status', selectedStatus)
  }, [selectedStatus]);

  useEffect(() => {
    handleAddFilter('state', selectedStates)
  }, [selectedStates]);

  useEffect(() => {
    handleAddFilter('city', selectedCities)
  }, [selectedCities]);

  useEffect(() => {
    handleAddFilter('key_account', selectedKA)
  }, [selectedKA]);

  useEffect(() => {
    handleAddFilter('only_monitore', selectedOnlyMonitore)
  }, [selectedOnlyMonitore]);

  return (
    <Container>
      <Content>
        <Input
          label="Buscar por projeto"
          onChange={e => search(e.target.value)}
          disabled={isLoading}
        />
        <Options>
          <ButtonFilter onClick={showFilter}>
            <IconFilter />
          </ButtonFilter>
          <ButtonSearch onClick={() => onSearch()}>
            <IconSearch />
          </ButtonSearch>
        </Options>
      </Content>
      <FilterContent>
        <ContainerInputs>
          <Label>Status</Label>
          <SelectMultiple
            className="select-multiple"
            value={selectedStatus}
            role="admin"
            options={optionsStatus}
            onChange={e => { setSelectedStatus(e) }}
          />
        </ContainerInputs>
        <ContainerInputs>
          <Label>Lei</Label>
          <SelectMultiple
            className="select-multiple"
            value={selectedLaws}
            laws={laws}
            role="admin"
            options={laws.map(law => law.description)}
            onChange={e => { setSelectedLaws(e) }}
          />
        </ContainerInputs>
        <ContainerInputs>
          <Label>Estado</Label>
          <SelectAutocomplete
            className="select-subcategory-name"
            placeholder="UF"
            options={locationsUf}
            onChange={(event, value) => setSelectedStates(value)}
          />
        </ContainerInputs>
        <ContainerInputs>
          <Label>Cidade</Label>
          <SelectAutocomplete
            className="select-subcategory-name"
            options={cities.map(item => item.name + ' - ' + item.state_code)}
            onChange={(event, value) => setSelectedCities(value)}
          />
        </ContainerInputs>

        <ContainerInputs>
          <Label>Key Account</Label>

          <ReactSelectMain
            className="select-multiple"
            placeholder=""
            options={[{label: 'Sem Key account', value: {id: 99999}}, ...users]}
            isClearable 
            onChange={selected => {
              const id = selected === null ? '' : selected['value']['id'];
              //setValue("key_account_user_id", id)
              setSelectedKA(id)
            }}
          />
        </ContainerInputs>

        <ContainerInputs>
          <Label>Projeto Exclusivo Monitore?</Label>
          <SelectAutocomplete
            className="select-multiple"
            placeholder=""
            options={['Sim', 'Não']}
            multiline={false}
            disableCloseOnSelect={false}
            onChange={(event, value)=> {
              setSelectedOnlyMonitore(value)
            }}
          />
        </ContainerInputs>

      </FilterContent>
      <ButtonContent>
        <Button 
          variant="secondary" 
          onClick={() => {
            handleAddFilter('analysis_date', true)
          }}>
          Filtrar fila de análise
        </Button>
      </ButtonContent>
    </Container>
  );
};

export default AdmProjectsTableFilter;
