import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .body-title{
    font-size: 12px;
    margin-bottom: 6px;
  }

  input, p:not(.subtitle) {
    p:not([color="blue"]){
      color: ${({ theme }) => theme.colors.brown};
    }
    font-size: 12px;
  }
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.5rem;
  gap: 1rem;
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3.75rem;
  row-gap: 1.25rem;
`;

export const RowImageAndVideos = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 49% 49%;

  @media (max-width: 1550px){
    grid-template-columns: 100%;
  }
`;

export const IncentiveContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IncentiveContainerRow = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShowFiles = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;

  .multiple-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: flex-start;
  }
  
  margin-top: 5px;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;