import { CepService } from "services/cep";

/* eslint-disable no-plusplus */
export const securePassword = (password) => {
  const regex = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return regex.text(password);
}

export const validateEmail = (email) => {
  const re = /[\w'+-]+(\.[\w'+-]+)*@\w+([-.]\w+)*\.\w{2,24}/;
  return re.test(String(email).toLowerCase());
};

export const checkShare = (validationCase, value) => {
  let validation = false;
  switch (validationCase) {
    case 'copy':
      if (value === '') {
        // toast.error('Erro ao copiar url!');
        validation = false;
        return false;
      }
      // toast.success('Link copiado!');
      validation = true;
      return true;
    default:
      break;
  }
  return validation;
};

export const validateCpf = (cpf) => {
  let Soma;
  let Resto;
  Soma = 0;
  if (
    !cpf ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    Soma += Number(cpf.substring(i - 1, i)) * (11 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== Number(cpf.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma += Number(cpf.substring(i - 1, i)) * (12 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== Number(cpf.substring(10, 11))) return false;
  return true;
};

export const validateBirthDate = (birthDate) => {
  try {
    // eslint-disable-next-line camelcase
    const data_array = `${birthDate.substring(2, 4)}/${birthDate.substring(
      0,
      2,
    )}/${birthDate.substring(4, 8)}`; // quebra a data em array
    // comparo as datas e calculo a idade
    const hoje = new Date();
    const bDate = new Date(data_array);
    let idade = hoje.getFullYear() - bDate.getFullYear();
    const m = hoje.getMonth() - bDate.getMonth();
    if (m < 0 || (m === 0 && hoje.getDate() < bDate.getDate())) idade--;

    if (idade < 18) {
      // console.log('Pessoas menores de 18 não podem se cadastrar.');
      return false;
    }

    if (idade >= 18 && idade <= 60) {
      // console.log('Maior de 18, pode se cadastrar.');
      return true;
    }

    // se for maior que 60 não vai acontecer nada!
    return false;
  } catch (e) {
    return false;
  }
};

export const checkAvailability = (availability) => {
  if (availability.length > 0) {
    return true;
  }
  return false;
};

export const validateVideoUrl = videoUrl => {
  const urlValids = ['youtube.com/watch?v=', 'vimeo.com/'];
  return urlValids.some(urlValid => ( videoUrl.includes(urlValid) ));
}

export const validateCnpj = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return true;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
           
    return true;
}

export const validatePhone = (phone) => {
	const regex = /^(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
	return regex.test(phone);
}

export const validateCep = (cep) => {
  return CepService.validate(cep);
}

export const validateFileExtension = (files, availableFileExtensions) => {
  console.log('files', files);
  console.log('availableFileExtensions', availableFileExtensions);
  if(!files)
  return false;
  
  if(typeof files === "string" || Array.from(files).every(x => typeof x === "string"))
    return true;

  if("type" in files)
    return availableFileExtensions.includes(files.type);

  return Array.from(files).every(file => availableFileExtensions.includes(file.type));
}

export const validateFileSize = (files, maxSize) => {
  const oneMb = 1048576;

  if(!files)
    return false;

  if(typeof files === "string" || Array.from(files).every(x => typeof x === "string"))
    return true;

  if("size" in files)
    return files.size < (maxSize * oneMb);

  return Array.from(files).every(file => file.size < (maxSize * oneMb));
}