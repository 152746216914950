import api from './api';

const PATH = '/user';

const listUsers = () => api.get(PATH);
const listUserNames = () => api.get(`${PATH}/list-user-names`);
const listAdminUsers = () => api.get(`${PATH}/admin`);
const listKeyAccounts = () => api.get(`${PATH}/list-key-accounts`);
const listProponentsByKa = id => api.get(`${PATH}/list-proponents-by-ka/${id}`);
const createUser = data => api.post(PATH, data);
const listUsersRoles = () => api.get(`${PATH}-roles`);
const getUserById = id => api.get(`${PATH}/${id}`);
const updateUser = data => api.post(`${PATH}/update/${data.id}`, data);
const getUserLoggedId = () => api.get(`${PATH}id`);
const getUsersLogged = () => api.get(`sponsorsid`);
const getUsersProjects = id => api.get(`get-sponsored-projects/users/${id}`);
const getProponentProjects = id =>
  api.get(`get-proponent-projects/users/${id}`);
const deleteUser = id => api.delete(`${PATH}s/${id}`);
const uploadUserImage = (id, data) =>
  api.post(`upload-user-image/${id}`, data);
const removeUserImage = id => api.post(`remove-user-image/${id}`);
const sendEmailToNewsletter = data => api.post(`send-email-to-newsletter`, data);
const confirmEmailAndCreate = token => api.get(`${PATH}/confirm-email-and-create/${token}`);
const transferProponentsToKa = data => api.post(`${PATH}/transfer-proponents-to-ka`, data);

export const UsersService = {
  listUsers,
  listAdminUsers,
  createUser,
  listUsersRoles,
  getUserById,
  updateUser,
  getUserLoggedId,
  getUsersLogged,
  getUsersProjects,
  getProponentProjects,
  deleteUser,
  uploadUserImage,
  removeUserImage,
  listUserNames,
  sendEmailToNewsletter,
  confirmEmailAndCreate,
  listKeyAccounts,
  listProponentsByKa,
  transferProponentsToKa,
};
