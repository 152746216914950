import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding-top: 1rem;
  height: 100%;
`;

export const Body = styled.div`
  height: calc(100% - 1rem);
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
