import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import { Container } from './styles';
import { currencyMask, currencyMaskStore } from 'utils/currency';

const AddCaptureProponent = ({ open, handleClose, onChange }) => {
  const [incentivValue, setIncentivValue] = useState(0);
  const [formattedValue, setFormattedValue] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Container>
          <Input 
            label="Valor do incentivo"
            onChange={e => {
              setFormattedValue(currencyMask(e.target.value))
              setIncentivValue(currencyMaskStore(e.target.value))
            }}
            value={`R$ ${formattedValue}`}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChange(incentivValue)} color="primary">
          ADICIONAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCaptureProponent;
