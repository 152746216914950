import React, { useState, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import { Box, ShowFiles, LabelInput } from './styles'
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { CloudUpload, RefreshOutlined } from '@material-ui/icons';
import Filename from 'components/Filename';
import './styles.css';

const DragAndDrop = ({ 
      onChange,
      files = [],
      disabled = false,
      isUploading = false,
      error,
      label = null,
      link = null,
      required=false,
      margin=0,
      isCnd
    }) => {
  const [fileSelected, setFileSelected] = useState(
    files.length > 0 ? files.join(', ') : '',
  );

  const handleDrop = useCallback(
    files => {
      if (files.length > 0) {
        const fileList = [...files];

        setFileSelected(fileList.map(file => file.name).join(', '));
        if (onChange) {
          onChange(files);
        }
      }
    },
    [onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files => handleDrop(files),
    noClick: true,
    noDrag: true,
  })

  return (
    
    <div className="App">
      {isCnd && 
        <>
          {!isUploading &&
            <CloudUpload error={!!error}
            {...getRootProps({
              onClick: event => true && event.stopPropagation()
            })}
            />
          }
          
          {isUploading && <RefreshOutlined className='rotate'/>}
        </>
      }

      {!isCnd && 
        <>
          {!!required &&
            <InputLabel 
              style={{color: 'red', 
                      marginLeft: margin+'%', 
                      backgroundColor: '#fff',
                      position: 'absolute',
                      marginTop: '-6px',
                      zIndex: 9,
                      fontSize: '12px',
                    }} 
              id="select-label">Obrigatório</InputLabel>
          }

          <Box error={!!error}
            {...getRootProps({
              onClick: event => true && event.stopPropagation()
            })}
          >
            <input {...getInputProps()} />
            <LabelInput fileSelected={fileSelected} error={error}>
              {fileSelected && !error ? 'Arquivo selecionado' : 'Arraste um arquivo ou clique para selecioná-lo'}
            </LabelInput>
          </Box>
          <div>
              {isUploading == true && <LinearProgress />}
              {!!error && (
                <FormHelperText style={{ paddingLeft: 16 }} error>
                  {error}
                </FormHelperText>
              )}
          </div>
          {link && link[0] !== null &&
            <ShowFiles>
              <Filename
                label={label}
                link={link}
                disabled={true}
              />
            </ShowFiles>
          }
          </>
      }
    </div>
  )
}
export default DragAndDrop;
