import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import Table from 'components/Table';
import Typography from 'components/Typography';
import defaultProjectAvatar from 'assets/images/default-project.png';
import formatCurrency from 'utils/currency';
import AdmProjectsTableDetail from './AdmProjectsTableDetails';

import { ProjectItem, ProgressBar, FundraisingItem, Link } from './styles';
import { formatDate } from 'utils/date';
import { WarningOutlined } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';

const AdmProjectsTable = ({
  projects,
  identification,
  onRefresh,
  isLoading,
  filterComponent: FilterComponent,
  isPaginated,
  currentPage,
  onChangePage,
  totalPages,
  laws,
}) => {
  const { push } = useHistory();

  const calculateProgress = useCallback((maxValue, currentValue) => {
    if ((maxValue === 0 && currentValue !== maxValue) || (currentValue == 0 && maxValue == 0)) {
      return 0;
    }
    if (currentValue === maxValue) {
      return 100;
    }
    const progress = Number((Number(currentValue) * 100) / maxValue).toFixed(2);
    return Number(progress);
  }, []);

  const formatDateColumn = useCallback((startsAt, fundingPeriod) => {
    if (startsAt === '' || fundingPeriod === '' || startsAt == null || fundingPeriod == null) {
      return 'Data não informada';
    }
    var newDate = new Date(new Date(fundingPeriod).setDate(new Date(fundingPeriod).getDate() + 1));
    var newDate2 = new Date(new Date(startsAt).setDate(new Date(startsAt).getDate() + 1));

    return `${formatDate(newDate2, 'dd/MM/yyyy')} a ${formatDate(
      newDate,
      'dd/MM/yyyy',
    )}`;
  }, []);

  const formatFundingPeriod = useCallback((fundingPeriod) => {
    if (fundingPeriod === '' || fundingPeriod == null) {
      return 'Data não informada';
    }
    var newDate = new Date(new Date(fundingPeriod).setDate(new Date(fundingPeriod).getDate() + 1));

    return formatDate(newDate, 'dd/MM/yyyy');
  }, []);

  const columns = useMemo(() => {
    return [
      {
        identification: 'name',
        name: '',
        showTitle: false,
        renderColumn: ({ image, name, category }) => {
          const cover = image ? image : defaultProjectAvatar;

          return (
            <ProjectItem>
              <img src={cover} alt="Proponent" />
              <div>
                <Typography variant="manrope" color="brown">
                  {name}
                </Typography>
                <Typography variant="caption">{category || ''}</Typography>
              </div>
            </ProjectItem>
          );
        },
      },
      {
        identification: 'id',
        name: 'status',
        renderColumn: ({ capturedValue, approvedValue, startsAt, fundingPeriod, status }) => {
          const timeElapsed = Date.now() - 86400000;
          const today = new Date(timeElapsed);
        
          const prazoCaptação = (new Date(fundingPeriod)).getTime();
          const isPrazoCaptação = formatFundingPeriod(fundingPeriod);
          const hoje = today.getTime();

          return (
            <FundraisingItem>
              <div>
                <Typography variant="manrope" color="grayDark2">
                  Fim da Captação
                </Typography>
                <Typography variant="caption">
                  {formatFundingPeriod(fundingPeriod)}
                  {isPrazoCaptação !== 'Data não informada' &&
                      prazoCaptação < hoje &&
                        (status === "Em captação" || status === "Aguardando regularização") &&
                      <div>
                        <WarningOutlined 
                          style={{fontSize: '1.2rem', color: 'red'}}
                          data-tip="O prazo de captação expirou." 
                          data-html={true}
                          data-for="tooltip1"
                        />
                        

                          <ReactTooltip 
                            id="tooltip1" 
                            border={true} 
                            borderColor='red' 
                            backgroundColor="#fff" 
                            textColor='red' 
                            place="top" 
                            type="info" 
                            effect="solid"
                          />
                      </div>
                  }
                </Typography>
              </div>
              <ProgressBar
                progress={calculateProgress(
                  Number(approvedValue),
                  Number(capturedValue),
                )}
              />
              <div>
                <Typography variant="caption">
                  {calculateProgress(
                    Number(approvedValue),
                    Number(capturedValue),
                  )}
                  %
                </Typography>
                <Typography variant="caption">
                  Falta captar {formatCurrency(Number(approvedValue) - Number(capturedValue), true, true)}
                </Typography>
              </div>
            </FundraisingItem>
          );
        },
      },
      {
        identification: 'text',
        name: '',
        renderColumn: ({proponentName, proponentEmail, proponentPhoto, proponentId}) => {
          return (
            <ProjectItem>
              <Link href={'proponentes/'+proponentId} rel="noreferrer" target="_blank">
                <img src={proponentPhoto ?? defaultProjectAvatar} alt="Proponent" />
              </Link>
              <div>
                <Typography variant="manrope" color="grayDark2" style={{ marginBottom: 4 }}>
                  Proponente
                </Typography>
                <Typography variant="manrope" color="brown" style={{ marginBottom: 6 }}>
                  {proponentName}
                </Typography>
                <Typography variant="caption">{proponentEmail}</Typography>
              </div>
            </ProjectItem>
          );
        },
      },
      {
        identification: 'id',
        name: 'Área do projeto',
        showTitle: true,
        isDetail: true,
        action: ({ id }) => window.open(`/admin/projetos/${id}`, "_blank"),
      },
    ];
  }, [calculateProgress, push, formatDateColumn]);

  return (
    <Table
    identification={identification || 'id'}
    columns={columns}
    data={projects}
    tableCollapsed
    filterComponent={FilterComponent}
    collapsedComponent={AdmProjectsTableDetail}
    onRefresh={onRefresh}
    loading={isLoading}
    isPaginated={isPaginated}
    collapsedComponentProps={{ laws }}
    currentPage={currentPage}
    onChangePage={onChangePage}
    totalPages={totalPages}
    />
  );
};

export default AdmProjectsTable;
