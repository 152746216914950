import React from 'react';
import noDataAdmin from 'assets/svgs/no-data-admin.svg';
import noDataProponent from 'assets/svgs/no-data-proponent.svg';
import { Container } from './styles';
import Typography from 'components/Typography';
import { useUser } from 'hooks/useUser';

const EmptyTable = () => {
  const { user } = useUser();
  return (
    <Container>
      <img src={user.isAdmin ? noDataAdmin : noDataProponent} alt="Sem dados" />
      <Typography variant="h5">Nenhum registro encontrado</Typography>
    </Container>
  );
};

export default EmptyTable;
