import React, { useMemo, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Table from 'components/Table';
import Typography from 'components/Typography';
import { OpenInNew, MarkUnRead, MarkRead } from 'assets/icons';
import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import formatCurrency from 'utils/currency';
// import NotificationTableDetails from './NotificationTableDetails';

import {
  ProjectItem,
  ProgressBar,
  FundraisingItem,
  Link,
  Row,
  Center,
  SelectContainer,
} from './styles';
import { NotificationService } from 'services/notifications';
import { useEffect } from 'react';
import NotificationProjectTableDetails from './NotificationsProjectTableDetail';
import Select from 'components/Select';

const NotificationsProjectTable = ({ identification }) => {
  const { push } = useHistory();

  // const calculateProgress = useCallback((maxValue, currentValue) => {
  //   const progress = Number((Number(currentValue) * 100) / maxValue).toFixed(2);
  //   return Number(progress);
  // }, []);

  const { id } = useParams();
  const [notifications, setNotifications] = useState([]);

  const loadNotifications = useCallback(async () => {
    try {
      const response = await NotificationService.listByProject(id);
      setNotifications(response.data);
      console.log(response);
    } catch (err) {
      console.log(err.message);
    }
  }, [id]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const columns = useMemo(() => {
    return [
      {
        identification: 'createdAt',
        name: 'Data',
        showTitle: true,
        renderColumn: ({ createdAt }) => {
          return (
            <>
              <Typography variant="manrope" color="grayDark1">
                Data
              </Typography>
              <Typography variant="manrope" color="brown">
                {new Date(createdAt).toLocaleDateString()}
              </Typography>
            </>
          );
        },
      },
      // {
      //   identification: 'nameProponent',
      //   name: 'Nome do proponente',
      //   showTitle: true,
      //   renderColumn: ({ name, image, category }) => {
      //     <div>
      //       <img src={image} alt="Projeto" />
      //       <div>
      //         <Typography variant="manrope" color="brown">
      //           {name}
      //         </Typography>
      //         <Typography variant="caption">
      //           {category || ''}
      //         </Typography>
      //       </div>
      //     </div>
      //   }
      // },
      {
        identification: 'title',
        name: 'Assunto',
        showTitle: true,
      },
      {
        identification: 'archive',
        name: 'Arquivo',
        showTitle: true,
        renderColumn: ({ annex }) => {
          return (
            <div>
              <Typography variant="body2" color="grayDark2">
                Arquivo
              </Typography>
              {annex 
              ? <Link href={`${annex}`} rel="noreferrer" target="_blank">
                  <OpenInNew />
                  <Typography variant="manrope" color="blue">
                    Documento
                  </Typography>
                </Link>
              : <Typography variant="manrope" color="brown">
                  {'Não informado'}
                </Typography>
              }
              
            </div>
          );
        },
      },
      {
        identification: 'wasRead',
        renderColumn: ({ wasRead, id }) => {
          return wasRead ? <MarkUnRead /> : <MarkRead />;
        },
      },
    ];
  }, [push]);

  return notifications.erro ? (
    <Center>
      <Typography color="grayDark2">{notifications.erro}</Typography>
    </Center>
  ) : (
    <div style={{ padding: '30px' }}>
      <SelectContainer>
      <Row>
          <Input
            name="name"
            label="Nome do projeto"
            width="450px"
          />
        </Row>
        <div style={{ maxWidth: '300px' }}>
          <Select
            label="Status"
            options={['Em captação', 'Em análise']}
            // value={'Selecione uma opção'}
            width="300px"
            role="proponente"
            // onChange={e => setValue('status', status[e.target.value])}
          />
        </div>
        <div style={{ maxWidth: '300px' }}>
          <DatePicker
          label="Data"
          // onChange={value => {
          //   const dateValue = value.toISOString().substring(0, 10);
          //   handleAddFilter('date', dateValue);
          // }}
        />
        </div>
      </SelectContainer>
      <Table
        identification={identification || 'id'}
        columns={columns}
        data={notifications}
        tableCollapsed
        collapsedComponent={NotificationProjectTableDetails}
        isPaginated={false}
      />
    </div>
  );
};

export default NotificationsProjectTable;
