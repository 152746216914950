import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0 1.5rem;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 99px;

  display: grid;
  grid-template-columns: 1fr 0.25fr 0.25fr;
  align-items: center;
  gap: 1rem;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 0.5fr 0.5fr;
  }
`;
