import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import green from '@material-ui/core/colors/green';

import { Container, CheckContainer, ChildrenContainer } from './styles';
import { useUser } from 'hooks/useUser';

const useStyles = makeStyles({
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
});

const AdminFormGroup = ({ children, checked, onChange }) => {
  const classes = useStyles();
  const { user } = useUser();
  const roles = [
    'cs_proponent1',
    'cs_proponent2',
    'support',
    'general',
    'cs_proponent_relationship',
    'monitore',
  ];
  return (
    <Container>
      <CheckContainer>
        <Checkbox
          checked={checked === true}
          onChange={e => onChange(true)}
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
          disabled={roles.includes(user.adminRole)}
        />
        <Checkbox
          checked={checked !== undefined && checked === false}
          onChange={e => onChange(false)}
          disabled={roles.includes(user.adminRole)}
        />
      </CheckContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

export default AdminFormGroup;
