import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  /* height: auto; */

  border-radius: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  padding: 100px 101px 100px 101px;

  @media (max-width: 600px){
    width: 90%;
    padding: 10% 18%;
  }

  @media (max-width: 450px){
    padding: 6%;
  }
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  margin-bottom: 44px;
`;

export const Title = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: ${({ theme }) => theme.colors.darkestGray};

  @media (max-width: 450px){
    font-size: 25px;
    line-height: 30px;
  }
`;

export const SocialButtons = styled.div`
  width: 100%;

  flex-direction: column;
  display: flex;

  margin-top: 32px;
  margin-bottom: 39px;

  .btn-linkedin {
    margin-bottom: 8px;
  }
`;

export const SocialText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 24px; */
  text-align: center;

  margin-left: 4px;

  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.blueAqua};
`;

export const ForgotPassword = styled.div`
  width: 100%;

  margin-top: 31px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    cursor: pointer;
    font-family: 'Cabin', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;

    color: #949191;

    text-decoration: none;
  }
`;

export const KeepConnected = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-top: 16px;

  p {
    font-family: 'Roboto' sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;

    margin-left: 10px;
  }
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 12px;
  height: 12px;

  margin: 0;
  padding: 0;

  &:not(:checked) {
    outline: 2px solid ${({ theme }) => theme.colors.blackGrayish60};
    -webkit-appearance: none;
  }

  border-radius: 3px;
`;

export const CheckBoxBorder = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.blackGrayish60};
  border-radius: 4px;
  height: 12;
  display: flex;
  align-items: center;
`;

export const Register = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 32px;

  gap: 30px;

  p:first-child {
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 12px;

    color: #b9b8b8;
  }

  a {
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 12px;

    text-decoration: none;

    color: ${({ theme }) => theme.colors.blueAqua};
  }
`;

export const Label = styled.p`
  font-family: 'Cabin', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 5px;
`;
