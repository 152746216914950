import styled from 'styled-components';

export const Container = styled.div`
  width: ${({ width }) => width ?? '16px'};
  height: ${({ height }) => height ?? '16px'};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ rounded }) => (rounded ? '9px' : '5px')};

  border: 2.5px solid
    ${({ theme, rounded }) =>
    rounded ? theme.colors.grayLight2 : theme.colors.blackGrayish60};

  &:hover {
    border-color: ${({ theme, color }) => theme.colors[color]};
  }
`;

export const Rounded = styled.div`
  height: 10px;
  width: 10px;
  background: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.primary)};

  border-radius: 6px;
`;

export const Square = styled.div`
  height: 10px;
  width: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme, color }) => color ? theme.colors[color] : theme.colors.primary};

  border-radius: 4px;
`;
