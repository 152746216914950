import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Button from 'components/Button';
import SideBar from './SideBar';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

import {
  Container,
  FooterBack,
  ContentBack,
  Form,
  FooterActions,
} from './styles';
import { useCallback } from 'react';
import { ProjectService } from 'services/projects';
import { dateFormattedToDateISO, formatDateFix } from 'utils/date';
import { toast } from 'react-toastify';
import PageTitle from 'components/PageTitle';
import { createProjectValidationResolver } from 'validations/createProject';
import { useUser } from 'hooks/useUser';
import { ProponentService } from 'services/proponent';
import { formatNull } from 'utils/formatNull';
import ProjectCreatedIncompletedModal from 'components/ProjectCreatedIncompletedModal';
import { ChangeProponentModal, CloseCaptureProponentModal, RequestAdjustModal, UpdateProjectRegistration } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { NotificationService } from 'services/notifications';
import { orderFeedbackErrorMessage } from 'utils/orderToast';
import UpdateStatus from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals/UpdateProjectRegistration';
import { validateFileExtension, validateFileSize } from 'utils/validation';

const validateProjectSchema = yup.object().shape({
  edition: yup.string().nullable().required("Edição do projeto obrigatório"),
  law_id: yup.string().nullable().required("Lei de incentivo fiscal obrigatório"),
  runtime_days: yup.string().nullable().required("Duração do projeto obrigatório"),
  more_than_one_city: yup.number().nullable().required("Selecione uma opção"),
  locations: yup.array().test("locations", "Adicione pelo menos uma cidade", value => value.length > 0),

  goal: yup.string().nullable().required("Objetivo geral obrigatório"),
  specific_goal: yup.string().nullable().required("Objetivo específico obrigatório"),

  sd_goal_ids: yup.array().nullable().test("sd_goal_ids", "Indique pelo menos um ODS", value => value?.length > 0),
  impact_category_id: yup.string().nullable().required("Categoria de impacto obrigatória"),
  impact_subcategory_id: yup.string().nullable().required("Subcategoria de impacto obrigatória"),
  target: yup.string().nullable().required("Público-alvo direto obrigatório"),
  audience_goal: yup.number().moreThan(0, "Meta do público-alvo direto obrigatório"),

  approved_value: yup.number().moreThan(0, "Valor aprovado obrigatório").test("funding_period", "Valor aprovado obrigatório", value => !!value),
  minimum_value: yup.number().moreThan(0, "Valor mínimo de execução obrigatório").test("minimum_value", "Valor aprovado obrigatório", value => !!value),
  approval_date: yup.string().nullable().required("Campo obrigatório"),
  funding_period: yup.string().nullable().required("Campo obrigatório"),

  image: yup.mixed()
    .test("image", "Capa do projeto obrigatória", files => !!files)
    .test("image", "Este formato não é compatível. Por favor, insira um arquivo com formato jpg ou png.", files => validateFileExtension(files, ["image/png", "image/jpeg"]))
    .test("image", "O tamanho do arquivo não é compatível. Por favor, insira uma imagem com até 2MB.", files => validateFileSize(files, 2)),
  images: yup.mixed()
    .nullable()
    .notRequired()
    .when("images", {
      is: value => value?.length,
      then: rule => rule
        .test("images", "Este formato não é compatível. Por favor, insira um arquivo com formato jpg ou png.", files => validateFileExtension(files[0], ["image/png", "image/jpeg"]))
        .test("images", "O tamanho do arquivo não é compatível. Por favor, insira uma imagem com até 2MB.", files => validateFileSize(files[0], 2))
    }),
  project_presentation: yup.mixed().nullable().notRequired(),
  official_journal: yup.array().nullable()
    .test("official_journal", "Obrigatório enviar publicação diário oficial", files => files?.length > 0)
    .test("official_journal", "Este formato não é compatível. Por favor, insira um arquivo com formato pdf, jpg ou png.", files => validateFileExtension(files, ["application/pdf", "image/png", "image/jpeg"]))
    .test("official_journal", "O tamanho do arquivo não é compatível. Por favor, insira uma imagem com até 1MB.", files => validateFileSize(files, 1)),
  
  account_opening_commitment: yup.boolean(),

  opening_receipt: yup.mixed().nullable()
    .when("account_opening_commitment", {
      is: account_opening_commitment => !account_opening_commitment,
      then: rule => rule
      .test("opening_receipt", "Obrigatório enviar o comprovante de abertura de conta do projeto", files => !!files)
      .test("opening_receipt", "Este formato não é compatível. Por favor, insira um arquivo com formato PDF", files => validateFileExtension(files, ["application/pdf"]))
      .test("opening_receipt", "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 2MB.", files => validateFileSize(files, 2)),
  
  }),

  fund_opening_receipt: yup.mixed().nullable()
  .when("account_opening_commitment", {
    is: account_opening_commitment => !account_opening_commitment,
    then: rule => rule
    .test("fund_opening_receipt", "Obrigatório enviar o comprovante de dados bancários do fundo", files => !!files)
    .test("fund_opening_receipt", "Este formato não é compatível. Por favor, insira um arquivo com formato PDF", files => validateFileExtension(files, ["application/pdf"]))
    .test("fund_opening_receipt", "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 2MB.", files => validateFileSize(files, 2)),

  }),
    
  work_plan: yup.mixed().nullable()
    .test("work_plan", "Obrigatório enviar o plano de Trabalho ou projeto aprovado na íntegra", files => !!files)
    .test("work_plan", "Este formato não é compatível. Por favor, insira um arquivo com formato PDF", files => validateFileExtension(files, ["application/pdf"]))
    .test("work_plan", "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 5MB.", files => validateFileSize(files, 5))
}, ["images", "images"]);

const RegisterProject = ({ project, id, loadProjectInfo, data, saveProject }) => {
  const { user } = useUser();
  const [proponent, setProponent] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm({
    resolver: createProjectValidationResolver,
    reValidateMode: "onChange"
  });
  const { getValues, setValue } = useForm();
  const [laws, setLaws] = useState([]);
  const [impactCategories, setImpactCategories] = useState([]);
  const [impactSubCategories, setImpactSubCategories] = useState([]);
  const [locationsUf, setLocationsUf] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalIncomplete, setShowModalIncomplete] = useState(false);
  const [odsSelecteds, setOdsSelecteds] = useState([]);
  const [createChip, setCreateChip] = useState([]);
  const [inputs, setInputs] = useState({ specificGoals: { '0': '' } });
  const [isFinishProject, setIsFinishProject] = useState(false);
  const [showCounterpartsInputs, setShowCounterpartsInputs] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledCapture, setIsDisabledCapture] = useState(false);
  const [isInAnalysis, setIsInAnalysis] = useState(false);
  const [showChangeProponentModal, setShowChangeProponentModal] = useState(false);
  const [showRequestAdjustModal, setShowRequestAdjustModal] = useState(false);
  const [stepErrors, setStepErrors] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  let stored_id = useRef();
  const { push } = useHistory();

  useEffect(() => {
    if (getValues('status')) {
      setCurrentStatus(getValues('status'));
    }
  })

  /* const modalRender = useMemo(() => {
    const modal = {
      addCapture: (
        <UpdateProjectRegistration
          open={modalOpen === 'updateProjectRegistration'}
          handleClose={() => setModalOpen('')}
          // onChange={(captured_value, sponsor_id, date) => {
          //   updateCapturedValueProject({ captured_value, sponsor_id, date })
          // }}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);
   */

  const modalRender = useMemo(() => {
    const modal = {
      CloseCaptureProponent: (
        <CloseCaptureProponentModal
          open={modalOpen2 === 'CloseCaptureProponent'}
          handleClose={() => setModalOpen2('')}
          project={project}
        />
      ),
    };
    return modal[modalOpen2];
  }, [modalOpen2, project]);

  const loadProponentData = useCallback(async () => {
    try {
      const response = await ProponentService.getOneProponent(
        user?.proponentId,
      );

      setProponent(response?.data[0]);
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const updateProponent = useCallback(async (payload) => {
    const project_id = stored_id.current;

    try {
      const response = await ProjectService.updateProjectProponent(project_id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Proponente alterado com sucesso!');
        setShowChangeProponentModal(false);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao alterar o proponente do projeto');
      }
    }
  }, []);

  const sendNotification = useCallback(async (data) => {
    const sender = `Remetente: ${user.name} - ${user.email}`

    try {
      const payload = {
        sender_id: user.proponentId,
        proponent_id: user.proponentId,
        title: 'Solicitação de ajuste',
        body: data.description,
        project_id: id,
        receiver_role: 'vitrine-manager',
        sender: sender,
      };
      await NotificationService.create(payload);
      
      await ProponentService.getCnds(user?.proponentId);
      await ProjectService.updateStateProject(id, { status: 'Em ajuste' });
      toast.success('Notificação enviada com sucesso!');
    } catch (err) {
      console.log('err', err)
      toast.error('Falha ao enviar notificação');
    }
    setShowRequestAdjustModal(false);
  }, []);

  const checkStatusDefined = async (project) => {
    if (typeof project !== "undefined") {
      return project?.status === "Em captação" || project?.status === "Em análise"
    } else {
      return false
    }
  }

  const updateStateProject = useCallback(async (data) => {

    try {
      await ProjectService.updateStateProject(id, ((project?.status == 'Em captação') ? { status: 'Em ajuste' } : { status: 'Em rascunho' }));
      toast.success('Status atualizado com sucesso');
      setTimeout(function () { window.location.reload(1); }, 3000);
    } catch (err) {
      console.log('err', err)
      toast.error('Falha ao atualizar o status do projeto');
    }
  }, []);

  const loadFormInfo = useCallback(async () => {
    const response = await ProjectService.getFormInfo();
    setLaws(response.data.laws);
    setImpactCategories(response.data.impact_categories);
    setImpactSubCategories(response.data.impact_subcategories);
    setLocationsUf(response.data.locations_uf);
  }, []);

  const handleNext = useCallback(() => {
    if (activeStep < 6) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }, [activeStep]);

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleClose = useCallback(() => {
    setShowModal(false);
    push('/projetos');
  }, [push]);

  const handleSave = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleCloseIncomplete = useCallback(() => {
    setShowModalIncomplete(false);
  }, []);

  const handleProjectInfo = useCallback(() => {
    // console.log('project', project);
    setValue('status', project.status);

    methods.setValue('id', Number(project.id));
    methods.setValue('status', project.status);
    methods.setValue('code', project.code);
    methods.setValue('approved_value', Number(project.approvedValue));
    methods.setValue('work_plan', project.work_plan);
    methods.setValue('capture_certificate', project.capture_certificate);
    methods.setValue('official_journal', project.official_journal);
    methods.setValue('edition', project.edition);
    methods.setValue('audience_goal', Number(project.audienceGoal));
    methods.setValue('captured_value', Number(project.capturedValue));
    methods.setValue('counterpart', project.counterpart);
    methods.setValue('sponsors', project.sponsors);
    methods.setValue('previous_sponsors', project.previous_sponsors);
    methods.setValue('starts_at', project.startsAt);
    methods.setValue('funding_period', project.fundingPeriod);
    methods.setValue('funding_period_has_passed', project.fundingPeriodHasPassed);
    methods.setValue('approval_date', project.approvalDate);
    methods.setValue('goal', project.goal);
    methods.setValue('law_id', project.law);
    methods.setValue('impact_category_id', project.impact_category_id);
    methods.setValue('impact_subcategory_id', project.impact_subcategory_id);
    methods.setValue('subcategory', project.subcategory);
    methods.setValue('minimum_value', Number(project.minimum_value));
    methods.setValue('name', project.name);
    methods.setValue('more_than_one_city', project.more_than_one_city);
    methods.setValue('can_change_city', project.can_change_city);
    methods.setValue('runtime_days', project.runtimeDays);
    methods.setValue('partially_captured', project.partially_captured);
    methods.setValue('specific_goal', project.specificGoals);
    methods.setValue('summary', project.description);
    methods.setValue('target', project.target);
    methods.setValue('video', project.video);
    methods.setValue('sd_goal_ids', project.sd_goals.map(x => x.id));
    methods.setValue('images', project.images.map(x => x.imagen));
    methods.setValue('account_opening_commitment', project.account_opening_commitment);
    methods.setValue('image', project.image);
    methods.setValue('quota', project.quota);
    methods.setValue('bank_id', project.bank_id);
    methods.setValue('agency', project.agency);
    methods.setValue('account_bank', project.account_bank);
    methods.setValue('other_informations', project.other_informations);
    methods.setValue('highlights_and_awards', project.highlights_and_awards);
    methods.setValue('company_names', project.company_names);
    methods.setValue('project_presentation', project.project_presentation);
    methods.setValue('opening_receipt', project.opening_receipt);
    methods.setValue('fund_opening_receipt', project.fund_opening_receipt);
    methods.setValue('retention_percentage', project.retention_percentage);
    setOdsSelecteds(project.sd_goals.map(item => Number(item.id) - 1));
    methods.setValue(
      'city',
      project?.location?.map(location => location.city),
    );
    methods.setValue(
      'ufs',
      project?.location?.map(location => location.uf),
    );
  }, [methods, project]);

  const onSubmit = useCallback(
    async data => {
      const copyData = Object.assign({}, data);

      const actualStatus = getValues('status');
      // if(actualStatus === 'Em análise'){
      //   return ;
      // }
      if (actualStatus) {
        if (proponent?.registerStatus === 'Em andamento' && actualStatus === 'Em análise') {
          setShowModalIncomplete(true)
          setValue('status', currentStatus)
          return;
        }
      }

      setIsUploading(true);
      const form = new FormData();

      //  0
      form.append('name', (data.name !== undefined) ? data.name : '');
      form.append('edition', formatNull(data.edition));
      form.append('code', formatNull(data.code));
      form.append('law_id', formatNull(data.law_id));
      form.append('retention_percentage', formatNull(data.retention_percentage));
      form.append('impact_category_id', formatNull(data.impact_category_id));
      form.append('impact_subcategory_id', formatNull(data.impact_subcategory_id));
      form.append('target', formatNull(data.target));
      form.append('audience_goal', formatNull(Number(data.audience_goal)));
      form.append('runtime_days', formatNull(data.runtime_days));
      //  1
      form.append('summary', data.summary);
      form.append('other_informations', data.other_informations);
      form.append('goal', formatNull(data.goal));
      form.append(
        'specific_goal',
        Object.values(inputs.specificGoals).join('||@||'),
      );
      //  2
      if (data.sd_goal_ids) {
        data.sd_goal_ids.forEach(sd_goal_id => {
          form.append('sd_goal_ids[]', sd_goal_id);
        });
      } else {
        form.append('sd_goal_ids[]', []);
      }
      form.append('more_than_one_city', formatNull(data.more_than_one_city));
      form.append('can_change_city', formatNull(data.can_change_city));

      //  3
      if (data.approved_value !== 0) {
        form.append('approved_value', Number(data.approved_value).toFixed(2));
      }
      form.append('captured_value', Number(data.captured_value).toFixed(2));
      form.append('starts_at', dateFormattedToDateISO(data.starts_at));
      form.append('funding_period', formatDateFix(data.funding_period));
      form.append('approval_date', formatDateFix(data.approval_date));
      form.append('bank_id', formatNull(data.bank_id));
      form.append('agency', formatNull(data.agency));
      form.append('account_bank', formatNull(data.account_bank));
      form.append('sponsors', data.sponsors);
      form.append('previous_sponsors', data.previous_sponsors);
      form.append('partially_captured', data.partially_captured);
      if (data.locations) {
        data.locations.forEach(locations => {
          form.append('locations[]', locations);
        });
      } else {
        form.append('locations[]', []);
      }

      if (typeof data.official_journal == 'object') {
        let official = (data.official_journal).filter(n => n);
        console.log('111 official', official)
        let uploadNewJournal = official.filter(({ name: a }) => !uploadedFiles?.some(({ name: b }) => a === b))

        console.log('111 uploadNewJournal', uploadNewJournal)
        //  4
        var official_journal = data.official_journal;

        if (uploadNewJournal && uploadNewJournal[0] !== undefined && typeof uploadNewJournal[0] !== 'string' && uploadNewJournal[0] !== undefined && uploadNewJournal[0] !== null) {
          form.append(
            'official_journal',
            uploadNewJournal[0],
            uploadNewJournal[0].name,
          );
        }

        if (uploadNewJournal && uploadNewJournal[1] !== undefined && typeof uploadNewJournal[1] !== 'string' && uploadNewJournal[1] !== undefined && uploadNewJournal[1] !== null) {
          form.append(
            'official_journal2',
            uploadNewJournal[1],
            uploadNewJournal[1].name,
          );
        }
        if (uploadNewJournal && uploadNewJournal[2] !== undefined && typeof uploadNewJournal[2] !== 'string' && uploadNewJournal[2] !== undefined && uploadNewJournal[2] !== null) {
          form.append(
            'official_journal3',
            uploadNewJournal[2],
            uploadNewJournal[2].name,
          );
        }
      }

      if (data.capture_certificate !== null && typeof data.capture_certificate !== 'string' && data.capture_certificate !== undefined) {
        form.append(
          'capture_certificate',
          data.capture_certificate[0],
          data.capture_certificate[0].name,
        );
      }
      if (data.work_plan !== null && typeof data.work_plan !== 'string' && data.work_plan !== undefined) {
        form.append('work_plan', data.work_plan[0], data.work_plan[0].name);
      }
      if (data.image !== null && typeof data.image !== 'string' && data.image !== undefined) {
        form.append(
          'image',
          data.image[0],
          data.image[0].name,
        );
      }
      if (data.delete_images) {
        data.delete_images.forEach(image_name => {
          form.append('delete_images[]', image_name);
        });
      }
      if (data.images && data.images[0] !== undefined && typeof data.images[0] !== 'string' && data.images[0] !== undefined) {
        form.append(
          'image1',
          data.images[0][0],
          data.images[0][0].name,
        );
      }
      if (data.images && data.images[1] !== undefined && typeof data.images[1] !== 'string' && data.images[1] !== undefined) {
        form.append(
          'image2',
          data.images[1][0],
          data.images[1][0].name,
        );
      }
      if (data.images && data.images[2] !== undefined && typeof data.images[2] !== 'string' && data.images[2] !== undefined) {
        form.append(
          'image3',
          data.images[2][0],
          data.images[2][0].name,
        );
      }
      if (data.images && data.images[3] !== undefined && typeof data.images[3] !== 'string' && data.images[3] !== undefined) {
        form.append(
          'image4',
          data.images[3][0],
          data.images[3][0].name,
        );
      }
      form.append('video', data.video);

      //  5
      // if (data.counterparts) {
      //   methods.setValue('quota', data.counterparts);
      //   data.counterparts.forEach(counterpart => {
      //     const array = {
      //       id: counterpart['id'] >= 999999 ? null : counterpart['id'],
      //       quota: counterpart['quota'],
      //       valueOf: counterpart['valueOf'],
      //       valueUpTo: counterpart['valueUpTo'],
      //       counterparts: counterpart['counterparts'],
      //     }
      //     form.append('counterparts[]', JSON.stringify(array));
      //   });
      if (data.quota) {
        methods.setValue('quota', data.quota);
        data.quota.forEach(quota => {
          const array = {
            id: quota['id'] >= 999999 ? null : quota['id'],
            quota: quota['quota'],
            valueOf: quota['valueOf'],
            valueUpTo: quota['valueUpTo'],
            counterparts: quota['counterparts'],
          }
          form.append('counterparts[]', JSON.stringify(array));
        });
      } else {
        form.append('counterparts[]', []);
      }

      form.append('account_opening_commitment', formatNull(data.account_opening_commitment));
      if (data.minimum_value) {
        form.append('minimum_value', Number(data.minimum_value).toFixed(2));
      }
      form.append('highlights_and_awards', data.highlights_and_awards);
      form.append('company_names', data.company_names);
      if (data.project_presentation !== null && typeof data.project_presentation !== 'string' && data.project_presentation !== undefined) {
        form.append('project_presentation', data.project_presentation[0], data.project_presentation[0].name);
      }

      if (data.opening_receipt !== null && typeof data.opening_receipt !== 'string' && data.opening_receipt !== undefined) {
        form.append('opening_receipt', data.opening_receipt[0], data.opening_receipt[0].name);
      }

      if (data.fund_opening_receipt !== null && typeof data.fund_opening_receipt !== 'string' && data.fund_opening_receipt !== undefined) {
        form.append('fund_opening_receipt', data.fund_opening_receipt[0], data.fund_opening_receipt[0].name);
      }
      if (data.delete_opening_receipt) {
        form.append('delete_opening_receipt', data.delete_opening_receipt);
      }
      if (data.delete_fund_opening_receipt) {
        form.append('delete_fund_opening_receipt', data.delete_fund_opening_receipt);
      }
      if (data.delete_official_journal) {
        data.delete_official_journal.forEach(doc => {
          form.append('delete_official_journal[]', doc);
        });
      }
      if (data.delete_capture_certificate) {
        form.append('delete_capture_certificate', data.delete_capture_certificate);
      }
      if (data.delete_work_plan) {
        form.append('delete_work_plan', data.delete_work_plan);
      }
      if (data.delete_project_presentation) {
        form.append('delete_project_presentation', data.delete_project_presentation);
      }

      const status = getValues('status');
      if (status) {
        form.append('status', status);
      }

      const formData = new FormData();
      for (var data of form.entries()) {
        if (data[1] !== 'undefined' & data[1] !== 'NaN' && data[1] !== '') {
          formData.append(data[0], data[1])
        }
      }

      console.log({ copyData });

      try {
        if (stored_id.current) {
          const response = await ProjectService.updateProject(stored_id.current, formData);
          if (response.status === 201) {
            toast.success('Projeto salvo com sucesso!');
            saveProject(response.data);

            if(response.data[0].status === 'Em análise'){
              setTimeout(() => {
                window.location.assign('/projetos');
              }, 1000);
            }
          } else {
            try {
              await validateProjectSchema.validate(copyData, { abortEarly: false });
            } catch (err) {
              const errors = {};

              err.inner.forEach((x) => {
                if (x.path !== undefined) {
                  if (!(x.path in errors)) {
                    errors[x.path] = { message: x.errors[0] }
                    methods.setError(x.path, { message: x.errors[0] });
                  }
                }
              });

              setShowCounterpartsInputs(false);
              setIsSaving(false);
              setIsInAnalysis(false);

              toast.error("Falha ao salvar projeto.");

              return;
            }

            setValue('status', currentStatus)
            orderFeedbackErrorMessage(response.data);
          }
        } else {
          setIsSaving(true);
          const response = await ProjectService.addProject(formData);
          if (response.status === 201) {
            toast.success('Projeto salvo com sucesso!');
            stored_id.current = response.data[0].id;
            saveProject(response.data);
          } else {
            try {
              await validateProjectSchema.validate(copyData, { abortEarly: false });
            } catch (err) {
              const errors = {};

              err.inner.forEach((x) => {
                if (x.path !== undefined) {
                  if (!(x.path in errors)) {
                    errors[x.path] = { message: x.errors[0] }
                    methods.setError(x.path, { message: x.errors[0] });
                  }
                }
              });

              setShowCounterpartsInputs(false);
              setIsSaving(false);
              setIsInAnalysis(false);

              toast.error("Falha ao salvar projeto.");
              return;
            }

            setValue('status', currentStatus)
            orderFeedbackErrorMessage(response.data);
          }
          setIsSaving(false);
        }
        setUploadedFiles(official_journal);
        setIsFinishProject(true)
      } catch (err) {
        setValue('status', currentStatus)
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error('Falha ao salvar projeto');
        }
      }
      setIsUploading(false);
    },
    [id, project, inputs, methods, activeStep, isFinishProject, proponent],
  );

  useEffect(() => {
    loadFormInfo();
  }, [loadFormInfo]);

  useEffect(() => {
    if (project) {
      handleProjectInfo();
    }
    loadProponentData();

    const status = getValues('status');
    const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);
    const isDisabledByStatusCapture =
      [
        'Em rascunho',
        'Em ajuste',
        'Aguardando regularização',
        'Incentivado',
        'Não captado',
        'Projeto reprovado',
      ].includes(status);
    setIsDisabledCapture(isDisabledByStatusCapture)
    setIsDisabled(isDisabledByStatus)
    setIsInAnalysis(status === 'Em análise')
  }, [project, handleProjectInfo, loadProponentData]);

  useEffect(() => {
    let specific_goal = { specificGoals: { 0: null } };

    if (project && project.specificGoals) {
      const exploded = project.specificGoals.split('||@||');
      specific_goal.specificGoals = Object.assign({}, exploded);
      setInputs(specific_goal);
    }
  }, []);

  useEffect(() => {
    stored_id.current = id;
  }, [id]);

  return (
    <FormProvider {...methods}>
      <PageTitle
        title="Cadastro de Projeto"
        subtitle="Preencha os campos abaixo com as informações do seu projeto, conforme aprovado em lei de incentivo fiscal. Preencha com atenção!"
      />
      <Container>
        <div style={{ display: 'flex' }}>
          <SideBar activeStep={activeStep} handleStep={handleStep} errors={methods.formState.errors} />
          <Form style={{ width: '69%' }} onSubmit={methods.handleSubmit(onSubmit)}>
            {activeStep === 0 && (
              <Step1
                laws={laws}
                ufs={locationsUf}
                odsSelecteds={odsSelecteds}
                locations={project?.location}
                setCreateChip={setCreateChip}
                createChip={createChip}
              />
            )}
            {activeStep === 1 && (
              <Step2 inputs={inputs} setInputs={setInputs} />
            )}
            {activeStep === 2 && (
              <Step3
                odsSelecteds={odsSelecteds}
                setOdsSelecteds={setOdsSelecteds}
                impactCategories={impactCategories}
                impactSubCategories={impactSubCategories}
              />
            )}
            {activeStep === 3 && <Step4 isUploading={isUploading} handleSave={data => {
              setValue('status', null)
              setIsFinishProject(false)
              methods.handleSubmit(onSubmit)(data)
            }} />}
            {activeStep === 4 && <Step5 id={project?.id} />}
            {activeStep === 5 && <Step6 project={project??null} setShowCounterpartsInputs={setShowCounterpartsInputs} />}
            <FooterBack>
              {activeStep > 0 ? (
                <ContentBack>
                  <Button
                    variant="secondary"
                    onClick={handleBack}
                    type="button"
                  >
                    VOLTAR
                  </Button>
                </ContentBack>
              ) : (
                <div />
              )}
              <FooterActions>

                {(project?.status === "Em captação" || project?.status === "Em análise") &&
                  <Button type="button" onClick={() => setModalOpen(true)} disabled={isDisabledCapture}>
                    EDITAR
                  </Button>
                }

                <UpdateProjectRegistration
                  open={modalOpen}
                  handleClose={() => setModalOpen(false)}
                  onClick={(status) => {
                    updateStateProject({ status })

                  }}
                />
                {project?.status !== "Em análise" &&
                  <Button variant="secondary" type="submit"
                    disabled={(showCounterpartsInputs && activeStep === 5) || isInAnalysis || isSaving}
                    style={{display: (project?.status === "Em captação" || project?.status === "Incentivado" || project?.status === "Não captado" || project?.status === "Projeto reprovado") ? 'none' : 'flex'}}
                    onClick={() => {
                      setValue('status', null)
                      setIsFinishProject(false)
                    }}>
                    SALVAR RASCUNHO
                  </Button>
                }
                {(((project?.status !== 'Em captação' && user.userRole !== 'vitrine-manager') || user.userRole === 'vitrine-manager') && project?.status !== 'Em análise') && (
                  <Button
                    type="submit"
                    style={{ display: activeStep === 5 ? 'flex' : 'none' }}
                    onClick={() => {
                      if (user.userRole === 'vitrine-manager') {
                        setValue('status', null)
                        handleSave();
                        setShowChangeProponentModal(true);
                      } else {
                        setValue('status', 'Em análise');
                        handleSave();
                      }
                    }}
                    disabled={(showCounterpartsInputs && activeStep === 5) || isDisabled || isSaving}
                  >
                    {user.userRole === 'vitrine-manager' ? 'DELEGAR PROPONENTE' : (project?.status === 'Em rascunho' ? 'CONCLUIR CADASTRO' : ((project?.status === 'Em ajuste' || project?.status === 'Aguardando regularização') ? 'CONCLUIR EDIÇÃO' : 'ENVIAR PARA ANÁLISE'))}
                  </Button>
                )}
                 {(project?.status === "Em captação" || project?.status === "Em análise") &&
                    <Button
                    type="button"
                    // borderColor={theme.colors.primary}
                    variant="primary"
                    style={{ display: activeStep === 5 ? 'flex' : 'none' }}
                    // color={theme.colors.primary}
                    onClick={() => setModalOpen2('CloseCaptureProponent')}
                  >
                    Encerrar a captação
                  </Button>
                }
                {/* {project?.status === 'Em captação' && (
                  <Button
                    onClick={() => setShowRequestAdjustModal(true)}
                    type={'button'}
                    style={{ display: activeStep === 5 ? 'flex' : 'none' }}
                    disabled={isSaving}
                  >
                    Solicitar ajustes
                  </Button>
                )} */}
                <Button
                  onClick={handleNext}
                  type={isDisabled ? 'button' : 'submit'}
                  style={{ display: activeStep === 5 ? 'none' : 'flex' }}
                  disabled={isSaving}
                >
                  PRÓXIMO
                </Button>
              </FooterActions>
            </FooterBack>
          </Form>
        </div>
      </Container>
      {showModalIncomplete && (
        <ProjectCreatedIncompletedModal
          open={showModalIncomplete}
          handleClose={handleCloseIncomplete}
        />
      )}
      {showChangeProponentModal && (
        <ChangeProponentModal
          open={true}
          onChange={(proponent_id) => {
            updateProponent({ proponent_id })
          }}
          onClose={() => setShowChangeProponentModal(!showChangeProponentModal)}
        />
      )}
      {showRequestAdjustModal && (
        <RequestAdjustModal
          open={true}
          onChange={(description) => {
            sendNotification({ description });
          }}
          handleClose={() => setShowRequestAdjustModal(false)}
        />
      )}

      {modalRender}

    </FormProvider>
  );
};

export default RegisterProject;
