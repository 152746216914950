import React from 'react';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { Container } from './styles';

function Input({
  isPassword = false,
  width,
  height,
  icon,
  required=false,
  margin=0,
  changeIcon,
  error,
  color,
  rounded = false,
  ...rest
}) {
  const Icon = icon || undefined;
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
      }}
    >
      {icon && (
        <Icon
          onClick={changeIcon}
          style={{
            position: 'absolute',
            right: 0,
            top: 15,
            width: 20,
            height: 20,
            marginRight: 15,
            cursor: 'pointer',
            zIndex: 1,
            color: color ?? '',
          }}
        />
      )}
      {!!(required && (rest.value?.length <= 0 || rest.value == '' || rest.value == null || rest.value == undefined)) &&
        <InputLabel 
          style={{color: 'red', 
                  marginLeft: margin+'%', 
                  backgroundColor: '#fff',
                  position: 'absolute',
                  marginTop: '-6px',
                  zIndex: 9,
                  fontSize: '12px',
                }} 
          id="select-label">Obrigatório</InputLabel>
      }
      <Container
        width={width}
        height={height}
        variant="outlined"
        color={color}
        rounded={rounded}
        {...rest}
        error={!!error}
      />
      {!!error && (
        <FormHelperText style={{ paddingLeft: 16 }} error>
          {error}
        </FormHelperText>
      )}
    </div>
  );
}

export default Input;
