import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  main {
    height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ProjectsContainer = styled.div`
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100% - 229px);
  height: auto;
  border-radius: 30px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
`;