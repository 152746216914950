import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const UpdateTaxRegime = ({ open, handleClose, onClick, taxRegime }) => {

  const refreshPage = ()=>{
    window.location.reload();  
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Deseja salvar <b>{taxRegime}</b> como regime tributário em seu cadastro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Não
        </Button>
        <Button type="submit" onClick={onClick} color="primary">
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateTaxRegime;