import React, { useCallback, useEffect, useState, useRef } from 'react';
import NoProjects from 'components/NoProjects';
import ProjectsTable from 'components/ProjectsTable';
import AddCircle from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import ProjectsFilter from 'components/ProjectsFilter';
import { ProjectsContainer, Container, Header } from './styles';
import Button from 'components/Button';
import { ProjectService } from 'services/projects';
import ModalInformation from 'components/ModalInformation';
import AdmProjectsForms from 'components/Forms/AdmProjectsForms';
import CreateProjectModal from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals/CreateProject'
import { responsiveFontSizes } from '@material-ui/core';
import { useMemo } from 'react';

const ProjectsDashboard = () => {
  const timerRef = useRef(null);
  const [filters, setFilters] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const { push } = useHistory();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noProjects, setNoProjects] = useState(false);
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Excluir Projeto',
    description: 'Projeto excluído com sucesso!',
  });

  const [modalOpen, setModalOpen] = useState(false);

  const handleChangeShowFilter = useCallback(() => {
    setShowFilter(prevState => !prevState);
  }, []);

  const modalRender = useMemo(() => {
    const modal = {
      createProject: (
        <CreateProjectModal
          open={modalOpen === 'createProject'}
          handleClose={() => setModalOpen('')}
          // onChange={(captured_value, sponsor_id, date) => {
          //   updateCapturedValueProject({ captured_value, sponsor_id, date })
          // }}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);


  let projectList = [];
  async function loadProjects() {
    try {
      setLoading(true);
      const response = await ProjectService.listProjects();
      response.data.map(p => {
        projectList.push({
          ...p,
          handleDeleteProject,
        });
      });
      // setProjects(response.data);
      console.log(response);
      setProjects(projectList);
      setNoProjects(response.data.length === 0);
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loadProjects();
  }, []);

  const handleDeleteProject = id => {
    projectList = projectList.filter(item => item.id !== id);
    setProjects(projectList);
    setShowModalInformation(true);
  };

  const loadFilter = async (search) => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      searchByProjectName(search)
    }, 2000);
  }

  const searchByProjectName = async search => {
    setLoading(true);
    const { data } = await ProjectService.listProjects();
    const filteredProjects = data.filter(project =>
      project.name.toLowerCase().includes(search.toLowerCase()),
    );
    setProjects(filteredProjects);
    if (search === '') loadProjects();
    setLoading(false);
  };

  const loadProjectsByFilter = useCallback(async () => {
    try {
      setLoading(true);
      let newData = [];
      const { data } = await ProjectService.searchProjects(filters);

      setProjects(data.data);
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  return (
    <Container>
      <main style={{ position: 'relative' }}>
        <Header>
          <PageTitle
            title="Projetos"
            subtitle="Boas vindas a sua vitrine de projetos!"
          />
          {/* <Button variant="primary" onClick={() => push('/projetos-registro')}>
            <AddCircle />
            ADICIONAR PROJETO
          </Button> */}

          {/* <Button variant="primary" onClick={() => setModalOpen('createProject')}>
            <AddCircle />
            ADICIONAR PROJETO
          </Button> */}
        </Header>
        <ProjectsFilter
          showFilter={handleChangeShowFilter}
          search={loadFilter}
          onSearch={loadProjectsByFilter}
          isLoading={loading}
        />
        {showFilter && (
          <div style={{ marginBottom: 26 }}>
            <AdmProjectsForms setFilters={setFilters} filters={filters} />
          </div>
        )}
        <ProjectsContainer>
          {noProjects ? (
            <NoProjects />
          ) : (
            <ProjectsTable
              data={projects}
              loading={loading}
              onRefresh={() => loadProjects()}
            />
          )}
        </ProjectsContainer>
        <ModalInformation
          open={showModalInformation}
          onClick={() => {
            setShowModalInformation(false);
          }}
          handleClose={() => {
            setShowModalInformation(false);
          }}
          {...modalInformationData}
        />
      </main>
      {modalRender}
    </Container>
  );
};

export default ProjectsDashboard;
