import React from 'react';

import { Container, Navigation, Title } from './styles';

const AdminTabs = ({ items, onChange, activeTab }) => {
  return (
    <Container>
      <Navigation>
        {
          items.map((item, index) => (
            <span 
              key={item}
              className={activeTab === index ? 'active-item' : 'no-active'}
              onClick={() => onChange(index)}
            >
              <Title>{item}</Title>
            </span>
          ))
        }
      </Navigation>
    </Container>
  );
};

export default AdminTabs;
