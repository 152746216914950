import React, { useCallback, useMemo, useRef } from 'react';
import RegistrationData from './RegistrationData';
import WitnessAndLocation from './WitnessAndLocation';
import ImagesAndVideos from './ImagesAndVideos';
import AccessData from './AccessData';
import Rules from './Rules';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

import { Footer, Actions, Form } from './styles';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useUser } from 'hooks/useUser';
import { cepMask, cnpjMask, cpfMask, phoneMask } from 'utils/maskUtils';
import { ProponentService } from 'services/proponent';
import { LoginService } from 'services/login';
import { useState } from 'react';
import { formatNull } from 'utils/formatNull';
import { orderFeedbackErrorMessage } from 'utils/orderToast';
import { validateCnpj, validateCpf, validateFileExtension, validateFileSize, validatePhone } from 'utils/validation';
import { removeMask } from 'utils/maskUtils';

const schema = yup.object().shape({
  legalPerson: yup.string().required('Tipo de pessoa obrigatório'),
  name: yup.string().required("Nome Fantasia (PJ) ou Nome Social (PF) obrigatório"),
  about: yup.string().required("Sobre obrigatório").nullable(),

  full_name: yup.string().when('legalPerson', {
    is: value => String(value) === "Pessoa física",
    then: yup.string().required("Nome completo obrigatório").nullable(),
    otherwise: yup.string().notRequired().nullable()
  }),
  email: yup.string().when('legalPerson', {
    is: value => String(value) === "Pessoa física",
    then: yup.string().required("Email obrigatório").email("Email inválido"),
    otherwise: yup.string().notRequired().nullable()
  }),
  cpf: yup.string().when('legalPerson', {
    is: value => String(value) === "Pessoa física",
    then: yup.string().required("CPF obrigatório").test("cpf", "CPF inválido", value => validateCpf(removeMask(value))),
    otherwise: yup.string().notRequired().nullable()
  }),
  phone: yup.string().when('legalPerson', {
    is: value => String(value) === "Pessoa física",
    then: yup.string().required("Telefone obrigatório").test("phone", "Telefone inválido", value => validatePhone(removeMask(value))),
    otherwise: yup.string().notRequired().nullable()
  }),

  corporate_name: yup.string().when('legalPerson', {
    is: value => String(value) !== "Pessoa física",
    then: yup.string().required("Razão social obrigatória").nullable(),
    otherwise: yup.string().notRequired().nullable()
  }),
  cnpj: yup.string().when('legalPerson', {
    is: value => String(value) !== "Pessoa física",
    then: yup.string().required("CNPJ obrigatório").test("cnpj", "CNPJ Inválido", value => validateCnpj(removeMask(value))).nullable(),
    otherwise: yup.string().notRequired().nullable()
  }),
  name_responsible: yup.string().when('legalPerson', {
    is: value => String(value) !== "Pessoa física",
    then: yup.string().required("Nome completo obrigatório").nullable()
    .test("name_responsible", "O nome da testemunha não pode ser igual o nome do responsável legal.", (value, { from: [from] }) => from.value["full_name_witness"] !== value),
    otherwise: yup.string().notRequired().nullable()
  }),
  cpf_responsible: yup.string().when('legalPerson', {
    is: value => String(value) !== "Pessoa física",
    then: yup.string().transform((v, o) => !o ? "" : v).required("CPF obrigatório").test("cpf_responsible", "CPF inválido", value => validateCpf(removeMask(value))),
    otherwise: yup.string().notRequired().nullable()
  }),
  phone_responsible: yup.string().when('legalPerson', {
    is: value => String(value) !== "Pessoa física",
    then: yup.string().transform((v, o) => !o ? "" : v).required("Telefone obrigatório").test("phone_responsible", "Telefone inválido", value => validatePhone(removeMask(value))),
    otherwise: yup.string().notRequired().nullable()
  }),
  email_responsible: yup.string().when('legalPerson', {
    is: value => String(value) !== "Pessoa física",
    then: yup.string().transform((v, o) => !o ? "" : v).required("Email obrigatório").email("Email inválido")
    .test("email_responsible", "O email da testemunha não pode ser igual o email do responsável legal.", (value, { from: [from] }) => from.value["witness_email"] !== value),
    otherwise: yup.string().notRequired().nullable()
  }),

  social_contract: yup.mixed().when('legalPerson', {
    is: value => String(value) === "Entidade Empresarial",
    then: yup.mixed().required("Contrato social obrigatório")
      .test("social_contract", "Este formato não é compatível. Por favor, insira um arquivo PDF", file => validateFileExtension(file, ["application/pdf"]))
      .test("social_contract", "Por favor, insira um arquivo PDF com até 2MB.", file => validateFileSize(file, 2)),
    otherwise: yup.mixed().notRequired().nullable()
  }),

  last_election: yup.mixed().when('legalPerson', {
    is: value => String(value) === "Entidade sem fins lucrativos",
    then: yup.mixed().required("Ata da última eleição obrigatória")
      .test("last_election", "Este formato não é compatível. Por favor, insira um arquivo PDF", file => validateFileExtension(file, ["application/pdf"]))
      .test("last_election", "Por favor, insira um arquivo PDF com até 2MB.", file => validateFileSize(file, 2)),
    otherwise: yup.mixed().notRequired().nullable()
  }),
  social_status: yup.mixed().when('legalPerson', {
    is: value => String(value) === "Entidade sem fins lucrativos",
    then: yup.mixed().required("Estatuto social obrigatório")
      .test("social_status", "Este formato não é compatível. Por favor, insira um arquivo PDF", file => validateFileExtension(file, ["application/pdf"]))
      .test("social_status", "Por favor, insira um arquivo PDF com até 2MB.", file => validateFileSize(file, 2)),
    otherwise: yup.mixed().notRequired().nullable()
  }),
  
  name_register_responsible: yup.string().required("Nome completo obrigatório").nullable(),
  email_register_responsible: yup.string().required("Email obrigatório").nullable().email("Email inválido"),
  phone_register_responsible: yup.string().transform((v, o) => !o ? "" : v).required("Telefone obrigatório").test("phone_register_responsible", "Telefone inválido", value => validatePhone(removeMask(value))),
  full_name_witness: yup.string().nullable().required("Nome completo da testemunha obrigatório")
  .test("full_name_witness", "Nome completo da testemunha obrigatório", value => value !== " ")
  .test("full_name_witness", "O nome da testemunha não pode ser igual o nome do responsável legal.", (value, { from: [from] }) => from.value["name_responsible"] !== value),
  witness_email: yup.string().required("Email obrigatório").nullable().test("witness_email", "Email obrigatório", value => value !== " ").email("Email inválido")
  .test("witness_email", "O email da testemunha não pode ser igual o email do responsável legal.", (value, { from: [from] }) => from.value["email_responsible"] !== value),
  cep: yup.string().required("CEP obrigatório").nullable(),
  number: yup.string().nullable().required("Número obrigatório")
}, []);

const ProponentForm = ({
  currentStep,
  handleNext,
  handlePrevious,
  loading,
  proponent,
  currentErrors,
  updateErrors
}) => {
  const { user, updateAvatar } = useUser();
  const [step5IsValid, setStep5IsValid] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);
  const statusRef = useRef();
  statusRef.current = registerStatus;
  const formHook = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      legalPerson:
        proponent?.legalPerson === 'undefined' ? '' : proponent?.legalPerson,
      tax_regime:
        proponent?.taxRegime === 'undefined'
        ? ''
        : proponent?.taxRegime,
      company_size:
        proponent?.companySize === 'undefined'
        ? ''
        : proponent?.companySize,
      name: proponent?.name === 'undefined' ? '' : proponent?.name,
      full_name: proponent?.fullName === 'undefined' ? '' : proponent?.fullName,
      cpf: cpfMask(proponent?.cpf === 'undefined' ? '' : proponent?.cpf || ''),
      email: proponent?.email === 'undefined' ? '' : proponent?.email,
      phone: phoneMask(
        proponent?.phone === 'undefined' ? '' : proponent?.phone || '',
      ),
      about: proponent?.about === 'undefined' ? '' : proponent?.about,
      years_experience:
        proponent?.yearsExperience === 'undefined'
          ? ''
          : proponent?.yearsExperience,
      cnpj: cnpjMask(
        proponent?.cnpj === 'undefined' ? '' : proponent?.cnpj || '',
      ),
      cep: cepMask(proponent?.cep === 'undefined' ? '' : proponent?.cep || ''),
      city: proponent?.city === 'undefined' ? '' : proponent?.city,
      number: proponent?.number === 'undefined' ? '' : proponent?.number,
      public_place:
        proponent?.publicPlace === 'undefined' ? '' : proponent?.publicPlace,
      complement:
        proponent?.complement === 'undefined' ? '' : proponent?.complement,
      country: proponent?.country === 'undefined' ? '' : proponent?.country,
      district: proponent?.district === 'undefined' ? '' : proponent?.district,
      state: proponent?.state === 'undefined' ? '' : proponent?.state,
      photo_profile:
        proponent?.photoProfile === 'undefined' ? '' : proponent?.photoProfile,
      photosCarousel:
        proponent?.photosCarousel === 'undefined'
          ? []
          : proponent?.photosCarousel,
      video: proponent?.video === 'undefined' ? '' : proponent?.video,
      site: proponent?.site === 'undefined' ? '' : proponent?.site,
      youtube: proponent?.youtube === 'undefined' ? '' : proponent?.youtube,
      facebook: proponent?.facebook === 'undefined' ? '' : proponent?.facebook,
      instagram:
        proponent?.instagram === 'undefined' ? '' : proponent?.instagram,
      linkedln: proponent?.linkedln === 'undefined' ? '' : proponent?.linkedln,
      value_captured_incentive_laws:
        proponent?.valueCapturedIncentiveLaws === 'undefined'
          ? ''
          : proponent?.valueCapturedIncentiveLaws,
      incentive_laws:
        proponent?.incentiveLaws === 'undefined'
          ? ''
          : proponent?.incentiveLaws,
      full_name_witness:
        proponent?.fullNameWitness === 'undefined'
          ? ''
          : proponent?.fullNameWitness,
      witness_email:
        proponent?.witnessEmail === 'undefined' ? '' : proponent?.witnessEmail,
      corporate_name:
        proponent?.corporateName === 'undefined'
          ? ''
          : proponent?.corporateName,
      name_responsible:
        proponent?.nameResponsible === 'undefined'
          ? ''
          : proponent?.nameResponsible,
      phone_responsible:
        proponent?.phoneResponsible === 'undefined'
          ? ''
          : proponent?.phoneResponsible,
      email_responsible:
        proponent?.emailResponsible === 'undefined'
          ? ''
          : proponent?.emailResponsible,
      cpf_responsible:
        proponent?.cpfResponsible === 'undefined'
          ? ''
          : proponent?.cpfResponsible,
      social_contract:
        proponent?.socialContract === 'undefined'
          ? ''
          : proponent?.socialContract,
      social_status:
        proponent?.socialStatus === 'undefined' ? '' : proponent?.socialStatus,
      last_election:
        proponent?.lastElection === 'undefined' ? '' : proponent?.lastElection,
      company_names:
        proponent?.companyNames === 'undefined' ? '' : proponent?.companyNames,
      highlights_and_awards:
        proponent?.highlightsAndAwards === 'undefined' ? '' : proponent?.highlightsAndAwards,
      rule_data_change: proponent.ruleDataChange,
      rule_incentivs_responsibilities: proponent.ruleIncentivsResponsibilities,
      rule_project_withdrawal: proponent.ruleProponentsResponsabilities,
      rule_proponents_responsabilities: proponent.ruleProjectWithdrawal,
      name_register_responsible: proponent.nameRegisterResponsible,
      phone_register_responsible: proponent.phoneRegisterResponsible,
      email_register_responsible: proponent.emailRegisterResponsible,
      register_status: proponent.registerStatus,
    },
  });

  const formStepVariant = useMemo(() => {
    return {
      1: (
        <RegistrationData
          loading={loading}
          legalPerson={proponent?.legalPerson}
          idProponent={proponent?.id}
          setIsDisabled={setIsDisabled}
          currentStep={currentStep}
          isUploading={loadingAction}
        />
      ),
      2: <WitnessAndLocation legalPerson={proponent?.legalPerson}/>,
      3: <ImagesAndVideos isUploading={loadingAction} />,
      // 4: <AccessData />,
      4: <Rules isValid={setStep5IsValid} />,
    };
  }, [loading, proponent, currentStep, loadingAction]);

  const renderForm = useCallback(() => {
    return formStepVariant[currentStep];
  }, [formStepVariant, currentStep, loadingAction]);

  const buildFirstStepPayload = useCallback(data => {
    if (data?.legalPerson === 'Pessoa física') {
      return {
        legal_person: data?.legalPerson,
        tax_regime: data?.taxRegime,
        name: data?.name,
        years_experience: data?.years_experience,
        incentive_laws: data?.incentive_laws,
        about: formatNull(data?.about),
        value_captured_incentive_laws: data?.value_captured_incentive_laws,
        full_name: data?.full_name,
        cpf: data?.cpf,
        email: data?.email,
        phone: data?.phone,
        company_names: data?.company_names,
        highlights_and_awards: data?.highlights_and_awards,
        cep: data?.cep,
        city: data?.city,
        number: data?.number,
        public_place: data?.public_place,
        complement: data?.complement,
        country: data?.country,
        district: data?.district,
        state: data?.state,
      };
    } else {
      const form = new FormData();
      form.append('email', data?.email);
      form.append('legal_person', data?.legalPerson);
      form.append('company_size', data?.companySize);
      form.append('tax_regime', data?.taxRegime);
      form.append('name', data?.name);
      form.append('years_experience', data?.years_experience);
      form.append('incentive_laws', data?.incentive_laws);
      form.append('about', formatNull(data?.about));
      form.append('cnpj', data?.cnpj);
      form.append('value_captured_incentive_laws', data?.value_captured_incentive_laws);
      form.append('corporate_name', data?.corporate_name);
      form.append('company_names', data?.company_names);
      form.append('highlights_and_awards', data?.highlights_and_awards);
      form.append('cep', data?.cep);
      form.append('city', data?.city);
      form.append('number', data?.number);
      form.append('public_place', data?.public_place);
      form.append('complement', data?.complement);
      form.append('country', data?.country);
      form.append('district', data?.district);
      form.append('state', data?.state);

      if (data?.social_contract && typeof data?.social_contract === 'object') {
        form.append(
          'social_contract',
          data?.social_contract,
          data?.social_contract?.name,
        );
      }
      if (data?.last_election && typeof data?.last_election === 'object') {
        form.append(
          'last_election',
          data?.last_election,
          data?.last_election?.name,
        );
      }
      if (data?.social_status && typeof data?.social_status === 'object') {
        form.append(
          'social_status',
          data?.social_status,
          data?.social_status?.name,
        );
      }

      return form;
    }
  }, []);

  const buildSecondStepPayload = useCallback(data => {
    const form = new FormData();
    if (data?.legalPerson === 'Pessoa física') {
      return {
        email: data?.email,
        name_register_responsible: data?.name_register_responsible,
        email_register_responsible: data?.email_register_responsible,
        phone_register_responsible: data?.phone_register_responsible,
        full_name_witness: data?.full_name_witness,
        witness_email: data?.witness_email,
      }
    }
    else{
      form.append('name_responsible', data?.name_responsible);
      form.append('phone_responsible', data?.phone_responsible);
      form.append('email_responsible', data?.email_responsible);
      form.append('cpf_responsible', data?.cpf_responsible);
  
      form.append('email', data?.email);
      form.append('name_register_responsible', data?.name_register_responsible);
      form.append('email_register_responsible', data?.email_register_responsible);
      form.append('phone_register_responsible', data?.phone_register_responsible);
      form.append('full_name_witness', data?.full_name_witness);
      form.append('witness_email', data?.witness_email);
      return form;
    }
  }, []);

  const buildThirdStepPayload = useCallback(data => {
    const form = new FormData();
    form.append('email', data?.email);
    if (data?.photo_profile && typeof data?.photo_profile === 'object') {
      form.append(
        'photo_profile',
        data?.photo_profile,
        data?.photo_profile?.name,
      );
    }

    if (data?.imagen1 && typeof data?.imagen1 === 'object') {
      form.append('imagen1', data?.imagen1, data?.imagen1?.name);
    }

    if (data?.imagen2 && typeof data?.imagen2 === 'object') {
      form.append('imagen2', data?.imagen2, data?.imagen2?.name);
    }

    if (data?.imagen3 && typeof data?.imagen3 === 'object') {
      form.append('imagen3', data?.imagen3, data?.imagen3?.name);
    }

    if (data?.imagen4 && typeof data?.imagen4 === 'object') {
      form.append('imagen4', data?.imagen4, data?.imagen4?.name);
    }

    form.append('video', data?.video);
    form.append('site', data?.site);
    form.append('facebook', data?.facebook);
    form.append('youtube', data?.youtube);
    form.append('linkedln', data?.linkedln);
    form.append('instagram', data?.instagram);

    return form;
  }, []);

  const buildFifthStepPayload = useCallback(
    data => {
      if (currentStep === 4) {
        if (!data.rule_data_change &&
          !data.rule_incentivs_responsibilities &&
          !data.rule_project_withdrawal &&
          !data.rule_proponents_responsabilities
        ) {
          throw new Error('teste');
        } else {
          return {
            rule_data_change: data?.rule_data_change,
            rule_incentivs_responsibilities: data?.rule_incentivs_responsibilities,
            rule_project_withdrawal: data?.rule_project_withdrawal,
            rule_proponents_responsabilities: data?.rule_proponents_responsabilities,
          };
        }
      }
    },
    [currentStep],
  );

  const getPayloadWithStep = useCallback(
    data => {
      try {
        const payload = {
          1: buildFirstStepPayload(data),
          2: buildSecondStepPayload(data),
          3: buildThirdStepPayload(data),
          4: buildFifthStepPayload(data),
        };

        return payload[currentStep];
      } catch (err) {
        toast.error(err.message);
        return;
      }
    },
    [
      currentStep,
      buildFirstStepPayload,
      buildSecondStepPayload,
      buildThirdStepPayload,
      buildFifthStepPayload,
      loadingAction,
    ],
  );

  const getPayloadCompleted = useCallback((data, isLastStep = false) => {
    const form = new FormData();
    // if (data?.password && (!data?.new_password || !data?.confirm_new_password)) {
    //   toast.error('4. Preencha os campos de nova senha e confirmar nova senha');
    //   return ;
    // }
    // if (data?.new_password !== data?.confirm_new_password) {
    //   toast.error('4. Nova senha e Confirmar nova senha devem ser iguais');
    //   return;
    // }

    if (data?.legalPerson === 'Pessoa física') {
      form.append('legal_person', formatNull(data?.legalPerson));
      form.append('name', formatNull(data?.name));
      form.append('years_experience', formatNull(data?.years_experience));
      form.append('incentive_laws', formatNull(data?.incentive_laws));
      form.append('about', formatNull(data?.about));
      form.append('value_captured_incentive_laws', formatNull(data?.value_captured_incentive_laws));
      form.append('full_name', formatNull(data?.full_name));
      form.append('cpf', formatNull(data?.cpf));
      form.append('email', formatNull(data?.email));
      form.append('phone', formatNull(data?.phone));
    } else {
      form.append('legal_person', formatNull(data?.legalPerson));
      form.append('name', formatNull(data?.name));
      form.append('years_experience', formatNull(data?.years_experience));
      form.append('incentive_laws', formatNull(data?.incentive_laws));
      form.append('about', formatNull(formatNull(data?.about)));
      form.append('cnpj', formatNull(data?.cnpj));
      form.append('value_captured_incentive_laws', formatNull(data?.value_captured_incentive_laws));
      form.append('corporate_name', formatNull(data?.corporate_name));
      form.append('name_responsible', formatNull(data?.name_responsible));
      form.append('phone_responsible', formatNull(data?.phone_responsible));
      form.append('email_responsible', formatNull(data?.email_responsible));
      form.append('cpf_responsible', formatNull(data?.cpf_responsible));

      if (data?.last_election && typeof data?.last_election === 'object') {
        form.append(
          'last_election',
          data?.last_election,
          data?.last_election?.name,
        );
      } else if (!proponent?.lastElection) {
        form.append('last_election', '');
      }
      if (data?.social_status && typeof data?.social_status === 'object') {
        form.append(
          'social_status',
          data?.social_status,
          data?.social_status?.name,
        );
      } else if (!proponent?.socialStatus) {
        form.append('social_status', '');
      }

      if (data?.social_contract && typeof data?.social_contract === 'object') {
        form.append(
          'social_contract',
          data?.social_contract,
          data?.social_contract?.name,
        );
      } else if (!proponent?.socialContract) {
        form.append('social_contract', '');
      }
    }
    form.append('tax_regime', formatNull(data?.tax_regime));
    form.append('company_size', formatNull(data?.company_size));
    form.append('highlights_and_awards', formatNull(data?.highlights_and_awards));
    form.append('company_names', formatNull(data?.company_names));
    form.append('name_register_responsible', formatNull(data?.name_register_responsible));
    form.append('email_register_responsible', formatNull(data?.email_register_responsible));
    form.append('phone_register_responsible', formatNull(data?.phone_register_responsible));
    form.append('full_name_witness', formatNull(data?.full_name_witness));
    form.append('witness_email', formatNull(data?.witness_email));
    form.append('cep', formatNull(data?.cep));
    form.append('city', formatNull(data?.city));
    form.append('number', formatNull(data?.number));
    form.append('public_place', formatNull(data?.public_place));
    form.append('complement', formatNull(data?.complement));
    form.append('country', formatNull(data?.country));
    form.append('district', formatNull(data?.district));
    form.append('state', formatNull(data?.state));

    if (data?.photo_profile && typeof data?.photo_profile === 'object') {
      form.append(
        'photo_profile',
        data?.photo_profile,
        data?.photo_profile?.name,
      );
    }

    if (data?.imagen1 && typeof data?.imagen1 === 'object') {
      form.append('imagen1', data?.imagen1, data?.imagen1?.name);
    }

    if (data?.imagen2 && typeof data?.imagen2 === 'object') {
      form.append('imagen2', data?.imagen2, data?.imagen2?.name);
    }

    if (data?.imagen3 && typeof data?.imagen3 === 'object') {
      form.append('imagen3', data?.imagen3, data?.imagen3?.name);
    }

    if (data?.imagen4 && typeof data?.imagen4 === 'object') {
      form.append('imagen4', data?.imagen4, data?.imagen4?.name);
    }

    form.append('video', data?.video);
    form.append('site', data?.site);
    form.append('facebook', data?.facebook);
    form.append('youtube', data?.youtube);
    form.append('linkedln', data?.linkedln);
    form.append('instagram', data?.instagram);

    form.append('rule_data_change', data.rule_data_change);
    form.append('rule_incentivs_responsibilities', data.rule_incentivs_responsibilities);
    form.append('rule_project_withdrawal', data.rule_project_withdrawal);
    form.append('rule_proponents_responsabilities', data.rule_proponents_responsabilities);
    if (isLastStep) {
      form.append('register_status', statusRef.current);
      form.append('is_last_step', isLastStep);
    }

    return form;
  }, []);

  const updateProponent = useCallback(
    async payload => {
      setLoadingAction(true);
      try {
        const { proponentId } = user;
        const response = await ProponentService.update(proponentId, payload);
        if (response.status === 203) {
          orderFeedbackErrorMessage(response.data);
        } else {
          if (response.data[0].photoProfile) {
            const photo_profile = response.data[0].photoProfile;
            updateAvatar(photo_profile)
          }
          toast.success('Dados salvos com sucesso!');
          if(response.data[0].registerStatus === 'Em análise'){
            setTimeout(() => {
              window.location.replace('/meus-dados')
            }, 1000);
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 413) {
          toast.error('Não são aceitos arquivos maiores do que 2MB');
        } else {
          console.log(err);
          toast.error('Falha ao salvar dados!');
        }
      } finally {
        setLoadingAction(false);
      }
    },
    [user],
  );

  const onSubmit = useCallback(
    async data => {
      // return console.log("submit", data);
      const payload = getPayloadCompleted(data);
      console.log("DATA", data)


      if (payload) {
        try {
          if (currentStep === 4) {
            try {
              await schema.validate(data, { abortEarly: false });
            } catch(err) {
              const errors = {};
      
              err.inner.forEach((x) => {
                if (x.path !== undefined) {
                  if(!(x.path in errors)) {
                    errors[x.path] = { message: x.errors[0] }
                    formHook.setError(x.path, { message: x.errors[0] });
                  }
                }
              });

              setRegisterStatus(null);
              console.log("ERROR", errors);
              toast.error('Falha ao salvar dados!');
              return;
            }

            const payloadCompleted = getPayloadCompleted(data, true);
            await updateProponent(payloadCompleted);
          } else {
            await updateProponent(payload);
          }
        } catch (err) {
          const errors = {};

          err.inner.forEach((x) => {
            if (x.path !== undefined) {
              if (!(x.path in errors)) {
                errors[x.path] = { message: x.errors[0] }
                formHook.setError(x.path, { message: x.errors[0] });
              }
            }
          });
          console.log(err);
          console.log('errors 2', errors);
          toast.error('Falha ao salvar dados!');
        }
      }
    },
    [getPayloadWithStep, currentStep, updateProponent, getPayloadCompleted, formHook],
  );

  if(JSON.stringify(formHook.formState.errors) !== JSON.stringify(currentErrors))
    updateErrors(JSON.parse(JSON.stringify(formHook.formState.errors)));

    console.log("PROPS", proponent)
  return (
    <FormProvider {...formHook}>
      <Form onSubmit={formHook.handleSubmit(onSubmit)}>
        {renderForm()}
        <Footer>
          {currentStep > 1 ? (
            <Button
              type="button"
              variant="secondary"
              onClick={() => handlePrevious()}
            >
              VOLTAR
            </Button>
          ) : (
            <div />
          )}
          <Actions>
            <Button
              variant={currentStep < 4 ? 'secondary' : 'primary'}
              style={{color: currentStep < 4 ? '#6A06A4' : '#fff', display: (proponent.registerStatus !== 'Em andamento') ? 'none' : 'flex'}}
              type="submit"
              disabled={(currentStep < 4 ? loadingAction : !step5IsValid) || isDisabled }
              onClick={() => {
                setRegisterStatus('Em análise');
                formHook.clearErrors();
              }}
            >
              
              {currentStep < 4  ? 'SALVAR RASCUNHO' : 'CONCLUIR CADASTRO'}
            </Button>

            {/* {proponent.registerStatus === 'Concluído' &&
              <Button
                variant='primary'
                type="submit"
                onClick={() => {
                  setRegisterStatus('Em análise');
                  formHook.clearErrors();
                }}>SALVAR CADASTRO</Button>
            } */}

            {currentStep < 4 && (
              <Button
                type="button"
                onClick={() => handleNext()}
                disabled={loadingAction}
              >
                PRÓXIMO
              </Button>
            )}
          </Actions>
        </Footer>
      </Form>
    </FormProvider>
  );
};

export default ProponentForm;
