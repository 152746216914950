import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import Filename from 'components/Filename';
import Typography from 'components/Typography';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useState } from 'react';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';

import { DivButtons, ImageAndVideos, ImageAndVideosBox, ShowFiles } from './style'
import { useFormContext, Controller } from 'react-hook-form';
import Input from 'components/Input';
import { validateFileExtension, validateFileSize, validateVideoUrl } from 'utils/validation';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { CloseCaptureProponentModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { SuccessAttDeadlineModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import DragAndDrop from 'components/DragAndDrop';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';
import { dateFormattedToDateISO } from 'utils/date';

const ProjectFundingVanquished = ({ open, handleClose, onChange, project, isUploading }) => {
  // const { setError, clearErrors } = useFormContext();
  const [modalOpen, setModalOpen] = useState('');
  const [attDeadline, setAttDeadline] = useState(false);
  const [date, setDate] = useState();
  const [capture_certificate, setCapture_certificate] = useState(null);
  const [delete_capture_certificate, setDelete_capture_certificate] = useState();
  const theme = useTheme();
  let capture_certificate_name = typeof capture_certificate === 'object' && capture_certificate !== null ? capture_certificate[0].name : capture_certificate;
  const [arrayFiles, setArrayFiles] = useState([]);
  const [deleteArrayFiles, setdeleteArrayFiles] = useState([]);
  const [arrayOfficialJournal, setArrayOfficialJournal] = useState([]);
  const [deleteArrayOfficialJournal, setdeleteArrayOfficialJournal] = useState([]);  
  const [deleteCaptureName, setDeleteCaptureName] = useState(false);
  const [payloads, setPayloads] = useState({});


  const modalRender = useMemo(() => {
    const modal = {
      CloseCaptureProponent: (
        <CloseCaptureProponentModal
          open={modalOpen === 'CloseCaptureProponent'}
          handleClose={() => setModalOpen('')}
          project={project}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, project]);

  const modalRender2 = useMemo(() => {
    const modal = {
      SuccessAttDeadline: (
        <SuccessAttDeadlineModal
          open={modalOpen === 'SuccessAttDeadline'}
          handleClose={() => window.location.reload()}
          project={project}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, project]);

  const closeModals = () => {
    setAttDeadline(false);
  }

  const handleAttDeadline = useCallback(() => {
    const form = new FormData();
    if(date){
      form.append('funding_period', dateFormattedToDateISO(date));
    }
    if(arrayOfficialJournal && arrayOfficialJournal[0] !== undefined && typeof arrayOfficialJournal[0] !== 'string' && arrayOfficialJournal[0] !== undefined && arrayOfficialJournal[0] !== null){

      form.append(
        'official_journal',
        arrayOfficialJournal[0],
        arrayOfficialJournal[0].name,
      );
    }
    if(capture_certificate !== null && typeof capture_certificate !== 'string' && capture_certificate !== undefined){

      form.append(
        'capture_certificate',
        capture_certificate[0],
        capture_certificate[0].name,
      );
    }

    if(date && (arrayOfficialJournal && arrayOfficialJournal[0] !== undefined && typeof arrayOfficialJournal[0] !== 'string' && arrayOfficialJournal[0] !== undefined && arrayOfficialJournal[0] !== null)){
      form.append(
        'status',
        'Aguardando análise dos ajustes'
      );
    }

    try {
      ProjectService.attDeadlineProject(project.id, form);
      setModalOpen('')
      setModalOpen('SuccessAttDeadline')
    } catch (error) {
      console.log('error', error)
      toast.error('Ocorreu um erro')
    }
  });

  const handleAddOfficialJournal = useCallback(
    (files, title) => {
      if (arrayOfficialJournal.length < 3) {
        // setValue(title, [...arrayOfficialJournal, ...files]);
        setArrayOfficialJournal([...arrayOfficialJournal, ...files]);
      }
    },
    [arrayOfficialJournal],
  );

  const handleDeleteOfficialJournal = useCallback(
    (name, uploadedName) => {
      setArrayOfficialJournal(arrayOfficialJournal.filter((file , index) => file.name !== name && index !== name));
      setdeleteArrayOfficialJournal([...deleteArrayOfficialJournal, uploadedName])
    },
    [arrayOfficialJournal, deleteArrayOfficialJournal],
  );

  const status = project.status;
  const isInAnalysis = status === 'Em análise';
  const isInCapture = status === 'Em captação';
  const isEncouraged = status === 'Incentivado';
  const isNotCaptured = status === 'Não captado';

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);



  return (
    <>
    <Dialog
      open={open}
      onClose={handleClose}
    >
      {!attDeadline &&
        <>
          <DialogTitle>
            Prazo de captação vencido
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Este projeto encontra-se fora do período de captação. Como você gostaria de prosseguir?
            </DialogContentText>

            <DivButtons>
              <Button onClick={() => setAttDeadline(true)} type="button">
                Atualizar prazo
              </Button>

              <Button
                type="button"
                // borderColor={theme.colors.primary}
                variant="secondary"
                // color={theme.colors.primary}
                onClick={() => setModalOpen('CloseCaptureProponent')}
              >
                Encerrar a captação
              </Button>
            </DivButtons>
          </DialogContent>
        </>
      }

      {attDeadline &&
        <>
          <DialogTitle>
            Atualizar prazo de captação
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Este projeto encontra-se fora do período de captação. Atualize o prazo de captação para seu projeto se manter elegível para captar.
            </DialogContentText>
            <div style={{marginTop: '15px'}}>
              <DatePicker
                label="Prazo de captação"
                name="funding_period"
                onChange={value => {
                  // const dateValue = value.toISOString().substring(0, 10);
                  const dateValue = value.toLocaleDateString("en-CA");

                  setDate(dateValue);
                }}
              />

              <ImageAndVideosBox>
                <ImageAndVideos>
                  <div style={{ width: '100%' }}>
                    <Typography variant="body2" color="brown" className="body-title">
                      Publicação diário oficial e/ou prorrogação (Formatos aceitos: PDF, JPG ou PNG c/ limite de 1 MB por arquivo sendo no máx. 2 arquivos)
                      <InfoIcon 
                          data-for="tooltip3" 
                          data-tip="O Diário Oficial é o documento que dá publicidade ao projeto e é emitido pelo Municipio (se a aprovação foi por lei municipal), Estado (se a aprovação foi por lei estadual) ou União (se a aprovação foi por lei federal)." 
                          multiline={true} 
                          data-html={true} 
                          style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
                    </Typography>
                    <ReactTooltip id="tooltip3" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                    <DragAndDrop
                      placeholder="nenhum arquivo enviado"
                      empty={arrayOfficialJournal.length === 0}
                      onChange={files => {
                        // clearErrors("official_journal");

                        // if(files.length + arrayOfficialJournal.length > 2)
                        //   return setError("official_journal", { message: "Adicione no máximo 2 arquivos." });
                        // if(!validateFileExtension(files, ["image/png", "image/jpeg", "application/pdf"]))
                        //   return setError("official_journal", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg, png ou pdf" });
                        // if(!validateFileSize(files, 1))
                        //   return setError("official_journal", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 1MB." });
                       
                        handleAddOfficialJournal(files, 'official_journal')
                      }}
                      required={true}
                      margin={75}
                      // disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                      multiple={true}
                      isUploading={isUploading}
                    />
                    <ShowFiles>
                      <div className='multiple-images'>
                        {arrayOfficialJournal.length > 0 &&
                          arrayOfficialJournal.map((item , index) => (
                            <Filename
                              label={item ? 'Arquivo ' + index : null}
                              handleDelete={() => handleDeleteOfficialJournal(item.name ?? index, item)}
                              link={item ? item : null}
                              // disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                            />
                          ))}
                      </div>
                    </ShowFiles>
                  </div>

                  <div style={{ width: '100%' }}>
                    <Typography variant="body2" color="brown" className="body-title">
                      Certificado de captação (opcional)  -  (Formatos aceitos: PDF c/ limite de 2 MB)
                      <InfoIcon data-for="tooltip6" data-tip="Não é o Diário Oficial. É o documento que certifica que o seu projeto está apto a captar recursos. Por não ser um documento obrigatório, não são todas as leis que possuem." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
                    </Typography>
                    <ReactTooltip id="tooltip6" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                    <DragAndDrop
                      placeholder="nenhum arquivo enviado"
                      onChange={files => {
                        // clearErrors("capture_certificate");

                        // if(!validateFileExtension(files, ["application/pdf"]))
                        //   return setError("capture_certificate", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf" });
                        // if(!validateFileSize(files, 2))
                        //   return setError("capture_certificate", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                        setCapture_certificate(files);
                        setDeleteCaptureName(false);
                      }}
                      // disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                      isUploading={isUploading}
                      deleteName={deleteCaptureName}
                    />
                    {/* {capture_certificate_name && (
                      <>
                        <Filename
                          label={'Certificado de captação'}
                          handleDelete={() => {
                            setCapture_certificate(null);
                            setDeleteCaptureName(true);
                            setDelete_capture_certificate(true)
                          }}
                          link={capture_certificate_name}
                        />
                      </>
                    )} */}
                  </div>
                </ImageAndVideos>
              </ImageAndVideosBox>
            </div>
            <DivButtons>
              <Button type="button" onClick={() => handleAttDeadline()} disabled={!date || arrayOfficialJournal.length == 0}>
                Atualizar
              </Button>

              <Button
                type="button"
                // borderColor={theme.colors.primary}
                variant="secondary"
                // color={theme.colors.primary}
                onClick={() => closeModals()}
              >
                Cancelar
              </Button>
            </DivButtons>
          </DialogContent>
        </> 
      }
    </Dialog> 

    {modalRender}
    {modalRender2}
    </>
  );
}

export default ProjectFundingVanquished;