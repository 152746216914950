import React from 'react';
import { variantMapping } from './styles';

const Typography = ({
  children,
  variant,
  color,
  className = '',
  ...otherProps
}) => {
  const Component = variantMapping[variant || 'h5'];

  return (
    <Component color={color} className={className} {...otherProps}>
      {children}
    </Component>
  );
};

export default Typography;
