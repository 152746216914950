import styled from 'styled-components';

export const Container = styled.div`
  p {
    font-family: 'Roboto', sans-serif;
    color: #fff;
  }
  border: 1px solid #999;
  border-radius: 10px;
`;

export const CardContainer = styled.div`
  /* width: 498px; */
  // height: 134px;

  padding: 16px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
`;

export const Values = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: #5C5050;
`;

export const Body = styled.div`
  display: grid;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  grid-gap: 16px;
  margin-bottom: 15px;
  padding: 0 10px 10px 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const CardTitle = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;

  color: #5c5050;
`;

export const CardContent = styled.div`
  // margin-top: 11px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  // line-height: 24px;

  letter-spacing: 0.15px;
  // height: 80px;
  overflow-x: auto;

  color: #5c5050;
`;

export const CardStyle = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 0.8fr 1fr;

  grid-gap: 16px;
`;
