import PageTitle from 'components/PageTitle';
import LawsTable from 'components/LawsTable';
import React, { useCallback, useEffect, useState } from 'react';
import { Header, LawsContainer } from './styles';
import { LawsService } from 'services/laws';
import Button from 'components/Button';
import { AddCircle } from '@material-ui/icons';
import { useHistory } from 'react-router';

const LawsPage = () => {
  const { push } = useHistory();
  const [laws, setLaws] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
  });

  const loadLaws = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await LawsService.listConectaLaws(page);
      console.log('data laws', data)
      console.log('page', page)
      setLaws(data.data);
      setPagination({
        currentPage: data.current_page,
        totalPages: data.last_page,
      });
      setLoading(false);
    } catch (err) {
      alert(err);
      console.log(err.message);
      setLoading(false);
    }
  }, [setLaws]);

  useEffect(() => {
    loadLaws();
  }, []);

  return (
    <div>
      <Header>
        <PageTitle
          title="Leis"
          subtitle="Essas são as leis cadastradas conosco."
        />
        {/* <Button variant="primary" onClick={() => push('/leis-registro')} className="squared">
          <AddCircle />
          ADICIONAR LEI
        </Button> */}
      </Header>
      <LawsContainer>
        <LawsTable
          data={laws}
          isLoading={loading}
          {...pagination}
          onRefresh={page => loadLaws(page)}
          onChangePage={page => loadLaws(page)}
        />
      </LawsContainer>
    </div>
  );
};

export default LawsPage;
