import { action } from 'typesafe-actions';

export const addImagesAction = data => action('@fullScreenImage/ADD_IMAGES', data);

export const openFullscreenAction = () => action('@fullScreenImage/OPEN_FULLSCREEN');

export const closeFullscreenAction = () => action('@fullScreenImage/CLOSE_FULLSCREEN');

export const nextImageAction = () => action('@fullScreenImage/NEXT_IMAGE');

export const previousImageAction = () => action('@fullScreenImage/PREVIOUS_IMAGE');

export const seletImageAction = data => action('@fullScreenImage/SELECT_IMAGE', data);