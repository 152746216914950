import styled from 'styled-components';

export const Container = styled.div``;

export const Circle = styled.div`
    width: .7rem;
    height: .7rem;
    background-color: ${({ status, theme }) => (status == 1 || status == 2) ? theme.colors.green : (status > 2  ? theme.colors.redLighter : theme.colors.gray)};
    border-radius: 100%;
    align-self: center;
`;

export const StatusContainer = styled.div`
    min-width: max-content;

    .status-message {
        display: flex;
        gap: 0.5rem;
    }
`;

export const ButtonLink = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 5px;
    border-radius: 5px;
    text-align: center;

    a{
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
    }
`;