import Typography from 'components/Typography';
import React, { useMemo } from 'react';

import { Container, Body } from './styles';

const PendenciesFieldItem = ({ step, items, title }) => {
  const formatStep = useMemo(() => {
    const variant = {
      1: '1. Dados básicos do projeto',
      2: '2. Resumo e objetivos',
      3: '3. ODS e Impacto',
      4: '4. Vídeo, imagens e documentos',
      5: '5. Valor do projeto e incentivos',
      6: '6. Contrapartidas',
    }

    return variant[step];
  }, [step]);

  return (
    <Container>
      {
        title ? (
          <Typography variant="body2" color="grayDark2">
            {title}
          </Typography>
        ) : (
          <>
            <Typography variant="body2" color="grayDark2">
              {formatStep}
            </Typography>
            <Body>
              {
                items.map(item => (
                  <Typography variant="manrope" color="brown" key={item}>
                    {item}
                  </Typography>
                ))
              }
            </Body>
          </>
        )
      }
      
    </Container>
  );
};

export default PendenciesFieldItem;