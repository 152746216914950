import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiStepIcon-active': { color: '#6A06A4' },
    '& .MuiStepIcon-completed': { color: '#6A06A4' },
    '& .MuiStepLabel-active': { color: '#6A06A4' },
    '& .Mui-disabled .MuiStepIcon-root': { color: '#9E9E9E' },
    '& .MuiStepper-root': { fill: '#fff' },
    // '& .MuiSvgIcon-root': { color: '#6A06A4' },
    borderRadius: '6px 16px 16px 6px',
    backgroundColor: '#fefcfe',
  },
  colorText: {
    '& .MuiStepIcon-text': { fill: '#fff' },
    '& .MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active': {
      fill: '#6A06A4',
    },
    borderRadius: '6px 16px 16px 6px',
  },
}));

export const useStylesAdmin = makeStyles(() => ({
  root: {
    '& .MuiStepIcon-active': { color: '#C15028' },
    '& .MuiStepIcon-completed': { color: '#C15028' },
    '& .MuiStepLabel-active': { color: '#C15028' },
    '& .Mui-disabled .MuiStepIcon-root': { color: '#9E9E9E' },
    '& .MuiStepper-root': { fill: '#fff' },
    // '& .MuiSvgIcon-root': { color: '#C15028' },
    borderRadius: '6px 16px 16px 6px',
    backgroundColor: '#fefcfe',
  },
  colorText: {
    '& .MuiStepIcon-text': { fill: '#fff' },
    '& .MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active': {
      fill: '#C15028',
    },
    borderRadius: '6px 16px 16px 6px',
  },
}));