import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const UpdateStatus = ({ open, handleClose, onClick }) => {

  const refreshPage = ()=>{
    window.location.reload();  
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        O status de seu projeto será alterado para que você possa editar seu cadastro. Ao concluir a edição, você deverá clicar no botão "Enviar para análise". Deseja prosseguir?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Não
        </Button>
        <Button type="submit" onClick={onClick} color="primary">
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateStatus;