import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fefcfe;
  /* width: 710px;
  height: 925px; */
  border-radius: 16px;
  box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.10);
  padding-left: 40px;

  @media (max-width: 1250px){
    padding-left: 10px;
  }
`;

export const Border = styled.div`
  width: 100px;
`;

export const Content = styled.div`
  padding: 20px 0 0 20px;
`;
