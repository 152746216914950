import React from 'react';
import { Switch, Redirect, Route as RouteRRD } from 'react-router-dom';
import Route from './Route';

import Home from 'pages/Home';
import Register from 'pages/Register';
import Resultados from 'pages/Resultados';
import Projects from 'pages/Project';
import ProjectRegister from 'components/Forms/RegisterProject';
import LawRegister from 'components/Forms/RegisterLaw';
import ProjectDetails from 'pages/ProjectDetails';
import LawDetails from 'pages/LawDetails';
import Policy from 'pages/Policy';
import Terms from 'pages/Terms';
import error404 from 'pages/404';
import error500 from 'pages/500';
import Profile from 'pages/Profile';
import Notifications from 'pages/Notifications';
import CaptureReport from 'pages/CaptureReport';
import VitrinePage from 'pages/Adm/Vitrine';
import AdmProponente from 'pages/AdmProponente';
import AdmProjects from 'pages/AdmProjects';
import { DesignSystem } from 'pages/DesignSystem';
import UsersPage from 'pages/Adm/Users';
import LawsPage from 'pages/Adm/Laws';
import MainPageAdm from 'pages/Adm/MainPage';
import ProjectDetailAdm from 'pages/Adm/ProjectDetail';
import ForgotPassword from 'pages/ForgotPassword';
import AdminProfile from 'pages/Adm/Profile';
import AdmProponentDetail from 'pages/AdmProponentDetail';
import { useUser } from 'hooks/useUser';

const Routes = () => {
  const { user } = useUser();
  const isAdmin = user.userRole === 'vitrine-manager';

  return (
    <Switch>
      {/* <Route exact path="/example" component={DesignSystem} /> */}
      <Route exact path="/capture-report/:id" component={CaptureReport} isPrivate />
      {/* <Route exact path="/resultados" component={!isAdmin ? Resultados : error404} isPrivate /> */}
      <Route exact path="/" component={Home} />
      <Route exact path="/registro" component={Register} />
      <RouteRRD
        exact
        path="/recover-password/:token"
        component={ForgotPassword}
      />
      
      <Route exact path="/projetos" component={!isAdmin ? Projects : error404} isPrivate />
      <Route exact path="/projetos/:id" component={!isAdmin ? ProjectDetails : error404} isPrivate />
      <Route exact path="/leis/:id" component={isAdmin ? LawDetails : error404} isPrivate />
      <Route
        exact
        path="/projetos-registro"
        component={ProjectRegister}
        isPrivate
      />
      <Route
        exact
        path="/leis-registro"
        component={LawRegister}
        isPrivate
      />
      <Route
        exact
        path="/admin/notificacoes"
        component={isAdmin ? Notifications : error404}
        isPrivate
      />
      <Route
        exact
        path="/admin/meus-dados"
        component={isAdmin ? AdminProfile : error404}
        isPrivate
      />
      <Route exact path="/notificacoes" component={!isAdmin ? Notifications : error404} isPrivate />
      <Route
        exact
        path="/admin/proponentes"
        component={isAdmin ? AdmProponente : error404}
        isPrivate
      />
      <Route
        exact
        path="/admin/proponentes/:id"
        component={isAdmin ? AdmProponentDetail : error404}
        isPrivate
      />
      <Route exact path="/admin/usuarios" component={isAdmin ? UsersPage : error404} isPrivate />
      <Route exact path="/admin/leis" component={isAdmin ? LawsPage : error404} isPrivate />
      <Route exact path="/admin/projetos" component={isAdmin ? AdmProjects : error404} isPrivate />
      <Route
        exact
        path="/admin/projetos/:id"
        component={isAdmin ? ProjectDetailAdm : error404}
        isPrivate
      />
      <Route exact path="/meus-dados" component={!isAdmin ? Profile : error404} isPrivate />
      <Route exact path="/error-404" component={error404} isPrivate />
      <Route exact path="/error-500" component={error500} isPrivate />
      <Route exact path="/admin/vitrine" component={isAdmin ? VitrinePage : error404} isPrivate />
      <Route exact path="/admin" component={isAdmin ? MainPageAdm : error404} isPrivate />

      {/* Teste */}

      <Route
        exact
        path="/admin/patrocinadores"
        component={isAdmin ? UsersPage : error404}
        isPrivate
      />

      <RouteRRD
        exact
        path="/politica-de-privacidade"
        component={Policy}
      />

      <RouteRRD
        exact
        path="/confirmar-email/:token"
        component={Home}
      />
      <RouteRRD
        exact
        path="/termos-e-condicoes-de-uso"
        component={Terms}
      />
      {/* <Redirect from="/" to="error-404" />
      <Redirect from="*" to="error-404" /> */}
    </Switch>
  );
};

export default Routes;
