import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import ReactTooltip from "react-tooltip";
import ReactSelect from "react-select";
import Typography from 'components/Typography';
import Select from 'components/Select';
import Input from 'components/Input';
import { Container, Content, Row, Body, DocumentItem, ContentChip, DivAdd, DivMargin, Link, LocationTwoRow, LocationThreeRow } from './styles';
import FileInput from 'components/FileInput';
import { useFormContext, Controller } from 'react-hook-form';
import { cnpjMask, cpfMask, phoneMask, cepMask, onlyNumber } from 'utils/maskUtils';
import { LawsService } from 'services/laws';
import { LoaderContainer } from '../styles';
import { CircularProgress } from '@material-ui/core';
import AdminFormGroup from '../AdminFormGroup';
import SelectMultiple from 'components/SelectMultiple';
import { CepService } from 'services/cep';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import Button from 'components/Button';
import { Chip } from '@material-ui/core';
import { UserIcon } from 'components/SideMenu/styles';
import { useUser } from 'hooks/useUser';
import { UsersService } from 'services/users';
import { purple } from '@material-ui/core/colors';
import './style.css';
import ReactSelectMain from 'components/ReactSelectMain';
import DragAndDrop from 'components/DragAndDrop';
import { validateFileExtension, validateFileSize } from 'utils/validation';

const RegistrationDataAdmin = ({ loading, legalPerson, isDisabled, isUploading }) => {
  const { control, setValue, setError, getValues, formState: { errors }, clearErrors } = useFormContext();
  const defaultErrors = errors;
  const [personType, setPersonType] = useState(0);
  const [companySize, setCompanySize] = useState();
  const [taxRegime, setTaxRegime] = useState();
  const [legalNature, setLegalNature] = useState();
  const [laws, setLaws] = useState([]);
  const [yearsExperience, setYearsExperience] = useState();
  const [incentiveLaws, setIncentiveLaws] = useState();
  const [selectedLaws, setSelectedLaws] = useState([]);
  const [authCep, setAuthCep] = useState(getValues('auth_cep'));
  const [authPublicPlace, setAuthPublicPlace] = useState(getValues('auth_public_place'));
  const [authNumber, setAuthNumber] = useState(getValues('auth_number'));
  const [authState, setAuthState] = useState(getValues('auth_state'));
  const [authCity, setAuthCity] = useState(getValues('auth_city'));
  const [authDistrict, setAuthDistrict] = useState(getValues('auth_district'));
  const [selectedKA, setSelectedKA] = useState(null);
  const valuesLawRef = useRef();
  const { user } = useUser();
  valuesLawRef.current = selectedLaws;
  const years_experience = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1} ${i + 1 > 1 ? 'anos' : 'ano'}`,
      })),
    [],
  );
  const legalPersons = useMemo(
    () => [
      'Pessoa física',
      'Entidade Empresarial',
      'Entidade sem fins lucrativos',
      'Administração Pública Federal',
      'Administração Pública Estadual',
      'Administração Pública Municipal'
    ],
    [],
  );

  const companySizes = useMemo(
    () => [
      'MEI',
      'ME',
      'EPP',
      'EI',
      'EIRELI'
    ],
    [],
  );

  const taxs_regime = useMemo(
    () => [
      'Não se aplica',
      'Lucro real',
      'Lucro presumido',
      'Simples nacional',
    ],
    [],
  );

  const [authLegalPerson, setAuthLegalPerson] = useState(getValues('auth_legal_person'));
  const [authName, setAuthName] = useState(getValues('auth_name'));
  const [authFullName, setAuthFullName] = useState(getValues('auth_full_name'));
  const [authCpf, setAuthCpf] = useState(getValues('auth_cpf'));
  const [authEmail, setAuthEmail] = useState(getValues('auth_email'));
  const [authPhone, setAuthPhone] = useState(getValues('auth_phone'));
  const [authAmountIncentiveLaws, setAuthAmountIncentiveLaws] = useState(getValues('auth_value_captured_incentive_laws'));
  const [authYearsExperience, setAuthYearsExperience] = useState(getValues('auth_years_experience'));
  const [
    authExperienceIncentiveLaws,
    setAuthExperienceIncentiveLaws,
  ] = useState(getValues('auth_experience_incentive_laws'));
  const [authAbout, setAuthAbout] = useState(getValues('auth_about'));
  const [authCorporateName, setAuthCorporateName] = useState(getValues('auth_corporate_name'));
  const [authCnpj, setAuthCnpj] = useState(getValues('auth_cnpj'));
  const [authNameResponsible, setAuthNameResponsible] = useState(getValues('auth_name_responsible'));
  const [authCpfResponsible, setAuthCpfResponsible] = useState(getValues('auth_cpf_responsible'));
  const [authPhoneResponsible, setAuthPhoneResponsible] = useState(getValues('auth_phone_responsible'));
  const [authEmailResponsible, setAuthEmailResponsible] = useState(getValues('auth_email_responsible'));
  const [authSocialContract, setAuthSocialContract] = useState(getValues('auth_social_contract'));
  const [authLastElection, setAuthLastElection] = useState(getValues('auth_last_election'));
  const [authComplement, setAuthComplement] = useState(getValues('auth_complement'));
  const [authSocialState, setAuthSocialState] = useState(getValues('auth_social_status'));
  const [authCompanyNames, setAuthCompanyNames] = useState(getValues('auth_company_names'));
  const [authHighlightsAndAwards, setAuthHighlightsAndAwards] = useState(getValues('auth_highlights_and_awards'));
  const [authTaxRegime, setAuthTaxRegime] = useState(getValues('auth_tax_regime'));
  const [users, setUsers] = useState([]);

  const [companyNamesArray, setCompanyNamesArray] = useState([]);
  const [companyNames, setCompanyNames] = useState('');
  
  const [disabled, setDisabled] = useState(false);
  const [cepError, setCepError] = useState();

  const theme = useTheme();

  useEffect(() => {
    if (getValues('incentive_laws')) {
      setIncentiveLaws(getValues('incentive_laws'));
    }
    if (getValues('years_experience')) {
      setYearsExperience(getValues('years_experience'));
    }

    if (getValues('tax_regime')) {
      setTaxRegime(getValues('tax_regime'));
    }

    if (getValues('company_size')) {
      setCompanySize(getValues('company_size'));
    }
    
    if (getValues('auth_cep') !== undefined) {
      setAuthCep(getValues('auth_cep'));
    }
    if (getValues('auth_public_place') !== undefined) {
      setAuthPublicPlace(getValues('auth_public_place'));
    }
    if (getValues('auth_number') !== undefined) {
      setAuthNumber(getValues('auth_number'));
    }
    if (getValues('auth_district') !== undefined) {
      setAuthDistrict(getValues('auth_district'));
    }
    if (getValues('auth_complement') !== undefined) {
      setAuthComplement(getValues('auth_complement'));
    }
    if (getValues('auth_state') !== undefined) {
      setAuthState(getValues('auth_state'));
    }
    if (getValues('auth_city') !== undefined) {
      setAuthCity(getValues('auth_city'));
    }

  }, [getValues]);

  const loadLaws = useCallback(async () => {
    try {
      const response = await LawsService.list();
      setLaws(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadLaws();

    if (legalPerson) {
      setPersonType(legalPersons.findIndex(item => item === legalPerson));
    }
  }, [loadLaws, legalPerson, legalPersons]);

  const handleAddCompany = useCallback(() => {
    setCompanyNamesArray([...companyNamesArray, companyNames]);
    setCompanyNames('');
  }, [companyNamesArray, companyNames]);

  const handleDeleteCompanyNamesArray = chipToDelete => {
    setCompanyNamesArray(chips =>
      chips.filter(chip => chip !== chipToDelete),
    );
  };

  const mounted_company_names = useRef();
  const company_names = getValues('company_names');
  let exploded_company_names = company_names ? company_names.split(',') : [];

  useEffect(() => {
    if (!mounted_company_names.current) {
      setCompanyNamesArray(exploded_company_names);
      mounted_company_names.current = true;
    } else {
      setValue('company_names', companyNamesArray.join(','));
    }
  }, [setValue, companyNamesArray]);

  useEffect(() => {
    async function fetchUsers() {
      const { data } = await UsersService.listKeyAccounts();

      setUsers(data.map((user, idx) => ({
        label: user.name,
        value: Object.assign({}, user)
      })));
    }

    fetchUsers();
  }, []);

  const findCep = useCallback(
    async cep => {
      if(getValues('previous_cep') !== cep && !disabled){
        setDisabled(true);
        setCepError(undefined);
        try {
          const response = await CepService.find(cep);
          if (!response.data.erro) {
            setValue('public_place', response.data.logradouro);
            setValue('district', response.data.bairro);
            setValue('state', response.data.uf);
            setValue('city', response.data.localidade);
          } else {
            setCepError("CEP inválido");
          }
          setValue('previous_cep', cep)
        } catch (err) {
          toast.error('Falha ao pesquisar endereço');
        }
        setDisabled(false);
      }
    },
    [setValue]
  );

  const renderFiledPhysicalPerson = useCallback(() => {
    return (
      <>
        <AdminFormGroup
          checked={authName}
          onChange={value => {
            setAuthName(value);
            setValue('auth_name', value);
          }}
        >
        {/* *Obrigatório */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => <Input label="Nome Fantasia (PJ) ou Nome Social (PF)" disabled={isDisabled} {...field} />}
          />
        </AdminFormGroup>

        <Row colSize={2}>
          <AdminFormGroup
            checked={authFullName}
            onChange={value => {
              setAuthFullName(value);
              setValue('auth_full_name', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              name="full_name"
              control={control}
              render={({ field }) => {
                field.value = getValues('full_name');
                return <Input label="Nome completo" {...field} disabled={isDisabled}/>;
              }}
            />
          </AdminFormGroup>
          {user.adminRole !== 'general' && (
            <AdminFormGroup
              checked={authCpf}
              onChange={value => {
                setAuthCpf(value);
                setValue('auth_cpf', value);
              }}
            >
            {/* *Obrigatório */}
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => (
                  <Input
                    label="CPF"
                    {...field}
                    onChange={e => {
                      const value = cpfMask(e.target.value);
                      setValue('cpf', value);
                    }}
                    disabled={isDisabled}
                  />
                )}
              />
          </AdminFormGroup>
        )}
        </Row>
        {user.adminRole !== 'general' && (
          <Row colSize={2}>
            <AdminFormGroup
              checked={authEmail}
              onChange={value => {
                setAuthEmail(value);
                setValue('auth_email', value);
              }}
            >
            {/* *Obrigatório */}
              <Controller
                name="email"
                control={control}
                render={({ field }) => <Input label="E-mail" {...field} disabled={isDisabled}/>}
              />
            </AdminFormGroup>
            <AdminFormGroup
              checked={authPhone}
              onChange={value => {
                setAuthPhone(value);
                setValue('auth_phone', value);
              }}
            >
            {/* *Obrigatório */}
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Telefone"
                    {...field}
                    onChange={e => {
                      const value = phoneMask(e.target.value);
                      setValue('phone', value);
                    }}
                    disabled={isDisabled}
                  />
                )}
              />
            </AdminFormGroup>
          </Row>
        )}
        <Row colSize={3}>
          {/* <AdminFormGroup
            checked={authAmountIncentiveLaws}
            onChange={value => {
              setAuthAmountIncentiveLaws(value);
              setValue('auth_value_captured_incentive_laws', value);
            }}
          > */}
            <Controller
              name="value_captured_incentive_laws"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  label="Valor captado via leis"
                  style={{ fontSize: '2rem' }}
                  disabled={isDisabled}
                />
              )}
            />
          {/* </AdminFormGroup> */}
          {/* <AdminFormGroup
            checked={authYearsExperience}
            onChange={value => {
              setAuthYearsExperience(value);
              setValue('auth_years_experience', value);
            }}
          > */}
            <Select
              className="select-project-name"
              label="Experiências anteriores"
              value={
                getValues('years_experience')
                  ? years_experience.findIndex(
                      item => item.value === yearsExperience,
                    )
                  : undefined
              }
              options={years_experience.map(item => item.label)}
              onChange={e => {
                setYearsExperience(years_experience[e.target.value].value);
                setValue(
                  'years_experience',
                  years_experience[e.target.value].value,
                );
              }}
              role="admin"
              disabled={isDisabled}
            />
          {/* </AdminFormGroup> */}

          {/* <AdminFormGroup
            checked={authExperienceIncentiveLaws}
            onChange={value => {
              setAuthExperienceIncentiveLaws(value);
              setValue('auth_experience_incentive_laws', value);
            }}
          > */}
            <SelectMultiple
              className="select-project-name"
              label="Leis que já trabalhei"
              value={typeof getValues('incentive_laws') == 'string' ? valuesLawRef.current : getValues('incentive_laws')}
              laws={laws}
              role="admin"
              options={laws.map(item => item.description)}
              onChange={e => {
                setSelectedLaws(e)
                setValue('incentive_laws',e)
                }
              }
              disabled={isDisabled}
            />
          {/* </AdminFormGroup> */}
        </Row>
      </>
    );
  }, [
    authName,
    control,
    authFullName,
    authCpf,
    authEmail,
    authPhone,
    authAmountIncentiveLaws,
    authYearsExperience,
    getValues,
    years_experience,
    authExperienceIncentiveLaws,
    laws,
    setValue,
    yearsExperience,
    incentiveLaws,
    isUploading,
    errors,
  ]);

  const renderFiledLegalPerson = useCallback(() => {
    return (
      <>
        <Row colSize={3}>
          <AdminFormGroup
            checked={authName}
            onChange={value => {
              setAuthName(value);
              setValue('auth_name', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input label="Nome Fantasia (PJ) ou Nome Social (PF)" disabled={isDisabled} {...field} />
              )}
            />
          </AdminFormGroup>
          {/* <AdminFormGroup
            checked={authAmountIncentiveLaws}
            onChange={value => {
              setAuthAmountIncentiveLaws(value);
              setValue('auth_value_captured_incentive_laws', value);
            }}
          > */}
            <Controller
              name="value_captured_incentive_laws"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  label="Valor captado via leis"
                  style={{ fontSize: '2rem' }}
                  disabled={isDisabled}
                />
              )}
            />
          {/* </AdminFormGroup> */}
          {/* <AdminFormGroup
            checked={authYearsExperience}
            onChange={value => {
              setAuthYearsExperience(value);
              setValue('auth_years_experience', value);
            }}
          > */}
            <Select
              className="select-project-name"
              label="Experiências anteriores"
              value={
                getValues('years_experience')
                  ? years_experience.findIndex(
                      item => item.value === yearsExperience,
                    )
                  : undefined
              }
              options={years_experience.map(item => item.label)}
              onChange={e => {
                setYearsExperience(years_experience[e.target.value].value);
                setValue(
                  'years_experience',
                  years_experience[e.target.value].value,
                );
              }}
              role="admin"
              disabled={isDisabled}
            />
          {/* </AdminFormGroup> */}
        </Row>
        <Row colSize={3}>
          <AdminFormGroup
            checked={authCorporateName}
            onChange={value => {
              setAuthCorporateName(value);
              setValue('auth_corporate_name', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              control={control}
              name="corporate_name"
              render={({ field }) => {
                field.value = getValues('corporate_name');
                return <Input {...field} label="Razão Social" disabled={isDisabled}/>;
              }}
            />
          </AdminFormGroup>
          <AdminFormGroup
            checked={authCnpj}
            onChange={value => {
              setAuthCnpj(value);
              setValue('auth_cnpj', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              name="cnpj"
              control={control}
              render={({ field }) => {
                field.value = cnpjMask(getValues('cnpj'));
                return (
                  <Input
                    label="CNPJ"
                    {...field}
                    onChange={e => {
                      const value = cnpjMask(e.target.value);
                      setValue('cnpj', value);
                    }}
                    disabled={isDisabled}
                  />
                );
              }}
            />
          </AdminFormGroup>

          {/* <AdminFormGroup
            checked={authExperienceIncentiveLaws}
            onChange={value => {
              setAuthExperienceIncentiveLaws(value);
              setValue('auth_experience_incentive_laws', value);
            }}
          > */}
            <SelectMultiple
              className="select-project-name"
              label="Leis que já trabalhei"
              value={typeof getValues('incentive_laws') == 'string' ? valuesLawRef.current : getValues('incentive_laws')}
              laws={laws}
              role="admin"
              options={laws.map(item => item.description)}
              onChange={e => {
                setSelectedLaws(e)
                setValue('incentive_laws',e)
                }
              }
              disabled={isDisabled}
            />
          {/* </AdminFormGroup> */}
        </Row>
      </>
    );
  }, [
    authName,
    control,
    authAmountIncentiveLaws,
    authYearsExperience,
    getValues,
    years_experience,
    authCorporateName,
    authCnpj,
    authExperienceIncentiveLaws,
    laws,
    setValue,
    yearsExperience,
    incentiveLaws,
    isUploading,
    errors,
  ]);

  useEffect(() => {
    if (getValues('auth_legal_person') !== undefined) {
      setAuthLegalPerson(getValues('auth_legal_person'));
    }
    if (getValues('auth_about') !== undefined) {
      setAuthAbout(getValues('auth_about'));
    }
    if (getValues('auth_name_responsible') !== undefined) {
      setAuthNameResponsible(getValues('auth_name_responsible'));
    }
    if (getValues('auth_cpf_responsible') !== undefined) {
      setAuthCpfResponsible(getValues('auth_cpf_responsible'));
    }
    if (getValues('auth_phone_responsible') !== undefined) {
      setAuthPhoneResponsible(getValues('auth_phone_responsible'));
    }
    if (getValues('auth_email_responsible') !== undefined) {
      setAuthEmailResponsible(getValues('auth_email_responsible'));
    }
    if (getValues('auth_social_contract') !== undefined) {
      setAuthSocialContract(getValues('auth_social_contract'));
    }
    if (getValues('auth_last_election') !== undefined) {
      setAuthLastElection(getValues('auth_last_election'));
    }
    if (getValues('auth_social_status') !== undefined) {
      setAuthSocialState(getValues('auth_social_status'));
    }
    if (getValues('auth_name') !== undefined) {
      setAuthName(getValues('auth_name'));
    }
    if (getValues('auth_full_name') !== undefined) {
      setAuthFullName(getValues('auth_full_name'));
    }
    if (getValues('auth_cpf') !== undefined) {
      setAuthCpf(getValues('auth_cpf'));
    }
    if (getValues('auth_email') !== undefined) {
      setAuthEmail(getValues('auth_email'));
    }
    if (getValues('auth_phone') !== undefined) {
      setAuthPhone(getValues('auth_phone'));
    }
    if (getValues('auth_value_captured_incentive_laws') !== undefined) {
      setAuthAmountIncentiveLaws(
        getValues('auth_value_captured_incentive_laws'),
      );
    }
    if (getValues('auth_years_experience') !== undefined) {
      setAuthYearsExperience(getValues('auth_years_experience'));
    }
    if (getValues('auth_experience_incentive_laws') !== undefined) {
      setAuthExperienceIncentiveLaws(
        getValues('auth_experience_incentive_laws'),
      );
    }
    if (getValues('auth_corporate_name') !== undefined) {
      setAuthCorporateName(getValues('auth_corporate_name'));
    }
    if (getValues('auth_cnpj') !== undefined) {
      setAuthCnpj(getValues('auth_cnpj'));
    }
    if (getValues('auth_tax_regime') !== undefined) {
      setAuthTaxRegime(getValues('auth_tax_regime'));
    }
    if (getValues('auth_highlights_and_awards') !== undefined) {
      setAuthHighlightsAndAwards(getValues('auth_highlights_and_awards'));
    }
    if (getValues('auth_company_names') !== undefined) {
      setAuthCompanyNames(getValues('auth_company_names'));
    }
  }, [getValues]);

  console.log("COMPANY SIZE", getValues('company_size'));


  return (
    <Container>
      <Typography variant="h6" color="grayDark2">
        Dados cadastrais do Proponente
      </Typography>
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <>
          <Content>
            <Row colSize={3}>
              <AdminFormGroup
                checked={authLegalPerson}
                onChange={value => {
                  setAuthLegalPerson(value);
                  setValue('auth_legal_person', value);
                }}
              >
              {/* *Obrigatório */}
                <Select
                  className="select-project-name"
                  label="Natureza jurídica do proponente"
                  value={legalPersons.findIndex(
                    item => item === getValues('legalPerson'),
                  )}
                  onChange={e => {
                    const value = e.target.value;
                    setPersonType(value);
                    setValue('legalPerson', legalPersons[value]);
                  }}
                  options={legalPersons}
                  disabled={isDisabled}
                  role="admin"
                />
              </AdminFormGroup>
              {(personType === 1 || personType === 2) && (
                <AdminFormGroup
                  checked={authTaxRegime}
                  onChange={value => {
                    setAuthTaxRegime(value);
                    setValue('auth_tax_regime', value);
                  }}
                >
                  <Select
                    className="select-project-name"
                    label="Regime tributário"
                    value={taxs_regime.findIndex(
                      item => item === getValues('tax_regime'),
                    )}
    
                    onChange={e => {
                      const value = e.target.value;
                      setTaxRegime(value);
                      setValue('tax_regime', taxs_regime[value]);
                    }}
    
                    required={(getValues('tax_regime') != '') ? false : true}
                    margin={93}

                    options={taxs_regime}
                    disabled={isDisabled}
                  />
              </AdminFormGroup>
              )}
              {personType === 1 &&
                <AdminFormGroup
                  checked={authLegalPerson}
                  onChange={value => {
                    setAuthLegalPerson(value);
                    setValue('auth_legal_person', value);
                  }}
                >
                {/* *Obrigatório */}
                  <Select
                    className="select-project-size"
                    label="Porte da empresa"
                    value={companySizes.findIndex(
                      item => item === getValues('company_size'),
                    )}
                    onChange={e => {
                      const value = e.target.value;
                      setCompanySize(value);
                      setValue('company_size', companySizes[value]);
                    }}
                    options={companySizes}
                    disabled={isDisabled}
                  />
                </AdminFormGroup>
              }
            </Row>

            {personType === 0
              ? renderFiledPhysicalPerson()
              : renderFiledLegalPerson()}
            <AdminFormGroup
              checked={authAbout}
              onChange={value => {
                setAuthAbout(value);
                setValue('auth_about', value);
              }}
            >
            {/* *Obrigatório */}
              <Controller
                name="about"
                control={control}
                render={({ field }) => (
                  <Input multiline rows={4} label="Sobre" {...field} disabled={isDisabled}/>
                )}
              />
            </AdminFormGroup>
            <AdminFormGroup
              checked={authHighlightsAndAwards}
              onChange={value => {
                setAuthHighlightsAndAwards(value);
                setValue("auth_highlights_and_awards", value);
              }}
            >
            <Controller
              name="highlights_and_awards"
              // control={control}
              render={({ field }) => (
                <Input
                  // width="85%"
                  label="Destaques e prêmios"
                  height="65px"
                  multiline
                  rows={4}
                  {...field}
                  disabled={isDisabled}
                />
              )}
            />
            </AdminFormGroup>

            <Typography variant="body1" color="blueGrayish56" className="title" style={{ marginTop: 35, marginBottom: 10 }}>
              Insira o usuário responsável.
            </Typography>

            <Typography variant="body1">
              <ReactSelectMain
                placeholder="Usuário responsável"
                options={users}
                value={users.find(({ value }) => value.id == getValues("key_account_user_id"))}
                onChange={selected => {
                  const id = selected['value']['id'];
                  setValue("key_account_user_id", id)
                  setSelectedKA(id)
                }}
                isDisabled={
                  user.adminRole == 'master' ||
                  user.adminRole == 'cs_proponent1' ||
                  user.adminRole == 'evaluator' ||
                  user.adminRole == 'support' ||
                  user.adminRole == 'cs_proponent_relationship' 
                  ?
                  false
                  :
                  isDisabled
                }
              />
            </Typography>

            <Typography variant="body1" color="blueGrayish56" className="title" style={{ marginTop: 35, marginBottom: 10 }}>
              Insira aqui a lista de empresas que você atende diretamente e que gostaria que a Incentiv.me não apresentasse o projeto. 
            </Typography>
            <DivMargin style={{ marginTop: 11 }}>
              {/* <AdminFormGroup
                checked={authCompanyNames}
                onChange={value => {
                  setAuthCompanyNames(value);
                  setValue("auth_company_names", value);
                }}
              > */}
                <div style={{width: '606px'}}>
                <DivAdd>
                    <Input
                      label="Nome da empresa"
                      value={companyNames}
                      onChange={e => setCompanyNames(e.target.value)}
                      disabled={isDisabled}
                      style={{ zIndex: 0 }}
                      data-tip="Indique todas as empresas que você já possui contato <br/> 
                      e/ou parceria firmada e que por isso, não gostaria que <br/>
                      a Incentiv apresentasse o seu projeto, com a finalidade de <br/>
                      evitar duplicidade de contato." 
                      data-html={true}
                      data-for="tooltip1"
                      data-event='click focus'
                    />
                    <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
                    {/* <Button
                      borderColor={theme.colors.primary}
                      variant="secondary"
                      color={theme.colors.primary}
                      onClick={handleAddCompany}
                      disabled={isDisabled}
                    >
                      INCLUIR
                    </Button> */}
                </DivAdd>
                </div>

                  <ContentChip>
                    {companyNamesArray.map(item => (
                      <Chip
                        variant="outlined"
                        label={item}
                        onDelete={() => handleDeleteCompanyNamesArray(item)}
                        disabled={isDisabled}
                      />
                    ))}
                  </ContentChip>
            {/* </AdminFormGroup> */}
          </DivMargin>
          </Content>

          <Body>
            <Typography variant="h6" color="grayDark2">
              Localização
            </Typography>
            <Content>
              <LocationTwoRow>
                <AdminFormGroup
                  checked={authCep}
                  onChange={value => {
                    setAuthCep(value);
                    setValue('auth_cep', value);
                  }}
                >
                {/* *Obrigatório */}
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="CEP"
                        {...field}
                        value={cepMask(getValues('cep'))}
                        onChange={e => {
                          const value = cepMask(e.target.value);
                          setValue('cep', value);
                          if (value.length === 9 && !disabled) {
                            findCep(value);
                          }
                          // field.onChange(e);
                        }}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </AdminFormGroup>
                <AdminFormGroup
                  checked={authPublicPlace}
                  onChange={value => {
                    setAuthPublicPlace(value);
                    setValue('auth_public_place', value);
                  }}
                >
                {/* *Obrigatório */}
                  <Controller
                    name="public_place"
                    control={control}
                    render={({ field }) => (
                      <Input label="Logradouro" {...field} disabled={isDisabled}/>
                    )}
                  />
                </AdminFormGroup>
              </LocationTwoRow>
              <LocationThreeRow>
                <AdminFormGroup
                  checked={authNumber}
                  onChange={value => {
                    setAuthNumber(value);
                    setValue('auth_number', value);
                  }}
                >
                  {/* <Controller
                    name="number"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" label="Número" inputProps={{ maxLength: 10 }} {...field} />
                    )}
                  /> */}

                  {/* *Obrigatório */}
                  <Controller
                  name="number"
                  control={control}
                  render={({ field }) => (
                  <Input
                    label="Número"
                    height="23px"
                    inputProps={{ maxLength: 10 }}
                    {...field}
                    onChange={e => {
                      const value = onlyNumber(e.target.value);
                      setValue('number', value);
                    }}
                    disabled={isDisabled}
                    />
                    )}
                  />

                </AdminFormGroup>
                <AdminFormGroup
                  checked={authDistrict}
                  onChange={value => {
                    setAuthDistrict(value);
                    setValue('auth_district', value);
                  }}
                >
                {/* *Obrigatório */}
                  <Controller
                    name="district"
                    control={control}
                    render={({ field }) => (
                      <Input label="Bairro" {...field} disabled={isDisabled}/>
                    )}
                  />
                </AdminFormGroup>
                {/* <AdminFormGroup
                  checked={authComplement}
                  onChange={value => {
                    setAuthComplement(value);
                    setValue('auth_complement', value);
                  }}
                > */}
                  <Controller
                    name="complement"
                    control={control}
                    render={({ field }) => (
                      <Input label="Complemento" {...field} disabled={isDisabled}/>
                    )}
                  />
                {/* </AdminFormGroup> */}
              </LocationThreeRow>
              <LocationTwoRow>
                <AdminFormGroup
                  checked={authState}
                  onChange={value => {
                    setAuthState(value);
                    setValue('auth_state', value);
                  }}
                >
                {/* *Obrigatório */}
                  <Controller
                    name="state"
                    control={control}
                    role="admin"
                    render={({ field }) => (
                      <Input label="UF" {...field} disabled={isDisabled}/>
                    )}
                  />
                </AdminFormGroup>
                <AdminFormGroup
                  checked={authCity}
                  onChange={value => {
                    setAuthCity(value);
                    setValue('auth_city', value);
                  }}
                >
                {/* *Obrigatório */}
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <Input label="Cidade" {...field} disabled={isDisabled}/>
                    )}
                  />
                </AdminFormGroup>
              </LocationTwoRow>
            </Content>
          </Body>
          
          {personType !== 0 && (
            <Body>
              {personType === 1 ? (
                <Body>
                  <Typography variant="h6" color="grayDark2">
                    Documentos
                  </Typography>
                  <Content>
                    <Typography variant="body1" color="blueGrayish56" className="title" style={{ marginTop: '1rem' }}>
                      Anexe os documentos abaixo fazendo o upload dos arquivos no formato PDF. Caso os arquivos sejam maiores que 2MB, confira <Link href={'https://site.incentiv.me/wp-content/uploads/2020/12/passo_a_passo_ilovepdf_2020.pdf'} rel="noreferrer" target="_blank">aqui um passo a passo de como comprimi-los</Link> e deixá-los no tamanho adequado
                    </Typography>
                    <DocumentItem>
                {/* *Obrigatório */}
                        <Typography variant="caption" color="grayDark2">
                          Contrato social *Obrigatório
                        </Typography>
                        <Typography variant="overline" color="grayDark2">
                          Lembre-se que os nomes e os CPFs dos representantes legais devem constar no Contrato Social.
                        </Typography>
                        <AdminFormGroup
                          checked={authSocialContract}
                          onChange={value => {
                            setAuthSocialContract(value);
                            setValue('auth_social_contract', value);
                          }}
                        >
                          <DragAndDrop 
                            placeholder="nenhum arquivo enviado"
                            onChange={files => {
                              if(!validateFileExtension(files, ["application/pdf"]))
                                return setError("social_contract", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                              if(!validateFileSize(files, 7))
                                return setError("social_contract", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
                              if (files.length === 1) {
                                setValue('social_contract', files[0]);

                                clearErrors("social_contract")
                              }
                            }}
                            label={'Contrato social'}
                            link={[getValues('social_contract')]}
                            disabled={isDisabled}
                            isUploading={isUploading}
                            error={errors["social_contract"]?.message}
                          />
                      </AdminFormGroup>
                    </DocumentItem>
                  </Content>
                </Body>
              ) : (
                <Body>
                  <Typography variant="h6" color="grayDark2">
                    Documentos
                  </Typography>
                  <Content>
                    <Typography variant="body1" color="blueGrayish56" className="title">
                      Anexe os documentos abaixo fazendo o upload dos arquivos no formato PDF. Caso os arquivos sejam maiores que 2MB, confira <Link href={'https://site.incentiv.me/wp-content/uploads/2020/12/passo_a_passo_ilovepdf_2020.pdf'} rel="noreferrer" target="_blank">aqui um passo a passo de como comprimi-los</Link> e deixá-los no tamanho adequado
                    </Typography>
                    <Row colSize={2}>
                      <DocumentItem>
                {/* *Obrigatório */}
                        <Typography variant="caption" color="grayDark2">
                          Ata da última eleição *Obrigatório
                        </Typography>
                        <Typography variant="overline" color="grayDark2">
                          Lembre-se que os nomes e os CPFs dos representantes legais devem constar na ata.
                        </Typography>
                        <AdminFormGroup
                          checked={authLastElection}
                          onChange={value => {
                            setAuthLastElection(value);
                            setValue('auth_last_election', value);
                          }}
                        >
                          <DragAndDrop 
                            onChange={files => {
                              if(!validateFileExtension(files, ["application/pdf"]))
                                return setError("last_election", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                              if(!validateFileSize(files, 7))
                                return setError("last_election", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
      
                              if (files.length === 1) {
                                setValue('last_election', files[0]);

                                clearErrors("last_election")
                              }
                            }}
                            label={'Ata da última eleição'}
                            link={[getValues('last_election')]}
                            isUploading={isUploading}
                            error={errors["last_election"]?.message}
                          />
                        </AdminFormGroup>
                      </DocumentItem>
                      <DocumentItem>
                {/* *Obrigatório */}
                        <Typography variant="caption" color="grayDark2">
                          Estatuto Social *Obrigatório
                        </Typography>
                        <AdminFormGroup
                          checked={authSocialState}
                          onChange={value => {
                            setAuthSocialState(value);
                            setValue('auth_social_status', value);
                          }}
                        >
                          <DragAndDrop 
                            onChange={files => {
                              if(!validateFileExtension(files, ["application/pdf"]))
                                return setError("social_status", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                              if(!validateFileSize(files, 7))
                                return setError("social_status", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
      
                              if (files.length === 1) {
                                setValue('social_status', files[0]);

                                clearErrors("social_status")
                              }
                            }}
                            label={'Estatuto social'}
                            link={[getValues('social_status')]}
                            isUploading={isUploading}
                            error={errors["social_status"]?.message}
                          />
                        </AdminFormGroup>
                      </DocumentItem>
                    </Row>
                  </Content>
                </Body>
              )}
            </Body>
          )}
        </>
      )}
    </Container>
  );
};

export default RegistrationDataAdmin;
