import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import Typography from 'components/Typography';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import PendenciesFields from './PendenciesFields';
import { Controller } from 'react-hook-form';

import { Container, Content, FormContainer, Actions } from './styles';
import { toast } from 'react-toastify';
import { NotificationService } from 'services/notifications';
import { useUser } from 'hooks/useUser';
import ModalInformation from 'components/ModalInformation';
import { formatDate } from 'utils/date';

const AnalyzeAdmin = ({ proponentId, isDisabled }) => {
  const { user } = useUser();
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Status não alterado',
    description: 'Você não pode concluir a análise se o proponente não estiver regular. Verifique o campo de Status da Análise.',
  });
  const { control, setValue, getValues } = useFormContext();
  const statusOptions = useMemo(() => {
    return ['Em andamento', 'Em análise', 'Concluído'];
  }, []);
  const complianceStatusOptions = useMemo(() => {
    return [
      'Novo proponente',
      'Aguardando análise',
      'Aguardando ajustes no cadastro',
      'Aguardando regularização de CNDs',
      'Proponente regular',
      'Proponente Inativo'
    ];
  }, []);
  const [status, setStatus] = useState('');
  const [complianceStatus, setComplianceStatus] = useState(getValues('analysis_compliance_status'));

  useEffect(() => {
    // if (getValues('register_status')) {
    //   if (Number.isInteger(Number(getValues('register_status')))) {
    //     const statusText = statusOptions[Number(getValues('register_status'))];
    //     setStatus(statusOptions.findIndex(status => status === statusText));
    //   } else {
    //     console.log();
        setStatus(statusOptions.findIndex(status => status === getValues('register_status')));
    //   }
    // } 
  }, [getValues, statusOptions]);

  const handleSendNotification = useCallback(async () => {
    try {
      const { register_status, analyze_subject, analyze_message, id, analysis_compliance_status } = getValues();
      const payload = {
        sender_id: user.proponentId,
        proponent_id: Number(proponentId),
        register_status,
        analysis_compliance_status: analysis_compliance_status ?? 0,
        title: analyze_subject,
        body: analyze_message,
        project_id: id,
      };
      await NotificationService.create(payload);
      toast.success('Notificação enviada com sucesso!');
    } catch (err) {
      toast.error('Falha ao enviar notificação');
    }
  }, [getValues, proponentId, user]);

  const cnd_date = getValues('cnd_date');
  return (
    <Container>
      <Typography variant="h6" color="grayDark2">
        Análise
      </Typography>
      <Typography variant="subtitle2" color="grayLight1">
        Solicite as correções necessárias abaixo.
      </Typography>
      <Content>
        <FormContainer>
        <Typography variant="subtitle2" color="grayLight1">
          Data de última consulta da CND: {formatDate(cnd_date, 'dd/MM/Y')}
        </Typography>
          <Select
            label="Status do cadastro"
            value={status}
            onChange={e => {
              const value = statusOptions[e.target.value];
              if(value === 'Concluído' && complianceStatus != 4){
                setShowModalInformation(true);
                return;
              }
              setStatus(statusOptions.findIndex(status => status === value));
              setValue('register_status', value);
              if(value === 'Em análise') {
                setComplianceStatus(1)
                setValue('analysis_compliance_status', 1);
              }
            }}
            role="admin"
            options={statusOptions}
            disabled={isDisabled}
          />
          <Select
            label="Status da análise de conformidade do proponente"
            value={complianceStatus ?? 0}
            onChange={e => {
              const value = e.target.value;
              
              if(value == 1){
                setStatus(statusOptions.findIndex(status => status === 'Em análise'));
              }

              setComplianceStatus(value);
              setValue('analysis_compliance_status', value);
            }}
            role="admin"
            options={complianceStatusOptions}
            disabled={isDisabled}
          />
          <Controller
            name="analyze_subject"
            control={control}
            render={({ field }) => <Input label="Assunto" {...field} disabled={isDisabled}/>}
          />
          <Controller
            name="analyze_message"
            control={control}
            render={({ field }) => (
              <Input label="Mensagem" multiline rows={10} {...field} disabled={isDisabled}/>
            )}
          />
          {/* <Actions>
            <Button variant="secondary" disabled={isDisabled}>CANCELAR</Button>
            <Button type="button" onClick={handleSendNotification} disabled={isDisabled}>
              ENVIAR
            </Button>
          </Actions> */}
          <ModalInformation
            open={showModalInformation}
            onClick={()=>{setShowModalInformation(false)}}
            handleClose={() => setShowModalInformation(false)}
            {...modalInformationData}
          />
        </FormContainer>
        <PendenciesFields />
      </Content>
    </Container>
  );
};

export default AnalyzeAdmin;
