import styled from 'styled-components';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';

export const Container = styled.div`
  border: 1px solid #dadada;

  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  z-index: 1;
  padding: 1.5rem;
  margin-right: 3rem;

  &.filterBox {
    width: 100%;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  height: 55px!important;
  span {
    font-family: Roboto;
    color: ${({ theme }) => theme.colors.brown};
  }
`;

export const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.brown};
`;

export const Row = styled.div`
  display: flex;
  // grid-template-columns: repeat(3, 1fr);
  align-items: flex-end;
  gap: 1.5rem;
  margin-top: 1rem;

  // @media (max-width: 1920px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @media (max-width: 1340px) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-notchedOutline {
    border-radius: 30px!important;
  }

  .MuiOutlinedInput-root {
    height: auto;
    min-height: 55px;
    max-height: 94px;
    position: initial;
    overflow-y: auto;    
  }

  .MuiFormControl-root{
    padding-right: 4px;
  }

  .MuiAutocomplete-inputRoot{
    padding: 8px 14px!important;
  }

  .MuiAutocomplete-inputRoot::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  .MuiAutocomplete-inputRoot::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }
  
  .MuiAutocomplete-inputRoot::-webkit-scrollbar-thumb {
    background-color: transparent;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    height: 20px;
  }

  div[class^='MuiOutlinedInput-root-'], div[class*=' MuiOutlinedInput-root-'] {
    border-radius: 30px!important;
    height: auto;
    min-height: 55px;
    max-height: 94px;
  }

  .select-multiple{
    height: auto!important;
    min-height: 55px!important;
    max-height: 94px!important;
  }

  .MuiSelect-select:focus {
    padding: 8px 14px;
    border-radius: 32px;
    min-height: 40px!important;
    max-height: 94px!important;
    display: flex;
    align-items: center;
  }

  // remove o botão de clear padrão do input
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const SelectStyled = styled(Select)`
  height: auto!important;
  min-height: 55px!important;
  max-height: 94px!important;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: auto!important;
  min-height: 55px!important;
  max-height: 94px!important;
`;