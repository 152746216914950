import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const UsersContainer = styled.div`
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100% - 229px);
  height: 100%;
  border-radius: 4px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
`;
