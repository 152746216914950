import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import {
  RoomOutlined,
  FolderOpenOutlined,
  AccountBalanceOutlined,
  PersonOutline,
  DevicesOutlined,
  LocalActivityOutlined,
} from '@material-ui/icons';
import { FundingPeriodVanquishedModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import {
  Container,
  ItemTitle,
  InfoItem,
  Row,
  Content,
  SocialNetworkContent,
  ValueContainer,
} from './styles';
import Typography from 'components/Typography';

import linkedInIcon from 'assets/svgs/linkedin.svg';
import facebookIcon from 'assets/svgs/facebook.svg';
import instagramIcon from 'assets/svgs/instagram.svg';
import youtubeIcon from 'assets/svgs/youtube.svg';
import formatCurrency from 'utils/currency';
import { ProjectService } from 'services/projects';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useUser } from 'hooks/useUser';

const ProponentDetailCard = ({ proponent }) => {
  const { push } = useHistory();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noProjects, setNoProjects] = useState(false);
  const [modalOpen, setModalOpen] = useState('FundingPeriodVanquished');
  const { user } = useUser();

  let projectList = [];

  async function loadProjects() {
    try {
      setLoading(true);
      const response = await ProjectService.listProjects();
      response.data.map(p => {
        projectList.push({
          ...p
        });
      });
      setProjects(projectList);
      setNoProjects(response.data.length === 0);
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }

  console.log("ISADMIN", user.isAdmin);

  useEffect(() => {
    loadProjects();
  }, []);

  const formateNumberProjects = useMemo(() => {
    if (proponent?.projects.length >= 10) {
      return proponent?.projects.length;
    }
    return String(proponent?.projects.length).padStart(2, '0');
  }, [proponent]);

  const goToSocialNetworkPage = useCallback(link => {
    window.open(link);
  }, []);

  const formattedValue = useMemo(() => {
    return formatCurrency(Number(proponent?.valueCapturedIncentiveLaws));
  }, [proponent]);

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const hoje = today.getTime();
  //console.log("PROJETOS", projects);is
  let auxIsvanquished = false;

  projects.map(p => {
    let formatDate = (new Date(p.fundingPeriod)).getTime();
    if(p.fundingPeriod !== null && formatDate < hoje && (p.status === "Em captação" || p.status === "Aguardando regularização")) {
      auxIsvanquished = true;
    }
  });

  const modalRender = useMemo(() => {
    const modal = {
      FundingPeriodVanquished: (
        <FundingPeriodVanquishedModal
          open={true}
          handleClose={() => setModalOpen('')}
          onClick={() => redirectToProject()}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);


  const redirectToProject = useCallback(() => {
    window.open('/projetos/', "_self");
  },[]);

  return (
    <Container>
      <Row>
        <InfoItem>
          <ItemTitle>
            <RoomOutlined />
            <Typography variant="h6">Localização</Typography>
          </ItemTitle>
          <Typography variant="subtitle1">
            {`${proponent?.city ??
              'Cidade não informada '}-${proponent?.state ??
              ' Estado não informado'}`}
          </Typography>
        </InfoItem>
        <InfoItem>
          <ItemTitle>
            <FolderOpenOutlined />
            <Typography variant="h6">Quantidade de Projetos</Typography>
          </ItemTitle>
          <Typography variant="subtitle1">
            {proponent?.projects.length === 0 ||
            !Boolean(proponent?.projects) ? (
              <>Nenhum projeto cadastrado</>
            ) : (
              formateNumberProjects
            )}
          </Typography>
        </InfoItem>
      </Row>
      <Row style={{ marginTop: 30 }}>
        <InfoItem>
          <ItemTitle>
            <AccountBalanceOutlined />
            <Typography variant="h6">
              Experiência em leis de incentivo
            </Typography>
          </ItemTitle>
          <div className={'incentiveLaws'}>
            <Typography variant="subtitle1">
              {proponent?.yearsExperience ? (
                proponent?.yearsExperience > 1 ? (
                  `${proponent?.yearsExperience} anos`
                ) : (
                  `${proponent?.yearsExperience} ano`
                )
              ) : (
                <>Tempo de experiência não informado</>
              )}
            </Typography>
            <Row style={{ marginTop: proponent?.incentiveLaws?.length > 0 ? 24 : 0, display: 'flex' , flexWrap: 'wrap', marginBottom: '0.35rem'}}>
              <Typography variant="subtitle1" style={{ width: '100%' }}>
              {
                proponent?.incentiveLaws?.length > 0 && (
                  proponent?.incentiveLaws?.map((l)=>{
                    return l + '; '
                  })
                ) 
              }
              </Typography>
              
              <Typography variant="subtitle1">
                {proponent?.incentiveLaws?.length === 0 ? 'Leis não informadas' : ''}
              </Typography>
            </Row>
          </div>
        </InfoItem>
        <ValueContainer>
          <InfoItem>
            <ItemTitle>
              <LocalActivityOutlined />
              <Typography variant="h6">
                Valor total já captado via leis de incentivo
              </Typography>
            </ItemTitle>
            <Typography variant="subtitle1">
              {proponent?.valueCapturedIncentiveLaws ? (
                formattedValue
              ) : (
                <>Não informado</>
              )}
            </Typography>
          </InfoItem>
        </ValueContainer>
      </Row>
      <Row>
        <InfoItem>
          <ItemTitle>
            <PersonOutline />
            <Typography variant="h6">Redes sociais</Typography>
          </ItemTitle>
          <SocialNetworkContent>
            {proponent?.linkedln && (
              <img
                src={linkedInIcon}
                alt="Linkedin"
                onClick={() => goToSocialNetworkPage(proponent?.linkedln)}
              />
            )}
            {proponent?.facebook && (
              <img
                src={facebookIcon}
                alt="Facebook"
                onClick={() => goToSocialNetworkPage(proponent?.facebook)}
              />
            )}
            {proponent?.instagram && (
              <img
                src={instagramIcon}
                alt="Instagram"
                onClick={() => goToSocialNetworkPage(proponent?.instagram)}
              />
            )}
            {proponent?.youtube && (
              <img
                src={youtubeIcon}
                alt="Youtube"
                onClick={() => goToSocialNetworkPage(proponent?.youtube)}
              />
            )}
          </SocialNetworkContent>
        </InfoItem>
        <InfoItem>
            <ItemTitle>
              <DevicesOutlined />
              <Typography variant="h6">Site</Typography>
            </ItemTitle>
            <Typography variant="subtitle1">
              {proponent?.site !== 'null' ? (
                <a
                  href={proponent?.site}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {proponent?.site}
                </a>
              ) : (
                <>Não informado</>
              )}
            </Typography>
          </InfoItem>
      </Row>
      {(auxIsvanquished && !user.isAdmin) &&
        modalRender
      }
    </Container>
  );
};

export default ProponentDetailCard;
