import Checkbox from '@material-ui/core/Checkbox';
import Input from 'components/Input';
import Typography from 'components/Typography';
import React, { useCallback, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { phoneMask, cpfMask } from 'utils/maskUtils';
import AdminFormGroup from '../AdminFormGroup';
import CardCND from 'components/CardCND';
import Button from 'components/Button';
import { ProponentService } from 'services/proponent';
import { useUser } from 'hooks/useUser';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';

import { Container, Content, Row, Body, LocationThreeRow, LocationTwoRow, Blankdiv } from './styles';
import ReactTooltip from 'react-tooltip';
import { useMemo } from 'react';
import { YoutubeSearchedFor } from '@material-ui/icons';
import { toast } from 'react-toastify';
import uploadHandler from 'services/uploadCdn';
import { Skeleton } from '@material-ui/lab';
import { Line } from 'components/CardCND/styles';

const Cnds = ({cnds, proponentId, handleUpdateCdns, uploadCndProponent, isDisabled, isBlankData, hasChangeData}) => {
    const theme = useTheme();
    const [cndsProp, setCndsProp] = useState(cnds);
    const [cndsPropPF, setCndsPropPF] = useState(cnds.cpf ? cnds.cpf : []);
    const [cndsPropPJ, setCndsPropPJ] = useState(cnds.cnpj ? cnds.cnpj : []);
    const [isLoading, setIsLoading] = useState(false);
    const [isBloqued, setIsBloqued] = useState(cnds.length !== 0 ? true : false);
    const enableConsult = isDisabled || isBloqued || isBlankData || hasChangeData;
    if(cndsPropPF !== undefined){
        Object.keys(cndsPropPF).map((cnd) => {
            if(((cndsPropPF[cnd].status !== 'OK' && cndsPropPF[cnd].status !== null) || cndsPropPF[cnd].is_expired) && isBloqued){
                setIsBloqued(false);
            }
        })  
    }
    if(cndsPropPJ !== undefined){
        Object.keys(cndsPropPJ).map((cnd) => {
            if(((cndsPropPJ[cnd].status !== 'OK' && cndsPropPJ[cnd].status !== null) || cndsPropPJ[cnd].is_expired) && isBloqued){
                setIsBloqued(false);
            }
        })
    }

    useEffect(()=>{
        if(cnds?.cpf !== cndsPropPF){
            setCndsPropPF(cnds.cpf);
        }

        if(cnds?.cnpj !== cndsPropPJ){
            setCndsPropPJ(cnds.cnpj);
        }
    }, [cnds])

    const validateExpiredCnds = useCallback(async () => {
        setIsLoading(true);
        try {
          await ProponentService.updateCnds(proponentId);
          const { data } = await ProponentService.getCnds(proponentId);
          handleUpdateCdns(data);
          setCndsPropPF(data.cpf || []);
          setCndsPropPJ(data.cnpj || []);
          setIsBloqued(true);
          setIsLoading(false);

          toast.success('Validação feita com sucesso');
        } catch (err) {
          toast.error('Ocorreu um erro durante a validação');
          setIsLoading(false);
          console.log(err.response);
        }
    }, [proponentId]);

    console.log("TEM DADO?",isBlankData);
    return(
        <Container>
            <Row style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}} colSize={2}>
                <Typography variant="h6" color="grayDark2">
                    Análise das CND's
                </Typography>

                {/* <Row >
                    <Button variant="primary" onClick={isLoading ? () => {} : () => validateExpiredCnds()} className="button-validate-cnds" disabled={enableConsult}>
                        <YoutubeSearchedFor />
                        {isLoading ? 'Buscando...' : 'Validar CNDs Vencidas'}
                    </Button>
                </Row> */}
            </Row>
            {isLoading && 
                <Row colSize={2}>
                    <>
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={99} />
                    </>
                </Row>
            }

            {(!isLoading && cndsPropPJ) && (
                <Row style={{gap: '0.5rem'}} >
                    <Typography variant="subtitle2" color="grayLight1">
                        CND's tipo CNPJ
                    </Typography>
                    <Row colSize={2}>
                        {
                            Object.keys(cndsPropPJ).map((cnd) => {
                                return <CardCND isDisabled={isDisabled} isPj={true} key={cnd} cnd={{...cndsPropPJ[cnd], name_cnd: cnd}} uploadCndProponent={(file, cnd) => uploadCndProponent(file, cnd) }/>
                            })
                        }
                    </Row>
                </Row>
            )}
            {cndsPropPF &&
                <Line />
            }

            {(!isLoading && cndsPropPF) && (
                <Row style={{gap: '0.5rem'}} >
                    <Typography variant="subtitle2" color="grayLight1">
                        CND's tipo CPF
                    </Typography>
                    <Row colSize={2}>
                        {
                            Object.keys(cndsPropPF).map((cnd) => {
                                return <CardCND isDisabled={isDisabled} key={cnd} cnd={{...cndsPropPF[cnd], name_cnd: cnd}} uploadCndProponent={(file, cnd) => uploadCndProponent(file, cnd) }/>
                            })
                        } 
                    </Row>
                </Row>
            )}

            {(cnds.length === 0 && !isLoading) &&
                <>
                    {enableConsult && (
                        <ul>
                            <Typography variant="subtitle1">
                                <p>Você não pode emitir as CND's por alguns desses motivos:</p>
                            </Typography>
                            <li><Typography variant="subtitle2" color="grayDark2">Sua permissão não é apta à isso.</Typography></li>
                            <li><Typography variant="subtitle2" color="grayDark2">Está faltando algum dado obrigatório no cadastro para a emissão (CPF, CNPJ, UF, CEP, Dados responsável legal)</Typography></li>
                            <li><Typography variant="subtitle2" color="grayDark2">As CND's já foram emitidas.</Typography></li>
                        </ul>
                    )}
                    <Blankdiv>Consulta não realizada!</Blankdiv>
                </>
            }  
        </Container>
    )
};

export default Cnds;