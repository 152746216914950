import React, { useCallback, useState, useRef, useEffect } from 'react';
// import Checkbox from 'components/Checkbox';
import FileInput from 'components/FileInput';
import Filename from 'components/Filename';
import Typography from 'components/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import {
  CheckContent,
  Container,
  Terms,
  ImageAndVideos,
  ShowFiles,
  FormGroup,
  Link,
  ImageAndVideosBox
} from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import Input from 'components/Input';
import { validateFileExtension, validateFileSize, validateVideoUrl } from 'utils/validation';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import './style.css';
import DragAndDrop from 'components/DragAndDrop';


const Step4 = ({ isUploading, handleSave }) => {
  const [checkboxState, setCheckboxState] = useState(false);
  const [arrayFiles, setArrayFiles] = useState([]);
  const [deleteArrayFiles, setdeleteArrayFiles] = useState([]);
  const [arrayOfficialJournal, setArrayOfficialJournal] = useState([]);
  const [deleteArrayOfficialJournal, setdeleteArrayOfficialJournal] = useState([]);

  const { setValue, getValues, control, setError, clearErrors, formState: { errors } } = useFormContext();
  const [projectPresentation, setProjectPresentation] = useState(getValues('project_presentation'));
  const [openingReceipt, setOpeningReceipt] = useState(getValues('opening_receipt'));
  const [fundOpeningReceipt, setFundOpeningReceipt] = useState(getValues('fund_opening_receipt'));
  const law_id = getValues('law_id');
  const validFund = fundOpeningReceipt && law_id > 10 ? true : false;
  const validOpening = openingReceipt && law_id <= 10 ? true : false;
  const theme = useTheme();

  const handleChangeCheckboxState = useCallback(() => {
    const account_opening_commitment = checkboxState ? 0 : 1;
    setValue('account_opening_commitment', account_opening_commitment);
    
    clearErrors("fund_opening_receipt");
    clearErrors("opening_receipt");
    
    setCheckboxState(prevState => !prevState);
  }, [checkboxState, setValue]);

  const handleDeleteOfficialJournal = useCallback(
    (name, uploadedName) => {
      setArrayOfficialJournal(arrayOfficialJournal.filter((file , index) => file.name !== name && index !== name));
      setdeleteArrayOfficialJournal([...deleteArrayOfficialJournal, uploadedName])
    },
    [arrayOfficialJournal, deleteArrayOfficialJournal],
  );

  const handleAddOfficialJournal = useCallback(
    (files, title) => {
      if (arrayOfficialJournal.length < 3) {
        setValue(title, [...arrayOfficialJournal, ...files]);
        setArrayOfficialJournal([...arrayOfficialJournal, ...files]);
      }
    },
    [arrayOfficialJournal, setValue],
  );

  const handleDeleteFile = useCallback(
    (name, uploadedName) => {
      setArrayFiles(arrayFiles.filter((file , index) => file[0].name !== name && index !== name));
      setdeleteArrayFiles([...deleteArrayFiles, uploadedName])
    },
    [arrayFiles, deleteArrayFiles],
  );

  const handleAddFiles = useCallback(
    (files, title) => {
      if (arrayFiles.length < 4) {
        setValue(title, [...arrayFiles, files]);
        setArrayFiles([...arrayFiles, files]);
      }
    },
    [arrayFiles, setValue],
  );

  const validateYoutubeUrl = useCallback(text => {
    const urlVideIsValid = validateVideoUrl(text);
    setValue('video', text)
    if (!urlVideIsValid) {
      setError('video', { message: 'URL do vídeo inválida' });
    } else {
      clearErrors('video');
    }
  }, [setError, clearErrors]);

  const mounted_checkbox = useRef();
  const account_opening_commitment = getValues('account_opening_commitment');

  useEffect(() => {
    if (!mounted_checkbox.current) {
      setCheckboxState(account_opening_commitment);
      mounted_checkbox.current = true;

      const images = getValues('images');
      if(Array.isArray(images) && images.length > 0){
        setValue('images', [...arrayFiles, ...images])
        setArrayFiles([...arrayFiles, ...images])
      }

      let official_journal = getValues('official_journal');
      if(Array.isArray(official_journal) && official_journal.length > 0){
        official_journal = official_journal.filter(n => n);
        setValue('official_journal', [...arrayOfficialJournal, ...official_journal])
        setArrayOfficialJournal([...arrayOfficialJournal, ...official_journal])
      }
    }
    setValue('official_journal', [...arrayOfficialJournal]);
    setValue('delete_images', deleteArrayFiles)
    setValue('delete_official_journal', deleteArrayOfficialJournal)
  }, [arrayFiles, setValue, arrayOfficialJournal]);

  const image = getValues('image');
  const capture_certificate = getValues('capture_certificate');
  const work_plan = getValues('work_plan');
  const project_presentation = getValues('project_presentation');
  const opening_receipt = getValues('opening_receipt');
  const fund_opening_receipt = getValues('fund_opening_receipt');
  
  //  Se for um arquivo que acabou de ser inserido é do tipo objeto, se for um q já tava no banco
  //  pega o link como string
  let image_name = typeof image === 'object' && image !== null ? image[0].name : image;
  let capture_certificate_name = typeof capture_certificate === 'object' && capture_certificate !== null ? capture_certificate[0].name : capture_certificate;
  let work_plan_name = typeof work_plan === 'object' && work_plan !== null ? work_plan[0].name : work_plan;
  let project_presentation_name = typeof project_presentation === 'object' && project_presentation !== null ? project_presentation[0].name : project_presentation;
  let opening_receipt_name = typeof opening_receipt === 'object' && opening_receipt !== null ? opening_receipt[0].name : opening_receipt;
  let fund_opening_receipt_name = typeof fund_opening_receipt === 'object' && fund_opening_receipt !== null ? fund_opening_receipt[0].name : fund_opening_receipt;

  const [deleteJournalName, setDeleteJournalName] = useState(false);
  const [deleteCaptureName, setDeleteCaptureName] = useState(false);
  const [deleteWorkName, setDeleteWorkName] = useState(false);
  const [deleteOpeningReceiptName, setDeleteOpeningReceiptName] = useState(false);
  const [deleteFundOpeningReceiptName, setDeleteFundOpeningReceiptName] = useState(false);

  const status = getValues('status');
  const isInAnalysis = status === 'Em análise';
  const isInCapture = status === 'Em captação';
  const isEncouraged = status === 'Incentivado';
  const isNotCaptured = status === 'Não captado';

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);

  return (
    <Container>
      <div>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          Vídeo, imagens e documentos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1" className="subtitle">
          Faça o upload das imagens e da documentação que permita comprovar a autenticidade do seu projeto.
          Em caso de dúvidas, confira <Link href={'https://www.youtube.com/watch?v=T50XTBCpJnM'} rel="noreferrer" target="_blank">este vídeo</Link> que explica mais sobre as documentações necessárias que
          devem ser anexadas nesta etapa!
        </Typography>
      </div>

      <ImageAndVideosBox>
        <ImageAndVideos>
          <div style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: 8 }}
              variant="h6"
              color="grayDark2"
            >
              Imagens
            </Typography>
            <Typography variant="subtitle2" color="grayLight1" className="subtitle">
              Utilize imagens para representar o seu projeto
            </Typography>
          </div>
          <div style={{ width: '100%'}}>
          {/* *Obrigatório */}
            <Typography variant="body1" color="brown" className="body-title">
              Capa do projeto - Deve ser no formato JPG e PNG e ter no max 2MB
            </Typography>
            <DragAndDrop 
              placeholder="nenhum arquivo enviado"
              onChange={files => {
                clearErrors("image");

                if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                  return setError("image", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg ou png" });
                if(!validateFileSize(files, 2))
                  return setError("image", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                setValue('image', files);
                handleSave();
              }}

              required={image_name ? false : true}
              margin={22}

              disabled={isDisabledByStatus}
              isUploading={isUploading}
              error={errors["image"]?.message}
            />
            {image_name &&
              <ShowFiles>
                <Filename
                  label={'Imagem de capa'}
                  handleDelete={() => {
                    setValue('delete_image', true)
                    setValue('image', null)
                    // setProjectPresentation(null)
                  }}
                  link={image_name}
                  disabled={true}
                />
              </ShowFiles>
            }
          </div>
          {/* *Obrigatório */}
          <div style={{ width:'100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <Typography variant="body1" color="brown" className="body-title">
              Adicione até 3 imagens sobre o seu projeto, com formatos JPG ou PNG e com tamanho max. de 2MB
              </Typography>
              <DragAndDrop
                placeholder="nenhum arquivo enviado"
                empty={arrayFiles.length === 0}
                onChange={files => {
                  clearErrors("images");

                  if(files.length + arrayFiles.length > 3)
                      return setError("images", { message: "Adicione no máximo 3 imagens." });
                  if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                    return setError("images", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg ou png" });
                  if(!validateFileSize(files, 2))
                    return setError("images", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });
                    
                  handleAddFiles(files, 'images');
                  setError("images", null);
                  handleSave();
                }}
                disabled={isDisabledByStatus}
                isUploading={isUploading}
                error={errors["images"]?.message}
              />
            </div>
            <ShowFiles>
              <div className='multiple-images'>
                {arrayFiles.length > 0 &&
                  arrayFiles.map((item , index) => (
                    <Filename
                      label={item[0].name ?? 'Imagem ' + index}
                      handleDelete={() => handleDeleteFile(item[0].name ?? index, item)}
                      link={!item[0].name ? item : null}
                      disabled={isDisabledByStatus}
                    />
                  ))}
              </div>
            </ShowFiles>
          </div>
          
        </ImageAndVideos>

        <ImageAndVideos>
          <div style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: 8 }}
              variant="h6"
              color="grayDark2"
            >
              Vídeo
            </Typography>
            <Typography variant="subtitle2" color="grayLight1" className="subtitle">
              Insira um link de vídeo que melhor explica o seu projeto!
            </Typography>
          </div>
          <div style={{ width: '100%'}}>
            <Typography variant="body1" color="brown" className="body-title">
              Link de vídeo do Vimeo ou YouTube que melhor explica o seu projeto!
            </Typography>
            <Controller
              name="video"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder="Inserir link" 
                  width="100%"
                  // {...field}
                  onChange={e => validateYoutubeUrl(e.target.value)}
                  error={errors?.video?.message}
                  value={getValues('video')}
                  disabled={isDisabledByStatus}
                />
              )}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'row', gap: '22px' }}>
          <div style={{ width:'100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <Typography variant="body1" className="body-title" color="brown">
                Apresentação do projeto - Deve ser no formato PDF e ter no max 7MB
                <InfoIcon data-for="tooltip2" data-tip="Insira aqui uma apresentação criativa do seu projeto." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
              </Typography>
              <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

              <DragAndDrop
                placeholder="nenhum arquivo enviado"
                onChange={files => {
                  clearErrors("project_presentation");

                  if(!validateFileExtension(files, ["application/pdf"]))
                    return setError("project_presentation", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf" });
                  if(!validateFileSize(files, 5))
                    return setError("project_presentation", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 5MB." });

                  setValue('project_presentation', files)
                  handleSave();
                }}
                disabled={isDisabledByStatus}
                isUploading={isUploading}
                error={errors["project_presentation"]?.message}
              />
            </div>
            <ShowFiles>
              {project_presentation_name &&
                <Filename
                  label={'Arquivo de apresentação'}
                  handleDelete={() => {
                    setValue('delete_project_presentation', true)
                    setValue('project_presentation', null)
                    setProjectPresentation(null)
                  }}
                  link={project_presentation_name}
                  disabled={isDisabledByStatus}
                />
              }
            </ShowFiles>
          </div>
        </div>
        </ImageAndVideos>
      </ImageAndVideosBox>
      

      <div style={{ marginTop: 26 }}>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          Documentos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1" className="subtitle">
          Anexe os documentos abaixo fazendo o upload dos arquivos, respeitando o formato 
          e tamanho indicado nos campos. Caso os arquivos sejam maiores que o permitido, confira 
          <Link href={'https://site.incentiv.me/wp-content/uploads/2020/12/passo_a_passo_ilovepdf_2020.pdf'} 
          rel="noreferrer" target="_blank"> aqui um passo a passo </Link> de como comprimi-los.
        </Typography>
      </div>
      
      <ImageAndVideosBox>
        <ImageAndVideos>
          <div style={{ width: '100%' }}>
            <Typography variant="body1" color="brown" className="body-title">
              Publicação diário oficial e/ou prorrogação (Formatos aceitos: PDF, JPG ou PNG c/ limite de 1 MB por arquivo sendo no máx. 2 arquivos)
              <InfoIcon 
                  data-for="tooltip3" 
                  data-tip="O Diário Oficial é o documento que dá publicidade ao projeto e é emitido pelo Municipio (se a aprovação foi por lei municipal), Estado (se a aprovação foi por lei estadual) ou União (se a aprovação foi por lei federal)." 
                  multiline={true} 
                  data-html={true} 
                  style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
            </Typography>
            <ReactTooltip id="tooltip3" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

            <DragAndDrop
              placeholder="nenhum arquivo enviado"
              empty={arrayOfficialJournal.length === 0}
              onChange={files => {
                clearErrors("official_journal");

                if(files.length + arrayOfficialJournal.length > 2)
                  return setError("official_journal", { message: "Adicione no máximo 2 arquivos." });
                if(!validateFileExtension(files, ["image/png", "image/jpeg", "application/pdf"]))
                  return setError("official_journal", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg, png ou pdf" });
                if(!validateFileSize(files, 1))
                  return setError("official_journal", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 1MB." });

                handleAddOfficialJournal(files, 'official_journal')
                handleSave();
              }}
              
              required={arrayOfficialJournal.length > 0 ? false : true}
              margin={22}
              disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
              multiple={true}
              isUploading={isUploading}
              error={errors["official_journal"]?.message}
            />
            {console.log({ errors })}
            <ShowFiles>
              <div className='multiple-images'>
                {arrayOfficialJournal.length > 0 &&
                  arrayOfficialJournal.map((item , index) => (
                    <Filename
                      label={item ? 'Arquivo ' + index : null}
                      handleDelete={() => handleDeleteOfficialJournal(item.name ?? index, item)}
                      link={item ? item : null}
                      disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                    />
                  ))}
              </div>
            </ShowFiles>
          </div>
          <div style={{ width: '100%' }}>
            <Typography variant="body1" color="brown" className="body-title">
              Plano de Trabalho ou projeto aprovado na íntegra  -  (Formatos aceitos: PDF c/ limite de 5 MB)
              <InfoIcon data-for="tooltip5" data-tip="O plano de trabalho descreve como será realizado o projeto. Nesse documento contém: resumo, objetivo geral, objetivos específicos, metodologia, justificativa e cronograma." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />

            </Typography>
            <ReactTooltip id="tooltip5" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

            <DragAndDrop
              placeholder="nenhum arquivo enviado"
              onChange={files => {
                clearErrors("work_plan");

                if(!validateFileExtension(files, ["application/pdf"]))
                  return setError("work_plan", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf" });
                if(!validateFileSize(files, 5))
                  return setError("work_plan", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 5MB." });

                setValue('work_plan', files)
                setDeleteWorkName(false);
                setError("work_plan", null);
                handleSave();
              }}
              required={work_plan_name ? false : true}
              margin={22}
              deleteName={deleteWorkName}
              disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
              isUploading={isUploading}
              error={errors["work_plan"]?.message}
            />
            {work_plan_name && (
              <>
                <Filename
                  label={'Plano de trabalho'}
                  handleDelete={() => {
                    setValue('work_plan', null);
                    setDeleteWorkName(true);
                    setValue('delete_work_plan', true)
                  }}
                  link={work_plan_name}
                />
              </>
            )}
          </div>
        </ImageAndVideos>
        <ImageAndVideos>
          {law_id && law_id <= 10 && (
            <div style={{ width: '100%' }}>
              <FormGroup style={{ gap: 0 }}>
                <Typography variant="body1" className="body-title" color="brown">
                  Comprovante de abertura de conta do projeto
                  <InfoIcon data-for="tooltip4" data-tip="O comprovante precisa ser o documento oficial emitido pela instituição bancária e precisa conter a identificação do proponente (cnpj), bem como do projeto a que está vinculada. " multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />

                </Typography>
                <ReactTooltip id="tooltip4" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                <DragAndDrop
                  placeholder="nenhum arquivo enviado"
                  // width="500px"
                  onChange={files => {
                    clearErrors("opening_receipt");

                    if(!validateFileExtension(files, ["application/pdf"]))
                      return setError("opening_receipt", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf" });
                    if(!validateFileSize(files, 5))
                      return setError("opening_receipt", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 5MB." });
                    
                    setDeleteOpeningReceiptName(false);
                    setValue('opening_receipt', files)
                    handleSave();
                  }}
                  
                  required={checkboxState ? false : (getValues('opening_receipt') == null ? true : false)}
                  margin={22}
                  deleteName={deleteOpeningReceiptName}
                  disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                  isUploading={isUploading}
                  error={errors["opening_receipt"]?.message}
                />
              </FormGroup>
              <ShowFiles>
                  {opening_receipt_name &&
                    <Filename
                      label={'Comprovante de abertura'}
                      handleDelete={() => {
                        setValue('opening_receipt', null)
                        setValue('delete_opening_receipt', true)
                        setDeleteOpeningReceiptName(true);
                      }}
                      link={opening_receipt_name}
                      disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                    />
                  }
              </ShowFiles>
            </div>
          )}

          {law_id && law_id > 10 && (
            <div style={{ width: '100%' }}>
              <FormGroup style={{ gap: 0 }}>
                <Typography variant="body1" className="body-title" color="brown">
                  Comprovante de dados bancários do fundo
                </Typography>
                <DragAndDrop
                  placeholder="nenhum arquivo enviado"
                  // width="500px"
                  onChange={files => {
                    clearErrors("fund_opening_receipt");

                    if(!validateFileExtension(files, ["application/pdf"]))
                      return setError("fund_opening_receipt", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf" });
                    if(!validateFileSize(files, 5))
                      return setError("fund_opening_receipt", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 5MB." });
                    setDeleteFundOpeningReceiptName(false);
                    setValue('fund_opening_receipt', files)
                    setError("fund_opening_receipt", null);
                    handleSave();
                  }}
                  required={checkboxState ? false : (fundOpeningReceipt ? false : true)}
                  margin={22}
                  deleteName={deleteFundOpeningReceiptName}
                  disabled={isDisabledByStatus}
                  isUploading={isUploading}
                  error={errors["fund_opening_receipt"]?.message}
                />
              </FormGroup>
              <ShowFiles>
                  {fund_opening_receipt_name &&
                    <Filename
                      label={'Comprovante de dados bancários'}
                      handleDelete={() => {
                        setValue('fund_opening_receipt', null)
                        setValue('delete_fund_opening_receipt', true)
                        setDeleteFundOpeningReceiptName(true);
                      }}
                      link={fund_opening_receipt_name}
                      disabled={isDisabledByStatus}
                    />
                  }
              </ShowFiles>
            </div>
          )}
          <div style={{ width: '100%' }}>
            <Typography variant="body1" color="brown" className="body-title">
              Certificado de captação (opcional)  -  (Formatos aceitos: PDF c/ limite de 2 MB)
              <InfoIcon data-for="tooltip6" data-tip="Não é o Diário Oficial. É o documento que certifica que o seu projeto está apto a captar recursos. Por não ser um documento obrigatório, não são todas as leis que possuem." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
            </Typography>
            <ReactTooltip id="tooltip6" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

            <DragAndDrop
              placeholder="nenhum arquivo enviado"
              onChange={files => {
                clearErrors("capture_certificate");

                if(!validateFileExtension(files, ["application/pdf"]))
                  return setError("capture_certificate", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf" });
                if(!validateFileSize(files, 2))
                  return setError("capture_certificate", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                setValue('capture_certificate', files)
                setDeleteCaptureName(false);
                handleSave();
              }}
              disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
              isUploading={isUploading}
              deleteName={deleteCaptureName}
              error={errors["capture_certificate"]?.message}
            />
            {capture_certificate_name && (
              <>
                <Filename
                  label={'Certificado de captação'}
                  handleDelete={() => {
                    setValue('capture_certificate', null);
                    setDeleteCaptureName(true);
                    setValue('delete_capture_certificate', true)
                  }}
                  link={capture_certificate_name}
                />
              </>
            )}
          </div>
        </ImageAndVideos>
      </ImageAndVideosBox>

      {!validFund && !validOpening &&(
        <>
          <div style={{ marginTop: 26 }}>
            <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
              Compromisso de abertura de conta do projeto 
              { !checkboxState && (getValues('opening_receipt') == null && getValues('fund_opening_receipt') == null) &&
                <span style={{color: 'red', fontSize: '12px', fontWeight: 'normal'}}>Obrigatório</span>
              }
            </Typography>
          </div>
          <CheckContent>
            <Checkbox
              checked={checkboxState}
              onClick={handleChangeCheckboxState}
              style={{ padding: 0 }}
              disabled={isDisabledByStatus}
            />
            <Terms disabled={isDisabledByStatus}>
              <Typography variant="body1" color="grayDark2" onClick={() => handleChangeCheckboxState()} className="subtitle">
                Eu declaro que ainda não possuo a conta aberta/termo de abertura de
                conta do projeto, mas que me comprometo a abrir a conta e enviar o
                termo de abertura para a Incentiv.me assim que solicitado.
              </Typography>
            </Terms>
          </CheckContent>
        </>
      )}
    </Container>
  );
};

export default Step4;
