import api from './api';

const PATH = '/auth';

const login = data => api.post(`${PATH}/login`, data);
const logout = () => api.post(`${PATH}/logout`);
const verifyToken = token => api.get(`${PATH}/verify-token`, { params: token });
const forgotPassword = email => api.post(`${PATH}/forget-password`, { email: email });
const resetPassword = (token, data) => api.post(`${PATH}/reset-password`, data, { 
  params: { 
    token_recover_password: token
  }
});
const changePassword = data => api.post(`${PATH}/change-password`, data);

export const LoginService = {
  login,
  logout,
  verifyToken,
  forgotPassword,
  resetPassword,
  changePassword
};