import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, FormHelperText, InputLabel, Select, TextField } from '@material-ui/core';
import Typography from 'components/Typography';
// import Chip from 'components/Chip';
import { validateBirthDate } from 'utils/validation';
import Input from 'components/Input';
import BasicDatePicker from 'components/DatePicker';
import { IconExclude } from 'assets/icons';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { AddCaptureModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';
import InfoIcon from '@material-ui/icons/Info';

import { useUser } from 'hooks/useUser';
import { account_bank, agency_bank } from 'utils/maskUtils';

// import Checkbox from 'components/Checkbox';
import {
  Container,
  Options,
  OptionsContent,
  ProjectText,
  ContentChip,
  ContentInputs,
  AddButton,
  ContentDate,
  RowCapture,
} from './styles';
import { isDate, parse } from 'date-fns';
import formatCurrency, { currencyMask, currencyMaskStore } from 'utils/currency';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import ModalInformation from 'components/ModalInformation';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import './style.css';
import { Autocomplete } from '@material-ui/lab';
import { v4 } from 'uuid';

export const DATE_FORMAT = 'dd/MM/yyyy';

const Step5 = ({ id }) => {
  const [statePartiallyOccupied, setStatePartiallyOccupied] = useState();
  const [statePartiallyOccupiedYes, setStatePartiallyOccupiedYes] = useState();
  const [statePartiallyOccupiedNo, setStatePartiallyOccupiedNo] = useState();
  const [mainSupportersOptional, setMainSupportersOptional] = useState('');
  const [mainSupporters, setMainSupporters] = useState('');
  const [mainSupportersArray, setMainSupportersArray] = useState([]);
  const [banks, setBanks] = useState(null);
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const [
    mainSupportersArrayOptional,
    setMainSupportersArrayOptional,
  ] = useState([]);
  const { control, setValue, clearErrors, getValues, formState: { errors } } = useFormContext();
  const { user } = useUser();
  
  const opening_receipt = getValues('opening_receipt');
  const fund_opening_receipt = getValues('fund_opening_receipt');
  const bank_id = getValues('bank_id');
  const handleChangePartiallyOccupied = useCallback(
    value => {
      setStatePartiallyOccupied(value);
      setValue('partially_captured', value);
    },
    [setValue],
  );

  const handleChangePartiallyOccupiedYes = useCallback(() => {
    setStatePartiallyOccupiedYes(prevState => !prevState);
    setStatePartiallyOccupiedNo(false);
  }, []);
  const handleChangePartiallyOccupiedNo = useCallback(() => {
    setStatePartiallyOccupiedNo(prevState => !prevState);
    setStatePartiallyOccupiedYes(false);
  }, []);

  const handleAddMainSupportersOptional = useCallback(() => {
    if (mainSupportersOptional) {
      setMainSupportersArrayOptional([
        ...mainSupportersArrayOptional,
        mainSupportersOptional,
      ]);
      setMainSupportersOptional('');
    }
  }, [mainSupportersArrayOptional, mainSupportersOptional]);

  const handleAddMainSupporters = useCallback(() => {
    setMainSupportersArray([...mainSupportersArray, mainSupporters]);
    setMainSupporters('');
  }, [mainSupportersArray, mainSupporters]);

  const handleDeleteMainSupportersArrayOptional = chipToDelete => {
    setMainSupportersArrayOptional(chips =>
      chips.filter(chip => chip !== chipToDelete),
    );
  };

  const handleDeleteMainSupportersArray = chipToDelete => {
    setMainSupportersArray(chips =>
      chips.filter(chip => chip !== chipToDelete),
    );
  };

  const [state, setState] = useState('');

  const maskDate = v => {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{2})(\d)/, '$1/$2');
    v = v.replace(/(\d{2})(\d)/, '$1/$2');

    v = v.replace(/(\d{2})(\d{1,2,3,4})$/, '$1/$2');
    v = v.replace(/(\d{4})\d+?$/, '$1');

    return v;
  };

  const [formattedValue, setFormattedValue] = useState(0);
  const [formattedMinimunValue, setFormattedMinimumValue] = useState(0);
  const [approvedByLaw, setApprovedByLaw] = useState(0);
  const [valueCaptured, setValueCaptured] = useState(0);

  const mounted_sponsors = useRef();
  const sponsors = getValues('sponsors');
  let exploded_sponsors = sponsors ? sponsors.split(',') : [];
  const valueAproved = formatCurrency(getValues('approved_value'));
  const [valueAprovedCaptured, setValueAprovedCaptured] = useState(getValues('captured_value') ?? null);
  const minimumValue = formatCurrency(getValues('minimum_value'), false);
  const url = (window.location.href).split('/');
  
  const mounted_previous_sponsors = useRef();
  const previous_sponsors = getValues('previous_sponsors');
  let exploded_previous_sponsors = previous_sponsors ? previous_sponsors.split(',') : [];

  useEffect(() => {
    if (!mounted_previous_sponsors.current) {
      setMainSupportersArrayOptional(exploded_previous_sponsors);
      mounted_previous_sponsors.current = true;
    } else {
      setValue('previous_sponsors', mainSupportersArrayOptional.join(','));
    }

  }, [setValue, mainSupportersArrayOptional]);

  useEffect(() => {
    if (!mounted_sponsors.current) {
      setMainSupportersArray(exploded_sponsors);
      mounted_sponsors.current = true;
    } else {
      setValue('sponsors', mainSupportersArray.join(','));
    }
  }, [setValue, mainSupportersArray]);

  useEffect(async () => {
    const { data } = await ProjectService.listBanks();

    setBanks(data.map(bank => ({
      label: bank.name,
      value: bank.id,
      code: bank.code
    })));
    
    let approved_value = getValues('approved_value') ?? null;
    if(approved_value){
      approved_value = approved_value.toLocaleString('pt-br', {minimumFractionDigits: 2})
      setFormattedValue(approved_value);
    }

    let minimum_value = getValues('minimum_value') ?? null;
    if(minimum_value){
      minimum_value = minimum_value.toLocaleString('pt-br', {minimumFractionDigits: 2});
      setFormattedMinimumValue(minimum_value);
    }
  }, []);

  const updateCapturedValueProject = useCallback(async (payload) => {
    try {
      const response = await ProjectService.updateCapturedValueProject(id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setShowModalInformation(false);
        setModalOpen('');
        toast.success('Captação atualizada com sucesso!');

        const updatedValue = Number(payload.captured_value) + getValues('captured_value');
        setValueAprovedCaptured(updatedValue)
        setValue('captured_value', updatedValue)
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar a captação do projeto');
      }
    }
  }, [modalInformationData]);

  const modalRender = useMemo(() => {
    const modal = {
      addCapture: (
        <AddCaptureModal
          open={modalOpen === 'addCapture'}
          handleClose={() => setModalOpen('')}
          onChange={(captured_value, sponsor_id, date) => {
            updateCapturedValueProject({ captured_value, sponsor_id, date })
          }}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);

  const status = getValues('status');

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);
  
  const isDisabledFunc = () => {
    if(!user.isAdmin){
      return isDisabledByStatus;
    }

    else {
      if(user.adminRole !== 'general'){
        return false;
      }
      else{
        return false;
      }
    }
  }

  console.log("FUNC", isDisabledFunc());
  const theme = useTheme();
  return (
    <Container>
      <div>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          Valor do projeto e incentivos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Insira as informações referentes aos valores do projeto.
        </Typography>
      </div>
      <ContentInputs>
        <div style={{ width: '30%' }}>
          {/* *Obrigatório */}

          { (getValues('approved_value') <= 0 || getValues('approved_value') == null) &&
            <InputLabel style={{color: 'red', marginLeft: '11.5%', marginTop: '-5px', backgroundColor: '#fff', position: 'absolute', fontSize: '12px', zIndex: 99}} id="select-label">Obrigatório</InputLabel>
          }

          <Controller
            name="approved_value"
            control={control}
            render={({ field }) => {
              return (
              <Input
                label="Valor aprovado em lei"
                {...field}
                onChange={e => {
                  setValue('approved_value', currencyMaskStore(e.target.value))
                  setFormattedValue(currencyMask(e.target.value))
                  clearErrors("approved_value");
                }}

                value={url[3] === 'projetos-registro' ? `R$ ${formattedValue}` : (formattedValue !== 0 ? `R$ ${formattedValue}` : valueAproved)}
                disabled={isDisabledByStatus}
                
                error={errors["approved_value"]?.message}
              />
            )
          }}
          />
        </div>
        <div style={{ width: '30%' }}>
          {/* *Obrigatório */}
          { (getValues('minimum_value') <= 0 || getValues('minimum_value') == null) &&
            <InputLabel style={{color: 'red', marginLeft: '11.5%', marginTop: '-5px', backgroundColor: '#fff', position: 'absolute', fontSize: '12px', zIndex: 99}} id="select-label">Obrigatório</InputLabel>
          }
          <Controller
            name="minimum_value"
            control={control}
            render={({ field }) => (
              <Input 
                label="Valor mínimo de execução"
                {...field}
                onChange={e => {
                  setValue('minimum_value', currencyMaskStore(e.target.value))
                  setFormattedMinimumValue(currencyMask(e.target.value))
                  clearErrors("minimum_value");
                }}

                value={url[3] === 'projetos-registro' ? `${formattedMinimunValue}` : (formattedMinimunValue !== 0 ? `${formattedMinimunValue}` : minimumValue)}
                disabled={isDisabledByStatus}
                error={errors["minimum_value"]?.message}
                data-tip="indique o valor mínimo de execução do projeto <br/>conforme a lei de aprovação prevê." 
                data-html={true}
                data-for="tooltip1"
                data-event='click focus'
              />
            )}
          />
          <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

        </div>

        <ContentDate>
          {/* *Obrigatório */}
          <DatePicker
            label="Data de aprovação"
            onChange={value => {
              // const dateValue = value.toISOString().substring(0, 10);
              const dateValue = value.toLocaleDateString("en-CA");

              setValue('approval_date', dateValue);
              clearErrors("approval_date");
            }}
            required={true}
            margin={5.5}
            value={getValues('approval_date')}
            defaultValue={getValues('approval_date')}
            disabled={isDisabledByStatus}
            error={Boolean(errors["approval_date"])}
          />

          {errors["approval_date"] && <FormHelperText error>{errors["approval_date"]?.message}</FormHelperText>}
        </ContentDate>

        <div style={{ width: '18%' }}>
          {/* *Obrigatório */}
          <DatePicker
            label="Prazo de captação"
            onChange={value => {
              // const dateValue = value.toISOString().substring(0, 10);
              const dateValue = value.toLocaleDateString("en-CA");

              setValue('funding_period', dateValue);
              clearErrors("funding_period");
            }}
            required={true}
            margin={5.5}
            defaultValue={getValues('funding_period')}
            disabled={isDisabledByStatus}
            error={Boolean(errors["funding_period"])}
          />

          {errors["funding_period"] && <FormHelperText error>{errors["funding_period"]?.message}</FormHelperText>}
          {/* {getValues('funding_period_has_passed') && (
            <>
              <InfoIcon data-for="tooltip2" data-tip="O prazo de captação expirou" multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute'}} />
              <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
            </>
          )} */}
        </div>
      </ContentInputs>
      <div style={{ display: 'flex', marginTop: 27 }}>
        <Input
          // width="673px"
          label="Principais incentivadores das edições anteriores"
          id="previous_sponsors"
          value={mainSupportersOptional}
          onChange={e => setMainSupportersOptional(e.target.value)}
          disabled={isDisabledByStatus}
        />
        {/* <AddButton onClick={handleAddMainSupportersOptional} disabled={isDisabledByStatus}>+</AddButton> */}
      </div>
      <ContentChip>
        {mainSupportersArrayOptional.map(item => (
          <Chip
            variant="outlined"
            label={item}
            onDelete={() => handleDeleteMainSupportersArrayOptional(item)}
            disabled={isDisabledByStatus}
          />
        ))}
      </ContentChip>
      
      {/* <ProjectText>
        <Typography variant="body1" color="blueGrayish56">
          Projeto parcialmente captado?
        </Typography>
      </ProjectText>
      <OptionsContent>
        <Options>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonCheckedIcon />}
            rounded
            color="primary"
            checked={getValues('partially_captured') === 1}
            onClick={() => handleChangePartiallyOccupied(1)}
            disabled={isDisabledByStatus}
          />
          <p>Sim</p>
        </Options>
        <Options>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonCheckedIcon />}
            rounded
            color="primary"
            checked={getValues('partially_captured') === 0}
            onClick={() => handleChangePartiallyOccupied(0)}
            disabled={isDisabledByStatus}
          />
          <p>Não</p>
        </Options>
      </OptionsContent> */}


      {/* {getValues('partially_captured') === 1 && ( */}
        <>
          <div style={{ marginTop: 30 }}>
            <Controller
              name="captured_value"
              control={control}
              render={({ field }) => {
                return (
                  <RowCapture>
                    <Input
                      label="Valor captado até o momento"
                      {...field}
                      onChange={e => {
                        setValue('captured_value', currencyMaskStore(e.target.value))
                        setValueCaptured(currencyMask(e.target.value))
                      }}
                      required={true}
                      margin={20}
                      value={formatCurrency(valueAprovedCaptured)}
                      disabled={true}
                      data-tip="indique o valor total captado pelo seu projeto até <br/>
                      o momento do cadastro. Essa informação é importante para
                      sabermos o saldo de captação disponível." 
                      data-html={true}

                      data-for="tooltip1"
                      data-event='click focus'
                    />
                    <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                    {/* <Button 
                      type="button" 
                      onClick={() => setModalOpen('addCapture')}
                      disabled={isDisabledByStatus}>Adicionar captação</Button> */}
                  </RowCapture>
              )}}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 38 }}>
            <Input
              // width="606px"
              label="Principais incentivadores"
              id="sponsors"
              value={mainSupporters}
              onChange={e => setMainSupporters(e.target.value)}
              disabled={isDisabledByStatus}
            />
            {/* <AddButton onClick={handleAddMainSupporters} disabled={isDisabledByStatus}>+</AddButton> */}
          </div>

          <ContentChip>
            {mainSupportersArray.map(item => (
              <Chip
                variant="outlined"
                label={item}
                onDelete={() => handleDeleteMainSupportersArray(item)}
                disabled={isDisabledByStatus}
              />
            ))}
          </ContentChip>
          {modalRender}
          <ModalInformation
            open={showModalInformation}
            handleClose={() => setShowModalInformation(false)}
            {...modalInformationData}
          />
        </>
      {/* )} */}


      {(opening_receipt !== null || fund_opening_receipt !== null) &&
        <>
          <div>
            <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
              Dados para depósito
            </Typography>
            <Typography variant="subtitle2" color="grayLight1">
              Insira as informações referentes aos dados bancários para depósito.
            </Typography>
          </div>

          <ContentInputs>
            <div style={{ width: '30%' }}>
              {banks && (
                <Autocomplete
                  name="bank_id"
                  id="bank-select-demo"
                  options={banks}
                  renderOption={(option) => (
                    <li key={v4()}>{option.code} - {option.label}</li>
                  )}
                  getOptionLabel={(option) => option.label}
                  disabled={isDisabledFunc()}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      label="Escolha um banco"
                      height={'38px'}
                      inputProps={{ 
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      disabled={isDisabledFunc()}
                    />
                  )}
                  defaultValue={banks?.find(bank => bank.value == bank_id)}
                  onChange={(event, newValue) => {
                    setValue('bank_id', newValue.value);
                  }}
                />
              )}
              
            </div>

            <div style={{ width: '30%' }}>
              <Controller
                name="agency"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Agência"
                      min={0}
                      {...field}
                      onChange={e => {
                        const value = agency_bank(e.target.value);
                        setValue('agency', value);
                      }}

                      disabled={isDisabledFunc()}
                      error={errors["agency"]?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ width: '30%' }}>
              <Controller
                name="account_bank"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Conta corrente"
                      min={0}
                      disabled={isDisabledFunc()}
                      {...field}
                      onChange={e => {
                        const value = account_bank(e.target.value);
                        setValue('account_bank', value);
                      }}                  
                    />
                  );
                }}
              />
            </div>
          </ContentInputs>
        </>
      }

    </Container>
  );
};

export default Step5;
