import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const notificationSchemaValidation = Yup.object().shape({
  title: Yup.string().required('Assunto obrigatório'),
  body: Yup.string().required('Mensagem obrigatório'),
});

const notificationAdminSchemaValidation = Yup.object().shape({
  title: Yup.string().required('Assunto obrigatório'),
  body: Yup.string().required('Mensagem obrigatório'),
  receiverId: Yup.string().required('Proponente obrigatório'),
  senderId: Yup.string().required('Remetente obrigatório'),
});

export const notificationValidationResolver = yupResolver(
  notificationSchemaValidation,
);
export const notificationAdminValidationResolver = yupResolver(
  notificationAdminSchemaValidation,
);
