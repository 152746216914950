import React from 'react';
import ProjectSteps from '../../ProjectSteps';
import Typography from 'components/Typography';
import { Container, Content } from './styles';

const SideBar = props => {
  return (
    <Container style={{width: '31%'}}>
      <Content>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="black">
          Seções
        </Typography>
        <Typography style={{ width: '83%' }} variant="subtitle2" color="grayDark2">
          Estas são as informações cadastradas do projeto.
        </Typography>
      </Content>
      <div>
        <ProjectSteps activeStep={props.activeStep} handleStep={props.handleStep} proponent_id={props.proponent_id} project_status={props.project_status}/>
      </div>
    </Container>
  );
};

export default SideBar;
