import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from 'components/Button';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';


const CreateProject = ({ open, handleClose, onChange, isAdmin = false }) => {
  const [nameProject, setNameProject] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { push } = useHistory();

  const redirectToProject = useCallback((idProject) => {
    if(isAdmin){
      push('projetos/'+idProject);
    }
    else{
      push('projetos/'+idProject+'?step=4');
    }
    // if(isAdmin)
    // else
    //   window.open('projetos/'+idProject+'?step=4', "_self");
  },[]);
  
  const addProject = async() => {
    const form = new FormData();

    form.append('name', nameProject);
    setIsSaving(true);

    try{
      const response = await ProjectService.addProject(form);
      if (response.status === 201) {
        toast.success('Projeto salvo com sucesso!');
        setTimeout(() => {
          redirectToProject(response.data[0].id);
        }, 1000);
      } else {
        toast.error("Falha ao salvar projeto.");
        setIsSaving(false);
        return;
      }
    } 
    
    catch(err){
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao salvar projeto');
      }
    }

    console.log(form);

  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Criar projeto
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Input label="Nome do projeto" onChange={e => setNameProject(e.target.value)} autoFocus focused={true}/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="secondary">
          Cancelar
        </Button>
        <Button onClick={isSaving ? () => {} : () => addProject()} type="button">
          {!isSaving ? 'Salvar' : 'Salvando...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateProject;