import { makeStyles, Select, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Cabin',
    bottom: 10,
    left: 10,
    position: 'relative',
    color: '#12203F',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

export const DropDownSelect = styled(Select)`
  border-radius: 15px !important;
  border: 1px solid ${props => props.theme.colors.background};
  height: 36px;
  color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.typography.fontFamily};
  ${props => props.theme.typography.span2};
`;

export const SelectItem = styled(MenuItem)`
  border: none;
  color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.typography.fontFamily};
  ${props => props.theme.typography.span2};
  background-color: ${props => props.theme.colors.white};
`;

export const Container = styled.div`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input, .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    padding: 0 0 0 5px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0.625rem;
  }

  .MuiOutlinedInput-root {
    height: 48px;   
  }

  .MuiAutocomplete-inputRoot{
    padding: 8px 14px!important;
  }

  // remove o botão de clear padrão do input
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;