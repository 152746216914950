import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.grayDark1};
    cursor: pointer;
  }

  @media (max-width: 960px){
    display: flex;
    word-break: break-word;
  }
`;
