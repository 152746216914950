import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-family: ${props => props.theme.typography.fontFamily};
  margin-top: 25px;
`;

export const DateRangePicker = styled(DatePicker)`
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.typography.fontFamily};
  height: 36px;
  padding: 10px;
`;

export const Button = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.typography.fontFamily};
  padding: 5px 10px;
  border-radius: 15px;
  margin: 0 20px;
  height: 36px;
`;

export const ButtonLabel = styled.span`
  ${props => props.theme.typography.span2};
  color: ${props => props.theme.colors.white};
`;
