import React, { useCallback, useEffect, useState } from 'react';
import RegisterLaw from 'components/Forms/RegisterLaw';
import { useParams } from 'react-router-dom';
import { LawsService } from 'services/laws';

const LawDetails = () => {
  const { id } = useParams();
  const [law, setLaw] = useState();

  const loadLawInfo = useCallback(async () => {
    const response = await LawsService.showLaw(id);

    if (response.data) {
      const lawData = {
        id: response.data.id,
        name: response.data.name,
        type: response.data.type,
        coverage: response.data.coverage,
        category_law: response.data.category_law,
        state: response.data.state,
        city: response.data.city,
        number: response.data.number,
        law_created_at: response.data.law_created_at,
        release_to_laws_list: response.data.release_to_laws_list,
      };
      setLaw(lawData);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      loadLawInfo();
    }
  }, [id]);

  return (
    <div>
      {law && <RegisterLaw law={law} id={id} law_created_at={law?.law_created_at}/>}
    </div>
  );
};

export default LawDetails;
