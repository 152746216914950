import styled from 'styled-components';
import { pixelsToRem } from 'utils/styles';
import { rgba } from 'polished';

export const Container = styled.div`
  border-radius: ${pixelsToRem(30)};
  padding: 1.5rem;
  background: ${({ theme }) => rgba(theme.colors.grayLight3, 0.1)};
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  gap: 0.5rem;
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const MessageContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  a {
    color: #5c5050;
  }
`;

export const Link = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;
`;
