const INITIAL_STATE = {
  statusMilestones: false,
  dataMilestones: {
    description: '',
    date: undefined,
    executionProof: undefined,
  },
  statusCounterparts: false,
  dataCounterparts: {
    description: '',
    date: undefined,
    executionProof: undefined,
  },
  statusCounterpartsReview: false,
  dataCounterpartsReview: {
    description: '',
    approved: true,
    failureReason: '',
  },
  statusFilter: false,
  dataFilter: {
    startDate: undefined,
    endDate: undefined,
    selectedProjects: [],
    selectedGoals: [],
    selectedLaws: [],
    selectedState: [],
    selectedCity: [],
    selectedCategory: [],
    selectedSubcategories: [],
  },
  vitrineModals: {
    addCaptation: false,
    changeBidder: false,
    changeStatus: false,
    deleteProjectBidder: false,
    indicateTo: false,
  },
  usersModal: {
    users: {},
    isOpen: false,
    isEdit: false,
    deleteUserModal: false,
    successModal: false,
    bidersModal: {
      open: false,
      deleteMiddleware: false
    }
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  const updateModal = state;
  switch (action.type) {
    case '@modals/@TOGGLE_MILESTONES_MODAL':
      updateModal.statusMilestones = !state.statusMilestones;
      updateModal.dataMilestones.description = action.payload?.description;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_ACTION_MILESTONES_MODAL':
      const { date, executionProof } = action.payload;
      updateModal.dataMilestones.date = date;
      updateModal.dataMilestones.executionProof = executionProof;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_CLEAR_DATA_MILESTONES_MODAL':
      updateModal.dataCounterparts.date = undefined;
      updateModal.dataCounterparts.executionProof = undefined;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_COUNTERPARTS_MODAL':
      updateModal.statusCounterparts = !state.statusCounterparts;
      updateModal.dataCounterparts.description = action.payload?.description;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_ACTION_COUNTERPARTS_MODAL':
      updateModal.dataCounterparts.date = action.payload.date;
      updateModal.dataCounterparts.executionProof =
        action.payload.executionProof;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_CLEAR_DATA_COUNTERPARTS_MODAL':
      updateModal.dataCounterparts.date = undefined;
      updateModal.dataCounterparts.executionProof = undefined;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_COUNTERPARTS_REVIEW_MODAL':
      updateModal.statusCounterpartsReview = !state.statusCounterpartsReview;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_ACTION_COUNTERPARTS_REVIEW_MODAL':
      const { approved, failureReason } = action.payload;
      updateModal.dataCounterpartsReview.approved = approved;
      updateModal.dataCounterpartsReview.failureReason = failureReason;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_CLEAR_DATA_COUNTERPARTS_REVIEW_MODAL':
      updateModal.dataCounterpartsReview.approved = true;
      updateModal.dataCounterpartsReview.failureReason = '';
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_FILTER_MODAL':
      updateModal.statusFilter = !state.statusFilter;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_ACTION_FILTER_MODAL':
      updateModal.dataFilter = action.payload;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@ON_CLEAR_DATA_FILTER_MODAL':
      updateModal.dataFilter = {
        startDate: undefined,
        endDate: undefined,
        selectedProjects: [],
        selectedGoals: [],
        selectedLaws: [],
        selectedState: [],
        selectedCity: [],
        selectedCategory: [],
        selectedSubcategories: [],
      };
      return {
        ...state,
        ...updateModal,
      };

    case '@modals/@ADD_CAPTATION':
      updateModal.vitrineModals.addCaptation = !state.vitrineModals
        .addCaptation;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_CREATE_USER_MODAL':
      updateModal.usersModal.isOpen = !state.usersModal.isOpen;
      updateModal.usersModal.isEdit = false;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@CHANGE_BIDDER':
      updateModal.vitrineModals.changeBidder = !state.vitrineModals
        .changeBidder;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_EDIT_USER_MODAL':
      updateModal.usersModal.isOpen = !state.usersModal.isOpen;
      updateModal.usersModal.isEdit = !state.usersModal.isEdit;
      updateModal.usersModal.users = action.payload;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_CHANGE_ADMIN_ROLE_MODAL':
      updateModal.usersModal.isOpen = !state.usersModal.isOpen;
      updateModal.usersModal.isEdit = !state.usersModal.isEdit;
      updateModal.usersModal.users = action.payload;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@CHANGE_STATUS':
      updateModal.vitrineModals.changeStatus = !state.vitrineModals
        .changeStatus;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@DELETE_PROJECT_BIDDER':
      updateModal.vitrineModals.deleteProjectBidder = !state.vitrineModals
        .deleteProjectBidder;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_DELETE_USER_MODAL':
      updateModal.usersModal.deleteUserModal = !state.usersModal
        .deleteUserModal;
      updateModal.usersModal.isEdit = false;
      updateModal.usersModal.isOpen = false;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_BIDDERS_MODAL':
      updateModal.usersModal.bidersModal = {
        open: !state.usersModal.bidersModal.open,
        deleteMiddleware: Boolean(action.payload.deleteMiddleware)
      };
      updateModal.usersModal.users = action.payload;
      updateModal.usersModal.isEdit = false;
      updateModal.usersModal.isOpen = false;
      return {
        ...state,
        ...updateModal
      }
    case '@modals/@INDICATE_TO':
      updateModal.vitrineModals.indicateTo = !state.vitrineModals.indicateTo;
      return {
        ...state,
        ...updateModal,
      };
    case '@modals/@TOGGLE_CREATE_SUCCESS_MODAL':
      updateModal.usersModal.isOpen = false;
      updateModal.usersModal.isEdit = state.usersModal.isEdit;
      updateModal.usersModal.successModal = !state.usersModal.successModal;
      return {
        ...state,
        ...updateModal,
      };
    default:
      return state;
  }
};

export default reducer;
