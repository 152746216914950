import React, { useEffect, useCallback } from 'react';
import Login from 'components/Login';
import Logo1 from 'assets/images/logo.png';
import Logo2 from 'assets/images/logo_inferior.svg';
import { Container, ImageLogo, ImageFooter } from './styles';
import { useDispatch } from 'react-redux';
import { register } from 'store/ducks/register/action';
import { useLocation } from 'react-router-dom';
import { UsersService } from 'services/users';
import { toast } from 'react-toastify';

export default function Home() {
  const location = useLocation();

  const dispatch = useDispatch();

  const clearStorage = useCallback(
    () =>
      dispatch(
        register({
          profile: {
            type: 'default',
            isActive: false,
          },
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    clearStorage();
    confirmEmail();
  }, [clearStorage]);

  const confirmEmail = useCallback(async () => {
    const myArray = (location.pathname).split("/");

    if(myArray.length === 3) {
      const token = myArray[2];
      const response = await UsersService.confirmEmailAndCreate(token);

      if(response.data[0].name) {
        toast.success('Email confirmado com sucesso')
        location.pathname = '/meus-dados';
      } else {
        toast.error('Ocorreu um erro')
      }
    }
  }, []);

  return (
    <Container>
      <div className="image" style={{width: '100%'}}>
        <ImageLogo src={Logo1}></ImageLogo>
      </div>
      <Login />
      <div className="image" style={{width: '100%'}}>
        <ImageFooter src={Logo2}></ImageFooter>
      </div>
    </Container>
  );
}
