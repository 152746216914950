import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
  background: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.white)};
`;
