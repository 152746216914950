import React from 'react';

import { Container, Content, Title } from './styles';
import { IconExclude } from 'assets/icons';
import Typography from 'components/Typography';

const Chip = ({ label, width, onClick, disabled }) => {
  return (
    <Container width={width}>
      <Content>
        <Title>
          <Typography variant="manrope">{label}</Typography>
        </Title>
        <div
          style={{
            marginTop: 4,
            marginLeft: 4,
            marginRight: 4,
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
        {/* {!disabled && <IconExclude width={18} height={18} />} */}
        </div>
      </Content>
    </Container>
  );
};

export default Chip;
