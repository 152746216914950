import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 50px 0 24px;
  max-height: 800px;
  overflow-y: auto;
`;

export const ButtonAddCounterPart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 240px;
  height: 40px;

  background: rgba(106, 6, 164, 0.05);
  border-radius: 30px;
  border: 0;

  &:hover {
    opacity: 0.8;
  }
  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 1450px){
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const DivCotas = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: 1450px){
    :first-child > div:first-child{
      width: 50%!important;
    }

    :first-child > div:nth-child(2){
      width: 25%!important;
    }

    :first-child > div:last-child{
      width: 25%!important;
    }
  }
`;

export const ButtonAddText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-left: 10px;

  color: ${({ theme }) => theme.colors.purple};
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 1450px){
    display: block;
    text-align: center;
  }
`;
