import React from 'react';
import PageTitle from 'components/PageTitle';

import { Container, MainContainer } from './styles';

const Terms = () => {
  return (
    <MainContainer>
      <Container>
        <div className="main">
          <div className="center">
              <b>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA</b>
          </div>
          <div>
              <p>Estes termos e condições de uso (“Termos”) regulam o uso do website <a href='https://site.Incentiv/' target='_blank'>https://site.Incentiv/</a> e da plataforma da <b>Incentiv</b> (em conjunto denominados “Plataforma”), pelo Usuário, sendo que qualquer uso da Plataforma pelo Usuário constituirá seu aceite com estes Termos. </p>
          </div>
          <div>
              <b>ACEITAÇÃO DOS TERMOS E FUTURAS ALTERAÇÕES:</b>
          </div>
          <div>
              <p>ANTES DE UTILIZAR A PLATAFORMA, O USUÁRIO DEVERÁ LER ATENTAMENTE ESTE TERMO. O USUÁRIO CONFIRMA QUE, AO SE CADASTRAR E UTILIZAR QUAISQUER DAS FUNCIONALIDADES DA PLATAFORMA, TERÁ LIDO, ENTENDIDO E ACEITADO TODOS OS TERMOS E CONDIÇÕES LISTADOS ABAIXO EM SUA INTEGRALIDADE. A NÃO ACEITAÇÃO DOS TERMOS E CONDIÇÕES ORA DISPOSTOS IMPLICAM NA IMPOSSIBILIDADE DE UTILIZAÇÃO DOS SERVIÇOS DA PLATAFORMA, PELO USUÁRIO, A QUALQUER TEMPO.</p>
          </div>
          <div>
              <p>A Plataforma poderá, a qualquer tempo e sem aviso prévio aos Usuários, alterar, modificar ou ajustar estes Termos, no todo ou em parte, que integrarão estes Termos e que, em caso de conflito, prevalecerão em relação à matéria conflitante. Eventuais modificações que restrinjam os direitos dos Usuários serão comunicadas devida e oportunamente. Isto não afasta a necessidade dos Usuários de reverem estes Termos com frequência, pois se subordinam àquele vigente no momento de seu acesso e uso, ficando automaticamente vinculados a estes Termos em caso de continuidade na utilização da Plataforma. Caso não concorde com as alterações, os Usuários deverão descontinuar o uso da Plataforma e cancelar sua Conta de Acesso, de acordo com as disposições previstas nestes Termos.</p>
          </div>
          <div>
              <p><b>APLICAÇÃO E VALIDADE:</b> Estes Termos são válidos e aplicáveis ao acesso da Plataforma, dentro ou fora do Brasil, e regula as funcionalidades e características de uso da Plataforma. </p>
          </div>
          <div>
            <p>Os termos “Você”, “seu” e “seus” referem-se a Você, Usuário da Plataforma. Os termos “nós” e “nosso” referem-se à Incentiv – Impacto Social & Incentivos Fiscais Ltda., com sede na cidade de Florianópolis, Estado de Santa Catarina, no CELTA (Centro para Laboração de Tecnologias Avançadas), na Rodovia SC 401, nº 600, Módulo 3.23, Parque Tecnológico Alfa, CEP: 88.030-000 e inscrita no CNPJ sob nº 30.248.849/0001-40 <b>(“Incentiv”).</b></p>
          </div>
          <div>
            <b>1. &emsp;DEFINIÇÕES</b>
          </div>
          <div>
            1.1 &emsp; Para os fins deste Termo, as seguintes definições e descrições deverão ser consideradas:
          </div>
          <div>
            <p><b>Conta de Acesso:</b> credencial de um Usuário cadastrado na Incentiv, cujo cadastro atende às condições gerais dispostas nestes Termos. A conta de acesso é definida pelo nome de usuário e senha pessoal intransferível e permite acessar às funcionalidades exclusivas na Plataforma.</p>
            <p><b>Usuário Registrado:</b> pessoas que se cadastram no site para cadastrar empreendedores, projetos ou empresas patrocinadora, ou ainda efetuar qualquer atividade ligada a um usuário que tenha posse de uma conta de acesso.</p>
            <p><b>Leis de Incentivo Fiscal:</b> incluindo, mas não se limitando, à Lei nº 8.313/1991 (Lei Federal de Incentivo à Cultura), Lei nº 11.438/2006 (Lei Federal de Incentivo ao Esporte), Lei nº 12.715/12, Lei nº 12.213/2010 (Fundo Nacional do Idoso), Lei nº 8.685/93, Lei nº 8.242/91 (Fundos dos Direitos da Criança e do Adolescente).</p>
            <p><b>Patrocinador:</b> usuário pessoa jurídica cadastrado, que envia contribuições financeiras a um ou mais Projetos, sob sua exclusiva responsabilidade, podendo ou não se beneficiar com alguma contrapartida ou abatimento nos seus impostos, ficando responsável por realizar os cálculos para identificar o volume de recursos que pode destinar via incentivos fiscais.</p>
            <p><b>Projeto:</b> um esforço temporário, finito, com início e fim bem determinados e empreendidos para se alcançar um objetivo exclusivo, ou seja, um resultado específico que o torna único.</p>
            <p><b>Projeto incentivado:</b> iniciativa cadastrada na Incentiv, de responsabilidade do Proponente, e devidamente aprovada para captação de recursos via Leis de Incentivo Fiscal, por renúncia fiscal nas esferas federal, estadual ou municipal e, em virtude de tal aprovação, permitem que os Patrocinadores, observados certos limites, possam destinar ao Projeto parte dos impostos devidos ao ente público. </p>
            <p><b>Projeto não incentivado:</b> projeto que não foi aprovado em nenhuma lei de incentivo fiscal. Neste caso, o incentivo ao projeto é feito via verba direta, sem a possibilidade de dedução no imposto de empresas ou pessoas.</p>
            <p><b>Proponente:</b> usuário que possui uma Conta de Acesso e que desenvolve e apresenta um Projeto, sob sua exclusiva responsabilidade, para captação de contribuições com o objetivo de angariar fundos para a sua concretização. Nos Projetos Incentivados o elaborador deve ser o Proponente do Projeto aprovado pelo órgão competente.</p>
            <p><b>Contrapartidas:</b> produtos ou serviços que serão oferecidos pelo Proponente aos Patrocinadores, sob sua exclusiva responsabilidade.</p>
            <p><b>Usuários:</b> Proponentes e Patrocinadores.</p>
            <p><b>Cookies:</b> arquivos enviados pelo servidor do sítio eletrônico para o computador dos Usuários, com a finalidade de identificar o computador e obter dados de acesso, como páginas navegadas ou links clicados, permitindo, desta forma, personalizar a navegação dos Usuários de acordo com o seu perfil.</p>
            <p><b>IP:</b> abreviatura de Internet Protocol. É a abreviação comum para IP Address que significa, Internet Protocol Address, ou endereço do protocolo de internet. Composta por números que identificam os computadores dos usuários da internet.</p>
            <p><b>Logs:</b> registro das atividades dos usuários logados, isto é, registrados e acessando com usuário e senha, dentro do sítio eletrônico www.Incentiv.</p>
            <p><b>Sítio eletrônico:</b> designa o site de internet hospedado em www.Incentiv e seus subdomínios desenvolvido pela Incentiv e disponibilizado para os usuários que desejem utilizar sítio para quaisquer doações para os Projetos lá cadastrados.</p>
            <p><b>Web beacons:</b> linhas de programação em páginas HTML que tem como finalidade obter detalhes da navegação do usuário, a exemplo de quanto tempo ficou com um site aberto, qual endereço visitado em seguida, dentre outros.</p>
            <p><b>Layout:</b> conjunto compreendido entre aparência e design da Incentiv.</p>
            <p><b>Link:</b> terminologia para endereço de internet.</p>
          </div>
          <div>
            <b>2. &emsp;CADASTRO E CRIAÇÃO DE CONTA DE ACESSO</b>
          </div>
          <div>
            2.1 &emsp;Ao acessar a Plataforma e concordar com os presentes Termos, os Patrocinadores que se cadastrarem na Plataforma, deverão indicar pessoa física responsável legal pela empresa, que deve atender aos mesmos critérios de idade. 
          </div>
          <div>
            2.2 &emsp;O Usuário poderá optar por se cadastrar na Plataforma e informar, explícita e voluntariamente, seu e-mail e senha composta de no mínimo 6 caracteres alfanuméricos. Outras informações pessoais poderão ser coletadas durante o uso da Plataforma, conforme descrito em nossa Política de Privacidade (<a href='/politica-de-privacidade' target='_blank'>acesse aqui</a>)
          </div>
          <div>
            2.3 &emsp;Ao finalizar o cadastro, o Usuário declara expressamente a veracidade de todas as informações prestadas, podendo ser responsabilizado civil e criminalmente, por falsidade ideológica, conforme art. 299 do Decreto-Lei nº 2.848/1940 ("Código Penal Brasileiro”).
          </div>
          <div>
            2.4 &emsp;A <b>Incentiv</b> poderá negar ou cancelar a Conta de Acesso do Usuário, independentemente de qualquer aviso ou notificação prévia, caso alguma informação apresentada seja irregular, insuficiente ou falsa, ou, ainda, seja identificado qualquer indício de fraude ou irregularidade no cadastro ou na utilização da Plataforma. 
          </div>
          <div>
            2.5 &emsp;Cada Usuário poderá ter somente um login, através do qual terá acesso a sua Conta de Acesso. Caso a <b>Incentiv</b> identifique a existência de mais de um login para o mesmo Usuário, será mantida a Conta de Acesso mais antiga, excluindo a(s) outra(s), podendo perder o direito à transferência ou restituição das informações porventura existentes. 
          </div>
          <div>
            2.6 &emsp;O login e senha são confidenciais e sigilosos, e o Usuário deve adotar as cautelas e diligências necessárias para impedir a utilização indevida por terceiros.
          </div>
          <div>
            2.7 &emsp;O Usuário deverá comunicar imediatamente a <b>Incentiv</b>, através do e-mail contato@Incentiv, eventual esquecimento, perda ou furto de sua senha de acesso, assim como qualquer risco de acesso a ela por terceiros.
          </div>
          <div>
            2.8 &emsp;O Usuário cujo login e/ou senha de acesso tenha sido perdido(a), furtado(a) e/ou roubado(a) permanece responsável pelas informações em seu nome na Plataforma, por meio do cadastro, até o momento de recebimento da comunicação ora prevista pela <b>Incentiv</b>.
          </div>
          <div>
            2.9 &emsp;Uma vez recebido o e-mail do Usuário comunicando a perda, furto e/ou roubo, a <b>Incentiv</b> procederá ao imediato bloqueio do Usuário na Plataforma, após o qual contatará o Usuário para a recuperação de seu cadastro ou, alternativamente, conforme o caso, a realização de um novo cadastro.
          </div>
          <div>
            2.10 &emsp;O Usuário poderá, a qualquer tempo, inativar sua Conta de Acesso através de seu perfil na Plataforma. A Conta será descontinuada e inativada. Vale ressaltar, que apesar de inativada, as informações contidas na Conta de Acesso permanecerão disponíveis à <b>Incentiv</b>, para o cumprimento das finalidades relativas aos Projetos.
          </div>
          <div>
            <b>3. &emsp;OBJETO DA PLATAFORMA </b>
          </div>
          <div>
            3.1 &emsp;A <b>Incentiv</b> organiza a captação de recursos on-line para os indivíduos e as organizações, com ou sem fins lucrativos, que pretendam levantar recursos para causas e projetos, na forma de Projeto Incentivados. 
          </div>
          <div>
            3.2 &emsp;A <b>Incentiv</b> apenas organiza as contribuições dos Patrocinadores até que atinjam os limiares da captação respectiva. No caso de Projetos Incentivados: (i) as contribuições são enviadas a título de patrocínio, respondendo o Proponente pelo imposto aplicável à espécie; (ii) as contribuições, poderão, não obrigatoriamente, apresentar qualquer tipo de Contrapartida, estando sujeitas única e exclusivamente ao envio do recibo de patrocínioao Patrocinador; e (iii) os valores não serão restituídos ao Patrocinador caso a meta estabelecida não seja alcançada.
          </div>
          <div>
            3.3 &emsp;Em caso de descumprimento de quaisquer das disposições do Projeto criado, pelo Proponente, a <b>Incentiv</b> não será responsável por realizar o que não foi cumprido, especialmente entregar determinado produto ou realizar o serviço prometido. O Patrocinador deverá buscar a satisfação do que foi prometido diretamente do Proponente.
          </div>
          <div>
            <b>4. &emsp;CARACTERÍSTICAS DO PROJETO INCENTIVADO </b>
          </div>
          <div>
            4.1 &emsp;Os Proponentes deverão manter atualizada as informações do projeto na ferramenta de monitoramento da Incentiv  na medida que o projeto for realizado.
          </div>
          <div>
            4.2 &emsp;O Patrocinador é responsável assim como a Incentiv, por acompanhar a evolução do Projeto, através de informações fornecidas por seu Proponente e, subsidiariamente, por detalhes enviados pela <b>Incentiv</b>. Por isso, está ciente que também possui obrigação de enviar status sobre os Projetos.
            <div class="sub-menu">
              4.2.1 &emsp;É de responsabilidade do Patrocinador e da Incentiv averiguar se o Projeto foi bem sucedido, ou não. A <b>Incentiv</b> não medirá esforços para manter o Patrocinador atualizado sobre o sucesso ou insucesso do Projeto apoiado por ele, o que não representará qualquer responsabilidade por parte da <b>Incentiv</b>, com o que expressamente concordam Usuários, Proponentes e Patrocinadores. 
            </div>
            <div class="sub-menu">
              4.2.2 &emsp;O Proponente se responsabiliza em manter a Plataforma atualizada, incluindo fotos, vídeos e informações sobre as ações realizadas e resultados alcançados, <u>MENSALMENTE</u>, prezando pela transparência e melhor comunicação com os Patrocinadores.
            </div>
          </div>
          <div>
            4.3 &emsp;Ao estabelecer Contrapartidas, os Proponentes assumem as obrigações previstas no artigo 854 e seguintes da Lei nº 10.406/2002 (Código Civil), tornando-se diretamente responsáveis pelo cumprimento de sua promessa perante os Patrocinadores que preencherem as condições estipuladas, sem qualquer responsabilidade, direta ou indireta, por parte da <b>Incentiv</b>.
          </div>
          <div>
            4.4 &emsp;Se o Proponente oferecer mais de uma espécie de Contrapartida, poderá o Usuário Patrocinador escolher a que melhor lhe agrada no momento da contribuição, desde que atenda ao valor mínimo necessário.
          </div>
          <div>
            4.5 &emsp;A <b>Incentiv</b> não possui qualquer responsabilidade, direta ou indireta, sobre as informações fornecidas pelo Proponente, seja ela em relação ao seu desenvolvimento, Contrapartidas oferecidas, tempo de execução, prazo de captação ou relacionada a qualquer outro tipo de detalhe.
          </div>
          <div>
            <b>5. &emsp;DIREITOS E RESPONSABILIDADES DA Incentiv </b>
          </div>
          <div>
            5.1 &emsp;A <b>Incentiv</b> se obriga com os Usuários a:
            <div class="sub-menu">
              <p>(i) &emsp;Preservar a funcionalidade da Plataforma, com links em funcionamento, utilizando layout que respeita a usabilidade, segurança e navegabilidade, facilitando a navegação sempre que possível e garantindo um ambiente confiável para processar suas operações; </p>
              <p>(ii) &emsp;Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas; e</p>
              <p>(iii) &emsp;Garantir, por meio do estado da técnica disponível, o sigilo e confidencialidade dos dados inseridos nas funcionalidades oferecidas na Plataforma.</p>
            </div>
          </div>
          <div>
            5.2 &emsp;A <b>Incentiv</b> poderá, a seu exclusivo critério, a qualquer momento (i) independentemente de qualquer comunicação aos Usuários ou ao Proponente, retirar qualquer Projeto da Plataforma; e (ii) mediante simples comunicação ao Usuário ou ao Proponente, excluir a Conta de Acesso, sem que isso implique no dever de indenizar o Usuário ou o Proponente.
          </div>
          <div>
            5.3 &emsp;Os Usuários, Proponentes e Patrocinadores autorizam a <b>Incentiv</b> a divulgar amplamente a captação de recursos do Projeto e aqueles que promoveram as colaborações nos mais diversos meios de comunicação e mídias, de modo a estimular maior participação nas atividades por outras pessoas e aumentar a chance de sucesso do Projeto, sem a exigência de remuneração ou contraprestação qualquer resultante dessa disseminação.
          </div>
          <div>
            5.4 &emsp;A <b>Incentiv</b> se reserva o direito de não publicar ou excluir Projetos e conteúdos enviados pelos Usuários, a qualquer tempo, sem prévio aviso e nenhum direito à indenização a quem quer que seja, que contiverem:

            <ul>
                <li>Ofensa à honra, imagem, reputação e dignidade de terceiros;</li>
                <li>Pornografia, pedofilia, e outras modalidades de satisfação sexual;</li>
                <li>Racismo ou discriminação de qualquer natureza;</li>
                <li>Bullying, Stalking ou qualquer outra espécie de constrangimento ilegal ou assédio;</li>
                <li>Manifesta violação a direito autoral ou direito de imagem;</li>
                <li>Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;</li>
                <li>Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro, homicídio, estelionato, dentre outros;</li>
                <li>Manifesta prática de ato contrário à lei, à ordem pública ou aos bons costumes;</li>
                <li>Uso da violência, disseminação de ódio ou qualquer outra forma de agressão ao bem estar físico de alguém;</li>
                <li>Atividade comercial similar à sua ou que utilize a Plataforma para angariar recursos por publicidade de terceiros em vez de praticar a interação regular do ambiente;</li>
                <li>Erros ou suspeita de equívocos.</li>
            </ul>
          </div>
          <div>
            5.5 &emsp;Caso seja apurada atividade suspeita do Usuários, Proponentes e Patrocinadores, que possam ensejar a existência de crime, lavagem de dinheiro, evasão de divisas e etc., poderá a <b>Incentiv</b> informar imediatamente os fatos às autoridades competentes, bem como, realizar o bloqueio total do Projeto em sua Plataforma, até que se tenha uma decisão definitiva sobre os fatos.
          </div>
          <div>
            <b>6. &emsp;DIREITOS E RESPONSABILIDADES DOS USUÁRIOS, PROPONENTES E PATROCINADORES </b>
          </div>
          <div>
            6.1 &emsp;É de obrigação dos Usuários, Proponentes e Patrocinadores: (i) conferir as informações e/ou características dos Projetos antes de realizar qualquer negócio; (ii) responsabilizar-se por qualquer ação ou afirmação realizada por sua Conta de Acesso; e (iii) cumprir todas as determinações destes Termos.
          </div>
          <div>
            6.2 &emsp;Os Proponentes que pretendem anunciar Projetos na <b>Incentiv</b> devem (i) assegurar que o objeto anunciado seja necessariamente lícito e de acordo com a legislação brasileira; (ii) estipular o valor aprovado em lei para captação do Projeto; (iii) informar a data fim para obter o valor total destinado para financiar o Projeto, sendo o prazo definido na aprovação do Projeto; (iv) estipular contrapartidas lícitas aos Patrocinadores que financiarem seu Projeto, determinando as condições de sua obtenção e o número de Patrocinadores que podem se beneficiar dessas contrapartidas; e (v) fornecer à <b>Incentiv</b> os dados bancários para a transferência dos recursos captados, descontadas as taxas e tarifas previamente acordadas, se aplicável, que não serão consideradas, para qualquer fim, como parte do incentivo.
          </div>
          <div>
            6.3 &emsp;Os Proponentes de Projetos Incentivados poderão cadastrar o mesmo projeto em outras plataformas, todavia, deverá prontamente, assim que receber qualquer valor de contribuição em outras plataformas, informar imediatamente a Incentiv do valor recebido, para que o mesmo possa ser reduzido do valor que se busca arrecadar na Plataforma, o que deverá ocorrer no prazo máximo de 5 (cinco) dias úteis após a confirmação do patrocínio, por meio de seu painel administrativo na Plataforma.

            <div class="sub-menu">
              6.3.1 &emsp;O não envio da informação supracitada poderá ensejar justa causa para a rescisão dos contratos firmados com o Proponente, bem como a comunicação às autoridades competentes, para que essas apurem os fatos e apliquem a penalidade, caso seja cabível.
            </div>
            <div class="sub-menu">
              6.3.2 &emsp;Caso haja um patrocínio ou incentivo, que ultrapasse o valor limite de captação aprovado para o Projeto, a responsabilidade é do Proponente, caso o Patrocinador não consiga realizar o abatimento fiscal.
            </div>
            <div class="sub-menu">
              6.3.3 &emsp;Caso o Proponente não faça a devida atualização do valor captado e a <b>Incentiv</b>  realize a captação de recursos para o Projeto, o Proponente deverá se responsabilizar pelo pagamento dos serviços prestados à <b>Incentiv </b>
            </div>
          </div>
          <div>
            6.4 &emsp;Os Usuários, Proponentes e Patrocinadores devem utilizar os recursos presentes na Plataforma para as finalidades a qual esta foi constituída, sob pena da aplicação das penas da lei, de indenizarem a quem causarem dano e de terem a Conta de Acesso do presente <b>Incentiv</b> excluída.
          </div>
          <div>
            6.5 &emsp;Os Usuários, Proponentes e Patrocinadores  poderão se valer do canal de ‘Fale Conosco’ toda vez que presenciarem ou verificarem conteúdo impróprio na Plataforma. A denúncia será sigilosa e preservará a identidade do Usuário.
          </div>
          <div>
            <b>7. &emsp;LIMITAÇÃO DE RESPONSABILIDADE DA Incentiv </b>
          </div>
          <div>
            7.1 &emsp;O Usuário declara estar ciente e concordar que a <b>Incentiv</b> se exime de qualquer responsabilidade por quaisquer declarações ou garantias implícitas ou aqui não expressamente previstas, incluindo, sem limitação, quaisquer garantias de adequação da Plataforma para uma finalidade específica. A <b>Incentiv</b> não será, em nenhuma hipótese, responsável por danos diretos, indiretos, materiais ou morais, assim como lucros cessantes, ocasionados, ou relacionados, à existência ou uso da Plataforma.   
          </div>
          <div>
            7.2 &emsp;A <b>Incentiv</b> não garante que a Plataforma atenderá aos fins pretendidos pelo Usuário, nem que esteja isenta de erros, possíveis interrupções ou falhas, ou que a Plataforma será compatível ou funcionará com qualquer software, aplicações ou serviços de terceiros. O Usuário reconhece que a Plataforma pode não estar disponível ou ser indisponibilizada por diversos fatores, incluindo, sem limitação, manutenções periódicas do sistema (programadas ou não), manifestações da natureza, falhas técnicas do software da Plataforma, infraestrutura de telecomunicações ou atraso ou interrupção ocasionada por vírus, ataques de negação de serviços, aumento ou flutuação de demanda, ações e omissões de terceiros ou qualquer outra causa que esteja fora do controle da <b>Incentiv</b>.
          </div>
          <div>
            7.3 &emsp;Além disso, o Usuário declara ter conhecimento de que o acesso à Plataforma é feito de modo digital, sendo imprescindível, para tanto, possuir conexão com a internet. A <b>Incentiv</b> não é responsável pela qualidade de sua conexão com a internet.
          </div>
          <div>
            7.4 &emsp;Os conteúdos enviados pelos Usuários e publicados na <b>Incentiv</b> não deverão ser interpretados como opinião da <b>Incentiv</b> tampouco declaração de concordância com o que foi publicado. Os Usuários, Proponentes e Patrocinadores são inteiramente responsáveis pelos conteúdos que submeterem à <b>Incentiv</b>.
          </div>
          <div>
            7.5 &emsp;A <b>Incentiv</b> não possui qualquer ingerência sobre os Projetos, sob seu escopo, prazos de entrega e disponibilidade de incentivos e/ou contrapartidas, controle do orçamento, fretes, valores anunciados ou outras condições prometidas pelos Proponentes. Da mesma forma, a <b>Incentiv</b> não pode ser responsabilizada pelos custos ou riscos do negócio relacionados com os Projetos Incentivados, sendo a Plataforma meramente um meio de divulgação dos Projetos e aproximação entre os Proponentes e os Patrocinadores.
          </div>
          <div>
            <b>8. &emsp;PROPRIEDADE INTELECTUAL  </b>
          </div>
          <div>
            8.1 &emsp;A <b>Incentiv</b> respeita os direitos de propriedade intelectual de terceiros e espera que os Usuários façam o mesmo. Todas as marcas, nomes comerciais e sinais distintivos de qualquer espécie presentes na Plataforma, incluindo a própria marca e designação, são pertencentes a seus respectivos titulares de direito. Para a utilização de quaisquer destes direitos é necessária a autorização expressa dos seus titulares e o uso indevido destes será devidamente punido por vias judiciais.
          </div>
          <div>
            8.2 &emsp;Ao realizar o incentivo para qualquer dos Projetos cadastrados na Plataforma, o Patrocinador autoriza o Proponente e a <b>Incentiv</b>, a utilizarem sua imagem nas peças publicitárias destinadas à divulgação do Projeto e da Plataforma, podendo essas peças serem veiculadas e difundidas por prazo indeterminado, salvo quando o Patrocinador previamente solicitar que seu incentivo seja anônimo. A reprodução desses conteúdos descritos está proibida, salvo com prévia autorização por escrito da <b>Incentiv</b> ou do Patrocinador titular de referida propriedade intelectual.
          </div>
          <div>
            8.3 &emsp;Os Usuários, Proponentes e Patrocinadores assumem toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida de todo e qualquer direito de propriedade intelectual da e presente na Plataforma.
          </div>
          <div>
            8.4 &emsp;A utilização das funcionalidades oferecidas pela <b>Incentiv</b> se dará na forma de prestação de serviço, não conferindo ao contratante nenhum direito sobre o software utilizado pela <b>Incentiv</b> ou de suas estruturas de informática que sustentam as aplicações de internet.
          </div>
          <div>
            8.5 &emsp;Salvo disposição específica em contrato existente entre os Proponentes com a <b>Incentiv</b> , os Proponentes retêm os direitos autorais dos conteúdos que já possuírem quando os enviam ao <b>Incentiv</b>, tais como, mas não limitadamente, a comentários, vídeos, imagens, áudio e mensagens, contudo, concedem uma licença irrevogável, perpétua, mundial, irrestrita, isenta de royalties e não exclusiva de reprodução, adaptação, modificação, tradução, publicação, distribuição ou exibição no próprio <b>Incentiv</b> possibilitando a melhor utilização da ferramenta, maior divulgação de algum produto, serviço ou atividade ligada ao <b>Incentiv</b>.
          </div>
          <div>
            <b>9. &emsp;ACESSO E RESTRIÇÕES DE FUNCIONALIDADE  </b>
          </div>
          <div>
            9.1 &emsp;Não é permitido aos Usuários acessarem as áreas de programação da Plataforma, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de <i>webmastering</i>, ficando sujeito quem o fizer à legislação penal brasileira e obrigados a reparar os danos que causarem.
          </div>
          <div>
            9.2 &emsp;Os Usuários também não estão autorizados a realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas de consulta desta Plataforma, de suas funcionalidades ou da sua Conta de Acesso.
          </div>
          <div>
            9.3 &emsp;Na <b>Incentiv</b> é proibida a utilização de aplicativos spider, ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo automatizado, tanto para realizar operações massificadas ou para quaisquer outras finalidades, sob aplicação da legislação penal brasileira e de reparar os danos que decorrerem desta utilização.
          </div>
          <div>
            <b>10. &emsp;PROJETOS EXCLUSIVOS DA FERRAMENTA MONITORE </b>
          </div>
          <div>
            10.1 &emsp;Em casos excepcionais, mediante tratativas prévias, o projeto que eventualmente utilizar apenas o serviço de monitoramento da Incentiv, operacionalizado pela ferramenta Monitore, deverá obrigatoriamente realizar cadastro na ferramenta Conecta, sem a necessidade das etapas obrigatórias para contratação dos serviços de captação de recursos, agenciamento, dentre outros, incluindo as etapas de Due Dilligence e celebração de contrato específico. O uso da ferramenta Monitore e o cadastro do projeto na Conecta com a finalidade de monitorar o projeto não incorrerá em nenhum custo para o proponente.
          </div>
          <div>
            <b>11. &emsp;POLÍTICA DE PRIVACIDADE </b>
          </div>
          <div>
            11.1 &emsp;A <b>Incentiv</b> reconhece, nos termos de sua <a href='/politica-de-privacidade' target='_blank'>Política de Privacidade</a>, que é parte integrante do presente Termo, e da legislação aplicável, a confidencialidade e segurança das informações prestadas pelo Usuário, empenhando-se em resguardar o seu sigilo, salvo por força de lei ou ordem judicial.
          </div>
          <div>
            <b>12. &emsp;DISPOSIÇÕES GERAIS  </b>
          </div>
          <div>
            Todas as notificações e autorizações que puderem ou tiverem que ser feitas ou dadas pelas Partes no âmbito destes Termos serão válidas e eficazes somente se exteriorizadas através de correspondência e enviada para, conforme o caso, o e-mail do Usuário indicado em sua Conta de Acesso ou o e-mail da <b>Incentiv</b> expressamente indicado nestes Termos, com comprovação de recebimento. Se qualquer parte destes Termos for considerada nula, inexequível ou inválida, isso não afetará as outras disposições. A falha de qualquer uma das Partes em aplicar qualquer disposição deste Termos não será considerada uma renúncia à futura execução dessa ou de qualquer outra disposição. O Usuário não poderá ceder estes Termos ou qualquer direito ou obrigação aqui previsto, sem o consentimento prévio por escrito da <b>Incentiv</b>. Os Termos serão vinculantes e reverterão em benefício das Partes e seus respectivos sucessores e cessionários. Nenhuma pessoa ou entidade, além das Partes, terá o direito de fazer cumprir ou buscar a execução destes Termos. Nada nestes Termos será considerado ou interpretado como criação de uma parceria, joint venture ou qualquer relacionamento semelhante entre as Partes. Estes Termos, assim como o relacionamento entre a <b>Incentiv</b> e o Usuário, devem ser regidos e interpretados de acordo com as leis em vigor na República Federativa do Brasil. As Partes concordam que qualquer disputa, controvérsia ou reclamação decorrente de/ou em conexão com estes Termos da Plataforma, ou a violação, rescisão ou nulidade do mesmo, deve ser submetido à jurisdição exclusiva da Comarca da Capital do Estado de Santa Catarina. 
          </div>
          <div>
            Para esclarecimento de quaisquer dúvidas, o Usuário poderá entrar em contato com a Inventiv.me por meio do canal Fale Conosco disponível na Plataforma ou através do Canal de Comunicação encarregado@Incentiv. O atendimento funciona de segunda-feira à sexta-feira, das 10h às 18h, exceto feriados. Consulte também na Plataforma nossa página com respostas às Perguntas Frequentes.
          </div>
          <div>
            Estes Termos de Uso foram modificados pela última vez em 06/04/2023.
          </div>
      </div>
      </Container>
      
    </MainContainer>
  );
};

export default Terms;
