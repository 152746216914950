import React from 'react';
import Typography from 'components/Typography';

import { Container } from './styles';

const TabItem = ({ title, active, handleClick }) => {
  return (
    <Container active={active} onClick={handleClick}>
      <Typography variant="subtitle1" color={active ? 'white' : 'primary'}>
        {title}
      </Typography>
    </Container>
  );
};

export default TabItem;