import Checkbox from '@material-ui/core/Checkbox';
import Input from 'components/Input';
import Typography from 'components/Typography';
import React, { useCallback, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { phoneMask, cpfMask } from 'utils/maskUtils';
import AdminFormGroup from '../AdminProjectForm/AdminFormGroup';
import { useUser } from 'hooks/useUser';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';

import { Container, Content, Row, Body, LocationThreeRow, LocationTwoRow, CardCnd, TextBox, Cnd } from './styles';
import ReactTooltip from 'react-tooltip';
import { useMemo } from 'react';
import { dateFormattedBr, dateFormattedToDateISO, datetimeFormattedBr } from 'utils/date';
import DragAndDrop from 'components/DragAndDrop';
import { InsertLink } from '@material-ui/icons';

const CardCND = ({cnd, uploadCndProponent, isPj = false, isDisabled}) => {
    const [cndProp, setCndProp] = useState(cnd);
    const [isUploading, setIsUploading] = useState(false);
    const today = new Date();
    const dateAux = new Date(cndProp.expire_date);
    const isNotFlag = cndProp.title !== 'PEP' && 
                      cndProp.title !== 'Cartão CNPJ' &&
                      cndProp.title !== 'CEIS - Portal da Transparência' &&
                      cndProp.title !== 'CEPIM - Portal da Transparência';


    const [isValidity, setIsValidity] = useState(dateAux <= today);

    useEffect(()=>{
        if(cnd !== cndProp){
            setCndProp(cnd);
        }
        setIsValidity(dateAux <= today)
    }, [cnd, dateAux, today, cndProp])

    // const namesCnds = useMemo(() => {
    //     return {
    //         'ceis': ['CEIS - Portal da Transparência', 30],
    //         'cepim': ['CEPIM - Portal da Transparência', 30],
    //         'cndt': ['CNDT - Info Simples', 180],
    //         'cnpjCard': ['Cartão CNPJ', 180],
    //         'fgts': ['FGTS - Info Simples', 30],
    //         'pep': ['Projeto regular', 30],
    //         'sefaz': ['Sefaz - Info Simples', 0],
    //         'tcuDisabled': ['TCU Desabilitada', 30],
    //         'tcuDisreputable': ['TCU Inidôneo', 30],
    //         'tcuProcesses': ['TCU Processos', 30],
    //         'unionDebt': ['Débitos da união - Info Simples', 180],
    //     };
    //   }, []);

    
    return (
        <CardCnd style={isValidity || (cndProp.status === 'WARNING' || cndProp.status === 'DANGER' || cndProp.status === '400') ? {borderColor: 'red'} : {}}>
            <Row>
                <AdminFormGroup
                    checked={(isValidity ? false : (cndProp.status == 'OK' ? true : false))}
                    onChange={value => {
                    // setAuthNameResponsible(value);
                    // setValue('auth_name_responsible', value);
                    }}
                >
           
                <Cnd>
                    <DragAndDrop 
                    placeholder="nenhum arquivo enviado"
                    onChange={async file => { 
                        setIsUploading(true);
                        await uploadCndProponent(file, {"type": cndProp.type, "isPj": isPj});
                        setIsUploading(false);
                    }}
                    label={'Foto de perfil'}
                    disabled={isDisabled}
                    isUploading={isUploading}
                    error={''}
                    isCnd ={true}
                    />
                    
                    <Row style={{gridTemplateColumns: '69% 28%', width: '100%', fontFamily: 'Roboto,sans-serif'}} colSize={2}>
                        <Row>
                            <TextBox>
                                <div>
                                    <a target='_blank' rel="noreferrer" href={cndProp.proof}><InsertLink/>{cndProp.title}{isNotFlag ? (isPj ? 'PJ' : 'PF') : ''}</a>
                                </div>
                                
                                <div>
                                    <p>Status: {cndProp.status === '400' ? 'Erro na consulta.' : cndProp.status}{cndProp.status === null ? 'CND não encontrada!' : ''}</p>
                                    <p>{cndProp.status === '400' ? 'Insira manualmente a documentação.' : cndProp.message} {cndProp.message === null ? 'Insira manualmente a documentação.' : ''}</p>
                                </div>
                            </TextBox>
                        </Row>

                        <Row>
                            <Controller
                                name=""
                                // control={control}
                                render={({ field }) => (
                                    <Input 
                                    label="Validade" {...field}
                                    style={{minWidth: '120px', maxWidth: '120px'}}
                                    value={cndProp.publish_date ? dateFormattedBr(cndProp.expire_date) : 'N/A'}
                                    color={isValidity ? 'red' : ''}
                                    disabled={true}
                                    />
                                )}
                                />
                        </Row>
                    </Row>
                </Cnd>
                </AdminFormGroup>
            </Row>

        </CardCnd>
    )
}

export default CardCND;