import React, { useMemo, useState, useCallback, useEffect } from 'react';
import AdminTabs from 'components/AdminTabs';
import PageTitle from 'components/PageTitle';

import { Container, Header, Content, Body } from './styles';
import ProjectInfoCardAdmin from 'components/ProjectInfoCardAdmin';
import { ProjectService } from 'services/projects'
import { useParams } from 'react-router-dom';
import AdminProjectForm from 'components/AdminProjectForm';
import NotificationsProjectTable from 'components/NotificationsProjectTable';
import ProjectOffsetsCard from 'components/ProjectOffsetsCard';
import ProjectGoalsCard from 'components/ProjectGoalsCard';
import { datetimeFormatted } from 'utils/date';
import { useUser } from '../../../hooks/useUser';
import { currencyMask } from 'utils/currency';

import { useHistory } from 'react-router-dom';


const ProjectDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = useMemo(() => {
    return ['VITRINE', 'CADASTRO', 'CENTRAL DE NOTIFICAÇÃO', 'CONTRAPARTIDAS', 'OBJETIVOS']
  }, []);

  const { user } = useUser();
  const { id } = useParams();
  const [project, setProject] = useState();
  const history = useHistory();

  const loadProjectInfo = useCallback(async () => {
    const [project, infoProject] = await Promise.all([
      ProjectService.findProject(id),
      ProjectService.showProject(id),
    ]);
    console.log('data2', project.data[0]);
    console.log('infoProject2', infoProject.data[0]);
    
    if (project.data.erro !== undefined) {
      history.push('/admin/projetos');
    }

    if (project.data.length === 1 && infoProject.data.length === 1) {
      const projectData = {
        id: project.data[0].id,
        name: project.data[0].name,
        code: project.data[0].code,
        analyze_reservations: project.data[0].analyzeReservations,
        commissionPercentage: project.data[0].commissionPercentage,
        proponent: infoProject.data[0].proponentName,
        proponent_id: infoProject.data[0].proponentId,
        description: project.data[0].summary,
        status: infoProject.data[0].status,
        fundingPeriod: project.data[0].fundingPeriod,
        approvalDate: project.data[0].approvalDate,
        runtimeDays: project.data[0].runtimeDays,
        category: infoProject.data[0].impactCategoriesName,
        subcategory: infoProject.data[0].impactSubcategoriesName,
        law: project.data[0].lawId,
        approvedValue: project.data[0].approvedValue,
        capturedValue: project.data[0].capturedValue,
        bank_id: project.data[0].bankId,
        agency: project.data[0].agency,
        account_bank: project.data[0].accountBank,
        capturedValueDate: project.data[0].capturedValueDate,
        target: project.data[0].target,
        audienceGoal: project.data[0].audienceGoal,
        sd_goals: infoProject.data[0].sdGoals,
        locations: infoProject.data[0].locations,
        images:
          infoProject.data[0].projectsImage
            ? infoProject.data[0].projectsImage
            : '[]',
        goal: project.data[0].goal,
        specificGoals: project.data[0].specificGoal,
        sponsors: project.data[0].sponsors,
        previous_sponsors: project.data[0].previousSponsors,
        counterpart: project.data[0].project_counterparts,
        video: project.data[0].video,
        edition: project.data[0].edition,
        impact_category_id: project.data[0].impactCategoryId,
        impact_subcategory_id: project.data[0].impactSubcategoryId,
        partially_captured: infoProject.data[0].partiallyCaptured,
        more_than_one_city: project.data[0].moreThanOneCity,
        can_change_city: project.data[0].canChangeCity,
        work_plan: project.data[0].workPlan,
        capture_certificate: project.data[0].captureCertificate,
        official_journal: (project.data[0].officialJournal || project.data[0].officialJournal2 || project.data[0].officialJournal3)
          ? [
            project.data[0].officialJournal,
            project.data[0].officialJournal2,
            project.data[0].officialJournal3
          ]
          : '[]',
        account_opening_commitment: project.data[0].accountOpeningCommitment,
        image: project.data[0].image,
        quota: infoProject.data[0].quota.map(q => {
          return {...q, valueOf: currencyMask(q.valueOf.toString()), valueUpTo: currencyMask(q.valueUpTo.toString()) }
        }),
        status: project.data[0].status,
        analysis_status: project.data[0].analysisStatus,
        analyze_reservations: project.data[0].analyzeReservations,
        analysis_compliance_status: project.data[0].analysisComplianceStatus,
        minimum_value: project.data[0].minimumValue,
        highlights_and_awards: project.data[0].highlightsAndAwards,
        project_presentation: project.data[0].projectPresentation,
        contract_file: project.data[0].contractFile,
        opening_receipt: project.data[0].openingReceipt,
        fund_opening_receipt: project.data[0].fundOpeningReceipt,
        company_names: project.data[0].companyNames,
        other_informations: project.data[0].otherInformations,
        auth_edition: Number(project.data[0].authEdition),
        auth_abstract: Number(project.data[0].auth_abstract),
        auth_video: Number(project.data[0].authVideo),
        auth_counterpart: Number(project.data[0].authCounterpart),
        auth_runtime_days: Number(project.data[0].authRuntimeDays),
        auth_change_city: Number(project.data[0].authChangeCity),
        auth_more_than_one_city: Number(project.data[0].authMoreThanOneCity),
        auth_partially_captured: Number(project.data[0].authPartiallyCaptured),
        auth_schedule: Number(project.data[0].authSchedule),
        auth_contract: Number(project.data[0].authContract),
        auth_minimum_value: Number(project.data[0].authMinimumValue),
        auth_initial_value: Number(project.data[0].authInitialValue),
        auth_final_value: Number(project.data[0].authFinalValue),
        auth_project_presentation: Number(project.data[0].authProjectPresentation),
        auth_project_initial_date: Number(project.data[0].authProjectInitialDate),
        auth_project_final_date: Number(project.data[0].authProjectFinalDate),
        auth_project_approved_full: Number(project.data[0].authProjectApprovedFull),
        auth_official_journal: Number(project.data[0].authOfficialJournal),
        auth_opening_term: Number(project.data[0].authOpeningTerm),
        auth_budget_spreadsheet: Number(project.data[0].authBudgetSpreadsheet),
        auth_capture_certificate: Number(project.data[0].authCaptureCertificate),
        auth_work_plan: Number(project.data[0].authWorkPlan),
        auth_audience_goal: Number(project.data[0].authAudienceGoal),
        auth_status: Number(project.data[0].authStatus),
        auth_name: Number(project.data[0].authName),
        auth_external_project_id: Number(project.data[0].authExternalProjectId),
        auth_approved_value: Number(project.data[0].authApprovedValue),
        auth_captured_value: Number(project.data[0].authCapturedValue),
        auth_funding_period: Number(project.data[0].authFundingPeriod),
        auth_starts_at: Number(project.data[0].authStartsAt),
        auth_funding_period: Number(project.data[0].authFundingPeriod),
        auth_approval_date: Number(project.data[0].authApprovalDate),
        auth_location_ids: Number(project.data[0].authLocationIds),
        auth_impact_category_id: Number(project.data[0].authImpactCategoryId),
        auth_impact_subcategory_id: Number(project.data[0].authImpactSubcategoryId),
        auth_law_id: Number(project.data[0].authLawId),
        auth_step_id: Number(project.data[0].authStepId),
        auth_sd_goal_ids: Number(project.data[0].authSdGoalIds),
        auth_problem: Number(project.data[0].authProblem),
        auth_problem_description: Number(project.data[0].authProblemDescription),
        auth_goal: Number(project.data[0].authGoal),
        auth_specific_goal: Number(project.data[0].authSpecificGoal),
        auth_summary: Number(project.data[0].authSummary),
        auth_roots_problem: Number(project.data[0].authRootsProblem),
        auth_consequences_problem: Number(project.data[0].authConsequencesProblem),
        auth_target: Number(project.data[0].authTarget),
        auth_sponsors: Number(project.data[0].authSponsors),
        auth_quota: Number(project.data[0].authQuota),
        auth_project_cover: Number(project.data[0].authProjectCover),
        auth_project_images: Number(project.data[0].authProjectImages),
        auth_highlights_and_awards: Number(project.data[0].authHighlightsAndAwards),
        auth_company_names: Number(project.data[0].authCompanyNames),
        auth_opening_receipt: Number(project.data[0].authOpeningReceipt),
        auth_fund_opening_receipt: Number(project.data[0].authFundOpeningReceipt),
        register_status_proponent: infoProject.data[0].registerStatus,
        analysis_compliance_status_proponent: infoProject.data[0].analysisComplianceStatus,
        process_status: infoProject.data[0].processStatus,
        proponent_legal_person: infoProject.data[0].proponentLegalPerson,
        proponent_company_size: infoProject.data[0].proponentCompanySize,
        proponent_name: infoProject.data[0].proponentName,
        proponent_full_name: infoProject.data[0].proponentFullName,
        proponent_cpf: infoProject.data[0].proponentCpf,
        proponent_email: infoProject.data[0].proponentEmail,
        proponent_public_place: infoProject.data[0].proponentPublicPlace,
        proponent_number: infoProject.data[0].proponentNumber,
        proponent_complement: infoProject.data[0].proponentComplement,
        proponent_district: infoProject.data[0].proponentDistrict,
        proponent_city: infoProject.data[0].proponentCity,
        proponent_state: infoProject.data[0].proponentState,
        proponent_cep: infoProject.data[0].proponentCep,
        proponent_phone: infoProject.data[0].proponentPhone,
        proponent_company_names: infoProject.data[0].proponentCompanyNames,
        proponent_full_name_witness: infoProject.data[0].proponentFullNameWitness,
        proponent_witness_email: infoProject.data[0].proponentWitnessEmail,
        proponent_corporate_name: infoProject.data[0].proponentCorporateName,
        proponent_cnpj: infoProject.data[0].proponentCnpj,
        proponent_name_responsible: infoProject.data[0].proponentNameResponsible,
        proponent_cpf_responsible: infoProject.data[0].proponentCpfResponsible,
        proponent_phone_responsible: infoProject.data[0].proponentPhoneResponsible,
        proponent_email_responsible: infoProject.data[0].proponentEmailResponsible,
        proponent_key_account_user_id: infoProject.data[0].proponentKeyAccountUserId,
        law_type: project.data[0].lawType,
        law_coverage: project.data[0].lawCoverage,
        category_law: project.data[0].categoryLaw,
        law_state: project.data[0].lawState,
        law_city: project.data[0].lawCity,
        specific_law_id: project.data[0].specificLawId,
        cpf: infoProject.data[0].proponentCpf,
        full_name: infoProject.data[0].proponentFullName,
        legal_person: infoProject.data[0].proponentLegalPerson,
        company_size: infoProject.data[0].proponentCompanySize,
        corporate_name: infoProject.data[0].proponentCorporateName,
        complement: infoProject.data[0].proponentComplement,
        name_responsible: infoProject.data[0].proponentNameResponsible,
        cpf_responsible: infoProject.data[0].proponentCpfResponsible,
        phone_responsible: infoProject.data[0].proponentPhoneResponsible,
        email_responsible: infoProject.data[0].proponentEmailResponsible,
        full_name_witness: infoProject.data[0].proponentFullNameWitness,
        witness_email: infoProject.data[0].proponentWitnessEmail,
        phone: infoProject.data[0].proponentPhone,
        cnpj: infoProject.data[0].proponentCnpj,
        email: infoProject.data[0].proponentEmail,
        public_place: infoProject.data[0].proponentPublicPlace,
        number: infoProject.data[0].proponentNumber,
        district: infoProject.data[0].proponentDistrict,
        city: infoProject.data[0].proponentCity,
        cep: infoProject.data[0].proponentCep,
        state: infoProject.data[0].proponentState,
        change_fields: project.data[0].changeFields,
        document_token: project.data[0].documentToken,
        only_monitore: project.data[0].onlyMonitore,
        only_monitore_date: project.data[0].onlyMonitoreDate,
        only_monitore_user: project.data[0].onlyMonitoreUser,
        fundingPeriodHasPassed: infoProject.data[0].fundingPeriodHasPassed,
        retention_percentage: project.data[0].retentionPercentage,
      };
      setProject(projectData);
    }
  }, [id]);

  async function handleUpdateQuota() {
    // let infoProjectUpdated = await ProjectService.showProject(id);
    // setProject({ ...project, quota: infoProjectUpdated.data[0].quota })
    loadProjectInfo();
  }

  useEffect(() => {
    if (id) {
      loadProjectInfo();
    }
  }, [id, loadProjectInfo]);

  function changeOnlyMonitoreProject() {
    const date = new Date();
    setProject({...project, only_monitore: 1, only_monitore_user: user.name, only_monitore_date: date})
  }

  const url = window.location.href;
  const isCadProject = url.split("?");

  var renderComponent = useCallback(() => {
    const variant = {
      0: <ProjectInfoCardAdmin project={project} />,
      1: <AdminProjectForm project={project} updateProject={e => handleUpdateQuota()} loadProjectInfo={loadProjectInfo} handleChangeOnlyMonitore={changeOnlyMonitoreProject} />,
      2: <NotificationsProjectTable />,
      3: <ProjectOffsetsCard project={project} extraMargin />,
      4: <ProjectGoalsCard project={project} extraMargin />
    };
    return variant[activeTab];
  }, [project, activeTab]);

  return (
    <Container>
      <Header>
        <PageTitle
          title={project?.name || "Nome do projeto"}
          subtitle="Confira as informações cadastradas do projeto."
        />
      </Header>
      <Content>
        <AdminTabs
          items={tabs}
          activeTab={activeTab}
          onChange={tab => setActiveTab(tab)}
        />
        <Body>
          {renderComponent()}
        </Body>
      </Content>
    </Container>
  );
};

export default ProjectDetail;
