import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.5rem;
  gap: 1rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
  width: calc(100% - 75px);
`;

export const RowPublic = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 340px);
  gap: 24px;
  width: calc(100% - 24px);
`;

export const OptionsContent = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 19px;

    color: ${({ theme }) => theme.colors.blackGrayish60};
  }
`;

export const CityList = styled.div`
  display: grid;
  grid-template-columns: 134px 292px 100px;
  gap: 1.5rem;

  margin-top: 20px;
`;

export const ContentChip = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;
  max-width: 90%;
  max-height: 88px;
  overflow-y: auto;
  flex-wrap: wrap;
`;