import React, { useState, Fragment } from 'react';
import { Visibility, VisibilityOff } from "@material-ui/icons";

import Autocomplete from 'components/Autocomplete';
import * as S from './styles';
import { IconButton } from '@material-ui/core';

const TransferTable = ({ data, onChange, users, currentUser, partialAccepts, initialOpen }) => {
    const [enableCustomUser, setEnableCustomUser] = useState({});
    const batchKey = Object.keys(data).find(x => data[x].type === "batch");
    const userTotal = data ? Object.values(data)?.map(({ bidders }) => bidders.length)?.reduce((x, y) => x + y, 0) : 0;
    const total = Object.values(data).map(({ bidders }, idx) => (Object.keys(data)[idx] === "unset") ? 0 : bidders.length)?.reduce((x, y) => x + y, 0);
    const batchList = Object.values(data).map((x, key) => (x.type === "batch") && { [Object.keys(data)[key]]: x })?.reduce((x, y) => ({ ...y, ...x }), 0);
    const otherList = Object.values(data).map((x, key) => (x.type !== "batch") && { [Object.keys(data)[key]]: x })?.reduce((x, y) => ({ ...y, ...x }), 0);
    const [showList, setShowList] = useState(initialOpen);

    const removeEntry = (copy, bidderKey, bidderId) => {
        const oldBidders = copy[bidderKey].bidders;
        const newBidders = copy[bidderKey].bidders.filter(x => x.id !== bidderId);

        copy[bidderKey].bidders = newBidders;

        if(copy[bidderKey].bidders.length <= 0)
            delete copy[bidderKey];

        return ({
            newBidders, oldBidders
        });
    }

    const addEntry = (copy, targetKey, bidder, user) => {
        const oldBidders = copy[targetKey]?.bidders || [];

        copy[targetKey] = {
            ...copy[targetKey],
            type: copy[targetKey]?.type || "custom",
            bidders: [...oldBidders, bidder],
            user
        }

        return ({
            newBidders: copy[targetKey].bidders, oldBidders
        });
    }

    const handleToggleCustomUser = (bidder) => () => {
        const value = enableCustomUser[bidder.id];
        const checked = !Boolean(enableCustomUser[bidder.id]);
        const copyEnableCustomUser = Object.assign({}, enableCustomUser);
    
        if(checked || typeof value === 'boolean') {
            copyEnableCustomUser[bidder.id] = checked;
    
            return setEnableCustomUser(copyEnableCustomUser);
        }
        
        const copyTransferBidders = Object.assign({}, data);
    
        copyTransferBidders[batchKey] = {
            ...copyTransferBidders[batchKey],
            bidders: [...copyTransferBidders[batchKey].bidders, bidder]
        }
    
        copyTransferBidders[value.label].bidders = copyTransferBidders[value.label].bidders.filter(x => x.id !== bidder.id);
        
        delete copyEnableCustomUser[bidder.id];
    
        if(copyTransferBidders[value.label].bidders.length <= 0)
            delete copyTransferBidders[value.label];
    
        onChange(copyTransferBidders);
        return setEnableCustomUser(copyEnableCustomUser);
    }

    const handleOnChange = (bidder, originKey) => (selected) => {
        const { label: targetKey, value: user } = selected;
        const copyEnableCustomUser = JSON.parse(JSON.stringify(enableCustomUser));
        const copyTransferBidders = JSON.parse(JSON.stringify(data));

        if(targetKey !== batchKey)
            copyEnableCustomUser[bidder.id] = { label: user.name, value: user };
        else
            delete copyEnableCustomUser[bidder.id];

        setEnableCustomUser(copyEnableCustomUser);
       
        removeEntry(copyTransferBidders, originKey, bidder.id);
        addEntry(copyTransferBidders, targetKey, bidder, user);

        onChange(copyTransferBidders);
    };

    function setTransferBatch(update) {
        const copyEnableCustomUser = JSON.parse(JSON.stringify(enableCustomUser));
        const copyTransferBidders = JSON.parse(JSON.stringify(data));

        if(!update) {
            copyTransferBidders.unset = {
                ...copyTransferBidders[batchKey],
                user: undefined
            }

            delete copyTransferBidders[batchKey];
            
           return onChange(copyTransferBidders);
        }

        const { label, value } = update;

        let updatedBidders = copyTransferBidders[label]?.bidders || [];

        updatedBidders = [...updatedBidders, ...(copyTransferBidders[batchKey]?.bidders || [])];
        setEnableCustomUser(copyEnableCustomUser);

        updatedBidders.map(bidder => delete copyEnableCustomUser[bidder.id]);
    
        copyTransferBidders[label] = {
            type: "batch",
            bidders: updatedBidders,
            user: value
        }
    
        delete copyTransferBidders[batchKey];
        onChange(copyTransferBidders);
    }

    if(!data)
        return <Fragment />;

    return (
        <div>
            <S.BatchAutocompleteContainer>
                <IconButton size="small" onClick={() => setShowList(!showList)}>
                    {showList ? ( <Visibility /> ) : ( <VisibilityOff /> )}
                </IconButton>

                <Autocomplete
                    placeholder="Transferir lote de proponentes para..."
                    options={users}
                    onChange={setTransferBatch}
                    isClearable
                    style={{ flex: 1 }} />
            </S.BatchAutocompleteContainer>

            {showList && (
                <S.EntryList>
                    {Object.values(batchList).map((entry, key) => (
                        <li key={key}>
                            <S.SectionTitle>
                                {(Object.keys(batchList)[key] !== "unset") ?
                                    `[LOTE] ${currentUser.name} > ${Object.keys(batchList)[key]}` :
                                    `Sem destino definido:`}
                            </S.SectionTitle>

                            <S.SubList>
                                {entry.bidders.map((bidder, bidderKey) => (
                                    <li key={bidderKey}>
                                        <S.TransferButton
                                            active={enableCustomUser[bidder.id]}
                                            // onClick={handleToggleCustomUser(bidder)}
                                        >
                                            {bidder.name}
                                        </S.TransferButton>

                                        {enableCustomUser[bidder.id] && (
                                            <Autocomplete
                                                placeholder='Transferir para...'
                                                style={{ flex: 1, marginLeft: "4px" }}
                                                options={users.filter(x =>
                                                    (x.value.id !== currentUser.id) &&
                                                    (x.value.id !== data[batchKey]?.user?.id)
                                                )}
                                                value={(typeof enableCustomUser[bidder.id] !== 'boolean') && enableCustomUser[bidder.id]}
                                                onChange={handleOnChange(bidder, Object.keys(data)[key])} />
                                        )}
                                    </li>
                                ))}
                            </S.SubList>
                        </li>
                    ))}

                    {Object.values(otherList).map((entry, key) => (
                        <li key={key}>
                            <S.SectionTitle>
                                {`Para ${Object.keys(otherList)[key]}:`}
                            </S.SectionTitle>

                            <S.SubList>
                                {entry.bidders.map((bidder, bidderKey) => (
                                    <li key={bidderKey}>
                                        <S.TransferButton
                                            active={enableCustomUser[bidder.id]}
                                            onClick={handleToggleCustomUser(bidder)}>
                                            {bidder.name}
                                        </S.TransferButton>

                                        {enableCustomUser[bidder.id] && (
                                            <Autocomplete
                                                placeholder='Transferir para...'
                                                style={{ flex: 1, marginLeft: "4px" }}
                                                options={users.filter(x =>
                                                    (x.value.id !== currentUser.id) &&
                                                    (x.value.id !== data[Object.keys(otherList)[key]]?.user?.id)
                                                )}
                                                value={(typeof enableCustomUser[bidder.id] !== 'boolean') && enableCustomUser[bidder.id]}
                                                onChange={handleOnChange(bidder, Object.keys(otherList)[key])} />
                                        )}
                                    </li>
                                ))}
                            </S.SubList>
                        </li>
                    ))}
                </S.EntryList>
            )}

            <S.TotalLabel partialAccepts={partialAccepts} isPartial={total < userTotal}>
                Você está transferindo <strong>{total}</strong> proponentes
            </S.TotalLabel>
        </div>
    );
}

export default TransferTable;