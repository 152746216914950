import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 260px;
  background: ${({ theme }) => theme.colors.generalBackground};
  display: flex;
  align-items: center;
  position: fixed;
`;

export const SideMenu = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0px 40px 40px 0px;
  height: 100%;
  width: 260px;
  /* box-shadow: 5px 5px 5px -2px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.0), 0px 5.5px 5px rgba(0, 0, 0, 0.24);
  margin-right: 1.2rem;
`;

export const UserInfo = styled.div`
  min-height: 155px;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.typographyNew.fontFamily};
  color: ${({ theme }) => theme.colors.background};

  p {
    font-size: ${({ theme }) => theme.typography.p2.fontSize};
    font-weight: ${({ theme }) => theme.typography.p2.fontWeight};
    line-height: 12px;
    margin: 0px;
    margin-top: 10px;
  }

  h4 {
    font-size: ${({ theme }) => theme.typography.span.fontSize};
    font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
    line-height: 16px;
    margin: 0px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }
`;

export const UserIcon = styled.div`
  height: 65px;
  width: 65px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
`;

export const MenuOptions = styled.div`
  height: 75%;
  min-height: 200px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div > .newsletter {
    padding-top: 10px;
    margin-left: 10px;
  }
`;

export const MenuItem = styled.div`
  width: 100%;
  height: 50px;
  font-family: ${({ theme }) => theme.typographyNew.fontFamily};
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin: 10px 0px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: 0.2s;

  svg {
    color: ${({ theme }) => theme.colors.grayIcon};
  }

  p {
    margin: 0 0 0 25px;
    opacity: 0.87;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const PoliticsCard = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  margin: 18px;
  border-radius: 40px;
  padding: 15px 15px;
  cursor: pointer;
`;

export const Footer = styled.div`
  padding: 40px 0px;
  display: flex;
  min-width: 225px;
  flex-direction: column;
  align-items: center;
`;

export const WhiteText = styled.p`
  ${({ theme }) => theme.typography.p4};
  color: ${({ theme }) => theme.colors.white};
  margin: 0px 2px;
`;

export const ContrastText = styled.p`
  ${({ theme }) => theme.typography.p4};
  color: ${({ theme }) => theme.colors.light};
  margin: 0px 2px;
`;

export const MonitoreButtonContainer = styled.div`
  padding: 0rem 1.5rem;
  width: 100%;

  img {
    width: 115px;
  }
`;

export const MonitoreButton = styled.div`
  cursor: pointer;
  width: 208px;
  border-radius: 30px;
  padding: 6px 0;
  border: 2px solid ${({ theme }) => theme.colors.grayLight2};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;
  color: black;
  font-family: Roboto,sans-serif;
  font-size: 14px;

  :hover{
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonSend = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.primary};

  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;

  cursor: pointer;
`;