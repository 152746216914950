import React, { useRef } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { useFullScreenImages } from 'hooks/useFullScreenImages';
import { Container, Content, SelectedImage, ThumbnailContainer, Thumbnail } from './styles';
import { v4 } from 'uuid';

const FullScreenImages = () => {
  const { isOpen, close, images, currentImage, selectImage } = useFullScreenImages();
  const containerRef = useRef(null);

  useClickOutside({
    ref: containerRef,
    action: () => {
      if (isOpen) {
        close();
      }
    }
  });

  if (!isOpen) {
    return null;
  }


  return (
    <Container>
      <Content ref={containerRef}>
        <SelectedImage src={images[currentImage]} />
        <ThumbnailContainer>
          {
            images.map((image, index) => (
              <Thumbnail key={v4()} src={image} onClick={() => selectImage(index)} />
            ))
          }
        </ThumbnailContainer>
      </Content>
    </Container>
  );
};

export default FullScreenImages;