import React, { useCallback, useState } from 'react';
import ProponentSidebar from './ProponentSidebar';
import ProponentForm from './ProponentForm';
import { Container } from './styles';
import { useEffect } from 'react';

const ProponentProfileForm = ({ loading, proponent }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [errors, setErrors] = useState({});

  const handleNext = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const handlePrevious = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <Container>
      <ProponentSidebar
        activeStep={activeStep}
        changeStep={step => setActiveStep(step)}
        errors={errors}
      />
      <ProponentForm
        currentStep={activeStep}
        handleNext={() => handleNext()}
        handlePrevious={() => handlePrevious()}
        loading={loading}
        proponent={proponent}
        currentErrors={errors}
        updateErrors={setErrors}
      />
    </Container>
  );
};

export default ProponentProfileForm;
