import React from 'react';
import { Container } from './styles';
import RegisterForm from 'components/Forms/RegisterForm';

const Register = () => {
  return (
    <Container>
      <RegisterForm />
    </Container>
  );
};

export default Register;
