import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 32px 20px 0 24px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;

  color: ${({ theme }) => theme.colors.grayLight1};
`;

export const ContentCard = styled.div`
  width: 90%;
  margin-top: 14px;
  padding-top: 8px;

  ${({ error }) => error && css`
    border: 1px solid red;
    background: #FFD7D4;
    border-radius: 8px;
  `}
`;

export const ProjectCityText = styled.div`
  margin-top: 40px;

  font-size: 14px;
  line-height: 16px;

  color: #5c5050;
`;

export const ImpactDiv = styled.div`
  display: flex; 
  width: 100%;
  marginTop: 31px; 
  gap: 22px;
  margin-top: 22px;

  @media (max-width: 1060px){
    display: grid;
  }
`;

export const ProjectLocalText = styled.div`
  padding-top: 30px;
  font-size: 14px;
  line-height: 16px;

  color: #5c5050;
`;

export const OptionsContent = styled.div`
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 19px;

    color: ${({ theme }) => theme.colors.blackGrayish60};
  }
`;

export const CityList = styled.div`
  display: grid;
  grid-template-columns: 134px 292px 100px;
  gap: 1.5rem;

  margin-top: 20px;
`;

export const ContentChip = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;
  max-width: 90%;
  max-height: 88px;
  overflow-y: auto;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;