import React from 'react';
import { Badge } from '@material-ui/core';
import {
  WorkOutline as ProjectsIcon,
  ExitToApp as ExitIcon,
  AssessmentOutlined as ResultsIcon,
  LibraryBooksOutlined,
  NotificationsOutlined,
  PersonOutline,
  Group,
  AccountCircleOutlined,
  Gavel,
} from '@material-ui/icons';
import { useUser } from 'hooks/useUser';

export const BidderOptions = (badgeContent = '0') => [
  {
    icon:
      badgeContent > 0 ? (
        <Badge badgeContent={badgeContent} color="primary">
          <NotificationsOutlined
            style={{ height: '24px', width: '24px', margin: '0px 10px' }}
          />
        </Badge>
      ) : (
        <NotificationsOutlined
          style={{ height: '24px', width: '24px', margin: '0px 10px' }}
        />
      ),
    label: 'Notificações',
    path: '/notificacoes',
  },
  {
    icon: (
      <ProjectsIcon
        style={{ height: '24px', width: '24px', margin: '0px 10px' }}
      />
    ),
    label: 'Meus Projetos',
    path: '/projetos',
  },
];

export const CompanyOptions = (badgeContent = '0', isMaster = false) => [
  {
    icon:
      badgeContent > 0 ? (
        <Badge badgeContent={badgeContent} color="primary">
          <NotificationsOutlined
            style={{ height: '24px', width: '24px', margin: '0px 10px' }}
          />
        </Badge>
      ) : (
        <NotificationsOutlined
          style={{ height: '24px', width: '24px', margin: '0px 10px' }}
        />
      ),
    label: 'Notificações',
    path: '/admin/notificacoes',
  },
  {
    icon: (
      <Group style={{ height: '24px', width: '24px', margin: '0px 10px' }} />
    ),
    label: 'Proponentes',
    path: '/admin/proponentes',
  },
  {
    icon: (
      <ProjectsIcon
        style={{ height: '24px', width: '24px', margin: '0px 10px' }}
      />
    ),
    label: 'Projetos',
    path: '/admin/projetos',
  },
  {
    icon: (
      <AccountCircleOutlined
        style={{ height: '24px', width: '24px', margin: '0px 10px' }}
      />
    ),
    label: 'Usuários',
    path: '/admin/usuarios',
  },
  {
    icon: (
      <Gavel
        style={{ height: '24px', width: '24px', margin: '0px 10px' }}
      />
    ),
    label: 'Leis',
    path: '/admin/leis',
  }
];
