import { ProjectService } from "services/projects";

export default function updateCaptureContributionMap(contributions, idCapture) {
  contributions.map( async (contrib, index) => {
    const form = new FormData();
    contrib.id && form.append(`id`, contrib.id);
    contrib.proof_financial && form.append(`proof_financial`, contrib.proof_financial);
    contrib.proof_contribution && form.append(`proof_contribution`, contrib.proof_contribution);
    contrib.register_date && form.append(`register_date`, contrib.register_date);
    contrib.date_email && form.append(`date_email`, contrib.date_email);
    contrib.corporate_name && form.append(`corporate_name`, contrib.corporate_name);
    contrib.value && form.append(`value`, contrib.value);
    form.append(`index`, index);
    idCapture && form.append(`capture_id`, idCapture);
    await ProjectService.editContributionProject(form);
  });
}