import React, { useCallback, useEffect, useState } from 'react';
import { Container, ProjectsContainer } from './styles';
import NotificationsFilterAdmin from 'components/NotificationsFilterAdmin';
import NotificationsTable from 'components/NotificationsTable';
import { NotificationService } from 'services/notifications';
import NotificationFormAdmin from 'components/NotificationFormAdmin';

const AdminProponenteNotifications = ({ id }) => {
  const [notifications, setNotifications] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    proponentId: id,
  });

  const loadNotifications = useCallback(async () => {
    try {
      setLoading(true);
      if (Object.keys(filters).length > 0) {
        const response = await NotificationService.search(filters);
        if (Array.isArray(response.data.data)) {
          setNotifications(response.data.data);
        } else {
          setNotifications([]);
        }
      } else {
        const response = await NotificationService.listByProponent(id);
        if (Array.isArray(response.data)) {
          setNotifications(response.data);
        } else {
          setNotifications([]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [filters, id]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <Container>
      <main>
        {(notifications?.length > 0 || Object.keys(filters).length > 0) && (
          <NotificationsFilterAdmin
            filters={filters}
            setFilters={setFilters}
            isProponent
            proponentId={id}
          />
        )}
        <ProjectsContainer>
          <NotificationsTable
            notifications={notifications}
            onRefresh={() => loadNotifications()}
            isLoading={loading}
          />
        </ProjectsContainer>
      </main>
      <NotificationFormAdmin
        open={openForm}
        onClose={() => setOpenForm(false)}
        onAfterSave={() => loadNotifications()}
        proponentId={id}
      />
    </Container>
  );
};

export default AdminProponenteNotifications;
