import styled from 'styled-components';
import Typography from 'components/Typography';

export const Title = styled(Typography)`
  margin: 0;
`;

export const Subtitle = styled.span`
  ${({ theme }) => theme.typography.span};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.colors.black};
`;
