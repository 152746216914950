import React, { useCallback, useEffect, useRef, useState } from 'react';
import Typography from 'components/Typography';
import { useTheme } from 'styled-components';
import Input from 'components/Input';
import CounterPartCards from 'components/CounterPartCards';
import Button from 'components/Button';
import { IconAdd } from 'assets/icons';
import formatCurrency, { currencyMask } from 'utils/currency';
import {
  Container,
  ButtonContent,
  ButtonAddCounterPart,
  ButtonAddText,
  InfoTextContainer,
  Content,
  IconColor,
  ActionsButton,
} from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import { setNestedObjectValues } from 'formik';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import { toast } from 'react-toastify';
import { useUser } from 'hooks/useUser';
import { Tooltip } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';

const Step6 = ({changedFields, setChangedFields}) => {
    const { control, setValue, getValues } = useFormContext();
    const [counterPartList, setCounterPartList] = useState(getValues('quota') ?? []);

    useEffect(()=>{
        setCounterPartList(getValues('quota'));
    }, [getValues('quota')])
    
    const [form, setForm] = useState({
        id: counterPartList[0]?.id ? counterPartList[0].id+1 : 999999,
        quota: '',
        valueOf: '',
        valueUpTo: '',
        counterparts: { '1': '', '2': '', '3': '' },
    });

    const [changeAuthCounterparts, setChangeAuthCounterparts] = useState(changedFields.counterparts);
    changeAuthCounterparts && setValue("auth_quota", !changeAuthCounterparts);

    const [showRegisterInputs, setShowRegisterInputs] = useState(true);
    const [showDivRegister, setShowDivRegister] = useState(false);
    const [errorDuplicate, setErrorDuplicate] = useState(false);
    const [authQuota, setAuthQuota] = useState(getValues('auth_quota'));
    const theme = useTheme();

    const { user } = useUser();
    const roles = [
        'Em rascunho',
        'Em ajuste',
        'Aguardando regularização'
    ];
    let isDisabled = false;
  const project_status = getValues('status');
  
  if((user.adminRole === 'cs_proponent1' || user.adminRole === 'cs_proponent2' || user.adminRole === 'cs_proponent_relationship') && !roles.includes(project_status)){
    isDisabled = true;
  } else if((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
    isDisabled = true;
  } else if(user.adminRole === 'general' || user.adminRole === 'monitore') {
    isDisabled = true;
  }

    const addCounterPartInputs = () => {
        const length = Object.keys(form.counterparts).length + 1;
        setForm({
            ...form,
            counterparts: { ...form.counterparts, [length]: '', },
        });
    };

    const handleChange = item => {
        setShowRegisterInputs(prevState => !prevState);
        setShowDivRegister(prevState => !prevState);

        setForm({
            ...form,
            ...item,
        });

        const updatedArray = counterPartList.filter(value => value.id !== item.id);
        setCounterPartList(updatedArray);
        setValue('counterparts', updatedArray);
        setValue('quota', updatedArray);
        setCounterPartList(getValues('quota'));

        // console.log("QUOTA", updatedArray);
        // console.log("counterparts", counterPartList);
    };

    const handleAddCounterPartList = () => {
        if (
            form.counterparts[0] === '' ||
            form.counterparts[1] === '' ||
            form.counterparts[2] === '' ||
            form.quota === '' ||
            form.valueOf === '' ||
            form.valueUpTo === ''
        ){
            toast.error('Atenção: Um dos campos de contrapartidas está em branco. Preencha ou exclua antes de salvar a cota cadastrada.')
            return;
        }
        setCounterPartList([...counterPartList, form]);
        setValue('counterparts', [...counterPartList, form]);
        setValue('quota', [...counterPartList, form]);
        setCounterPartList(getValues('quota'));
        setForm({
            id: form.id + 50,
            counterparts: { '1': '', '2': '', '3': '' },
            quota: '',
            valueOf: '',
            valueUpTo: '',
        });
        console.log("QUOTA", [...counterPartList, form]);
        console.log("counterparts", counterPartList);

        setShowRegisterInputs(prevState => !prevState);
        setShowDivRegister(prevState => !prevState);
    };

    const hasDuplicates = (array) => {
        return (new Set(array)).size !== array.length;
    }

    const existCounterpart = (counterparts, value) => {
        // eslint-disable-next-line array-callback-return
        let count = 0;
        Object.keys(counterparts).forEach((key)=> {
            if(counterparts[key].toLowerCase() === value.toLowerCase()){
                count++;
            }

            else{
                if(hasDuplicates(Object.values(form.counterparts))){
                    count++;
                }
            }
        })
        
        if((count !== 0 && value !== '')) {
            setErrorDuplicate(true);
        }

        else{
            setErrorDuplicate(false);
        }
    }

    const mounted = useRef();

    useEffect(() => {
        if(!mounted.current){
            setShowRegisterInputs(prevState => !prevState);
            mounted.current = true;
        }
    });

  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Contrapartidas
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Quais as contrapartidas oferecidas pelo projeto aos patrocinadores?
        </Typography>
      </InfoTextContainer>
      <Content>
          <AdminFormGroup
              checked={changeAuthCounterparts ? false : authQuota}
              onChange={value => {
                  setAuthQuota(value);
                  setValue("auth_quota", value);
                  setChangeAuthCounterparts(!changeAuthCounterparts);
                  value && setChangedFields({...changedFields, counterparts: false});
              }}
          > 
            <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                {
                    (changedFields.counterparts && changeAuthCounterparts) && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                    )
                }
            </div>
            {!showRegisterInputs &&
            counterPartList.map((item, index) => {
                return (
                    <div style={{ marginTop: 24 }}>
                        <CounterPartCards
                            key={item.id}
                            title={item.quota}
                            cards={item}
                            onClick={() => handleChange(item)}
                        />
                    </div>
                );
            })}
            {showDivRegister && (
                <>
                    <div style={{ marginTop: 24 }}>
                        {showRegisterInputs && (
                        <div style={{ display: 'flex', gap: 24, width: '100%' }}>
                            <div style={{ width: '70%' }}>
                            {/* <Controller
                                name="counterpart"
                                control={control}
                                render={({ field }) => (
                                <Input width="660px" label="Cota" {...field} />
                                )}
                            /> */}
                            <Input
                                // width="70%"
                                label="Cota *Obrigatória"
                                onChange={e => setForm({ ...form, quota: e.target.value })}
                                value={form.quota}
                            />
                            </div>

                            <div style={{ width: '15%' }}>
                            <Controller
                                name="minimum_value"
                                control={control}
                                render={({ field }) => (
                                <Input
                                    // width="15%"
                                    label="Valor de *Obrigatória"                                   
                                    {...field}
                                    onChange={e =>
                                    setForm({ ...form, valueOf: currencyMask(e.target.value) })
                                    }
                                    value={`R$ ${form.valueOf}`}
                                />
                                )}
                            />
                            </div>

                            <div style={{ width: '15%' }}>
                            <Input
                                // width="15%"
                                label="Valor até *Obrigatória"    
                                value={`R$ ${form.valueUpTo}`}                       
                                onChange={e => setForm({ ...form, valueUpTo: currencyMask(e.target.value) })}
                            />
                            </div>
                        </div>
                        )}

                        {showRegisterInputs &&
                        Object.entries(form.counterparts).map((item, index) => (
                            <div style={{ marginTop: 24 }} key={item[0]}>
                            {item[0] !== 'title' && (
                                <Input
                                height="62px"
                                // placeholder={`Contrapartida ${(parseInt(item[0]))}`}
                                label={`Contrapartida`+ (index === 0 ? ' *Obrigatória' : ` 0${item[0]}`)}
                                value={item[1]}
                                onChange={e => {
                                    setForm({
                                    ...form,
                                    counterparts: {
                                        ...form.counterparts,
                                        [item[0]]: e.target.value,
                                    },
                                    })
                                    existCounterpart(form.counterparts, e.target.value);
                                }}
                                />
                            )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            {/* <ButtonContent>
                <ActionsButton>
                    {showRegisterInputs && showDivRegister && (
                        <Button
                        borderColor="transparent"
                        variant="secondary"
                        color={theme.colors.primary}
                        onClick={handleChange}
                        >
                        Cancelar Cota
                        </Button>
                    )}

                    {showRegisterInputs && Object.keys(form.counterparts).length > 1 && showDivRegister && (
                        <Button
                        borderColor="transparent"
                        variant="secondary"
                        color={theme.colors.primary}
                        onClick={() => {
                            delete form.counterparts[(Object.keys(form.counterparts).length)];
                            setForm({
                            ...form,
                            counterparts: form.counterparts,
                            });
                        }}
                        >
                        Excluir contrapartida
                        </Button>
                    )}

                    <Button
                        variant="secondary"
                        color={errorDuplicate ? 'grey' : theme.colors.primary}
                        onClick={showRegisterInputs ? handleAddCounterPartList : handleChange}
                        disabled={(user.adminRole === 'cs_proponent_relationship' ? false : isDisabled) || errorDuplicate}
                    >
                        {showDivRegister ? 'SALVAR COTA' : 'CADASTRAR NOVA COTA'}
                    </Button>

                    {errorDuplicate && (
                        <Tooltip title="As contrapartidas não podem ter o mesmo nome." placement="top">
                            <WarningOutlined style={{color: 'red', width: '1.35rem', marginLeft: '5px'}} />
                        </Tooltip>
                    )}
                </ActionsButton>
                {showRegisterInputs && showDivRegister && (
                    <ButtonAddCounterPart onClick={addCounterPartInputs} type="button" style={{ cursor: 'pointer' }}>
                        <IconColor><IconAdd width={20} height={20} /></IconColor>
                        <ButtonAddText style={{color: theme.colors.primary}}>ADICIONAR CONTRAPARTIDA</ButtonAddText>
                    </ButtonAddCounterPart>
                )}
            </ButtonContent> */}
        </AdminFormGroup>
      </Content>
    </Container>
  );
};

export default Step6;