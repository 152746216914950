import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.lighter};
  padding: 20px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const ProjectIcon = styled.div`
  height: 140px;
  min-width: 140px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 15px;

  img {
    height: 140px;
    width: 140px;
    border-radius: 15px;
    object-fit: cover;
  }
`;

export const HeaderCompany = styled.h2`
  ${({ theme }) => theme.typography.h2};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 22px;
`;

export const HeaderTitle = styled.h2`
  ${({ theme }) => theme.typography.title};
  color: ${({ theme }) => theme.colors.background};
  font-size: 45px;
  line-height: 45px;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
`;

export const HeaderDescription = styled.p`
  ${({ theme }) => theme.typography.p2};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const CardsConatiner = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 375px auto;
  grid-template-rows: 271px auto;
  gap: 1.5rem;
`;

export const EndCaptureContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 222px;
  justify-content: space-between;
`;

export const CardContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.lighter};
  border-radius: 30px;

  &.first-card {
    .content-row {
      margin-top: 0.5rem;
    }

    p:nth-child(even) {
      margin-bottom: 8px;
    }
    p:nth-child(odd) {
      margin-bottom: 16px;
    }

    .subcategory-scroll {
      display: flex;
      flex-direction: column;
      min-height: 50px;

      overflow-y: auto;
    }
  }

  /* &.project-content {
    justify-content: space-between;
  } */
  color: ${({ theme }) => theme.colors.background};
`;

export const CardTitle = styled.span`
  ${({ theme }) => theme.typography.span};
  color: ${({ theme }) => theme.colors.secondary};
  align-self: center;
`;

export const CardContent = styled.p`
  ${({ theme }) => theme.typography.p2};
  color: ${({ theme }) => theme.colors.background};
  margin-top: 5px;
`;

export const CardTextContent = styled.p`
  ${({ theme }) => theme.typography.p2};
  color: ${({ theme }) => theme.colors.background};
  margin-top: 1rem;
`;

export const CardProjectContent = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media (max-width: 1820px) {
    .ods-div {
      max-height: 11em !important;
    }
  }

  @media (max-width: 1470px) {
    .ods-div {
      max-height: 7em !important;
    }
  }

  @media (max-width: 1295px) {
    .ods-div {
      max-height: 5em !important;
    }
  }

  .ods-div {
    max-height: 11em;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .updateText {
    color: ${({ theme }) => theme.colors.primary};
  }

  .updateDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .lastUpdateText{
    text-align: center;
    margin-top: 8px;
    font-size: 16px;
  }
`;

export const CardODSContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 1.5rem;
  
  &.sponsorBox {
    div {
      p {
        max-height: 45px;
        overflow-y: auto;
        margin-bottom: 10px;
      }
    }
  }
`;

export const CardCategory = styled.p`
  ${({ theme }) => theme.typography.p3};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  svg {
    vertical-align: top;
  }
`;