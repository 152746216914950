import api from './api';

const PATH = '/laws';

const list = () => api.get(PATH);
const listConectaLaws = (page) => api.get(`${PATH}/list-conecta-laws`, { params: { page }});
const deleteLaw = id => api.delete(`${PATH}/delete/${id}`);
const addLaw = data => api.post(PATH, data);
const updateLaw = (id, data) => api.post(`${PATH}/update/${id}`, data);
const showLaw = id => api.get(`${PATH}-show/${id}`);
const getStates = (params = {}) => api.get(`${PATH}/get-states`, { params: { ...params }});
const getCities = (params = {}) => api.get(`${PATH}/get-cities`, { params: { ...params }});
const getConectaLaws = (params = {}) => api.get(`${PATH}/get-conecta-laws`, { params: { ...params }});

export const LawsService = {
  list,
  listConectaLaws,
  deleteLaw,
  addLaw,
  updateLaw,
  showLaw,
  getStates,
  getCities,
  getConectaLaws,
}