import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const DeleteProponent = ({ open, handleClose, onClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Tem certeza que deseja excluir?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Essa operação é irreversivel. Após a exclusão, os dados referentes ao proponente e seus projetos serão perdidos. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Voltar
        </Button>
        <Button onClick={onClick} color="primary">
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteProponent;