import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &:last-of-type {
    .step-content {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const IconStep = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, active, error }) => (error) ? "red" : (active ? theme.colors.primary : theme.colors.grayDark2)};
`;

export const Body = styled.div`
  border-left: 2px solid ${({ theme }) => theme.colors.grayLight1};
  height: 100%;
  margin: 0.5rem 0 1rem 11px;
  padding: 0.75rem 1.5rem;
`;