import React, { useCallback, useEffect } from 'react';
import Typography from 'components/Typography';

import { Container, Form, Content, Row, Actions } from './styles';
import Input from 'components/Input';
import InputPassword from 'components/InputPassword';
import Button from 'components/Button';
import { useUser } from 'hooks/useUser';
import { useForm, Controller } from 'react-hook-form';
import { profileAdminValidationResolver } from 'validations/profileAdmin';
import { phoneMask } from 'utils/maskUtils';
import { LoginService } from 'services/login';
import { toast } from 'react-toastify';
import { UsersService } from 'services/users';
import FileInput from 'components/FileInput';
import { ProponentService } from 'services/proponent';
import { SponsorsService } from 'services/sponsors';

const AdminProfileForm = () => {
  const { user, updateAvatar } = useUser();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: profileAdminValidationResolver,
    defaultValues: {
      name: user.name,
      email: user.email,
      phone: user.phone,
      password: '',
      new_password: '',
    },
  });

  const onCancel = useCallback(() => {
    reset({
      email: '',
      name: '',
      phone: '',
      password: '',
      new_password: '',
    });
  }, [reset]);

  const changePassword = useCallback(async data => {
    try {
      const response = await LoginService.changePassword(data);
      if (response.data.erro) {
        toast.error(response.data.erro);
      } else {
        toast.success('Senha alterada com sucesso!');
      }
    } catch (err) {
      toast.error('Falha ao alterar senha');
    }
  }, []);

  const onSubmit = useCallback(
    async data => {
      try {
        const { name, email, phone, password, new_password } = data;
        const my_data = true;
        const payload = {
          id: user?.id,
          name,
          email,
          role: user?.userRole,
          phone
        };
        if (password && new_password) {
          await changePassword({ password, new_password, my_data });
        }
        const promises = [
          UsersService.updateUser(payload)
        ];

        if (phone && user?.proponentId) {
          promises.push(ProponentService.update(user?.proponentId, { phone }));
        }
        await Promise.all(promises);
        toast.success('Dados cadastrais atualizados com sucesso!');
      } catch (err) {
        console.log('err', err)
        toast.error('Falha ao atualizar dados cadastrais!');
      }
    },
    [changePassword, user],
  );

  const handleUploadImage = useCallback(async (files) => {
    try {
      const formData = new FormData();
      formData.append('image', files[0], files[0].name);

      const response = await UsersService.uploadUserImage(user?.id, formData);

      if (response.status === 203) {
        Object.keys(response.data).map(item =>
          toast.error(response.data[item][0]),
        );
      }

      if(response.data.image){
        const image = response.data.image;
        updateAvatar(image);
      }

      toast.success('Imagem atualizada com sucesso!');
    } catch (err) {
      if (err.response !== undefined) {
        if(err.response.status === 413){
          toast.error('Não são aceitos arquivos maiores do que 2MB');
        }
      }
    }
  }, [user, updateAvatar]);

  async function loadProponentData() {
    try {
      const response = await ProponentService.getOneProponent(user?.proponentId);
      if (Array.isArray(response.data)) {
        setValue('phone', phoneMask(response.data[0].phone));
      }
    } catch (err) {
      toast.error('Falha ao carregar dados do proponente!');
    }
  }
  
  async function loadUserData() {
    try {
      const response = await UsersService.getUserById(user?.id);
      // console.log('response.data', response.data)
      if (typeof response.data === "object") {
        setValue('phone', phoneMask(response.data.phone));
        setValue('name', response.data.name);
        // updateAvatar(response.data.photoProfile);
      }
    } catch (err) {
      toast.error('Falha ao carregar dados do proponente!');
    }
  }

  useEffect(() => {
    if (user?.proponentId) {
      loadProponentData();
    } else {
      loadUserData();
    }

  }, [user, setValue, updateAvatar]);

  return (
    <Container>
      <Typography variant="h6" color="grayDark2">
        Dados cadastrais
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              label="Nome completo"
              error={errors?.name?.message}
              {...field}
            />
          )}
        />
        <Row>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <Input
                label="Celular"
                width="360px"
                {...field}
                onChange={e => {
                  const value = phoneMask(e.target.value);
                  setValue('phone', value);
                }}
              />
            )}
          />
          <FileInput
            files={user?.avatar ? [user?.avatar] : []}
            onChange={handleUploadImage}
            buttonText={user?.avatar ? 'ALTERAR' : 'UPLOAD'}
          />
        </Row>
        <Typography variant="h6" color="grayDark2">
          Dados de acesso
        </Typography>
        <Content>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                label="Email"
                error={errors?.email?.message}
                disabled
                {...field}
              />
            )}
          />
          <Row>
            <Controller
              control={control}
              name="password"
              render={({ field }) => <InputPassword label="Senha" {...field} error={errors["password"]?.message}/>}
            />
            <Controller
              control={control}
              name="new_password"
              render={({ field }) => (
                <InputPassword label="Confirmar senha" {...field} error={errors["new_password"]?.message}/>
              )}
            />
          </Row>
        </Content>
        {/* <Actions>
          <Button variant="secondary" onClick={() => onCancel()}>
            CANCELAR
          </Button>
          <Button type="submit">ENVIAR</Button>
        </Actions> */}
      </Form>
    </Container>
  );
};

export default AdminProfileForm;
