import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider, Controller, useFormContext } from 'react-hook-form';
import Button from 'components/Button';

import {
  Container,
  Form,
  FooterActions,
  Row,
  CheckContent,
  FooterBack,
} from './styles';
import { useCallback } from 'react';
import PageTitle from 'components/PageTitle';
import { createLawValidationResolver } from 'validations/createLaws';
import { useUser } from 'hooks/useUser';
import Typography from 'components/Typography';
import Input from 'components/Input';
import Select from 'components/Select';
import { LocationsService } from 'services/locations';
import { ProjectService } from 'services/projects';
import { LawsService } from 'services/laws';
import DatePicker from 'components/DatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import { formatNull } from 'utils/formatNull';
import SelectAutocomplete from 'components/SelectAutocomplete';

const RegisterLaw = ({ law, id, law_created_at }) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const handleLawInfo = useCallback(() => {
    setValue('id', Number(law.id));
    setValue('name', law.name);
    setValue('type', law?.type);
    setValue('coverage', law?.coverage);
    setValue('category_law', law?.category_law);
    setValue('state', law?.state);
    setValue('city', law?.city);
    setValue('number', law?.number);
    setValue('law_created_at', law?.law_created_at);
    setValue('release_to_laws_list', law?.release_to_laws_list);

    if (law.state) {
      loadCities(law.state);
    }
    if (law.release_to_laws_list) {
      setCheckboxState(true);
    }
    if (law.law_created_at) {
      setCreatedAt(law.law_created_at);
    }
  }, [law, id]);

  useEffect(() => {
    if (law) {
      handleLawInfo();
    }
  }, [law]);

  const [checkboxState, setCheckboxState] = useState(false);
  const [createdAt, setCreatedAt] = useState(null);
  const { user } = useUser();
  const [locationsUf, setLocationsUf] = useState([]);
  const [cities, setCities] = useState([]);
  let stored_id = useRef();
  const [isSaving, setIsSaving] = useState(false);

  const loadFormInfo = useCallback(async () => {
    const response = await ProjectService.getFormInfo();
    setLocationsUf(response.data.locations_uf);
  }, []);

  const loadCities = useCallback(async value => {
    const response = await ProjectService.getCity(value);
    setCities(response.data);
  }, []);

  const law_types = [
    'Fundo',
    'Lei'
  ];

  const coverage = [
    'Federal',
    'Estadual',
    'Municipal'
  ];

  const categorys_law = [
    'Cultura',
    'Esporte',
    'Infância e adolescência',
    'Idosos',
    'Saúde'
  ];

  useEffect(() => {
    loadFormInfo();
  }, [loadFormInfo]);

  const handleChangeCheckboxState = useCallback(() => {
    const release_to_laws_list = checkboxState ? 0 : 1;
    setValue('release_to_laws_list', release_to_laws_list);
    setCheckboxState(prevState => !prevState);
  }, [checkboxState]);

  const onSubmit = useCallback(
    async data => {
      const form = new FormData();

      console.log('data', data);
      if (!data.name) {
        toast.error("Nome da Lei é obrigatório");
        return;
      }
      form.append('name', formatNull(data.name));
      form.append('type', formatNull(data.type));
      form.append('coverage', formatNull(data.coverage));
      form.append('category_law', formatNull(data.category_law));
      form.append('number', formatNull(data.number));
      form.append('state', formatNull(data.state));
      form.append('city', formatNull(data.city));
      form.append('law_created_at', formatNull(data.law_created_at));
      form.append('release_to_laws_list', formatNull(data.release_to_laws_list));

      const formData = new FormData();
      for (var data of form.entries()) {
        if (data[1] !== 'undefined' & data[1] !== 'NaN' && data[1] !== '') {
          formData.append(data[0], data[1])
        }
      }

      try {
        if (stored_id.current) {
          const response = await LawsService.updateLaw(stored_id.current, formData);
          if (response.status === 201) {
            toast.success('Lei salva com sucesso!');
          }
          // else {
          //   orderFeedbackErrorMessage(response.data);
          // }
        } else {
          const response = await LawsService.addLaw(formData);
          if (response.status === 201) {
            toast.success('Lei salva com sucesso!');
            stored_id.current = response.data.id;
          }
          // else {
          //   orderFeedbackErrorMessage(response.data);
          // }
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error('Falha ao salvar lei');
        }
      }
    },
    [id, law],
  );

  useEffect(() => {
    stored_id.current = id;
  }, [id]);

  return (
    <FormProvider>
      <PageTitle
        title="Cadastro de Lei"
        subtitle="Preencha os campos abaixo com as informações da lei"
      />
      <Container>
        <div>
          <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
            Adicionar uma lei
          </Typography>
          {/* <Typography variant="subtitle2" color="grayLight1">
            Insira as informações sobre a lei.
          </Typography> */}
        </div>
        <Form style={{ height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Input
                  label="Nome da Lei"
                  {...field}
                  style={{ marginBottom: '1.5rem' }}
                />
              )}
            />
            <Row>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Tipo de Lei"
                    options={law_types.map(law => law)}
                    onChange={e => {
                      setValue('type', law_types[e.target.value]);
                    }}
                    value={law_types.findIndex(
                      item => item === getValues('type'),
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="coverage"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Abrangência"
                    options={coverage.map(law => law)}
                    onChange={e => {
                      setValue('coverage', coverage[e.target.value]);
                    }}
                    value={coverage.findIndex(
                      item => item === getValues('coverage'),
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="number"
                render={({ field }) => (
                  <Input
                    label="Número da Lei"
                    type="number"
                    {...field}
                  />
                )}
              />
            </Row>
            <Row>
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <Select
                    onChange={e => {
                      const state = locationsUf[e.target.value].state_code
                      setValue('state', state)
                      loadCities(state)
                    }}
                    options={locationsUf.map(item => item.state_code)}
                    label="Estado"
                    value={locationsUf.findIndex(
                      item => item.state_code === getValues('state'),
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="city"
                render={({ field }) => (
                  <Select
                    onChange={e => {
                      const city = cities[e.target.value].name
                      setValue('city', city)
                    }}
                    options={cities.map(item => item.name)}
                    label="Município"
                    value={cities.findIndex(
                      item => item.name === getValues('city'),
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="category_law"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Categoria da lei"
                    options={categorys_law.map(law => law)}
                    onChange={e => {
                      setValue('category_law', categorys_law[e.target.value]);
                    }}
                    value={categorys_law.findIndex(
                      item => item === getValues('category_law'),
                    )}
                  />
                )}
              />
            </Row>
            <Row>
              <DatePicker
                label="Data de criação"
                onChange={value => {
                  const dateValue = value.toLocaleDateString("en-CA");

                  setValue('law_created_at', dateValue);
                }}
                defaultValue={law?.law_created_at}
              />
              <CheckContent>
                <Typography variant="body1" color="grayDark2" onClick={() => handleChangeCheckboxState()} className="subtitle">
                  Liberar na lista da Conecta?
                </Typography>
                <Checkbox
                  checked={checkboxState}
                  onClick={handleChangeCheckboxState}
                  style={{ padding: 0 }}
                />
              </CheckContent>

            </Row>
          </div>
          <FooterBack>
            <FooterActions>
              <Button variant="secondary" type="submit"
              // onClick={()=>{
              //   setValue('status', null)
              //   setIsFinishProject(false)
              // }}
              >
                SALVAR
              </Button>
            </FooterActions>
          </FooterBack>
        </Form>
      </Container>
    </FormProvider>
  );
};

export default RegisterLaw;
