import React from 'react';

import { Container, Content } from './styles';
import avatarDefault from 'assets/images/user-default.png';
import Typography from 'components/Typography';

const ProponentInfoCard = ({ proponent }) => {
  console.log(proponent);
  return (
    <Container>
      <img src={proponent?.photoProfile || avatarDefault} alt={proponent?.fullName} />
      <Content>
        <Typography variant="h3" color="black">{proponent?.fullName || proponent?.name}</Typography>
        <Typography variant="subtitle2" color="darkGray">
          {proponent?.legalPerson && proponent?.legalPerson !== "null" ? proponent?.legalPerson : 'Não informado'}
        </Typography>
        <Typography variant="body1" color="black" className="text-justify">
          {proponent?.about && proponent?.about !== "null" ? proponent?.about : 'Não informado'}
        </Typography>
      </Content>
    </Container>
  );
};

export default ProponentInfoCard;