import { useCallback, useEffect } from 'react';

export const useClickOutside = ({ ref, action }) => {
  const handleClickOutside = useCallback(
    event => {
      if (!ref.current?.contains(event.target)) {
        action();
      }
    },
    [action, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);
};
