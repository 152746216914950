import { useDispatch, useSelector } from 'react-redux';
import {
  addImagesAction,
  nextImageAction,
  previousImageAction,
  openFullscreenAction,
  closeFullscreenAction,
  seletImageAction
} from 'store/ducks/fullScreenImage/actions';

export const useFullScreenImages = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.fullScreenImages);

  const addImages = (images = []) => {
    dispatch(addImagesAction(images));
  };

  const open = () => {
    dispatch(openFullscreenAction());
  };

  const close = () => {
    dispatch(closeFullscreenAction());
  };
  
  const nextImage = () => {
    dispatch(nextImageAction());
  };
  
  const previousImage = () => {
    dispatch(previousImageAction());
  };
  
  const selectImage = (index = 0) => {
    dispatch(seletImageAction(index));
  };

  return {
    currentImage: data.currentImage,
    isOpen: data.show,
    images: data.images,
    addImages,
    open,
    close,
    nextImage,
    previousImage,
    selectImage,
  }

};
