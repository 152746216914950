import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 1100px;
  
  h6 + h6 {
    margin-top: 1rem;
  }

  @media (max-width: 1700px) {
    max-width: 900px;
  }
  @media (max-width: 1550px) {
    max-width: 52vw;
  }

  @media (max-width: 1450px) {
    max-width: 49vw;
  }

  @media (max-width: 1370px) {
    max-width: 44vw;
  }

  @media (max-width: 1250px) {
    max-width: 40vw;
  }

  @media (max-width: 960px){
    max-width: 100%;
    width: 100%;
  }
`;
export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  div > div {
    width: 100%!important;
  }
`;

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  ${({ colSize }) => css`
    grid-template-columns: repeat(${colSize}, 1fr);
  `}

  @media (max-width: 1700px) {
    ${({ colSize }) => css`
      grid-template-columns: repeat(${Number(Math.ceil(colSize/2))}, 1fr);
    `}
  }

  @media (max-width: 1350px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const DocumentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  > div {
    margin-top: 0.5rem;
  }
`;

export const DivMargin = styled.div`
  margin-top: 30px;
`;

export const DivAdd = styled.div`
  display: flex;

  button {
    margin-left: 46px;
    height: 38px;
    width: 150px;
    align-self: center;
  }

  @media (max-width: 1300px){
    display: inline-flex;
  }
`;

export const ContentChip = styled.div`
  margin: 24px 0 21px;

  display: flex;
  gap: 20px;

  flex-wrap: wrap;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

`;

export const AddButton = styled.div`
  width: 40px;
  height: 46px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grayLight1};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 20px;

  font-size: 20px;
  color: ${({ theme }) => theme.colors.grayDark2};

  cursor: pointer;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

export const EnterpriseAdd = styled.div`
  width: 606px

  @media (max-width: 1300px){
    width: 606px
  }
`;

export const LocationTwoRow = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 150px 1fr;

  @media (max-width: 1150px){
    grid-template-columns: auto;
  }
`;

export const LocationThreeRow = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 150px 1fr 1fr;

  @media (max-width: 1150px){
    grid-template-columns: auto;
  }
`;