import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.grayIce};
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;

  .incentiveLaws {
    height: 120px;
    overflow: auto;

    @media (max-width: 1000px){
      height: auto;
    }
  }
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;

  svg {
    margin-right: 1rem;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;

  @media (max-width: 1000px){
    grid-template-columns: auto;
    margin-top: 20px!important;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SocialNetworkContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  img {
    cursor: pointer;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* flex: 1; */

  div + div {
    margin-top: 80px;
  }
`;