import React, { useMemo } from 'react';
import Typography from 'components/Typography';
import Step from 'components/Step';

import { Container, Content, TitleContainer } from './styles';
import { useUser } from 'hooks/useUser';

const ProponentSidebar = ({ activeStep, changeStep, isAdmin = false, errors }) => {
  const { user } = useUser();
  const isHiddedToGeneral = user.adminRole === 'general';
  const isNotHiddenCnd = user.adminRole === 'evaluator' || 
                         user.adminRole === 'master' || 
                         user.adminRole === 'key_account2' || 
                         user.adminRole === 'cs_proponent_relationship' || 
                         user.adminRole === 'cs_proponent2';

  const steps = useMemo(() => {
    const items = [
      {
        number: 1,
        title: 'Dados cadastrais',
        description:
          'Selecione o tipo do seu perfil como proponente e preencha as demais informações.',
      },
      {
        number: 2,
        title: 'Testemunha',
        description:
          'Indique uma pessoa para assinar o contrato digital como testemunha.',
      },
      {
        number: 3,
        title: 'Imagens, vídeos e presença digital',
        description:
          'Escolha as imagens e vídeos que melhor representam a sua instituição! Informe também suas redes sociais oficiais.',
      },
    ];

    if(isAdmin) {
      if(isNotHiddenCnd){
        items.push({
          number: 4,
          title: 'CNDs',
          description: 'Texto explicativo sobre as CNDs',
        });
      }

      if(!isHiddedToGeneral){
        items.push({
          number: !isNotHiddenCnd ? 4 : 5,
          title: 'Análise',
          description: 'Entre em contato com o proponente para solicitar as correções necessárias.',
        });
      }
    } else {
      items.push(
      // {
      //   number: 4,
      //   title: 'Dados de acesso',
      //   description:
      //     'Definaaaa seu login e senha de acesso à plataforma. Caso tenha algum problema com a senha e precise alterá-la, basta clicar em “Alterar a senha”.',
      // },
      {
        number: 4,
        title: 'Regras',
        description:
          'Leia as regras de utilização do sistema e aceite',
      })
    }

    return items;
  }, [isAdmin]);
  return (
    <Container isAdmin={isAdmin}>
      <Content>
        <TitleContainer>
          <Typography variant="h6" color="grayDark2">
            Etapas do cadastro
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Essas são as etapas que você vai percorrer para cadastrar o seu
            perfil.
          </Typography>
        </TitleContainer>
        <Step
          currentStep={activeStep}
          steps={steps}
          changeStep={step => changeStep(step)}
          errors={errors}
        />
      </Content>
    </Container>
  );
};

export default ProponentSidebar;
