import styled from 'styled-components';
import { pixelsToRem } from 'utils/styles';
import { rgba } from 'polished';

export const Container = styled.div`
  border-radius: ${pixelsToRem(30)};
  padding: 1.5rem;
  background: ${({ theme }) => rgba(theme.colors.grayLight3, 0.1)};
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  gap: 0.5rem;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;
`;
