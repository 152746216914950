import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ArrowForward } from '@material-ui/icons';
import Table from 'components/Table';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { EditContainer, TesteContainer } from './styles';
import { toggleEditUserModal } from 'store/ducks/modals/actions';
import AdmLawsTableDetails from './AdmLawsTableDetails';

const LawsTable = ({ 
  data,
  identification,
  isLoading,
  currentPage,
  onChangePage,
  totalPages,
  onRefresh,
}) => {
  const { push } = useHistory();

  console.log('data', data)
  const columns = useMemo(() => {
    return [
      {
        identification: 'number',
        name: 'Número',
        showTitle: true,
      },
      {
        identification: 'name',
        name: 'Nome',
        showTitle: true,
      },
      {
        identification: 'type',
        name: 'Tipo',
        showTitle: true,
      },
      {
        identification: 'release_to_laws_list',
        name: 'Lei liberada?',
        showTitleRenderColumn: true,
        renderColumn: ({ release_to_laws_list }) => {
          return (
            <div>
              {release_to_laws_list 
              ? <Typography style={{fontWeight: 500}}variant="caption" color="brown">Sim</Typography>
              : <Typography style={{fontWeight: 500}}variant="caption" color="brown">Não</Typography>
              }
              
            </div>
          );
        },
      }
      // {
      //   identification: 'id',
      //   name: 'Editar lei',
      //   showTitle: true,
      //   isDetail: true,
      //   action: ({ id }) => push(`/leis/${id}`),
      // },
    ];
  }, [push]);

  return (
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={data}
      onRefresh={onRefresh}
      isPaginated={true}
      loading={isLoading}
      currentPage={currentPage}
      onChangePage={onChangePage}
      totalPages={totalPages}
    />
  );
};

export default LawsTable;
