import React from 'react';
import { Container } from './styles';
import facebookIcon from 'assets/images/facebook.png';
import linkedinIcon from 'assets/images/linkedin.png';

function LoginButtonSocial({
  children,
  color,
  socialType = 'LinkedIn',
  ...rest
}) {
  return (
    <Container variant="outlined" color={color} {...rest}>
      {socialType === 'LinkedIn' ? (
        <img src={linkedinIcon} alt="icon" />
      ) : (
        <img src={facebookIcon} alt="icon" />
      )}
      {children}
    </Container>
  );
}

export default LoginButtonSocial;
