import api from './api';

const PATH = '/notifications';

const list = page => api.get(PATH, { params: { page } });
const search = (params = {}, page) => api.get(`${PATH}/search`, { params: { ...params, page } });
const create = data => api.post(`${PATH}/send`, data);
const update = (id, data) => api.put(`${PATH}/${id}`, data);
const read = id => api.put(`${PATH}/read/${id}`);
const unread = id => api.put(`${PATH}/${id}`);
const listByProject = id => api.get(`${PATH}/project/show/${id}`);
const listByProponent = proponentId => api.get(`${PATH}/proponent/show/${proponentId}`);
const listSubjects = data => api.get(`${PATH}/list-subjects`, data);

export const NotificationService = {
  list,
  search,
  create,
  update,
  read,
  unread,
  listByProject,
  listByProponent,
  listSubjects,
};
