import { format } from 'date-fns';

export const formatDate = (date, formatString) => {
  try {
    if (typeof date !== Date) {
      return format(new Date(date), formatString);
    }
    return format(date, formatString);
  } catch {
    return 'Data inválida';
  }
};

export const dateFormattedToDateISO = date => {
  if (date) {
    const formatted_date = date.split('/');
    return formatted_date;
  }
};

export const datetimeFormatted = datetime => {
  if (datetime){
    const data = new Date(datetime);
    return `${data.getDate()}/${data.getMonth()+1}/${data.getFullYear()} ${data.getHours()}:${data.getMinutes()}:${data.getSeconds()}`;
  }
  return "Data inválida"
};

export const dateFormattedBr = (date) => {
  if (date){
    const data = new Date(date);
    return `${data.getDate() < 10 ? "0"+data.getDate() : data.getDate()}/${(data.getMonth()+1) < 10 ? "0"+(data.getMonth()+1) : data.getMonth()+1}/${data.getFullYear()}`;
  }
  return "Data inválida"
};


export function formatDateFix(date) {
  if(date !== null){
    if(validateDateFormat(date)){
      return date;
    }else{
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
  else
    return;
}

function validateDateFormat(dateString) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(dateString);
}