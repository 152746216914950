import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.5rem;
  gap: 1rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
`;

export const IncentiveContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IncentiveContainerRow = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ContentChip = styled.div`
  margin: 24px 0px 21px;
  display: inherit;
  gap: 20px;
  flex-wrap: nowrap;
  width: 95%;
  max-height: 300px;
  overflow: hidden auto;

  div{
    width: 47%;
    margin: 5px;
    justify-content: space-between;
  }

`;

export const ContentInputs = styled.div`
  margin-top: 26px;
  display: flex;
  gap: 24px;
  
  @media (max-width: 1590px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div {
      width: 100%!important;
    }

  }

  @media (max-width: 1025px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    div {
      width: 100%!important;
    }

  }

  

  // div:first-child > div{
  //   width: 100%!important;
  // }
`;

export const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.orange};

  cursor: pointer;
`;

export const RowCapture = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  gap: 1.5rem;
`;

export const AddButton = styled.div`
  width: 40px;
  height: 46px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grayLight1};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 20px;

  font-size: 20px;
  color: ${({ theme }) => theme.colors.grayDark2};

  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
`;