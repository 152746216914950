import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 100px 0 25px;
  width: 100%;
`;

export const SpaceFields = styled.div`
  margin-top: 25px;
`;

export const ProjectCityText = styled.div`
  margin-top: 40px;

  font-size: 14px;
  line-height: 16px;

  color: #5c5050;
`;
export const ProjectLocalText = styled.div`
  padding-top: 30px;
  font-size: 14px;
  line-height: 16px;

  color: #5c5050;
`;

export const DivEdicao = styled.div`
  display: grid;
  gap: 22px;
  grid-template-columns: 50% 50%;

  @media (max-width: 1200px){
    display: grid;
  }
`;

export const OptionsContent = styled.div`
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 19px;

    color: ${({ theme }) => theme.colors.blackGrayish60};
  }
`;

export const CityList = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
  gap: 1.5rem;

  margin-top: 20px;

  @media (max-width: 1200px){
    grid-template-columns: 25% 50% 25%;
  }
`;

export const ContentChip = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;
  max-width: 90%;
  max-height: 88px;
  overflow-y: auto;
  flex-wrap: wrap;
`;