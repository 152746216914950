import React from 'react';
import Typography from 'components/Typography';
import { IconCreate } from 'assets/icons';
import { Container, Body, CardStyle, Values } from './styles';
import Cards from './cards';
import { formatNumber } from 'utils/maskUtils';

const CounterPartCards = ({ title, cards, onClick }) => {
  return (
    <Container>
      <Body>
        <p>
          <b>Cota:</b> {title} - <b>Valor de:</b> R$ {cards.valueOf} <b>até:</b> R$ {cards.valueUpTo}
          <IconCreate
            fill='rgb(255, 255, 255)'
            style={{ cursor: 'pointer', marginLeft: 10, marginTop: 7 }}
            onClick={() => onClick(cards.id)}
          />
        </p>
      </Body>
      <p style={{ fontWeight: 'bold', marginBottom: 2, color: '#000', padding: '0 12px'}}>Contrapartidas:</p>
      {/* <CardStyle>
        {Object.entries(cards.counterparts).map(([key, value]) => (
          <>
            {key !== 'title' && <Cards value={value} key={key} />}
          </>
        ))}
      </CardStyle> */}
      <table style={{width: '100%', padding: '0 10px 10px 10px'}}>
        <tbody>
        {Object.entries(cards.counterparts).map(([key, value]) => (
          <>
            <tr>{key !== 'title' && <Cards value={value} key={key} />}</tr>
          </>
        ))}
        </tbody>
      </table>
    </Container>
  );
};

export default CounterPartCards;
