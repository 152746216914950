import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px !important;
  max-width: 450px !important;
  min-width: 350px !important;
  background: #FEFCFE;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  
  ${({ isAdmin }) => isAdmin ? 
    css`
      border-radius: 6px 16px 16px 6px;
    ` : 
    css`
      border-radius: 16px;
    `
  }
  padding: 1.5rem 4.25rem;
  /* height: max-content; */
  min-height: 100%;

  @media (max-width: 1370px) {
    width: 400px !important;
  }

  @media (max-width: 1150px){
    padding: 1.5rem 2rem;
    width: 300px !important;
    min-width: 280px!important;
  }

  @media (max-width: 1000px){
    padding: 1.5rem 1rem;
  }

  @media (max-width: 960px){
    width: 30%!important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 388px;
  max-height: auto;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
