import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'components/Typography';

import { 
  Container,
  InfoTextContainer,
  Content,
  OdsContainer,
  Row,
  RowPublic,
  Link,
} from './styles';
import Input from 'components/Input';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import ODSCard from 'components/ODSCard';
import Select from 'components/Select';
import { useUser } from 'hooks/useUser';
import { onlyNumber } from 'utils/maskUtils';
import { WarningOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const Step3 = ({ odsSelecteds, setOdsSelecteds, impactCategories, impactSubCategories, changedFields, setChangedFields}) => {
  const { control, setValue, getValues } = useFormContext();
  const [authSdGoalIds, setauthSdGoalIds] = useState(getValues('auth_sd_goal_ids'));
  const [changeAuthTarget, setChangeAuthTarget] = useState(changedFields.target);
  changeAuthTarget && setValue('auth_target', !changeAuthTarget);
  const [changeAuthAudienceGoal, setChangeAuthAudienceGoal] = useState(changedFields.audience_goal);
  changeAuthAudienceGoal && setValue('auth_audience_goal', !changeAuthAudienceGoal);
  const { user } = useUser();
  const roles = [
    'Em rascunho',
    'Em ajuste',
    'Aguardando regularização'
  ];
  let isDisabled = false;
  const project_status = getValues('status');
  
  if((user.adminRole === 'cs_proponent1' || user.adminRole === 'cs_proponent2' || user.adminRole === 'cs_proponent_relationship') && !roles.includes(project_status)){
    isDisabled = true;
  } else if((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
    isDisabled = true;
  } else if(user.adminRole === 'general' || user.adminRole === 'monitore') {
    isDisabled = true;
  }

  const [odsSelected, setOdsSelected] = useState([]);
  const [authCategory, setAuthCategory] = useState(
    getValues('auth_impact_category_id'),
  );
  const [authSubCategory, setAuthSubCategory] = useState(
    getValues('auth_impact_subcategory_id'),
  );
  const [authTarget, setAuthTarget] = useState(getValues('auth_target'));
  const [authAudienceGoal, setAuthAudienceGoal] = useState(
    getValues('auth_audience_goal'),
  );

  const handleSelectedOds = useCallback(
    type => {
      if (odsSelected.includes(type)) {
        setOdsSelecteds(odsSelected.filter(ods => ods !== type));
      } else {
        setOdsSelecteds([...odsSelected, type]);
      }
    },
    [odsSelected],
  );

  useEffect(() => {
    setValue(
      'sd_goal_ids',
      odsSelected.map(ods => ods + 1),
    );
  }, [setValue, odsSelected]);

  useEffect(() => {
    if (odsSelecteds) {
      setOdsSelected(odsSelecteds);
    }
  }, [odsSelecteds, setValue]);

  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          ODS e Impacto
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Indique quais são as ODS’s e categorias de impacto do seu projeto e o público que será impactado por ele.
        </Typography>
      </InfoTextContainer>
      <Content>
        {/* <AdminFormGroup
          checked={authSdGoalIds}
          onChange={value => {
            setauthSdGoalIds(value);
            setValue("auth_sd_goal_ids", value);
          }}
        > */}
          <OdsContainer>
            <InfoTextContainer>
              <Typography variant="h6" color="grayDark2">
                Principais ODS’s incorporadas no projeto
              </Typography>
              <Typography variant="subtitle2" color="grayLight1">
                Os 17 Objetivos de Desenvolvimento Sustentável (ODS) equilibram as três dimensões do desenvolvimento sustentável: a econômica, a social e a ambiental. Você pode selecionar quantos ODS quiser, mas lembre-se que eles precisam estar alinhados com os objetivos do seu projeto.
              </Typography>
              <Typography variant="body2" color="blueGrayish56" style={{ marginTop: 24 }}>
                Ficou em dúvida sobre qual a ODS incorporar no seu projeto? Veja detalhes no site da ONU clicando <Link href={'https://odsbrasil.gov.br/'} rel="noreferrer" target="_blank">aqui </Link>
                * É obrigatório indicar ao menos 1 objetivo especifico.
              </Typography>
            </InfoTextContainer>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
              {
                changedFields.sd_goal_ids && (
                  <Tooltip title="Campo alterado pelo proponente" placement="top">
                    <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                  </Tooltip>
                )
              }
              <div>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(ods => (
                  <ODSCard
                    type={ods}
                    onClick={() => handleSelectedOds(ods)}
                    opacity={!odsSelected.includes(ods)}
                    disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                  />
                ))}
              </div>
            </div>
          </OdsContainer>
        {/* </AdminFormGroup> */}
      </Content>
      <Typography variant="body1" color="blueGrayish56">
      O objetivo geral deve destacar qual é o produto principal, o que o projeto propõe e qual impacto pretende gerar. 
      </Typography>
      <Content>
      <Row>
          {/* <AdminFormGroup
            checked={getValues('auth_impact_category_id') ? true : false}
            onChange={value => {
              setAuthCategory(value);
              setValue('auth_impact_category_id', value);
            }}
          > */}
            <Controller
              name="impact_category_id"
              control={control}
              render={({ field }) => {
                return (
                  <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                    {
                      changedFields.impact_category_id && (
                        <Tooltip title="Campo alterado pelo proponente" placement="top">
                          <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                        </Tooltip>
                      )
                    }
                    
                    {/* *Obrigatório */}
                    <Select
                      {...field}
                      label="Categoria de impacto"
                      options={impactCategories.map(item => item.name)}
                      // error={errors?.impact_category_id?.message}
                      onChange={e => {
                        setValue(
                          'impact_category_id',
                          impactCategories[e.target.value].id,
                        );
                      }}
                      role="admin"
                      value={impactCategories.findIndex(
                        item => item.id === getValues('impact_category_id'),
                      )}
                      disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                    />
                  </div>
                )
              }}
            />
          {/* </AdminFormGroup> */}
          {/* <AdminFormGroup
            checked={getValues('auth_impact_subcategory_id') ? true : false}
            onChange={value => {
              setAuthSubCategory(value);
              setValue('auth_impact_subcategory_id', value);
            }}
          > */}
            <Controller
              name="impact_subcategory_id"
              control={control}
              render={({ field }) => {
                return (
                  <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                    {
                      changedFields.impact_subcategory_id && (
                        <Tooltip title="Campo alterado pelo proponente" placement="top">
                          <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                        </Tooltip>
                      )
                    }
                    {/* *Obrigatório */}
                    <Select
                      {...field}
                      label="Subcategoria de impacto"
                      options={impactSubCategories.map(item => item.name)}
                      // error={errors?.impact_subcategory_id?.message}
                      onChange={e => {
                        setValue(
                          'impact_subcategory_id',
                          impactSubCategories[e.target.value].id,
                        );
                      }}
                      role="admin"
                      value={impactSubCategories.findIndex(
                        item => item.id === getValues('impact_subcategory_id'),
                      )}
                      disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                    />
                  </div>
                )
              }}
            />
          {/* </AdminFormGroup> */}
        </Row>
        <RowPublic>
          <AdminFormGroup
            checked={changeAuthTarget ? false : getValues('auth_target') ? true : false}
            onChange={value => {
              setAuthTarget(value);
              setValue('auth_target', value);
              setChangeAuthTarget(!changeAuthTarget);
              value && setChangedFields({
                target: false,
                audience_goal: changeAuthAudienceGoal
              });
            }}
          >
            <Controller
              name="target"
              control={control}
              render={({ field }) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                  {
                    (changedFields.target && changeAuthTarget) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                      </Tooltip>
                    )
                  }
                  {/* *Obrigatório */}
                  <Input label="Publico-alvo direto" fullWidth {...field} disabled={isDisabled}/>
                </div>
              )}
            />
          </AdminFormGroup>
          <AdminFormGroup
            checked={ changeAuthAudienceGoal ? false : getValues('auth_audience_goal') ? true : false}
            onChange={value => {
              setAuthAudienceGoal(value);
              setValue('auth_audience_goal', value);
              setChangeAuthAudienceGoal(!changeAuthAudienceGoal);
              value && setChangedFields({
                target: changeAuthTarget,
                audience_goal: false
              });
            }}
          >
            <Controller
              name="audience_goal"
              control={control}
              render={({ field }) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                  {
                    (changedFields.audience_goal && changeAuthAudienceGoal) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                      </Tooltip>
                    )
                  }
                  {/* *Obrigatório */}
                  <Input
                    label="Meta do público-alvo direto" 
                    fullWidth {...field} 
                    disabled={isDisabled}
                    onChange={e => {
                      const value = onlyNumber(e.target.value);
                      setValue('audience_goal', value);
                    }}
                  />
                </div>
              )}
            />
          </AdminFormGroup>
        </RowPublic>
      </Content>
    </Container>
  );
};

export default Step3;