import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  margin-top: 30px;
`;

export const GridInputs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 20px;

  @media (max-width: 450px){
    display: grid;
    gap: 30px;
  }
`;

export const SelectPerson = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 10px;
`;
