import styled from 'styled-components';
import { pixelsToRem } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pixelsToRem(34)};

  @media (max-width: 1550px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
`;
