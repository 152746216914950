import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { ProjectService } from 'services/projects';
import { LawsService } from 'services/laws';
import Typography from 'components/Typography';
import Select from 'components/Select';
import SelectId from 'components/SelectId';
import SelectAutocomplete from 'components/SelectAutocomplete';
import Input from 'components/Input';
import CounterPartCards from 'components/CounterPartCards';
import Button from 'components/Button';
import { IconAdd } from 'assets/icons';
import formatCurrency, { currencyMask } from 'utils/currency';
import { v4 } from 'uuid';
import {
  Container,
  ButtonContent,
  ButtonAddCounterPart,
  ButtonAddText,
  InfoTextContainer,
  Content,
  IconColor,
  FormContainer,
  Actions,
  FlagState
} from './styles';
import './styles.css';
import { setNestedObjectValues } from 'formik';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import PendenciesFields from './PendenciesFields';
import { useUser } from 'hooks/useUser';
import { NotificationService } from 'services/notifications';
import { toast } from 'react-toastify';
import { translateAuthProjectData } from 'utils/translate';
import { groupBy } from 'utils/query';
import ModalInformation from 'components/ModalInformation';
import { percentageMask } from 'utils/maskUtils';
import { ProjectInfo } from 'components/ProjectCard/styles';
import { Link } from 'components/AdmProjectsTable/styles';
import { Close, ContactsOutlined } from '@material-ui/icons';
import { FormControl, InputLabel, ListSubheader, MenuItem, Select as SelectMU, Collapse, Checkbox, FormControlLabel, Modal, Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { datetimeFormatted } from 'utils/date';
import DeleteModal from 'components/UsersModal/DeleteModal';
import { IconRight } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals/ChangeProponent/styles';
import { DialogFooter } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals/CloseCaptureProponent/styles';
import DragAndDrop from 'components/DragAndDrop';
import { Controller, useFormContext } from 'react-hook-form';
import { validateFileExtension, validateFileSize } from 'utils/validation';
import { FormGroup } from '../Step4/styles';
import { useTheme } from 'styled-components';

const Step7 = ({ proponentId, projectData , isOnlyMonitore, handleSetProjectOnlyMonitore, handleSave, isUploading}) => {
  const isNull = val => val == null;
  const { control, setValue, setError, getValues, formState: { errors } } = useFormContext();

  const checkFields = () => {
    if (projectData.legal_person == "Pessoa física") {
      if (isNull(projectData.legal_person)) return false
      if (isNull(projectData.name)) return false
      if (isNull(projectData.full_name)) return false
      if (isNull(projectData.cpf)) return false
      if (isNull(projectData.email)) return false
      if (isNull(projectData.public_place)) return false
      if (isNull(projectData.number)) return false
      if (isNull(projectData.complement)) return false
      if (isNull(projectData.district)) return false
      if (isNull(projectData.city)) return false
      if (isNull(projectData.state)) return false
      if (isNull(projectData.cep)) return false
      if (isNull(projectData.phone)) return false
      if (isNull(projectData.company_names)) return false
      if (isNull(projectData.full_name_witness)) return false
      if (isNull(projectData.witness_email)) return false
    } else {
      if (isNull(projectData.legal_person)) return false
      if (isNull(projectData.company_size)) return false
      if (isNull(projectData.name)) return false
      if (isNull(projectData.corporate_name)) return false
      if (isNull(projectData.cnpj)) return false
      if (isNull(projectData.public_place)) return false
      if (isNull(projectData.number)) return false
      if (isNull(projectData.complement)) return false
      if (isNull(projectData.district)) return false
      if (isNull(projectData.city)) return false
      if (isNull(projectData.state)) return false
      if (isNull(projectData.cep)) return false
      if (isNull(projectData.name_responsible)) return false
      if (isNull(projectData.cpf_responsible)) return false
      if (isNull(projectData.phone_responsible)) return false
      if (isNull(projectData.email_responsible)) return false
      if (isNull(projectData.company_names)) return false
      if (isNull(projectData.full_name_witness)) return false
      if (isNull(projectData.witness_email)) return false
    }
  }

  const { user } = useUser();
  const theme = useTheme();
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Status não alterado',
    description: 'Você não pode concluir a análise se o projeto não estiver regular. Verifique o campo de Status da Análise.',
  });
  const statusOptions = useMemo(() => {
    return [
      'Em rascunho',
      'Em análise',
      'Em ajuste',
      'Aguardando regularização',
      'Em captação',
      'Incentivado',
      'Não captado',
      'Projeto reprovado',
    ];
  }, []);
  const complianceStatusOptions = useMemo(() => {
    return [
      'Aguardando análise',
      'Aguardando dados de negociação',
      'Aguardando ajustes no cadastro',
      'Aguardando regularização do proponente',
      'Aguardando assinatura de contrato',
      'Projeto regular',
      'Cadastro recusado',
      'Aprovado com ressalvas'
    ];
  }, []);
  const lawTypeOptions = useMemo(() => {
    return [
      'Fundo',
      'Lei',
    ];
  }, []);
  const fundOptions = useMemo(() => {
    return [
      'Estadual',
      'Municipal',
    ];
  }, []);
  const patronageOptions = useMemo(() => {
    return [
      'Estadual',
      'Municipal',
      'Federal',
    ];
  }, []);

  const categoryOptions = useMemo(() => {
    return [
      'Cultura',
      'Esporte',
      'Infância e adolescência',
      'Idosos',
      'Saúde'
    ];
  }, []);
  const [status, setStatus] = useState(getValues('status'));
  const [complianceStatus, setComplianceStatus] = useState(getValues('analysis_compliance_status'));

  const [lawType, setLawType] = useState(getValues('law_type') ?? null);
  const [coverage, setCoverage] = useState(getValues('law_coverage') ?? null);
  const [categoryLaw, setCategoryLaw] = useState(getValues('category_law') ?? null);
  const [lawState, setLawState] = useState(getValues('law_state') ?? null);
  const [lawStateOptions, setLawStateOptions] = useState([]);
  const [lawCity, setLawCity] = useState(getValues('law_city') ?? null);
  const [lawCitiesOptions, setLawCitiesOptions] = useState([]);
  const [specificLaw, setSpecificLaw] = useState(getValues('specific_law_id') ?? null);
  const [contractFile, setContractFile] = useState(getValues('contract_file') ?? null);
  // const [laws, setLaws] = useState(null);
  // const [lawsAll, setLawsAll] = useState(null);
  const [lawsIsLoaded, setLawsIsLoaded] = useState(false);
  const lawStateRef = useRef();
  const lawCityRef = useRef();
  const lawsRef = useRef();
  lawStateRef.current = lawState;
  lawCityRef.current = lawCity;
  // lawsRef.current = laws;

  const lawCitiesOptionsRef = useRef();
  lawCitiesOptionsRef.current = lawCitiesOptions;
  const lawStateOptionsRef = useRef();
  lawStateOptionsRef.current = lawStateOptions;

  const [reviewItems, setReviewItems] = useState({});
  const roles = [
    'cs_proponent1',
    'cs_proponent2',
    'support',
    'cs_proponent_relationship',
    'monitore'
  ]
  const isDisabled = roles.includes(user.adminRole);
  const disableContract = projectData.status == "Em análise" &&
    projectData.register_status_proponent == "Concluído" &&
    projectData.analysis_compliance_status_proponent == 4 &&
    !checkFields();

  const onlyMonitoreAction = ( 
    user.adminRole === 'support' ||
    user.adminRole === 'monitore' ||
    user.adminRole === 'cs_proponent1' ||
    user.adminRole === 'cs_proponent_relationship' ||
    user.adminRole === 'evaluator' 
    ) ? true : false;

  

  let disableStatus = false;
  const project_status = getValues('status');

  if ((user.adminRole === 'cs_proponent1' || user.adminRole === 'cs_proponent2') && !roles.includes(project_status)) {
    disableStatus = true;
  } else if ((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
    disableStatus = true;
  } else if (user.adminRole === 'general' || user.adminRole === 'monitore') {
    disableStatus = true;
  }

  const [checked, setChecked] = useState(isOnlyMonitore ? true : false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSendOnlyMonitore = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    handleSetProjectOnlyMonitore();
    setIsModalOpen(false);
  }

  useEffect(() => {
    const keys = Object.keys(getValues());
    const authKeys = keys.filter(key => key.includes('auth_'));
    const data = [];
    const rules = ['false', null, 'null'];
    const isFund = getValues('law_id') > 10;

    authKeys.forEach(item => {
      if (!getValues(item) || rules.includes(getValues(item))) {
        if (
          (item === 'auth_fund_opening_receipt' &&
            isFund &&
            getValues('law_id')) ||
          (item === 'auth_opening_receipt' && !isFund && getValues('law_id')) ||
          (item !== 'auth_fund_opening_receipt' && item !== 'auth_opening_receipt')
        ) {
          data.push({
            item,
            value: getValues(item),
            translate: translateAuthProjectData[item]?.text,
            step: translateAuthProjectData[item]?.step.id,
            stepTitle: translateAuthProjectData[item]?.step.text,
          });
        }
      }
    });

    setReviewItems(groupBy(data, 'step'));
  }, [getValues]);

  const [payloads, setPayloads] = useState({});

  async function loadStates() {

    const payload = {
      type: lawTypeOptions[lawType],
      coverage: patronageOptions[coverage],
      category_law: categoryOptions[categoryLaw],
    }

    try {
      setPayloads(payload);

      const response = await LawsService.getStates(payload);

      if (lawStateOptions !== response.data || payloads !== payload) {
        setLawStateOptions(response.data)
      }

      // setLawState(undefined)
    } catch (err) {
      toast.error('Falha ao carregar estados!');
    }
  }

  async function loadCities() {

    const payload = {
      type: lawType !== null ? lawTypeOptions[lawType] : null,
      coverage: coverage !== null ? patronageOptions[coverage] : null,
      category_law: categoryLaw !== null ? categoryOptions[categoryLaw] : null,
      state: coverage == 1 ? null : lawState !== null ? lawStateOptions[lawState] : null
    }
    try {
      const response = await LawsService.getCities(payload);
      if (lawCitiesOptionsRef.current !== response.data) {
        setLawCitiesOptions(response.data)
      }
      // setLawCity(undefined)
    } catch (err) {
      toast.error('Falha ao carregar cidades!');
    }
  }

  function groupBy (array, key) {
    return array.reduce((acc, item) => {
        if (!acc[item[key]]) acc[item[key]] = []
          acc[item[key]].push(item)
          return acc
    }, [])
  }

  const [payloadLaws, setPayloadLaws] = useState({});
  const [lawsAll, setLawsAll] = useState([]);
  const [laws, setLaws] = useState([]);
  const [funds, setFunds] = useState([]);
  const [fundsEst, setFundsEst] = useState([]);
  const [fundsEstGroup, setFundsEstGroup] = useState([]);
  const [fundsMun, setFundsMun] = useState([]);
  const [fundsFed, setFundsFed] = useState([]);
  const [lawsEst, setLawsEst] = useState([]);
  const [lawsEstGroup, setLawsEstGroup] = useState([]);
  const [lawsMun, setLawsMun] = useState([]);
  const [lawsFed, setLawsFed] = useState([]);

  const loadLaws = useCallback(async () => {
    let payload = {
      city: '',
      type: '',
      coverage: '',
      state: '',
      category_law: ''
    }

    setPayloadLaws(payload);
    
    try {
      const response = await LawsService.getConectaLaws(payload);
      setLawsAll(response.data);

      setLaws(response.data.filter((item) => item.type === 'Lei'));
      setLawsEst(response.data.filter((item) => item.release_to_laws_list == 1 && item.type === 'Lei' && item.coverage === 'Estadual'));
      setLawsMun(response.data.filter((item) => item.release_to_laws_list == 1 && item.type === 'Lei' && item.coverage === 'Municipal'));
      setLawsFed(response.data.filter((item) => item.release_to_laws_list == 1 && item.type === 'Lei' && item.coverage === 'Federal'));

      setFunds(response.data.filter((item) => item.type === 'Fundo'));
      setFundsEst(response.data.filter((item) => item.release_to_laws_list == 1 && item.type === 'Fundo' && item.coverage === 'Estadual'));
      setFundsMun(response.data.filter((item) => item.release_to_laws_list == 1 && item.type === 'Fundo' && item.coverage === 'Municipal'));
      setFundsFed(response.data.filter((item) => item.release_to_laws_list == 1 && item.type === 'Fundo' && item.coverage === 'Federal'));
      
      
      setLawsEstGroup(groupBy(lawsEst, 'state'));
      setFundsEstGroup(groupBy(fundsEst, 'state'));

    } catch (err) {
      toast.error('Falha ao carregar leis!');
    }
  }, []);

  useEffect(() => {
    loadLaws();

  }, [loadLaws]);


  delete reviewItems.undefined;

  const handleSubscriptionRoutine = useCallback(async () => {
    try {
      await ProjectService.subscriptionRoutine(projectData.id)
      toast.success('Contrato enviado com sucesso');
    } catch (err) {
      toast.error('Erro ao enviar contrato');
      console.log(err);
    }
  })

  const handleContentUploadedDocument = useCallback(async () => {
    try {
      await ProjectService.contentUploadedDocument(projectData.id)
    } catch (err) {
      console.log(err);
    }
  })

  const handleGetContractFile = useCallback(async () => {
    try {
      const { data } = await ProjectService.getContractFile(projectData.id);
      const blob = new Blob([data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report_" + new Date() + ".pdf";
      link.download = `document.pdf`;
      link.click();
    } catch (err) {
      toast.error('Esperando envio do contrato');
    }
  }, []);


  const analyze_missing_fields = Object.keys(reviewItems).map(item =>
    reviewItems[item].map(reviewItem => reviewItem.translate),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let result = [];

  analyze_missing_fields.forEach((element, key) => {
    result.push('|||Passo ' + (Number(key) + 1) + ' : ');

    result = result.concat(element);
  });

  const handleSendNotification = useCallback(async () => {
    try {
      const { analysis_status, analyze_subject, analyze_message, id, analysis_compliance_status } = getValues();
      const analyze_full_message = analyze_message.concat(result);
      /*     console.log('analyze_full_message', analyze_full_message);
      console.log('analyze_message', analyze_message);
      console.log('result', result); */

      const payload = {
        sender_id: user.proponent_id,
        proponent_id: proponentId,
        analysis_status: analysis_status ?? 0,
        analysis_compliance_status: analysis_compliance_status ?? 0,
        title: analyze_subject,
        body: analyze_full_message,
        project_id: id,
      };
      await NotificationService.create(payload);
      toast.success('Notificação enviada com sucesso!');
    } catch (err) {
      toast.error('Falha ao enviar notificação');
    }
  }, [getValues, proponentId, user, result]);

  console.log("PROJECTDATA", projectData.document_token);

  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Análise
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Solicite as correções necessárias abaixo.
        </Typography>
      </InfoTextContainer>
      <Content>
        <FormContainer>
          <Select
            label="Status do projeto"
            value={statusOptions.findIndex(item => item === status)}
            onChange={e => {
              const value = statusOptions[e.target.value];
              if (value === 'Em captação' && (complianceStatus != 5 && complianceStatus != 6 && complianceStatus != 7)) {
                setShowModalInformation(true);
                return;
              }
              setStatus(value);
              if (value === 'Em análise') {
                setComplianceStatus(0)
                setValue('analysis_compliance_status', 0);
              }
              setValue('status', value);
            }}
            role="admin"
            options={statusOptions}
            disabled={isDisabled}
          />
          <Select
            label="Status da análise"
            value={complianceStatus ?? 0}
            onChange={e => {
              const value = e.target.value;
              setComplianceStatus(value);
              setValue('analysis_compliance_status', value);
            }}

            options={complianceStatusOptions}
            disabled={isDisabled}
          />

          {complianceStatus == 7 && <Controller
            name="analyze_reservations"
            control={control}
            render={({ field }) => (
              <Input label="Aprovado com ressalvas" multiline rows={5} disabled={isDisabled} fullWidth
                {...field}
              />)}
          />
          }

          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input label="Código do projeto" fullWidth {...field} disabled={!['master', 'evaluator'].includes(user.adminRole)} />
            )}
          />
          {/* *Obrigatório */}
          <Controller
            name="commission_percentage"
            control={control}
            render={({ field }) => (
              <Input label="Percentual de comissão"
                fullWidth
                {...field}
                onChange={e => {
                  const value = percentageMask(e.target.value);
                  setValue('commission_percentage', value);
                }}
                disabled={['general', 'monitore'].includes(user.adminRole)}
              />
            )}
          />
          {/* <Select
            name='law_type'
            label="Tipo de Lei"
            value={lawType}
            onChange={e => {
              const value = e.target.value;
              setLawType(value);
              setValue('law_type', value);
              setLawCity(null);
              setSpecificLaw(null);
              setLawState(null);
              setCoverage(null);

            }}
            options={lawTypeOptions}
          />
          {lawType !== null && (
            <Select
              name=' law_coverage'
              label="Abrangência da lei"
              value={coverage}
              onChange={e => {
                const value = e.target.value;
                setCoverage(value);
                setValue('law_coverage', value);
                setLawCity(null);
                setSpecificLaw(null);
                setLawState(null);
              }}
              options={lawType === 0 ? fundOptions : patronageOptions}
            />
          )}
          {coverage == 0 && lawStateOptions && (
            <Select
              name='law_state'
              label="Estado"
              value={lawState}
              onChange={e => {
                const value = e.target.value;
                setLawState(value);
                setValue('law_state', value);
                setLawCity(null);
                setSpecificLaw(null);
              }}
              options={lawStateOptions}
            />
          )}

          {(lawCitiesOptions && (coverage == 1)) && (
            <Select
              name='law_city'
              label="Município"
              value={lawCity}
              onChange={e => {
                const value = e.target.value;
                setLawCity(value);
                setValue('law_city', value);
                setSpecificLaw(null);
              }}
              options={lawCitiesOptions}
            />
          )} */}
          <FormControl
            variant="outlined"
            rounded={true}
            className='selectCategory rounded'
          >
            <InputLabel htmlFor="grouped-select">Lista de leis</InputLabel>
            <SelectMU
              defaultValue=""
              disabled={['monitore'].includes(user.adminRole)}
              id="grouped-select"
              label="Lista de leis"
              value={specificLaw}
              name='specific_law_id'
              onChange={e => {
                const value = e.target.value;
                setSpecificLaw(value);
                setValue('specific_law_id', value);
              }}
              style={{ height: '48px', borderRadius: '10px' }}>
              <ListSubheader className='titleList'>Fundo</ListSubheader>

              {fundsEst ? fundsEst.length > 0 && (
                <ListSubheader>Estadual</ListSubheader>
              ) : ''}

              {fundsEst ? fundsEst.length > 0 && (

                React.Children.toArray(
                  fundsEst.map((option, index) => (
                    <MenuItem
                      key={v4()}
                      value={option.id}
                      style={{ minWidth: 400, width: '100%' }}
                    ><FlagState className="flagState">{option.state?option.state:'N/D'}</FlagState>{option.name}</MenuItem>
                  )
                  )
                )
              ) : ''}


              {fundsMun ? fundsMun.length > 0 && (
                <ListSubheader>Municipal</ListSubheader>
              ) : ''}
              {fundsMun ? fundsMun.length > 0 && (
                React.Children.toArray(

                  fundsMun.map((option, index) => (
                    <MenuItem
                      key={v4()}
                      value={option.id}
                      style={{ minWidth: 400, width: '100%' }}
                    ><FlagState className="flagState">{option.city?option.city:'N/D'}/{option.state?option.state:'N/D'}</FlagState>{option.name}</MenuItem>
                  )
                  )
                )
              ) : ''}
              {fundsFed ? fundsFed.length > 0 && (
                <ListSubheader>Federal</ListSubheader>
              ) : ''}
              {fundsFed ? fundsFed.length > 0 && (

                React.Children.toArray(

                  fundsFed.map((option, index) => (
                    <MenuItem
                      key={v4()}
                      value={option.id}
                      style={{ minWidth: 400, width: '100%' }}
                    >{option.name}</MenuItem>
                  )
                  )
                )
              ) : ''}

              <ListSubheader className='titleList'>Lei</ListSubheader>
              {lawsEst ? lawsEst.length > 0 && (
                <ListSubheader>Estadual</ListSubheader>
              ) : ''}
              {lawsEst ? lawsEst.length > 0 && (
                React.Children.toArray(
                  lawsEst.map((option, index) => (
                    <MenuItem
                      key={v4()}
                      value={option.id}
                      style={{ minWidth: 400, width: '100%' }}
                    ><FlagState className="flagState">{option.state?option.state:'N/D'}</FlagState>{option.name}</MenuItem>
                  ))
                )
              ) : ''}
              
              {/* {lawsEstGroup.map((valor, x) => {
                return (
                  <>
                    <ListSubheader>{x}</ListSubheader>
                  
                    {valor.map((lawsEG) => {
                      return (
                        <MenuItem
                          key={v4()}
                          value={lawsEG.id}
                          style={{ minWidth: 400, width: '100%' }}
                        >{lawsEG.name}</MenuItem>
                      )
                    })}
                  </>
                )})
              } */}

              {lawsMun ? lawsMun.length > 0 && (
                <ListSubheader>Municipal</ListSubheader>
              ) : ''}
              {lawsMun ? lawsMun.length > 0 && (

                React.Children.toArray(

                  lawsMun.map((option, index) => (
                    <MenuItem
                      key={v4()}
                      value={option.id}
                      style={{ minWidth: 400, width: '100%' }}
                    ><FlagState className="flagState">{option.city?option.city:'N/D'}/{option.state?option.state:'N/D'}</FlagState>{option.name}</MenuItem>
                  )
                  )
                )
              ) : ''}
              {lawsFed ? lawsFed.length > 0 && (
                <ListSubheader>Federal</ListSubheader>
              ) : ''}
              {lawsFed ? lawsFed.length > 0 && (

                React.Children.toArray(

                  lawsFed.map((option, index) => (
                    <MenuItem
                      key={v4()}
                      value={option.id}
                      style={{ minWidth: 400, width: '100%' }}
                    >{option.name}</MenuItem>
                  )
                  )
                )
              ) : ''}
            </SelectMU>
          </FormControl>
          {/* 
            <SelectId
              name='specific_law_id'
              label="Lista de leis"
              value={specificLaw}
              onChange={e => {
                const value = e.target.value;
                console.log("AQUI FUNCIONA", value);
                setSpecificLaw(value);
                setValue('specific_law_id', value);
              }}
              options={lawsAll?lawsAll:''}
            />

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="grouped-select">Lista de leis</InputLabel>
              <SelectMU 
                name='specific_law_id' 
                defaultValue="" 
                id="grouped-select"
                onChange={e => {
                  const value = e.target.value;
                  setSpecificLaw(value);
                  setValue('specific_law_id', value);
                }}>
                {funds ? funds.length > 0 && (
                    funds.map((option, index) => (
                    <>
                      {index == 0 && (<ListSubheader>Fundo</ListSubheader>)}
                      <MenuItem
                        key={v4()}
                        value={index}
                        style={{ minWidth: 400, width: '100%' }}
                      >{option.name}</MenuItem>
                    </>
                  )
                  )
                ): '' }


                {laws ? laws.length > 0 && (
                    laws.map((option, index) => (
                    <>
                      {index == 0 && (<ListSubheader>Mecenato</ListSubheader>)}
                      <MenuItem                      
                        key={v4()}
                        value={index}
                        style={{ minWidth: 400, width: '100%' }}
                      >{option.name}</MenuItem>
                    </>
                  )
                  )
                ): '' }
              </SelectMU>
          </FormControl> */}


          {/* {(laws && (coverage == 2 || (coverage == 1 && lawCity != null) || (coverage == 0 && lawState != null))) && (
            
          )} */}

              <FormGroup>
                <Typography variant="body1" color="brown" className="body-title">
                  Envie o arquivo do contrato de forma manual (Caso não seja feito pela Lexio).
                </Typography>
                <DragAndDrop
                  placeholder="nenhum arquivo enviado"
                  onChange={files => {
                    if(!validateFileExtension(files, ["application/pdf"]))
                      return setError("contract_file", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                    if(!validateFileSize(files, 7))
                      return setError("contract_file", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });

                    setValue('contract_file', files)
                    setError("contract_file", null);
                    console.log("ARQUVOO", getValues("contract_file"));
                    handleSave();
                  }}
                  disabled={isDisabled}
                  isUploading={isUploading}
                  error={errors["contract_file"]?.message}
                  label={'Contrato projeto'}
                  link={[getValues('contract_file')]}
                />
              </FormGroup>

          <Controller
            name="analyze_subject"
            control={control}
            render={({ field }) => <Input label="Assunto" {...field} disabled={isDisabled} />}
          />
          <Controller
            name="analyze_message"
            control={control}
            render={({ field }) => (
              <Input label="Mensagem" multiline rows={10} {...field} disabled={isDisabled} />
            )}
          />
          {/* <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input label="Status do Contrato" value={projectData.process_status} />
            )}
          /> */}
          {/* <Actions>
            <Button variant="secondary" disabled={isDisabled}>
              CANCELAR
            </Button>
            <Button type="button" onClick={handleSendNotification} disabled={isDisabled}>
              ENVIAR
            </Button>

            <Button type="button" onClick={() => handleSubscriptionRoutine()} disabled={!disableContract}>
              ENVIAR CONTRATO
            </Button>
          </Actions> */}

          {projectData.document_token && (
            <Link onClick={() => handleGetContractFile()} rel="noreferrer" target="_blank">
            <Typography variant="body1" style={{color: theme.colors.primary, textDecoration: 'underline'}}>
              Baixar Documento
            </Typography>
          </Link>
          )}

          {
            onlyMonitoreAction && (
              <>
                <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onClick={e => setIsModalOpen(true)}
                    disabled={!['support','monitore','cs_proponent1','cs_proponent_relationship','evaluator'].includes(user.adminRole)}
                  />
                } 
                label="Projeto exclusivo Monitore" 
                />
                {
                  projectData.only_monitore ? (
                    <>
                      <Input style={{marginTop: '8px'}} label="Liberado por:" disabled={true} value={projectData.only_monitore_user}/>
                      <Input style={{marginTop: '8px'}} label="Data" disabled={true} value={datetimeFormatted(projectData.only_monitore_date)} />
                    </>
                  ) : null
                }
              </>
            )
          }
        <Dialog
          open={isModalOpen}
          onClose={()=>setIsModalOpen(false)}
          style={{overflowY: 'hidden'}}
        >
          <DialogContent>
            <IconRight>
              <Close onClick={() => setIsModalOpen(false)} />
            </IconRight>
            <DialogTitle>
              {!checked ? 'Esse processo não poderá ser voltado.': 'Retirando exclusividade na monitore'}
              
            </DialogTitle>
            <DialogContent>
              Você tem certeza disso?
            </DialogContent>
            <DialogFooter style={{display: 'flex', marginTop: '1.25rem', marginLeft: '1.25rem'}}>
              <Button type="button" onClick={() => handleSendOnlyMonitore()}>
                CONFIRMAR
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        </FormContainer>
        <ModalInformation
          open={showModalInformation}
          onClick={() => { setShowModalInformation(false) }}
          handleClose={() => setShowModalInformation(false)}
          {...modalInformationData}
        />
        <PendenciesFields />
      </Content>
    </Container>
  );
};

export default Step7;
