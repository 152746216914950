
const uploadHandler = (file) => {
    return new Promise((resolve, reject) => {
        fetch('https://api.incentiv.dev/queries/storage/upload-signed-url', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                isPublic: true,
                service: "conectaV1",
                reference: "cnd",
                name: file.name,
                size: file.size
            })
        })
            .then(response => response.json())
            .then(res => {
                fetch(res.postSignedUrl, {
                    method: 'PUT',
                    body: file
                })
                    .then(() => resolve(res))
                    .catch(err => reject(err));
            })
            .catch(err => reject(err));
    });
};

export default uploadHandler;