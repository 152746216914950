import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { pixelsToRem } from 'utils/styles';

export const Container = styled(TextField)`
  width: ${({ width }) => width ?? '100% !important'};
  fieldset {
    border-radius: ${({ rounded }) =>
      rounded ? pixelsToRem(30) : pixelsToRem(10)};
    ${({ color }) =>
      color &&
      css`
        border-color: ${color};
      `}
  }

  label {
    transform: translate(1rem, ${pixelsToRem(17)}) scale(1);
  }

  input {
    height: ${({ height }) => height ?? '1.5rem'};
    padding: 0.75rem 0 0.75rem 1rem;
    margin-right: 1rem;
  }
`;
