import React, { useMemo, useState, useCallback } from 'react';
import Typography from 'components/Typography';
import { Container, Link } from './styles';
import { ChatBubbleOutline, OpenInNew } from 'assets/icons';
import { phoneMask } from 'utils/maskUtils';
import { MailOutline, LockOpen, DeleteOutline, YoutubeSearchedFor, InsertDriveFile } from '@material-ui/icons';
import ModalInformation from 'components/ModalInformation';
import {
  UpdateEmailModal,
  UpdatePasswordModal,
  DeleteProponentModal
} from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { ProponentService } from 'services/proponent';
import { CndService } from 'services/cnd';
import { toast } from 'react-toastify';
import { useUser } from 'hooks/useUser';
import * as yup from 'yup';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { confirmPasswordValidationResolver } from 'validations/confirmPassword';

const ProponenteTableDetails = props => {
  const { user } = useUser();
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const {
    formState: { errors },
    setError
  } = useForm({
    resolver: confirmPasswordValidationResolver,
  });

  const validatePasswordSchema = yup.object({
    password: yup.string()
      .matches(/^(?=.*[!$*&@#])/, "Deve conter um caractere especial")
      .matches(/^(?=.*[a-z])/, "Deve conter um caractere minúsculo")
      .matches(/^(?=.*[A-Z])/, "Deve conter um caractere maiúsculo")
      .matches(/^(?=.*[0-9])/, "Deve conter um número")
      .min(6, 'Mínimo de 6 caracteres')
      .max(30, 'Máximo de 30 caracteres')
      .required('Senha é obrigatório'),
  }, []);

  const updateEmail = useCallback(async (payload) => {
    const email = {
      email: payload
    }

    if(!payload){
      toast.error('Insira um email');
      return;
    }

    try {
      const response = await ProponentService.updateProponentEmail(props.id, email);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Email atualizado com sucesso');
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao alterar o email do proponente');
      }
    }
  }, []);

  const updatePassword = useCallback(async (payload) => {
    const password = {
      password: payload
    }
    console.log('password1', password)
    console.log('payload1', payload)
    // await formHook.validate(payload, { abortEarly: false });
    try {
      await validatePasswordSchema.validate(password, { abortEarly: false });

      const response = await ProponentService.updateProponentPassword(props.id, password);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Senha atualizada com sucesso');
        setModalOpen(false);
      }
    } catch (err) {
      const errors = {};

      err.inner.forEach((x) => {
        if (x.path !== undefined) {
          if (!(x.path in errors)) {
            errors[x.path] = { message: x.errors[0] }
            setError(x.path, { message: x.errors[0] });
          }
        }
      });
      // console.log(err);
      console.log('errors 2', errors);

      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao alterar a senha do proponente');
      }
    }
  }, [errors]);

  const deleteProponent = useCallback(async () => {
    try {
      const response = await ProponentService.deleteProponent(props.id);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Proponente removido com sucesso');
        setModalOpen(false);
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao remover o proponente');
      }
    }
  }, []);

  const modalRender = useMemo(() => {
    const modal = {
      updateEmail: (
        <UpdateEmailModal
          open={modalOpen === 'updateEmail'}
          handleClose={() => setModalOpen('')}
          onChange={(data) => updateEmail(data)}
        />
      ),
      updatePassword: (
        <UpdatePasswordModal
          open={modalOpen === 'updatePassword'}
          handleClose={() => setModalOpen('')}
          onChange={(data) => updatePassword(data)}
          errors={errors}
        />
      ),
      deleteProponent: (
        <DeleteProponentModal
          open={modalOpen === 'deleteProponent'}
          handleClose={() => setModalOpen('')}
          onClick={() => deleteProponent()}
        />
      ),
    };
    return modal[modalOpen];
  });

  const checkCND = useCallback(async () => {
    try {
      const payload = {
        proponent_id: props.id
      }

      const response = await CndService.checkCND(payload);
      // props.onRefresh();
      toast.success('Consulta realizada com sucesso');
    } catch (err) {
      toast.error('Falha ao consultar CND');
    }
  }, []);

  return (
    <Container>
      <div>
        <div>
          <Typography variant="body2" color="grayDark2">
            CNPJ
          </Typography>
          <Typography variant="manrope" color="brown">
            {props.cnpj}
          </Typography>
        </div>
        <div style={{ marginTop: 14 }}>
          <Typography variant="body2" color="grayDark2">
            Telefone
          </Typography>
          <Typography variant="manrope" color="brown">
            {phoneMask(props.phone)}
          </Typography>
        </div>
      </div>
      <div>
        <Typography variant="body2" color="grayDark2">
          Endereço
        </Typography>
        <Typography variant="manrope" color="brown">
          {props.district}
        </Typography>
      </div>
      <div>
        <Typography variant="body2" color="grayDark2">
          Proj em captação
        </Typography>
        <Typography variant="manrope" color="brown">
          {props.numberProjectsCapture}
        </Typography>
      </div>
      <div>
        <Typography variant="body2" color="grayDark2">
          Arquivos
        </Typography>
        {props?.socialStatus && (
          <Link href={props?.socialStatus} rel="noreferrer" target="_blank">
            <OpenInNew />
            <Typography variant="manrope" color="blue">
              Estatuto Social
            </Typography>
          </Link>
        )}
        {props?.lastElection && (
          <Link href={props?.lastElection} rel="noreferrer" target="_blank">
            <OpenInNew />
            <Typography variant="manrope" color="blue">
              Ato da última eleição
            </Typography>
          </Link>
        )}
      </div>
      {/* <div>
        <Typography variant="body2" color="grayDark2">
          Responder notificação
        </Typography>
        {props?.receiveNotifications && (
          <Link
            href={props?.receiveNotifications}
            rel="noreferrer"
            target="_blank"
          >
            <ChatBubbleOutline />
            <Typography variant="manrope" color="blue">
              Enviar notificação
            </Typography>
          </Link>
        )}
      </div> */}
      {/* {['master', 'support', 'cs_proponent2', 'cs_proponent_relationship'].includes(user.adminRole) && (
        <>
          <div style={{ marginTop: 14 }}>
            <Link onClick={() => setModalOpen('updateEmail')}>
              <MailOutline />
              <Typography variant="manrope" color="blue">
                Alterar e-mail
              </Typography>
            </Link>
          </div>
          <div style={{ marginTop: 14 }}>
            <Link onClick={() => setModalOpen('updatePassword')}>
              <LockOpen />
              <Typography variant="manrope" color="blue">
                Alterar senha
              </Typography>
            </Link>
          </div>
        </>
      )}
      {['evaluator'].includes(user.adminRole) && (props.cnd_date >= 30 || props.cnd_date == null) && (
        <div style={{ marginTop: 14 }}>
          <Link onClick={() => checkCND()}>
            <YoutubeSearchedFor />
            <Typography variant="manrope" color="blue">
              Consultar CND
            </Typography>
          </Link>
        </div>
      )}
      {['evaluator'].includes(user.adminRole) && (props.cnd_date < 30 && props.cnd_date != null) && (
        <div style={{ marginTop: 14 }}>
          <Typography variant="manrope" color="brown">
            Certidão poderá ser consultada em {30 - props.cnd_date} dia{30 - props.cnd_date > 1 && 's'}
          </Typography>
        </div>
      )}
      {['evaluator'].includes(user.adminRole) && props.cnd_pdf && (
        <div style={{ marginTop: 14 }}>
          <Link href={props.cnd_pdf} rel="noreferrer" target="_blank">
            <InsertDriveFile />
            <Typography variant="manrope" color="blue">
              Visualizar Certidão
            </Typography>
          </Link>
        </div>
      )}
      {['support', 'cs_proponent2', 'master'].includes(user.adminRole) && (
          <div style={{ marginTop: 14 }}>
            <Link onClick={() => setModalOpen('deleteProponent')}>
              <DeleteOutline />
              <Typography variant="manrope" color="blue">
                Remover proponente
              </Typography>
            </Link>
          </div>
      )} */}
        {modalRender}

        <ModalInformation
          open={showModalInformation}
          handleClose={() => setShowModalInformation(false)}
          {...modalInformationData}
        />
    </Container>
  );
};

export default ProponenteTableDetails;
