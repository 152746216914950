import styled from 'styled-components';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

export const Container = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.grayIce};
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;

  svg {
    margin-right: 1rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 80vw;
`;

export const CurrentImage = styled.img`
  width: auto;
  height: 437px;
  border-radius: 30px;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
`;

export const BackButton = styled(ArrowBackIos)`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const NextButton = styled(ArrowForwardIos)`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const NoImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  img {
    width: 260px;
  }
`;
