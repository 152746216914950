import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, Backdrop } from '@material-ui/core';
import { ptBR } from 'date-fns/locale';

import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Label,
  Description,
  Content,
  Row,
  CancelButton,
  ButtonLabel,
  ConfirmButton,
  ExecutionDatePicker,
  ExecutionProofInput,
  Container,
} from './styles';

import { toggleMilestonesModal, onActionMilestonesModal } from 'store/ducks/modals/actions';

const MilestonesModal = () => {
  const dispatch = useDispatch();
  const [date, setDate] = React.useState();
  const [executionProof, setExecutionProof] = React.useState();
  const openModal = useSelector(state => state.modals.statusMilestones);
  const description = useSelector(state => state.modals.dataMilestones.description);

  const handleClose = () => {
    setExecutionProof('');
    dispatch(toggleMilestonesModal());
  };

  const handleChange = date => setDate(date);

  const handleAction = () => {
    dispatch(onActionMilestonesModal({ date, executionProof }));
  };

  return (
    <Container
      open={openModal}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          background: 'rgba(#050714, 0.6) !important',
        },
      }}
      disableAutoFocus
    >
      <Fade in={openModal}>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Entrega de marco</ModalTitle>
          </ModalHeader>
          <Content>
            <div>
              <Label>Marco</Label>
              <Description>
                {description}
              </Description>
            </div>
            <Row>
              <Box
                display="flex"
                flexDirection="column"
                margin="0 15px"
                flex="1"
              >
                <Label>Data da execução</Label>
                <ExecutionDatePicker
                  adjustDateOnChange
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  startDate={date}
                  selected={date}
                  onChange={handleChange}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                margin="0 15px"
                flex="2"
              >
                <Label>Prova de execução</Label>
                <ExecutionProofInput
                  value={executionProof}
                  onChange={e => setExecutionProof(e.target.value)}
                />
              </Box>
            </Row>
            <Row>
              <CancelButton onClick={handleClose}>
                <ButtonLabel>Cancelar</ButtonLabel>
              </CancelButton>
              <ConfirmButton onClick={() => handleAction()}>
                <ButtonLabel>Incluir Marco</ButtonLabel>
              </ConfirmButton>
            </Row>
          </Content>
        </ModalContainer>
      </Fade>
    </Container>
  );
};

export default MilestonesModal;
