import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;

  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
      border-color: #7483DD !important;
    }

    label.Mui-focused {
      color: #7483DD !important;
    }
  }
`;

export const ToLogin = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 32px;

  gap: 30px;

  a {
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 12px;

    text-decoration: none;

    color: ${({ theme }) => theme.colors.blueAqua};
  }
`;

export const Enter = styled.div`
  display: block;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 316px;
    height: 32px;

    background: #7483DD;

    &:hover {
      background: #7483DD;
    }

    border: none;
    border-radius: 30px;

    @media (max-width: 450px){
      width: 100%;
      display: block;
      margin: 0 auto;
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;

      color: ${({ theme }) => theme.colors.white};

      text-transform: uppercase;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
`;
