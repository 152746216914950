import Checkbox from '@material-ui/core/Checkbox';
import Input from 'components/Input';
import Typography from 'components/Typography';
import React, { useCallback, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { phoneMask, cpfMask } from 'utils/maskUtils';
import AdminFormGroup from '../AdminFormGroup';
import { useUser } from 'hooks/useUser';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';

import { Container, Content, Row, Body, LocationThreeRow, LocationTwoRow } from './styles';
import ReactTooltip from 'react-tooltip';
import './style.css';
import { useMemo } from 'react';

const WitnessAndLocationAdmin = ({ isDisabled, legalPerson }) => {
  const { setValue, getValues, control } = useFormContext();
  const [checked, setChecked] = useState(false);
  const [authNameRegisterResponsible, setAuthNameRegisterResponsible] = useState();
  const [authEmailRegisterResponsible, setAuthEmailRegisterResponsible] = useState();
  const [authPhoneRegisterResponsible, setAuthPhoneRegisterResponsible] = useState();
  const [authFullNameWitness, setAuthFullNameWitness] = useState(getValues('auth_full_name_witness'));
  const [authWitnessEmail, setAuthWitnessEmail] = useState(getValues('auth_witness_email'));
  const [disabled, setDisabled] = useState(false);
  const [personType, setPersonType] = useState(0);
  const [authNameResponsible, setAuthNameResponsible] = useState(getValues('auth_name_responsible'));
  const [authCpfResponsible, setAuthCpfResponsible] = useState(getValues('auth_cpf_responsible'));
  const [authPhoneResponsible, setAuthPhoneResponsible] = useState(getValues('auth_phone_responsible'));
  const [authEmailResponsible, setAuthEmailResponsible] = useState(getValues('auth_email_responsible'));
  const { user } = useUser();
  const theme = useTheme();

  const legalPersons = useMemo(
    () => [
      'Pessoa física',
      'Entidade Empresarial',
      'Entidade sem fins lucrativos',
      'Administração Pública Federal',
      'Administração Pública Estadual',
      'Administração Pública Municipal'
    ],
    [],
  );

  const handleAddResponsible = useCallback((value) => {
    setChecked(value);
    if (value) {
      const full_name_witness = getValues('name_responsible');
      const witness_email = getValues('email_responsible');
      setValue('full_name_witness', full_name_witness);
      setValue('witness_email', witness_email);
    } else {
      setValue('full_name_witness', '');
      setValue('witness_email', '');
    }
  }, [setValue, getValues]);

  useEffect(() => {
    if (getValues('auth_name_register_responsible') !== undefined) {
      setAuthNameRegisterResponsible(getValues('auth_name_register_responsible'));
    }
    if (getValues('auth_phone_register_responsible') !== undefined) {
      setAuthPhoneRegisterResponsible(getValues('auth_phone_register_responsible'));
    }
    if (getValues('auth_email_register_responsible') !== undefined) {
      setAuthEmailRegisterResponsible(getValues('auth_email_register_responsible'));
    }
    if (getValues('auth_full_name_witness') !== undefined) {
      setAuthFullNameWitness(getValues('auth_full_name_witness'));
    }
    if (getValues('auth_witness_email') !== undefined) {
      setAuthWitnessEmail(getValues('auth_witness_email'));
    }
    
    if (legalPerson || getValues('legalPerson')) {
      setPersonType(legalPersons.findIndex(
        item => item === (getValues('legalPerson') || legalPerson),
      ));
    }

  }, [legalPerson, legalPersons, getValues]);

  return (
    <Container>
      {personType !== 0 && (
            <>
              {user.adminRole !== 'general' && (
                <>
                  <Typography variant="h6" color="grayDark2">
                    Dados do responsável legal
                  </Typography>
                  <Content>
                    <Row colSize={4}>
                        <AdminFormGroup
                          checked={authNameResponsible}
                          onChange={value => {
                            setAuthNameResponsible(value);
                            setValue('auth_name_responsible', value);
                          }}
                        >
                        {/* *Obrigatório */}
                          <Controller
                            name="name_responsible"
                            control={control}
                            render={({ field }) => (
                              <Input 
                              data-tip="O responsável legal é a pessoa que <br/>
                              possui poderes para assinar o contrato <br/>
                              representando a empresa. Ele deve constar no <br/>
                              Quadro Societário ou no Contrato Social/Estatuto/Ata, <br/>
                              ou ainda, em procuração que lhe outorgue poderes para isso." 
                              data-html={true}
                              data-for="tooltip1"
                              data-event='click focus'
                              label="Nome completo" {...field} disabled={isDisabled}/>
                            )}
                          />
                          <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                        </AdminFormGroup>
                        <AdminFormGroup
                          checked={authCpfResponsible}
                          onChange={value => {
                            setAuthCpfResponsible(value);
                            setValue('auth_cpf_responsible', value);
                          }}
                        >
                        {/* *Obrigatório */}
                          <Controller
                            name="cpf_responsible"
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="CPF"
                                {...field}
                                onChange={e => {
                                  const value = cpfMask(e.target.value);
                                  setValue('cpf_responsible', value);
                                }}
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </AdminFormGroup>
                        <AdminFormGroup
                          checked={authPhoneResponsible}
                          onChange={value => {
                            setAuthPhoneResponsible(value);
                            setValue('auth_phone_responsible', value);
                          }}
                        >
                        {/* *Obrigatório */}
                          <Controller
                            name="phone_responsible"
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Telefone"
                                {...field}
                                onChange={e => {
                                  const value = phoneMask(e.target.value);
                                  setValue('phone_responsible', value);
                                }}
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </AdminFormGroup>
                        <AdminFormGroup
                          checked={authEmailResponsible}
                          onChange={value => {
                            setAuthEmailResponsible(value);
                            setValue('auth_email_responsible', value);
                          }}
                        >
                        {/* *Obrigatório */}
                          <Controller
                            name="email_responsible"
                            control={control}
                            render={({ field }) => <Input label="Email" {...field} disabled={isDisabled}/>}
                          />
                        </AdminFormGroup>
                    </Row>
                  </Content>
                </>
              )}
              <div className='space'></div>
            </>
      )}

      <Body>
        <Typography variant="h6" color="grayDark2">
        Dados do responsável pelo cadastro

        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
        Preencha com os seus dados pessoais. 
        <InfoIcon 
          data-for="tooltip2" 
          data-tip="O responsável pelo cadastro é a pessoa<br/> 
          que faz a gestão do projeto e que poderá <br/>
          responder nossas dúvidas. Ela pode ser a mesma <br/>
          pessoa que o responsável legal ou não." 
          multiline={true} 
          data-html={true} 
          style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
        </Typography>
        <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
      </Body>
      <Content>
        <Row colSize={3}>
          <AdminFormGroup
            checked={authNameRegisterResponsible}
            onChange={value => {
              setAuthNameRegisterResponsible(value);
              setValue('auth_name_register_responsible', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              name="name_register_responsible"
              control={control}
              render={({ field }) => (
                <Input
                  label="Nome completo"
                  {...field}
                  disabled={isDisabled}
                />
              )}
            />
          </AdminFormGroup>
          <AdminFormGroup
            checked={authEmailRegisterResponsible}
            onChange={value => {
              setAuthEmailRegisterResponsible(value);
              setValue('auth_email_register_responsible', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              name="email_register_responsible"
              control={control}
              render={({ field }) => (
                <Input
                  label="E-mail"
                  {...field}
                  disabled={isDisabled}
                />
              )}
            />
          </AdminFormGroup>
          <AdminFormGroup
            checked={authPhoneRegisterResponsible}
            onChange={value => {
              setAuthPhoneRegisterResponsible(value);
              setValue('auth_phone_register_responsible', value);
            }}
          >
          {/* *Obrigatório */}
            <Controller
              name="phone_register_responsible"
              control={control}
              render={({ field }) => (
                <Input
                  label="Telefone"
                  {...field}
                  onChange={e => {
                    const value = phoneMask(e.target.value);
                    setValue('phone_register_responsible', value);
                  }}
                  disabled={isDisabled}
                />
              )}
            />
          </AdminFormGroup>
        </Row>
      </Content>
      {user.adminRole !== 'general' && (
        <Body>
          <Typography variant="h6" color="grayDark2">
            Dados da testemunha
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Para ser validado, nosso contrato digital exige a assinatura de uma testemunha, que não pode ser o representante legal, deve ter mais de 18 anos e não precisa ter vínculo com o projeto.
          </Typography>
          <Content>
            <Typography variant="body1">
              <Checkbox
                checked={checked}
                onChange={e => handleAddResponsible(e.target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                style={{ width: 'fit-content' }}
                disabled={isDisabled}
              />
              Mesmos dados do responsável pelo cadastro
            </Typography>
            <Row colSize={2}>
              <AdminFormGroup
                checked={authFullNameWitness}
                onChange={value => {
                  setAuthFullNameWitness(value);
                  setValue('auth_full_name_witness', value);
                }}
              >
              {/* *Obrigatório */}
                <Controller
                  name="full_name_witness"
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Nome completo da testemunha"
                      {...field}
                      disabled={isDisabled}
                      data-tip="A testemunha precisa ser diferente do responsável<br/>
                      legal indicado anteriormente. Pode ser qualquer pessoa <br/>
                      maior de idade." 
                      data-html={true}
                      data-for="tooltip1"
                      data-event='click focus'
                    />
                  )}
                />
                <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
              </AdminFormGroup>
              <AdminFormGroup
                checked={authWitnessEmail}
                onChange={value => {
                  setAuthWitnessEmail(value);
                  setValue('auth_witness_email', value);
                }}
              >
              {/* *Obrigatório */}
                <Controller
                  name="witness_email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="E-mail da testemunha"
                      {...field}
                      disabled={isDisabled}
                    />
                  )}
                />
              </AdminFormGroup>
            </Row>
          </Content>
        </Body>
      )}
    </Container>
  );
};

export default WitnessAndLocationAdmin;