import styled, { css } from 'styled-components';

export const Td = styled.td`
  padding: 1rem 1.5rem;
  border: 0;
  background: ${({ theme }) => theme.colors.tableItemBackground};

  ${({ isCollapsed, isAdmin }) =>
    isCollapsed
      ? css`
          &:first-child {
            border-radius: ${isAdmin ? '4px' : '30px 0 0 0'};
            width: 30%;
          }
          &:nth-child(2) {
            width: 30%;
          }
          &:last-child {
            border-radius: ${isAdmin ? '4px' : ' 0 30px 0 0'};
          }
        `
      : css`
          &:first-child {
            border-radius: ${isAdmin ? '4px' : '30px 0 0 30px'};
            width: 30%;
          }
          &:nth-child(2) {
            width: 30%;
          }
          &:last-child {
            border-radius: ${isAdmin ? '4px' : '0 30px 30px 0'};
          }
        `};
      ${({ showCollapsed, isAdmin }) => (!showCollapsed && !isAdmin) && css` svg {display: none;}`};
`;

export const TdContentAction = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: transform 0.2s;

  svg {
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.54;
  }

  &:hover {
    transform: scale(0.95);
  }
`;

export const TdContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  p {
    margin: 0;
  }
`;

export const TdActionCollapse = styled.div`
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.54;
  }
`;

export const TdCollapsed = styled.td`
  padding: 1rem 1.5rem;
  border: 0;
  background: ${({ theme }) => theme.colors.tableItemBackground};
  border-radius: 0 0 6px 6px !important;
`;

export const TrCollapsed = styled.tr`
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          display: table-row;
        `
      : css`
          display: none;
        `};
`;
