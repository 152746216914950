import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import Table from 'components/Table';
import Typography from 'components/Typography';
import defaultProjectAvatar from 'assets/images/default-project.png';
import formatCurrency from 'utils/currency';
import BidderTableDetail from './BiddersTableDetail';
// import VitrineTableDetail from './VitrineTableDetail';

import { ProjectItem } from './styles';

const BiddersTable = ({ data, identification }) => {
  const columns = useMemo(() => {
    return [
      {
        identification: 'nameBidder',
        name: '',
        renderColumn: ({ nameBidder, urlImage }) => {
          return (
            <ProjectItem>
              <img src={urlImage || defaultProjectAvatar} alt="Projeto" />
              <div>
                <Typography variant="manrope" color="grayDark2">
                  Nome do proponente
                </Typography>
                <Typography variant="manrope" color="brown">
                  {nameBidder}
                </Typography>
              </div>
            </ProjectItem>
          );
        },
      },
      {
        identification: 'local',
        name: 'Localização',
        showTitle: true,
      },
      {
        identification: 'projects',
        name: 'Projetos (Qtd.)',
        showTitle: true,
      },
      {
        identification: 'email',
        name: 'E-mail',
        showTitle: true,
      },
      {
        identification: 'status',
        name: 'Status',
        showTitle: true,
      },
      {
        identification: 'id',
        name: 'Área do proponente',
        showTitle: true,
        isDetail: true,
        //TODO: Implementar a função de detalhe
      },
    ];
  }, []);

  return (
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={data}
      tableCollapsed
      collapsedComponent={BidderTableDetail}
      isPaginated={false}
    />
  );
};

export default BiddersTable;
