import { useDispatch } from 'react-redux';
import { login } from 'store/ducks/auth/actions';
import { LoginService } from 'services/login';
import api from 'services/api';
import { toast } from 'react-toastify';

export const useAuth = () => {
  const dispatch = useDispatch();

  const onSignIn = async (email, password, persist) => {
    try {
      await LoginService.login({ email, password })
        .then(response => {
          api.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;
          const role =
            response.data.user.role === 'vitrine-manager'
              ? 'Company'
              : 'Bidder';
          const isAdmin =
            response.data.user.is_admin === 1 ||
            response.data.user.role === 'vitrine-manager';

          const user = {
            id: response.data.user.id,
            email: response.data.user.email,
            token: response.data.access_token,
            name: response.data.user.name,
            avatar: response.data.user.image,
            isAdmin,
            role,
            userRole: response.data.user.role,
            proponentId: response.data.user.proponentId || response.data.user.proponent_id,
            sponsorId: response.data.user.sponsorId || response.data.user.sponsor_id,
            phone: response.data.user.phone,
            adminRole: response.data.user.admin_role,
          };
          dispatch(login(user, persist));
        })
        .catch(err => {
          if (err.response.data) {
            toast.error(err.response.data.error);
          } else {
            toast.error('Credenciais inválidas');
          }
        });
    } catch (err) {
      throw new Error(err);
    }
  };

  return {
    onSignIn,
  };
};
