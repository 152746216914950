import React from 'react';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import { FlagOutlined } from '@material-ui/icons';
import { Container, Row, StepLabel, ProjectStep } from './styles';

const ProgressBar = ({ withLabel, progress, label = '', type = 'proponent', isFromStatus = false }) => {

  const color = type === 'proponent' ? '#754ca4' :(type === 'black' ?  '#000' : '#C15028')
  const useStyles = makeStyles(() => ({
    barrot: {
      background: isFromStatus ? `repeating-linear-gradient(to right, ${color}, ${color} 24.8%, transparent 24.9%, transparent 25%)`: color,
    },
    root: {
      backgroundColor: 'rgba(18, 32, 63, 0.1);',
    },
  }));
  const classes = useStyles();

  return (
    <Container>
      {withLabel && (
        <Row>
          <FlagOutlined />
          <ProjectStep>Status</ProjectStep>
        </Row>
      )}
      <Box width="100%" display="flex" alignItems="center">
        <Box width="100%" height={15}>
          <LinearProgress
            classes={{
              barColorPrimary: classes.barrot,
            }}
            className={classes.root}
            variant="determinate"
            value={progress > 100 ? 100 : progress}
            style={{ height: '100%', borderRadius: 4, background: isFromStatus ? `repeating-linear-gradient(to right, #e2e4e7, #e2e4e7 24.8%, transparent 24.9%, transparent 25%)`: null }}
          />
        </Box>
      </Box>
      {withLabel && <StepLabel>{label}</StepLabel>}
    </Container>
  );
};

export default ProgressBar;
