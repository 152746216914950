import React from 'react';
import Input from 'components/Input';
import ButtonIcon from 'components/ButtonIcon';
import FilterList from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';

import { Container, Actions } from './styles';

const ProjectsFilter = ({ showFilter, search, onSearch, isLoading }) => {
  return (
    <Container>
      <Input label="Buscar por projeto" rounded={true} 
        onChange={e => search(e.target.value)}
        disabled={isLoading}
      />
      <Actions>
        <ButtonIcon variant="secondary" size="large" onClick={showFilter} >
          <FilterList />
        </ButtonIcon>
        <ButtonIcon variant="primary" size="large" onClick={() => onSearch()}>
          <Search />
        </ButtonIcon>
      </Actions>
    </Container>
  );
};

export default ProjectsFilter;
