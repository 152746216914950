import React, { useState, useCallback, useEffect } from 'react';
import NewTabs from 'components/NewTabs';
import PageTitle from 'components/PageTitle';
import ProponentProfile from 'components/ProponentProfile';
import ProponentProfileForm from 'components/ProponentProfileForm';
import { ProponentService } from 'services/proponent';
import { useUser } from 'hooks/useUser';
import { toast, ToastContainer } from 'react-toastify';

const Profile = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [proponent, setProponent] = useState();

  if(!localStorage.getItem("clickedNotifier")){
    localStorage.setItem("clickedNotifier", true);
  }

  const Msg = ({ closeToast, toastProps }) => (
    <div>
      Foi adicionado um novo campo no seu cadastro. Vá na aba cadastro e saiba mais.
    </div>
  )

  const loadProponentData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ProponentService.getOneProponent(
        user?.proponentId,
      );
      if (Array.isArray(response.data)) {
        let proponentLaws = response?.data[0]?.incentiveLaws;
        let proponentData = response?.data[0];
        if(proponentLaws){
          if(proponentLaws.length >0){
            let lawArray = proponentLaws.split(",")
            if(lawArray){
              proponentData.incentiveLaws = lawArray
            }
          }
        }else{
          proponentLaws = []
          proponentData.incentiveLaws = proponentLaws
        }
        setProponent(proponentData);
      }
      console.log(response?.data[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadProponentData();
    if(localStorage.getItem("clickedNotifier") !== 'visualizado')
      displayMsg()
  }, [loadProponentData, user]);

  const displayMsg = () => {
    toast(
      <Msg/>, 
      {
        type: 'info',
        icon: true,
        position: "top-right", 
        autoClose: 30000, 
        draggable: true
      }
    ) 
  }

  return (
    <div className="fade-in">
      <main>
        <PageTitle title="Meus Dados" />
        <NewTabs
          tabs={[
            {
              title: 'Perfil',
              component: (
                <ProponentProfile loading={loading} proponent={proponent} />
              ),
            },
            {
              title: localStorage.getItem("clickedNotifier") === "visualizado" ? 'Cadastro' : 'Cadastro ✨',
              component: (
                <ProponentProfileForm loading={loading} proponent={proponent} />
              ),
            },
          ]}
        />
      </main>
    </div>
  );
};

export default Profile;
