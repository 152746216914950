import React, { useState, memo, useCallback, useEffect } from 'react';
import Typography from 'components/Typography';
import { ArrowForward, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import {
  Td,
  TdContentAction,
  TdContent,
  TdActionCollapse,
  TdCollapsed,
  TrCollapsed,
} from './styles';

function TableCell({
  item,
  columns = [],
  isCollapsed = false,
  collapsedComponent: CollapsedComponent,
  onRefresh,
  isAdmin = false,
  collapsedComponentProps,
  onCollapsed,
  showCollapsed = true
}) {
  const [collapsed, setCollapsed] = useState(false);

  const renderColumnItem = useCallback(
    column => {
      if (column.renderColumn) {
        return (
          <TdContent>
            {column.showTitleRenderColumn && (
              <Typography variant="body2" color="grayDark2">
                {column.name}
              </Typography>
            )}
            {column.renderColumn(item)}
          </TdContent>
        );
      }

      if (column.isDetail) {
        return (
          <TdContentAction onClick={() => column.action && column.action(item)}>
            {column.showTitle && (
              <Typography variant="body2" color="grayDark2">
                {column.name}
              </Typography>
            )}
            <ArrowForward fontSize="small" />
          </TdContentAction>
        );
      }

      return (
        <TdContent>
          {column.showTitle && (
            <Typography variant="body2" color="grayDark2">
              {column.name}
            </Typography>
          )}
          <Typography variant="manrope" color="brown">
            {item[column.identification]}
          </Typography>
        </TdContent>
      );
    },
    [item],
  );

  useEffect(() => {
    if (onCollapsed && collapsed) {
      onCollapsed(item);
    }
  }, [onCollapsed, collapsed, item]);

  return (
    <>
      <tr>
        {columns.map(column => (
          <Td isCollapsed={collapsed} isAdmin={isAdmin}>
            {renderColumnItem(column)}
          </Td>
        ))}
        {isCollapsed && (
          <Td isCollapsed={collapsed} isAdmin={isAdmin} showCollapsed={showCollapsed}>
            <TdActionCollapse onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <ArrowDropUp fontSize="small" />
              ) : (
                <ArrowDropDown fontSize="small" />
              )}
            </TdActionCollapse>
          </Td>
        )}
      </tr>
      <TrCollapsed isCollapsed={collapsed} isAdmin={isAdmin}>
        <TdCollapsed colSpan={columns.length + 1}>
          {CollapsedComponent && (
            <CollapsedComponent 
              {...item}
              onRefresh={onRefresh}
              {...collapsedComponentProps}
            />
          )}
        </TdCollapsed>
      </TrCollapsed>
    </>
  );
}

export default memo(TableCell);
