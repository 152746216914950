import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 45px;
  min-height: 745px;
  height: 100%;
  font-family: ${props => props.theme.typography.fontFamily};
`;

export const TabPanelContainer = styled.div`
  height: 65px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
`;

export const TabPanelButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;
`;

export const TabPanelButtonLabel = styled.span`
  ${props => props.theme.typography.span};
  font-family: ${props => props.theme.typography.span};
  color: ${props => props.theme.colors.secondary};
  align-self: center;
`;

export const TabContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 30px;
  min-height: 650px;
  height: auto;
  ${({ notPadding }) => notPadding ? css`
    padding: 0;
  ` : css`
    padding: 24px;
  `}
  margin-top: 30px;
`;
