import styled from 'styled-components';

export const Container = styled.div`
  width: ${({ width }) => width ?? '100%'};
  /* height: 32px; */

  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 2px;
`;

export const Title = styled.div`
  margin-left: 12px;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.black};
`;
