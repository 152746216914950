import React, { useCallback, useState } from 'react';
import RecoveryPasswordForm from 'components/Forms/RecoveryPasswordForm';
import {
  Container,
  Header,
  Title,
} from './styles';

const RecoveryPassword = () => {
  const [visibility, setVisibility] = useState(false);

  const changeVisibilityIcon = useCallback(() => {
    setVisibility(!visibility);
  }, [visibility]);

  return (
    <Container>
      <Header>
        <Title>Redefinir senha</Title>
      </Header>
      <RecoveryPasswordForm changeIcon={changeVisibilityIcon} visibility={visibility} />
    </Container>
  );
};

export default RecoveryPassword;
