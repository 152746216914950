import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
export const Container = styled(IconButton)`
  ${({ variant, theme }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid ${theme.colors.primary} !important;
    `}

  ${({ variant, theme }) =>
    variant === 'contained' &&
    css`
      background-color: ${theme.colors.primary} !important;
      svg {
        fill: white;
      }
    `}
`;
