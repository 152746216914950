import { combineReducers } from 'redux';

import word from './word';
import modals from './modals';
import auth from './auth';
import register from './register';
import fullScreenImages from './fullScreenImage';

export default combineReducers({
  word,
  modals,
  auth,
  register,
  fullScreenImages
});
