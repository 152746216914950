import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ArrowForward } from '@material-ui/icons';
import Table from 'components/Table';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { EditContainer, TesteContainer } from './styles';
import { toggleBidersModal, toggleEditUserModal } from 'store/ducks/modals/actions';

const UsersTable = ({ data, identification, isLoading }) => {
  const dispatch = useDispatch();
  const maskPassword = password => {
    return password.replace(/./g, '*');
  };
  const roles = {
    master: 'Mestre',
    cs_proponent1: 'Prospecção',
    cs_proponent2: 'Backoffice CS Proponente',
    support: 'Suporte',
    evaluator: 'Avaliador',
    general: 'Geral',
    key_account2: 'Key Account Nível 2',
    cs_proponent_relationship: 'CS Proponente Nível Relacionamento',
    monitore: 'Monitore',
  };
  console.log('data', data)
  const columns = useMemo(() => {
    return [
      {
        identification: 'name',
        name: 'Nome do usuário',
        showTitle: true,
      },
      {
        identification: 'email',
        name: 'Email',
        showTitle: true,
      },
      {
        identification: 'adminRole',
        name: 'Perfil',
        showTitleRenderColumn: true,
        renderColumn: ({ adminRole }) => {
          return (
            <TesteContainer>
              <Typography variant="manrope" color="brown" className="teste">
                {roles[adminRole]}
              </Typography>
            </TesteContainer>
          );
        },
      }
      // {
      //   identification: 'user',
      //   renderColumn: user => {
      //     return (
      //       <EditContainer onClick={() => dispatch(toggleEditUserModal(user))}>
      //         <Typography variant="caption">Editar perfil</Typography>
      //         <ArrowForward size={24} />
      //       </EditContainer>
      //     );
      //   },
      // }
    ];
  }, []);
  const transferIndex = columns.findIndex(x => x.identification === "transfer");
  
  // if(transferIndex < 0){
  //   columns.push({
  //     identification: 'transfer',
  //     renderColumn: user => {
  //       return (
  //         ['cs_proponent1', 'cs_proponent_relationship'].includes(user.adminRole) && (
  //           <EditContainer onClick={() => dispatch(toggleBidersModal(user))}>
  //             <Typography variant="caption">Transferir proponentes</Typography>
  //             <ArrowForward size={24} />
  //           </EditContainer>
  //         )
  //       );
  //     },
  //   });
  // }

  return (
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={data}
      isPaginated={false}
      loading={isLoading}
    />
  );
};

export default UsersTable;
