import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'components/Typography';
import FileInput from 'components/FileInput';

import {
  Container,
  Content,
  Row,
  DocumentItem,
  ContainerRow,
  Body,
} from './styles';
import Input from 'components/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';
import FileItem from 'components/FileItem';
import AdminFormGroup from '../AdminFormGroup';
import DragAndDrop from 'components/DragAndDrop';
import { validateFileExtension, validateFileSize } from 'utils/validation';

const ImagesAndVideosAdmin = ({ isDisabled, isUploading }) => {
  const { control, setValue, setError, getValues, formState: { errors }, clearErrors } = useFormContext();

  const [images, setImages] = useState([]);
  const [authPhotoProfile, setAuthPhotoProfile] = useState(getValues('auth_photo_profile'));
  const [authVideo, setAuthVideo] = useState(getValues('auth_video'));
  const [authPhotosCarousel, setAuthPhotosCarousel] = useState(getValues('auth_photos_carousel'));
  const [authSite, setAuthSite] = useState(getValues('auth_site'));
  const [authLinkedIn, setAuthLinkedIn] = useState(getValues('auth_linkedln'));
  const [authYouTube, setAuthYouTube] = useState(getValues('auth_youtube'));
  const [authInstagram, setAuthInstagram] = useState(getValues('auth_instagram'));
  const [authFacebook, setAuthFacebook] = useState(getValues('auth_facebook'));

  const handleDeleteImage = useCallback(
    index => {
      const newImages = images.filter((item, i) => i !== index);
      setImages(newImages);
      if (newImages.length === 0) {
        setValue('imagen1', '');
        setValue('imagen2', '');
        setValue('imagen3', '');
        setValue('imagen4', '');
      } else {
        setValue('photosCarousel', newImages);
      }
    },
    [images, setValue],
  );

  const handleAddImages = useCallback(
    files => {
      const fileList = Array.from(files);
      fileList.forEach((file, index) => {
        if (images.length + (index + 1) <= 4) {
          setValue(`imagen${images.length + (index + 1)}`, file);
        }
      });
    },
    [setValue, images],
  );

  useEffect(() => {
    if (Array.isArray(getValues('photosCarousel'))) {
      setImages(getValues('photosCarousel').map(image => image.imagen));
    }

    if (getValues('auth_photo_profile') !== undefined) {
      setAuthPhotoProfile(getValues('auth_photo_profile'));
    }
    if (getValues('auth_video') !== undefined) {
      setAuthVideo(getValues('auth_video'));
    }
    if (getValues('auth_photos_carousel') !== undefined) {
      setAuthPhotosCarousel(getValues('auth_photos_carousel'));
    }
    if (getValues('auth_site') !== undefined) {
      setAuthSite(getValues('auth_site'));
    }
    if (getValues('auth_linkedln') !== undefined) {
      setAuthLinkedIn(getValues('auth_linkedln'));
    }
    if (getValues('auth_facebook') !== undefined) {
      setAuthFacebook(getValues('auth_facebook'));
    }
    if (getValues('auth_youtube') !== undefined) {
      setAuthYouTube(getValues('auth_youtube'));
    }
    if (getValues('auth_instagram') !== undefined) {
      setAuthInstagram(getValues('auth_instagram'));
    }
  }, [getValues]);

  return (
    <Container>
      <Row colSize={2}>
        <ContainerRow>
          <Typography variant="h6" color="grayDark2">
            Imagens
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Clique em upload e anexe os itens necessários.
          </Typography>
          <Content>
            <DocumentItem>
              <Typography variant="overline" color="grayDark2">
                Foto de perfil (opcional)
              </Typography>
              {/* <AdminFormGroup
                checked={authPhotoProfile}
                onChange={value => {
                  setAuthPhotoProfile(value);
                  setValue('auth_photo_profile', value);
                }}
              > */}
                <DragAndDrop 
                  placeholder="nenhum arquivo enviado"
                  onChange={files => {
                      if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                        return setError("photo_profile", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg ou png" });
                      if(!validateFileSize(files, 2))
                        return setError("photo_profile", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                    if (files.length === 1) {
                      setValue('photo_profile', files[0]);

                      clearErrors("photo_profile")
                    }
                  }}
                  label={'Foto de perfil'}
                  link={[getValues('photo_profile')]}
                  disabled={isDisabled}
                  isUploading={isUploading}
                  error={errors["photo_profile"]?.message}
                />
            </DocumentItem>
          </Content>
        </ContainerRow>
        <ContainerRow>
          <Typography variant="h6" color="grayDark2">
            Vídeo
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Insira um link de vídeo que melhor explica o seu projeto!
          </Typography>
          <Content>
            <DocumentItem>
              <Typography variant="overline" color="grayDark2">
              Link de vídeo do Vimeo ou Youtube que melhor explica o seu projeto!
              </Typography>
              <AdminFormGroup
                checked={authVideo}
                onChange={value => {
                  setAuthVideo(value);
                  setValue('auth_video', value);
                }}
              >
                <Controller
                  name="video"
                  control={control}
                  render={({ field }) => <Input {...field} disabled={isDisabled}/>}
                />
              </AdminFormGroup>
            </DocumentItem>
          </Content>
        </ContainerRow>
        <ContainerRow>
          <Typography variant="subtitle2" color="grayLight1">
          Adicione até 3 imagens sobre o proponente - Formatos aceitos: JPG e PNG com o max. de 2MB por imagem
          </Typography>
          <Content>
            <DocumentItem>
              <AdminFormGroup
                checked={authPhotosCarousel}
                onChange={value => {
                  setAuthPhotosCarousel(value);
                  setValue('auth_photos_carousel', value);
                }}
              >
                {/* <FileInput
                  buttonText="UPLOAD"
                  onChange={handleAddImages}
                  multiple
                  disabled={images.length === 4 || isDisabled}
                /> */}
                <DragAndDrop 
                  placeholder="nenhum arquivo enviado"
                  onChange={files => {
                      if(files.length + images.length > 3)
                        return setError("images", { message: "Adicione no máximo 3 arquivos."});
                      if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                        return setError("images", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg ou png" });
                      if(!validateFileSize(files, 2))
                        return setError("images", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                    if (files.length === 1) {
                      handleAddImages(files);

                      clearErrors("images")
                    }
                  }}
                  isUploading={isUploading}
                  multiple={true}
                  disabled={isDisabled}
                  error={errors["images"]?.message}
                />
              </AdminFormGroup>
            </DocumentItem>
            {images.map((photo, index) => (
              <FileItem
                key={v4()}
                title={photo}
                onDelete={() => handleDeleteImage(index)}
              />
            ))}
          </Content>
        </ContainerRow>
      </Row>
      <Content>
        <Body>
          <Typography variant="h6" color="grayDark2">
            Presença digital
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Indique o perfil da sua instituição nas principais redes sociais.
          </Typography>
          <Content>
            <AdminFormGroup
              checked={authSite}
              onChange={value => {
                setAuthSite(value);
                setValue('auth_site', value);
              }}
            >
              <Controller
                name="site"
                control={control}
                render={({ field }) => (
                  <Input label="Site" {...field} disabled={isDisabled}/>
                )}
              />
            </AdminFormGroup>
            <Row colSize={2}>
              <AdminFormGroup
                checked={authLinkedIn}
                onChange={value => {
                  setAuthLinkedIn(value);
                  setValue('auth_linkedln', value);
                }}
              >
                <Controller
                  name="linkedln"
                  control={control}
                  render={({ field }) => (
                    <Input label="LinkedIn" {...field} disabled={isDisabled}/>
                  )}
                />
              </AdminFormGroup>
              <AdminFormGroup
                checked={authFacebook}
                onChange={value => {
                  setAuthFacebook(value);
                  setValue('auth_facebook', value);
                }}
              >
                <Controller
                  name="facebook"
                  control={control}
                  render={({ field }) => (
                    <Input label="Facebook" {...field} disabled={isDisabled}/>
                  )}
                />
              </AdminFormGroup>
              <AdminFormGroup
                checked={authYouTube}
                onChange={value => {
                  setAuthYouTube(value);
                  setValue('auth_youtube', value);
                }}
              >
                <Controller
                  name="youtube"
                  control={control}
                  render={({ field }) => (
                    <Input label="YouTube" {...field} disabled={isDisabled}/>
                  )}
                />
              </AdminFormGroup>
              <AdminFormGroup
                checked={authInstagram}
                onChange={value => {
                  setAuthInstagram(value);
                  setValue('auth_instagram', value);
                }}
              >
                <Controller
                  name="instagram"
                  control={control}
                  render={({ field }) => (
                    <Input label="Instagram" {...field} disabled={isDisabled}/>
                  )}
                />
              </AdminFormGroup>
            </Row>
          </Content>
        </Body>
      </Content>
    </Container>
  );
};

export default ImagesAndVideosAdmin;
