import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: ${({ theme }) => theme.colors.white};
`;
