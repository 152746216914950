import React, { useMemo } from 'react';
import Table from 'components/Table';
import AvatarTable from 'components/AvatarTable';
import Typography from 'components/Typography';
import defaultProjectAvatar from 'assets/images/default-project.png';
import ProponenteTableDetails from './ProponenteTableDetails';
import { useHistory } from 'react-router-dom';
import { ButtonLink, Circle, StatusContainer } from './styles';

const ProponenteTable = ({ 
  notifications,
  identification,
  isLoading,
  isPaginated,
  currentPage,
  onChangePage,
  totalPages,
  onRefresh,
 }) => {
  const { push } = useHistory();
  const columns = useMemo(() => {
    return [
      {
        identification: 'nameProponent',
        name: 'Nome do Proponente',
        showTitle: true,
        renderColumn: ({ photoProfile, name, fullName }) => {
          return (
            <AvatarTable
              logo={photoProfile}
              title={name}
              subtitle={fullName ?? name}
            />
          );
        },
      },
      {
        identification: 'localization',
        name: 'Localização',
        renderColumn: ({ city, state }) => {
          return (
            <div>
              <Typography variant="manrope" color="grayDark2">
                Localização
              </Typography>
              <Typography variant="manrope" color="brown">
                {city} - {state}
              </Typography>
            </div>
          );
        },
      },
      {
        identification: 'cnd_status',
        name: 'Status da CND',
        renderColumn: ({ cnd_status, cnd_message }) => {
          return (
            <StatusContainer>
              <Typography variant="manrope" color="grayDark2">
                Status
              </Typography>
              <Typography variant="manrope" color="brown" className="status-message">
                <Circle status={cnd_status}/>
                {cnd_message ?? 'Consulta não realizada'}
              </Typography>
            </StatusContainer>
          );
        },
      },
      {
        identification: 'projects',
        name: 'Projetos (Qtd.)',
        renderColumn: ({ numberProjects }) => {
          return (
            <div>
              <Typography variant="manrope" color="grayDark2">
                Projetos (Qtd.)
              </Typography>
              <Typography variant="manrope" color="brown">
                {numberProjects}
              </Typography>
            </div>
          );
        },
      },
      {
        identification: 'email',
        name: 'E-mail',
        showTitle: true,
      },
      {
        identification: 'status',
        name: 'Status',
        renderColumn: ({ status }) => {
          return (
            <div>
              <Typography variant="manrope" color="grayDark2">
                Status
              </Typography>
              <Typography variant="manrope" color="brown">
                {Number(status) > 0 ? 'Ativo' : 'Inativo'}
              </Typography>
            </div>
          );
        },
      },
      {
        identification: 'proponenteArea',
        name: 'Área do Proponente',
        showTitle: true,
        isDetail: true,
        renderColumn: ({ id }) => {
          return (
            <div>
              <Typography variant="manrope" color="grayDark2">
                <ButtonLink>
                  <a href={`/admin/proponentes/${id}`} target="_blank" rel="noreferrer">Área do Proponente</a>
                </ButtonLink>
              </Typography>
            </div>
          );
        }
      },
    ];
  }, [push]);

  return (
    <Table
      identification={identification || 'id'}
      key={identification}
      columns={columns}
      data={notifications}
      tableCollapsed
      collapsedComponent={ProponenteTableDetails}
      isPaginated={isPaginated}
      loading={isLoading}
      currentPage={currentPage}
      onChangePage={onChangePage}
      totalPages={totalPages}
      onRefresh={onRefresh}
    />
  );
};

export default ProponenteTable;
