import React from 'react';
import Typography from 'components/Typography';
import { formatDate } from 'utils/date';
import { Container, Link } from './styles';
import { ChatBubbleOutline } from 'assets/icons';

const NotificationProjectTableDetails = props => {
  return (
    <Container>
      <div>
        <div>
          <Typography variant="body2" color="grayDark2">
            Mensagem
          </Typography>
          <Typography variant="manrope" color="brown">
            {props?.body || '-'}
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default NotificationProjectTableDetails;
