import styled from 'styled-components';

export function getColors(color) {
  const objState = {
    proponente: {
      linearGradient: 'linear-gradient(to right, #8700E3, #550B78 20%)',
      primary: '#8700E3',
    },
    patrocinador: {
      linearGradient:
        'linear-gradient(to right, #55D875 11.87%, #1CB9B8 22.5%)',
      primary: 'rgba(30, 205, 204, 1)',
    },
    default: {
      linearGradient: 'linear-gradient(to right, #DA2100, #7483DD 20%)',
      primary: 'gray',
    },
  };
  return objState[color];
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Form = styled.form`
  width: 786px;
  height: 100%;

  padding: 24px;

  border-radius: 15px;

  background-color: ${({ theme }) => theme.colors.white};

  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  @media (max-width: 786px){
    width: 94%;
    float: none;
    display: block;
    margin: 0 auto;
    padding: 15px;
  }
`;

export const BorderBottom = styled.div`
  width: 100%;

  margin-top: 10px;

  border: 1px solid ${({ theme }) => theme.colors.grayLight2};
`;

export const Submit = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 35px;

  gap: 10px;
`;

export const Back = styled.div`
  cursor: pointer;
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #46c4f6;
    text-transform: uppercase;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 12px;

  /* position: absolute;
  width: 94px;
  height: 32px;
  left: 29%;
  top: 18px; */

  border-radius: 4px;
`;
