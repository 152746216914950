import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { Container, Enter, ToLogin } from './styles';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { confirmPasswordValidationResolver } from 'validations/confirmPassword';
import { LoginService } from 'services/login';
import { useParams } from 'react-router-dom';
import InputPassword from 'components/InputPassword';
import PasswordCreatedModal from 'components/PasswordCreatedModal';

const RecoveryPasswordForm = () => {
  const { token } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: confirmPasswordValidationResolver,
  });

  const onSubmit = async ({ password, passwordConfirmation }) => {
    try {
      if (password !== passwordConfirmation) {
        toast.error('Senha e confirmar senha devem ser iguais');
      } else {
        await LoginService.resetPassword(token, { password });
        setShowModal(true);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Falha no servidor');
      }
    }
  };

  useEffect(() => {
    watch(({ password, passwordConfirmation }) => {
      setFormValid(!!password && !!passwordConfirmation);
    });
  }, [watch]);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="password"
        control={control}
        render={({ field }) => {
          return (
            <InputPassword
              className="input-password-login"
              label="Senha"
              error={errors?.password?.message}
              {...field}
            />
          );
        }}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field }) => {
          return (
            <InputPassword
              className="input-password-login"
              label="Confirmar nova senha"
              error={errors?.passwordConfirmation?.message}
              {...field}
            />
          );
        }}
      />
      <Enter>
        <Button type="submit" disabled={!formValid}>
          <div>
            <p>Continuar</p>
          </div>
        </Button>

        <ToLogin>
          <a href="/login">Clique aqui para acessar o sistema</a>
        </ToLogin>
      </Enter>
      <PasswordCreatedModal open={showModal} handleClose={() => setShowModal(false)} />
    </Container>
  );
};

export default RecoveryPasswordForm;
