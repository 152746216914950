import { Typography, Backdrop } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import { Container, ContainerModal, Footer, Row, Title } from '../styles';
import { toggleSuccessUserModal } from 'store/ducks/modals/actions';


const SuccessModal = ({ refreshData }) => {
  const successModal = useSelector(state => state.modals.usersModal.successModal);
  const isEdit = useSelector(state => state.modals.usersModal.isEdit);
  const dispatch = useDispatch();
  const handleClose = () => {
    refreshData();
    dispatch(toggleSuccessUserModal());
  };
  const onDelete = () => {};

  return (
    <Container
    open={successModal}
    onClose={handleClose}
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      style: {
        backgroundColor: `rgba(#050714, 0.6) !important`,
      }
    }}
    disableAutoFocus
    >
      <ContainerModal>
        <Title>
          <Typography variant="h4">{isEdit ? 'Dados alterados com sucesso!' : 'Dados cadastrados com sucesso!'}</Typography>
        </Title>
        <Row>
          <Typography>{isEdit ? 'Suas alterações foram salvas.' : 'O usuário foi cadastrado.'}</Typography>
        </Row>
        <Footer>
          <Button variant="tertiary" onClick={handleClose}>Fechar</Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
}

export default SuccessModal;
