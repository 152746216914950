import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Error500 = () => {
  const history = useHistory();

  return (
    <div className="fade-in">
      <main>
        <Grid container>
          <h2>500</h2>
          <p>Desculpe, estamos com problemas para carregar esta página.</p>
        </Grid>
        <Grid item>
          <Grid>
            <Button className="btnSecondary" href="/">
              <ArrowBackIosIcon /> página inicial
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Error500;
