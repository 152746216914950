import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import Typography from 'components/Typography';
import TabsOptions from 'components/TabsOptions';
import { Container, Link, Grid } from './styles';
import { OpenInNew } from 'assets/icons';
import { formatDate } from 'utils/date';

const AdmProjectsTableDetails = props => {
  const { push } = useHistory();

  const goToCaptureReport = useCallback(() => {
    const project_id = props.id;
    push(`/capture-report/${project_id}`);
  }, [push]);

  const renderCities = useMemo(() => {
    // if(props.id === 55){
      // console.log('props', props)
    // }

    if (props?.locations && props?.locations.length > 0) {
      const locations = props.locations;
      return locations
        .map(location => `${location.name} - ${location.state_code}`)
        .join(', ');
    }
    return 'Não informado';
  }, [props]);

  // useEffect(() => {
  //   setLaw(props?.lawDescription);
  // });
  const formatLaw = useMemo(() => {
    return props.laws.find(law => law.id === props?.lawId)?.description || 'Lei não encontrada';
  }, [props]);

  const formatDatePlusOne = useCallback((initialDate) => {
    if (initialDate === '' || initialDate == null) {
      return 'Data não informada';
    }
    var newDate = new Date(new Date(initialDate).setDate(new Date(initialDate).getDate() + 1));

    return formatDate(newDate, 'dd/MM/yyyy');
  }, []);

  return (
    <Container>
      <TabsOptions
        captureReport={goToCaptureReport}
        data={props}
        onRefresh={() => props?.onRefresh()}
      />
      <Grid>
        <div>
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>
            Resumo
          </Typography>
          <Typography variant="manrope" color="brown" style={{ marginBottom: 14 }}>
            {(props?.summary !== "null" && props?.summary !== "undefined") ? props?.summary : "Não informado"}
          </Typography>
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>
            Cidade da realização
          </Typography>
          <Typography variant="manrope" color="brown">
            {renderCities}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>
            Status do Projeto
          </Typography>
          <Typography variant="manrope" color="brown" style={{ marginBottom: 17 }}>
            {(props?.status !== "null" && props?.status !== "undefined" && props?.status !== null) ? props?.status : 'Não informado'}
          </Typography>
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>
            Última atualização
          </Typography>
          <Typography variant="manrope" color="brown" style={{ marginBottom: 17 }}>
            {formatDate(props?.updatedAt, 'dd/MM/yyyy')}
          </Typography>
          {props?.status === "Em análise" && (
            <>
              <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>
                Em análise desde:
              </Typography>
              <Typography variant="manrope" color="brown" style={{ marginBottom: 17 }}>
                {props?.changedToInAnalysisDate ? formatDatePlusOne(props?.changedToInAnalysisDate) : 'Data não informada.'}
              </Typography>
            </>
          )}
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 5 }}>
            Lei de incentivo
          </Typography>
          <Typography variant="manrope" color="brown" style={{ marginBottom: 17 }}>
            {formatLaw}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" color="grayDark2" style={{ marginBottom: 14 }}>
            Arquivos
          </Typography>
          {props?.socialContract && (
            <Link
              href={props?.socialContract}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
              Contrato social
              </Typography>
            </Link>
          )}

          {props?.lastElection && (
            <Link
              href={props?.lastElection}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
              Ata de diretoria
              </Typography>
            </Link>
          )}

          {props?.socialStatus && (
            <Link
              href={props?.socialStatus}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
              Estatuto Social
              </Typography>
            </Link>
          )}

          {props?.workPlan && (
            <Link
              href={props?.workPlan}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue" >
                Plano de Trabalho ou projeto aprovado na íntegra
              </Typography>
            </Link>
          )}
          {props?.officialJournal && (
            <Link
              href={props?.officialJournal}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
                Publicação no diário oficial
              </Typography>
            </Link>
          )}
          {props?.projectPresentation && (
            <Link
              href={props?.projectPresentation}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
                Apresentação do projeto
              </Typography>
            </Link>
          )}
          {props?.captureCertificate && (
            <Link
              href={props?.captureCertificate}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
                Certificado de captação
              </Typography>
            </Link>
          )}
          {props?.openingReceipt && props.lawId && props.lawId <= 10 && (
            <Link
              href={props?.openingReceipt}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
                Comprovante de abertura de conta do projeto
              </Typography>
            </Link>
          )}
          {props?.fundOpeningReceipt && props.lawId && props.lawId > 10 && (
            <Link
              href={props?.fundOpeningReceipt}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: 12 }}
            >
              <OpenInNew />
              <Typography variant="manrope" color="blue">
                Comprovante de dados bancários do fundo
              </Typography>
            </Link>
          )}
        </div>
      </Grid>
    </Container>
  );
};

export default AdmProjectsTableDetails;
