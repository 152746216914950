export const PROFILE = {
  type: '',
  isActive: false,
  identification: -1,
  name: '',
  cpf: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptReceiveNews: false,
  isTermsAccepted: false,
}