import styled from 'styled-components';

export const Container = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  p {
    font-size: ${props => props.theme.typography.p2.fontSize};
    margin: 0px;
    color: ${props => props.theme.colors.secondary};
  }

  .MuiLinearProgress-bar {
    transition: none;
  }
`;

export const ProjectStep = styled.p`
  ${props => props.theme.typography.p1};
  color: ${props => props.theme.colors.secondary};
  margin: 5px;
`;

export const StepLabel = styled.p`
  ${props => props.theme.typography.p3};
  color: ${props => props.theme.colors.secondary};
  padding-top: 5px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.secondary};
`;
