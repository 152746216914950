import React, { useEffect, useCallback } from 'react';
import RecoveryPassword from 'components/RecoveryPassword';
import Logo1 from 'assets/images/logo.png';
import Logo2 from 'assets/images/logo_inferior.svg';
import { Container, ImageLogo, ImageFooter } from './styles';
import { useDispatch } from 'react-redux';
import { register } from 'store/ducks/register/action';

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const clearStorage = useCallback(
    () =>
      dispatch(
        register({
          profile: {
            type: 'default',
            isActive: false,
          },
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  return (
    <Container>
      <div className="image" style={{width: '100%'}}>
        <ImageLogo src={Logo1}></ImageLogo>
      </div>
      <RecoveryPassword />
      <div className="image" style={{width: '100%'}}>
        <ImageFooter src={Logo2}></ImageFooter>
      </div>
    </Container>
  );
}
