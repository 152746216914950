import { action } from 'typesafe-actions';

export const toggleMilestonesModal = data =>
  action('@modals/@TOGGLE_MILESTONES_MODAL', data);

  export const onActionMilestonesModal = data =>
  action('@modals/@ON_ACTION_MILESTONES_MODAL', data);

export const onClearDataMilestonesModal = () =>
  action('@modals/@ON_CLEAR_DATA_MILESTONES_MODAL');

export const toggleCounterpartsModal = data =>
  action('@modals/@TOGGLE_COUNTERPARTS_MODAL', data);

export const onActionCounterpartsModal = data =>
  action('@modals/@ON_ACTION_COUNTERPARTS_MODAL', data);

export const onClearDataCounterpartsModal = () =>
  action('@modals/@ON_CLEAR_DATA_COUNTERPARTS_MODAL');

export const toggleCounterpartsReviewModal = () =>
  action('@modals/@TOGGLE_COUNTERPARTS_REVIEW_MODAL');

export const onActionCounterpartsReviewModal = data =>
  action('@modals/@ON_ACTION_COUNTERPARTS_REVIEW_MODAL', data);

export const onClearDataCounterpartsReviewModal = () =>
  action('@modals/@ON_CLEAR_DATA_COUNTERPARTS_REVIEW_MODAL');

export const toggleFilterModal = () => action('@modals/@TOGGLE_FILTER_MODAL');

export const onActionFilterModal = data =>
  action('@modals/@ON_ACTION_FILTER_MODAL', data);

export const onClearDataFilterModal = data =>
  action('@modals/@ON_CLEAR_DATA_FILTER_MODAL', data);

export const addCaptation = () => action('@modals/@ADD_CAPTATION');

export const changeBidder = () => action('@modals/@CHANGE_BIDDER');

export const changeStatus = () => action('@modals/@CHANGE_STATUS');

export const deleteProjectBidder = () => action('@modals/@DELETE_PROJECT_BIDDER');

export const indicateTo = () => action('@modals/@INDICATE_TO');
export const toggleCreateUserModal = () => 
  action('@modals/@TOGGLE_CREATE_USER_MODAL');

export const toggleEditUserModal = data => action('@modals/@TOGGLE_EDIT_USER_MODAL', data);

export const toggleChangeAdminRoleModal = data => action('@modals/@TOGGLE_CHANGE_ADMIN_ROLE_MODAL', data);

export const toggleDeleteUserModal = () => action('@modals/@TOGGLE_DELETE_USER_MODAL');

// export const toggleBidersModal = data => action('@modals/@TOGGLE_BIDDERS_MODAL', { deleteMiddleware: Boolean(data?.deleteMiddleware), data });
export const toggleBidersModal = data => action('@modals/@TOGGLE_BIDDERS_MODAL', data);

export const toggleSuccessUserModal = () => action('@modals/@TOGGLE_CREATE_SUCCESS_MODAL');
