import styled from 'styled-components';
import Background from 'assets/images/bg.png';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${Background});
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1366px) {
    height: 100%;
  }
`;

export const ImageLogo = styled.img`
  width: 224px;
  height: 173px;
  display: block;
  position: relative;
  margin: 0 auto;

  @media (max-width: 600px){
    width: 50vw;
    height: auto;
  }

  @media (max-width: 450px){
    width: 35vw;
    height: auto;
  }
`;

export const ImageFooter = styled.img`
  width: 237px;
  height: 78px;
  display: block;
  position: relative;
  margin: 20px auto;

  @media (max-width: 600px){
    width: 50vw;
    height: auto;
  }

  @media (max-width: 450px){
    width: 35vw;
    height: auto;
    margin: 10px auto;
  }
`;
