import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.table`
  width: 100%;
  /* height: 100%; */
  border-spacing: 0;
`;

export const TdCollapseDivider = styled.td`
  background: ${({ theme }) => theme.colors.white} !important;
  padding: 0 !important;
  height: 1rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
