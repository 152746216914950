import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import Typography from 'components/Typography';
import Input from 'components/Input';
import { Container, Label, ContentInput, Row, ContainerInputs, SelectStyled, DatePickerStyled, Test } from './styles';
import { ProjectService } from 'services/projects';
import { OdsService } from 'services/ods';
import DatePicker from 'components/DatePicker';
import SelectMultiple from 'components/SelectMultiple';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { LocationsService } from 'services/locations';
import { useUser } from 'hooks/useUser';

const AdmProjectsForms = ({ setFilters, filters }) => {
  const { user } = useUser();
  const [laws, setLaws] = useState([]);
  const [impactCategories, setImpactCategories] = useState([]);
  const [impactSubCategories, setImpactSubCategories] = useState([]);
  const [locationsUf, setLocationsUf] = useState([]);
  const [cities, setCities] = useState([]);
  // const [defaultCities, setDefaultCities] = useState([]);
  const [ods, setOds] = useState([]);
  const [selectedLaws, setSelectedLaws] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedOds, setSelectedOds] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedComplianceStatus, setSelectedComplianceStatus] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState([]);


  const loadFormInfo = useCallback(async () => {
    const [response, odsData, locationsData] = await Promise.all([
      ProjectService.getFormInfo(),
      OdsService.list(),
      LocationsService.list(),
    ]);
    let defaultArr = { name: 'Todos', description: 'Todos', state_code: 'Todos' };
    setLaws([defaultArr , ...response.data.laws]);
    setImpactCategories([defaultArr , ...response.data.impact_categories]);
    setImpactSubCategories([defaultArr , ...response.data.impact_subcategories]);
    const locationsArr = response.data.locations_uf.map(item => item.state_code).sort();
    setLocationsUf(['Todos' , ...locationsArr]);
    setOds([defaultArr , ...odsData.data]);
    setCities([defaultArr , ...locationsData.data.data]);
    // setDefaultCities(locationsData.data.data);
  }, []);

  const optionsStatus = [
      'Todos',
      'Em rascunho',
      'Em análise',
      'Em ajuste',
      'Aguardando regularização',
      'Em captação',
      'Incentivado',
      'Não captado',
      'Projeto reprovado',
  ];

  const complianceStatusOptions = useMemo(() => {
    return [
      'Todos',
      'Aguardando análise',
      'Aguardando dados de negociação',
      'Aguardando ajustes no cadastro',
      'Aguardando regularização do proponente',
      'Aguardando assinatura de contrato',
      'Projeto regular',
      'Cadastro recusado',
      'Aprovado com ressalvas',
    ];

  }, []);

  const orderOptions = useMemo(() => {
    return [
      'Atualizado entre',
      'Expira entre',
      'Criado entre',
      'Valor do projeto',
      'Data de cadastro',
      'Data de envio para análise',
      'Última análise realizada',
    ];

  }, []);

  const handleAddFilter = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [setFilters, filters],
  );

  useEffect(() => {
    loadFormInfo();
  }, [loadFormInfo]);

  useEffect(() => {
    handleAddFilter('law', selectedLaws)
  }, [selectedLaws]);

  useEffect(() => {
    handleAddFilter('category', selectedCategories)
  }, [selectedCategories]);

  useEffect(() => {
    handleAddFilter('status', selectedStatus)
  }, [selectedStatus]);

  useEffect(() => {
    handleAddFilter('ods', selectedOds)
  }, [selectedOds]);

  useEffect(() => {
    handleAddFilter('state', selectedStates)
  }, [selectedStates]);

  useEffect(() => {
    handleAddFilter('city', selectedCities)
  }, [selectedCities]);

  useEffect(() => {
    handleAddFilter('order_by', selectedOrderBy)
  }, [selectedOrderBy]);

  useEffect(() => {
    handleAddFilter('analysis_compliance_status', selectedComplianceStatus)
  }, [selectedComplianceStatus]);

  return (
    <Container className={!user.isAdmin ? 'filterBox' : ''}>
      <Typography variant="body1" color="brown">
        Filtros avançados
      </Typography>
      {!user.isAdmin ? (
        <Row>
          <ContainerInputs>
            <Label>Status</Label>
            <SelectMultiple
              className="select-multiple"
              role="proponente"
              value={selectedStatus}
              options={optionsStatus}
              onChange={e => { setSelectedStatus(e) }}
            />
          </ContainerInputs>
          <ContainerInputs>
            <Label>Lei</Label>
            <SelectMultiple
              className="select-multiple"
              role="proponente"
              value={selectedLaws}
              laws={laws}
              options={laws.map(law => law.description)}
              onChange={e => { setSelectedLaws(e) }}
            />
          </ContainerInputs>
          <ContainerInputs>
            <Label>Estado</Label>
            <SelectAutocomplete
              className="select-subcategory-name"
              placeholder="UF"
              options={locationsUf}
              onChange={(event, value) => setSelectedStates(value)}
            />
          </ContainerInputs>
          <ContainerInputs>
            <Label>Cidade</Label>
            <SelectAutocomplete
              className="select-subcategory-name"
              options={cities.map(item => item.name + ' - ' + item.state_code)}
              onChange={(event, value) => setSelectedCities(value)}
            />
          </ContainerInputs>
        </Row>
        ) : (
          <Row>
            <ContainerInputs>
              <Label>Categoria</Label>
              <SelectAutocomplete
                className="select-category-name"
                role="admin"
                options={impactCategories.map(item => item.name)}
                onChange={
                  (event, value) => setSelectedCategories(value)
                }
              />
            </ContainerInputs>
            <ContainerInputs>
              <Label>Status da análise de conformidade do projeto</Label>
              <SelectMultiple
                className="select-multiple"
                role="admin"
                value={selectedComplianceStatus}
                options={complianceStatusOptions.map(status => status)}
                onChange={e => { setSelectedComplianceStatus(e) }}
              />
            </ContainerInputs>
            <ContainerInputs>
              <Label>ODS</Label>
              <SelectMultiple
                className="select-multiple"
                value={selectedOds}
                options={ods.map(item => item.name)}
                onChange={e => { setSelectedOds(e) }}
                role="admin"
              />
            </ContainerInputs>
          </Row>
        )}
      {user.isAdmin && (
        <>
          <Row>
            <ContainerInputs>
              <Label>% Captada</Label>
              <ContentInput>
                <Input
                  type="number"
                  onChange={e =>
                    handleAddFilter('minimum_captured_value', Number(e.target.value))
                  }
                />
                <span>e</span>
                <Input
                  type="number"
                  onChange={e =>
                    handleAddFilter('maximum_captured_value', Number(e.target.value))
                  }
                />
              </ContentInput>
            </ContainerInputs>
            <ContainerInputs>
              <Label>Atualizado entre</Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_updated_at', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_updated_at', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>
            <ContainerInputs>
              <Label>Expira entre</Label>
              <ContentInput>
                <DatePickerStyled
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_funding_period', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_funding_period', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>
            </Row>
            <Row>
            <ContainerInputs>
              <Label>Criado entre</Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_created_at', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_created_at', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>
            <ContainerInputs>
              <Label>Valor do projeto</Label>
              <ContentInput>
                <Input
                  type="number"
                  onChange={e =>
                    handleAddFilter('minimum_approved_value', Number(e.target.value))
                  }
                />
                <span>e</span>
                <Input
                  type="number"
                  onChange={e =>
                    handleAddFilter('maximum_approved_value', Number(e.target.value))
                  }
                />
              </ContentInput>
            </ContainerInputs>
            <ContainerInputs>
              <Label>Data que projeto entrou em análise</Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_send_to_analysis_date_project', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_send_to_analysis_date_project', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>
          </Row>
          <Row>
            <ContainerInputs>
              <Label>Data de cadastro (Proponente)</Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_proponent_created_at', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_proponent_created_at', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>

            <ContainerInputs>
              <Label>Data de envio para análise (Proponente)</Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_send_to_analysis_date', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_send_to_analysis_date', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>

            <ContainerInputs>
              <Label>Última análise realizada (Proponente) </Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_analysis_compliance_status_date', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_analysis_compliance_status_date', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>

            <ContainerInputs>
              <Label>Ordenar por</Label>
              <SelectAutocomplete
                className="select-subcategory-name"
                placeholder="Ordenar por"
                role="admin"
                options={orderOptions}
                onChange={(event, value) => setSelectedOrderBy(value)}
                multiline={false}
                disableCloseOnSelect={false}
              />
            </ContainerInputs>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AdmProjectsForms;
