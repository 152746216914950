import React from 'react';
import { CircularProgress } from '@material-ui/core';
import ProponentDetailCard from './ProponentDetailCard';
import ProponentImagesCard from './ProponentImagesCard';
import ProponentInfoCard from './ProponentInfoCard';

import { Container, Content, LoaderContainer } from './styles';

const ProponentProfile = ({ loading, proponent }) => {
  return (
    <Container>
      {
        loading ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <>
            <ProponentInfoCard proponent={proponent} />
            <Content>
              <ProponentDetailCard proponent={proponent} />
              <ProponentImagesCard files={proponent?.photosCarousel} />
            </Content>
          </>
        )
      }
      
    </Container>
  );
};

export default ProponentProfile;