import React, { useCallback, useState } from 'react';
import Checkbox from 'components/Checkbox';
import LoginForm from 'components/Forms/LoginForm';
import LoginButtonSocial from 'components/LoginButtonSocial';
import { useTheme } from 'styled-components';
import {
  Container,
  ForgotPassword,
  Header,
  KeepConnected,
  Register,
  SocialButtons,
  SocialText,
  Title,
  Label,
  Warning,
  Link,
  IconContainer,
} from './styles';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import Typography from 'components/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Forms = () => {
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const theme = useTheme();

  const changeVisibilityIcon = useCallback(() => {
    setVisibility(!visibility);
  }, [visibility]);

  const changeChecked = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const env = process.env.REACT_APP_ENV === 'homolog';

  return (
    <Container>
      {env && (
        <Warning>
          <IconContainer>
            <ErrorOutlineIcon color="disabled"/>
          </IconContainer>
          <div>
            <Typography variant="body2">
              Você está acessando a versão de testes
            </Typography>

            <Typography variant="body2">
              (não oficial) da Conecta.Incentiv.
            </Typography>

            <Typography variant="body2">
            Para acessar a plataforma oficial <Link href={"https://conecta.incentiv.me/"} rel="noreferrer" target="_blank">clique aqui</Link>
            </Typography>
          </div>
        </Warning>
      )}
      <Header>
        <Title>Entrar</Title>
      </Header>
      {/* <SocialButtons>
        <LoginButtonSocial
          className="btn-linkedin"
          socialType="LinkedIn"
          color={theme.colors.blueAqua}
        >
          <SocialText>ENTRAR COM LINKEDIN</SocialText>
        </LoginButtonSocial>
        <LoginButtonSocial socialType="Facebook" color={theme.colors.blueAqua}>
          <SocialText>ENTRAR COM FACEBOOK</SocialText>
        </LoginButtonSocial>
      </SocialButtons> */}
      <LoginForm changeIcon={changeVisibilityIcon} visibility={visibility}>
        <ForgotPassword>
          <span onClick={() => setShowModal(true)}>Esqueceu sua senha?</span>
        </ForgotPassword>
        <KeepConnected>
          <Checkbox
            checked={checked}
            onClick={changeChecked}
            color="blueAqua"
          />
          <Label>Mantenha-me conectado</Label>
        </KeepConnected>
      </LoginForm>

      {/* <Register>
        <p>Novo usuário?</p>
        <a href="/registro">Cadastre-se</a>
      </Register> */}
      <ForgotPasswordModal
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
    </Container>
  );
};

export default Forms;
