import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Text = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;

  text-decoration-line: underline;

  color: ${({ theme }) => theme.colors.blue};
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;

  svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;