import {
  DeleteModal,
  AddCaptureModal
} from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import ModalInformation from 'components/ModalInformation';
import React, { useMemo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { ProjectService } from 'services/projects';

import { Container, Text } from './styles';
import { useUser } from 'hooks/useUser';
import updateCaptureContributionMap from 'utils/updateCaptureContributionMap';

const TabsOptionsReports = ({ captureReport, data, onRefresh }) => {
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const { user } = useUser();
  const showToProponent = user.isAdmin == false && data.sponsor_id == null;
  const removeCapture = useCallback(async () => {
    try {
      await ProjectService.deleteCapture(data.id);
      setModalInformationData({
        ...modalInformationData,
        title: 'Aporte excluido com sucesso!',
        onClick: () => {
          setShowModalInformation(false);
          setModalOpen('');
        },
      });
      onRefresh();
      toast.success('Aporte excluído com sucesso');
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao remover projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const updateCapturedValueProject = useCallback(async (payload) => {
    // const form = new FormData();

    // form.append('contributions', JSON.stringify(payload.contribution));

    // payload.contribution.map((contrib, index)=>{
    //   contrib.id ? form.append(`proof_financial_${contrib.id}`, contrib.proof_financial) : form.append(`new_proof_financial_${index}`, contrib.proof_financial);
    //   contrib.id ? form.append(`proof_contribution_${contrib.id}`, contrib.proof_contribution) : form.append(`new_proof_contribution_${index}`, contrib.proof_contribution);
    // });

    const contributions = payload.contribution;
    try {
      const response = await ProjectService.editCapturedValueProject(data.id, payload); 
      await updateCaptureContributionMap(contributions, data.id);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Captação atualizada com sucesso!');

        onRefresh();
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar a captação do projeto');
      }
    }
  }, [modalInformationData]);

  const modalRender = useMemo(() => {
    const modal = {
      deleteCapture: (
        <DeleteModal
          open={modalOpen === 'deleteCapture'}
          handleClose={() => setModalOpen('')}
          onClick={() => removeCapture()}
          title={'Tem certeza que deseja excluir esse aporte?'}
          body={'Essa ação não pode ser desfeita. Após a exclusão, os dados referentes ao aporte serão perdidos.'}
        />
      ),
      addCapture: (
        <AddCaptureModal
          open={modalOpen === 'addCapture'}
          handleClose={() => setModalOpen('')}
          onChange={(captured_value, sponsor_id, date, contribution, incentiveType, isSendEmail) => {
            updateCapturedValueProject({ captured_value, sponsor_id, date, contribution, incentiveType, isSendEmail})
          }}
          data={data}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, removeCapture]);

  return (
    <Container>
      {(['cs_proponent2', 'support', 'master', 'cs_proponent_relationship'].includes(user.adminRole) || showToProponent) && <Text onClick={() => setModalOpen('deleteCapture')}>Remover aporte</Text>}
      {(['cs_proponent2', 'support', 'master', 'cs_proponent_relationship'].includes(user.adminRole) || showToProponent) && <Text onClick={() => setModalOpen('addCapture')}>Editar aporte</Text>}
      {modalRender}

      <ModalInformation
        open={showModalInformation}
        handleClose={() => setShowModalInformation(false)}
        {...modalInformationData}
      />
    </Container>
  );
};

export default TabsOptionsReports;
