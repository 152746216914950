import styled from 'styled-components';

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayDark2};

  svg {
    margin-left: 10px;
    path {
      fill: ${({ theme }) => theme.colors.generalSection};
    }
  }
`;

export const TesteContainer = styled.div`
  .teste{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.brown};
    font-weight: 500;
  }
`;