import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Typography from 'components/Typography';
import Button from 'components/Button';
import Select from 'components/Select';
import Input from 'components/Input';
import { Container, ContainerAporte, InputContainer, Text, TextDelete, TitleFieldset } from './styles';
import { IconAdd, IconDelete, IconExclude } from 'assets/icons';
import DatePicker from 'components/DatePicker';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { SponsorService } from 'services/sponsor';
import { useTheme } from 'styled-components';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import formatCurrency, { currencyMask, currencyMaskStore } from 'utils/currency';
import { validateFileExtension, validateFileSize } from 'utils/validation';
import ReactSelectMain from 'components/ReactSelectMain';
import { Autocomplete } from '@material-ui/lab';
import { v4 } from 'uuid';
import './styles.css';
import DragAndDrop from 'components/DragAndDrop';
import Filename from 'components/Filename';
import { dateFormattedToDateISO, formatDateFix } from 'utils/date';

const AddCapture = ({ open, handleClose, onChange, data }) => {
  const float = data && data.value ? (data.value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) : null;

  const theme = useTheme();
  const role = theme.colors.background == '#12203F' ? 'proponente' : 'admin';

  const [incentiveType, setIncentiveType] = useState(role === 'proponente' ? 0 : (data ? (data?.sponsor_id ? 0 : 1) : null));
  const [formattedValue, setFormattedValue] = useState(data?.value ? currencyMask(float) :  0);
  const [incentivValue, setIncentivValue] = useState(data?.value ?? 0);
  const [date, setDate] = useState(data?.date ?? 0);

  // const [encouragingName, setEncouragingName] = useState(0);
  const [sponsors, setSponsors] = useState(0);
  const [selectedSponsor, setSelectedSponsor] = useState(data?.sponsor_id ?? null);
  const { setValue, control, getValues } = useForm();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledEmail, setIsDisabledEmail] = useState(true);
  
  const isDisabledRef = useRef();
  const isDisabledEmailRef = useRef();
  isDisabledRef.current = isDisabled;
  isDisabledEmailRef.current = isDisabledEmail;

  const [errors, setErrors] = useState(
    {
      proof_financial: {
        message: ''
      },

      proof_contribution: {
        message: ''
      }
    }
  )

  const [contribuitions, setContruibuitions] = 
    useState(data?.contributions ??
      [
        { 
          corporate_name: "", 
          proof_financial: null, 
          proof_contribution: null,
          value: "",
          register_date: ""
        }
    ]
    );

  const loadSponsors = useCallback(async () => {
    const response = await SponsorService.listSponsors();
    setSponsors(response.data);
  }, []);


  useEffect(() => {
    loadSponsors();
  }, []);

  const clearErrors = (proof_type) => {
    if(proof_type == 'proof_financial')
      setErrors({...errors, proof_financial: {message: ''}})
    else 
      setErrors({...errors, proof_contribution: {message: ''}})
  }

  if(sponsors){
    var sponsorsList = sponsors?.map((sponsor, idx) => ({
      label: sponsor.name,
      value: Object.assign({}, sponsor)
    }))
  }

  // useEffect(() => {
  //   if(incentiveType == 1){
  //     setValue('sponsorId', null)
  //     setSelectedSponsor(null)
  //   }
  // }, [incentiveType])
  
  useEffect(() => {
    checkDisabled();
  }, [contribuitions, incentiveType])

  let handleChangeContruibuition = (i, e, proofType) => {
    let newContruitionValues = [...contribuitions];

    if(e?.path){
      newContruitionValues[i][proofType] = e;
    }

    else{
      if(e.target.name === 'value'){
        const value_contribuition_formated = (e.target.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }); 
        
        newContruitionValues[i][e.target.name] = currencyMaskStore(value_contribuition_formated);
      }

      else
        newContruitionValues[i][e.target.name] = e.target.value;
    }

    setContruibuitions(newContruitionValues);
  }

  let addFieldsContruibuitions = () => {
    setContruibuitions([...contribuitions, 
      { 
        corporate_name: "", 
        proof_financial: null, 
        proof_contribution: null,
        value: "",
        register_date: ""
      }
    ])
    
    setIsDisabled(true)
    setIsDisabledEmail(true)
  }

  let removeContruibuitions = (i) => {
    let newContruitionValues = [...contribuitions];
    newContruitionValues.splice(i, 1);
    setContruibuitions(newContruitionValues)
    
    checkDisabled();
  }

  let isNull = (e) => {
    return e !== null
  }

  let checkDisabled = () => {
    var disabledCheck = false;
    var disabledCheckEmail = false;

    contribuitions.map((element, index) => (
      disabledCheck = (
        (isNull(incentiveType)) && 
        isNull(selectedSponsor) && 
        contribuitions[index]['corporate_name'] &&
        contribuitions[index]['value'] &&
        contribuitions[index]['register_date']
      ),
      disabledCheckEmail = (
        (isNull(incentiveType)) && 
        isNull(selectedSponsor) && 
        contribuitions[index]['corporate_name'] &&
        contribuitions[index]['value'] &&
        contribuitions[index]['register_date'] &&
        contribuitions[index]['proof_contribution'] &&
        contribuitions[index]['proof_financial']
      )
    ));
    setIsDisabled(!disabledCheck);
    setIsDisabledEmail(!disabledCheckEmail);
  }

  console.log("DATA", data)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        Adicionar captação              
      </DialogTitle>
      <DialogContent>
        <Container>
          {role !== 'proponente' &&
            <InputContainer>
              <Select 
                label="Tipo de incentivo"
                value={incentiveType}
                options={['Captado via Incentiv.me', 'Captado via terceiros']}
                onChange={e => {
                  setIncentiveType(e.target.value)
                  checkDisabled()
                }}
                role={role}
              />
            
            
            {
              incentiveType !== 1 && sponsors && (
                <Autocomplete
                  
                  options={sponsorsList}
                  renderOption={(option) => (
                    <li value={option.id} key={v4()}>{option.label}</li>
                  )}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      label="Patrocinadores"
                      style={{width: '93%'}}
                      inputProps={{ 
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}

                  // value={sponsorsList.find(({ value }) => value.id == getValues('sponsorId'))}
                  defaultValue={sponsorsList.find(({ value }) => value.id == selectedSponsor)}
                  onChange={(event, selected) => {
                    const id = selected ? selected['value']['id'] : null;
                    setValue('sponsorId', id)
                    setSelectedSponsor(id)
                    checkDisabled()
                  }}                
                />
              )
            }

            </InputContainer>
          }

          {role !== 'proponente' && incentiveType !== 1 && contribuitions.map((element, index) => (
            <>
              <ContainerAporte>
                <Text>Aporte {index < 9 ? '0'+(index+1) : index+1}</Text>
                
                <Container>
                  <Controller
                    name="corporate_name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        style={{width: '97%!important'}}
                        label="Razão social"
                        {...field}
                        onChange={e => {
                          handleChangeContruibuition(index, e)
                          checkDisabled()
                        }}
                        defaultValue={contribuitions[index]['corporate_name']}
                      />
                    )}
                  />
                </Container>

                <InputContainer>
                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => (
                      <Input 
                        label="Valor do incentivo"
                        {...field}
                        onChange={e => {
                          handleChangeContruibuition(index, e)
                          checkDisabled()
                        }}
                        value={formatCurrency(contribuitions[index]['value'], true, false, 2)}
                      />
                    )}
                  />

                  <DatePicker
                    label="Data de realização do aporte"
                    onChange={e => {
                      const dateValue = {target : {name: 'register_date', value: formatDateFix(e.toLocaleDateString("en-CA"))}};
                      console.log(dateValue)
                      handleChangeContruibuition(index, dateValue)
                      checkDisabled()
                    }}

                    isAportContrib={true}
                    defaultValue={contribuitions[index]['register_date']}
                  />
                </InputContainer>

                <InputContainer>
                  <div style={{ width: '100%' }}>
                    <Typography variant="body1" color="brown" className="body-title">
                      Ficha financeira  -  (Formatos aceitos: PDF c/ limite de 5 MB)
                    </Typography>

                    <DragAndDrop
                      placeholder="nenhum arquivo enviado"
                      onChange={files => {
                        handleChangeContruibuition(index, files[0], 'proof_financial')
                        clearErrors("proof_financial");

                        if(!validateFileExtension(files, ["application/pdf"])){
                          setErrors({...errors, proof_financial: {message: 'Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf.'}})
                        }

                        if(!validateFileSize(files, 5)){
                          setErrors({...errors, proof_financial: {message: 'Tamanho de arquivo incompatível. Por favor, insira arquivos com até 5MB.'}})
                        }
                      }}
                      // disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                      // isUploading={isUploading}
                      // deleteName={deleteCaptureName}
                      error={errors.proof_financial.message}
                    />
                    {(contribuitions[index]['proof_financial'] && !errors.proof_financial.message) && (
                      <>
                        <Filename
                          label={'Ficha financeira'}
                          handleDelete={() => {
                            const auxContruibution = [...contribuitions];
                            auxContruibution[index]['proof_financial'] = null;
                            setContruibuitions(auxContruibution);
                          }}
                          link={contribuitions[index]['proof_financial']}
                        />
                      </>
                    )}
                  </div>

                  <div style={{ width: '100%' }}>
                    <Typography variant="body1" color="brown" className="body-title">
                      Comprovante de aporte  -  (Formatos aceitos: PDF/JPG c/ limite de 5 MB)
                    </Typography>

                    <DragAndDrop
                      placeholder="nenhum arquivo enviado"
                      onChange={files => {
                        clearErrors("proof_contribution")
                        handleChangeContruibuition(index, files[0], 'proof_contribution')
                        if(!validateFileExtension(files, ["application/pdf", "image/jpg", "image/jpeg"])){
                          setErrors({...errors, proof_contribution: {message: 'Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf ou jpg.'}})
                        }

                        if(!validateFileSize(files, 5)){
                          setErrors({...errors, proof_contribution: {message: 'Tamanho de arquivo incompatível. Por favor, insira arquivos com até 5MB.'}})
                        }
                      } }
                      // onChange={files => {
                      //   clearErrors("capture_certificate");

                      //   if(!validateFileExtension(files, ["application/pdf", "application/jpg"]))
                      //     return setError("capture_certificate", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf ou jpg" });
                      //   if(!validateFileSize(files, 2))
                      //     return setError("capture_certificate", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                      //   setValue('capture_certificate', files)
                      //   setDeleteCaptureName(false);
                      //   handleSave();
                      // }}
                      // disabled={isInAnalysis || isInCapture || isEncouraged || isNotCaptured}
                      // isUploading={isUploading}
                      // deleteName={deleteCaptureName}
                      error={errors.proof_contribution.message}
                    />
                    {(contribuitions[index]['proof_contribution'] && !errors.proof_contribution.message) && (
                      <>
                        <Filename
                          label={'Comprovante de aporte'}
                          handleDelete={() => {
                            const auxContruibution = [...contribuitions];
                            auxContruibution[index]['proof_contribution'] = null;
                            setContruibuitions(auxContruibution);
                          }}
                          link={contribuitions[index]['proof_contribution']}
                        />
                      </>
                    )}
                  </div>
                </InputContainer>

                <TextDelete onClick={() => removeContruibuitions(index)}>Excluir <IconDelete/></TextDelete>

              </ContainerAporte>
            </>
          ))}
          {role !== 'proponente' && incentiveType !== 1 &&
            <Text onClick={() => addFieldsContruibuitions()}>Adicionar razão social <IconAdd/></Text>
          } 
                    
          {((role === 'proponente' && incentiveType === 0) || (role === 'admin' && incentiveType === 1)) && (
            <InputContainer>
              <Controller
                name="incentived_value"
                control={control}
                render={({ field }) => (
                  <Input 
                    label="Valor do incentivo"
                    {...field}
                    onChange={e => {
                      setFormattedValue(currencyMask(e.target.value))
                      setIncentivValue(currencyMaskStore(e.target.value))
                      console.log(currencyMask(e.target.value));
                      console.log(currencyMaskStore(e.target.value));
                      checkDisabled()
                    }}
                    value={`R$ ${formattedValue}`}
                  />
                )}
              />
              <DatePicker
                label="Data de realização do aporte"
                onChange={value => {
                  const dateValue = value.toLocaleDateString("en-CA");
                  setDate(formatDateFix(dateValue));
                  checkDisabled()
                }}
                defaultValue={date}
              />
            </InputContainer>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button 
        onClick={handleClose} 
          type="button"
          borderColor={theme.colors.primary}
          variant="secondary"
          color={theme.colors.primary}
          >Cancelar</Button>
        <Button onClick={() => {
          onChange(incentivValue, selectedSponsor, date, contribuitions, incentiveType)
        }} color="primary"
        disabled={((incentiveType == 1 || (role === 'proponente' && incentiveType === 0))) ? (!(date && incentivValue)) : isDisabledRef.current}>
          {data ? 'EDITAR REGISTRO' : 'SALVAR REGISTRO'}
        </Button>

        {/* {role !== 'proponente' && incentiveType == 0 && (
          <Button onClick={() => {
            onChange(incentivValue, selectedSponsor, date, contribuitions, null, true)
          }} color="primary"
          disabled={isDisabledEmailRef.current}>
             {data ? 'SALVAR E REENVIAR' : 'SALVAR E ENVIAR'}
          </Button>
        )
        } */}
      </DialogActions>
    </Dialog>
  );
};

export default AddCapture;
