export const ODSCards = [
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-1.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-2.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-3.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-4.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-5.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-6.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-7.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-8.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-9.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-10.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-11.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-12.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-13.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-14.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-15.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-16.svg',
  'https://s3.amazonaws.com/assets.incentiv.me-new/sdg/pt/SDG-17.svg',
];
