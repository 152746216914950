import { PROFILE } from "constants/register-configs";

const INITIAL_STATE = {
  profile: PROFILE,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@register/REGISTER':
      return { ...state, profile: { ...action.payload } };
    default:
      return state;
  }
};

export default reducer;
