import axios from 'axios';
import { simpleCrypto } from '../store/ducks/auth';

const localStorageItem = localStorage.getItem('@Vitrine' || '{}');

const userLogin =
  localStorageItem &&
  simpleCrypto.decrypt(localStorage.getItem('@Vitrine' || '{}') || '{}');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const correios = axios.create({
  baseURL: 'https://viacep.com.br/ws',
});

export const geocode = axios.create({
  baseURL: process.env.REACT_APP_GEOCODE_URL,
});

api.interceptors.request.use(config => {
  if (!config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${userLogin?.token}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        // localStorage.removeItem(String('@Vitrine'));
        window.location = '/';
      } else if (error.response.status === 440) {
        localStorage.removeItem(String('@Vitrine'));
        window.location = '/';
      }
    }

    return Promise.reject(error);
  },
);

export const authorization = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  },
};

export const auth = {
  // userId: userLogin && userLogin.user.id,
  headers: {
    Authorization: userLogin && userLogin.token,
  },
};

export const getFetcher = (url, token) =>
  api.get(url, { headers: { Authorization: token } }).then(res => res.data);

export default api;
