import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: auto;
  height: 756px;
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const SelectedImage = styled.img`
  height: 100%;
  width: auto;
  border-radius: 6px;
  object-fit: cover;
  object-position: top;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  object-fit: cover;
  object-position: center;
  gap: 20px;
`;

export const Thumbnail = styled.img`
  width: 254px;
  height: 173px;
  border-radius: 6px;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
`;
