import React from 'react';
import deffaulProjectAvatar from 'assets/images/default-project.png';
import Typography from 'components/Typography';
import { ProjectItem, Complement, Container } from './styles';

const AvatarTable = ({ logo, title, subtitle, complement }) => {
  return (
    <ProjectItem>
      <img src={logo || deffaulProjectAvatar} alt="Projeto" />
      <Container>
        <Typography variant="body2" color="grayDark2">
          {title}
        </Typography>
        <Typography variant="manrope" color="brown">
          {subtitle ?? 'Outros'}
        </Typography>
        {complement && <Complement>{complement}</Complement>}
      </Container>
    </ProjectItem>
  );
};

export default AvatarTable;
