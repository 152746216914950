import React, { useState } from 'react';
import Typography from 'components/Typography';
import { Container, Link, SubjectContainer, MessageContainer } from './styles';
import { ChatBubbleOutline } from 'assets/icons';
import NotificationForm from 'components/NotificationForm';
import NotificationFormAdmin from 'components/NotificationFormAdmin';
import { useUser } from 'hooks/useUser';
import DOMPurify from "dompurify";
const NotificationTableDetails = props => {
  const { user } = useUser();
  const [openNotificationForm, setOpenNotificationForm] = useState(false);

  let body = props?.body;

  if (body != null) {
    body = body.split('|||');
  }

  const subjects = {
    1: 'Cadastro',
    2: 'Análise em andamento',
    3: 'Análise concluída',
    4: 'Atualização de informações',
    5: 'Otimização do cadastro',
    6: 'Oportunidade de patrocínio',
    7: 'Outros',
  };

  body = body || {};
  return (
    <Container>
      <div>
        <SubjectContainer>
          <Typography variant="body2" color="grayDark2">
            Assunto
          </Typography>
          <Typography variant="manrope" color="brown">
            {props.subjectId ? subjects[props.subjectId] : (props.title ?? '-')}
          </Typography>
        </SubjectContainer>
        <MessageContainer>
          <Typography variant="body2" color="grayDark2">
            Mensagem
          </Typography>
          {Object.values(body).map(element => (
            <Typography variant="manrope" color="brown">
              <div
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element
                  .replace(': ,', ': ')
                  .replace(/,\s*$/, '')
                  .replace(/,/g, ', '))}}
              />
            </Typography>
          ))}
        </MessageContainer>
      </div>
      <div>
      {/* {['master', 'cs_proponent2', 'support', 'evaluator', 'cs_proponent_relationship'].includes(user.adminRole) && (
        <Typography variant="manrope" color="brown">
          <div>
            <Typography variant="body2" color="grayDark2">
              Responder notificação
            </Typography>
            <Link onClick={() => setOpenNotificationForm(true)}>
              <ChatBubbleOutline />
              <Typography variant="manrope" color="blue">
                Enviar notificação
              </Typography>
            </Link>
          </div>
        </Typography>
      )} */}
      </div>
      {user.isAdmin ? (
        <NotificationFormAdmin
          open={openNotificationForm}
          projectId={props?.projectId}
          proponentName={props?.proponentsName}
          proponentId={props?.receiverProponentId}
          onClose={() => setOpenNotificationForm(false)}
          onAfterSave={() => props?.onRefresh()}
          adminId={user?.id}
        />
      ) : (
        <NotificationForm
          open={openNotificationForm}
          receiverId={props?.receiverId}
          projectId={props?.projectId}
          onClose={() => setOpenNotificationForm(false)}
          onAfterSave={() => props?.onRefresh()}
        />
      )}
    </Container>
  );
};

export default NotificationTableDetails;
