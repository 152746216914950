import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  main {
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 25;
`;

export const Actions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;

  gap: 1.5rem;

  input {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ProjectsContainer = styled.div`
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100% - 229px);
  height: auto;
  border-radius: 30px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const ButtonSearch = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.orange};

  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 4px;

  cursor: pointer;
`;

export const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.orange};

  cursor: pointer;
`;