import React from 'react';
import {
  Box,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { useStyles, useStylesAdmin } from './materialStyles';
import { Subtitle, Title, StepButtonStyled } from './styles';
import { useUser } from 'hooks/useUser';
import { useEffect } from 'react';
import { useState } from 'react';

const steps = [
  {
    label: 'Dados básicos do projeto',
    description: `Insira as informações essenciais sobre o planejamento e execução do projeto.`,
  },
  {
    label: 'Resumo e objetivos',
    description: 'Sintetize os objetivos e o foco principal do seu projeto.',
  },
  {
    label: 'ODS e Impacto',
    description: `Indique os principais ODS's e categorias que correspondem ao seu projeto e especifique o público-alvo.`,
  },
  {
    label: 'Vídeo, imagens e documentos',
    description: `Escolha o vídeo e as imagens que melhor representam o seu projeto! Além disso, alguns documentos também precisarão ser anexados.`,
  },
  {
    label: 'Valor do projeto e incentivos',
    description: `Insira as informações referentes aos valores do projeto e o prazo de captação desse.`,
  },
  {
    label: 'Contrapartidas',
    description: `Exponha os benefícios para as empresas que decidirem patrocinar o seu projeto. `,
  },
];

const RegisterProject = props => {
  const { user } = useUser();
  const [stepErrors, setStepErrors] = useState({});

  const classesProponent = useStyles();
  const classesAdmin = useStylesAdmin();
  const classes = user?.isAdmin ? classesAdmin : classesProponent;

  useEffect(() => {
    const keyStep1Errors = ["name", "edition", "law_id", "runtime_days", "more_than_one_city", "locations"];
    const keyStep2Errors = ["goal", "specific_goal"];
    const keyStep3Errors = ["sd_goal_ids", "impact_category_id", "impact_subcategory_id", "target", "audience_goal"];
    const keyStep4Errors = ["image", "official_journal", "opening_receipt", "work_plan"];
    const keyStep5Errors = ["approved_value", "minimum_value", "approval_date", "funding_period"];

    setStepErrors({
      1: Object.keys(props.errors).some(x => keyStep1Errors.includes(x)),
      2: Object.keys(props.errors).some(x => keyStep2Errors.includes(x)),
      3: Object.keys(props.errors).some(x => keyStep3Errors.includes(x)),
      4: Object.keys(props.errors).some(x => keyStep4Errors.includes(x)),
      5: Object.keys(props.errors).some(x => keyStep5Errors.includes(x)),
      6: false,
      7: false
    });
  }, [props, props.errors]);

  return (
    <Box
      sx={{
        maxWidth: 500,
      }}
    >
      <Stepper
        className={classes.root}
        style={{
          borderRadius: 30,
          marginTop: 15,
        }}
        nonLinear
        // activeStep={6}
        orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step
            className={classes.colorText}
            key={step.label}
            completed={false}
            disabled={index === 6}
          >
            <StepButtonStyled color="inherit" onClick={props.handleStep(index)} isCompleted={props.activeStep >= index} hasError={stepErrors?.[index+1]}>
              <StepLabel error={stepErrors?.[index+1]}>
                {props.activeStep + 1 <= index ? (
                  step.label
                ) : (
                  <Title style={stepErrors?.[index+1] ? { color: "red" } : undefined} active={props.activeStep === index}>
                    {step.label}
                  </Title>
                )}
              </StepLabel>
            </StepButtonStyled>
            <StepContent TransitionProps={{ in: true }}>
              <Typography>
                <Subtitle>{step.description}</Subtitle>
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default RegisterProject;
