import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { groupBy } from 'utils/query';
import { translateAuthProjectData } from 'utils/translate';
import PendenciesFieldItem from './PendenciesFieldItem';

import { Container } from './styles';

const PendenciesFields = () => {
  const [reviewItems, setReviewItems] = useState({});
  const { getValues } = useFormContext();

  useEffect(() => {
    const keys = Object.keys(getValues());
    const authKeys = keys.filter(key => key.includes('auth_'));
    const data = [];
    const rules = ['false', null, 'null'];
    const isFund = getValues('law_id') > 10;

    authKeys.forEach(item => {
      if (!getValues(item) || rules.includes(getValues(item))) {
        if (
          (item === 'auth_fund_opening_receipt' &&
            isFund &&
            getValues('law_id')) ||
          (item === 'auth_opening_receipt' && !isFund && getValues('law_id')) ||
          (item !== 'auth_fund_opening_receipt' &&
            item !== 'auth_opening_receipt')
        ) {
          data.push({
            item,
            value: getValues(item),
            translate: translateAuthProjectData[item]?.text,
            step: translateAuthProjectData[item]?.step.id,
            stepTitle: translateAuthProjectData[item]?.step.text,
          });
        }
      }
    });

    setReviewItems(groupBy(data, 'step'));
  }, [getValues]);

  delete reviewItems.undefined;

  return (
    <Container>
      {Object.keys(reviewItems).length === 0 ? (
        <PendenciesFieldItem title="Nenhum campo foi marcado como “revisar”" />
      ) : (
        Object.keys(reviewItems).map(item => (
          <PendenciesFieldItem
            key={item}
            step={item}
            items={reviewItems[item].map(reviewItem => reviewItem.translate)}
          />
        ))
      )}
    </Container>
  );
};

export default PendenciesFields;
