import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'components/Typography';
import FileInput from 'components/FileInput';

import { Container, Content, Row, DocumentItem, ContainerRow, Body, ContentLinks } from './styles';
import Input from 'components/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';
import FileItem from 'components/FileItem';
import { ProponentService } from 'services/proponent';
import { toast } from 'react-toastify';
import DragAndDrop from 'components/DragAndDrop';
import { validateFileExtension, validateFileSize } from 'utils/validation';

const ImagesAndVideos = ({ isUploading }) => {
  const { control, setValue, setError, getValues, formState: { errors }, clearErrors } = useFormContext();

  const [images, setImages] = useState([]);
  const isDisabled = getValues('register_status') === 'Em análise' || getValues('register_status') === 'Concluído';

  const handleDeleteImage = useCallback(async imageId => {
    try {
      await ProponentService.removeImage(imageId);
      toast.success('Imagem removida com sucesso!');
      const newImages = images.filter(item => item.id !== imageId);
      setValue('photosCarousel', newImages);
      setImages(newImages);
    } catch (err) {
      toast.error('Falha ao remover imagem');
    }
  }, [images, setValue]);

  const handleAddImages = useCallback(files => {
    const fileList = Array.from(files);

    fileList.forEach((file, index) => {
      if (images.length + (index + 1) <= 4) {
        setValue(`imagen${images.length + (index + 1)}`, file);
      }
    });
  }, [setValue, images]);

  useEffect(() => {
    if (Array.isArray(getValues('photosCarousel'))) {
      setImages(getValues('photosCarousel'));
    }
  }, [getValues]);



  return (
    <Container>
      <Row colSize={2}>
        <ContainerRow>
          <Typography variant="h6" color="grayDark2">
            Imagens
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Clique em upload e anexe os itens necessários.
          </Typography>
          <Content>
            <DocumentItem>
              <Typography variant="overline" color="grayDark2">
                Foto de perfil (opcional)
              </Typography>
              <DragAndDrop 
                placeholder="nenhum arquivo enviado"
                onChange={files => {
                    if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                      return setError("photo_profile", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg ou png" });
                    if(!validateFileSize(files, 2))
                      return setError("photo_profile", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                  if (files.length === 1) {
                    setValue('photo_profile', files[0]);

                    clearErrors("photo_profile")
                  }
                }}
                label={'Foto de perfil'}
                link={[getValues('photo_profile')]}
                disabled={isDisabled}
                isUploading={isUploading}
                error={errors["photo_profile"]?.message}
              />
            </DocumentItem>
          </Content>
        </ContainerRow>
        <ContainerRow>
          <Typography variant="h6" color="grayDark2">
            Vídeo
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Insira um link de vídeo que melhor explica o seu projeto!
          </Typography>
          <Content>
            <DocumentItem>
              <Typography variant="overline" color="grayDark2">
                Link de vídeo do Vimeo ou Youtube que melhor explica o seu projeto!
              </Typography>
              <Controller
                name="video"
                control={control}
                render={({ field }) => (
                  <Input {...field} disabled={isDisabled} />
                )}
              />
            </DocumentItem>
          </Content>
        </ContainerRow>
        <ContainerRow>
          <Typography variant="subtitle2" color="grayLight1">
            Adicione até 3 imagens sobre o proponente - Formatos aceitos: JPG e PNG com o max. de 2MB por imagem
          </Typography>
          <ContentLinks>
            <DocumentItem>
              <DragAndDrop 
                placeholder="nenhum arquivo enviado"
                onChange={files => {
                    if(files.length + images.length > 3)
                      return setError("images", { message: "Adicione no máximo 3 arquivos."});
                    if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                      return setError("images", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato jpg ou png" });
                    if(!validateFileSize(files, 2))
                      return setError("images", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 2MB." });

                  if (files.length === 1) {
                    handleAddImages(files);

                    clearErrors("images")
                  }
                }}
                isUploading={isUploading}
                multiple={true}
                error={errors["images"]?.message}
              />
            </DocumentItem>
            {
              images.map(photo => (
                <FileItem 
                  key={photo.id}
                  title={photo.imagen}
                  onDelete={() => handleDeleteImage(photo.id)}
                  disabled={isDisabled}
                />
              ))
            }
          </ContentLinks>
        </ContainerRow>
      </Row>
      <Content>
        <Body>
          <Typography variant="h6" color="grayDark2">
            Presença digital
          </Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Indique o perfil da sua instituição nas principais redes sociais.
          </Typography>
          <Content>
            <Controller
              name="site"
              control={control}
              render={({ field }) => (
                <Input label="Site" {...field} disabled={isDisabled} />
              )}
            />
            <Row colSize={2}>
              <Controller
                name="linkedln"
                control={control}
                render={({ field }) => (
                  <Input label="LinkedIn" {...field} disabled={isDisabled} />
                )}
              />
              <Controller
                name="facebook"
                control={control}
                render={({ field }) => (
                  <Input label="Facebook" {...field} disabled={isDisabled} />
                )}
              />
              <Controller
                name="youtube"
                control={control}
                render={({ field }) => (
                  <Input label="Youtube" {...field} disabled={isDisabled} />
                )}
              />
              <Controller
                name="instagram"
                control={control}
                render={({ field }) => (
                  <Input label="Instagram" {...field} disabled={isDisabled} />
                )}
              />
            </Row>
          </Content>
        </Body>
      </Content>
    </Container>
  );
};

export default ImagesAndVideos;