import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.5rem;
  gap: 1rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 240px);
  gap: 1.5rem;
`;

export const IncentiveContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IncentiveContainerRow = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ButtonAddCounterPart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 240px;
  height: 40px;

  background: rgba(106, 6, 164, 0.05);
  border-radius: 30px;
  border: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonAddText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-left: 10px;

  color: ${({ theme }) => theme.colors.primary};
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

export const IconColor = styled.div`
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const ActionsButton = styled.div`
  display: inline-flex;
  align-items: center;
`;