import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Typography from 'components/Typography';

import {
  Container,
  InfoTextContainer,
  Content,
  Row,
  Options,
  OptionsContent,
  CityList,
  ContentChip,
} from './styles';
import Input from 'components/Input';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'components/Select';
import { Radio, Tooltip } from '@material-ui/core';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { ProjectLocalText } from 'components/Forms/RegisterProject/Step3/styles';
import { useTheme } from 'styled-components';
import { ProjectService } from 'services/projects';
import { useUser } from 'hooks/useUser';
import { onlyNumber } from 'utils/maskUtils';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import ReactTooltip from 'react-tooltip';
import './style.css';

const Step1 = ({ laws, ufs, locations, setCreateChip, createChip, changedFields, setChangedFields}) => {
  const { control, setValue, getValues } = useFormContext();
  const [authStatus, setAuthStatus] = useState(getValues('auth_status'));
  const [authLaw, setAuthLaw] = useState(getValues('auth_law_id'));
  const [authName, setAuthName] = useState(getValues('auth_name'));
  const [authLocationIds, setauthLocationIds] = useState(getValues('auth_location_ids'));
  const [authSpecificGoal, setAuthSpecificGoal] = useState(getValues('auth_specific_goal'));
  const [citySelected, setCitySelected] = useState();
  const [citiesSelected, setCitiesSelected] = useState([]);
  const [ufsSelected, setUfsSelected] = useState([]);
  const [changeCityProject, setChangeCityProject] = useState(false);
  const [selectedUF, setSelectedUF] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [cities, setCities] = useState([]);
  const [showCities, setShowCities] = useState(false);
  const [showRetentionPercentage, setShowRetentionPercentage] = useState(false);
  const [selectedLaw, setSelectedLaw ] = useState(getValues('law_id'));

  setTimeout(() => {
    if([11, 12, 13, 14, 15, 16].includes(getValues('law_id'))){
      setShowRetentionPercentage(true);
    }
  }, 400);

  const [authRuntimeDays, setAuthRuntimeDays] = useState(
    getValues('auth_runtime_days'),
  );
  const [changeAuthName, setChangeAuthName] = useState(changedFields.name);
  changeAuthName && setValue('auth_name', !changeAuthName);
  const [changeAuthLaw, setChangeAuthLaw] = useState(changedFields.law_id);
  changeAuthLaw && setValue('auth_law_id', !changeAuthLaw);
  
  const theme = useTheme();
  const [moreThanOneCity, setMoreThanOneCity] = useState(getValues('more_than_one_city'));
  const { user } = useUser();
  const roles = [
    'Em rascunho',
    'Em ajuste',
    'Aguardando regularização'
  ];

  let isDisabled = false;
  const project_status = getValues('status');
  
  if((user.adminRole === 'cs_proponent1' || user.adminRole === 'cs_proponent2' || user.adminRole === 'cs_proponent_relationship') && !roles.includes(project_status)){
    isDisabled = true;
  } else if((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
    isDisabled = true;
  } else if(user.adminRole === 'general' || user.adminRole === 'monitore') {
    isDisabled = true;
  }

  const handleSelectedCitites = useCallback(
    type => {
      if (citiesSelected.includes(type)) {
        setCitiesSelected(citiesSelected.filter(ods => ods !== type));
      } else {
        setCitiesSelected([...citiesSelected, type]);
      }
    },
    [citiesSelected],
  );

  useEffect(() => {
    setValue('location_ids', citiesSelected);
    setValue('city', citiesSelected);
  }, [setValue, citiesSelected]);

  useEffect(() => {
    setValue('ufs', ufsSelected);
  }, [setValue, ufsSelected]);

  const loadCities = useCallback(async value => {
    const response = await ProjectService.getCity(value);
    setCities(response.data);
    setShowCities(true);
  }, []);

  const handleSelectedUF = useCallback(
    value => {
      setSelectedUF(value);
      if (ufsSelected.includes(value)) {
        setUfsSelected(ufsSelected.filter(ufs => ufs !== value));
      } else {
        setUfsSelected([...ufsSelected, value]);
      }
      loadCities(value);
    },
    [loadCities, ufsSelected],
  );

  const handleSelectedCity = useCallback(
    value => {
      setSelectedCity(value);
      handleSelectedCitites(value);
    },
    [handleSelectedCitites],
  );

  const handleCreateCity = useCallback(() => {
    if (moreThanOneCity === 0 && createChip.length > 0) return;
    
    const value = `${selectedUF} - ${selectedCity}`;
    
    if (!createChip.includes(value)) {
      setCreateChip(prevState => [
        ...prevState,
        value,
      ]);
    };

  }, [createChip, moreThanOneCity, selectedCity, selectedUF, setValue]);

  const handleDeleteCity = useCallback(cityToDelete => {
    setCreateChip(prevState => prevState.filter(city => city !== cityToDelete));
  }, []);

  useEffect(() => {
    if (locations) {
      setCitySelected(locations?.length > 0);
      const ufIndex = ufs.findIndex(
        uf => uf.state_code === locations[0].state_code,
      );
      const uf = ufs.find(uf => uf.state_code === locations[0].state_code);
      setSelectedUF(ufIndex);
      locations = locations.filter((element) => {
          return element.uf !== null;
      })
      if(createChip.length === 0){
        setCreateChip(
          locations.map(location => `${location.uf} - ${location.city}`),
        );
      }
    }
  }, [locations, setValue, ufs, loadCities]);

  useEffect(()=>{
    hasLawPecent(selectedLaw)
  },[selectedLaw]);

  function hasLawPecent(lawId) {
    if([11,12,13,14,15,16].includes(lawId)){
      setShowRetentionPercentage(true);
    }else{
      setShowRetentionPercentage(false);
      setValue('retention_percentage', null);
    }
  }

  setValue('locations', createChip);

  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Dados básicos do projeto
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Insira as informações sobre o seu projeto conforme aprovado em lei.
        </Typography>
      </InfoTextContainer>
      <Content>
        <Row>
          <AdminFormGroup
            checked={ changeAuthName ? false : (getValues('auth_name') ? true : false)}
            onChange={value => {
              setAuthName(value);
              setValue('auth_name', value);
              setChangeAuthName(!changeAuthName);
              value && setChangedFields({
                ...changedFields,
                name: false,
                law_id: changeAuthLaw
              });
            }}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                {
                  (changedFields?.name && changeAuthName) && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                {/* *Obrigatório */}
                <Input 
                  label="Nome do projeto" 
                  {...field} 
                  disabled={isDisabled}
                  data-tip="Insira o nome do projeto que foi aprovado <br/>no Diário Oficial " 
                  data-html={true}

                  required={true}
                  value={field.value}
                  margin={85}

                  data-for="tooltip1"
                  data-event='click focus'
                  />
                </div>
              )}
            />
            
            <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
          </AdminFormGroup>
          <Controller
            name="edition"
            control={control}
            render={({ field }) => (
              <div style={{display: 'flex',alignItems: 'center',gap: '5px', color: 'red'}}>
                {
                  changedFields?.edition && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                {/* *Obrigatório */}
                <Input 
                  label="Edição do projeto" 
                  {...field} 
                  disabled={isDisabled}
                  data-tip="Insira aqui em qual edição o projeto está ou se <br/>
                  é edição única." 
                  data-html={true}

                  required={true}
                  margin={413}
                  value={field.value}

                  data-for="tooltip1"
                  data-event='click focus'
                  />
              </div>
            )}
          />
          <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
        </Row>
        <Row>
          <AdminFormGroup
            checked={ changeAuthLaw ? false : (getValues('auth_law_id') ? true : false)}
            onChange={value => {
              setAuthLaw(value);
              setValue('auth_law_id', value);
              setChangeAuthLaw(!changeAuthLaw);
              value && setChangedFields({
                ...changedFields,
                name: changeAuthName,
                law_id: false
              });
            }}
          >
            <Controller
                name="law_id"
                control={control}
                render={({ field }) => {

                  return (
                    <div style={{display: 'flex',alignItems: 'center',gap: '5px', color: 'red'}}>
                      {
                        (changedFields.law_id && changeAuthLaw) && (
                          <Tooltip title="Campo alterado pelo proponente" placement="top">
                            <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                          </Tooltip>
                        )
                      }
                      {/* *Obrigatório */}
                      <Select
                        {...field}
                        className="select-project-name"
                        label="Lei de incentivo fiscal"
                        options={laws.map(law => law.description)}
                        // error={errors?.law_id?.message}
                        onChange={e => {
                          setValue('law_id', laws[e.target.value].id);
                          setSelectedLaw(laws[e.target.value].id);
                        }}
                        value={laws.findIndex(
                          item => item.id === getValues('law_id'),
                        )}
                        required={true}
                        margin = {20}
                        disabled={isDisabled}
                        role="admin"
                      />
                    </div>
                  )
                }}
              />
          </AdminFormGroup>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <div style={{display: 'flex',alignItems: 'center',gap: '5px', color: 'red'}}>
                {
                  changedFields?.code && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                <Input  
                  label="Código do projeto" 
                  fullWidth 
                  {...field} 
                  disabled={user.adminRole === 'general' || user.adminRole === 'monitore'}
                  value={field.value}
                  />
              </div>
            )}
          />
        </Row>
        {
          showRetentionPercentage && (
            <Row>
              <Controller
                name="retention_percentage"
                control={control}
                render={({ field }) => (
                  <Input  
                    label="% de Retenção" 
                    fullWidth 
                    {...field} 
                    value={field.value}
                    onChange={e => {
                        const value = onlyNumber(e.target.value);
                        setValue('retention_percentage', value);
                      }}
                    />
                )}
              />
          </Row>
          )
        }
        
      </Content>
      <Typography variant="subtitle2" color="grayLight1">
        Insira o tempo estimado para a execução do projeto.
      </Typography>
      <Content>
        {/* <AdminFormGroup
          checked={getValues('auth_runtime_days') ? true : false}
          onChange={value => {
            setAuthRuntimeDays(value);
            setValue('auth_runtime_days', value);
          }}
        > */}
          <Controller
            name="runtime_days"    
            control={control}
            render={({ field }) => (
              <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                {
                  changedFields?.runtime_days && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                {/* *Obrigatório */}
                <Input
                  label="Duração do projeto (em dias)"
                  {...field}
                  onChange={e => {
                    const value = onlyNumber(e.target.value);
                    setValue('runtime_days', value);
                  }}
                  disabled={isDisabled}
                  required={true}             
                  margin={85}
                />
              </div>
            )}
          />
        {/* </AdminFormGroup> */}
      </Content>
      <Content>
      {/* <AdminFormGroup
          checked={getValues('auth_location_ids') ? true : false}
          onChange={value => {
            setauthLocationIds(value);
            setValue("auth_location_ids", value);
          }}
        > */}
          <InfoTextContainer>
            <Typography variant="h6" color="grayDark2">
              Localização de realização do projeto
            </Typography>
            <Typography variant="subtitle2" color="grayLight1">
              Você pode selecionar mais de uma cidade, caso o projeto aconteça em mais de um lugar ou seja itinerante.
            </Typography>
            <Typography variant="body1" color="blueGrayish56" style={{ marginTop: 24 }}>
              O projeto acontece em mais de uma cidade ou é itinerante? Marque sim e indique os locais.
            </Typography>
            <Tooltip title="Campo alterado pelo proponente" placement="top">
                <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
            </Tooltip>
          </InfoTextContainer>
          <OptionsContent>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                {
                  changedFields?.more_than_one_city && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
            <Options>
              <Radio
                checked={getValues('more_than_one_city') === 1}
                onClick={() => {
                  setValue('more_than_one_city', 1);
                  setMoreThanOneCity(1);
                  setCitySelected(!citySelected);
                }}
                name="more_than_one_city"
                disabled={isDisabled}
              />
              <p>Sim</p>
            </Options>
            <Options>
              <Radio
                checked={getValues('more_than_one_city') === 0}
                onClick={() => {
                  setValue('more_than_one_city', 0);
                  setMoreThanOneCity(0);
                  setCitySelected(!citySelected);
                }}
                name="more_than_one_city"
                disabled={isDisabled}
              />
              <p>Não</p>
            </Options>
            </div>
          </OptionsContent>
          <CityList>
            {/* *Obrigatório */}
            <Select
              onChange={e => {
                const ufList = ufs.map(item => item.state_code).sort();
                handleSelectedUF(ufList[e.target.value])
              }}
              options={ufs.map(item => item.state_code).sort()}
              label="UF"
              role="admin"
              required={true}
              margin = {50}
              disabled={isDisabled}
              // value={selectedUF}
            />
            {/* {
              showCities && ( */}
                {/* *Obrigatório */}
                <Select
                  options={cities.map(item => item.name)}
                  onChange={e => handleSelectedCity(cities[e.target.value].name)}
                  label="Lista de cidades"
                  disabled={isDisabled}
                  required={true}
                  margin = {70}
                />
              {/* )
            }
            {selectedUF && selectedCity && ( */}
              {/* <Button
                borderColor={theme.colors.orange}
                variant="secondary"
                color={theme.colors.orange}
                onClick={handleCreateCity}
                disabled={isDisabled}
              >
                INCLUIR
              </Button> */}
            {/* )} */}
          </CityList>
          <ContentChip>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                  {
                    changedFields?.locations && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                      </Tooltip>
                    )
                  }
              {createChip.map(city => (
                <Chip
                  key={city}
                  label={city}
                  width="200px"
                  onClick={() => handleDeleteCity(city)}
                  disabled={isDisabled}
                />
              ))}
            </div>
          </ContentChip>
        {/* </AdminFormGroup> */}
        {getValues('more_than_one_city') === 1 && (
          <div>
          {/* *Obrigatório */}
            <ProjectLocalText>
              <Typography variant="body1" color="blueGrayish56">
                É possível alterar as cidades de realização do projeto de acordo com as indicações do patrocinador?
              </Typography>
            </ProjectLocalText>
            <OptionsContent>
              <Options>
                <Radio
                  rounded
                  color="primary"
                  checked={getValues('can_change_city') === 1}
                  onClick={() => {
                    setValue('can_change_city', 1)
                    setChangeCityProject(!changeCityProject)
                  }}
                  disabled={isDisabled}
                />
                <p>Sim</p>
              </Options>
              <Options>
                <Radio
                  rounded
                  color="primary"
                  checked={getValues('can_change_city') === 0}
                  onClick={() => {
                    setValue('can_change_city', 0)
                    setChangeCityProject(!changeCityProject)}
                  }
                  disabled={isDisabled}
                />
                <p>Não</p>
              </Options>
            </OptionsContent>
          </div>
        )}
      </Content>
    </Container>
  );
};

export default Step1;
