import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 0 0 25px;

  overflow-y: auto;
`;

export const Title = styled.div`
  margin-top: 25px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: ${({ theme }) => theme.colors.grayLight1};
`;

export const TextBody = styled.div`
  margin-top: 32px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.4px;

  color: ${({ theme }) => theme.colors.grayLight1};
`;

export const ContentInputs = styled.div`
  margin-top: 20px;
`;

export const ButtonAddCounterPart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  padding: 0 10px;
  height: 40px;

  background: rgba(106, 6, 164, 0.05);
  border-radius: 30px;
  border: 0;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: ${({ theme }) => theme.colors.primary}; 
    fill-opacity: 1;
  }
`;

export const ButtonAddText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-left: 10px;

  color: ${({ theme }) => theme.colors.primary};
`;

export const Footer = styled.div`
  width: 98%;
  margin-top: 31px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const SizeInputs = styled.div`
  margin-top: 2px;

  font-size: 12px;
  line-height: 10px;

  letter-spacing: 0.4px;

  color: rgba(18, 32, 63, 0.56);
`;

export const InputCounts = styled.div`
  display: flex;
  flex-direction: column;
  /*  margin-top: 21px; */
`;
export const Counts = styled.div`
  display: flex;
  width: 97.5%;
  justify-content: flex-end;
`;

export const ButtonDelete = styled.div`
  width: 36px;
  height: 36px;

  margin-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.primary};

  border-radius: 4px;

  cursor: pointer;
  path {
    fill: #fff;
  }

  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
`;