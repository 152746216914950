export const Admin = {
  colors: {
    background: '#1A2440',
    primary: '#C15028',
    secondary: '#C15028',
    light: '#9ca0fb',
    lighter: '#FAFAFA',
    darkGray: '#6B6767',
    darkestGray: '#504F4F',
    contrast: '#1A2440',
    green: '#56c656',

    pinkIce: '#f2ecec',
    pinkLighter: '#ffebeb',
    white: '#ffffff',
    redDarkest: '#b00020',
    orange: '#c15020',
    redLighter: '#de2d2d',
    brown: '#5c5050',
    blueGrayish60: ' rgba(18, 32, 63, 0.6)',
    blueGrayish56: 'rgba(18, 32, 63, 0.56)',
    purple: '#754ca4',
    blue: '#2196f3',
    blueAqua: '#46c4f6',
    gray: '#bcb4b4',
    grayDark1: '#757575',
    grayDark2: '#8d8d8d',
    grayLight1: '#a4a4a4',
    grayLight2: '#dadada',
    grayIce: '#fafafa',
    grayLight3: '#BFBDC8',
    tableItemBackground: '#fafafa',
    black: '#000000',
    blackGrayish60: 'rgba(0, 0, 0, 0.6)',
    blackGrayish35: 'rgba(0, 0, 0, 0.36)',
    blackGrayish20: 'rgba(0, 0, 0, 0.2)',
    blackGrayish10: 'rgba(0, 0, 0, 0.1)',
    grayIcon: '#616161',
    generalBackground: '#eeeeee',
  },

  gradient: ['#00FFFF', '#0B7878'],

  typographyNew: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '96px',
      fontWeight: 'bold',
      lineHeight: '125%',
    },
    h2: {
      fontSize: '60px',
      fontWeight: 'bold',
      lineHeight: '133%',
    },
    h3: {
      fontSize: '48px',
      fontWeight: 'bold',
      lineHeight: '133%',
    },
    h4: {
      fontSize: '34px',
      fontWeight: 'bold',
      lineHeight: '130%',
    },
    h5: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '132%',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '138%',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '150%',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '142%',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '150%',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '142%',
    },
    manrope: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: '120%',
    },
    overline: {
      fontSize: '10px',
      fontWeight: 'normal',
      lineHeight: '158%',
    },
  },

  typography: {
    fontFamily: 'Cabin',
    h1: {
      fontSize: '64px',
      fontWeight: 700,
      lineHeight: '64px',
      margin: 0,
    },

    h2: {
      fontSize: '25px',
      fontWeight: 700,
      lineHeight: '25px',
      margin: 0,
    },

    h3: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '18px',
      margin: 0,
    },

    h4: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '18px',
      margin: 0,
    },

    title: {
      fontSize: '50px',
      fontWeight: 700,
      lineHeight: '64px',
      margin: 0,
    },

    titleModal: {
      fontSize: '36px',
      fontWeight: 400,
      lineHeight: '42px',
      margin: 0,
    },

    span: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '16px',
      margin: 0,
    },

    span2: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '16px',
      margin: 0,
    },

    p1: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '12px',
      margin: 0,
    },

    p2: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '14px',
      margin: 0,
    },

    p3: {
      fontSize: '10px',
      fontWeight: 700,
      lineHeight: '10px',
      margin: 0,
    },

    p4: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
      margin: 0,
    },

    p5: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '14px',
      margin: 0,
    },
  },
};

export const ProponentTheme = {
  colors: {
    background: '#12203F',
    primary: '#6A06A4',
    secondary: '#754CA4',
    light: '#9ca0fb',
    blue: '#2196f3',
    lighter: '#FAF5FE',
    darkGray: '#6B6767',
    white: '#FFFFFF',
    contrast: '#FFFFFF',
    brown: '#5c5050',
    grayIce: '#fafafa',
    tableItemBackground: '#FAF5FE',
    black: '#000000',
    grayLight2: '#dadada',
    grayLight3: '#BFBDC8',
    grayIcon: '#616161',
    grayDark2: '#8d8d8d',
    grayLight1: '#a4a4a4',
    blueAqua: '#46c4f6',

    fieldTextDefaultBorder: 'rgba(18, 32, 63, 0.6)',
    fieldTextActiveBorder: '#754ca4',
    fieldTextActiveTitle: '#754ca4',
    fieldTextBorderFilled: 'rgba(0, 0, 0, 0.2)',
    fieldTextTitleFilled: 'rgba(18, 32, 63, 0.56)',
    fieldTextTextFilled: '#5c5050',
    fieldTextErrorBorder: '#de2d2d',
    fieldTextErrorTitle: '#b00020',
    fieldTextErrorMessage: '#b00020',

    buttonActiveArea: '#6a06a4',
    buttonActiveAction: '#ffffff',
    buttonDisabledArea: 'rgba(0, 0, 0, 0.1)',
    buttonDisabledActionn: 'rgba(18, 32, 63, 0.26)',
    blueGrayish56: 'rgba(18, 32, 63, 0.56)',

    radioAreaPrimary: '#6a06a4',
    radioAreaSecondary: 'rgba(0, 0, 0, 0.36)',
    radioText: 'rgba(0, 0, 0, 0.87)',
    radioDisabled: 'rgba(0, 0, 0, 0.36)',
    
    purple: '#754ca4',
    grayDark1: '#757575',

    textH1: '#000000',
    textH2: '#000000',
    textH3: '#6a06a4',
    textH4: '#000000',
    textH5: '#000000',
    textH6: '#8d8d8d',
    textSubtitle1: '#000000',
    textSubtitle2: '#bcb4b4',
    textBody1: '#000000',
    textBody2: '#000000',
    textCaption: '#000000',
    textOverline: '#000000',

    generalBackground: '#eeeeee',
    generalActiveSection: '#051d35',
    generalSection: '#757575',

    stepActiveArea: '#6a06a4',
    stepActiveText: '#000000',
    stepDisabledArea: '#9e9e9e',
    stepDisabledText: '#9e9e9e',
    stepNumber: '#ffffff',
  },

  gradient: ['#8700E3', '#550B78'],

  typographyNew: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '96px',
      fontWeight: 'bold',
      lineHeight: '125%',
    },
    h2: {
      fontSize: '60px',
      fontWeight: 'bold',
      lineHeight: '133%',
    },
    h3: {
      fontSize: '48px',
      fontWeight: 'bold',
      lineHeight: '133%',
    },
    h4: {
      fontSize: '34px',
      fontWeight: 'bold',
      lineHeight: '130%',
    },
    h5: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '132%',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '138%',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '150%',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '142%',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '150%',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '142%',
    },
    manrope: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: '120%',
    },
    overline: {
      fontSize: '10px',
      fontWeight: 'normal',
      lineHeight: '158%',
    },
  },

  typography: {
    fontFamily: 'Cabin',
    h1: {
      fontSize: '64px',
      fontWeight: 700,
      lineHeight: '64px',
      margin: 0,
    },

    h2: {
      fontSize: '25px',
      fontWeight: 700,
      lineHeight: '25px',
      margin: 0,
    },

    h3: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '18px',
      margin: 0,
    },

    h4: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '18px',
      margin: 0,
    },

    title: {
      fontSize: '50px',
      fontWeight: 700,
      lineHeight: '64px',
      margin: 0,
    },

    span: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '16px',
      margin: 0,
    },

    span2: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '16px',
      margin: 0,
    },

    p1: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '12px',
      margin: 0,
    },

    p2: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '14px',
      margin: 0,
    },

    p3: {
      fontSize: '10px',
      fontWeight: 700,
      lineHeight: '10px',
      margin: 0,
    },

    p4: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
      margin: 0,
    },

    p5: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '14px',
      margin: 0,
    },
  },
};
