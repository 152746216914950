import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 50px 0 24px;

  margin-bottom: 130px;
`;

export const OptionsContent = styled.div`
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 19px;

    color: ${({ theme }) => theme.colors.blackGrayish60};

    margin-left: 10px;
  }
`;

export const ProjectText = styled.div`
  /* margin-top: 40px; */

  font-size: 14px;
  line-height: 16px;

  color: #5c5050;
`;

export const ContentChip = styled.div`
  margin: 24px 0px 21px;
  display: inherit;
  gap: 20px;
  flex-wrap: nowrap;
  width: 95%;
  max-height: 300px;
  overflow: hidden auto;

  div{
    width: 47%;
    margin: 5px;
    justify-content: space-between;
  }

`;

export const ContentDate = styled.div`
    width: 18%;

    div{
      width: 100%;
    }
`;

export const ContentInputs = styled.div`
  margin-top: 26px;
  display: flex;
  gap: 24px;
  
  @media (max-width: 1590px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div {
      width: 100%!important;
    }

  }

  @media (max-width: 1025px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    div {
      width: 100%!important;
    }

  }

  

  // div:first-child > div{
  //   width: 100%!important;
  // }
`;

export const AddButton = styled.div`
  width: 40px;
  height: 46px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grayLight1};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 20px;

  font-size: 20px;
  color: ${({ theme }) => theme.colors.grayDark2};

  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
`;

export const RowCapture = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  gap: 1.5rem;
`;