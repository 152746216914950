import React from 'react';
import { useLocation } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import SideMenu from '../SideMenu';

import { Content, ContentPrimaryRoute, App, Container } from './styles';

const Layout = ({ children, options }) => {
  const location = useLocation();
  const paths = ['/registro', '/completar-cadastro', '/politica-de-privacidade', '/termos-e-condicoes-de-uso'];

  return (
    <>
      <div style={{"backgroundColor": "#F8E45E", "padding": "10px", "borderRadius": "10px", "textAlign": "center", "color": "#D19300", "position": "fixed", "zIndex": "999", "width": "100%"}}>
        <span>Este é um ambiente apenas de consulta. Os botões de salvamento foram removidos. Para acessar a nova plataforma, <a href='https://app.incentiv.live'>clique aqui</a></span>
      </div>
      <Container>
        {location.pathname === '/' || paths.includes(location.pathname) || location.pathname.includes('/recover-password') || location.pathname.includes('/confirmar-email') ? (
          <ContentPrimaryRoute>{children}</ContentPrimaryRoute>
        ) : ( 
          <App>
            <Hidden smDown>
              <SideMenu options={options} />
            </Hidden>
            <Content>
              {children}
            </Content>
          </App>

        )}
      </Container>
    </>
  );
};

export default Layout;
