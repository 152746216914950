import styled from 'styled-components';

export const Controller = styled.div`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  flex-direction: column;

  .MuiLinearProgress-root {
    width: 96%;
    align-self: center;
    height: 2px;
  }
`;

export const Container = styled.div`
  height: 47px;
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  border: 1px solid ${({ theme, error }) => error ? '#ef5350' : theme.colors.grayLight1};
  border-radius: 10px;

  input {
    display: none;
  }

  .open-file {
    cursor: pointer;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 35px;
  padding: 0 1rem;
  border: none;
  outline: none;
  background: #E0E0E0;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;

  &:disabled {
    cursor: not-allowed;
  }
`;