import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker as MaterialDatePicker,
} from 'material-ui-pickers';
import { ptBR } from 'date-fns/locale';
import { ThemeProvider } from '@material-ui/styles';
import { useUser } from 'hooks/useUser';
import { InputLabel } from '@material-ui/core';

const DatePicker = ({ label, format = 'dd/MM/yyyy', onChange, rounded, defaultValue, disabled, height, required = false, margin=0, error, isAportContrib }) => {
  let formattedDefaultValue = null;
  if(defaultValue){
    formattedDefaultValue = new Date(defaultValue);
    if(!isAportContrib)
      formattedDefaultValue.setDate(formattedDefaultValue.getDate() + 1);
    else
      formattedDefaultValue.setDate(formattedDefaultValue.getDate());

  }

  const [date, setDate] = useState(formattedDefaultValue || null);
  const { user } = useUser();

  const materialTheme = {
    overrides: {
      MuiInputBase: {
        root: {
          borderRadius: rounded ? '25px !important' : '10px !important',
          height: height ?? '3em',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: user.isAdmin ? '#C15028' : '#6A06A4',
        },
      },
    },
  };

  return (   

    <ThemeProvider theme={materialTheme}>
      {(required && (date?.length <= 0 || date == 0 || date == '' || date == null)) &&
      <InputLabel 
        style={{color: 'red', 
                marginLeft: margin+'%', 
                backgroundColor: '#fff',
                position: 'absolute',
                marginTop: '-6px',
                zIndex: 9,
                fontSize: '12px',
              }} 
        id="select-label">Obrigatório</InputLabel>
      }

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <MaterialDatePicker
          variant="outlined"
          label={label}
          value={date}
          onChange={value => {
            setDate(value);
            onChange(value);
          }}
          format={format}
          lang="pt"
          disabled={disabled}
          style={{width: "100%"}}
          error={error}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
