import React, { useMemo, useCallback } from 'react';
import Input from 'components/Input';
import Select from 'components/Select';
import Typography from 'components/Typography';
import { BorderBottom } from '../styles';
import { Container, GridInputs, SelectPerson } from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import { useState } from 'react';
import { cpfMask, cnpjMask, phoneMask, onlyNumber } from 'utils/maskUtils';
import { useSelector } from 'react-redux';
import { getColors } from '../styles';
import { CndService } from 'services/cnd';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import './style.css';

const Step2 = ({ personTypeChange }) => {
  const registerValue = useSelector(state => state.register.profile);
  const checkProponent = registerValue.profile.type === 'proponente';
  const theme = useTheme();
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const [personTypeSelected, setPersonTypeSelected] = useState();
  const personTypes = useMemo(() => {
    return checkProponent
      ? [
          'Pessoa física',
          'Entidade Empresarial',
          'Entidade sem fins lucrativos',
        ]
      : ['Pessoa física', 'Pessoa jurídica'];
  }, [checkProponent]);

  const checkCnd = useCallback(async (value, type) => {
    if((type === 'cpf' && value.length != 11) || (type === 'cnpj' && value.length != 14)) {
      return ;
    }

    try {
      const payload = {
        cpf: type === 'cpf' ? value : null,
        cnpj: type === 'cnpj' ? value : null,
        legal_person: personTypes[personTypeSelected]
      }

      const response = await CndService.checkCND(payload);
      // toast.success('Consulta realizada com sucesso');
    } catch (err) {
      console.log('err', err)
      // toast.error('Falha ao consultar CND');
    }
  }, [personTypeSelected]);

  return (
    <Container>
      <Typography variant="h6" color="grayDark2">
        Dados cadastrais do {checkProponent ? `Proponente` : `Patrocinador`}
      </Typography>
      <div style={{ marginTop: 10 }}>
        <Typography
          variant="subtitle2"
          color="grayLight1"
          style={{ textAlign: 'justify' }}
        >
          {checkProponent
            ? `
          Selecione o tipo de identificação do seu perfil como proponente:
          pessoa física ou jurídica. Os campos preenchidos serão utilizados na
          formalização da parceria com a Incentiv.me e na efetivação do
          incentivo.
          `
            : `Selecione o tipo de identificação do seu perfil como patrocinador: pessoa física ou jurídica.`}
        </Typography>
      </div>
      <div style={{ marginTop: 15, width: '100%' }}>
        <SelectPerson>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            name="identification"
            options={personTypes}
            width="100%"
            label="Pessoa Física ou Jurídica"
            error={errors?.legal_person?.message}
            value={personTypes[personTypeSelected]}
            onChange={e => {
              setValue('legal_person', personTypes[e.target.value]);
              setPersonTypeSelected(e.target.value);
              personTypeChange();
            }}
            required={true}
            margin={90}
            color={getColors(registerValue.profile.type).primary}
            role="proponente"
          />
        </SelectPerson>
      </div>
      {personTypeSelected >= 0 ? (
        <>
          <div style={{ marginTop: 30, width: '100%' }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  label="Nome do perfil"
                  error={errors?.name?.message}
                  // color={getColors(registerValue.profile.type).primary}
                  {...field}
                  data-tip="Insira aqui o título do estabelecimento <br>(nome fantasia) conforme cartão CNPJ." 
                  data-html={true}
                  data-for="tooltip1"
                  required={true}
                  margin={90}
                  data-event='click focus'
                />
              )}
            />
            <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

          </div>
          <GridInputs>
            {personTypeSelected === 0 ? (
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => {
                  if (field.value) {
                    field.value = cpfMask(field.value);
                  }
                  return (
                    <Input
                      label="CPF"
                      error={errors?.cpf?.message}
                      {...field}
                      value={field.value}
                      required={true}
                      margin={80}
                      onChange={e => {
                        const value = cpfMask(e.target.value);
                        setValue('cpf', value);
                        checkCnd(onlyNumber(value), 'cpf');
                      }}
                    />
                  );
                }}
              />
            ) : (
              <Controller
                name="cnpj"
                control={control}
                render={({ field }) => {
                  if (field.value) {
                    field.value = cnpjMask(field.value);
                  }
                  return (
                    <Input
                      label="CNPJ"
                      error={errors?.cnpj?.message}
                      {...field}
                      // value={field.value}
                      required={true}
                      margin={80}
                      onChange={e => {
                        const value = cnpjMask(e.target.value);
                        setValue('cnpj', value);
                        checkCnd(onlyNumber(value), 'cnpj');
                      }}
                    />
                  );
                }}
              />
            )}
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  label="Celular"
                  error={errors?.phone?.message}
                  {...field}
                  value={field.value}
                  required={true}
                  margin={80}
                  onChange={e => {
                    const value = phoneMask(e.target.value);
                    setValue('phone', value);
                  }}
                />
              )}
            />
          </GridInputs>
          <div style={{ marginTop: 20 }}>
            <BorderBottom />
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default Step2;
