import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0 0rem;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 1fr 0.35fr 0.35fr;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 4px;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr 0.5fr 1fr 0.5fr 0.5fr;
  }

  .MuiOutlinedInput-root{
    height: auto;
    min-height: 56px;
    max-height: 94px;
    position: initial;
    overflow-y: auto;    
  }

  .MuiFormControl-root{
    padding-right: 4px;
    height: 55px;
  }
`;
