import api from './api';

const PATH = '/cnd';

const checkCND = (params = {}) => api.get(`${PATH}/check`, { params: { ...params }});
const validateExpiredCNDs = () => api.get(`${PATH}/validate-expired-cnds`);
  
export const CndService = {
  checkCND,
  validateExpiredCNDs
};
