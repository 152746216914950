import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectUi,
  FormHelperText,
} from '@material-ui/core';
import useStyles from './materialStyles';
import './StyleSelect.css';
import { v4 } from 'uuid';
import { LabelInput } from './styles';

const Select = ({
  label,
  value,
  options,
  multipleOptions,
  darkBackground,
  onChange,
  role,
  required = false,
  margin = 0,
  width,
  error,
  color,
  noValueText = 'Sem dados',
  rounded = false,
  ...rest
}) => {
  const classes = useStyles({ color: color, rounded: rounded });
  return (
    <FormControl
      classes={{ root: classes.customOutline }}
      variant="outlined"
      error={!!error}
      rounded={rounded}
    >
      <InputLabel id="select-label">{label}</InputLabel>

      {!!(required && (value == -1 || value == '' || value == null)) &&
        <LabelInput style={{color: 'red', marginLeft: margin+'%', backgroundColor: '#fff', position: 'absolute', top: -6, fontSize: '12px', zIndex: 99}} id="select-label">Obrigatório</LabelInput>
      }

      <SelectUi
        {...rest}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value}
        onChange={onChange}
        style={{ height: 48, width }}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={v4()}
              value={index}
              style={{ minWidth: 400, width: '100%' }}
              className={role}
            >
              <p style={{ display: 'inline' }}>{option}</p>
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={v4()}
            value={noValueText}
            className={role}
            style={{ minWidth: 400, width: '100%' }}
            disabled
          >
            <em>{noValueText}</em>
          </MenuItem>
        )}
      </SelectUi>
      {!!error && typeof error !== "boolean" && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
