import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import Table from 'components/Table';
import Typography from 'components/Typography';
import defaultProjectAvatar from 'assets/images/default-project.png';
import formatCurrency from 'utils/currency';
import ProjectsTableDetail from './ProjectsTableDetail';

import { ProjectItem, ProgressBar, FundraisingItem, ButtonLink } from './styles';
import { formatDate } from 'utils/date';
import { WarningOutlined } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';

const ProjectsTable = ({ data, identification, loading }) => {
  const { push } = useHistory();

  const calculateProgress = useCallback((maxValue, currentValue) => {
    const progress = Number((Number(currentValue) * 100) / maxValue).toFixed(2);
    return Number.isNaN(Number(progress)) ? 0 : Number(progress);
  }, []);

  const formatDateColumn = useCallback((startsAt, fundingPeriod) => {
    if (startsAt === '' || fundingPeriod === '' || startsAt == null || fundingPeriod == null) {
      return 'Data não informada';
    }
    var newDate = new Date(new Date(fundingPeriod).setDate(new Date(fundingPeriod).getDate() + 1));
    var newDate2 = new Date(new Date(startsAt).setDate(new Date(startsAt).getDate() + 1));

    return `${formatDate(newDate2, 'dd/MM/yyyy')} a ${formatDate(
      newDate,
      'dd/MM/yyyy',
    )}`;
  }, []);

  const formatFundingPeriod = useCallback((fundingPeriod) => {
    if (fundingPeriod === '' || fundingPeriod == null) {
      return 'Data não informada';
    }
    var newDate = new Date(new Date(fundingPeriod).setDate(new Date(fundingPeriod).getDate() + 1));

    return formatDate(newDate, 'dd/MM/yyyy');
  }, []);

  const columns = useMemo(() => {
    return [
      {
        identification: 'name',
        name: '',
        renderColumn: ({ category, name, image }) => {
          const cover = image ? image : defaultProjectAvatar;

          return (
            <ProjectItem>
              <img src={cover} alt="Projeto" />
              <div>
                <Typography variant="manrope" color="brown">
                  {name}
                </Typography>
                <Typography variant="caption">{category || ''}</Typography>
              </div>
            </ProjectItem>
          );
        },
      },
      {
        identification: 'id',
        name: 'status',
        renderColumn: ({ capturedValue, approvedValue, startsAt, fundingPeriod, status }) => {
          const timeElapsed = Date.now();
          const today = new Date(timeElapsed);
        
          const prazoCaptação = (new Date(fundingPeriod)).getTime();
          const isPrazoCaptação = formatFundingPeriod(fundingPeriod);
        
          const hoje = today.getTime();
          return (
            <FundraisingItem>
              <div>
                <Typography variant="manrope" color="grayDark2">
                    Fim da Captação
                </Typography>

                <Typography variant="caption">
                  {formatFundingPeriod(fundingPeriod)}
                  {isPrazoCaptação !== 'Data não informada' &&
                      prazoCaptação < hoje && 
                        (status === "Em captação" || status === "Aguardando regularização") &&
                      <>
                        <WarningOutlined 
                          style={{fontSize: '1.2rem', color: 'red'}}
                          data-tip="O prazo de captação expirou." 
                          data-html={true}
                          data-for="tooltip1"
                        />
                        

                          <ReactTooltip 
                            id="tooltip1" 
                            border={true} 
                            borderColor='red' 
                            backgroundColor="#fff" 
                            textColor='red' 
                            place="top" 
                            type="info" 
                            effect="solid"
                          />
                      </>
                  }
                  
                </Typography>
              </div>
              
              <ProgressBar
                progress={calculateProgress(
                  Number(approvedValue),
                  Number(capturedValue),
                )}
              />
              <div>
                <Typography variant="caption">
                  {calculateProgress(
                    Number(approvedValue),
                    Number(capturedValue),
                  )}
                  %
                </Typography>
                <Typography variant="caption">
                  Falta captar {formatCurrency(Number(approvedValue) - Number(capturedValue), true, true)}
                </Typography>
              </div>
            </FundraisingItem>
          );
        },
      },
      {
        identification: 'status',
        name: 'Status',
        showTitle: true,
      },
      {
        identification: 'id',
        name: 'Área do proponente',
        showTitle: true,
        isDetail: true,
        renderColumn: ({ id }) => {
          return (
            <div style={{width: '75%'}}>
              <Typography variant="manrope" color="grayDark2">
                <ButtonLink>
                  <a href={`projetos/${id}`} target="_blank" rel="noreferrer">Área do Proponente</a>
                </ButtonLink>
              </Typography>
            </div>
          );
        }
      },
    ];
  }, [calculateProgress, push, formatDateColumn]);

  return (
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={data}
      tableCollapsed
      collapsedComponent={ProjectsTableDetail}
      isPaginated={false}
      loading={loading}
    />
  );
};

export default ProjectsTable;
