import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.5rem;
  background-color: #fff;

  border-radius: 30px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const ContentButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 24px;
`;

export const FooterBack = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ContentBack = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 22px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
`;
