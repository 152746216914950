import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .description {
    margin-top: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.5rem;
  gap: 1rem;
`;

export const SizeInputs = styled.div`
  margin-top: 2px;

  font-size: 12px;
  line-height: 10px;

  letter-spacing: 0.4px;

  color: rgba(18, 32, 63, 0.56);
`;

export const InputCounts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;

`;
export const Counts = styled.div`
  display: flex;
  width: 85%;
  justify-content: flex-end;
`;

export const ButtonDelete = styled.div`
  width: 36px;
  height: 36px;

  margin-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.orange};

  border-radius: 4px;

  cursor: pointer;
  path {
    fill: #fff;
  }

  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
`;

export const Footer = styled.div`
  width: 98%;
  margin-top: 31px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ButtonAddCounterPart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  padding: 0 10px;
  height: 40px;

  background: rgba(188, 10, 10, 0.05);
  border-radius: 30px;
  border: 0;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: #C15028;
    fill-opacity: 1;
  }
`;

export const ButtonAddText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-left: 10px;

  color: ${({ theme }) => theme.colors.orange};
`;