import React, { useCallback, useEffect, useState } from 'react';
import { SwipeableDrawer, CircularProgress } from '@material-ui/core';
import {
  Container,
  TitleContainer,
  Form,
  FileContainer,
  ActionsContainer,
  ProgressContainer,
  ContainerInputs,
} from './styles';
import Typography from 'components/Typography';
import Select from 'components/Select';
import Input from 'components/Input';
import FileInput from 'components/FileInput';
import Button from 'components/Button';
import { useForm, Controller } from 'react-hook-form';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';
import { NotificationService } from 'services/notifications';
import { notificationValidationResolver } from 'validations/notification';
import NotificationCreatedModal from 'components/NotificationCreatedModal';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { useUser } from 'hooks/useUser';

const NotificationForm = ({
  open,
  onClose,
  receiverId,
  projectId,
  onAfterSave,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: notificationValidationResolver,
  });
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const loadFormInfo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ProjectService.listProjects();
      setProjects(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSubmit = useCallback(async data => {
    try {
      setLoading(true);
      const { title, body, projectId, annex } = data;

      const payload = new FormData();
      payload.append('title', title);
      payload.append('body', body);
      payload.append('project_id', projectId);

      if (annex) {
        payload.append('annex', annex, annex?.name);
      }

      await NotificationService.create(payload);
      setOpenModal(true);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha no servidor');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSelect = useCallback(
    (index, key, list) => {
      const { id } = list[index] || {};
      if (id) {
        setValue(key, id);
      }
    },
    [setValue],
  );

  const handleFile = useCallback(
    files => {
      if (files) {
        setValue('annex', files[0]);
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (open) {
      clearErrors();
      loadFormInfo();
    }
  }, [open, loadFormInfo, clearErrors]);

  useEffect(() => {
    if (open && receiverId && projectId) {
      setValue('receiverId', receiverId);
      setValue('projectId', projectId);
    } else {
      reset();
    }
  }, [open, receiverId, projectId, setValue, reset]);

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={onClose}>
      <Container>
        <TitleContainer>
          <Typography variant="subtitle1">Enviar mensagem</Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Envie uma mensagem para o {user.isAdmin ? 'proponente' : 'nosso time'}.

          </Typography>
        </TitleContainer>
        {loading ? (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInputs>
              <SelectAutocomplete
                options={projects.map(project => project.name)}
                label="Nome do Projeto"
                value={
                  projectId
                    ? projects.findIndex(project => project.id === projectId)
                    : undefined
                }
                onChange={(event, value) => {
                  const index = projects.findIndex(p => p.name === value)
                  handleSelect(index, 'projectId', projects)
                }}
                error={errors?.projectId?.message}
                multiline={false}
                disableCloseOnSelect={false}
              />
            </ContainerInputs>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <Input
                  label="Assunto"
                  {...field}
                  error={errors?.title?.message}
                />
              )}
            />
            <Controller
              name="body"
              control={control}
              render={({ field }) => (
                <Input
                  label="Mensagem"
                  multiline
                  rows={7}
                  error={errors?.body?.message}
                  {...field}
                />
              )}
            />
            <FileContainer>
              <Typography variant="caption">Anexar</Typography>
              <FileInput
                onChange={files => handleFile(files)}
                error={errors?.annex?.message}
              />
            </FileContainer>
            <ActionsContainer>
              <Button variant="secondary" onClick={() => onClose()}>
                CANCELAR
              </Button>
              <Button type="submit">ENVIAR</Button>
            </ActionsContainer>
          </Form>
        )}
      </Container>

      <NotificationCreatedModal
        open={openModal}
        handleClose={() => {
          setOpenModal(!openModal);
          onClose();
          onAfterSave();
        }}
      />
    </SwipeableDrawer>
  );
};

export default NotificationForm;
