import React, { useCallback } from 'react';
import { Container } from './styles';

function Button({
  children,
  variant = 'primary',
  size = 'medium',
  color = 'primary',
  borderColor = '',
  className = '',
  ...rest
}) {
  const renderButtonType = useCallback(() => {
    switch (variant) {
      case 'secondary':
        return 'outlined';
      case 'tertiary':
        return 'text';
      case 'primary':
      default:
        return 'contained';
    }
  }, [variant]);

  return (
    <Container
      className={className}
      variant={renderButtonType()}
      color="primary"
      style={{
        color: variant === 'primary' ? '#fff' : color,
        borderColor,
        // display: 'none'
      }}
      buttonsize={size}
      {...rest}
    >
      {children}
    </Container>
  );
}

export default Button;
