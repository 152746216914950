import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 312px;
  margin-bottom: 0.75rem;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
