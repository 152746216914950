import styled from 'styled-components';

export const ProjectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
`;