import React, { useCallback, useEffect, useState } from 'react';
import { SwipeableDrawer, CircularProgress } from '@material-ui/core';
import {
  Container,
  TitleContainer,
  Form,
  FileContainer,
  ActionsContainer,
  ProgressContainer,
  ContainerInputs,
} from './styles';
import Typography from 'components/Typography';
import Select from 'components/Select';
import Input from 'components/Input';
import FileInput from 'components/FileInput';
import Button from 'components/Button';
import { useForm, Controller } from 'react-hook-form';
import { ProponentService } from 'services/proponent';
import { ProjectService } from 'services/projects';
import { UsersService } from 'services/users';
import { toast } from 'react-toastify';
import { NotificationService } from 'services/notifications';
import { notificationAdminValidationResolver } from 'validations/notification';
import NotificationCreatedModal from 'components/NotificationCreatedModal';
import SelectAutocomplete from 'components/SelectAutocomplete';

const NotificationFormAdmin = ({
  open,
  onClose,
  receiverId,
  projectId,
  onAfterSave,
  proponentName,
  proponentId,
  adminId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();
  const [proponents, setProponents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [proponentSelected, setProponentSelected] = useState();
  const [proponentProjects, setProponentProjects] = useState([]);

  const loadFormInfo = useCallback(async () => {
    try {
      setLoading(true);
      const [resProponent, resProjects, resAdminUsers, resSubjects] = await Promise.all([
        ProponentService.listProponents(),
        ProjectService.listProjects(),
        UsersService.listAdminUsers(),
        NotificationService.listSubjects(),
      ]);
      setProponents(resProponent.data);
      setProjects(resProjects.data);
      setAdminUsers(resAdminUsers.data);
      setProponentSelected(proponentId);
      setSubjects(resSubjects.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSubmit = useCallback(async data => {
    try {
      const { title, body, projectId, receiverId, senderId, annex, subjectId } = data;
      const payload = new FormData();
      // payload.append('title', title);
      payload.append('body', body);
      payload.append('senderId', senderId);
      payload.append('subject_id', subjectId);
      payload.append('project_id', projectId);
      payload.append('proponent_id', receiverId);

      if (annex) {
        payload.append('annex', annex, annex?.name);
      }

      await NotificationService.create(payload);
      setOpenModal(true);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha no servidor');
      }
    }
  }, []);

  const handleSelect = useCallback(
    (index, key, list) => {
      const { id } = list[index] || {};
      if (id) {
        setValue(key, id);
      }
    },
    [setValue],
  );

  const handleFile = useCallback(
    files => {
      if (files) {
        setValue('annex', files[0]);
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (open) {
      clearErrors();
      loadFormInfo();
    }
  }, [open, loadFormInfo, clearErrors]);

  useEffect(() => {
    if (open && receiverId && projectId) {
      setValue('receiverId', receiverId);
      setValue('projectId', projectId);
    } else {
      reset();
    }
    if(proponentSelected){
      setValue('receiverId', proponentSelected);
    }
    if(adminId){
      setValue('senderId', adminId);
    }
    if(projectId){
      setValue('projectId', projectId);
    }
    setProponentProjects(projects.filter(project => project.proponentId === proponentSelected))
  }, [open, receiverId, projectId, setValue, reset, proponentSelected, proponentId, adminId]);

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={onClose}>
      <Container>
        <TitleContainer>
          <Typography variant="subtitle1">Enviar mensagem</Typography>
          <Typography variant="subtitle2" color="grayLight1">
            Envie uma mensagem para o proponente.
          </Typography>
        </TitleContainer>
        {loading ? (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInputs>
              <SelectAutocomplete
                options={adminUsers.map(proponent => proponent.name)}
                label="Remetente"
                onChange={(event, value) => {
                  const index = adminUsers.findIndex(p => p.name === value)
                  handleSelect(index, 'senderId', adminUsers)
                }}
                value={
                  adminId
                  ? adminUsers.findIndex(user => user.id === adminId)
                  : getValues('senderId')
                }
                error={errors?.senderId?.message}
                multiline={false}
                disableCloseOnSelect={false}
              />
              <SelectAutocomplete
                options={proponents.map(proponent => proponent.name)}
                label="Proponente"
                onChange={(event, value) => {
                  const index = proponents.findIndex(p => p.name === value)
                  handleSelect(index, 'receiverId', proponents)
                  if(index >= 0) setProponentSelected(proponents[index].id);
                }}
                value={
                  proponentName
                  ? proponents.findIndex(proponent => proponent.name === proponentName)
                  : getValues('receiverId')
                }
                error={errors?.receiverId?.message}
                multiline={false}
                disableCloseOnSelect={false}
              />
              <SelectAutocomplete
                options={projects
                  .filter(project => project.proponentId === proponentSelected)
                  ?.map(proj => proj.name)}
                label="Nome do Projeto"
                onChange={(event, value) => {
                  const index = projects.filter(project => project.proponentId === proponentSelected).findIndex(p => p.name === value)
                  handleSelect(index, 'projectId', projects.filter(project => project.proponentId === proponentSelected))
                }}
                value={
                  projectId
                    ? proponentProjects.findIndex(project => project.id === projectId)
                    : getValues('projectId')
                }
                error={errors?.projectId?.message}
                multiline={false}
                disableCloseOnSelect={false}
              />
            </ContainerInputs>
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <Select
                  options={subjects.map(subject => subject.name)}
                  label="Assunto"
                  onChange={e =>
                    handleSelect(e.target.value, 'subjectId', subjects)
                  }
                  role="admin"
                />
              )}
            />
            <Controller
              name="body"
              control={control}
              render={({ field }) => (
                <Input
                  label="Mensagem"
                  multiline
                  rows={7}
                  error={errors?.body?.message}
                  {...field}
                />
              )}
            />
            <FileContainer>
              <Typography variant="caption">Anexar</Typography>
              <FileInput
                onChange={files => handleFile(files)}
                error={errors?.annex?.message}
              />
            </FileContainer>
            <ActionsContainer>
              <Button variant="secondary" onClick={() => onClose()}>
                CANCELAR
              </Button>
              <Button type="submit">ENVIAR</Button>
            </ActionsContainer>
          </Form>
        )}
      </Container>

      <NotificationCreatedModal
        open={openModal}
        handleClose={() => {
          setOpenModal(!openModal);
          onClose();
          onAfterSave();
        }}
      />
    </SwipeableDrawer>
  );
};

export default NotificationFormAdmin;
