import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop } from '@material-ui/core';
import { toast } from 'react-toastify';

import Button from 'components/Button';
import { toggleBidersModal } from 'store/ducks/modals/actions';
import { UsersService } from 'services/users';
import { userBiddersMock } from "../mock";
import * as S from './styles';
import TransferTable from './TransferTable';

const BidersModal = ({ refreshData }) => {
  const dispatch = useDispatch();
  const { open, deleteMiddleware } = useSelector(({ modals }) => modals.usersModal.bidersModal);
  const { users: currentUser } = useSelector(state => state.modals.usersModal);
  const [users, setUsers] = useState([]);
  const [userBidders, setUserBidders] = useState();
  const [transferringAll, setTransferringAll] = useState(false);
  const userTotal = userBidders ? Object.values(userBidders)?.map(({ bidders }) => bidders.length)?.reduce((x, y) => x + y, 0) : 0;

  const transferBidders = async () => {
    try {
      const receiver_id = Object.values(userBidders)?.map(({ user }) => user)[0]?.id;
      console.log("receiver_id", receiver_id);

      const payload = {
        user_sender_id: currentUser.id,
        user_receiver_id: receiver_id
      }

      const response = await UsersService.transferProponentsToKa(payload);

      toast.success('Proponentes transferidos com sucesso!');
    } catch(err) {
      console.log(err.message);
    }
  }

  const deleteUser = useCallback(async () => {
    if(!deleteMiddleware) return;

    try {
      await UsersService.deleteUser(currentUser.id);
      refreshData();
    } catch (err) {
      console.log(err.message);
    }
  }, [users, refreshData]);

  const getCount = (data) => ({
    toTransfer: Object.values(data).map(({ bidders }, idx) => (Object.keys(data)[idx] === "unset") ? 0 : bidders.length)?.reduce((x, y) => x + y, 0),
    total:  data ? Object.values(data)?.map(({ bidders }) => bidders.length)?.reduce((x, y) => x + y, 0) : 0
  });

  function handleTransferTableChange(transferBidders) {
    if(Object.keys(transferBidders).length !== 0) {
      setUserBidders(transferBidders);
  
      const { toTransfer, total } = getCount(transferBidders);
      setTransferringAll(toTransfer >= total);
    }
  }

  function handleClose() {
    dispatch(toggleBidersModal({}));
  };

  async function onAction() {
    await transferBidders();

    if(deleteMiddleware){
      await deleteUser();
    }

    handleClose();
  };

  useEffect(() => {
    async function fetchUsers() {
        if(JSON.stringify(currentUser) == "{}") return;

        const { data } = await UsersService.listKeyAccounts();

        setUsers(data.map(user => ({
            label: user.name,
            value: Object.assign({}, user)
        })));
    }

    async function fetchBidders() {
      const { data } = await UsersService.listProponentsByKa(currentUser.id);

      setUserBidders({
          unset: {
              type: "batch",
              bidders: data
          }
      });
    }

    fetchUsers();
    fetchBidders();
  }, [currentUser]);

  return (
    <S.Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      disableAutoFocus
    >
      <S.ModalContainer>
        <S.Title>
            Transferir Proponentes
        </S.Title>

        {deleteMiddleware && (
            <S.Subtitle>
                Este usuário possui {userTotal} proponentes relacionados.<br />
                Para <strong>excluir</strong> será necessário transferir os proponentes para outro usuário.
            </S.Subtitle>
        )}

        <TransferTable
            data={userBidders}
            onChange={handleTransferTableChange}
            users={users}
            currentUser={currentUser}
            initialOpen={!deleteMiddleware}
            partialAccepts={!deleteMiddleware} />

        <S.Footer>
          <Button variant="tertiary" onClick={handleClose}>
            Voltar
          </Button>

        {userBidders && 
          <Button
            variant="tertiary"
            onClick={onAction}
            disabled={deleteMiddleware ? (!transferringAll) : userBidders.unset}>
              {deleteMiddleware ? "Excluir" : "Transferir"}
          </Button>
        }
        </S.Footer>
      </S.ModalContainer>
    </S.Modal>
  );
};

export default BidersModal;
