import styled from 'styled-components';
import { pixelsToRem } from 'utils/styles';
import { rgba } from 'polished';

export const Container = styled.div`
  border-radius: ${pixelsToRem(30)};
  padding: 1.5rem;
  background: ${({ theme }) => rgba(theme.colors.grayLight3, 0.1)};
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  gap: 0.5rem;
`;

export const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;
`;
