import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Checkbox from 'components/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import Typography from 'components/Typography';
import { BorderBottom } from '../styles';
import { register } from 'store/ducks/register/action';
import {
  Container,
  Header,
  Main,
  Options,
  OptionsContent,
  SelectProfile,
  RegisterTitle,
  ImageContainer,
} from './styles';

import BemVindo from 'assets/images/bemvindo.svg';
import { getColors } from '../styles';

const Step1 = ({ showNextStep, setShowNextStep }) => {
  const [sponsor, setSponsor] = useState(false);
  const [proponent, setProponent] = useState(false);
  const [showImage, setShowImage] = useState(true);

  const registerValue = useSelector(state => state.register.profile);
  const dispatch = useDispatch();

  const handleCheckType = useCallback(
    type => {
      if (type === 'default') {
        setProponent(false);
        setSponsor(false);
      } else setShowImage(false);

      setShowNextStep(type);

      switch (type) {
        case 'patrocinador':
          if (sponsor) {
            setShowImage(true);
            setShowNextStep('default');
          }
          dispatch(
            register({
              profile: {
                ...registerValue.profile,
                type: type,
                isActive: !sponsor,
              },
            }),
          );
          setProponent(false);
          return setSponsor(!sponsor);

        case 'proponente':
          if (proponent) {
            setShowImage(true);
            setShowNextStep('default');
          }
          dispatch(
            register({
              profile: {
                ...registerValue.profile,
                type: type,
                isActive: !proponent,
              },
            }),
          );
          setSponsor(false);
          return setProponent(!proponent);

        default:
          setSponsor(false);
          setProponent(false);
          setShowNextStep('default');
          return setShowImage(true);
      }
    },
    [setShowNextStep, sponsor, dispatch, registerValue.profile, proponent],
  );

  return (
    <Container>
      <Header state={showNextStep}>
        <RegisterTitle color={showNextStep}>Cadastro</RegisterTitle>
        <Typography
          variant="subtitle2"
          color="grayLight1"
          style={{ marginTop: 10 }}
        >
          Vamos começar! Precisamos de algumas informações sobre você e sua
          instituição. Lembre-se de salvar seus dados ao final de cada etapa
          para que nada seja perdido.
        </Typography>
      </Header>

      <Main>
        <Typography variant="h6" color="grayDark2">
          Perfil
        </Typography>
        <div style={{ marginTop: 8 }}>
          <Typography variant="subtitle2" color="grayLight1">
            A nossa plataforma possui dois perfis de cadastro. O perfil de
            Patrocinador possibilita o patrocínio de projeto(s) existente(s) na
            plataforma e o de Proponente possibilita o cadastro de projetos que
            buscam financiamento.
          </Typography>
        </div>
      </Main>
      <SelectProfile>
        Selecione abaixo o perfil que você deseja cadastrar!
      </SelectProfile>
      <OptionsContent>
        <Options>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonCheckedIcon />}
            rounded
            checked={Boolean(
              registerValue.profile?.type === 'patrocinador' &&
                registerValue.profile?.isActive,
            )}
            style={{ padding: 0, color: getColors(showNextStep).primary }}
            onClick={() => handleCheckType('patrocinador')}
          />
          <p>Patrocinador</p>
        </Options>
        <Options>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonCheckedIcon />}
            rounded
            checked={
              registerValue.profile?.type === 'proponente' &&
              registerValue.profile?.isActive
            }
            style={{ padding: 0, color: getColors(showNextStep).primary }}
            onClick={() => handleCheckType('proponente')}
          />
          <p>Proponente</p>
        </Options>
      </OptionsContent>
      <BorderBottom />
      {showImage && (
        <ImageContainer>
          <img src={BemVindo} alt="boas vindas" />
        </ImageContainer>
      )}
    </Container>
  );
};

export default Step1;
