import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import DatePicker from 'react-datepicker';

export const ModalContainer = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 600px;
  width: 615px;
  font-family: ${props => props.theme.typography.fontFamily};
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  padding: 30px;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.background};
  height: 55px;
`;

export const ModalTitle = styled.h2`
  ${props => props.theme.typography.h2};
  color: ${props => props.theme.colors.background};
`;

export const Label = styled.span`
  ${props => props.theme.typography.span};
  color: ${props => props.theme.colors.background};
  margin: 10px 0px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CancelButton = styled.div`
  border-radius: 15px;
  align-content: center;
  cursor: pointer;
  padding: 10px;
  color: ${props => props.theme.colors.background};
  margin: 0px 15px;
`;

export const ConfirmButton = styled.div`
  border-radius: 15px;
  align-content: center;
  cursor: pointer;
  padding: 10px;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.white};
  margin: 0px 15px;
`;

export const ButtonLabel = styled.span`
  ${props => props.theme.typography.span2};
`;

export const DateRangePicker = styled(DatePicker)`
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.typography.fontFamily};
  height: 36px;
  padding: 10px;
`;

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
