import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'components/Select';
import { Container } from './styles';
import { ProponentService } from 'services/proponent';
import { ProjectService } from 'services/projects';
import DatePicker from 'components/DatePicker';

const NotificationsFilter = ({ setFilters, filters }) => {
  // const [date, setDate] = useState();
  const [projects, setProjects] = useState([]);
  const [proponents, setProponents] = useState([]);
  const optionsStatus = useMemo(() => ['Lido', 'Não lido'], []);

  const loadInfo = useCallback(async () => {
    try {
      const [resProponents, resProjects] = await Promise.all([
        ProponentService.listProponents(),
        ProjectService.listProjects(),
      ]);

      setProponents(resProponents.data.data);
      setProjects(resProjects.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleAddFilter = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [setFilters, filters],
  );

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  return (
    <Container>
      <Select
        label="Projeto"
        options={projects.map(item => item.name)}
        onChange={e => {
          handleAddFilter('projectId', projects[e.target.value].id);
        }}
        rounded={true}
      />
      <DatePicker
        label="Data"
        onChange={value => {
          const dateValue = value.toISOString().substring(0, 10);
          handleAddFilter('date', dateValue);
        }}
        rounded={true}
      />
      <Select
        label="Status"
        options={optionsStatus}
        onChange={e => handleAddFilter('status', optionsStatus[e.target.value])}
        rounded={true}
      />
    </Container>
  );
};

export default NotificationsFilter;
