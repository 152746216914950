import React,  { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import { Container, DialogFooter, InfoTextContainer, LabelError, Options, OptionsContent, Title } from './styles';
import { currencyMask, currencyMaskStore } from 'utils/currency';
import { useTheme } from 'styled-components';
import Typography from 'components/Typography';
import ModalInformation from 'components/ModalInformation';
import { ConfirmCloseCaptureModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { SuccessEndCaptureModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { useHistory } from 'react-router-dom';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';


const CloseCaptureProponent = ({ open, handleClose, onChange, ...props }) => {
  const { push } = useHistory();
  const [incentivValue, setIncentivValue] = useState(0);
  const [formattedValue, setFormattedValue] = useState(0);
  const [projectExecute, setprojectExecute] = useState(null);
  const [reason, setReason] = useState(null);
  const [valueCapturedFinal, setvalueCapturedFinal] = useState(null);
  const theme = useTheme();
  const inputReference = useRef(null);

  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });

  const modalRender = useMemo(() => {
    const modal = {
      ConfirmCloseCapture: (
        <ConfirmCloseCaptureModal
          open={modalOpen === 'ConfirmCloseCapture'}
          handleClose={() => setModalOpen('')}
          onClick={() => teste()}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);

  const modalRender2 = useMemo(() => {
    const modal = {
      SuccessEndCapture: (
        <SuccessEndCaptureModal
          open={modalOpen === 'SuccessEndCapture'}
          handleClose={() => setModalOpen('')}
          onClick={() => closeModals()}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);

  const closeModals = () => { 
    setModalOpen('')
    handleClose();
    //window.open('/projetos/'+props.project.id);
    window.location.reload(false);

  }

  const handleChange = (event) => {
    setprojectExecute(event.target.value);
  };

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const clearValue = () => {
    setvalueCapturedFinal(0);
  }

  const redirectToCapture = useCallback(() => {
    window.open('/capture-report/'+props.project.id, "_blank");
  },[]);

  const teste = useCallback(() => {
    const payload = {
      will_be_executed: projectExecute,
      value_captured: valueCapturedFinal,
      reason: reason 
    }

    try {
      ProjectService.endCapture(props.project.id, payload);
      setModalOpen('')
      setModalOpen('SuccessEndCapture')
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
    // setModalOpen('')
    // handleClose();
  },[valueCapturedFinal, projectExecute, reason]);

  const isDisabled = (valueCapturedFinal < 0 || !projectExecute)
    ? true
    : (projectExecute == "0" && !reason ? true : false)

  return (
    <>
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    >
      <DialogTitle>
        Encerrar captação
      </DialogTitle>

      <DialogContent>
        <Container>
          <Input 
            label="Valor total captado (Até o momento)"
            onChange={e => {
              setFormattedValue(currencyMask(e.target.value))
              setIncentivValue(currencyMaskStore(e.target.value))
            }}
            ref={inputReference}
            onBlur={e => {
              setvalueCapturedFinal(currencyMaskStore(e.target.value) - props.project.capturedValue) 
            }}

            value={`R$ ${formattedValue}`}
          />

            { valueCapturedFinal < 0 &&
            <>
              <LabelError>O valor informado é inferior aos incentivos registrado em seu projeto até o momento. Corrija o valor a cima ou visite o relatório de captação clicando no botão abaixo.</LabelError>
              <div className="updateDiv">
                  <Button type="button" onClick={redirectToCapture}>
                    Ver relatório de captação
                  </Button>
              </div>
            </>
            }
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Este projeto será executado?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Sim" />
              <FormControlLabel value="0" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>

          {(projectExecute === "0") &&
            <Input
              height="62px"
              label="Qual o motivo do encerramento?"
              multiline
              onChange={handleChangeReason}
            />
          }

        </Container>

        <DialogFooter>
          {/* <FormLabel>Deseja prosseguir com o arquivamento?</FormLabel> */}
          <DialogActions>
            {/* <Button onClick={() => onChange(incentivValue)} color="primary">
              SIM
            </Button>

            <Button 
              onClick={handleClose} 
              type="button"
              borderColor={theme.colors.primary}
              variant="secondary"
              color={theme.colors.primary}
            >
              NÃO
            </Button> */}

            <Button color="primary" disabled={isDisabled} onClick={() => setModalOpen('ConfirmCloseCapture')}>Continuar</Button>
            <Button 
            onClick={handleClose} 
              type="button"
              borderColor={theme.colors.primary}
              variant="secondary"
              color={theme.colors.primary}
              >Cancelar</Button>
          </DialogActions>
        </DialogFooter>
      </DialogContent>
    </Dialog>

    {modalRender}
    {modalRender2}

    <ModalInformation
      open={showModalInformation}
      handleClose={() => setShowModalInformation(false)}
      {...modalInformationData}
      project
    />
    </>
  );
};

export default CloseCaptureProponent;
