import React, { useCallback, useState } from 'react';

import { Container, Content } from './styles';
import TabItem from './TabItem';

const NewTabs = ({ tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const renderComponent = useCallback(() => {
    if (tabs[currentTab] && tabs[currentTab].component) {
      return tabs[currentTab].component
    }
    return null;
  }, [tabs, currentTab]);
  
  return (
    <Container>
      <Content>
        {
          tabs.map((tab, index) => (
            <TabItem
              title={tab?.title}
              active={index === currentTab}
              handleClick={() => {
                if(index === 1) {
                  if(localStorage.getItem("clickedNotifier") === true)
                    localStorage.setItem("clickedNotifier", "clicado");
                }
                setCurrentTab(index)
              }}
            />
          ))
        }
      </Content>
      {renderComponent()}
    </Container>
  );
};

export default NewTabs;