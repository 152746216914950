import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerModal, Title, Row, Footer, Container } from './styles';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import Chip from 'components/Chip';
import {
  indicateTo as changeStatusModal,
} from 'store/ducks/modals/actions';
import { Admin } from 'themes';

const IndicateTo = () => {
  const [data, setData] = React.useState({
    name: '',
  });

  const [indicated, setIndicated] = React.useState([]);

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  const handleAdd = e => {
    e.preventDefault();
    setIndicated([...indicated, data.name]);
    setData({ 
      name: '',
    });
    console.log(data);
  };

  // TODO: integrar com o backend
  const handleSubmit = e => {
    console.log(indicated);
    handleClose();
  }

  const handleDelete = (index) => {
    setIndicated(indicated.filter((item, i) => i !== index));
  }

  const dispatch = useDispatch();
  const indicateTo = useSelector(state => state.modals.vitrineModals.indicateTo);
  const handleClose = () => {
    dispatch(changeStatusModal());
  }

  return (
    <Container
      open={indicateTo}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        },
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Row>
          <Input
            name="name"
            onChange={handleChange}
            label="Nome do incentivador"
            value={data.name}
          />
          <Button variant="secondary" onClick={handleAdd} color={Admin.orange}>Inserir</Button>
        </Row>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', gap: '10px'}}>
        {indicated.map((item, index) => (
        
            <Chip
              name="price"
              onChange={handleChange}
              label={item}
              onClick={() => handleDelete(index)}
            />
          ))}
        </div>
        <Footer>
          <Button onClick={handleSubmit}>Indicar</Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
};
export default IndicateTo;
