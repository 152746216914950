import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import { Container, ContainerModal, Title, Row, Footer } from './styles';
import {
  toggleCreateUserModal,
  toggleEditUserModal,
  toggleDeleteUserModal,
  toggleSuccessUserModal,
  toggleBidersModal,
} from 'store/ducks/modals/actions';
import Input from 'components/Input';
import Button from 'components/Button';
import { Admin } from 'themes';
import { useCallback } from 'react';
import { UsersService } from 'services/users';
import { useUser } from 'hooks/useUser';
import { toast } from 'react-toastify';
import Select from 'components/Select';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

const INITIAL_DATA = {
  id: '',
  name: '',
  email: '',
  // password: "",
  // passwordConfirm: "",
  role: 'vitrine-manager',
};

const validatePasswordSchema = yup.object().shape({
  password: yup.string()
  .nullable().transform((o, c) => o === "" ? null : c)
  .matches(/^(?=.*[!$*&@#])/, "Deve conter um caractere especial")
  .matches(/^(?=.*[a-z])/, "Deve conter um caractere minúsculo")
  .matches(/^(?=.*[A-Z])/, "Deve conter um caractere maiúsculo")
  .matches(/^(?=.*[0-9])/, "Deve conter um número")
  .min(6, 'Mínimo de 6 caracteres')
  .max(30, 'Máximo de 30 caracteres'),
  passwordConfirm: yup.string()
  .when('password', {
    is: (password) => password.length > 0,
    then: yup.string()           
    .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Confirme a senha'),
  })
});

const UsersModal = ({ refreshData }) => {
  const { user } = useUser();
  const dispatch = useDispatch();
  const openModal = useSelector(state => state.modals.usersModal.isOpen);
  const isEdit = useSelector(state => state.modals.usersModal.isEdit);
  const { users } = useSelector(state => state.modals.usersModal);
  const {
    formState: { errors },
    setError,
    clearErrors
  } = useForm();

  const [data, setData] = useState({
    ...INITIAL_DATA,
  });

  const rolesOptions = [
    'Mestre',
    'Prospecção',
    'Backoffice CS Proponente',
    'Suporte',
    'Avaliador',
    'Geral',
    'Key Account Nível 2',
    'CS Proponente Nível Relacionamento',
    'Monitore',
  ];

  const rolesOptions2 = {
    0: 'Mestre',
    1: 'Prospecção',
    2: 'Backoffice CS Proponente',
    3: 'Suporte',
    4: 'Avaliador',
    5: 'Geral',
    6: 'Key Account Nível 2',
    7: 'CS Proponente Nível Relacionamento',
    8: 'Monitore',
  };

  const rolesOptions3 = {
    master: 0,
    cs_proponent1: 1,
    cs_proponent2: 2,
    support: 3,
    evaluator: 4,
    general: 5,
    key_account2: 6,
    cs_proponent_relationship: 7,
    monitore: 8,
  };
  // console.log('rolesOptions', rolesOptions)
  useEffect(() => {
    if (isEdit)
      setData({
        name: users && users.name,
        email: users && users.email,
        id: users && users.id,
        admin_role: rolesOptions3[users.adminRole],
        has_proponents: users && users.has_proponents,
        // password: "",
        // passwordConfirm: "",
      });
    else setData({ ...INITIAL_DATA });
  }, [isEdit, users, errors]);

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const updateUser = useCallback(async () => {
    try {
      if(data.password){
        await validatePasswordSchema.validate(data, { abortEarly: false });
      }
      await UsersService.updateUser(data);

    } catch (err) {
      const errors = {};
      err.inner.forEach((x) => {
        if (x.path !== undefined) {
          if (!(x.path in errors)) {
            errors[x.path] = { message: x.errors[0] }
            setError(x.path, { message: x.errors[0] });
          }
        }
      });

      toast.error("Falha ao salvar usuário.");

      return false;
    }
    return true;

  }, [data, dispatch, errors]);

  const createUser = useCallback(async () => {
    try {
      await UsersService.createUser({
        name: data.name,
        email: data.email,
        password: data.password,
        role: data.role,
        confirm_password: data.passwordConfirm,
        admin_role: data.admin_role
      });
      refreshData();
      dispatch(toggleSuccessUserModal());
    } catch (err) {
      Object.keys(err.response.data).map(item =>
        toast.error(err.response.data[item][0]),
      );
      console.log(err);
    }
  }, [
    data.email,
    data.name,
    data.password,
    data.passwordConfirm,
    data.role,
    data.admin_role,
    dispatch,
    refreshData,
  ]);

  const handleSubmit = async () => {
    if (isEdit) {
      var wasUpdated = await updateUser();
    }
    else createUser();
    if(!wasUpdated) return;
    setData({
      name: '',
      email: '',
      // password: '',
      // passwordConfirm: '',
      admin_role: '',
    });
    dispatch(toggleSuccessUserModal());
    clearErrors();
  };

  const handleClose = () => {
    if (isEdit) {
      return dispatch(
        toggleEditUserModal({
          username: '',
          email: '',
          password: '',
          id: -1,
        }),
      );
    }
    dispatch(toggleCreateUserModal());
  };

  const handleDelete = () => {
    if(data.has_proponents){
      dispatch(toggleBidersModal({ ...data, deleteMiddleware: true }));
    } else {
      dispatch(toggleDeleteUserModal());
    }
  };

  return (
    <Container
      open={openModal}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        },
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Title>
          <Typography>
            <b>{isEdit ? 'Editar' : 'Adicionar'} usuário</b>
          </Typography>
          <Typography variant="body2">
            {isEdit
              ? 'Edite abaixo os dados do usuário.'
              : 'Insira abaixo os dados do novo usuário.'}
            
          </Typography>
        </Title>
        <Row>
          <Typography variant="h6">Dado cadastral</Typography>
        </Row>
        <Row>
          <Input
            value={data.name}
            onChange={e => handleChange(e)}
            name="name"
            width="500px"
            label="Nome completo"
          />
        </Row>
        <Row>
          <Typography variant="h6">Dados de acesso</Typography>
        </Row>
        <Row>
          <Input
            value={data.email}
            onChange={e => handleChange(e)}
            name="email"
            width="500px"
            label="E-mail"
          />
        </Row>
        {user.adminRole === 'master' && (
          <>
            <Row>
              <Typography variant="h6">Perfil de acesso</Typography>
            </Row>
            <Row>
              <Select
                label="Perfil"
                options={rolesOptions}
                name="admin_role"
                value={rolesOptions.findIndex(item => item === rolesOptions2[data.admin_role])}
                onChange={e => handleChange(e)}
                role="admin"
              />
            </Row>
          </>
        )}
        {(!isEdit || (isEdit && (data.id === user.id))) && (
          <>
            <Row>
              <Input
                value={data.password}
                onChange={e => handleChange(e)}
                name="password"
                width="500px"
                label="Senha"
                type="password"
                error={errors["password"]?.message}
              />
            </Row>
            <Row>
              <Input
                value={data.passwordConfirm}
                onChange={e => handleChange(e)}
                name="passwordConfirm"
                width="500px"
                label="Confirmar senha"
                type="password"
                error={errors["passwordConfirm"]?.message}
              />
            </Row>
          </>
        )}

        {isEdit && (data.id !== user.id) && (
          <Row>
            <Button onClick={handleDelete} variant="tertiary">
              <Typography variant="caption">Excluir usuário</Typography>
            </Button>
          </Row>
        )}
        <Footer>
          <Button
            variant="secondary"
            color={Admin.orange}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={user.adminRole === 'master' ? false : data.id != user.id}>
            {isEdit ? 'Salvar' : 'Cadastrar'}
          </Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
};

export default UsersModal;
