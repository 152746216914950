import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const SendToMonitore = ({ open, handleClose, onClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
      Tem certeza que deseja liberar esse projeto na Monitore?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Essa ação não pode ser desfeita. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onClick} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendToMonitore;