import React from 'react';
import PageTitle from 'components/PageTitle';

import { Container, MainContainer } from './styles';

const Policy = () => {
  return (
    <MainContainer>
      <Container>
        <div className="main">
          <div className="center">
              <b>POLÍTICA DE PRIVACIDADE</b>
          </div>
          <div>
              <p>A Incentiv – Impacto Social & Incentivos Fiscais Ltda. (“Incentiv.me” ou “Nós”) entende a importância do dever de respeitar a privacidade e segurança das informações dos Usuários que utilizam a Plataforma da Incentiv.me. Por isso, o uso de tais informações é realizado em conformidade com os regulamentos nacionais de proteção de dados, inclusive a Lei Geral de Proteção de Dados (Lei nº 13.709/2018 – “LGPD”).</p>
          </div>
          <div>
              <b>Pedimos que leia com atenção esta Política de Privacidade (“Política”). Ela descreve como são tratadas as informações pessoais fornecidas por Você ao acessar e utilizar a Plataforma. Caso Você não concorde com os termos e condições abaixo, Você não deve utilizar a nossa Plataforma.</b>
          </div>
          <div>
              <p>Este documento é parte integrante dos Termos e Condições de Uso da Plataforma, que contém uma visão geral da Plataforma e, sem prejuízo de outras definições atribuídas nesta Política, as definições que são contempladas nos Termos e Condições de Uso da Plataforma aplicam-se à essa Política.</p>
          </div>
          <div>
              <p>Após a leitura desta Política, Você poderá entrar em contato para sanar qualquer dúvida ou exercer direitos relacionados aos seus dados pessoais. Para isso, basta contatar nosso Canal de Comunicação pelo e-mail encarregado@incentiv.me.</p>
          </div>
          <div>
              <p>Para facilitar a sua leitura, elaboramos o quadro abaixo com um breve resumo dos principais pontos abordados neste documento.</p>
          </div>
          <div className="table-center">
              <table>
                  <tr>
                      <td><b>Agente de tratamento</b></td>
                      <td>Incentiv – Impacto Social & Incentivos Fiscais Ltda.
                          CNPJ: 30.248.849/0001-40 
                          CELTA (Centro para Laboração de Tecnologias Avançadas), na Rodovia SC 401, nº 600, Módulo 3.23, Parque Tecnológico Alfa
                          Florianópolis/SC
                          CEP: 88.030-000.

                          Canal de Comunicação solicitações e dúvidas:  <b>encarregado@incentiv.me</b></td>
                  </tr>
                  <tr>
                      <td><b>Natureza opcional de concessão dos dados pessoais</b></td>
                      <td>Você é livre para fornecer ou não os seus dados pessoais e, mesmo assim, continuar navegando na Plataforma. <b>Ver mais...</b> </td>
                  </tr>
                  <tr>
                      <td><b>Tipos de dados pessoais processados</b></td>
                      <td>Dados pessoais fornecidos diretamente por Você ou coletados de forma automática. <b>Ver mais...</b> </td>
                  </tr>
                  <tr>
                      <td><b>Comunicação</b></td>
                      <td>Envio de comunicação por parte da Incentiv.me a Você, inclusive informativos. É possível o descadastramento a qualquer momento. <b>Ver mais...</b></td>
                  </tr>
                  <tr>
                      <td><b>Tratamento e retenção dos dados pessoais</b></td>
                      <td>Finalidades pelas quais tratamos os seus dados pessoais, bem como por quanto tempo serão retidos e, ainda, quais são os métodos de segurança adotados para evitar qualquer tipo de incidente envolvendo os seus dados pessoais. <b>Ver mais...</b></td>
                  </tr>
                  <tr>
                      <td><b>Compartilhamento de dados pessoais</b></td>
                      <td>A Incentiv.me não comercializa os seus dados pessoais. Porém, há a possibilidade de compartilhamento de tais dados pessoais com alguns terceiros. <b>Ver mais...</b> </td>
                  </tr>
                  <tr>
                      <td><b>Cookies</b></td>
                      <td>Ao navegar em nossa Plataforma, coletamos os seguintes tipos de cookies: 
                          <ul>
                              <li>cookies de preferência: permitem a Plataforma lembrar de certas informações para personalizar a sua experiência.</li>
                              <li>cookies publicitários: os cookies de publicidade são usados por partes envolvidas no serviço de publicidade de outras empresas, seja em nossa Plataforma ou em divulgações nos sites de terceiros. </li>
                              <li>cookies analíticos: os cookies analíticos coletam informações sobre o uso da nossa Plataforma, como a frequência de acesso, e servem para melhorar nossos serviços. <b>Ver mais...</b></li>
                          </ul>
                      </td>
                  </tr>
                  <tr>
                      <td><b>Controle dos dados pessoais e como exercer os seus direitos</b></td>
                      <td>Confirmação da existência de tratamento, acesso, correção etc. <b>Ver mais...</b></td>
                  </tr>
                  <tr>
                      <td><b>Alterações na Política de Privacidade</b></td>
                      <td>Notificaremos os Usuários sobre eventuais alterações materiais nesta Política de Privacidade através de postagem em nossa Plataforma. <b>Ver mais...</b> </td>
                  </tr>
              </table>
          </div>
          <div>
              <p>Essa Política não se aplica aos serviços oferecidos por terceiros, portanto, caso algum conteúdo apresentado na Plataforma encaminhe o Usuário, através de link externo, a outros websites, recomendamos que o Usuário analise cautelosamente as políticas de privacidade destes.</p>
          </div>
          <div>
              <b>1. &emsp;NATUREZA OPCIONAL DE CONCESSÃO DOS DADOS</b>
          </div>
          <div>
              1.1 &emsp; O Usuário é livre para fornecer dados pessoais ao usar a Plataforma. A não concessão dos dados pode impossibilitar a obtenção de alguns serviços solicitados.
          </div>
          <div>
              <b>2. &emsp;TIPOS DE DADOS PROCESSADOS</b>
          </div>
          <div>
              2.1 &emsp;<b> Dados de navegação, coletados automaticamente:</b> durante o funcionamento normal da Plataforma, certos dados pessoais poderão vir a ser coletados por conta da utilização normal, pelos Usuários, de protocolos de comunicação da internet. Essas informações poderão incluir, por exemplo, endereços de IP, desempenho do provedor, da rede e do dispositivo, o tipo do navegador, o sistema operacional, nomes de domínio dos computadores usados por quem navega na Plataforma; e serão adquiridas somente para obter estatísticas anônimas sobre o uso de forma a melhorar continuamente o acesso e conteúdo da Plataforma. Tais informações serão excluídas após o processamento e poderão ser usadas ​​para determinar responsabilidades em caso de crimes cibernéticos. 
          </div>
          <div>
              2.2 &emsp;<b> Dados fornecidos voluntariamente pelos usuários da Incentiv.me:</b> para acessar a Plataforma, o Usuário poderá optar por se cadastrar e informar, explícita e voluntariamente, seu e-mail e perfis em redes sociais. Como se vê, a <b>Incentiv.me</b> coleta o mínimo possível de dados, a fim de manter a funcionalidade da Plataforma e a qualidade da prestação de seus serviços. No entanto, outras informações pessoais poderão ser coletadas durante o uso da Plataforma, a saber: nome completo, CPF, RG, data de nascimento, nacionalidade, endereço completo (CEP, rua/avenida, número, complemento, bairro, referência, cidade e Estado), telefone, dados bancários e de cartão de crédito.
          </div>
          <div>
              2.3 &emsp;<b> Dados fornecidos pela Incentiv.me ao Proponente:</b> a <b>Incentiv.me</b> poderá coletar e organizar os dados de desempenho dos Projetos Incentivados para disponibilizar ao Proponente informações sobre seu projeto, quais sejam: resultados do Projeto (valor arrecadado e número de Patrocinadores), instruções de como sacar o valor arrecadado e lista com informações dos Patrocinadores (nome completo, CPF/CNPJ, e-mails e endereço completo) que participaram do seu Projeto, inclusive para fins de viabilização da entrega das Contrapartidas requisitadas. 
          </div>
          <div>
              <b>3. &emsp; COMUNICAÇÃO </b>
          </div>
          <div>
              3.1 &emsp; Os Usuários estão cientes de que ao interagirem com a Plataforma, poderão receber mensagens por e-mail sobre a <b>Incentiv.me</b>, inclusive de conteúdo publicitário, para divulgação das novidades que envolvem a empresa. Caso deseje, o Usuário poderá descadastrar o recebimento de tais comunicações nos próprios e-mails recebidos, em sua Conta de Acesso cadastrada na Plataforma ou, ainda, enviando uma mensagem diretamente ao endereço de contato encarregado@incentiv.me. No entanto, mesmo se o Usuário optar por não receber os e-mails anteriores, a <b>Incentiv.me</b> ainda poderá enviar e-mails transacionais (por exemplo, em conexão com uma solicitação de redefinição de senha, uma verificação por e-mail ou um e-mail relacionado ao serviço escolhido) necessários para facilitar o uso da Plataforma.
          </div>
          <div>
              <b>4. &emsp; TRATAMENTO E RETENÇÃO DOS DADOS </b>
          </div>
          <div>
              4.1 &emsp; Os dados são processados de acordo com a legislação e incluídos em base de dados eletrônicas ou físicas, de acordo com as finalidades estabelecidas neste documento ou nos Termos e Condições de Uso da Plataforma e acessados pelo mínimo de pessoas necessárias ao tratamento, todas obrigadas aos deveres de confidencialidade e segurança. Basicamente, a <b>Incentiv.me</b> utiliza os seus dados para:
              <ul>
                  <li><b>Atender a finalidade para a qual o dado foi fornecido:</b> nós podemos utilizar os dados que Você nos forneceu para cumprir a finalidade informada no momento da coleta. Por exemplo, podemos usar seus dados de identificação e qualificação para permitir a devida execução dos serviços oferecidos na Plataforma;</li>
                  <li><b>Cumprir com obrigações legais ou regulatórias:</b> seus dados pessoais poderão ser utilizados para o atendimento de obrigações dispostas em lei, regulações de órgãos governamentais, autoridades fiscais, Poder Judiciário e/ou outra autoridade competente;</li>
                  <li><b>Permitir o exercício regular de nossos direitos:</b> mesmo após o término da nossa relação com Você, nós poderemos tratar alguns de seus dados pessoais para exercer nossos direitos garantidos em lei, inclusive como prova em processos judiciais ou administrativos; e</li>
                  <li><b>Realizar atividades de tratamento internas com base em nosso legítimo interesse:</b> podemos tratar os dados, também, com base em nosso legítimo interesse, sempre observando os limites da sua expectativa e nunca em prejuízo de seus interesses, direitos e liberdades fundamentais.</li>
              </ul>
          </div>
          <div>
              4.2 &emsp; O armazenamento é feito em servidores que seguem os critérios internacionais de segurança e retidos pelo tempo necessário para atingir a finalidade para a qual foram coletados, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes. Posteriormente, os dados pessoais serão apagados, exceto se houver outras obrigações legais de armazenamento.    
          </div>
          <div>
              <b>5. &emsp; COMPARTILHAMENTO DOS DADOS</b>
          </div>
          <div>
              5.1 &emsp; A <b>Incentiv.me</b> não comercializa os dados pessoais coletados na Plataforma ou durante a prestação de seus serviços. Porém, há a possibilidade de compartilhamento com nossos contratados, parceiros de negócios e prestadores de serviço para finalidade exclusiva de executar tais serviços, com base nos seguintes critérios e para as finalidades descritas abaixo:
              <ul>
                  <li><b>Empresas do mesmo grupo econômico da Incentiv.me:</b> podemos compartilhar os dados dos Usuários com outras associações/empresas do mesmo grupo econômico da <b>Incentiv.me</b>, para operacionalização e oferta de novos serviços, marketing, prospecção, pesquisas de mercados e promoção dos serviços da <b>Incentiv.me</b>;</li>
                  <li><b>Parceiros de negócios, prestadores de serviços e outros terceiros:</b> essas empresas trabalham com a <b>Incentiv.me</b> para viabilizar a prestação dos serviços ao Usuário e aprimorá-los. São exemplos os serviços tecnologia. Esses parceiros recebem as informações pessoais dos Usuários com a finalidade específica de prestar serviços à própria <b>Incentiv.me</b> e não possuem quaisquer direitos de uso dessas informações fora dessa hipótese; </li>
                  <li><b>Novos negócios:</b> no contínuo desenvolvimento do nosso negócio, processos de aquisição e fusão de empresas, subsidiárias e outros negócios podem ocorrer. Nessa transferência de negócios, dados pessoais e demais informações dos Usuários também são transferidos, mas ainda assim, será mantida esta Política; </li>
                  <li><b>Requisição judicial:</b> a <b>Incentiv.me</b> pode compartilhar dados pessoais em caso de requisição judicial ou mediante determinação de autoridade competente, nos termos da lei; e</li>
                  <li><b>Com a autorização do Usuário:</b> Em outros casos não previstos acima, havendo o objetivo de compartilhamento dos dados pessoais e informações, enviaremos ao Usuário uma notificação com informações a respeito de tal compartilhamento para solicitar o seu consentimento, para tal finalidade determinada.</li>
              </ul>
          </div>
          <div>
              <b>6. &emsp; POLÍTICA DE COOKIES</b>
          </div>
          <div>
              6.1 &emsp; Ao visitar um site, informações pessoais como nome, e-mail e interesses dos usuários são armazenadas em um cookie. Trata-se de uma prática comum no âmbito da internet. Os cookies ajudam o site a se lembrar de informações sobre a visita, como o seu idioma, por exemplo. Isso pode tornar a sua próxima visita mais fácil e o site mais útil para Você.
          </div>
          <div>
              6.2 &emsp; A <b>Incentiv.me</b> utiliza cookies ou tecnologias semelhantes para coletar informações sobre o computador ou dispositivo móvel do Usuário, com a finalidade de garantir o funcionamento adequado da Plataforma e melhoria na experiência de navegação. Os cookies servem para simplificar a análise do tráfego ou indicar quando um site é visitado, permitindo o envio de informações a usuários, cabendo a estes fazerem a gestão desses cookies em seus próprios navegadores de Internet. Os diferentes tipos de cookies estão identificados a seguir:
              <ul>
                  <li><b>cookies de preferência:</b> permitem a Plataforma lembrar de certas informações para personalizar a sua experiência. Se esses cookies forem desativados, a Plataforma pode se tornar menos funcional, mas não deve impedi-la de funcionar corretamente.  </li>
                  <li><b>cookies de segurança:</b> os cookies de segurança são usados para autenticar usuários, evitar o uso fraudulento de credenciais de login e proteger dados de usuários de acesso não autorizado. Se esses cookies estiverem bloqueados ou desativados, a Plataforma não funcionará corretamente.</li>
                  <li><b>cookies de processamento:</b> os cookies de processamento ajudam nossa Plataforma a entregar a funcionalidade que o usuário espera ao permitir o acesso a áreas seguras. Se esses cookies estiverem bloqueados ou desativados, a Plataforma não funcionará corretamente.</li>
                  <li><b>cookies de sessão pública:</b> os cookies de sessão pública permitem que o Usuário colete informações relacionadas com interação do usuário com a própria Plataforma, incluindo as páginas visitadas com mais frequência e se houve, em algum momento, mensagens de erro. Esses cookies são responsáveis por melhorar a Plataforma. Se estiverem bloqueados ou desativados, a Plataforma pode tornar-se menos funcional, mas não deve impedi-la de funcionar corretamente. </li>
                  <li><b>cookies publicitários:</b> os cookies de publicidade são usados por partes envolvidas no serviço de publicidade de outras empresas, seja em nossa Plataforma ou em divulgações nos sites de terceiros. Estes cookies coletam informações sobre as atividades on-line do usuário, como anúncios vistos em sites visitados, para extrair inferências sobre quais anúncios publicitários podem ser relevantes. Se esses cookies forem desativados, a Plataforma pode se tornar menos funcional, mas não deve impedi-la de funcionar corretamente. </li>
                  <li><b>cookies analíticos:</b> os cookies analíticos coletam informações sobre o uso da nossa Plataforma, como a frequência de acesso, e servem para melhorar nossos serviços. Se esses cookies estiverem desativados, a Plataforma pode tornar-se menos funcional, mas não deve impedi-lo de funcionar corretamente.</li>
              </ul>
          </div>
          <div>
              <b>7. &emsp; CONTROLE DOS DADOS</b>
          </div>
          <div>
              7.1 &emsp; A <b>Incentiv.me</b> possui um responsável pela gestão dos dados de usuários (gestor de implementação), disponível para esclarecimentos ou pedidos relacionados a essas informações. Caso pretenda <u>remover, corrigir, atualizar ou apagar</u> o conteúdo coletado, os Usuários deverão entrar em contato pelo Canal de Comunicação encarregado@incentiv.me. A solicitação será analisada em até 15 (quinze) dias, contados do recebimento, e qualquer alteração, caso necessária, será realizada o mais breve possível, em tempo razoável.  
          </div>
          <div>
              <b>8. &emsp; ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</b>
          </div>
          <div>
              8.1 &emsp; Notificaremos sobre eventuais alterações materiais nesta Política de Privacidade através de postagem em nossa Plataforma e/ou redes sociais. Por isso, recomendamos que os Usuários acessem nossa Política periodicamente e observem a data de sua modificação, ao final deste documento, para confirmar eventual modificação. Alterações significativas quanto à finalidade do tratamento dos dados, ou que impactem os direitos dos titulares, serão informadas com maior destaque.  
          </div>
          <div>
            Esta Política foi modificada pela última vez em 06/05/2022.
          </div>
      </div>
      </Container>
      
    </MainContainer>
  );
};

export default Policy;
