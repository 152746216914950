import React, { useState, useCallback } from 'react';

import Typography from 'components/Typography';
import Select from 'components/Select';
import {
  Container,
  ContentCard,
  Link,
  ImpactDiv
} from './styles';
import ODSCard from 'components/ODSCard';
import { useTheme } from 'styled-components';

import { useFormContext, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import Input from 'components/Input';
import { onlyNumber } from 'utils/maskUtils';
import { FormHelperText } from '@material-ui/core';

const Step3 = ({ odsSelecteds, setOdsSelecteds, impactCategories, impactSubCategories }) => {
  const [odsSelected, setOdsSelected] = useState([]);
  
  const { setValue, getValues, clearErrors, control, formState: { errors } } = useFormContext();
  const theme = useTheme();
  const role = theme.colors.background == '#12203F' ? 'proponente' : 'admin';

  const handleSelectedOds = useCallback(
    type => {
      if (odsSelected.includes(type)) {
        setOdsSelecteds(odsSelected.filter(ods => ods !== type));
      } else {
        setOdsSelecteds([...odsSelected, type]);
      }
    },
    [odsSelected],
  );

  useEffect(() => {
    setValue(
      'sd_goal_ids',
      odsSelected.map(ods => ods + 1),
    );
  }, [setValue, odsSelected]);

  useEffect(() => {
    if (odsSelecteds) {
      setOdsSelected(odsSelecteds);
    }
  }, [odsSelecteds, setValue]);

  const status = getValues('status');

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);

  return (
    <Container>
      <div>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          ODS e Impacto
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Indique quais são as ODS’s e categorias de impacto do seu projeto e o público que será impactado por ele.
        </Typography>
      </div>
      <div style={{ marginTop: 24 }}>
        <Typography variant="h6" color="grayDark2">
          Principais ODS’s incorporadas no projeto
        </Typography>
      </div>
      <div style={{ marginTop: 8, width: '75%' }}>
        <Typography variant="subtitle2" color="grayLight1">
          Os 17 Objetivos de Desenvolvimento Sustentável (ODS) equilibram as
          três dimensões do desenvolvimento sustentável: a econômica, a social e
          a ambiental. Você pode selecionar quantos ODS quiser, mas lembre-se
          que eles precisam estar alinhados com os objetivos do seu
          projeto.
        </Typography>
      </div>
      <div style={{ marginTop: 24 }}>
        <Typography
          variant="subtitle2"
          color="grayLight1"
          style={{ marginTop: 24 }}
        >
          Ficou em dúvida sobre qual a ODS incorporar no seu projeto? Veja
          detalhes no site da ONU clicando <Link href={'https://odsbrasil.gov.br/'} rel="noreferrer" target="_blank">aqui </Link> 
          {odsSelecteds.length <= 0 &&
            <span style={{color: 'red'}}>* É obrigatório indicar ao menos 1 objetivo especifico.</span>
          }
        </Typography>
      </div>
      <ContentCard error={Boolean(errors["sd_goal_ids"])}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(ods => (
          <ODSCard
            type={ods}
            onClick={() => { handleSelectedOds(ods); clearErrors("sd_goal_ids"); }}
            opacity={!odsSelected.includes(ods)}
            disabled={isDisabledByStatus}
          />
        ))}
      </ContentCard>

      {errors["sd_goal_ids"] && <FormHelperText error>{errors["sd_goal_ids"]?.message}</FormHelperText>}

      <ImpactDiv>
        
        {/* *Obrigatório */}
        <Controller
          name="impact_category_id"
          control={control}
          render={({ field }) => {
            return (
              <Select
                {...field}
                label="Categoria de impacto"
                options={impactCategories.map(item => item.name)}
                error={errors?.impact_category_id?.message}
                role={role}
                onChange={e => {
                  setValue(
                    'impact_category_id',
                    impactCategories[e.target.value].id,
                  );
                  clearErrors("impact_category_id");
                }}
                required={true}
                margin={86}
                value={impactCategories.findIndex(
                  item => item.id === getValues('impact_category_id'),
                )}
                disabled={isDisabledByStatus}
              />
            )
          }}
        />

        {/* *Obrigatório */}
        <Controller
          name="impact_subcategory_id"
          control={control}
          render={({ field }) => {
            return (
              <Select
                {...field}
                label="Subcategoria de impacto"
                options={impactSubCategories.map(item => item.name)}
                error={errors?.impact_subcategory_id?.message}
                role={role}
                onChange={e => {
                  setValue(
                    'impact_subcategory_id',
                    impactSubCategories[e.target.value].id,
                  );
                  clearErrors("impact_subcategory_id");
                }}
                required={true}
                margin={86}
                value={impactSubCategories.findIndex(
                  item => item.id === getValues('impact_subcategory_id'),
                )}
                disabled={isDisabledByStatus}
              />
            )
          }}
        />
      </ImpactDiv>
      <ImpactDiv>
      {/* *Obrigatório */}
      <Controller
        name="target"
        control={control}
        render={({ field }) => (
          <Input
            className="select-subcategory-name"
            label="Publico-alvo direto"
            error={errors?.target?.message}
            {...field}
            required={true}
            margin={86}
            onChange={e => {
              const value = e.target.value;
              setValue('target', value);
              clearErrors("target");
            }}
            disabled={isDisabledByStatus}
          />
        )}
      />
      {/* *Obrigatório */}
      <Controller
        name="audience_goal"
        control={control}
        render={({ field }) => {
          return (
            <Input
              label="Meta do público-alvo direto"
              min={0}
              {...field}
              onChange={e => {
                const value = onlyNumber(e.target.value);
                setValue('audience_goal', value);
                clearErrors("audience_goal");
              }}
              required={true}
              margin={86}
              disabled={isDisabledByStatus}
              error={errors["audience_goal"]?.message}
            />
          );
        }}
      />
    </ImpactDiv>
    </Container>
  );
};

export default Step3;
