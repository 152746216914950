import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Fade, Backdrop, Box } from '@material-ui/core';
import CircleFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Label,
  Description,
  Content,
  Row,
  ConfirmButton,
  CancelButton,
  ButtonLabel,
  CheckBoxLabel,
  Container,
  FailureReasonInput,
} from './styles';

import { 
  toggleCounterpartsReviewModal,
  onActionCounterpartsReviewModal
} from 'store/ducks/modals/actions';

const CounterpartsReviewModal = () => {
  const dispatch = useDispatch();
  const [approved, setApproved] = React.useState(true);
  const [failureReason, setFailureReason] = React.useState('');
  const openModal = useSelector(state => state.modals.statusCounterpartsReview);
  const { description } = useSelector(state => state.modals.dataCounterpartsReview);

  const handleClose = () => {
    setFailureReason('');
    setApproved(true);
    dispatch(toggleCounterpartsReviewModal());
  };

  const handleAction = () => {
    dispatch(onActionCounterpartsReviewModal({ approved, failureReason }));
  }

  return (
    <Container
      open={openModal}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          background: 'rgba(#050714, 0.6) !important',
        },
      }}
      disableAutoFocus
    >
      <Fade in={openModal}>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Análise de Contrapartida</ModalTitle>
          </ModalHeader>
          <Content>
            <div>
              <Label>Contrapartida</Label>
              <Description>
                {description}
              </Description>
            </div>
            <Box margin="10px 0">
              <Label>Status</Label>
              <Row>
                <Row>
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleFilled />}
                    checked={approved}
                    value={approved}
                    style={{ color: '#1A2440' }}
                    onChange={() => setApproved(true)}
                  />
                  <CheckBoxLabel>Aprovada</CheckBoxLabel>
                </Row>
                <Row>
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleFilled />}
                    checked={!approved}
                    value={!approved}
                    style={{ color: '#1A2440' }}
                    onChange={() => setApproved(false)}
                  />
                  <CheckBoxLabel>Reprovada</CheckBoxLabel>
                </Row>
              </Row>
            </Box>
            <Box
              style={{
                display: approved ? 'none' : 'flex',
              }}
              display="flex"
              flexDirection="column"
              margin="0 0 20px 0"
            >
              <Label>Motivo</Label>
              <FailureReasonInput
                rowsMax={4}
                multiline
                value={failureReason}
                onChange={e => setFailureReason(e.target.value)}
              />
            </Box>
            <Row>
              <CancelButton onClick={handleClose}>
                <ButtonLabel>Cancelar</ButtonLabel>
              </CancelButton>
              <ConfirmButton onClick={() => handleAction()}>
                <ButtonLabel>Concluir Análise</ButtonLabel>
              </ConfirmButton>
            </Row>
          </Content>
        </ModalContainer>
      </Fade>
    </Container>
  );
};

export default CounterpartsReviewModal;
