import styled from 'styled-components';

export const Container = styled.form`
  margin-top: 20px;

  display: grid;
`;

export const GridInputs = styled.div`

`;

export const Input1 = styled.div`
  grid-area: input1;
`;

export const Input2 = styled.div`
  grid-area: input2;
`;

export const Input3 = styled.div`
  grid-area: input3;
`;

export const CheckNews = styled.div`
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .text {
    cursor: pointer;
  }
`;

export const CheckTerms = styled.div`
  color: #f44336;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
`;


export const ErrorBox = styled.p`
  margin-left: 50px;
`;

export const Link = styled.a`
  // color: ${({ theme }) => theme.colors.grayDark1};
`;

export const StepDados = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;

  @media (max-width: 450px){
    display: grid;
    gap: 30px;
  }
`;