import React from 'react';

import { Card } from './styles';
import { ODSCards } from '../../constants/ods-card-images';

const ODSCard = ({ type, opacity, onClick, disabled }) => {
  return (
    <Card
      onClick={onClick}
      opacity={opacity}
      src={ODSCards[type]}
      alt="ods-card"
      disabled={disabled}
    />
  );
};

export default ODSCard;
