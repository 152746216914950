import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ProjectService } from 'services/projects';
import Footer from './Footer';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import { dateFormattedToDateISO, formatDateFix } from 'utils/date';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { formatNumber } from 'utils/maskUtils';
import { toast } from 'react-toastify';
import ProjectCreatedIncompletedModal from 'components/ProjectCreatedIncompletedModal';
import { useUser } from 'hooks/useUser';
import { ChangeProponentModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { formatNull } from 'utils/formatNull';
import { orderFeedbackErrorMessage } from 'utils/orderToast';

const ProjectForm = ({ activeStep, project, handleNext, handlePrevious, loadProjectInfo, handleChangeOnlyMonitore, saveProject  }) => {
  // const { getValues } = useFormContext();
  const { push } = useHistory();
  const { user } = useUser();
  const isGeneral = user.adminRole === 'general';

  const formHook = useForm();
  const [laws, setLaws] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [impactCategories, setImpactCategories] = useState([]);
  const [impactSubCategories, setImpactSubCategories] = useState([]);
  const [locationsUf, setLocationsUf] = useState([]);
  const [odsSelecteds, setOdsSelecteds] = useState([]);
  const [createChip, setCreateChip] = useState([]);
  const [counterparts, setCounterparts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [inputs, setInputs] = useState({ specificGoals: { '0': '' } });
  const [showModal, setShowModal] = useState(false);
  const [showModalIncomplete, setShowModalIncomplete] = useState(false);
  const [showChangeProponentModal, setShowChangeProponentModal] = useState(false);

  const [isOnlyMonitore, setIsOnlyMonitore] = useState(project.only_monitore ? true : false);

  const changeFieldsDB = project?.change_fields?.replaceAll(' ','')?.replaceAll('"','')?.replaceAll('[','')?.replaceAll(']','');
  const arryFields = changeFieldsDB?.split(',');
 
  const [changedFields, setChangedFields] = useState({
    name: arryFields?.filter((f)=>f=='name').length > 0 ? true : false,
    edition: arryFields?.filter((f)=>f=='edition').length > 0 ? true : false,
    code: arryFields?.filter((f)=>f=='code').length > 0 ? true : false,
    law_id: arryFields?.filter((f)=>f=='law_id').length > 0 ? true : false,
    impact_category_id: arryFields?.filter((f)=>f=='impact_category_id').length > 0 ? true : false,
    impact_subcategory_id: arryFields?.filter((f)=>f=='impact_subcategory_id').length > 0 ? true : false,
    target: arryFields?.filter((f)=>f=='target').length > 0 ? true : false,
    audience_goal: arryFields?.filter((f)=>f=='audience_goal').length > 0 ? true : false,
    runtime_days: arryFields?.filter((f)=>f=='runtime_days').length > 0 ? true : false,
    summary: arryFields?.filter((f)=>f=='summary').length > 0 ? true : false,
    other_informations: arryFields?.filter((f)=>f=='other_informations').length > 0 ? true : false,
    goal: arryFields?.filter((f)=>f=='goal').length > 0 ? true : false,
    specific_goal: arryFields?.filter((f)=>f=='specific_goal').length > 0 ? true : false,
    sd_goal_ids: arryFields?.filter((f)=>f=='sd_goal_ids').length > 0 ? true : false,
    more_than_one_city: arryFields?.filter((f)=>f=='more_than_one_city').length > 0 ? true : false,
    approved_value: arryFields?.filter((f)=>f=='approved_value').length > 0 ? true : false,
    captured_value: arryFields?.filter((f)=>f=='captured_value').length > 0 ? true : false,
    funding_period: arryFields?.filter((f)=>f=='funding_period').length > 0 ? true : false,
    partially_captured: arryFields?.filter((f)=>f=='partially_captured').length > 0 ? true : false,
    locations: arryFields?.filter((f)=>f=='locations').length > 0 ? true : false,
    video: arryFields?.filter((f)=>f=='video').length > 0 ? true : false,
    account_opening_commitment: arryFields?.filter((f)=>f=='account_opening_commitment').length > 0 ? true : false,
    minimum_value: arryFields?.filter((f)=>f=='minimum_value').length > 0 ? true : false,
    highlights_and_awards: arryFields?.filter((f)=>f=='highlights_and_awards').length > 0 ? true : false,
    company_names: arryFields?.filter((f)=>f=='company_names').length > 0 ? true : false,
    sponsors: arryFields?.filter((f)=>f=='sponsors').length > 0 ? true : false,
    more_than_one_city: arryFields?.filter((f)=>f=='more_than_one_city').length > 0 ? true : false,
    counterparts: arryFields?.filter((f)=>f=='counterparts').length > 0 ? true : false,
    official_journal: arryFields?.filter((f)=>f=='official_journal').length > 0 ? true : false,
    work_plan: arryFields?.filter((f)=>f=='work_plan').length > 0 ? true : false,
    capture_certificate: arryFields?.filter((f)=>f=='capture_certificate').length > 0 ? true : false,
    contract_file: arryFields?.filter((f)=>f=='contract_file').length > 0 ? true : false,
    project_presentation: arryFields?.filter((f)=>f=='project_presentation').length > 0 ? true : false,
    opening_receipt: arryFields?.filter((f)=>f=='opening_receipt').length > 0 ? true : false,
    image: arryFields?.filter((f)=>f=='image').length > 0 ? true : false,
    approval_date: arryFields?.filter((f)=>f=='approval_date').length > 0 ? true : false,
  });
  
  console.log("INFO DO PROJETO", project); 
  // console.log("State Changed Filters", changedFields); 
  // console.log("arryFields", arryFields); 

  const handleCloseIncomplete = useCallback(() => {
    setShowModalIncomplete(false);
  }, []);

  const updateProponent = useCallback(async (payload) => {
    try {
      const response = await ProjectService.updateProjectProponent(project.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Proponente alterado com sucesso!');
        setShowChangeProponentModal(false);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao alterar o proponente do projeto');
      }
    }
  }, []);

  async function handleSetProjectOnlyMonitore(){    
    setIsOnlyMonitore(true);
    try {
      const response = await ProjectService.sendProjectToOnlyMonitore(project.id);
      // handleChangeOnlyMonitore();
      window.location.reload();
    }catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao deixar projeto exclusivo na Monitore!');
      }
    }
  };

  const loadProjectFormInfo = useCallback(async () => {

  const response = await ProjectService.getFormInfo();
    setLaws(response.data.laws);
    setImpactCategories(response.data.impact_categories);
    setImpactSubCategories(response.data.impact_subcategories);
    setLocationsUf(response.data.locations_uf);
  }, []);

  const renderStep = useCallback(() => {
    const component = {
      0: <Step1
            laws={laws}
            ufs={locationsUf} 
            locations={project?.locations} 
            setCreateChip={setCreateChip}
            createChip={createChip} 
            changedFields={changedFields}
            setChangedFields={(e)=> setChangedFields({...e})}
          />,
      1: <Step2 inputs={inputs} setInputs={setInputs} changedFields={changedFields} setChangedFields={(e)=> setChangedFields({...e})} />,
      2: <Step3
            odsSelecteds={odsSelecteds} 
            setOdsSelecteds={setOdsSelecteds}
            impactCategories={impactCategories}
            impactSubCategories={impactSubCategories}
            changedFields={changedFields}
            setChangedFields={(e)=> setChangedFields({...e})}
         />,
      3: <Step4 images={project?.images} handleSave={updateProject} changedFields={changedFields} setChangedFields={(e)=> setChangedFields({...e})} isUploading={isUploading}/>,
      4: <Step5 id={project?.id} changedFields={changedFields} setChangedFields={(e)=> setChangedFields({...e})}/>,
      5: <Step6 
            counterparts={counterparts} 
            setCounterparts={setCounterparts} 
            changedFields={changedFields} 
            setChangedFields={(e)=> setChangedFields({...e})}
          />,
      6: <Step7 
            proponentId={project?.proponent_id} 
            projectData={project}
            changedFields={changedFields}
            setChangedFields={(e)=> setChangedFields({...e})}
            isOnlyMonitore={isOnlyMonitore}
            handleSave={updateProject}
            isUploading={isUploading}
            handleSetProjectOnlyMonitore={()=>{handleSetProjectOnlyMonitore()}}
          />,
    };
    return component[activeStep];
  }, [
    counterparts,
    activeStep,
    laws,
    impactCategories,
    impactSubCategories,
    locations,
    odsSelecteds,
    locationsUf,
    project,
    createChip,
    inputs,
    isUploading,
  ]);

  const setProjectInfo = useCallback(() => {
    // console.log('project', project);

    formHook.setValue('id', Number(project.id));
    formHook.setValue('approved_value', Number(project.approvedValue));
    formHook.setValue('work_plan', project.work_plan);
    formHook.setValue('image', project.image);
    formHook.setValue('code', project.code);
    formHook.setValue('commission_percentage', project.commissionPercentage);
    formHook.setValue('analyze_reservations', project.analyzeReservations);
    formHook.setValue('capture_certificate', project.capture_certificate);
    formHook.setValue('official_journal', project.official_journal);
    formHook.setValue('edition', project.edition);
    formHook.setValue('audience_goal', Number(project.audienceGoal));
    formHook.setValue('captured_value', Number(project.capturedValue));
    formHook.setValue('counterpart', project.counterpart);
    formHook.setValue('sponsors', project.sponsors);
    formHook.setValue('previous_sponsors', project.previous_sponsors);
    formHook.setValue('starts_at', project.startsAt);
    formHook.setValue('funding_period', project.fundingPeriod);
    formHook.setValue('funding_period_has_passed', project.fundingPeriodHasPassed);
    formHook.setValue('approval_date', project.approvalDate);
    formHook.setValue('goal', project.goal);
    formHook.setValue('law_id', project.law);
    formHook.setValue('impact_category_id', project.impact_category_id);
    formHook.setValue('impact_subcategory_id', project.impact_subcategory_id);
    formHook.setValue('minimum_value', Number(project.minimum_value));
    formHook.setValue('name', project.name);
    formHook.setValue('more_than_one_city', project.more_than_one_city);
    formHook.setValue('can_change_city', project.can_change_city);
    formHook.setValue('runtime_days', project.runtimeDays);
    formHook.setValue('partially_captured', project.partially_captured);
    formHook.setValue('specific_goal', project.specificGoals);
    formHook.setValue('summary', project.description);
    formHook.setValue('target', project.target);
    formHook.setValue('video', project.video);
    formHook.setValue('sd_goal_ids', project.sd_goals.map(x => x.id));
    formHook.setValue('images', project.images.map(x => x.imagen));
    formHook.setValue('account_opening_commitment', project.account_opening_commitment);
    formHook.setValue('quota', project.quota);
    formHook.setValue('status', project.status);
    formHook.setValue('analysis_status', project.analysis_status);
    formHook.setValue('analysis_compliance_status', project.analysis_compliance_status);
    formHook.setValue('other_informations', project.other_informations);
    formHook.setValue('analyze_reservations', project.analyze_reservations);
    
    formHook.setValue('minimum_value', project.minimum_value);
    formHook.setValue('project_presentation', project.project_presentation);
    formHook.setValue('contract_file', project.contract_file);
    formHook.setValue('opening_receipt', project.opening_receipt);
    formHook.setValue('fund_opening_receipt', project.fund_opening_receipt);
    formHook.setValue('highlights_and_awards', project.highlights_and_awards);
    formHook.setValue('company_names', project.company_names);

    formHook.setValue('auth_edition', project.auth_edition); 
    formHook.setValue('auth_abstract', project.auth_abstract); 
    formHook.setValue('auth_video', project.auth_video); 
    formHook.setValue('auth_counterpart', project.auth_counterpart); 
    formHook.setValue('auth_runtime_days', project.auth_runtime_days); 
    formHook.setValue('auth_change_city', project.auth_change_city); 
    formHook.setValue('auth_more_than_one_city', project.auth_more_than_one_city); 
    formHook.setValue('auth_partially_captured', project.auth_partially_captured); 
    formHook.setValue('auth_schedule', project.auth_schedule); 
    formHook.setValue('auth_contract', project.auth_contract); 
    formHook.setValue('auth_minimum_value', project.auth_minimum_value); 
    formHook.setValue('auth_initial_value', project.auth_initial_value); 
    formHook.setValue('auth_final_value', project.auth_final_value); 
    formHook.setValue('auth_project_presentation', project.auth_project_presentation); 
    formHook.setValue('auth_opening_receipt', project.auth_opening_receipt); 
    formHook.setValue('auth_fund_opening_receipt', project.auth_fund_opening_receipt); 
    formHook.setValue('auth_project_initial_date', project.auth_project_initial_date); 
    formHook.setValue('auth_project_final_date', project.auth_project_final_date); 
    formHook.setValue('auth_project_approved_full', project.auth_project_approved_full); 
    formHook.setValue('auth_official_journal', project.auth_official_journal); 
    formHook.setValue('auth_opening_term', project.auth_opening_term); 
    formHook.setValue('auth_budget_spreadsheet', project.auth_budget_spreadsheet); 
    formHook.setValue('auth_capture_certificate', project.auth_capture_certificate); 
    formHook.setValue('auth_work_plan', project.auth_work_plan); 
    formHook.setValue('auth_audience_goal', project.auth_audience_goal); 
    formHook.setValue('auth_status', project.auth_status); 
    formHook.setValue('auth_name', project.auth_name); 
    formHook.setValue('auth_external_project_id', project.auth_external_project_id); 
    formHook.setValue('auth_approved_value', project.auth_approved_value); 
    formHook.setValue('auth_captured_value', project.auth_captured_value); 
    formHook.setValue('auth_funding_period', project.auth_funding_period); 
    formHook.setValue('auth_starts_at', project.auth_starts_at); 
    formHook.setValue('auth_starts_at', project.auth_starts_at); 
    formHook.setValue('auth_funding_period', project.auth_funding_period); 
    formHook.setValue('auth_location_ids', project.auth_location_ids); 
    formHook.setValue('auth_impact_category_id', project.auth_impact_category_id); 
    formHook.setValue('auth_impact_subcategory_id', project.auth_impact_subcategory_id); 
    formHook.setValue('auth_law_id', project.auth_law_id); 
    formHook.setValue('auth_step_id', project.auth_step_id); 
    formHook.setValue('auth_sd_goal_ids', project.auth_sd_goal_ids); 
    formHook.setValue('auth_problem', project.auth_problem); 
    formHook.setValue('auth_problem_description', project.auth_problem_description); 
    formHook.setValue('auth_goal', project.auth_goal); 
    formHook.setValue('auth_specific_goal', project.auth_specific_goal); 
    formHook.setValue('auth_summary', project.auth_summary); 
    formHook.setValue('auth_roots_problem', project.auth_roots_problem); 
    formHook.setValue('auth_consequences_problem', project.auth_consequences_problem); 
    formHook.setValue('auth_target', project.auth_target); 
    formHook.setValue('auth_sponsors', project.auth_sponsors); 
    formHook.setValue('auth_quota', project.auth_quota); 
    formHook.setValue('auth_project_cover', project.auth_project_cover); 
    formHook.setValue('auth_project_images', project.auth_project_images);
    formHook.setValue('auth_approval_date', project.auth_approval_date);
    formHook.setValue('bank_id', project.bank_id);
    formHook.setValue('agency', project.agency);
    formHook.setValue('account_bank', project.account_bank);
    formHook.setValue('auth_minimum_value', project.auth_minimum_value);
    formHook.setValue('auth_highlights_and_awards', project.auth_highlights_and_awards);
    formHook.setValue('auth_company_names', project.auth_company_names);

    setOdsSelecteds(project.sd_goals.map(item => Number(item.id) - 1));
    formHook.setValue(
      'city',
      project?.location?.map(location => location.city),
    );
    formHook.setValue(
      'ufs',
      project?.location?.map(location => location.uf),
    );
    if (project.locations) {
      const locations_map = project.locations.map(location => `${location.uf} - ${location.city}`);
      formHook.setValue('locations[]', locations_map);
    } else {
      formHook.setValue('locations[]', []);
    }

    formHook.setValue('account_opening_commitment', project.account_opening_commitment);
    formHook.setValue('law_type', project.law_type);
    formHook.setValue('law_coverage', project.law_coverage);
    formHook.setValue('category_law', project.category_law);
    formHook.setValue('law_state', project.law_state);
    formHook.setValue('law_city', project.law_city);
    formHook.setValue('specific_law_id', project.specific_law_id);
    formHook.setValue('retention_percentage', project.retention_percentage);
    
  }, [formHook, project]);

  useEffect(() => {
    if (project) {
      loadProjectFormInfo();
      setProjectInfo();
    }
  }, [project, setProjectInfo, loadProjectFormInfo]);

  useEffect(() => {
    formHook.setValue('specific_goal', inputs.specificGoals);
  }, [inputs]);

  useEffect(() => {
    let specific_goal = { specificGoals: { 0: null } };

    if (project && project.specificGoals) {
      const exploded = project.specificGoals.split('||@||');
      specific_goal.specificGoals = Object.assign({}, exploded);
      setInputs(specific_goal);
    }
  }, []);

  const updateProject = useCallback(async data => {
    setIsUploading(true);
    const form = new FormData();
    const formHookValues = formHook.getValues();

    form.append('name', (formHookValues.name !== undefined) ? formHookValues.name : '');
    form.append('edition', formatNull(formHookValues.edition));
    form.append('code', formatNull(formHookValues.code));
    form.append('commission_percentage', formatNull(formHookValues.commission_percentage));
    form.append('analyze_reservations', formHookValues.analyze_reservations);
    form.append('status', formHookValues.status);
    form.append('analysis_status', formHookValues.analysis_status);
    form.append('law_id', formatNull(formHookValues.law_id));
    form.append('impact_category_id', formatNull(formHookValues.impact_category_id));
    form.append('impact_subcategory_id', formatNull(formHookValues.impact_subcategory_id));
    form.append('target', formatNull(formHookValues.target));
    form.append('audience_goal', Number(formHookValues.audience_goal));
    form.append('runtime_days', formatNull(formHookValues.runtime_days));

    form.append('bank_id', formHookValues.bank_id);
    form.append('agency', formHookValues.agency);
    form.append('account_bank', formHookValues.account_bank);
    //  1
    form.append('summary', formHookValues.summary);
    form.append('other_informations', formatNull(formHookValues.other_informations));
    form.append('goal', formatNull(formHookValues.goal));
    if(formHookValues.specific_goal){
      const formatted_specific_goals =  typeof formHookValues.specific_goal == 'object' ? Object.values(formHookValues.specific_goal).join('||@||') : formHookValues.specific_goal
      form.append(
        'specific_goal',
        formatted_specific_goals,
      );
    }
    //  2
    if (formHookValues.sd_goal_ids) {
      formHookValues.sd_goal_ids.forEach(sd_goal_id => {
        form.append('sd_goal_ids[]', sd_goal_id);
      });
    } else {
      form.append('sd_goal_ids[]', []);
    }
    form.append('more_than_one_city', formatNull(formHookValues.more_than_one_city));
    form.append('can_change_city', formatNull(formHookValues.can_change_city));

    //  3
    if(formHookValues.approved_value !== 0){
      form.append('approved_value', Number(formHookValues.approved_value).toFixed(2));
    }
    form.append('captured_value', Number(formHookValues.captured_value).toFixed(2));
    form.append('starts_at', dateFormattedToDateISO(formHookValues.starts_at));
    if(formHookValues.funding_period){
      form.append('funding_period', formatDateFix(formHookValues.funding_period));
    }
    if(formHookValues.approval_date){
      form.append('approval_date', formatDateFix(formHookValues.approval_date));
    }
    form.append('sponsors', formHookValues.sponsors);
    form.append('previous_sponsors', formHookValues.previous_sponsors);
    form.append('partially_captured', formHookValues.partially_captured);

    if (formHookValues.locations) {
      formHookValues.locations.forEach(locations => {
        form.append('locations[]', locations);
      });
    } 
    // else {
    //   form.append('locations[]', []);
    // }

    //  4
    form.append('video', formHookValues.video);

    if(formHookValues.official_journal && formHookValues.official_journal[0] !== undefined && typeof formHookValues.official_journal[0] !== 'string' && formHookValues.official_journal[0] !== undefined && formHookValues.official_journal[0] !== null){
      form.append(
        'official_journal',
        formHookValues.official_journal[0],
        formHookValues.official_journal[0].name,
      );
    }
    if(formHookValues.official_journal && formHookValues.official_journal[1] !== undefined && typeof formHookValues.official_journal[1] !== 'string' && formHookValues.official_journal[1] !== undefined && formHookValues.official_journal[1] !== null){
      form.append(
        'official_journal2',
        formHookValues.official_journal[1],
        formHookValues.official_journal[1].name,
      );
    }
    if(formHookValues.official_journal && formHookValues.official_journal[2] !== undefined && typeof formHookValues.official_journal[2] !== 'string' && formHookValues.official_journal[2] !== undefined && formHookValues.official_journal[2] !== null){
      form.append(
        'official_journal3',
        formHookValues.official_journal[2],
        formHookValues.official_journal[2].name,
      );
    }
    
    if(formHookValues.capture_certificate !== null && typeof formHookValues.capture_certificate !== 'string' && formHookValues.capture_certificate !== undefined){
      form.append(
        'capture_certificate',
        formHookValues.capture_certificate[0],
        formHookValues.capture_certificate[0].name,
      );
    }
    if(formHookValues.work_plan !== null && typeof formHookValues.work_plan !== 'string' && formHookValues.work_plan !== undefined){
      form.append('work_plan', formHookValues.work_plan[0], formHookValues.work_plan[0].name);
    }
    if(formHookValues.image !== null && typeof formHookValues.image !== 'string' && formHookValues.image !== undefined){
      form.append(
        'image',
        formHookValues.image[0],
        formHookValues.image[0].name,
      );
    }
    if (formHookValues.delete_images) {
      formHookValues.delete_images.forEach(image_name => {
        form.append('delete_images[]', image_name);
      });
    }
    if (formHookValues.delete_official_journal) {
      formHookValues.delete_official_journal.forEach(doc => {
        form.append('delete_official_journal[]', doc);
      });
    }
    if(formHookValues.images && formHookValues.images[0] !== undefined && typeof formHookValues.images[0] !== 'string' && formHookValues.images[0] !== undefined){
      form.append(
        'image1',
        formHookValues.images[0][0],
        formHookValues.images[0][0].name,
      );
    }
    if(formHookValues.images && formHookValues.images[1] !== undefined && typeof formHookValues.images[1] !== 'string' && formHookValues.images[1] !== undefined){
      form.append(
        'image2',
        formHookValues.images[1][0],
        formHookValues.images[1][0].name,
      );
    }
    if(formHookValues.images && formHookValues.images[2] !== undefined && typeof formHookValues.images[2] !== 'string' && formHookValues.images[2] !== undefined){
      form.append(
        'image3',
        formHookValues.images[2][0],
        formHookValues.images[2][0].name,
      );
    }
    if(formHookValues.images && formHookValues.images[3] !== undefined && typeof formHookValues.images[3] !== 'string' && formHookValues.images[3] !== undefined){
      form.append(
        'image4',
        formHookValues.images[3][0],
        formHookValues.images[3][0].name,
      );
    }

    //  5
    // if (formHookValues.counterparts) {
    //   formHookValues.counterparts.forEach(counterpart => {
    //     const array = {
    //       id: counterpart['id'],
    //       quota: counterpart['quota'],
    //       valueOf: counterpart['valueOf'],
    //       valueUpTo: counterpart['valueUpTo'],
    //       counterparts: counterpart['counterparts'],
    //     }
    //     form.append('counterparts[]', JSON.stringify(array));
    //   });
    if (formHookValues.quota) {
      formHookValues.quota.forEach(quota => {
        const array = {
          id: quota['id'] >= 999999 ? null : quota['id'],
          quota: quota['quota'],
          valueOf: quota['valueOf'],
          valueUpTo: quota['valueUpTo'],
          counterparts: quota['counterparts'],
        }
        form.append('counterparts[]', JSON.stringify(array));
      });
    } else {
      form.append('counterparts[]', []);
    }
    if(formHookValues.minimum_value){
      form.append('minimum_value', Number(formHookValues.minimum_value).toFixed(2));
    }
    if(formHookValues.project_presentation !== null && typeof formHookValues.project_presentation !== 'string' && formHookValues.project_presentation !== undefined){
      form.append('project_presentation', formHookValues.project_presentation[0], formHookValues.project_presentation[0].name);
    }
    if(formHookValues.contract_file !== null && typeof formHookValues.contract_file !== 'string' && formHookValues.contract_file !== undefined){
      form.append('contract_file', formHookValues.contract_file[0], formHookValues.contract_file[0].name);
    }
    form.append('highlights_and_awards', formHookValues.highlights_and_awards);
    form.append('company_names', formHookValues.company_names);
    if(formHookValues.delete_project_presentation){
      form.append('delete_project_presentation', formHookValues.delete_project_presentation);
    }
    if(formHookValues.opening_receipt !== null && typeof formHookValues.opening_receipt !== 'string' && formHookValues.opening_receipt !== undefined){
      form.append('opening_receipt', formHookValues.opening_receipt[0], formHookValues.opening_receipt[0].name);
    }
    if(formHookValues.delete_opening_receipt){
      form.append('delete_opening_receipt', formHookValues.delete_opening_receipt);
    }

    if(formHookValues.fund_opening_receipt !== null && typeof formHookValues.fund_opening_receipt !== 'string' && formHookValues.fund_opening_receipt !== undefined){
      form.append('fund_opening_receipt', formHookValues.fund_opening_receipt[0], formHookValues.fund_opening_receipt[0].name);
    }
    if(formHookValues.delete_fund_opening_receipt){
      form.append('delete_fund_opening_receipt', formHookValues.delete_fund_opening_receipt);
    }
    if(formHookValues.delete_capture_certificate){
      form.append('delete_capture_certificate', formHookValues.delete_capture_certificate);
    }
    if(formHookValues.delete_work_plan){
      form.append('delete_work_plan', formHookValues.delete_work_plan);
    }
    if(formHookValues.analysis_compliance_status || formHookValues.analysis_compliance_status == 0){
      form.append('analysis_compliance_status', formHookValues.analysis_compliance_status);
    }

    form.append('auth_edition', formHookValues.auth_edition ? 1 : 0); 
    form.append('auth_abstract', formHookValues.auth_abstract ? 1 : 0); 
    form.append('auth_video', formHookValues.auth_video ? 1 : 0); 
    form.append('auth_counterpart', formHookValues.auth_counterpart ? 1 : 0); 
    form.append('auth_runtime_days', formHookValues.auth_runtime_days ? 1 : 0); 
    form.append('auth_change_city', formHookValues.auth_change_city ? 1 : 0); 
    form.append('auth_more_than_one_city', formHookValues.auth_more_than_one_city ? 1 : 0); 
    form.append('auth_partially_captured', formHookValues.auth_partially_captured ? 1 : 0); 
    form.append('auth_schedule', formHookValues.auth_schedule ? 1 : 0); 
    form.append('auth_contract', formHookValues.auth_contract ? 1 : 0); 
    form.append('auth_minimum_value', formHookValues.auth_minimum_value ? 1 : 0); 
    form.append('auth_initial_value', formHookValues.auth_initial_value ? 1 : 0); 
    form.append('auth_final_value', formHookValues.auth_final_value ? 1 : 0); 
    form.append('auth_project_presentation', formHookValues.auth_project_presentation ? 1 : 0); 
    form.append('auth_opening_receipt', formHookValues.auth_opening_receipt ? 1 : 0); 
    form.append('auth_fund_opening_receipt', formHookValues.auth_fund_opening_receipt ? 1 : 0); 
    form.append('auth_project_initial_date', formHookValues.auth_project_initial_date ? 1 : 0); 
    form.append('auth_project_final_date', formHookValues.auth_project_final_date ? 1 : 0); 
    form.append('auth_project_approved_full', formHookValues.auth_project_approved_full ? 1 : 0); 
    form.append('auth_official_journal', formHookValues.auth_official_journal ? 1 : 0); 
    form.append('auth_opening_term', formHookValues.auth_opening_term ? 1 : 0); 
    form.append('auth_budget_spreadsheet', formHookValues.auth_budget_spreadsheet ? 1 : 0); 
    form.append('auth_capture_certificate', formHookValues.auth_capture_certificate ? 1 : 0); 
    form.append('auth_work_plan', formHookValues.auth_work_plan ? 1 : 0); 
    form.append('auth_audience_goal', formHookValues.auth_audience_goal ? 1 : 0); 
    form.append('auth_status', formHookValues.auth_status ? 1 : 0); 
    form.append('auth_name', formHookValues.auth_name ? 1 : 0); 
    form.append('auth_external_project_id', formHookValues.auth_external_project_id ? 1 : 0); 
    form.append('auth_approved_value', formHookValues.auth_approved_value ? 1 : 0); 
    form.append('auth_captured_value', formHookValues.auth_captured_value ? 1 : 0); 
    form.append('auth_funding_period', formHookValues.auth_funding_period ? 1 : 0); 
    form.append('auth_starts_at', formHookValues.auth_starts_at ? 1 : 0); 
    form.append('auth_funding_period', formHookValues.auth_funding_period ? 1 : 0); 
    form.append('auth_location_ids', formHookValues.auth_location_ids ? 1 : 0); 
    form.append('auth_impact_category_id', formHookValues.auth_impact_category_id ? 1 : 0); 
    form.append('auth_impact_subcategory_id', formHookValues.auth_impact_subcategory_id ? 1 : 0); 
    form.append('auth_law_id', formHookValues.auth_law_id ? 1 : 0); 
    form.append('auth_step_id', formHookValues.auth_step_id ? 1 : 0); 
    form.append('auth_sd_goal_ids', formHookValues.auth_sd_goal_ids ? 1 : 0); 
    form.append('auth_problem', formHookValues.auth_problem ? 1 : 0); 
    form.append('auth_problem_description', formHookValues.auth_problem_description ? 1 : 0); 
    form.append('auth_goal', formHookValues.auth_goal ? 1 : 0); 
    form.append('auth_specific_goal', formHookValues.auth_specific_goal ? 1 : 0); 
    form.append('auth_summary', formHookValues.auth_summary ? 1 : 0); 
    form.append('auth_roots_problem', formHookValues.auth_roots_problem ? 1 : 0); 
    form.append('auth_consequences_problem', formHookValues.auth_consequences_problem ? 1 : 0); 
    form.append('auth_target', formHookValues.auth_target ? 1 : 0); 
    form.append('auth_sponsors', formHookValues.auth_sponsors ? 1 : 0); 
    form.append('auth_quota', formHookValues.auth_quota ? 1 : 0); 
    form.append('auth_project_cover', formHookValues.auth_project_cover ? 1 : 0); 
    form.append('auth_project_images', formHookValues.auth_project_images ? 1 : 0);
    form.append('auth_highlights_and_awards', formHookValues.auth_highlights_and_awards ? 1 : 0);
    form.append('auth_company_names', formHookValues.auth_company_names ? 1 : 0);
    form.append('auth_approval_date', formHookValues.auth_approval_date ? 1 : 0);
    
    form.append('account_opening_commitment', formatNull(formHookValues.account_opening_commitment));
    form.append('law_type', formatNull(formHookValues.law_type));
    form.append('law_coverage', formatNull(formHookValues.law_coverage));
    form.append('category_law', formatNull(formHookValues.category_law));
    form.append('law_state', formatNull(formHookValues.law_state));
    form.append('law_city', formatNull(formHookValues.law_city));
    form.append('specific_law_id', formatNull(formHookValues.specific_law_id));
    form.append('retention_percentage', formHookValues.retention_percentage);

    try {
      const response = await ProjectService.updateProject(project.id, form);
      console.log('response', response)
      if (response.status === 201) {
        toast.success('Projeto salvo com sucesso!');
        setShowModal(true);
        saveProject(response.data)
      } else {
        orderFeedbackErrorMessage(response.data);
        // setShowModalIncomplete(true);
      }
    } catch (err) {
      console.log('err', err);
      if (err.response) {
        // setShowModalIncomplete(true);
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao salvar projeto');
      }
    }
    setIsUploading(false);
    
  }, []);


  return (
    <Container style={{ width: '69%' }}>
      <FormProvider {...formHook}>
        {renderStep()}
        {showModalIncomplete && (
          <ProjectCreatedIncompletedModal
            open={showModalIncomplete}
            handleClose={handleCloseIncomplete}
          />
        )}
        {showChangeProponentModal && (
          <ChangeProponentModal
            open={true}
            onChange={(proponent_id) => {
              updateProponent({ proponent_id })
              loadProjectInfo()
            }}
            onClose={() => setShowChangeProponentModal(!showChangeProponentModal)}
          />
        )}
        <Footer
          activeStep={activeStep}
          handleNext={value => {
            updateProject();
            if(activeStep >= 6 || (activeStep === 5 && isGeneral)){
              toast.success('Projeto analisado com sucesso!');
              push('/admin/projetos');
            } else {
              handleNext();
            }
          }}
          // handleStep={handleStep}
          handlePrevious={handlePrevious}
          handleSave={value => {
            updateProject();
            // toast.success('Projeto salvo com sucesso!');
            // push('/admin/projetos');
          }}
          projectStatus={project.status}
          proponentId={project.proponent_id}
          formHook={formHook}
          setShowChangeProponentModal={setShowChangeProponentModal}
          loadProjectInfo={loadProjectInfo}
         />
      </FormProvider>
    </Container>
  );
};

export default ProjectForm;