import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 1100px;

  h6 + h6 {
    margin-top: 1rem;
  }

  @media (max-width: 1700px) {
    max-width: 900px;
  }
  @media (max-width: 1550px) {
    max-width: 52vw;
  }

  @media (max-width: 1450px) {
    max-width: 49vw;
  }

  @media (max-width: 1370px) {
    max-width: 44vw;
  }

  @media (max-width: 1250px) {
    max-width: 40vw;
  }

  @media (max-width: 960px){
    max-width: 100%;
    width: 100%;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  ${({ colSize }) => css`
    grid-template-columns: repeat(${colSize}, 1fr);
  `}

  @media (max-width: 1700px) {
    ${({ colSize }) => css`
      grid-template-columns: repeat(${Number(Math.ceil(colSize/2))}, 1fr);
    `}
  }

  @media (max-width: 1350px){
    display: block;
    grid-template-columns: auto;
  }
`;
export const DocumentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    margin-top: 0.5rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;
