import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { groupBy } from 'utils/query';
import { translateAuthProponentData } from 'utils/translate';
import PendenciesFieldItem from './PendenciesFieldItem';

import { Container } from './styles';

const PendenciesFields = () => {
  const [reviewItems, setReviewItems] = useState({});
  const { getValues } = useFormContext();

  useEffect(() => {
    const keys = Object.keys(getValues());
    var authKeys = keys.filter(key => key.includes('auth_'));
    const data = [];
    const rules = ["false", null, "null"];
    const legalPerson = getValues('legalPerson');
    var arr = [];

    //  Removendo os campos de diligência que não precisam ser 
    //  verificados de acordo com perfil da pessoa (física ou jurídica)
    if(legalPerson === 'Pessoa física'){
      arr = [
        'auth_corporate_name',
        'auth_name_responsible',
        'auth_cpf_responsible',
        'auth_phone_responsible',
        'auth_email_responsible',
        'auth_social_contract',
        'auth_last_election',
        'auth_social_status',
        'auth_cnpj',
      ];
    } else if(legalPerson === 'Entidade Empresarial') {
      arr = [
        'auth_full_name',
        'auth_email',
        'auth_phone',
        'auth_cpf',
        'auth_last_election',
        'auth_social_status',
      ];
    } else {
      arr = [
        'auth_full_name',
        'auth_email',
        'auth_phone',
        'auth_cpf',
        'auth_social_contract',
      ];
    }
    authKeys = authKeys.filter(e => !arr.includes(e))

    authKeys.forEach(item => {
      if ((!getValues(item) || rules.includes(getValues(item))) && getValues(item) !== undefined) {
        data.push({
          item,
          value: getValues(item),
          translate: translateAuthProponentData[item]?.text,
          step: translateAuthProponentData[item]?.step.id,
          stepTitle: translateAuthProponentData[item]?.step.text,
        });
      }
    });

    console.log(data);

    setReviewItems(groupBy(data, 'step'));

  }, [getValues]);

  return (
    <Container>
      {
        Object.keys(reviewItems).length === 0 ? (
          <PendenciesFieldItem
            title="Nenhum campo foi marcado como “revisar”"
          />
        ) : (
          Object.keys(reviewItems).map(item => (
            <PendenciesFieldItem
              key={item}
              step={item}
              items={reviewItems[item].map(reviewItem => reviewItem.translate)}
            />
          ))
        )
      }
      
    </Container>
  );
};

export default PendenciesFields;