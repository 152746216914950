import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { pixelsToRem } from 'utils/styles';

const renderSize = {
  small: css`
    padding: ${pixelsToRem(4)} ${pixelsToRem(12)} !important;
    font-size: 12px !important;
  `,
  medium: css`
    padding: ${pixelsToRem(6)} ${pixelsToRem(22)} !important;
    font-size: 16px !important;
  `,
  large: css`
    padding: ${pixelsToRem(10)} ${pixelsToRem(26)} !important;
    font-size: 16px !important;
  `,
};

export const Container = styled(Button)`
  border-radius: ${pixelsToRem(30)} !important;
  line-height: ${pixelsToRem(24)} !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.5rem;
  }

  &.squared {
    border-radius: 8px !important;
  }

  ${({ buttonsize }) => renderSize[buttonsize]}
`;
