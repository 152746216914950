import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 6px;
  padding: 2rem 1.5rem;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const Form = styled.form`
  margin-top: 1.25rem;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Content = styled.div`
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1300px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;
