import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerModal, Title, Row, Footer, Container } from './styles';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import { deleteProjectBidder as changeModalStatus } from 'store/ducks/modals/actions';

const DeleteProject = () => {

  const dispatch = useDispatch();
  const deleteProjectBidder = useSelector(state => state.modals.vitrineModals.deleteProjectBidder);
  const handleClose = () => {
    dispatch(changeModalStatus());
  }

  const handleDelete = () => {
    handleClose();
  }

  return (
    <Container
      open={deleteProjectBidder}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        }
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Title>
          <Typography variant="h4">Tem certeza que deseja excluir esse projeto?</Typography>
        </Title>
        <Row>
          <Typography>
            Essa ação não pode ser desfeita. Após a exclusão, os dados referentes ao projeto serão perdidos. 
          </Typography>
        </Row>
        <Footer>
          <Button variant="tertiary" onClick={handleClose}>Voltar</Button>
          <Button variant="tertiary" onClick={handleDelete}>Excluir</Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
}
export default DeleteProject;