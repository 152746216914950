import React from 'react';
import Typography from 'components/Typography';
import { Container, Header, IconStep, Body } from './styles';

const StepItem = ({ number, active, title, content, onClick, error }) => {
  return (
    <Container>
      <Header>
        <IconStep active={active} onClick={() => onClick(number)} error={error}>
          <Typography variant="caption" color="white">{number}</Typography>
        </IconStep>
        <Typography variant="body2">{title}</Typography>
      </Header>
      <Body className={!content && "step-content"}>
        <Typography variant="body2" color="grayDark2">{content}</Typography>
      </Body>
    </Container>
  );
};

export default StepItem;