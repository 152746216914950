import styled from 'styled-components';

export const variantMapping = {
  h1: styled.h1`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.h1.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.h1.fontWeight};
    margin: 0;
  `,
  h2: styled.h2`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.h2.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.h2.fontWeight};
    margin: 0;
  `,
  h3: styled.h3`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.h3.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.h3.fontWeight};
    margin: 0;
  `,
  h4: styled.h4`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.h4.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.h4.fontWeight};
    margin: 0;
  `,
  h5: styled.h5`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.h5.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.h5.fontWeight};
    margin: 0;
  `,
  h6: styled.h6`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.h6.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.h6.fontWeight};
    margin: 0;
  `,
  subtitle1: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.subtitle1.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.subtitle1.fontWeight};
    margin: 0;
  `,
  subtitle2: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.subtitle2.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.subtitle2.fontWeight};
    margin: 0;
  `,
  body1: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.body1.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.body1.fontWeight};
    margin: 0;
  `,
  body2: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.body2.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.body2.fontWeight};
    margin: 0;
  `,
  manrope: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.manrope.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.manrope.fontWeight};
  `,
  caption: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.caption.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.caption.fontWeight};
    margin: 0;
  `,
  overline: styled.p`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${props => props.theme.typographyNew.overline.fontSize};
    font-family: ${props => props.theme.typographyNew.fontFamily};
    font-weight: ${props => props.theme.typographyNew.overline.fontWeight};
    margin: 0;
  `,
};
