import React, { useCallback, useEffect } from 'react';
import Typography from 'components/Typography';

import { 
  Container, 
  InfoTextContainer, 
  Content, 
  Row, 
  CheckboxContainer,
  FormGroup,
  ShowFiles,
  RowImageAndVideos,
  Link,
} from './styles';
import Filename from 'components/Filename';
import Input from 'components/Input';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { useState, useRef } from 'react';
import { Checkbox, Tooltip } from '@material-ui/core';
import FileInput from 'components/FileInput';
import { useUser } from 'hooks/useUser';
import { WarningOutlined } from '@material-ui/icons';
import { validateFileExtension, validateFileSize } from 'utils/validation';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import './style.css';
import DragAndDrop from 'components/DragAndDrop';


const Step4 = ({ images , handleSave, changedFields, setChangedFields, isUploading }) => {
  const { control, setValue, setError, getValues, formState: { errors } } = useFormContext();
  const [authVideo, setAuthVideo] = useState(getValues('auth_video'));
  const [acceptCommitment, setAcceptCommitment] = useState(false);
  // const [acceptCommitment, setAcceptCommitment] = useState(false);
  const [authProjectimage, setAuthProjectimage] = useState(getValues('auth_project_cover'));
  const [authProjectImages, setAuthProjectImages] = useState(getValues('auth_project_images'));
  const [authOfficialJournal, setAuthOfficialJournal] = useState(getValues('auth_official_journal'));
  const [authCaptureCertificate, setAuthCaptureCertificate] = useState(getValues('auth_capture_certificate'));
  const [authWorkPlan, setAuthWorkPlan] = useState(getValues('auth_work_plan'));
  const [checkboxState, setCheckboxState] = useState(false);
  const [arrayFiles, setArrayFiles] = useState([]);
  const [deleteArrayFiles, setdeleteArrayFiles] = useState([]);
  const [arrayOfficialJournal, setArrayOfficialJournal] = useState([]);
  const [deleteArrayOfficialJournal, setdeleteArrayOfficialJournal] = useState([]);
  
  const [authProjectPresentation, setAuthProjectPresentation] = useState(getValues('auth_project_presentation'));
  const [projectPresentation, setProjectPresentation] = useState(getValues('project_presentation'));
  const [authOpeningReceipt, setAuthOpeningReceipt] = useState(getValues('auth_opening_receipt'));
  const [openingReceipt, setOpeningReceipt] = useState(getValues('opening_receipt'));

  const [authFundOpeningReceipt, setAuthFundOpeningReceipt] = useState(getValues('auth_fund_opening_receipt'));
  const [fundOpeningReceipt, setFundOpeningReceipt] = useState(getValues('fund_opening_receipt'));
  const law_id = getValues('law_id');
  const validFund = fundOpeningReceipt && law_id > 10 ? true : false;
  const validOpening = openingReceipt && law_id <= 10 ? true : false;

  const mounted_checkbox = useRef();
  const account_opening_commitment = getValues('account_opening_commitment');

  const [deleteJournalName, setDeleteJournalName] = useState(false);
  const [deleteCaptureName, setDeleteCaptureName] = useState(false);
  const [deleteWorkName, setDeleteWorkName] = useState(false);

  const [changeAuthImage, setChangeAuthImage] = useState(changedFields.image);
  changeAuthImage && setValue("auth_project_cover", !changeAuthImage);
  const [changeAuthVideo, setChangeAuthVideo] = useState(changedFields.video);
  changeAuthVideo && setValue("auth_video", !changeAuthVideo);
  const [changeAuthProjectPresentation, setChangeAuthProjectPresentation] = useState(changedFields.project_presentation);
  changeAuthProjectPresentation && setValue("auth_project_presentation", !changeAuthProjectPresentation);
  const [changeAuthOfficialJournal, setChangeAuthOfficialJournal] = useState(changedFields.official_journal);
  changeAuthOfficialJournal && setValue("auth_official_journal", !changeAuthOfficialJournal);
  const [changeAuthCaptureCertificate, setChangeAuthCaptureCertificate] = useState(changedFields.capture_certificate);
  changeAuthCaptureCertificate && setValue("auth_capture_certificate", !changeAuthCaptureCertificate);
  const [changeAuthWorkPlan, setChangeAuthWorkPlan] = useState(changedFields.work_plan);
  changeAuthWorkPlan && setValue("auth_work_plan", !changeAuthWorkPlan);
  const [changeAuthOpeningReceipt, setChangeAuthOpeningReceipt] = useState(changedFields.opening_receipt);
  changeAuthOpeningReceipt && setValue("auth_opening_receipt", !changeAuthOpeningReceipt);

  const { user } = useUser();
  const roles = [
    'Em rascunho',
    'Em ajuste',
    'Aguardando regularização'
  ];
  let isDisabled = false;
  const project_status = getValues('status');
  const theme = useTheme();
  
  if((user.adminRole === 'cs_proponent1' || user.adminRole === 'cs_proponent2' || user.adminRole === 'cs_proponent_relationship') && !roles.includes(project_status)){
    isDisabled = true;
  } else if((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
    isDisabled = true;
  } else if(user.adminRole === 'general' || user.adminRole === 'monitore') {
    isDisabled = true;
  }

  const handleChangeCheckboxState = useCallback(() => {
    const account_opening_commitment = checkboxState ? 0 : 1;
    setValue('account_opening_commitment', account_opening_commitment);
    setCheckboxState(prevState => !prevState);
  }, [checkboxState, setValue]);

  const handleDeleteFile = useCallback(
    (name, uploadedName) => {
      setArrayFiles(arrayFiles.filter((file , index) => file[0].name !== name && index !== name));
      // const imagesArray = getValues('images');
      setdeleteArrayFiles([...deleteArrayFiles, uploadedName])
      // imagesArray.splice(name);
      // setValue('images', imagesArray)
    },
    [arrayFiles, deleteArrayFiles],
  );

  const handleDeleteOfficialJournal = useCallback(
    (name, uploadedName) => {
      setArrayOfficialJournal(arrayOfficialJournal.filter((file , index) => file.name !== name && index !== name));
      setdeleteArrayOfficialJournal([...deleteArrayOfficialJournal, uploadedName])
    },
    [arrayOfficialJournal, deleteArrayOfficialJournal],
  );

  const handleAddFiles = useCallback(
    (files, title) => {
      if (arrayFiles.length < 4) {
        setValue(title, [...arrayFiles, files]);
        setArrayFiles([...arrayFiles, files]);
      }
    },
    [arrayFiles, setValue],
  );

  const handleAddOfficialJournal = useCallback(
    (files, title) => {
      if (arrayOfficialJournal.length < 3) {
        setValue(title, [...arrayOfficialJournal, ...files]);
        setArrayOfficialJournal([...arrayOfficialJournal, ...files]);
      }
    },
    [arrayOfficialJournal, setValue],
  );

  useEffect(() => {
    if (!mounted_checkbox.current) {
      setCheckboxState(account_opening_commitment);
      mounted_checkbox.current = true;
      
      const images = getValues('images');
      if(Array.isArray(images) && images.length > 0){
        setValue('images', [...arrayFiles, ...images])
        setArrayFiles([...arrayFiles, ...images])
      }

      let official_journal = getValues('official_journal');
      if(Array.isArray(official_journal) && official_journal.length > 0){
        official_journal = official_journal.filter(n => n);
        setValue('official_journal', [...arrayOfficialJournal, ...official_journal])
        setArrayOfficialJournal([...arrayOfficialJournal, ...official_journal])
      }
    }
    setValue('official_journal', [...arrayOfficialJournal]);
    setValue('delete_images', deleteArrayFiles)
    setValue('delete_official_journal', deleteArrayOfficialJournal)
  }, [arrayFiles, setValue, arrayOfficialJournal]);

  const image = getValues('image');
  const capture_certificate = getValues('capture_certificate');
  const work_plan = getValues('work_plan');
  const project_presentation = getValues('project_presentation');
  const opening_receipt = getValues('opening_receipt');
  const fund_opening_receipt = getValues('fund_opening_receipt');

  //  Se for um arquivo que acabou de ser inserido é do tipo objeto, se for um q já tava no banco
  //  pega o link como string
  const image_name = typeof image === 'object' && image !== null ? image[0].name : image;
  const capture_certificate_name = typeof capture_certificate === 'object' && capture_certificate !== null ? capture_certificate[0].name : capture_certificate;
  const work_plan_name = typeof work_plan === 'object' && work_plan !== null ? work_plan[0].name : work_plan;
  const project_presentation_name = typeof project_presentation === 'object' && project_presentation !== null ? project_presentation[0].name : project_presentation;
  const opening_receipt_name = typeof opening_receipt === 'object' && opening_receipt !== null ? opening_receipt[0].name : opening_receipt;
  const fund_opening_receipt_name = typeof fund_opening_receipt === 'object' && fund_opening_receipt !== null ? fund_opening_receipt[0].name : fund_opening_receipt;
  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Vídeo, imagens e documentos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1" className="subtitle">
          Faça o upload das imagens e da documentação que permita comprovar a autenticidade do seu projeto.
          Em caso de dúvidas, confira <Link href={'https://www.youtube.com/watch?v=T50XTBCpJnM'} rel="noreferrer" target="_blank">este vídeo</Link> que explica mais sobre as documentações necessárias que
          devem ser anexadas nesta etapa!
        </Typography>
      </InfoTextContainer>
      <Content>
        <RowImageAndVideos>
          <div>
            <InfoTextContainer>
              <Typography variant="h6" color="grayDark2">
                Imagens
              </Typography>
              <Typography variant="subtitle2" color="grayLight1" className="subtitle">
                Utilize imagens para representar o seu projeto
              </Typography>
            </InfoTextContainer>

            <AdminFormGroup
              checked={changeAuthImage ? false : authProjectimage}
              onChange={value => {
                setAuthProjectimage(value);
                setValue("auth_project_cover", value);
                setChangeAuthImage(!changeAuthImage);
                value && setChangedFields({
                  ...changedFields,
                  project_presentation: changeAuthProjectPresentation,
                  official_journal: changeAuthOfficialJournal,
                  capture_certificate: changeAuthCaptureCertificate,
                  work_plan: changeAuthWorkPlan,
                  opening_receipt: changeAuthOpeningReceipt,
                  image: false,
                });
              }}
            >
              {/* *Obrigatório */}
              <FormGroup>
                <Typography variant="body1" color="brown" className="body-title">
                  Capa do projeto - Deve ser no formato JPG e PNG e ter no max 2MB *Obrigatório
                </Typography>
                <div>
                  {
                    (changedFields.image && changeAuthImage) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                      </Tooltip>
                    )
                  }
                  <DragAndDrop 
                    placeholder="nenhum arquivo enviado"
                    onChange={files => {
                      if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                        return setError("image", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato jpg ou png." });
                      if(!validateFileSize(files, 2))
                        return setError("image", { message: "O tamanho do arquivo não é compatível. Por favor, insira uma imagem com até 2MB." });
      
                      setValue('image', files);
                      setError("image", null);
                      handleSave();
                    }}
                    disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                    isUploading={isUploading}
                    error={errors["image"]?.message}
                  />
                </div>
                {image_name &&
                  <ShowFiles>
                    <Filename
                      label={'Imagem de capa'}
                      handleDelete={() => {
                        setValue('delete_image', true)
                        setValue('image', null)
                        // setProjectPresentation(null)
                      }}
                      link={image_name}
                      disabled={true}
                    />
                  </ShowFiles>
                }
              </FormGroup>
            </AdminFormGroup>

            <AdminFormGroup
              checked={authProjectImages}
              onChange={value => {
                setAuthProjectImages(value);
                setValue("auth_project_images", value);
              }}
            >
              <FormGroup>
                <Typography variant="body1" color="brown" className="body-title">
                  Adicione até 3 imagens sobre o seu projeto, com formatos JPG ou PNG e com tamanho max. de 2MB
                </Typography>
                <DragAndDrop
                  placeholder="nenhum arquivo enviado"
                  empty={arrayFiles.length === 0}
                  onChange={files => {
                    if(files.length + arrayFiles.length > 3)
                      return setError("images", { message: "Adicione no máximo 3 imagens." });
                    if(!validateFileExtension(files, ["image/png", "image/jpeg"]))
                      return setError("images", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato jpg ou png." });
                    if(!validateFileSize(files, 2))
                      return setError("images", { message: "Tamanho de arquivo incompatível. Por favor, insira imagens com até 2MB." });

                    handleAddFiles(files, 'images');
                    setError("images", null);
                    handleSave();
                  }}
                  disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                  isUploading={isUploading}
                  error={errors["images"]?.message}
                />
              </FormGroup>
              <ShowFiles>
                <div className='multiple-images'>
                  {arrayFiles.length > 0 &&
                    arrayFiles.map((item , index) => (
                      <Filename
                        label={item[0].name ?? 'Imagem ' + index}
                        handleDelete={() => handleDeleteFile(item[0].name ?? index, item)}
                        link={!item[0].name ? item : null}
                        disabled={isDisabled}
                      />
                    ))}
                </div>
              </ShowFiles>
            </AdminFormGroup>
          </div>

          <div>
            <InfoTextContainer>
              <Typography variant="h6" color="grayDark2">
                Vídeos
              </Typography>
              <Typography variant="subtitle2" color="grayLight1" className="subtitle">
                Insira um link de vídeo que melhor explica o seu projeto!
              </Typography>
            </InfoTextContainer>

            <AdminFormGroup
              checked={changeAuthVideo ? false : authVideo}
              onChange={value => {
                setAuthVideo(value);
                setValue("auth_video", value);
                setChangeAuthVideo(!changeAuthVideo);
                value && setChangedFields({
                  ...changedFields,
                  project_presentation: changeAuthProjectPresentation,
                  official_journal: changeAuthOfficialJournal,
                  capture_certificate: changeAuthCaptureCertificate,
                  work_plan: changeAuthWorkPlan,
                  opening_receipt: changeAuthOpeningReceipt,
                  image: changeAuthImage,
                  video: false,
                });
              }}
            >
              <FormGroup>
                <Typography variant="body1" color="brown" className="body-title">
                  Link de vídeo do Vimeo ou YouTube que melhor explica o seu projeto!
                </Typography>
                <Controller
                  name="video"
                  control={control}
                  render={({ field }) => (
                    <div>
                      {
                        (changedFields.video && changeAuthVideo) && (
                          <Tooltip title="Campo alterado pelo proponente" placement="top">
                            <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                          </Tooltip>
                        )
                      }
                      <Input
                        placeholder="Inserir link"
                        {...field}
                        onChange={e => setValue('video', e.target.value)}
                        value={getValues('video')}
                        disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                      />
                    </div>
                  )}
                />
              </FormGroup>
            </AdminFormGroup>

            <AdminFormGroup
                checked={changeAuthProjectPresentation ? false : authProjectPresentation}
                onChange={value => {
                  setAuthProjectPresentation(value);
                  setValue("auth_project_presentation", value);
                  setChangeAuthProjectPresentation(!changeAuthProjectPresentation);
                  value && setChangedFields({
                    ...changedFields,
                    project_presentation: false,
                    official_journal: changeAuthOfficialJournal,
                    capture_certificate: changeAuthCaptureCertificate,
                    work_plan: changeAuthWorkPlan,
                    opening_receipt: changeAuthOpeningReceipt,
                    image: changeAuthImage,
                    video: changeAuthVideo,
                  });
                }}
              >
                <FormGroup>
                  <Typography variant="body1" color="brown" className="body-title">
                    Apresentação do projeto - Deve ser no formato PDF e ter no max 7MB
                    <InfoIcon data-for="tooltip2" data-tip="Insira aqui uma apresentação criativa do seu projeto." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
                  </Typography>
                  <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
                  <div>
                    {
                      (changedFields.project_presentation && changeAuthProjectPresentation) && (
                        <Tooltip title="Campo alterado pelo proponente" placement="top">
                          <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                        </Tooltip>
                      )
                    }
                    <DragAndDrop
                      placeholder="nenhum arquivo enviado"
                      onChange={files => {
                        if(!validateFileExtension(files, ["application/pdf"]))
                          return setError("project_presentation", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                        if(!validateFileSize(files, 7))
                          return setError("project_presentation", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
      
                        setValue('project_presentation', files)
                        setError("project_presentation", null);
                        handleSave();
                      }}
                      disabled={user.adminRole === 'cs_proponent_relationship' ? false : isDisabled}
                      isUploading={isUploading}
                      error={errors["project_presentation"]?.message}
                    />
                  </div>
                </FormGroup>
                {project_presentation_name &&
                  <ShowFiles>
                    <Filename
                      label={'Arquivo de apresentação'}
                      handleDelete={() => {
                        setValue('delete_project_presentation', true)
                        setValue('project_presentation', null)
                        setProjectPresentation(null)
                      }}
                      link={project_presentation_name}
                      disabled={isDisabled}
                    />
                  </ShowFiles>
                }
            </AdminFormGroup>
          </div>

        </RowImageAndVideos>
      </Content>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Documentos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1" className="subtitle">
          Anexe os documentos abaixo fazendo o upload dos arquivos, respeitando o formato 
          e tamanho indicado nos campos. Caso os arquivos sejam maiores que o permitido, confira 
          <Link href={'https://site.incentiv.me/wp-content/uploads/2020/12/passo_a_passo_ilovepdf_2020.pdf'} 
          rel="noreferrer" target="_blank"> aqui um passo a passo </Link> de como comprimi-los.
        </Typography>
      </InfoTextContainer>
      <Content>
        <RowImageAndVideos>
          <div>
            <AdminFormGroup
              checked={changeAuthOfficialJournal ? false : authOfficialJournal}
              onChange={value => {
                setAuthOfficialJournal(value);
                setValue("auth_official_journal", value);
                setChangeAuthOfficialJournal(!changeAuthOfficialJournal);
                value && setChangedFields({
                  ...changedFields,
                  project_presentation: changeAuthProjectPresentation,
                  official_journal: false,
                  capture_certificate: changeAuthCaptureCertificate,
                  work_plan: changeAuthWorkPlan,
                  opening_receipt: changeAuthOpeningReceipt,
                  image: changeAuthImage,
                  video: changeAuthVideo,
                });
              }}
            >
              <FormGroup>
                <Typography variant="body1" color="brown" className="body-title">
                Publicação diário oficial e/ou prorrogação (Formatos aceitos: PDF, JPG ou PNG c/ limite de 1 MB por arquivo sendo no máx. 2 arquivos)
                <InfoIcon 
                  data-for="tooltip3" 
                  data-tip="O Diário Oficial é o documento que dá publicidade ao projeto e é emitido pelo Municipio (se a aprovação foi por lei municipal), Estado (se a aprovação foi por lei estadual) ou União (se a aprovação foi por lei federal)." 
                  multiline={true} 
                  data-html={true} 
                  style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
                </Typography>
                <ReactTooltip id="tooltip3" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
                <div>
                  {
                    (changedFields.official_journal && changeAuthOfficialJournal) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                      </Tooltip>
                    )
                  }
                  <DragAndDrop
                    placeholder="nenhum arquivo enviado"
                    empty={arrayOfficialJournal.length === 0}
                    onChange={files => {
                      if(files.length + arrayOfficialJournal.length > 2)
                        return setError("official_journal", { message: "Adicione no máximo 2 arquivos."});
                      if(!validateFileExtension(files, ["application/pdf", "image/png", "image/jpeg"]))
                        return setError("official_journal", { message: "Este formato não é compatível. Por favor, insira apenas arquivos com formato pdf, jpg ou png" });
                      if(!validateFileSize(files, 1))
                        return setError("official_journal", { message: "Tamanho de arquivo incompatível. Por favor, insira arquivos com até 1MB." });
                      
                      handleAddOfficialJournal(files, 'official_journal')
                      setError("official_journal", null);
                      handleSave();
                    }}
                    disabled={isDisabled}
                    multiple={true}
                    isUploading={isUploading}
                    error={errors["official_journal"]?.message}
                  />
                </div>
              </FormGroup>
              <ShowFiles>
                <div className='multiple-images'>
                  {arrayOfficialJournal.length > 0 &&
                    arrayOfficialJournal.map((item , index) => (
                      <Filename
                        label={item ? 'Arquivo ' + index : null}
                        handleDelete={() => handleDeleteOfficialJournal(item.name ?? index, item)}
                        link={item ? item : null}
                        disabled={isDisabled}
                      />
                    ))}
                </div>
              </ShowFiles>
            </AdminFormGroup>

            <AdminFormGroup
              checked={changeAuthCaptureCertificate ? false : authCaptureCertificate}
              onChange={value => {
                setAuthCaptureCertificate(value);
                setValue("auth_capture_certificate", value);
                setChangeAuthCaptureCertificate(!changeAuthCaptureCertificate);
                value && setChangedFields({
                  ...changedFields,
                  project_presentation: changeAuthProjectPresentation,
                  official_journal: changeAuthOfficialJournal,
                  capture_certificate: false,
                  work_plan: changeAuthWorkPlan,
                  opening_receipt: changeAuthOpeningReceipt,
                  image: changeAuthImage,
                  video: changeAuthVideo,
                });
              }}
            >
              <FormGroup>
                <Typography variant="body1" color="brown" className="body-title">
                  Certificado de captação (opcional)  -  (Formatos aceitos: PDF c/ limite de 2 MB)
                  <InfoIcon data-for="tooltip6" data-tip="Não é o Diário Oficial. É o documento que certifica que o seu projeto está apto a captar recursos. Por não ser um documento obrigatório, não são todas as leis que possuem." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
                </Typography>
                <ReactTooltip id="tooltip3" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
                <div>
                  {
                    (changedFields.capture_certificate && changeAuthCaptureCertificate) && (
                      <Tooltip title="Campo alterado pelo proponente" placement="top">
                        <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                      </Tooltip>
                    )
                  }
                  <DragAndDrop
                    placeholder="nenhum arquivo enviado"
                    onChange={files => {
                      if(!validateFileExtension(files, ["application/pdf"]))
                        return setError("capture_certificate", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                      if(!validateFileSize(files, 2))
                        return setError("capture_certificate", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 2MB." });

                      setValue('capture_certificate', files)
                      setDeleteCaptureName(false);
                      setError("capture_certificate", null);
                      handleSave();
                    }}
                    disabled={isDisabled}
                    deleteName={deleteCaptureName}
                    isUploading={isUploading}
                    error={errors["capture_certificate"]?.message}
                  />
                </div>
                {capture_certificate_name && (
                  <ShowFiles>
                    <Filename
                      label={'Certificado de captação'}
                      handleDelete={() => {
                        setValue('capture_certificate', null);
                        setDeleteCaptureName(true);
                        setValue('delete_capture_certificate', true)
                      }}
                      link={capture_certificate_name}
                      disabled={isDisabled}
                    />
                  </ShowFiles>
                )}
              </FormGroup>
            </AdminFormGroup>
          </div>
          <div>
          <AdminFormGroup
            checked={changeAuthWorkPlan ? false : authWorkPlan}
            onChange={value => {
              setAuthWorkPlan(value);
              setValue("auth_work_plan", value);
              setChangeAuthWorkPlan(!changeAuthWorkPlan);
              value && setChangedFields({
                ...changedFields,
                project_presentation: changeAuthProjectPresentation,
                official_journal: changeAuthOfficialJournal,
                capture_certificate: changeAuthCaptureCertificate,
                work_plan: false,
                opening_receipt: changeAuthOpeningReceipt,
                image: changeAuthImage,
                video: changeAuthVideo,
              });
            }}
          >
            <FormGroup>
              <Typography variant="body1" color="brown" className="body-title">
                Plano de Trabalho ou projeto aprovado na íntegra  -  (Formatos aceitos: PDF c/ limite de 5 MB)
                <InfoIcon data-for="tooltip4" data-tip="O plano de trabalho descreve como será realizado o projeto. Nesse documento contém: resumo, objetivo geral, objetivos específicos, metodologia, justificativa e cronograma." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
              </Typography>
              <ReactTooltip id="tooltip4" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

              <div>
                {
                  (changedFields.work_plan && changeAuthWorkPlan) && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                <DragAndDrop
                  placeholder="nenhum arquivo enviado"
                  onChange={files => {
                    if(!validateFileExtension(files, ["application/pdf"]))
                      return setError("work_plan", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                    if(!validateFileSize(files, 5))
                      return setError("work_plan", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 5MB." });
    
                    setValue('work_plan', files)
                    setDeleteWorkName(false);
                    setError("work_plan", null);
                    handleSave();
                  }}
                  deleteName={deleteWorkName}
                  disabled={isDisabled}
                  isUploading={isUploading}
                  error={errors["work_plan"]?.message}
                />
              </div>
              {work_plan_name && (
                <ShowFiles>
                  <Filename
                    label={'Plano de trabalho'}
                    handleDelete={() => {
                      setValue('work_plan', null);
                      setDeleteWorkName(true);
                      setValue('delete_work_plan', true)
                    }}
                    link={work_plan_name}
                    disabled={isDisabled}
                  />
                </ShowFiles>
              )}
            </FormGroup>
          </AdminFormGroup>
          
            {law_id && law_id <= 10 && (
              <AdminFormGroup
                checked={changeAuthOpeningReceipt ? false : authOpeningReceipt}
                onChange={value => {
                  setAuthOpeningReceipt(value);
                  setValue("auth_opening_receipt", value);
                  setChangeAuthOpeningReceipt(!changeAuthOpeningReceipt);
                  value && setChangedFields({
                    ...changedFields,
                    project_presentation: changeAuthProjectPresentation,
                    official_journal: changeAuthOfficialJournal,
                    capture_certificate: changeAuthCaptureCertificate,
                    work_plan: changeAuthWorkPlan,
                    opening_receipt: false,
                    image: changeAuthImage,
                    video: changeAuthVideo,
                  });
                }}
              >
                <FormGroup>
                  <Typography variant="body1" className="body-title" color="brown">
                    Comprovante de abertura de conta do projeto
                    <InfoIcon data-for="tooltip5" data-tip="O comprovante precisa ser o documento oficial emitido pela instituição bancária e precisa conter a identificação do proponente (cnpj), bem como do projeto a que está vinculada. " multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
                  </Typography>

                  <ReactTooltip id="tooltip5" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
                  <div>
                    {
                      (changedFields.opening_receipt && changeAuthOpeningReceipt) && (
                        <Tooltip title="Campo alterado pelo proponente" placement="top">
                          <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                        </Tooltip>
                      )
                    }
                    <DragAndDrop
                      placeholder="nenhum arquivo enviado"
                      // width="500px"
                      onChange={files => {
                        if(!validateFileExtension(files, ["application/pdf"]))
                          return setError("opening_receipt", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                        if(!validateFileSize(files, 2))
                          return setError("opening_receipt", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 2MB." });
    
                        setValue('opening_receipt', files)
                        setError("opening_receipt", null);
                        handleSave();
                      }}
                      disabled={isDisabled}
                      isUploading={isUploading}
                      error={errors["opening_receipt"]?.message}
                    />
                  </div>
                </FormGroup>
                {opening_receipt_name &&
                  <ShowFiles>
                    <Filename
                      label={'Comprovante de abertura'}
                      handleDelete={() => {
                        setValue('opening_receipt', null)
                        setValue('delete_opening_receipt', true)
                        setOpeningReceipt(null)
                      }}
                      link={opening_receipt_name}
                      disabled={isDisabled}
                    />
                  </ShowFiles>
                }
              </AdminFormGroup>
            )}

            {law_id && law_id > 10 && (
              <AdminFormGroup
                checked={authFundOpeningReceipt}
                onChange={value => {
                  setAuthFundOpeningReceipt(value);
                  setValue("auth_fund_opening_receipt", value);
                }}
              >
                <FormGroup>
                  <Typography variant="body1" className="body-title" color="brown">
                    Comprovante de dados bancários do fundo
                  </Typography>
                  <DragAndDrop
                    placeholder="nenhum arquivo enviado"
                    // width="500px"
                    onChange={files => {
                      if(!validateFileExtension(files, ["application/pdf"]))
                        return setError("fund_opening_receipt", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                      if(!validateFileSize(files, 2))
                        return setError("fund_opening_receipt", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 2MB." });
                        
                      setValue('fund_opening_receipt', files)
                      setError("fund_opening_receipt", null);
                      handleSave();
                    }}
                    disabled={isDisabled}
                    isUploading={isUploading}
                    error={errors["fund_opening_receipt"]?.message}
                  />
                </FormGroup>
                {fund_opening_receipt_name &&
                  <ShowFiles>
                    <Filename
                      label={'Comprovante de dados bancários do fundo'}
                      handleDelete={() => {
                        setValue('fund_opening_receipt', null)
                        setValue('delete_fund_opening_receipt', true)
                        setFundOpeningReceipt(null)
                      }}
                      link={fund_opening_receipt_name}
                      disabled={isDisabled}
                    />
                  </ShowFiles>
                }
              </AdminFormGroup>
            )}
          </div>
        </RowImageAndVideos>

        {!validFund && !validOpening && (
          <InfoTextContainer>
            <Typography variant="h6" color="grayDark2">
              Compromisso de abertura de conta do projeto
            </Typography>
            <CheckboxContainer>
              <Checkbox
                checked={checkboxState}
                onClick={handleChangeCheckboxState}
                disabled={isDisabled}
              />
              <Typography variant="body1" color="grayDark2" onClick={() => handleChangeCheckboxState()} className="subtitle">
                Eu declaro que ainda não possuo a conta aberta/termo de abertura de conta do projeto, mas que me comprometo 
                a abrir a conta e enviar o termo de abertura para a Incentiv.me assim que solicitado.
              </Typography>
            </CheckboxContainer>
          </InfoTextContainer>
        )}
      </Content>
    </Container>
  );
};

export default Step4;