import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Error404 = () => {
  const history = useHistory();

  return (
    <main>
      <Grid container>
        <h2>404</h2>
        <p>
          Desculpe, não conseguimos encontrar a página que você está procurando.
        </p>
      </Grid>
      <Grid item>
        <Grid>
          <Button className="btnSecondary" href="/">
            <ArrowBackIosIcon /> página inicial
          </Button>
        </Grid>
      </Grid>
    </main>
  );
};

export default Error404;
