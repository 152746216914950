import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 1rem 0;
  margin-top: 1rem;
  height: 100%;
`;

export const Body = styled.div`
  height: calc(100% - 1rem);
`;