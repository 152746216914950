import { yupResolver } from '@hookform/resolvers/yup';
import { removeMask } from 'utils/maskUtils';
import { validateCnpj, validateCpf, validateEmail, validatePhone } from 'utils/validation';
import * as Yup from 'yup';

const createProponentSchemaValidation = Yup.object().shape({
  cpf: Yup.string().when('legal_person', {
    is: value => String(value) !== 'Pessoa física',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('CPF obrigatório').test("cpf", "CPF inválido", value => validateCpf(removeMask(value))),
  }),
  cnpj: Yup.string().when('legal_person', {
    is: value => String(value) === 'Pessoa física',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('CNPJ obrigatório').test("cnpj", "CNPJ inválido", value => validateCnpj(value)),
  }),
  email: Yup.string()
    .email('Por favor, informe um e-mail válido')
    .required('E-mail obrigatório').test("Email inválido", validateEmail),
  legal_person: Yup.string().required('Tipo de pessoa obrigatório'),
  name: Yup.string().required('Nome obrigatório'),
  password: Yup.string()
    .matches(/^(?=.*[!$*&@#])/, "Deve conter um caractere especial")
    .matches(/^(?=.*[a-z])/, "Deve conter um caractere minúsculo")
    .matches(/^(?=.*[A-Z])/, "Deve conter um caractere maiúsculo")
    .matches(/^(?=.*[0-9])/, "Deve conter um número")
    .min(6, 'Mínimo de 6 caracteres')
    .max(30, 'Máximo de 30 caracteres')
    .required('Senha é obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Confirme a senha'),
  phone: Yup.string().required('Telefone obrigatório').test("phone", "Telefone inválido", value => validatePhone(removeMask(value))),
  is_terms_accepted: Yup.boolean().required('O aceite dos termos e da política é obrigatório'),
  receive_news: Yup.boolean().notRequired(),
});

export const createProponentValidationResolver = yupResolver(
  createProponentSchemaValidation,
);
