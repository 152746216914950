import React, { useCallback, useEffect, useState } from 'react';
import RegisterProject from 'components/Forms/RegisterProject';
import PageTitle from 'components/PageTitle';
import Tabs from 'components/Tabs';
import ProjectInfoCard from 'components/ProjectInfoCard';
import ProjectGoalsCard from 'components/ProjectGoalsCard';
import ProjectOffsetsCard from 'components/ProjectOffsetsCard';
import { ProjectService } from 'services/projects';
import { useParams } from 'react-router-dom';
import { currencyMask } from 'utils/currency';

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState();

  const loadProjectInfo = useCallback(async () => {
    const [project, infoProject] = await Promise.all([
      ProjectService.findProject(id),
      ProjectService.showProject(id),
    ]);
    console.log('data', project.data[0]);
    console.log('infoProject', infoProject.data[0]);
    
    if (project.data.length === 1 && infoProject.data.length === 1) {
      const projectData = {
        id: project.data[0].id,
        name: project.data[0].name,
        code: project.data[0].code,
        proponent: infoProject.data[0].proponentName,
        description: project.data[0].summary,
        status: infoProject.data[0].status,
        fundingPeriod: project.data[0].fundingPeriod,
        approvalDate: project.data[0].approvalDate,
        runtimeDays: project.data[0].runtimeDays,
        category: infoProject.data[0].impactCategoriesName,
        subcategory: infoProject.data[0].impactSubcategoriesName,
        law: project.data[0].lawId,
        approvedValue: project.data[0].approvedValue,
        capturedValue: project.data[0].capturedValue,
        capturedValueDate: project.data[0].capturedValueDate,
        target: project.data[0].target,
        audienceGoal: project.data[0].audienceGoal,
        sd_goals: infoProject.data[0].sdGoals,
        location: infoProject.data[0].locations,
        images: 
          infoProject.data[0].projectsImage
            ? infoProject.data[0].projectsImage
            : '[]',
        goal: project.data[0].goal,
        specificGoals: project.data[0].specificGoal,
        sponsors: project.data[0].sponsors,
        previous_sponsors: project.data[0].previousSponsors,
        counterpart: project.data[0].project_counterparts,
        video: project.data[0].video,
        edition: project.data[0].edition,
        impact_category_id: project.data[0].impactCategoryId,
        impact_subcategory_id: project.data[0].impactSubcategoryId,
        partially_captured: infoProject.data[0].partiallyCaptured,
        more_than_one_city: project.data[0].moreThanOneCity,
        can_change_city: project.data[0].canChangeCity,
        work_plan: project.data[0].workPlan,
        capture_certificate: project.data[0].captureCertificate,
        official_journal: (project.data[0].officialJournal || project.data[0].officialJournal2 || project.data[0].officialJournal3)
          ? [
            project.data[0].officialJournal,
            project.data[0].officialJournal2,
            project.data[0].officialJournal3
          ]
          : '[]',
        account_opening_commitment: project.data[0].accountOpeningCommitment,
        image: project.data[0].image,
        quota: infoProject.data[0].quota.map(q => {
          return {...q, valueOf: currencyMask(q.valueOf.toString()), valueUpTo: currencyMask(q.valueUpTo.toString()) }
        }),
        other_informations: project.data[0].otherInformations,
        company_names: project.data[0].companyNames,
        highlights_and_awards: project.data[0].highlightsAndAwards,
        minimum_value: project.data[0].minimumValue,
        project_presentation: project.data[0].projectPresentation,
        contract_file: project.data[0].contractFile,
        opening_receipt: project.data[0].openingReceipt,
        fund_opening_receipt: project.data[0].fundOpeningReceipt,
        fundingPeriodHasPassed: infoProject.data[0].fundingPeriodHasPassed,
        bank_id: project.data[0].bankId,
        agency: project.data[0].agency,
        account_bank: project.data[0].accountBank,
        retention_percentage: project.data[0].retentionPercentage,
      };
      setProject(projectData);
    }
  }, [id]);

  async function handleUpdateQuota() {
    // let infoProjectUpdated = await ProjectService.showProject(id);
    // setProject({ ...project, quota: infoProjectUpdated.data[0].quota })
    loadProjectInfo();
  }

  useEffect(() => {
    if (id) {
      loadProjectInfo();
    }
  }, [id, loadProjectInfo]);

  const url = window.location.href;
  const isCadProject = url.split("?");
  console.log(isCadProject);

  return (
    <div className="fade-in">
      <main>
        <PageTitle title={project?.name || "Nome do projeto"} />
        <Tabs
          tabs={['Informações', 'Objetivos', 'Contrapartidas', 'Cadastro']}
          cadProject={isCadProject[1] === 'step=4' ? true : false}
          screens={[
            <ProjectInfoCard project={project} />,
            <ProjectGoalsCard project={project} />,
            <ProjectOffsetsCard project={project} />,
            <RegisterProject saveProject={e => handleUpdateQuota()} project={project} id={id} loadProjectInfo={loadProjectInfo}/>,
          ]}
        />
      </main>
    </div>
  );
};

export default ProjectDetails;
