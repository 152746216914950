import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { rgba } from 'polished';

export const ForgotButton = styled(Button)`
  &.MuiButton-textPrimary {
    color: ${({ theme }) => theme.colors.blueAqua};
  }

  &:hover {
    background: ${({ theme }) => rgba(theme.colors.blueAqua, 0.1)} !important;
  }
`;

export const Input = styled(TextField)`
  width: 100%;
  .MuiInput-underline:after {
    border-color: ${({ theme }) => theme.colors.blueAqua} !important;
  }

  label.Mui-focused {
    color: ${({ theme }) => theme.colors.blueAqua} !important;
  } 
`;
