import Typography from 'components/Typography';
import React from 'react';

import { Container, TextContent, Title, Content, ContentBody, GoalTextContent } from './styles';
const ProjectGoalsCard = ({ project, extraMargin = false }) => {
  const exploded = project?.specificGoals?.split('||@||');
  const specific_goals = Object.assign({}, exploded);

  return (
    <Container style={{ margin: extraMargin ? '24px': 0 }}>
      <Title variant="h6" color="grayDark2">Objetivo Geral</Title>
      <TextContent className={project?.goal?.length > 250 && 'isEllipsis'}>
        <Typography variant="manrope" color="brown">{project?.goal}</Typography>
      </TextContent>
      <Content>
      <Title variant="h6" color="grayDark2">Objetivo(s) específico(s)</Title>
        <ContentBody>
          {Object.entries(specific_goals).map((item, index) => (
            <GoalTextContent>
              <Typography variant="body2" color="grayDark2">{`Objetivo 0${Number(item[0]) +1}`}</Typography>
              <Typography variant="manrope" color="brown">{item[1]}</Typography>
            </GoalTextContent>
          ))}
        </ContentBody>
      </Content>
    </Container>
  );
};

export default ProjectGoalsCard;