import React from 'react';
import Typography from 'components/Typography';

import {
  Container,
  InfoTextContainer,
  Content,
  SizeInputs,
  InputCounts,
  ButtonAddCounterPart,
  ButtonAddText,
  Footer,
  Counts,
  ButtonDelete
} from './styles';

import Input from 'components/Input';
import AdminFormGroup from 'components/AdminProjectForm/AdminFormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { IconAdd, IconDelete, IconExclude } from 'assets/icons';
import { useUser } from 'hooks/useUser';
import { WarningOutlined } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import './style.css';

const Step2 = props => {
  const changedFields = props.changedFields;
  const setChangedFields = (e)=> {props.setChangedFields({...e})};
  const { control, setValue, getValues } = useFormContext();
  const [authSummary, setAuthSummary] = useState(getValues('auth_summary'));
  const [authGoal, setAuthGoal] = useState(getValues('auth_goal'));
  const [authSpecificGoal, setAuthSpecificGoal] = useState(getValues('auth_specific_goal'));
  const [specificGoal, setSpecificGoal] = useState(getValues('specific_goal'));
  const [changeAuthSummary, setChangeAuthSummary] = useState(changedFields.summary);
  changeAuthSummary && setValue("auth_summary", !changeAuthSummary);
  const [changeAuthGoal, setChangeAuthGoal] = useState(changedFields.goal);
  changeAuthGoal && setValue("auth_goal", !changeAuthGoal);
  const [changeAuthSpecificGoal, setChangeAuthSpecificGoal] = useState(changedFields.specific_goal);
  changeAuthGoal && setValue("auth_specific_goal", !changeAuthGoal);
  const { user } = useUser();
  const roles = [
    'Em rascunho',
    'Em ajuste',
    'Aguardando regularização'
  ];
  let isDisabled = false;
  const project_status = getValues('status');
  
  if((user.adminRole === 'cs_proponent1') && !roles.includes(project_status)){
    isDisabled = true;
  } 
  //  else if((user.adminRole === 'support' || user.adminRole === 'evaluator') && project_status === 'Em análise') {
  //   isDisabled = true;
  // } else if(user.adminRole === 'general') {
  //   isDisabled = true;
  // }

  if(['general', 'monitore'].includes(user.adminRole)){
    isDisabled = true;
  }

  const addSpecificGoals = () => {
    const length = Object.keys(props.inputs.specificGoals).length;
    props.setInputs({
      ...props.inputs,
      specificGoals: { ...props.inputs.specificGoals, [length]: '' },
    });
  };

  

  const removeSpecificGoal = (index) => {
    let specificGoals = props.inputs.specificGoals;
    //  remove elemento do objeto
    delete(specificGoals[index]);

    //  processo de reordenar índices do objeto
    let tempData = {};
    let newIndex = 0;
    Object.entries(specificGoals).forEach(([key, value]) => {
      tempData[newIndex] = value;
      newIndex++
    });

    props.setInputs({
      specificGoals: { ...tempData },
    });
  }
  const theme = useTheme();
  

  const isInAnalysis = getValues('status') === 'Em análise';
  return (
    <Container>
      <InfoTextContainer>
        <Typography variant="h6" color="grayDark2">
          Resumo e objetivos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Utilize uma narrativa lógica e criativa para encantar possíveis incentivadores!
        </Typography>
        <Typography variant="body1" color="blueGrayish56" className={'description'}>
          Qual é a síntese do projeto? Qual o principal produto/serviço e como isto será realizado?
        </Typography>
      </InfoTextContainer>
      <Content>
        <AdminFormGroup
          checked={ changeAuthSummary ? false : authSummary }
          onChange={value => {
            setAuthSummary(value);
            setValue("auth_summary", value);
            setChangeAuthSummary(!changeAuthSummary);
            value && setChangedFields({...changedFields, summary: false});
          }}
        >
          <Controller
            name="summary"
            control={control}
            render={({ field }) => (
              <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                {
                  (changedFields.summary && changeAuthSummary) && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                <Input 
                  label="Resumo"
                  multiline
                  width="100%"
                  inputProps={{ maxLength: 700 }}
                  {...field}
                  disabled={isDisabled}
                />
              </div>
            )}
          />
        </AdminFormGroup>
      </Content>
      <Typography variant="body1" color="blueGrayish56" style={{textAlign: 'justify'}}>
        O objetivo geral deve destacar qual é o produto principal, o que o projeto propõe e qual impacto pretende gerar. 
      </Typography>
      <Content>
        <AdminFormGroup
          checked={ changeAuthGoal ? false : authGoal}
          onChange={value => {
            setAuthGoal(value);
            setValue("auth_goal", value);
            setChangeAuthGoal(!changeAuthGoal);
            value && setChangedFields({...changedFields, goal: false});
          }}
        >
          <Controller
            name="goal"
            control={control}
            render={({ field }) => (
              <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
                {
                  (changedFields.goal && changeAuthGoal) && (
                    <Tooltip title="Campo alterado pelo proponente" placement="top">
                      <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
                    </Tooltip>
                  )
                }
                {/* *Obrigatório */}
                <Input 
                  label="Objetivo Geral"
                  multiline
                  width="100%"
                  {...field}
                  disabled={isDisabled}
                  data-tip="Descreva a ideia central do seu projeto. O objetivo
                  geral responde à pergunta: “O que é?” Então ele deve
                  expressar de forma clara qual é a intenção do projeto" 
                  data-html={true}
                  data-for="tooltip1"
                  data-event='click focus'
                />
              </div>
            )}
          />
          <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
        </AdminFormGroup>
        <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: 'red'}}>
          {
            (changedFields.specific_goal && changeAuthSpecificGoal) && (
              <Tooltip title="Campo alterado pelo proponente" placement="top">
                <WarningOutlined style={{color: 'red', width: '1.35rem'}} />
              </Tooltip>
            )
          }
          <Typography variant="body1" color="blueGrayish56" style={{textAlign: 'justify'}}>
            Os objetivos específicos correspondem aos resultados que o projeto pretende alcançar e que contribuirão para alcançar o objetivo geral. É obrigatório indicar ao menos 1 objetivo especifico.
            <InfoIcon data-for="tooltip2" data-tip="Os objetivos específicos respondem <br/> “o que farei para alcançar o objetivo <br/>geral do projeto?”" multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute'}} />
          </Typography>
          <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
        </div>
        <AdminFormGroup
          checked={changeAuthSpecificGoal ? false : authSpecificGoal}
          onChange={value => {
            setAuthSpecificGoal(value);
            setValue("auth_specific_goal", value)
            setChangeAuthSpecificGoal(!changeAuthSpecificGoal);
            value && setChangedFields({...changedFields, specific_goal: false});
          }}
        >
          <InputCounts>
            {Object.entries(props.inputs.specificGoals).map((item, index) => (
              <>
              <div key={item[0]} style={{ marginBottom:'20px' }}>
                <Controller
                  name={`specific_goal_${index}`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      width="100%"
                      label={`Objetivo específico 0${Number(item[0]) +1}`}
                      // placeholder={`Objetivo específico ${item[0]}`}
                      height="65px"
                      multiline
                      rows={5}
                      {...field}
                      onChange={e => {
                        // const caretStart = e.target.selectionStart;
                        // const caretEnd = e.target.selectionEnd;
                        
                        // e.target.setSelectionRange(caretStart, caretEnd);
                        props.setInputs({
                          ...props.inputs,
                          specificGoals: {
                            ...props.inputs.specificGoals,
                            [item[0]]: e.target.value,
                          },
                        });
                        
                        // setTimeout(() => {
                        //   e.target.setSelectionRange(caretStart, caretEnd);
                        // }, 1);
                      }}
                      value={item[1]}
                      disabled={isDisabled}
                    />
                  )}
                />
                {/* <ButtonDelete onClick={() => removeSpecificGoal(item[0])} disabled={isInAnalysis || isDisabled} >
                  <IconDelete />
                </ButtonDelete> */}
              </div>
            </>
            ))}
            <Counts>
              <SizeInputs>
                {/* {watch('specific_goal') ? watch('specific_goal').length : 0}/150 */}
              </SizeInputs>
            </Counts>
          </InputCounts>
          {/* <Footer>
            <ButtonAddCounterPart type="button" onClick={addSpecificGoals} disabled={isDisabled}>
              <IconAdd width={20} height={20} />
              <ButtonAddText style={{ color: '#C15028' }}>
                ADICIONAR OBJETIVO
              </ButtonAddText>
            </ButtonAddCounterPart>
          </Footer> */}

          <Typography variant="body1" color="blueGrayish56" style={{textAlign: 'justify'}}>
            Como serão realizadas as ações e programação do projeto? Descreva essas e outras informações importantes.
          </Typography>
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Controller
              name="other_informations"
              control={control}
              render={({ field }) => (
                <Input
                  width="100%"
                  label="Outras informações"
                  height="65px"
                  multiline
                  rows={10}
                  inputProps={{ maxLength: 6000 }}
                  {...field}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </AdminFormGroup>
      </Content>
    </Container>
  );
};

export default Step2;