import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.75rem;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  margin-bottom: 0.75rem;
  gap: 2%;
`;

export const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.orange};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  path {
    fill: ${({ theme }) => theme.colors.orange};
    fill-opacity: 1;
  }
`;

export const TextDelete = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.orange};

  align-self: end;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.orange};
    fill-opacity: 1;
  }
`;

export const ContainerAporte = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 1.5rem;
  margin-bottom: 0.75rem;
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 10px;

  ${Text}{
    margin-top: -22px;
    background-color: #fff;
    padding: 0 5px;
    cursor: auto;    
    display: flex;
    align-items: center;
  }
`;

