import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  customOutline: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: props => (props.rounded ? 25 : 10),
      // borderColor: ({ color }) => color,
    },
    '.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: 0,
    }
  },
  formControlLabel: { fontSize: '10px' },
});

export default useStyles;
