import { useUser } from 'hooks/useUser';
import React from 'react';
import { Redirect, Route as RouteRRD } from 'react-router-dom';

const Route = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useUser();
  
    return (
      <RouteRRD
        {...rest}
        render={({ location }) => {
          if((location.pathname !== '/meus-dados' && location.pathname !== '/') && user.sponsorId){
            return (
              <Redirect
                to={{
                  pathname: '/meus-dados',
                  state: { from: location }
                }}
              /> 
            )
          } else {
            return (
              <RouteRRD
                {...rest}
                render={({ location }) => {
                  return isPrivate === (Object.keys(user).length > 0) ? (
                    <Component />
                  ) : (
                    <Redirect
                      to={{
                        pathname: isPrivate ? '/' : `${user.isAdmin ? '/admin/projetos' : '/meus-dados'}`,
                        state: { from: location },
                      }}
                    />
                  );
                }}
              />
            );
          }
        }}
      />
    )

};

export default Route;
