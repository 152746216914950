import styled from 'styled-components';
import Background from 'assets/images/bg.png';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: max-content;
  display: flex;
  padding: 3rem 0;
  justify-content: center;
  background: url(${Background});
  background-size: cover;

  @media (max-width: 786px){
    min-height: auto;
    padding: 1rem 0;
  }
`;
