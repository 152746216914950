import axios from "axios";
import { removeMask } from "utils/maskUtils";

const PATH = 'https://viacep.com.br/ws';

const find = cep => {
  const formatedCep = removeMask(cep);
  return axios.get(`${PATH}/${formatedCep}/json`);
};

const validate = async cep => {
  try {
    const { data } = await find(cep);

    if(data?.erro)
      return false;

    return true;
  } catch(err) {
    return false;
  }
}

export const CepService = {
  find,
  validate
}