import React from 'react';
import { Pagination as MaterialPagination } from '@material-ui/lab'
import { Container } from './styles';

function Pagination({
  totalPages = 1,
  currentPage = 1,
  onChange
}) {
  return (
    <Container>
      <MaterialPagination 
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => onChange(page)}
        color="primary"
        shape="rounded"
        variant="outlined"
      />
    </Container>
  );
}

export default Pagination;