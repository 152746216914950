import React from 'react';

import { AddCircle } from '@material-ui/icons';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import ProjectsFilter from 'components/ProjectsFilter';
import NoProjects from 'components/NoProjects';
import VitrineTable from 'components/VitrineTable';
import AddCaptationModal from 'components/VitrineModals/AddCaptation';
import DeleteProject from 'components/VitrineModals/DeleteProject';
import ChangeBidder from 'components/VitrineModals/ChangeBidder';
import ChangeStauts from 'components/VitrineModals/ChangeStatus';
import IndicateTo from 'components/VitrineModals/IndicateTo';

import { Container, Header, ProjectsContainer } from './styles';
import { v4 } from 'uuid';

const VitrinePage = () => {
  const defaultProject = {
    fundraising: {
      date: '08/10/2021 a 31/12/2021',
      minValue: 0,
      maxValue: 100000,
      currentValue: 70000,
    },

    project: {
      id: v4(),
      logo: '',
      title: 'Educação e cultura',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
      category: 'Educação e cultura',
    },
    bidder: {
      id: v4(),
      logo: '',
      title: 'Proponent',
      name: 'Nome do proponente',
      email: 'email@email.com',
    },
    status: 'Aprovado',
    resume:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem voluptates excepturi asperiores quas, voluptate pariatur suscipit commodi doloribus, dolores expedita voluptatibus fuga fugiat similique? Modi accusantium enim dicta fugit dignissimos.',
    incentiveLaw: 'Nome da Lei',
    city: 'Timbaúba-PE',
    projectStatus: 'Em andamento',
    lastUpdate: new Date(),
    endDate: new Date(),
  };

  const projects = new Array(2).fill(defaultProject);

  return (
    <Container>
      <AddCaptationModal />
      <DeleteProject />
      <ChangeBidder />
      <ChangeStauts />
      <IndicateTo />
      <Header>
        <PageTitle
          title="Projetos"
          subtitle="Esses são os projetos cadastrados conosco."
        >
          Vitrine
        </PageTitle>
        {/* <Button variant="primary">
          <AddCircle />
          ADICIONAR PROJETO
        </Button> */}
      </Header>
      {projects.length > 0 && <ProjectsFilter />}
      <ProjectsContainer>
        {projects.length === 0 ? (
          <NoProjects />
        ) : (
          <VitrineTable data={projects} />
        )}
      </ProjectsContainer>
    </Container>
  );
};

export default VitrinePage;
