import React from 'react';
import { Title, Subtitle } from './styles';

const PageTitle = ({ title, subtitle, type }) => {
  return (
    <div>
      <Title color="secondary" variant="h3">
        {title}
      </Title>
      <Subtitle>{subtitle}</Subtitle>
    </div>
  );
};

export default PageTitle;
