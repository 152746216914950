import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 1100px;
  
  h6 + h6 {
    margin-top: 1rem;
  }

  @media (max-width: 1700px) {
    max-width: 900px;
  }
  @media (max-width: 1550px) {
    max-width: 52vw;
  }

  @media (max-width: 1450px) {
    max-width: 49vw;
  }

  @media (max-width: 1370px) {
    max-width: 44vw;
  }

  @media (max-width: 1250px) {
    max-width: 40vw;
  }

  @media (max-width: 960px){
    max-width: 100%;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: grid;
  margin-top: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;
