import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerModal, Title, Row, Footer, Container } from '../styles';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import {
  toggleCreateUserModal,
  toggleEditUserModal,
  toggleDeleteUserModal,
} from 'store/ducks/modals/actions';
import { UsersService } from 'services/users';

export const DeleteModal = ({ refreshData }) => {
  const dispatch = useDispatch();

  const openDeleteModal = useSelector(
    state => state.modals.usersModal.deleteUserModal,
  );

  const { users } = useSelector(state => state.modals.usersModal);

  const deleteUser = useCallback(async () => {
    try {
      await UsersService.deleteUser(users.id);
      refreshData();
    } catch (err) {
      console.log(err.message);
    }
  }, [users, refreshData]);

  const handleClose = () => {
    dispatch(toggleDeleteUserModal());
  };

  const onBack = () => {
    dispatch(toggleEditUserModal());
    dispatch(toggleDeleteUserModal());
  };

  const onDelete = () => {
    deleteUser();
    dispatch(toggleDeleteUserModal());
  };

  return (
    <Container
      open={openDeleteModal}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        },
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Title>
          <Typography variant="h4">
            Tem certeza que deseja excluir esse usuário?
          </Typography>
        </Title>
        <Row>
          <Typography>
            Essa ação não pode ser desfeita. Após a exclusão, os dados
            referentes ao usuário serão perdidos.{' '}
          </Typography>
        </Row>
        <Footer>
          <Button variant="tertiary" onClick={onBack}>
            Voltar
          </Button>
          <Button variant="tertiary" onClick={onDelete}>
            Excluir
          </Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
};

export default DeleteModal;
