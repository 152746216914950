import SimpleCrypto from 'simple-crypto-js';

export const simpleCrypto = new SimpleCrypto(
  process.env.REACT_APP_CRIPTO_KEY || '',
);

const localStorageItem = localStorage.getItem('@Vitrine' || '{}');

const userLogin = localStorageItem
  ? simpleCrypto.decrypt(localStorage.getItem('@Vitrine' || '{}') || '{}')
  : {};

const INITIAL_STATE = {
  isAuthenticated: Boolean(userLogin.token),
  user: userLogin,
  persistUser: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  const updatedUserState = state;

  switch (action.type) {
    case '@auth/LOGIN':
      updatedUserState.user = action.payload.data;
      updatedUserState.isAuthenticated = true;
      updatedUserState.persistUser = action.payload.persist;

      localStorage.setItem('persist@user', action.payload.persist);

      localStorage.setItem(
        String('@Vitrine'),
        simpleCrypto.encrypt(JSON.stringify(action.payload.data)),
      );
      return { ...state, ...updatedUserState };

    case '@auth/LOGOUT':
      localStorage.removeItem(String('@Vitrine'));
      updatedUserState.isAuthenticated = false;
      updatedUserState.persistUser = false;
      updatedUserState.user = {};
      localStorage.setItem('persist@user', false);
      localStorage.removeItem(String('@Vitrine'));

      return { ...state, ...updatedUserState };

    case '@auth/AUTH':
      return updatedUserState;

    case '@auth/UPDATE_USER':
      updatedUserState.user = action.payload.data;

      localStorage.setItem(
        String('@Vitrine'),
        simpleCrypto.encrypt(JSON.stringify(updatedUserState.user)),
      );
      return { ...state, ...updatedUserState };
    
    case '@auth/UPDATE_USER_AVATAR':
      updatedUserState.user.avatar = action.payload;

      localStorage.setItem(
        String('@Vitrine'),
        simpleCrypto.encrypt(JSON.stringify(updatedUserState.user)),
      );
      return { ...state, ...updatedUserState };

    default:
      return state;
  }
};

export default reducer;
