import React, { useEffect, useMemo, useState } from 'react';
import Input from 'components/Input';
import Select from 'components/Select';
import Typography from 'components/Typography';
import './style.css';
import { 
  Container, 
  SpaceFields,
  ProjectCityText,
  Options,
  OptionsContent,
  CityList,
  ProjectLocalText,
  ContentChip,
  DivEdicao
 } from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Chip from 'components/Chip';
import Button from 'components/Button';
import { useTheme } from 'styled-components';
import { ProjectService } from 'services/projects';
import { onlyNumber } from 'utils/maskUtils';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { FormHelperText, Tooltip, InputLabel } from '@material-ui/core';
import ReactTooltip from "react-tooltip";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import InfoIcon from '@material-ui/icons/Info';
import { ErrorMessage } from '@hookform/error-message';

const schema = yup.object().shape({
  name: yup.string().required('Nome do projeto obrigatório'),
  law_id: yup.string().required("Lei de incentivo discal obrigatório")
});

const Step1 = ({ laws, setFormValid, ufs, locations, setCreateChip, createChip, }) => {
  const { control, setValue, formState: { errors }, watch, setError, clearErrors, getValues } = useFormContext({
    resolver: yupResolver(schema)
  });

  const [citiesSelected, setCitiesSelected] = useState([]);
  const [ufsSelected, setUfsSelected] = useState([]);
  const [citySelected, setCitySelected] = useState();
  const [changeCityProject, setChangeCityProject] = useState(false);
  const [selectedUF, setSelectedUF] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [cities, setCities] = useState([]);
  const [showCities, setShowCities] = useState(false);
  const [showRetentionPercentage, setShowRetentionPercentage] = useState(false);

  setTimeout(() => {
    if([11, 12, 13, 14, 15, 16].includes(getValues('law_id'))){
      setShowRetentionPercentage(true);
    }
  }, 500);

  const theme = useTheme();
  const [moreThanOneCity, setMoreThanOneCity] = useState(false);

  const getFormattedValue = value => {
    return value.replace(/\D/g, '');
  };

  const role = theme.colors.background == '#12203F' ? 'proponente' : 'admin';

  const handleSelectedCitites = useCallback(
    type => {
      if (citiesSelected.includes(type)) {
        setCitiesSelected(citiesSelected.filter(ods => ods !== type));
      } else {
        setCitiesSelected([...citiesSelected, type]);
      }
    },
    [citiesSelected],
  );

  useEffect(() => {
    setValue('location_ids', citiesSelected);
    setValue('city', citiesSelected);
  }, [setValue, citiesSelected]);

  useEffect(() => {
    setValue('ufs', ufsSelected);
  }, [setValue, ufsSelected]);

  const loadCities = useCallback(async value => {
    const response = await ProjectService.getCity(value);
    setCities(response.data);
    setShowCities(true);
  }, []);

  const handleSelectedUF = useCallback(
    value => {
      setSelectedUF(value);
      if (ufsSelected.includes(value)) {
        setUfsSelected(ufsSelected.filter(ufs => ufs !== value));
      } else {
        setUfsSelected([...ufsSelected, value]);
      }
      loadCities(value);
    },
    [loadCities, ufsSelected],
  );

  const handleSelectedCity = useCallback(
    value => {
      setSelectedCity(value);
      handleSelectedCitites(value);
    },
    [handleSelectedCitites],
  );

  const handleCreateCity = useCallback(() => {
    clearErrors("locations");

    if (moreThanOneCity === 0 && createChip.length > 0) return;
    
    const value = `${selectedUF} - ${selectedCity}`;
    
    if (!createChip.includes(value)) {
      setCreateChip(prevState => [
        ...prevState,
        value,
      ]);
    };

  }, [createChip, moreThanOneCity, selectedCity, selectedUF, setValue]);

  const handleDeleteCity = useCallback(cityToDelete => {
    setCreateChip(prevState => prevState.filter(city => city !== cityToDelete));
  }, []);

  useEffect(() => {
    if (locations) {
      setCitySelected(locations?.length > 0);
      const ufIndex = ufs.findIndex(
        uf => uf.state_code === locations[0].state_code,
      );
      const uf = ufs.find(uf => uf.state_code === locations[0].state_code);
      setSelectedUF(ufIndex);
      locations = locations.filter((element) => {
          return element.uf !== null;
      })
      if(createChip.length === 0){
        setCreateChip(
          locations.map(location => `${location.uf} - ${location.city}`),
        );
      }
    }
  }, [locations, setValue, ufs, loadCities]);

  setValue('locations', createChip);

  const schemaValidationForm = useMemo(() => {
    return [
      {
        field: 'name',
        condition: data => String(data.name).trim().length === 0,
        errorMessage: 'Nome obrigatório'
      }
    ];
  }, []);
  
  const validateSchemaItem = useCallback((data, item) => {
    const { condition, field, errorMessage } = item;
    if (data[item.field] === undefined || data[item.field] === '' || condition(data)) {
      setError(field, { message: errorMessage });
    } else {
      clearErrors(field);
    }
  }, [setError, clearErrors]);

  const validateFormStep = useCallback(data => {
    schemaValidationForm.forEach(schema => {
      validateSchemaItem(data, schema);
    });
  }, [validateSchemaItem, schemaValidationForm]);

  const status = getValues('status');

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);
  const url = window.location.href;
  const isCadProject = url.split("?");
  console.log(isCadProject);

  function hasLawPecent(lawId) {
    if([11,12,13,14,15,16].includes(lawId)){
      setShowRetentionPercentage(true);
    }else{
      setShowRetentionPercentage(false);
      setValue('retention_percentage', null);
    }
  }

  return (
    <Container>
      <div>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          Dados básicos do projeto
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Insira as informações sobre o seu projeto conforme aprovado em lei.
        </Typography>
      </div>
      <SpaceFields>
        <div>
          <DivEdicao>
            {/* *Obrigatório */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  className="input-project-name"
                  label="Nome do projeto"
                  error={errors?.name?.message}
                  {...field}

                  required={true}
                  margin={85}
                  value={field.value}

                  disabled={isDisabledByStatus}
                  data-tip="Insira o nome do projeto que foi aprovado <br/>no Diário Oficial " 
                  data-html={true}
                  data-for="tooltip1"
                  data-event='click focus'
                  focused={(isCadProject[1] === 'step=4' && field.value) ? true : false}
                />
              )}
            />
            <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

            {/* *Obrigatório */}
            <Controller
              name="edition"
              control={control}
              render={({ field }) => (
                <Input
                  label="Edição do projeto"
                  {...field}
                  value={field.value}
                  error={errors?.edition?.message}
                  disabled={isDisabledByStatus}
                  data-tip="Insira aqui em qual edição o projeto está ou se <br/>
                  é edição única." 
                  data-html={true}

                  required={true}             
                  margin={85}
                  data-for="tooltip1"
                  data-event='click focus'
                />
              )}
            />
            <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

              <Controller
                name="law_id"
                control={control}
                render={({ field }) => {

                  {/* *Obrigatório */}
                  return (
                    <Select
                      {...field}
                      className="select-project-name"
                      label="Lei de incentivo fiscal"
                      role={role}
                      options={laws.map(law => law.description)}
                      error={errors?.law_id?.message}
                      onChange={e => {
                        clearErrors("law_id");
                        setValue('law_id', laws[e.target.value].id);
                        hasLawPecent(laws[e.target.value].id);
                      }}
                      required={true}
                      margin = {85}
                      value={laws.findIndex(
                        item => item.id === getValues('law_id'),
                      )}
                      disabled={isDisabledByStatus}
                    />
                  )
                }}
              />
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Código do projeto"
                    {...field}
                    value={field.value}
                    error={errors?.code?.message}
                    disabled={isDisabledByStatus}
                  />
                )}
              />
          </DivEdicao>
          <DivEdicao>
            { 
              showRetentionPercentage && (
                <Controller
                  name="retention_percentage"
                  control={control}
                  render={({ field }) => (
                    <Input
                      style={{ marginTop: '22px' }}
                      label="% de Retenção"
                      {...field}
                      onChange={e => {
                        const value = onlyNumber(e.target.value);
                        setValue('retention_percentage', value);
                      }}
                      value={field.value}
                      error={errors?.code?.message}
                    />
                  )}
                />
              )
            }
          </DivEdicao>
        </div>
        <div style={{ marginTop: 41 }}>
          <Typography variant="subtitle2" color="grayLight1">
            Insira o tempo estimado para a execução do projeto.
          </Typography>
        </div>
        <div style={{ marginTop: 18 }}>
          {/* *Obrigatório */}
          <Controller
            name="runtime_days"
            control={control}
            render={({ field }) => (
              <Input
                label="Duração do projeto (em dias)"
                error={errors?.runtime_days?.message}
                {...field}
                onChange={e => {
                  const value = onlyNumber(e.target.value);
                  setValue('runtime_days', value);
                  clearErrors("runtime_days");
                }}
                disabled={isDisabledByStatus}
                data-tip="Aqui insira a duração do seu <br/>projeto. Coloque a duração em dias<br/> Ex: 1 mês = 30 dias." 
                data-html={true}
                required={true}             
                margin={92}
                data-for="tooltip1"
                data-event='click focus'
              />
            )}
          />
          <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

        </div>

        <div style={{ marginTop: 34 }}>
        <Typography variant="h6" color="grayDark2">
          Localização de realização do projeto
        </Typography>
      </div>
      <div style={{ marginTop: 8 }}>
        <Typography variant="subtitle2" color="grayLight1">
          Você pode selecionar mais de uma cidade, caso seu projeto aconteça em
          mais de um lugar ou seja itinerante.
        </Typography>
      </div>
      <ProjectCityText>
        {/* *Obrigatório */}
        <Typography variant="body1" color="blueGrayish56">
          <label style={errors["more_than_one_city"] && { color: "#f44336" }}>O projeto acontece em mais de uma cidade ou é itinerante? Marque sim e indique os locais. *Obrigatório</label>

          <InfoIcon data-for="tooltip2" data-tip="Marque a opção <b>Sim</b> caso seu <br/>projeto seja executado em mais<br/> de uma cidade." multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute', marginTop: '-5px'}} />
          <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

        </Typography>
        
      </ProjectCityText>
      <OptionsContent>
        <Options>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonCheckedIcon />}
            rounded
            color="primary"
            checked={getValues('more_than_one_city') === 1}
            onClick={() => {
              setValue('more_than_one_city', 1);
              setMoreThanOneCity(1);
              setCitySelected(!citySelected);
              clearErrors("more_than_one_city");
            }}
            disabled={isDisabledByStatus}
          />
          <p>Sim</p>
        </Options>
        <Options>
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonCheckedIcon />}
            rounded
            color="primary"
            checked={getValues('more_than_one_city') === 0}
            onClick={() => {
              setValue('more_than_one_city', 0);
              setMoreThanOneCity(0);
              setCitySelected(!citySelected);
              clearErrors("more_than_one_city");
            }}
            disabled={isDisabledByStatus}
          />
          <p>Não</p>
        </Options>
      </OptionsContent>
      
      {errors["more_than_one_city"] && <FormHelperText error>{errors["more_than_one_city"]?.message}</FormHelperText>}

      <>
        <CityList>
          {/* *Obrigatório */}
          <Select
            onChange={e => {
              const ufList = ufs.map(item => item.state_code).sort();
              handleSelectedUF(ufList[e.target.value])
            }}
            options={ufs.map(item => item.state_code).sort()}
            label="UF"

            required={true}
            margin = {50}

            role={role}
            value={selectedUF}
            disabled={isDisabledByStatus}
            error={Boolean(errors["locations"])}
          />
          
          {/* *Obrigatório */}
          { !selectedCity &&
            <InputLabel style={{color: 'red', marginLeft: '41%', marginTop: '-5px', backgroundColor: '#fff', position: 'absolute', fontSize: '12px', zIndex: 99}} id="select-label">Obrigatório</InputLabel>
          }

          <Select
            options={cities.map(item => item.name)}
            onChange={e => handleSelectedCity(cities[e.target.value].name)}
            label="Lista de cidades"
            disabled={isDisabledByStatus}
            role={role}
            error={Boolean(errors["locations"])}
          />
          <Button
            borderColor={theme.colors.primary}
            variant="secondary"
            color={theme.colors.primary}
            onClick={handleCreateCity}
            disabled={!Boolean(selectedUF && selectedCity) || isDisabledByStatus}
          >
            INCLUIR
          </Button>
          {/* )} */}
        </CityList>

        {errors["locations"] && <FormHelperText error>{errors["locations"]?.message}</FormHelperText>}

        <ContentChip>
          {createChip.map(city => (
            <Chip
              key={city}
              label={city}
              width="240px"
              onClick={() => handleDeleteCity(city)}
              disabled={isDisabledByStatus}
            />
          ))}
        </ContentChip>
        {getValues('more_than_one_city') === 1 && (
          <div>
            <ProjectLocalText>
              {/* *Obrigatório */}
              <Typography variant="body1" color="blueGrayish56">
                É possível alterar as cidades de realização do projeto de acordo com as indicações do patrocinador? 
                {!(getValues('can_change_city') == 1 ||  getValues('can_change_city') == 0) && <span style={{fontSize: '14px', color: 'red'}}>Obrigatório</span> }
              </Typography>
            </ProjectLocalText>
            <OptionsContent>
              <Options>
                <Checkbox
                  rounded
                  icon={<CircleUnchecked />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  color="primary"
                  checked={getValues('can_change_city') === 1}
                  onClick={() => {
                    setValue('can_change_city', 1)
                    setChangeCityProject(!changeCityProject)
                  }}
                  disabled={isDisabledByStatus}
                />
                <p>Sim</p>
              </Options>
              <Options>
                <Checkbox
                  rounded
                  icon={<CircleUnchecked />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  color="primary"
                  checked={getValues('can_change_city') === 0}
                  onClick={() => {
                    setValue('can_change_city', 0)
                    setChangeCityProject(!changeCityProject)}
                  }
                  disabled={isDisabledByStatus}
                />
                <p>Não</p>
              </Options>
            </OptionsContent>
          </div>
        )}
      </>
      </SpaceFields>
    </Container>
  );
};

export default Step1;
