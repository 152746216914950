import styled from 'styled-components';

export const Container = styled.div`
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  background: ${({ theme }) => theme.colors.white};

  border-radius: 15px;
`;

export const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.orange};

  cursor: pointer;
`;
