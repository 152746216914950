

export const translateAuthProponentData = {
  auth_legal_person: {
    text: 'Natureza jurídica do proponente',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_about: {
    text: 'Sobre',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_name_responsible: {
    text: 'Nome do responsável',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_cpf_responsible: {
    text: 'CPF do responsável',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_phone_responsible: {
    text: 'Telefone do responsável',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_email_responsible: {
    text: 'Email do responsável',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_social_contract: {
    text: 'Contrato social',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_last_election: {
    text: 'Ata da última eleição',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_social_status: {
    text: 'Estatuto Social',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_name: {
    text: 'Nome do perfil',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_full_name: {
    text: 'Nome completo',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_cpf: {
    text: 'CPF',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_email: {
    text: 'Email',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_phone: {
    text: 'Telefone',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  // auth_value_captured_incentive_laws: {
  //   text: 'Valor total já captado via leis de incentivo',
  //   step: {
  //     id: 1,
  //     text: 'Dados cadastrais',
  //   },
  // },
  // auth_years_experience: {
  //   text: 'Experiência em leis de incentivo',
  //   step: {
  //     id: 1,
  //     text: 'Dados cadastrais',
  //   },
  // },
  // auth_experience_incentive_laws: {
  //   text: 'Com quais leis de incentivo possui experiência?',
  //   step: {
  //     id: 1,
  //     text: 'Dados cadastrais',
  //   },
  // },
  auth_corporate_name: {
    text: 'Razão Social',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_cnpj: {
    text: 'CNPJ',
    step: {
      id: 1,
      text: 'Dados cadastrais',
    },
  },
  auth_name_register_responsible: {
    text: 'Nome completo do responsável pelo cadastro',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_email_register_responsible: {
    text: 'Email do responsável pelo cadastro',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_phone_register_responsible: {
    text: 'Telefone do responsável pelo cadastro',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_full_name_witness: {
    text: 'Nome completo da testemunha',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_witness_email: {
    text: 'E-mail da testemunha',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_cep: {
    text: 'CEP',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_public_place: {
    text: 'Logradouro',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_number: {
    text: 'Número',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_district: {
    text: 'Bairro',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  // auth_complement: {
  //   text: 'Complemento',
  //   step: {
  //     id: 2,
  //     text: 'Testemunha',
  //   },
  // },
  auth_state: {
    text: 'Estado',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  auth_city: {
    text: 'Cidade',
    step: {
      id: 2,
      text: 'Testemunha',
    },
  },
  // auth_photo_profile: {
  //   text: 'Foto de perfil',
  //   step: {
  //     id: 3,
  //     text: 'Imagens, vídeos e presença digital',
  //   },
  // },
  auth_video: {
    text: 'Link de Vídeo',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  },
  auth_photos_carousel: {
    text: 'Imagens do proponente',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  },
  auth_site: {
    text: 'Site',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  },
  auth_linkedln: {
    text: 'LinkedIn',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  },
  auth_facebook: {
    text: 'Facebook',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  },
  auth_youtube: {
    text: 'YouTube',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  },
  auth_instagram: {
    text: 'Instagram',
    step: {
      id: 3,
      text: 'Imagens, vídeos e presença digital',
    },
  }
};

export const translateAuthProjectData = {
  // auth_status: {
  //   text: 'Status do projeto',
  //   step: {
  //     id: 1,
  //     text: 'Dados básicos do projeto',
  //   },
  // },
  auth_name: {
    text: 'Nome do projeto',
    step: {
      id: 1,
      text: 'Dados básicos do projeto',
    },
  },
  auth_law_id: {
    text: 'Lei de incentivo fiscal',
    step: {
      id: 1,
      text: 'Dados básicos do projeto',
    },
  },
  // auth_runtime_days: {
  //   text: 'Tempo de execução em dias',
  //   step: {
  //     id: 1,
  //     text: 'Dados básicos do projeto',
  //   },
  // },
  // auth_location_ids: {
  //   text: 'Localização de realização do projeto',
  //   step: {
  //     id: 1,
  //     text: 'Dados básicos do projeto',
  //   },
  // },
  auth_summary: {
    text: 'Resumo',
    step: {
      id: 2,
      text: 'Resumo e objetivos',
    },
  },
  auth_goal: {
    text: 'Objetivo geral',
    step: {
      id: 2,
      text: 'Resumo e objetivos',
    },
  },
  auth_specific_goal: {
    text: 'Objetivo específico',
    step: {
      id: 2,
      text: 'Resumo e objetivos',
    },
  },
  // auth_sd_goal_ids: {
  //   text: 'Principais ODS’s incorporadas no projeto',
  //   step: {
  //     id: 3,
  //     text: 'ODS e Impacto',
  //   },
  // },
  // auth_impact_category_id: {
  //   text: 'Categoria de impacto',
  //   step: {
  //     id: 3,
  //     text: 'ODS e Impacto',
  //   },
  // },
  // auth_impact_subcategory_id: {
  //   text: 'Subcategoria de impacto',
  //   step: {
  //     id: 3,
  //     text: 'ODS e Impacto',
  //   },
  // },
  auth_target: {
    text: 'Público-alvo direto',
    step: {
      id: 3,
      text: 'ODS e Impacto',
    },
  },
  auth_audience_goal: {
    text: 'Meta do público-alvo direto',
    step: {
      id: 3,
      text: 'ODS e Impacto',
    },
  },
  auth_approved_value: {
    text: 'Valor aprovado em lei',
    step: {
      id: 5,
      text: 'Valor do projeto e incentivos',
    },
  },
  auth_approval_date: {
    text: 'Data de aprovação',
    step: {
      id: 5,
      text: 'Valor do projeto e incentivos',
    },
  },
  auth_funding_period: {
    text: 'Prazo de captação',
    step: {
      id: 5,
      text: 'Valor do projeto e incentivos',
    },
  },
  // auth_sponsors: {
  //   text: 'Indicar o nome dos principais incentivadores das edições anteriores do projeto',
  //   step: {
  //     id: 4,
  //     text: 'Valor do projeto e incentivos',
  //   },
  // },
  // auth_partially_captured: {
  //   text: 'Projeto parcialmente captado',
  //   step: {
  //     id: 4,
  //     text: 'Valor do projeto e incentivos',
  //   },
  // },
  auth_minimum_value: {
    text: 'Valor mínimo de execução',
    step: {
      id: 5,
      text: 'Valor do projeto e incentivos',
    },
  },
  auth_project_cover: {
    text: 'Capa do projeto',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_video: {
    text: 'Link de vídeo do Vimeo ou Youtube que melhor explica o seu projeto!',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_project_images: {
    text: 'Adicione até 5 imagens do projeto',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_official_journal: {
    text: 'Publicação diário oficial',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_capture_certificate: {
    text: 'Certificado de captação',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_work_plan: {
    text: 'Plano de trabalho',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_project_presentation: {
    text: 'Apresentação do projeto',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_opening_receipt: {
    text: 'Comprovante de abertura de conta do projeto',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_fund_opening_receipt: {
    text: 'Comprovante de dados bancários do fundo',
    step: {
      id: 4,
      text: 'Vídeo, imagens e documentos',
    },
  },
  auth_quota: {
    text: 'Cota',
    step: {
      id: 6,
      text: 'Contrapartidas',
    },
  },
};
