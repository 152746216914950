import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import Input from 'components/Input';


const RequestAdjust = ({ open, handleClose, onChange }) => {
  const [description, setDescription] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Descreva o motivo da solicitação
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Input label="Descrição" onChange={e => setDescription(e.target.value)}/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Voltar
        </Button>
        <Button onClick={() => onChange(description)} color="primary">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestAdjust;