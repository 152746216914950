/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import Layout from 'components/Layout';
import { ProponentTheme, Admin } from 'themes';
import { BidderOptions, CompanyOptions } from 'constants/menu-options';
import MilestonesModal from 'components/MilestonesModal';
import CounterpartsModal from 'components/CounterpartsModal';
import CounterpartsReviewModal from 'components/CounterpartsReviewModal';
import FilterModal from 'components/FilterModal';
import FullScreenImages from 'components/FullScreenImages';
import { ToastContainer } from 'react-toastify';

import Routes from 'routes';

import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyles } from 'themes/global';
import { NotificationService } from 'services/notifications';
import { init as initApm } from "@elastic/apm-rum";

const App = () => {
  const user = useSelector(state => state.auth.user);
  const registerValue = useSelector(state => state.register.profile);
  const [menu, setMenu] = useState([]);

  const getUserTheme = role => {
    return role === 'Company' ? Admin : ProponentTheme;
  };

  const getUserThemeMaterial = role => {
    return role === 'Company'
      ? createTheme({
          palette: {
            primary: {
              main: Admin.colors.primary,
            },
            secondary: {
              main: Admin.colors.secondary,
            },
          },
        })
      : createTheme({
          palette: {
            primary: {
              main: ProponentTheme.colors.primary,
            },
            secondary: {
              main: ProponentTheme.colors.secondary,
            },
          },
        });
  };

  const getOptions = useCallback(async role => {
    try {
      const response = await NotificationService.list();
      const total = Array.isArray(response.data.data)
        ? `${response.data.data.length}+`
        : '0';
        const options =
        role === 'Company' ? CompanyOptions(total) : BidderOptions(total);
      setMenu(options);
    } catch (err) {
      console.log(err);
      return role === 'Company' ? CompanyOptions(0) : BidderOptions(0);
    }
  }, []);

  const getSponsor = registerValue.profile?.type === 'patrocinador';

  useEffect(() => {
    if (user.role) getOptions(user.role);
  }, [getOptions, user]);

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <ThemeProvider
            theme={
              getSponsor
                ? {
                    colors: {
                      ...ProponentTheme.colors,
                      primary: 'rgba(30, 205, 204, 1)',
                      secondary: '#1CB9B8',
                    },
                    typographyNew: { ...ProponentTheme.typographyNew },
                  }
                : getUserTheme(user.role)
            }
          >
            <MaterialThemeProvider
              theme={
                getSponsor
                  ? createTheme({
                      palette: {
                        primary: {
                          main: 'rgba(30, 205, 204, 1)',
                        },
                        secondary: {
                          main: '#1CB9B8',
                        },
                      },
                    })
                  : getUserThemeMaterial(user.role)
              }
            >
              <Layout options={menu}>
                <Routes />
                <MilestonesModal />
                <CounterpartsModal />
                <CounterpartsReviewModal />
                <FilterModal />
              </Layout>
            </MaterialThemeProvider>
            <FullScreenImages />
            <GlobalStyles />
          </ThemeProvider>
        </Switch>
        <ToastContainer
          icon={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          autoClose={3000}
          theme="colored"
        />
      </div>
    </BrowserRouter>
  );
};
export default App;
