import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const DeleteProject = ({ open, handleClose, onClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Tem certeza que deseja excluir esse projeto?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Essa ação não pode ser desfeita. Após a exclusão, os dados referentes ao projeto serão perdidos. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Voltar
        </Button>
        <Button onClick={onClick} color="primary">
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteProject;