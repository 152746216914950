import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const SuccessEndCapture = ({ open, handleClose, onClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Captação encerrada
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sua solicitação foi recebida e será analisada. Em breve entraremos em contato.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessEndCapture;