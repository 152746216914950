import Typography from 'components/Typography';
import React from 'react';
import { DeleteOutline } from '@material-ui/icons';

import { Container } from './styles';

const FileItem = ({ title, onDelete, disabled }) => {
  return (
    <Container>
      <Typography variant="body1" color="blue">
        {title}
      </Typography>
      {!disabled && <DeleteOutline onClick={onDelete} />}
    </Container>
  );
};

export default FileItem;