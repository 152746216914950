const INITIAL_STATE = {
  currentImage: 0,
  images: [],
  show: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@fullScreenImage/ADD_IMAGES':
      return {
        ...state,
        images: action.payload
      };
    case '@fullScreenImage/OPEN_FULLSCREEN':
      return {
        ...state,
        show: true,
      };
    case '@fullScreenImage/CLOSE_FULLSCREEN':
      return {
        ...state,
        show: false,
      };
    case '@fullScreenImage/NEXT_IMAGE':
      if (state.currentImage === (state.images.length - 1)) {
        state.currentImage = 0;
      } else {
        state.currentImage += 1;
      }
      return state;
    case '@fullScreenImage/PREVIOUS_IMAGE':
      if (state.currentImage === 0) {
        state.currentImage = state.images.length - 1;
      } else {
        state.currentImage -= 1;
      }
      return state;
    case '@fullScreenImage/SELECT_IMAGE':
      return {
        ...state,
        currentImage: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
