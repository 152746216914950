import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import {
  DateRange,
  Gavel,
  BookmarkBorderOutlined,
  LocalAtmOutlined,
  LocationOnOutlined,
  PersonOutline,
  AccountBalanceOutlined,
  WarningOutlined
} from '@material-ui/icons';
import Typography from 'components/Typography'

import {
  Header,
  HeaderCompany,
  HeaderTitle,
  ProjectIcon,
  Card,
  CardTitle,
  CardCategory,
  CardContent,
  CardContentDiv,
  Row,
  CardsConatiner,
  EndCaptureContent,
  CardContentRow,
  CardTextContent,
  CardProjectContent,
  CardODSContent
} from './styles';
import ProgressBar from '../ProgressBar';

import ODSCard from '../ODSCard';
import { formatDate } from 'utils/date';
import formatCurrency from 'utils/currency';
import { useCallback } from 'react';
import { LawsService } from 'services/laws';
import defaultImage from 'assets/images/default-project.png';
import CarroselImage from '../CarroselImage';
import Button from 'components/Button';
import { useTheme } from 'styled-components';
import { ProjectService } from 'services/projects';
import { toast } from 'react-toastify';
import ModalInformation from 'components/ModalInformation';
import { AddCaptureProponentModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { ProjectFundingVanquishedModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import ReactTooltip from 'react-tooltip';

const ProjectInfoCard = ({ project }) => {

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const prazoCaptação = (new Date(project?.fundingPeriod)).getTime();

  const hoje = today.getTime();

  const [modalOpen, setModalOpen] = useState('ProjectFundingVanquished');

  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const theme = useTheme();
  const [law, setLaw] = useState('');

  const [capturedValueDate, setCapturedValueDate] = useState(null);

  const loadLaws = useCallback(async () => {
    const response = await LawsService.list();
    setLaw(response.data.find(law => law.id === project?.law)?.description);
  }, [project]);

  const calculateProgress = useCallback((maxValue, currentValue) => {
    const progress = Number(Number(currentValue) * 100 / maxValue).toFixed(2);
    return Number.isNaN(Number(progress)) ? 0 : Number(progress);
  }, []);

  const formatDateColumn = useCallback((fundingPeriod) => {
    var date = new Date(new Date(fundingPeriod).setDate(new Date(fundingPeriod).getDate() + 1));

    return formatDate(date, 'dd/MM/yyyy');
  }, []);

  const updateContribution = useCallback(async (payload) => {
    if(!payload) {
      payload = {
        value: 0
      }
    }
    try {
      const response = await ProjectService.updateCapturedValueProject(project?.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setCapturedValueDate(formatDate(new Date(), 'dd/MM/yyyy'));
        toast.success('Aporte atualizado com sucesso');
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar o aporte do projeto');
      }
    }
  }, [project]);

  useEffect(() => {
    loadLaws();
  }, [loadLaws]);
  
  useEffect(() => {
    const capturedDate = project?.capturedValueDate 
    ? new Date(new Date(project.capturedValueDate).setDate(new Date(project.capturedValueDate).getDate() + 1))
    : null;
    let formattedDate = '-';
    if(capturedDate){
      formattedDate =  formatDate(capturedDate, 'dd/MM/yyyy');
    }

    setCapturedValueDate(formattedDate);
  }, [project]);

  const time = new Date(project?.fundingPeriod);
  const newDate = new Date();
  const images = [];
  images.push(
    {
      id: 0,
      imagen: project?.image != null ? project?.image : defaultImage
    }
  );
  project?.images.map((image, i) => {
    images.push(image);
  });

  newDate.setDate(time.getDate()+1);

  const status = {
    'Em rascunho': 25,
    'Em análise': 50,
    'Em ajuste': 50,
    'Aguardando regularização': 50,
    'Em captação': 75,
    'Incentivado': 100,
    'Não captado': 100,
    'Projeto reprovado': 0,
  }

  const modalRender = useMemo(() => {
    const modal = {
      AddCaptureProponent: (
        <AddCaptureProponentModal
          open={modalOpen === 'AddCaptureProponent'}
          handleClose={() => setModalOpen('')}
          onChange={(captured_value) => {
            updateCapturedValueProject({ captured_value })
          }}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, project]);

  const modalRender2 = useMemo(() => {
    const modal = {
      ProjectFundingVanquished: (
        <ProjectFundingVanquishedModal
          open={true}
          handleClose={() => setModalOpen('')}
          project={project}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, project]);

  const updateCapturedValueProject = useCallback(async (payload) => {
    try {
      const response = await ProjectService.updateCapturedValueProject(project.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        toast.success('Captação atualizada com sucesso!')
        window.location.reload();
      }
    } catch (err) {
      console.log('err', err)
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar a captação do projeto');
      }
    }
  }, [modalInformationData, project]);

  console.log(prazoCaptação < hoje);

  return (
    <Box style={{ padding: '24px' }}>
      <Header>
        <ProjectIcon>
          {
            project?.images ? (
              <CarroselImage files={images} />
            ) : (
              <img src={defaultImage} alt="Capa do projeto" />
            )
          }
        </ProjectIcon>
        <Box marginLeft="16px">
          <HeaderCompany>{project?.proponent}</HeaderCompany>
          <HeaderTitle>{project?.name ? project?.name : 'Dado não informado'}</HeaderTitle>
          <Typography variant="subtitle2" color="darkGray">{project?.edition && project?.edition !== "und" ? (!isNaN(project?.edition) ? project?.edition + "ª edição" : project?.edition + " edição") : 'Edição não informada'}</Typography>
          <Typography variant="body1" color="black" style={{ marginTop: 6 }}>
          {project?.description && project?.description!== "undefined" ? project?.description : 'Descrição não informada.'}
          </Typography>
          {
            (project?.status !== null && project?.status !== "null") && (
              <Box marginTop="15px" width="400px">
                <ProgressBar progress={status[project?.status]} withLabel label={project?.status} isFromStatus/>
              </Box>
            )
          }
        </Box>
      </Header>
      <CardsConatiner>
        <Card className='first-card'>
          <CardContentRow> 
            <Gavel />
            <Typography variant='h6'>Lei de incentivo fiscal</Typography>
          </CardContentRow>
          <Typography variant='subtitle2'>{law || 'Lei não encontrada'}</Typography>
          <CardContentRow className='content-row'>
            <BookmarkBorderOutlined />
            <Typography variant='h6'>Impacto</Typography>
          </CardContentRow>
          <Typography variant="subtitle1" color="darkGray" className='content-title'>Categoria</Typography>
          <Typography variant='subtitle2'>{project?.category ? project?.category : 'Categoria não informada'}</Typography>
          <Typography variant="subtitle1" color="darkGray">Subcategoria</Typography>
          <Typography variant='subtitle2' className="subcategory-scroll">{project?.subcategory ? project?.subcategory : 'Subcategoria não informada'}</Typography>
        </Card>
        <Card className='project-content'>
          <CardProjectContent>
            <div>
              <CardContentRow>
                <LocalAtmOutlined />
                <Typography variant='h6'>Valor do projeto</Typography>
              </CardContentRow>
              <ProgressBar progress={calculateProgress(Number(project?.approvedValue || 0), Number(project?.capturedValue || 0))} withLabel={false} type="black"/>
              <Row style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                <Typography variant='subtitle2' color="black">{calculateProgress(Number(project?.approvedValue || 0), Number(project?.capturedValue || 0))}%</Typography>
                <Typography variant='subtitle2' color="black">{formatCurrency(Number(project?.capturedValue || 0))} de {formatCurrency(Number(project?.approvedValue || 0))}</Typography>
              </Row>
            </div>
            <div>
              <CardContentRow>
                <PersonOutline />
                <Typography variant='h6'>Patrocinadores</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{(project?.sponsors && project?.sponsors !== "undefined" && project?.sponsors !== "null" && project?.sponsors !== null) ? project?.sponsors : 'Patrocinadores não informados'}</Typography>
            </div>
            <div>
              <div className="updateDiv">
                <Button type="button"
                  onClick={() => setModalOpen('AddCaptureProponent')}
                  >
                  Atualizar aportes
                </Button>
                <Button
                  type="button"
                  borderColor={theme.colors.primary}
                  variant="secondary"
                  color={theme.colors.primary}
                  onClick={() => updateContribution(null)}
                >
                  Sem atualização
                </Button>
              </div>
              <Typography variant='subtitle2' className="lastUpdateText">
                <span className="updateText">Última atualização do aporte realizada em:</span> {capturedValueDate}
              </Typography>
            </div>
            <div>
              <CardContentRow>
                <PersonOutline />
                <Typography variant='h6'>Patrocinadores em edições anteriores</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{(project?.previous_sponsors && project?.previous_sponsors !== "undefined" && project?.previous_sponsors !== "null" && project?.previous_sponsors !== null) ? project?.previous_sponsors : 'Patrocinadores em edições anteriores não informados'}</Typography>
            </div>
          </CardProjectContent>
        </Card>
        <Card>
          <EndCaptureContent>
            <div>
              <CardContentRow>
                <DateRange />
                <Typography variant='h6'>Fim da Captação</Typography>
              </CardContentRow>
              <Typography style={{display: 'flex', alignItems: 'center'}} variant='subtitle2'>{project?.fundingPeriod ? formatDateColumn(project?.fundingPeriod) : 'Data não informada.' }
                
                {(prazoCaptação < hoje && project?.fundingPeriod && (project?.status === "Em captação" || project?.status === "Aguardando regularização")) &&
                <>
                  <WarningOutlined 
                    style={{fontSize: '1.2rem', color: 'red'}}
                    data-tip="O prazo de captação expirou." 
                    data-html={true}
                    data-for="tooltip1"
                  />
                  

                    <ReactTooltip 
                      id="tooltip1" 
                      border={true} 
                      borderColor='red' 
                      backgroundColor="#fff" 
                      textColor='red' 
                      place="right" 
                      type="info" 
                      effect="solid"
                    />
                </>
                }

              </Typography>

            </div>
            <div> 
              <CardContentRow>
                <DateRange />
                <Typography variant='h6'>Duração</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{project?.runtimeDays} {project?.runtimeDays > 1 || project?.runtimeDays == 0 ? 'dias' :(project?.runtimeDays == 1 ? 'dia' : '-')}</Typography>
            </div>
            <div>
              <CardContentRow>
                <LocationOnOutlined />
                <Typography variant='h6'>É um projeto itinerante?</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{project?.more_than_one_city === 1 ? 'Sim': 'Não'}</Typography>
            </div>
          </EndCaptureContent>
        </Card>
        <Card>
          <CardProjectContent>
            <div>
              <CardContentRow>
                <AccountBalanceOutlined />
                <Typography variant='h6'>
                  Objetivos de Desenvolvimento Sustentável (ODS)
                </Typography>
              </CardContentRow>
              <div className="ods-div">
              {
                  project?.sd_goals?.map(sdGoal => {
                    return sdGoal.id ? (
                      <ODSCard key={sdGoal.id} type={Number(sdGoal.id) - 1} />
                    ):(
                      <Typography variant='subtitle2'>Não informada</Typography>)
                    }
                  )
                }
              </div>
            </div>
            <div>
              <CardContentRow>
                <PersonOutline />
                <Typography variant='h6'>Público-alvo</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{project?.target && project?.target !=="undefined" ? project?.target : 'Público-alvo não informado'}</Typography>
            </div>
            
            <div>
              <CardContentRow>
                <LocationOnOutlined />
                <Typography variant='h6'>Cidades</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{project?.location?.map(location => location.locationId !== null ? <div style={{padding: '5px', width: '50%'}} key={location.id}>{`${location.city} - ${location.uf}`}</div> : 'Cidades não informadas')}</Typography>
            </div>
            
            <div>
              <CardContentRow>
                <PersonOutline />
                <Typography variant='h6'>Meta do público direto</Typography>
              </CardContentRow>
              <Typography variant='subtitle2'>{project?.audienceGoal}</Typography>
            </div>
          </CardProjectContent>
        </Card>
      </CardsConatiner>
      {modalRender}

      {(prazoCaptação < hoje && project?.fundingPeriod && (project?.status === 'Em captação' || project?.status === "Aguardando regularização")) &&
        modalRender2
      }

      <ModalInformation
        open={showModalInformation}
        handleClose={() => setShowModalInformation(false)}
        {...modalInformationData}
      />
    </Box>
  );
};

export default ProjectInfoCard;
