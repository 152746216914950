import { Modal, InputBase } from '@material-ui/core';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  width: 615px;
  font-family: ${props => props.theme.typography.fontFamily};
  background-color: ${props => props.theme.colors.white};
  position: absolute;
`;

export const ModalHeader = styled.div`
  height: 60px;
  padding: 20px 30px;
  background-color: ${props => props.theme.colors.background};
  border-radius: 15px 15px 0px 0px;
`;

export const ModalTitle = styled.h2`
  ${props => props.theme.typography.h2};
  color: ${props => props.theme.colors.white};
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Label = styled.span`
  ${props => props.theme.typography.span};
  color: ${props => props.theme.colors.background};
  margin: 10px 0px;
`;

export const Description = styled.p`
  ${props => props.theme.typography.p2};
  color: ${props => props.theme.colors.darkGray};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CancelButton = styled.div`
  border-radius: 15px;
  align-content: center;
  cursor: pointer;
  padding: 10px;
  color: ${props => props.theme.colors.background};
  margin: 0px 15px;
`;

export const ConfirmButton = styled.div`
  border-radius: 15px;
  align-content: center;
  cursor: pointer;
  padding: 10px;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.white};
  margin: 0px 15px;
`;

export const ButtonLabel = styled.span`
  ${props => props.theme.typography.span2};
`;

export const CheckBoxLabel = styled.p`
  ${props => props.theme.typography.p5};
`;

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FailureReasonInput = styled(InputBase)`
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.typography.fontFamily};
  padding: 10px;
  height: 36px;
`;
