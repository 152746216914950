import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerModal, Title, Row, Footer, Container } from './styles';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import {
  addCaptation as changeModalStatus,
} from 'store/ducks/modals/actions';

const AddCaptationModal = () => {
  const [data, setData] = React.useState({
    type: '',
    name: '',
    price: 0,
  });

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(data);
    handleClose();
  };

  const dispatch = useDispatch();
  const addCaptation = useSelector(state => state.modals.vitrineModals.addCaptation);
  const handleClose = () => {
    dispatch(changeModalStatus());
  }

  return (
    <Container
      open={addCaptation}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        },
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Row>
          <Select
            name="type"
            className="select"
            label="Tipo de incentivo"
            options={['Captado via Incentiv.me', 'Captado via terceiros']}
            value={data.type}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <Input
            name="name"
            onChange={handleChange}
            label="Nome do incentivador"
            value={data.name}
          />
        </Row>
        <Row>
          <Input
            name="price"
            onChange={handleChange}
            label="Valor do incentivo"
            value={data.price.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          />
        </Row>
        <Footer>
          <Button onClick={handleSubmit}>Adicionar</Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
};
export default AddCaptationModal;
