import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 103%;
  
  h6 + h6 {
    margin-top: 1rem;
  }

  @media (max-width: 1450px) {
    max-width: 49vw;
  }

  @media (max-width: 1370px) {
    max-width: 44vw;
  }

  @media (max-width: 1250px) {
    max-width: 40vw;
  }

  @media (max-width: 960px){
    max-width: 100%;
    width: 100%;
  }
`;

export const FlagState = styled.div`
  min-width: 55px;
  background-color: #cacaca;
  text-align: center;
  margin-right: 5px;
  color: #C15028;
  border-radius: 15px;
  padding: 0 10px;
`;

export const Content = styled.div`
  display: grid;
  margin-top: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  // justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;