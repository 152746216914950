import React, { useRef, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectUi,
  FormHelperText,
} from '@material-ui/core';
import useStyles from './materialStyles';
import './StyleSelect.css';
import { v4 } from 'uuid';

const SelectMultiple = ({
  label,
  value,
  options,
  multipleOptions,
  darkBackground,
  onChange,
  width,
  error,
  role,
  color,
  noValueText = 'Sem dados',
  rounded = false,
  laws,
  firstLaw,
  ...rest
}) => {
  const classes = useStyles({ color: color, rounded: rounded });
  const values = useRef();
  values.current = value
  const [valuesList, setValueList] = useState([values.current]);

  function handleSelect(event){
    setValueList(event.target.value);
    onChange(event.target.value)
  }

  function showLabel(){
    return <div>{value[0]}</div>
  }
  
  return (
    <FormControl
      classes={{ root: classes.customOutline }}
      variant="outlined"
      error={!!error}
      rounded={rounded}
    >
      <InputLabel id="select-label">{label}</InputLabel>
      <SelectUi
        {...rest}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value}
        onChange={handleSelect}
        multiple={true}
        displayEmpty={true}
        renderValue={()=>{return value?.length == 0 ? null  : (value?.length == 1 ? showLabel() : <div>Mais de um selecionado...</div>) }}
        style={{ height: 48, paddingRight: '30px' }}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={v4()}
              value={option}
              style={{ minWidth: 400, width: '100%'}}
              className={role}
            >
              <p style={{ display: 'inline' }}>{option}</p>
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={v4()}
            value={noValueText}
            style={{ minWidth: 400, width: '100%' }}
            className={role}
            disabled
          >
            <em>{noValueText}</em>
          </MenuItem>
        )}
      </SelectUi>
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectMultiple;