import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import StepItem from './StepItem';
import { Container } from './styles';

const Step = ({ currentStep, steps, changeStep, errors }) => {
  const [sidebarErrors, setSidebarErrors] = useState({});
  
  useEffect(() => {
    const keysStage1 = ["legalPerson", "name", "about", "full_name", "email", "cpf", "phone", "corporate_name", "cnpj", "cep","number", "social_contract", "last_election", "social_status"];
    const keysStage2 = ["name_responsible", "cpf_responsible", "phone_responsible", "email_responsible", "name_register_responsible", "email_register_responsible", "phone_register_responsible", "full_name_witness", "witness_email"];
    const keysStage3 = ["photo_profile", "photosCarousel"];

    if(errors){
      setSidebarErrors({
        1: Object.keys(errors).some(x => keysStage1.includes(x)),
        2: Object.keys(errors).some(x => keysStage2.includes(x)),
        3: Object.keys(errors).some(x => keysStage3.includes(x)),
        4: false
      });
    }
  }, [errors]);

  return (
    <Container>
      {steps.map((step, i) => (
        <StepItem
          key={step.title}
          number={step.number}
          active={step.number <= currentStep}
          title={step.title}
          content={step.description}
          onClick={step => changeStep(step)}
          error={sidebarErrors?.[i+1]}
        />
      ))}
    </Container>
  );
};

export default Step;
