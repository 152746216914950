import { action } from 'typesafe-actions';

export const login = (data, persist) => action('@auth/LOGIN', {data, persist});

export const logout = () => action('@auth/LOGOUT');

export const auth = () => action('@auth/AUTH');

export const updateUser = data => action('@auth/UPDATE_USER', data);

export const updateUserAvatar = data => action('@auth/UPDATE_USER_AVATAR', data);
