import Typography from 'components/Typography';
import React, { useState, useEffect, useMemo } from 'react';
import Button from 'components/Button';

import { Container, Title, ContentBody, GoalTextContent, Content } from './styles';

const ProjectOffsetsCard = ({ project, extraMargin = false }) => {
  

  return (
    <Container style={{ margin: extraMargin ? '24px': 0 }}>
      {
        project?.quota && (
          project.quota.map((q) => {
            return (
              <>
                <Title key={q.id} variant="h6" color="grayDark2">Quota: {q.quota} || Valor de: {q.valueOf} Até: {q.valueUpTo}</Title>
                <div>
                  {
                    project.counterpart.map((c)=>{
                      return c.project_quota_id == q.id && (
                        <>
                          <ContentBody style={{ marginBottom: '1.25rem'}}>
                            <GoalTextContent className={q.quota > 250 && 'isEllipsis'}>
                              <Typography variant="body2" color="grayDark2">Contrapartida</Typography>
                              <Typography key={c.id} variant="manrope" color="brown">{c.description}</Typography> 
                            </GoalTextContent>
                          </ContentBody>
                        </>
                      )
                    })
                  }
                </div>
              </>
            )
          })
        )


      }
    </Container>
    
  );
};

export default ProjectOffsetsCard;