import React, { useState, useCallback, useEffect, useRef } from 'react';
import AdmProjectsTableFilter from 'components/AdmProjectsTableFilter';

import { Container } from './styles';
import { ProjectService } from 'services/projects';
import AdmProjectsForms from 'components/Forms/AdmProjectsForms';
import AdmProjectsTable from 'components/AdmProjectsTable';
import { LawsService } from 'services/laws';

const AdminProponenteProjects = ({ id }) => {
  const [filters, setFilters] = useState({
    proponentId: id,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [laws, setLaws] = useState([]);

  const handleChangeShowFilter = useCallback(() => {
    setShowFilter(prevState => !prevState);
  }, []);

  const loadProjects = useCallback(async () => {
    console.log("Entra loadprojects?");
    try {
      setLoading(true);
      const { data } = await ProjectService.loadAllProjectsByProponent(id);
      setProjects(data);
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const loadProjectsByFilter = useCallback(async () => {
    console.log("Entra loadProjectsByFilter?$$$");
    try {
      setLoading(true);
      const { data } = await ProjectService.searchProjects(filters);
      // if (Array.isArray(data)) {
      //   setProjects(data);
      //   console.log(data);
      // }
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const searchByProjectName = async search => {
    console.log("Entra searchByProjectName????? ");
    const { data } = await ProjectService.listProjects();    
    const filteredProjects = data.filter(project =>
      project.name.toLowerCase().includes(search.toLowerCase()) && project.proponentId == id
    );
    setProjects(filteredProjects);
    if (search === '') loadProjects();
  };

  const loadLaws = useCallback(async () => {
    const response = await LawsService.list();
    setLaws(response.data);
  }, []);

  useEffect(() => {
    Promise.all([
      loadProjects(),
      loadLaws()
    ]);
  }, [loadProjects, loadLaws]);

  return (
    <Container>
      <AdmProjectsTableFilter
        showFilter={handleChangeShowFilter}
        search={searchByProjectName}
        onSearch={loadProjectsByFilter}
        setFilters={setFilters}
        filters={filters}
      />
      {showFilter && (
        <div style={{ marginBottom: 26 }}>
          <AdmProjectsForms setFilters={setFilters} filters={filters} />
        </div>
      )}
      <AdmProjectsTable
        projects={projects}
        onRefresh={() => loadProjects()}
        isLoading={loading}
        laws={laws}
      />
    </Container>
  );
};

export default AdminProponenteProjects;
