import styled from 'styled-components';

export const Container = styled.div`
  width: 580px;
  padding: 3rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Form = styled.form`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ActionsContainer = styled.div`
  margin-top: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0.625rem;
  }

  .MuiOutlinedInput-root {
    height: 48px;   
  }

  .MuiAutocomplete-inputRoot{
    padding: 8px 14px!important;
  }

  // remove o botão de clear padrão do input
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  gap: 1rem;
`;