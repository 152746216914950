import { AddCircle } from '@material-ui/icons';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import UsersTable from 'components/UsersTable';
import React, { useCallback, useEffect, useState } from 'react';
import { Header, UsersContainer } from './styles';
import { useDispatch } from 'react-redux';
import { toggleCreateUserModal } from 'store/ducks/modals/actions';
import { UsersService } from 'services/users';
import { SalesforceService } from 'services/salesforce';

import UsersModal from 'components/UsersModal';
import DeleteModal from 'components/UsersModal/DeleteModal';
import SuccessModal from 'components/UsersModal/SuccessModal';
import BiddersModal from 'components/UsersModal/BiddersModal';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const rolesOptions = [
    'Mestre',
    'Prospecção',
    'Backoffice CS Proponente',
    'Suporte',
    'Avaliador',
    'Geral',
    'Key Account Nível 2',
    'CS Proponente Nível Relacionamento',
  ];
  const loadSponsors = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await UsersService.listAdminUsers();
      // const getToken = await SalesforceService.getToken();
      // const refreshToken = await SalesforceService.refreshToken();

      setUsers(data);
      setLoading(false);
    } catch (err) {
      alert(err);
      console.log(err.message);
      setLoading(false);
    }
  }, [setUsers]);

  useEffect(() => {
    loadSponsors();
  }, []);

  const dispatch = useDispatch();

  const createUser = () => {
    dispatch(toggleCreateUserModal());
  };

  return (
    <div>
      <UsersModal refreshData={loadSponsors} rolesOptions={rolesOptions}/>
      <DeleteModal refreshData={loadSponsors} />
      <SuccessModal refreshData={loadSponsors} />
      <BiddersModal refreshData={loadSponsors} />
      <Header>
        <PageTitle
          title="Usuários"
          subtitle="Cadastre abaixo os usuários para acessar a conta administrador"
        />
        {/* <Button variant="primary" onClick={createUser} className="squared">
          <AddCircle />
          ADICIONAR USUÁRIO
        </Button> */}
      </Header>
      <UsersContainer>
        <UsersTable data={users} isLoading={loading} />
      </UsersContainer>
    </div>
  );
};

export default UsersPage;
