import React from 'react';
import { Typography, Box } from '@material-ui/core';
import {
  TabPanelButton,
  TabPanelButtonLabel,
  TabPanelContainer,
  TabContent,
  Container,
} from './styles';

const TabPanel = ({ children, index, value, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Tabs = ({ tabs, screens, notPadding = false, cadProject = false }) => {
  const [activeTab, setActiveTab] = React.useState(cadProject ? 3 : 0);

  return (
    <Container>
      <TabPanelContainer>
        {tabs.map((tab, index) => (
          <TabPanelButton
            style={{ backgroundColor: activeTab !== index ? '#FFFFFF' : '' }}
            onClick={() => setActiveTab(index)}
          >
            <TabPanelButtonLabel
              style={{ color: activeTab === index ? '#FFFFFF' : '' }}
            >
              {tab}
            </TabPanelButtonLabel>
          </TabPanelButton>
        ))}
      </TabPanelContainer>
      <TabContent notPadding={notPadding}>
        {screens?.map((screen, index) => (
          <TabPanel value={activeTab} index={index}>
            {screen}
          </TabPanel>
        ))}
      </TabContent>
    </Container>
  );
};

export default Tabs;
