import React, { useCallback, useEffect, useState } from 'react';
import { Container, Header, ProjectsContainer } from './styles';
import PageTitle from 'components/PageTitle';
import AddCircle from '@material-ui/icons/AddCircle';
import Button from 'components/Button';
import NotificationsFilter from 'components/NotificationsFilter';
import NotificationsFilterAdmin from 'components/NotificationsFilterAdmin';
import NotificationsTable from 'components/NotificationsTable';
import { NotificationService } from 'services/notifications';
import NotificationForm from 'components/NotificationForm';
import NotificationFormAdmin from 'components/NotificationFormAdmin';
import { useUser } from 'hooks/useUser';

const Notifications = () => {
  const { user } = useUser();
  const [notifications, setNotifications] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [isPaginated, setIsPaginated] = useState(false);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
  });

  const loadNotifications = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        if (Object.keys(filters).length > 0) {
          const response = await NotificationService.search(filters, page);
          if (Array.isArray(response.data.data)) {
            setNotifications(response.data.data);
            setPagination({
              currentPage: response.data.current_page,
              totalPages: response.data.last_page,
            });
            setIsPaginated(true);
          } else {
            setIsPaginated(false);
            setNotifications([]);
          }
        } else {
          const response = await NotificationService.list(page);
          if (Array.isArray(response.data)) {
            setIsPaginated(false);
            setNotifications(response.data);
          } else {
            setIsPaginated(true);
            setPagination({
              currentPage: response.data.current_page,
              totalPages: response.data.last_page,
            });
            setNotifications(response.data.data);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [filters],
  );

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <Container>
      <main>
        <Header>
          <PageTitle
            title="Central de notificação"
            subtitle={
              user.isAdmin
                ? 'Notifique os proponentes sobre as novidades e problemas aqui.'
                : 'Saiba as novidades mais recentes sobre o(s) seu(s) projeto(s)!'
            }
          />
          {/* {(['master', 'cs_proponent2', 'support', 'evaluator', 'cs_proponent_relationship'].includes(user.adminRole) || !user.isAdmin) && (
            <Button
              variant="primary"
              onClick={() => setOpenForm(true)}
              className={user.isAdmin && 'squared'}
            >
              <AddCircle />
              Enviar mensagem
            </Button>
          )} */}
        </Header>
        {(notifications?.length > 0 || (notifications?.length === 0 && Object.keys(filters).length > 0)) &&
          (user.isAdmin ? (
            <NotificationsFilterAdmin
              filters={filters}
              setFilters={setFilters}
            />
          ) : (
            <NotificationsFilter filters={filters} setFilters={setFilters} />
          ))}
        <ProjectsContainer className={user.isAdmin && 'isAdmin'}>
          <NotificationsTable
            notifications={notifications}
            onRefresh={() => loadNotifications()}
            isLoading={loading}
            isPaginated={isPaginated}
            {...pagination}
            onChangePage={page => loadNotifications(page)}
          />
        </ProjectsContainer>
      </main>
      {user.isAdmin ? (
        <NotificationFormAdmin
          open={openForm}
          onClose={() => setOpenForm(false)}
          onAfterSave={() => loadNotifications()}
        />
      ) : (
        <NotificationForm
          open={openForm}
          onClose={() => setOpenForm(false)}
          onAfterSave={() => loadNotifications()}
        />
      )}
    </Container>
  );
};

export default Notifications;
