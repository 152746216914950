import styled from 'styled-components';
import { Modal } from '@material-ui/core';

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background: #fff;
  /* min-width: 35rem; */
  padding: 48px 40px;
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px;

  b {
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 10px;

  .select {
    width: 211px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 20px;
  gap: 10px;
`;
