import React, { useCallback, useState, useEffect, useRef } from 'react';
import AdmProjectsTable from 'components/AdmProjectsTable';
import AdmProjectsTableFilter from 'components/AdmProjectsTableFilter';
import AdmProjectsForms from 'components/Forms/AdmProjectsForms';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import { Container, Header, ProjectsContainer, DashboardItem, Dashboard } from './styles';
import { ProjectService } from 'services/projects';
import { useHistory } from 'react-router-dom';
import { AddCircle, SaveAlt } from '@material-ui/icons';
import { LawsService } from 'services/laws';
import Typography from 'components/Typography';
import formatCurrency from 'utils/currency';
import { CreateProjectModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { useMemo } from 'react';

const AdmProjects = () => {
  const timerRef = useRef(null);
  const [filters, setFilters] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [laws, setLaws] = useState([]);
  const { push } = useHistory();
  const [isPaginated, setIsPaginated] = useState(false);
  const [dashboard, setDashboard] = useState({
    proponents_total: null,
    projects_total: null,
    approved_value: null,
    incentiv_captured_total: null,
    in_analysis_today: null,
    in_analysis_seven_days: null,
    in_analysis_thirty_days: null,
  });

  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
  });
  const [name, setName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  
  const filtersRef = useRef();

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const handleChangeShowFilter = useCallback(() => {
    setShowFilter(prevState => !prevState);
  }, []);

  const modalRender = useMemo(() => {
    const modal = {
      createProject: (
        <CreateProjectModal
          open={modalOpen === 'createProject'}
          handleClose={() => setModalOpen('')}
          isAdmin = {true}
          // onChange={(captured_value, sponsor_id, date) => {
          //   updateCapturedValueProject({ captured_value, sponsor_id, date })
          // }}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);

  const loadProjects = useCallback(async (page = 1) => {
    try {
      setLoading(true);
      if (Object.keys(filtersRef.current).length > 0) {
        loadProjectsByFilter(page);
      } else {
        const { data } = await ProjectService.listProjectsPagination(null, page);
        setProjects(data.data);
        setPagination({
          currentPage: data.current_page,
          totalPages: data.last_page,
        });
        setIsPaginated(true);
      }
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, [setProjects]);

  const exportToCsv = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await ProjectService.exportToCsv(filtersRef.current);
      downloadBlob(data)

    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, []);

  const downloadBlob = useCallback(async (content) => {
    var blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    var url = URL.createObjectURL(blob);
  
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);

  const loadProjectsByFilter = useCallback(async (page = 1) => {
    setName(filtersRef.current.name)
    const filter = {
      ...filtersRef.current,
      name: name
    }
    setFilters(filter);
    
    try {
      setLoading(true);
      const { data } = await ProjectService.searchProjects(filtersRef.current , page);
      // if (Array.isArray(data)) {
        setProjects(data.data);
        setPagination({
          currentPage: data.current_page,
          totalPages: data.last_page,
        });
        setIsPaginated(true);
        const isShowDash = data.data !== undefined;
        setShowDashboard(isShowDash);
        const exploded_url = (data.first_page_url).split('projects-search');
        const urlParams = exploded_url[1];
        const query = new URLSearchParams(urlParams);

        setDashboard({
          proponents_total: query.get("proponents_total"),
          projects_total: query.get("projects_total"),
          approved_value: query.get("approved_value"),
          incentiv_captured_total: query.get("incentiv_captured_total"),
          in_analysis_today: query.get("in_analysis_today"),
          in_analysis_seven_days: query.get("in_analysis_seven_days"),
          in_analysis_thirty_days: query.get("in_analysis_thirty_days"),
        })
      // }
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, [filters, name]);  

  const loadLaws = useCallback(async () => {
    const response = await LawsService.list();
    setLaws(response.data);
  }, []);

  useEffect(() => {
    Promise.all([
      loadProjects(),
      loadLaws()
    ]);
  }, [loadProjects, loadLaws]);

  const loadFilter = async (search, page = 1) => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      searchByProjectName(search, page)
    }, 2000);
  }

  const searchByProjectName = async (search, page = 1) => {
    setLoading(true);
    const filter = {
      name: search
    }

    setName(search);

    filtersRef.current = {
      ...filtersRef.current,
      name: search
    }

    const { data } = await ProjectService.searchProjects(filtersRef.current, page);
    const filteredProjects = data.data?.filter(project =>
      project.name.toLowerCase().includes(search.toLowerCase()),
    );
    setProjects(filteredProjects);
    setPagination({
      currentPage: data.current_page,
      totalPages: data.last_page,
    });
    setIsPaginated(true);
    if (search === '') loadProjects();
    setLoading(false);
  };

  useEffect(() => {
    if(filters.analysis_date){
      loadProjectsByFilter();
    }
  }, [filters]);

  return (
    <Container>
      <main>
        <Header>
          <PageTitle
            title="Projetos"
            subtitle="Esses são os projetos cadastrados conosco."
            type="adm"
          />
          {/* <Button variant="primary" onClick={() => push('/projetos-registro')}>
            <AddCircle />
            ADICIONAR PROJETO
          </Button> */}
          {/* <Button variant="primary" onClick={() => setModalOpen('createProject')}>
            <AddCircle />
            ADICIONAR PROJETO
          </Button> */}
        </Header>
        <ProjectsContainer>
          <div style={{ marginBottom: 16 }}>
            <AdmProjectsTableFilter
              showFilter={handleChangeShowFilter}
              search={loadFilter}
              onSearch={loadProjectsByFilter}
              isLoading={loading}
              setFilters={setFilters}
              filters={filters}
            />
          </div>
          {showFilter && (
            <div style={{ marginBottom: 26 }}>
              <AdmProjectsForms setFilters={setFilters} filters={filters} />
            </div>
          )}
          {showDashboard && (
            <>
              <Dashboard>
                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                    Foram encontrados:
                  </Typography>
                  <Typography variant="h5" color="darkGray">{dashboard.proponents_total} Proponentes</Typography>
                </DashboardItem>

                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                    Foram encontrados:
                  </Typography>
                  <Typography variant="h5" color="darkGray">
                    {dashboard.projects_total} Projetos
                  </Typography>
                </DashboardItem>

                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                    Valor total em captação:
                  </Typography>
                  <Typography variant="h5" color="darkGray">
                    {formatCurrency(Number(dashboard.approved_value), true, true)}
                  </Typography>
                </DashboardItem>
                
                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                    Valor captado pela Incentiv.me:
                  </Typography>
                  <Typography variant="h5" color="darkGray">
                  {formatCurrency(Number(dashboard.incentiv_captured_total), true, true)}
                  </Typography>
                </DashboardItem>

              </Dashboard>
              <Dashboard style={{ marginBottom: 26 }}>
                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                    Projetos em análise - Hoje:
                  </Typography>
                  <Typography variant="h5" color="darkGray">
                  {dashboard.in_analysis_today}
                  </Typography>
                </DashboardItem>

                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                  Projetos em análise - Últimos 7 dias:
                  </Typography>
                  <Typography variant="h5" color="darkGray">
                  {dashboard.in_analysis_seven_days}
                  </Typography>
                </DashboardItem>

                <DashboardItem>
                  <Typography variant="body1" style={{ marginBottom: 5 }}>
                  Projetos em análise - Últimos 30 dias:
                  </Typography>
                  <Typography variant="h5" color="darkGray">
                  {dashboard.in_analysis_thirty_days}
                  </Typography>
                </DashboardItem>
              </Dashboard>
              <Button variant="secondary"
                onClick={() => exportToCsv()}
                style={{ width: 'fit-content', marginBottom: 16,alignSelf: 'end' }}>
                <SaveAlt />
                Exportar como CSV
              </Button>
            </>
          )}
          <AdmProjectsTable
            projects={projects}
            onRefresh={() => loadProjects()}
            isLoading={loading}
            laws={laws}
            {...pagination}
            onChangePage={page => name ? searchByProjectName(name, page) : loadProjects(page)}
            isPaginated={isPaginated}
          />
        </ProjectsContainer>
        {modalRender}
      </main>
    </Container>
  );
};

export default AdmProjects;
