import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerModal, Title, Row, Footer, Container } from './styles';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import {
  changeBidder as changeModalStatus,
} from 'store/ducks/modals/actions';

const ChangeBidder = () => {
  const [data, setData] = React.useState({
    name: '',
  });

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(data);
    handleClose();
  };

  const dispatch = useDispatch();
  const changeBidder = useSelector(state => state.modals.vitrineModals.changeBidder);
  const handleClose = () => {
    dispatch(changeModalStatus());
  }

  return (
    <Container
      open={changeBidder}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        },
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Row>
          <Input
            name="name"
            onChange={handleChange}
            label="Nome do proponente"
            value={data.name}
          />
        </Row>
        <Footer>
          <Button onClick={handleSubmit}>Transferir</Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
};
export default ChangeBidder;
