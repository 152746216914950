import { toast } from 'react-toastify';

export const orderFeedbackErrorMessage = (data) => {
    Object.values(data).filter(errorMessage =>{
        if(String(errorMessage).startsWith('1')){
            return toast.error(String(errorMessage))
        }
    });
    Object.values(data).filter(errorMessage =>{
        if(String(errorMessage).startsWith('2')){
            return toast.error(String(errorMessage))
        }
    });
    Object.values(data).filter(errorMessage =>{
        if(String(errorMessage).startsWith('3')){
            return toast.error(String(errorMessage))
        }
    });
    Object.values(data).filter(errorMessage =>{
        if(String(errorMessage).startsWith('4')){
            return toast.error(String(errorMessage))
        }
    });
    Object.values(data).filter(errorMessage =>{
        if(String(errorMessage).startsWith('5')){
            return toast.error(String(errorMessage))
        }
    });
    Object.values(data).filter(errorMessage =>{
        if(String(errorMessage).startsWith('6')){
            return toast.error(String(errorMessage))
        }
    });
};