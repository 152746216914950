import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from 'components/Typography';
import { formatDate } from 'utils/date';
import { Container, Link } from './styles';
import { ChatBubbleOutline, OpenInNew } from '@material-ui/icons';
import Button from 'components/Button';
import { Admin } from 'themes';

const BidderTableDetail = props => {
  return (
    <>
      <Container>
        <div>
          <div>
            <Typography variant="body2" color="grayDark2">
              CNPJ
            </Typography>
            <Typography variant="manrope" color="brown">
              {props?.cnpj || '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="body2" color="grayDark2">
              Telefone
            </Typography>
            <Typography variant="manrope" color="brown">
              {props?.phone || '-'}
            </Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography variant="body2" color="grayDark2">
              Endereço
            </Typography>
            <Typography variant="manrope" color="brown">
              {props?.address || '-'}
            </Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography variant="body2" color="grayDark2">
              Proj. em captação
            </Typography>
            <Typography variant="manrope" color="brown">
              {props?.projectsCaption || '-'}
            </Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography variant="body2" color="grayDark2">
              Arquivos
            </Typography>
            <Link href="#" rel="noreferrer" target="_blank">
              <OpenInNew color="red" />
              <Typography variant="manrope" color="blue">
                Estatuto social
              </Typography>
            </Link>
            <Link href="#" rel="noreferrer" target="_blank">
              <OpenInNew color="red" />
              <Typography variant="manrope" color="blue">
                Ato da última eleição
              </Typography>
            </Link>
          </div>
        </div>
        {/* <div>
          <Typography variant="body2" color="grayDark2">
            Responder notificação
          </Typography>
          <Link href="#" rel="noreferrer" target="_blank">
            <ChatBubbleOutline color="red" />
            <Typography variant="manrope" color="blue">
              Enviar notificação
            </Typography>
          </Link>
        </div> */}
      </Container>
    </>
  );
};

export default BidderTableDetail;
