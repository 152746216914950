import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.36);
`;

export const Navigation = styled.nav`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  span {
    display: inline-block;
    position: relative;
    padding: 0 54px;
    /* width: 180px; */
    cursor: pointer;

    line-height: 5rem;
    color: #ccc;

    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: white;
    }

    &.active-item {
      div {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
      }
    }

    &.active-item::after {
      content: '';
      height: 2px;
      border-radius: 3px 3px 0 0;
      width: 100%;
      position: absolute;
      bottom: -18px;
      left: 0;
      /* background-color: #757575; */
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;

  text-align: center;
  text-transform: uppercase;

  color: ${({ active, theme }) =>
    active ? theme.colors.orange : theme.colors.grayDark1};
`;
