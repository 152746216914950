import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${rgba('black', 0.15)};
  border-radius: 10px;
  padding: 0 0.75rem;

  svg {
    cursor: pointer;
    color: ${rgba('black', 0.36)};
  }
`;
