import Checkbox from '@material-ui/core/Checkbox';
import Input from 'components/Input';
import Typography from 'components/Typography';
import React, { useCallback, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { phoneMask, cpfMask } from 'utils/maskUtils';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from 'styled-components';
import './style.css';

import {
  Container,
  Content,
  Row,
  Body
} from './styles';
import ReactTooltip from 'react-tooltip';
import { useMemo } from 'react';

const WitnessAndLocation = ({legalPerson}) => {
  const { setValue, getValues, clearErrors, control, formState: { errors } } = useFormContext();
  const [checked, setChecked] = useState(false);
  const isDisabled = getValues('register_status') === 'Em análise' || getValues('register_status') === 'Concluído';
  const [personType, setPersonType] = useState(0);
  const theme = useTheme();
  
  const limitNumber = useCallback(
    value => {
      const valueF = ((value.toString()).substring(0, value.length - 1));
      setValue('number', valueF);
    },

    [setValue]
  );

  const handleAddResponsible = useCallback(
    value => {
      setChecked(value);
      if (value) {
        const full_name_witness = getValues('name_register_responsible');
        const witness_email = getValues('email_register_responsible');
        setValue('full_name_witness', full_name_witness);
        setValue('witness_email', witness_email);
      } else {
        setValue('full_name_witness', ' ');
        setValue('witness_email', ' ');
      }
    },
    [setValue, getValues],
  );

  const legalPersons = useMemo(
    () => [
      'Pessoa física',
      'Entidade Empresarial',
      'Entidade sem fins lucrativos',
      'Administração Pública Federal',
      'Administração Pública Estadual',
      'Administração Pública Municipal'
    ],
    [],
  );

  useEffect(() => {
    if (!getValues('public_place')) setValue('public_place', ' ');
    if (!getValues('district')) setValue('district', ' ');
    if (!getValues('state')) setValue('state', ' ');
    if (!getValues('city')) setValue('city', ' ');
    if (!getValues('full_name_witness')) setValue('full_name_witness', ' ');
    if (!getValues('witness_email')) setValue('witness_email', '');

    if (legalPerson || getValues('legalPerson')) {
      setPersonType(legalPersons.findIndex(
        item => item === (getValues('legalPerson') || legalPerson),
      ));
      
    }
  }, [legalPerson, legalPersons, getValues, setValue]);

  return (
    <Container>
        {personType !== 0 && (
          <>
          <Typography variant="h6" color="grayDark2">
            Dados do responsável legal
          </Typography>
          <Body>
            <Row colSize={4}>
              {/* *Obrigatório */}
              <Controller
                name="name_responsible"
                control={control}
                render={({ field }) => (
                  <Input 
                    data-tip="O responsável legal é a pessoa que <br/>
                    possui poderes para assinar o contrato <br/>
                    representando a empresa. Ele deve constar no <br/>
                    Quadro Societário ou no Contrato Social/Estatuto/Ata, <br/>
                    ou ainda, em procuração que lhe outorgue poderes para isso." 
                    data-html={true}
                    data-for="tooltip1"
                    data-event='click focus'
                    label="Nome completo" 
                    {...field}
                    disabled={isDisabled}
                    required={true}    
                    margin={70} 
                    error={errors["name_responsible"]?.message}
                    onChange={e => {
                      setValue("name_responsible", e.target.value);

                      if(errors["name_responsible"]?.message === "O nome da testemunha não pode ser igual o nome do responsável legal.")
                        clearErrors("full_name_witness");
  
                      clearErrors("name_responsible");
                    }}
                  />
                )}
              />
              <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

              {/* *Obrigatório */}
              <Controller
                name="cpf_responsible"
                control={control}
                render={({ field }) => (
                  <Input
                    label="CPF"
                    {...field}
                    onChange={e => {
                      const value = cpfMask(e.target.value);
                      setValue('cpf_responsible', value);
                      clearErrors("cpf_responsible");
                    }}
                    required={true}    
                    margin={70} 
                    disabled={isDisabled}
                    error={errors["cpf_responsible"]?.message}
                  />
                )}
              />
              {/* *Obrigatório */}
              <Controller
                name="phone_responsible"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Telefone"
                    {...field}
                    onChange={e => {
                      const value = phoneMask(e.target.value);
                      setValue('phone_responsible', value);
                      clearErrors("phone_responsible");
                    }}
                    required={true}    
                    margin={70}
                    disabled={isDisabled}
                    error={errors["phone_responsible"]?.message}
                  />
                )}
              />
              {/* *Obrigatório */}
              <Controller
                name="email_responsible"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Email"
                    {...field}
                    disabled={isDisabled}
                    required={true}    
                    margin={70}
                    error={errors["email_responsible"]?.message}
                    onChange={e => {
                      setValue("email_responsible", e.target.value);

                      if(errors["email_responsible"]?.message === "O email da testemunha não pode ser igual o email do responsável legal.")
                        clearErrors("witness_email");
  
                      clearErrors("email_responsible");
                    }}
                  />
                )}
              />
            </Row>
          </Body>
          </>
        )}

      <Content></Content>
      <Typography variant="h6" color="grayDark2">
        Dados do responsável pelo cadastro
        <InfoIcon 
          data-for="tooltip2" 
          data-tip="O responsável pelo cadastro é a pessoa<br/> 
          que faz a gestão do projeto e que poderá <br/>
          responder nossas dúvidas. Ela pode ser a mesma <br/>
          pessoa que o responsável legal ou não." 
          multiline={true} 
          data-html={true} 
          style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute'}} />

      </Typography>
      <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

      <Typography variant="subtitle2" color="grayLight1">
        Preencha com os seus dados pessoais.
      </Typography>

      <Content>
        <Row colSize={3}>
          {/* *Obrigatório */}
          <Controller
            name="name_register_responsible"
            control={control}
            render={({ field }) => (
              <Input
                label="Nome completo"
                {...field}
                required={true}
                margin={78}
                disabled={isDisabled}
                error={errors["name_register_responsible"]?.message}
              />
            )}
          />
          {/* *Obrigatório */}
          <Controller
            name="email_register_responsible"
            control={control}
            render={({ field }) => (
              <Input
                label="E-mail"
                {...field}
                required={true}
                margin={78}
                disabled={isDisabled}
                error={errors["email_register_responsible"]?.message}
              />
            )}
          />
          {/* *Obrigatório */}
          <Controller
            name="phone_register_responsible"
            control={control}
            render={({ field }) => (
              <Input
                label="Telefone"
                {...field}
                onChange={e => {
                  clearErrors("phone_register_responsible");
                  const value = phoneMask(e.target.value);
                  setValue('phone_register_responsible', value);
                }}
                required={true}
                margin={78}
                disabled={isDisabled}
                error={errors["phone_register_responsible"]?.message}
              />
            )}
          />
        </Row>
      </Content>
      <Body>
        <Typography variant="h6" color="grayDark2">
          Dados da testemunha
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
        Para ser validado, nosso contrato digital exige a assinatura de uma testemunha, que não pode ser o representante legal, deve ter mais de 18 anos e não precisa ter vínculo com o projeto.
        </Typography>
        <Content>
          <Typography variant="body1">
            <Checkbox
              checked={checked}
              onChange={e => handleAddResponsible(e.target.checked)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{ width: 'fit-content' }}
              disabled={isDisabled}
            />
            Mesmos dados do responsável pelo cadastro
          </Typography>
          
          <Row colSize={2}>
            <Controller
              name="full_name_witness"
              control={control}
              render={({ field }) => (
                <Input
                  label="Nome completo da testemunha"
                  {...field}
                  disabled={isDisabled}
                  error={errors["full_name_witness"]?.message} 
                  data-tip="A testemunha precisa ser diferente do responsável<br/>
                  legal indicado anteriormente. Pode ser qualquer pessoa <br/>
                  maior de idade." 
                  data-html={true}
                  data-for="tooltip1"
                  data-event='click focus'
                  required={true}
                  margin={85}
                  onChange={e => {
                    setValue("full_name_witness", e.target.value);

                    if(errors["full_name_witness"]?.message === "O nome da testemunha não pode ser igual o nome do responsável legal.")
                      clearErrors("name_responsible");

                    clearErrors("full_name_witness");
                  }}
                />
              )}
            />
            <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
            {/* *Obrigatório */}
            <Controller
              name="witness_email"
              control={control}
              render={({ field }) => {
                field.value = getValues('witness_email');
                return(
                  <Input
                    label="E-mail da testemunha"
                    {...field}
                    required={true}
                    margin={85}
                    disabled={isDisabled}
                    error={errors["witness_email"]?.message}
                    onChange={e => {
                      setValue("witness_email", e.target.value);
                      
                      console.log("Oi", e.target.value);
                      console.log("Olá", getValues('witness_email'));

                      if(errors["witness_email"]?.message === "O email da testemunha não pode ser igual o email do responsável legal.")
                        clearErrors("email_responsible");

                      clearErrors("witness_email");
                    }} 
                  />
                  );
                }}
            />
          </Row>
        </Content>
      </Body>
    </Container>
  );
};

export default WitnessAndLocation;
