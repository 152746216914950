import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;

  span {
    padding: 1px !important;
  }
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: ${rgba('#BC0A0A', 0.05)};
`;

export const ChildrenContainer = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
`;
