import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const profileAdminSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email('Email inválido').required('Email obrigatório'),
  password: Yup.string()
    .nullable().transform((o, c) => o === "" ? null : c)
    .matches(/^(?=.*[!$*&@#])/, "Deve conter um caractere especial")
    .matches(/^(?=.*[a-z])/, "Deve conter um caractere minúsculo")
    .matches(/^(?=.*[A-Z])/, "Deve conter um caractere maiúsculo")
    .matches(/^(?=.*[0-9])/, "Deve conter um número")
    .min(6, 'Mínimo de 6 caracteres')
    .max(30, 'Máximo de 30 caracteres')
  ,
  new_password: Yup.string()
  .when('password', {
    is: (password) => password && password.length > 0,
    then: Yup.string()           
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
        .required('Confirme a senha'),
    })
});

export const profileAdminValidationResolver = yupResolver(profileAdminSchemaValidation);
