import React, { useCallback, useMemo, useState } from 'react';
import { CalendarTodayOutlined } from '@material-ui/icons';
import {
  Container,
  ItemTitle,
  BackButton,
  NextButton,
  Content,
  CurrentImage,
  NoImagesContainer,
} from './styles';
import Typography from 'components/Typography';
import { useFullScreenImages } from 'hooks/useFullScreenImages';
import noImagesAdmin from 'assets/svgs/no-images-admin.svg';
import noImagesProponent from 'assets/svgs/no-images-proponent.svg';
import { useUser } from 'hooks/useUser';

const CarroselImage = ({ files }) => {
  const { open, addImages } = useFullScreenImages();
  const { user } = useUser();
  const [currentImage, setCurrentImage] = useState(0);
  console.log(files);
  const images = useMemo(() => {
    if (Boolean(files) && files.length > 0) {
      const filesList = Array.isArray(files) ? files : JSON.parse(files);
      if (Array.isArray(filesList)) {
        const images = filesList
          .map(image => image.imagen)
          .filter(image => image !== undefined);
        addImages(images);
        return images;
      }
      return [];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const nextImage = useCallback(() => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(oldValue => oldValue + 1);
    }
  }, [currentImage, images]);

  const previousImage = useCallback(() => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(oldValue => oldValue - 1);
    }
  }, [currentImage, images]);

  return (
    <Container>
      <Content>
        {images.length > 0 ? (
          <>
            <CurrentImage
              src={images[currentImage] || []}
              onClick={() => open()}
            />
          </>
        ) : (
          <NoImagesContainer>
            <img
              src={user.isAdmin ? noImagesAdmin : noImagesProponent}
              alt="No Images"
            />
            <Typography variant="h6">
              Nenhuma imagem ou vídeo cadastrado
            </Typography>
          </NoImagesContainer>
        )}
      </Content>
    </Container>
  );
};

export default CarroselImage;
