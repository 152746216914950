import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Card = styled(Grid)`
  margin-top: 30px;
  border-radius: 30px;
  background-color: ${props => props.theme.colors.white};
  padding: 28px;
  height: 285px;
  min-width: 450px;
  cursor: pointer;
`;

export const CardHeader = styled.div`
  border-radius: 30px;
  background-color: ${props => props.theme.colors.lighter};
  padding: 20px;
  min-height: 115px;
  max-height: 115px;
  display: flex;
  flex-direction: row;
`;

export const CardIcon = styled.div`
  border-radius: 50px;
  height: 85px;
  min-width: 85px;
  background-color: ${props => props.theme.colors.black};
`;

export const ProjectInfo = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-white-space: nowrap;
  overflow: hidden;
`;

export const CardTitleCompany = styled.p`
  ${props => props.theme.typography.p1};
  color: ${props => props.theme.colors.secondary};
`;

export const CardTitle = styled.h2`
  ${props => props.theme.typography.h2};
  color: ${props => props.theme.colors.background};
`;

export const CardDescContainer = styled.div`
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.h1.fontWeight};
  line-height: 14px;
  margin: 20px 0px;
  color: ${props => props.theme.colors.secondary};
`;

export const CardDescTitle = styled.p`
  ${props => props.theme.typography.p1};
  color: ${props => props.theme.colors.secondary};
`;

export const Description = styled.p`
  ${props => props.theme.typography.p2};
  color: ${props => props.theme.colors.background};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
