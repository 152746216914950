import {
  AddCaptureModal,
  ChangeProponentModal,
  ChangeStatusModal,
  IndicateToModal,
  DeleteProjectModal,
  SendToMonitoreModal
} from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import ModalInformation from 'components/ModalInformation';
import React, { useMemo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { ProjectService } from 'services/projects';

import { Container, Text } from './styles';
import { useUser } from 'hooks/useUser';

const TabsOptions = ({ captureReport, data, onRefresh }) => {
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const { user } = useUser();

  const incentivCapturedValues = data.incentivCapturedValues;
  const updateCapturedValueProject = useCallback(async (payload) => {
    try {
      const response = await ProjectService.updateCapturedValueProject(data.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setShowModalInformation(false);
        setModalOpen('');
        toast.success('Captação atualizada com sucesso!');
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar a captação do projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const updateProponent = useCallback(async (payload) => {
    console.log('payload', payload)
    try {
      const response = await ProjectService.updateProjectProponent(data.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setModalInformationData({
          ...modalInformationData,
          title: 'Proponente atualizada com sucesso!',
          onClick: () => {
            setShowModalInformation(false);
            setModalOpen('');
            if (onRefresh) {
              onRefresh();
            }
          },
        });
        setShowModalInformation(true);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao alterar o proponente do projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const updateStateProject = useCallback(async (payload) => {
    try {
      const response = await ProjectService.updateStateProject(data.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setModalInformationData({
          ...modalInformationData,
          title: 'Status atualizado com sucesso!',
          onClick: () => {
            setShowModalInformation(false);
            setModalOpen('');
            if (onRefresh) {
              onRefresh();
            }
          },
        });
        setShowModalInformation(true);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar o status do projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const updateProject = useCallback(async (payload) => {
    try {
      const response = await ProjectService.updateProject(data.id, payload);
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setModalInformationData({
          ...modalInformationData,
          title: 'Dados cadastrados com sucesso!',
          onClick: () => {
            setShowModalInformation(false);
            setModalOpen('');
            if (onRefresh) {
              onRefresh();
            }
          },
        });
        setShowModalInformation(true);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const removeProject = useCallback(async () => {
    try {
      await ProjectService.deleteProject(data.id);
      setModalInformationData({
        ...modalInformationData,
        title: 'Projeto excluido com sucesso!',
        onClick: () => {
          setShowModalInformation(false);
          setModalOpen('');
          if (onRefresh) {
            onRefresh();
          }
        },
      });
      setShowModalInformation(true);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao remover projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const sendProjectToMonitore = useCallback(async () => {
    try {
      await ProjectService.releaseProject(data.id);
      setModalInformationData({
        ...modalInformationData,
        title: 'Projeto liberado com sucesso!',
        onClick: () => {
          setShowModalInformation(false);
          setModalOpen('');
          if (onRefresh) {
            onRefresh();
          }
        },
      });
      setShowModalInformation(true);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao liberar projeto');
      }
    }
  }, [data, modalInformationData, onRefresh]);

  const modalRender = useMemo(() => {
    const modal = {
      addCapture: (
        <AddCaptureModal
          open={modalOpen === 'addCapture'}
          handleClose={() => setModalOpen('')}
          onChange={(captured_value, sponsor_id, date) => {
            updateCapturedValueProject({ captured_value, sponsor_id, date })
          }}
        />
      ),
      changeProponent: (
        <ChangeProponentModal
          open={modalOpen === 'changeProponent'}
          handleClose={() => setModalOpen('')}
          onClose={() => setModalOpen('')}
          project_data={data}
          onChange={(proponent_id) => {
            updateProponent({ proponent_id })
          }}
        />
      ),
      changeStatus: (
        <ChangeStatusModal
          open={modalOpen === 'changeStatus'}
          handleClose={() => setModalOpen('')}
          onChange={(status) => {
            console.log(status)
            updateStateProject({ status })
          }}
        />
      ),
      indicateTo: (
        <IndicateToModal
          open={modalOpen === 'indicateTo'}
          handleClose={() => setModalOpen('')}
          onChange={encouragers => updateProject({ encouragers })}
        />
      ),
      deleteProject: (
        <DeleteProjectModal
          open={modalOpen === 'deleteProject'}
          handleClose={() => setModalOpen('')}
          onClick={() => removeProject()}
        />
      ),
      sendToMonitore: (
        <SendToMonitoreModal
          open={modalOpen === 'sendToMonitore'}
          handleClose={() => setModalOpen('')}
          onClick={() => sendProjectToMonitore()}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, updateProject, removeProject]);

  return (
    <Container>

      {/* {(['key_account2', 'cs_proponent2'].includes(user.adminRole) && incentivCapturedValues > 0) && <Text onClick={() => setModalOpen('sendToMonitore')}>Liberar na Monitore</Text>} */}
      <Text onClick={captureReport}>Gerenciar captações</Text>
      {/* {!['cs_proponent1', 'cs_proponent_relationship', 'monitore'].includes(user.adminRole) && <Text onClick={() => setModalOpen('changeProponent')}>Trocar proponente</Text>} */}
      {/* {!['monitore'].includes(user.adminRole) && <Text onClick={() => setModalOpen('addCapture')}>Adicionar captação</Text>} */}
      {/* {(['master', 'support', 'evaluator'].includes(user.adminRole) || (user.adminRole === 'cs_proponent2' && data.status === 'Em captação')) && (
        <Text onClick={() => setModalOpen('changeStatus')}>Alterar status</Text>
      )}
      {!['support'].includes(user.adminRole) && <Text onClick={() => setModalOpen('indicateTo')}>Indicar para</Text>}
      {!['cs_proponent1', 'support', 'cs_proponent_relationship', 'general', 'monitore'].includes(user.adminRole) && <Text onClick={() => setModalOpen('deleteProject')}>Remover projeto</Text>} */}
      {modalRender}

      <ModalInformation
        open={showModalInformation}
        handleClose={() => setShowModalInformation(false)}
        {...modalInformationData}
      />
    </Container>
  );
};

export default TabsOptions;
