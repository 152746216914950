import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, Backdrop } from '@material-ui/core';
import { ptBR } from 'date-fns/locale';

import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Label,
  Row,
  CancelButton,
  ConfirmButton,
  ButtonLabel,
  Container,
  DateRangePicker,
} from './styles';

import { toggleFilterModal, onActionFilterModal } from 'store/ducks/modals/actions';
import Select from '../Select';

import { states } from 'constants/states';

const FilterModal = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [selectedProjects, setSelectedProjects] = React.useState([]);
  const [selectedGoals, setSelectedGoals] = React.useState([]);
  const [selectedLaws, setSelectedLaws] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = React.useState([]);

  const openModal = useSelector(state => state.modals.statusFilter);

  const handleClose = () => {
    dispatch(toggleFilterModal());
  };

  const handleStartDateChange = date => setStartDate(date);
  const handleEndDateChange = date => setEndDate(date);

  const handleAction = () => {
    dispatch(onActionFilterModal({
      startDate,
      endDate,
      selectedProjects,
      selectedGoals,
      selectedLaws,
      selectedState,
      selectedCity,
      selectedCategory,
      selectedSubcategories
    }));
    handleClose();
  }

  return (
    <Container
      open={openModal}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          background: 'rgba(#050714, 0.6) !important',
        },
      }}
      disableAutoFocus
    >
      <Fade in={openModal}>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Todos os filtros</ModalTitle>
          </ModalHeader>
          <Row>
            <Box display="flex" flexDirection="column" margin="0 15px" flex="1">
              <Label>De</Label>
              <DateRangePicker
                adjustDateOnChange
                locale={ptBR}
                dateFormat="dd/MM/yyyy"
                startDate={startDate}
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </Box>
            <Box display="flex" flexDirection="column" margin="0 15px" flex="1">
              <Label>Até</Label>
              <DateRangePicker
                adjustDateOnChange
                locale={ptBR}
                dateFormat="dd/MM/yyyy"
                startDate={endDate}
                selected={endDate}
                onChange={handleEndDateChange}
              />
            </Box>
          </Row>
          <Select
            label="Projetos"
            onChange={setSelectedProjects}
            options={['Orquestra 2021', 'Orquestra 2020']}
            value={selectedProjects}
            multipleOptions
          />
          <Select
            label="Objetivos de Desenvolvimento Sustentável (ODS)"
            onChange={setSelectedGoals}
            options={['2 - Fome zero', '3 - Boa saúde e bem-estar']}
            value={selectedGoals}
            multipleOptions
          />
          <Select
            label="Lei de incentivo Fiscal"
            onChange={setSelectedLaws}
            options={['Lei Federal de incentivo á cultura']}
            value={selectedLaws}
          />
          <Row>
            <Box flex="1" margin="0px 10px">
              <Select
                label="Estado"
                onChange={setSelectedState}
                options={states}
                value={selectedState}
              />
            </Box>
            <Box flex="2" margin="0px 10px">
              <Select
                label="Cidade"
                onChange={setSelectedCity}
                options={['João Pessoa', 'Rio de Janeiro', 'São Paulo']}
                value={selectedCity}
              />
            </Box>
          </Row>
          <Select
            label="Categoria de impacto"
            onChange={setSelectedCategory}
            options={['Arte e cultura']}
            value={selectedCategory}
          />
          <Select
            label="Subcategoria de impacto"
            onChange={setSelectedSubcategories}
            options={['Ações culturais', 'cinema']}
            value={selectedSubcategories}
            multipleOptions
          />
          <Row style={{ margin: '20px 0px' }}>
            <CancelButton onClick={handleClose}>
              <ButtonLabel>Cancelar</ButtonLabel>
            </CancelButton>
            <ConfirmButton onClick={() => handleAction()}>
              <ButtonLabel>Aplicar Filtros</ButtonLabel>
            </ConfirmButton>
          </Row>
        </ModalContainer>
      </Fade>
    </Container>
  );
};

export default FilterModal;
