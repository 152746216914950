import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Content } from './styles';
import { ProponentService } from 'services/proponent';
import { ProjectService } from 'services/projects';
import { UsersService } from 'services/users';
import { NotificationService } from 'services/notifications';
import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import SelectAutocomplete from 'components/SelectAutocomplete';

const NotificationsFilterAdmin = ({
  setFilters,
  filters,
  isProponent = false,
  proponentId,
}) => {
  // const [date, setDate] = useState();
  const [projects, setProjects] = useState([]);
  const [proponents, setProponents] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProponents, setSelectedProponents] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const optionsStatus = useMemo(() => ['Lido', 'Não lido'], []);

  const loadInfo = useCallback(async () => {
    try {
      const [resProponents, resProjects, resUsers, resSubjects] = await Promise.all([
        ProponentService.listProponents(),
        ProjectService.listProjects(),
        UsersService.listUserNames(),
        NotificationService.listSubjects(),
      ]);

      if (Array.isArray(resProponents.data)) {
        setProponents(resProponents.data);
      } else {
        setProponents(resProponents.data.data);
      }
      setProjects(resProjects.data);
      setUsers(resUsers.data)
      setSubjects(resSubjects.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleAddFilter = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [setFilters, filters],
  );

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  useEffect(() => {
    handleAddFilter('users', selectedUsers)
  }, [selectedUsers]);

  useEffect(() => {
    handleAddFilter('proponents', selectedProponents)
  }, [selectedProponents]);

  useEffect(() => {
    handleAddFilter('projects', selectedProjects)
  }, [selectedProjects]);
  
  return (
    <Container className="inputsNotifications">
      <Content>
        {!isProponent && (
          <>
            <SelectAutocomplete
              label="Proponente"
              options={proponents.map(item => item.name)}
              onChange={(event, value) => {
                setSelectedProponents(value);
              }}
              className="select-multiple"
            />

            <SelectAutocomplete
              label="Usuário"
              options={users.map(item => item.name)}
              onChange={(event, value) => {
                setSelectedUsers(value);
              }}
            />
          </>
        )}
        <Select
          label="Assunto"
          options={subjects.map(subject => subject.name)}
          onChange={e =>
            handleAddFilter('subjectId', subjects[e.target.value].id)
          }
          role="proponente"
        />
        <SelectAutocomplete
          label="Projeto"
          options={projects.map(project => project.name)}
          onChange={(event, value) => {
            setSelectedProjects(value);
          }}
        />
        <Select
          label="Status"
          options={optionsStatus}
          onChange={e =>
            handleAddFilter('status', optionsStatus[e.target.value])
          }
          role="admin"
        />
        <DatePicker
          label="Data"
          height={55}
          onChange={value => {
            const dateValue = value.toISOString().substring(0, 10);
            handleAddFilter('date', dateValue);
          }}
        />
      </Content>
    </Container>
  );
};

export default NotificationsFilterAdmin;
