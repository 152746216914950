import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { pixelsToRem } from 'utils/styles';

export const Container = styled(Button)`
  border-radius: ${pixelsToRem(8)} !important;
  line-height: ${pixelsToRem(24)} !important;
  padding: ${pixelsToRem(4)} 0 !important;
  font-size: 12px !important;
  border-color: ${props => props.color} !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 2px !important;
  width: 316px;
`;
