export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cepMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const onlyNumber = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/[^0-9]/g, '');
}

export const phoneMask = (value = '') => {
  if (!value) {
    return '';
  }

  if (value.split('')[value.length - 1] === '-') {
    return value.replace('-', '');
  }

  let r = value.replace(/\D/g, '');
  r = r.replace(/^0/, '');
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    r = r.replace(/^(\d*)/, '($1');
  }
  return r;
};

export const formatNumber = (n) => {
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const removeMask = (value = "") => {
  return value.replace(/[^0-9]+/g,'');
}

export const percentageMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(.\d{2})\d+?$/, '$1');
};

export const agency_bank = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{4})(\d{1})+?$/, "$1-$2");
}

export const account_bank = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{8})(\d{1})+?$/, "$1-$2");
}