import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 1100px;

  h6 + h6 {
    margin-top: 1rem;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  ${({ colSize }) => css`
    grid-template-columns: repeat(${colSize}, 1fr);
  `}
`;
export const DocumentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    margin-top: 0.5rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;
