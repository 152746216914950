import styled from 'styled-components';

export const Container = styled.div``;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  background-color: ${({ theme }) => theme.colors.white};

  margin-top: 24px;
  padding: 24px;

  border-radius: 15px;
`;
