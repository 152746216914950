import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const SendNewsletter = ({ open, handleClose, onClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
      Obrigado por se cadastrar!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        A partir de agora, você receberá o boletim do universo das leis de incentivo fiscal na caixa de entrada do seu e-mail.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default SendNewsletter;