import api from './api';

const PATH = '/proponents';

const create = data => api.post(PATH, data);
const update = (id, data) => api.post(`${PATH}/update/${id}`, data);
const listProponents = () => api.get(PATH);
const updateCnds = id => api.get(`${PATH}-cnd/${id}`);
const getCnds = id => api.get(`${PATH}-get-cnd/${id}`);
const updateOneCnd = (id, data) => api.put(`cnd-upload/${id}`, data);
const getListProponents = () => api.get(`${PATH}-all`);
const listProponentsPagination = (params = {}, page) => api.get(`${PATH}-pagination`, { params: { ...params, page } });
const getOneProponent = id => api.get(`${PATH}/${id}`);
const getFormInfo = () => api.get(`${PATH}/get-form-info`);
const removeImage = imageId => api.delete(`${PATH}/imagens/${imageId}`);
const searchProponents = (params = {}, page) => api.get(`${PATH}-search`, { params: { ...params, page } });
const updateProponentEmail = (id, data) => api.post(`${PATH}/update-email/${id}`, data);
const updateProponentTaxRegime = (id, data) => api.post(`${PATH}/update-tax-regime/${id}`, data);
const updateProponentPassword = (id, data) => api.post(`${PATH}/update-password/${id}`, data);
const deleteProponent = id => api.delete(`${PATH}/delete/${id}`);

export const ProponentService = {
  create,
  update,
  listProponents,
  updateCnds,
  updateOneCnd,
  getCnds,
  getListProponents,
  getFormInfo,
  getOneProponent,
  removeImage,
  listProponentsPagination,
  searchProponents,
  updateProponentEmail,
  updateProponentTaxRegime,
  updateProponentPassword,
  deleteProponent,
} 