export default function formatCurrency(number, prefix = true, reset = false, digits = 0) {
  if(reset && number < 0) number = 0;

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: digits,
  })
    .format(number)
    .replace('R$', prefix ? 'R$' : '');
}

export const currencyMask = value => {
  let v = value.replace(/\D/g, '');
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return v;
};

export const currencyMaskStore = value => {
  let v = value.replace(/\D/g, '');
  v = `${(+v / 100).toFixed(2)}`;
  v = v.replace(',', '.');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2.');
  return v;
};
