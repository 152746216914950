const INITIAL_STATE = {
  data: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  const updatedWordState = state;

  switch (action.type) {
    case '@word/UPDATE_WORD':
      updatedWordState.data = action.payload;

      return { ...state, ...updatedWordState };

    case '@word/REMOVE_WORD':
      INITIAL_STATE.data = '';

      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
