import React, { useCallback, useState } from 'react';
import Input from 'components/Input';
import Typography from 'components/Typography';
import {
  Container,
  ContentInputs,
  TextBody,
  Title,
  ButtonAddCounterPart,
  ButtonAddText,
  Footer,
  SizeInputs,
  InputCounts,
  Counts,
  ButtonDelete,
} from './styles';
import { IconAdd, IconDelete, IconExclude } from 'assets/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Controller, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import './style.css';
import { FormHelperText } from '@material-ui/core';
import { useEffect } from 'react';


const Step2 = props => {
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    getValues
  } = useFormContext();

  const [showInformations, setShowInformations] = useState(getValues('other_informations'));

  const addSpecificGoals = () => {
    const length = Object.keys(props.inputs.specificGoals).length;
    props.setInputs({
      ...props.inputs,
      specificGoals: { ...props.inputs.specificGoals, [length]: '' },
    });
  };
  const removeSpecificGoal = (index) => {
    let specificGoals = props.inputs.specificGoals;
    //  remove elemento do objeto
    delete(specificGoals[index]);

    //  processo de reordenar índices do objeto
    let tempData = {};
    let newIndex = 0;
    Object.entries(specificGoals).forEach(([key, value]) => {
      tempData[newIndex] = value;
      newIndex++
    });

    props.setInputs({
      specificGoals: { ...tempData },
    });
  }

  const validateSchemaItem = useCallback(
    (data, item) => {
      const { condition, field, errorMessage } = item;
      if (
        data[item.field] === undefined ||
        data[item.field] === '' ||
        condition(data)
      ) {
        setError(field, { message: errorMessage });
      } else {
        clearErrors(field);
      }
    },
    [setError, clearErrors],
  );
  
  const status = getValues('status');
  const theme = useTheme();
  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);

  useEffect(() => {
    Object.values(props.inputs.specificGoals).map((value, idx) => setValue(`specific_goal_${idx}`, value));
  }, []);

  return (
    <Container>
      <div>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          Resumo e objetivos
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Utilize uma narrativa lógica e criativa para encantar possíveis
          incentivadores!
        </Typography>
      </div>
      <Title>
        Qual é a síntese do seu projeto? Qual o principal produto/serviço e como
        isto será realizado?
      </Title>
      <div style={{ marginTop: 24 }}>
        <Controller
          name="summary"
          control={control}
          render={({ field }) => (
            <Input
              width="98%"
              label="Resumo"
              height="65px"
              required={true}
              margin={90}
              error={errors?.summary?.message}
              multiline
              rows={5}
              inputProps={{ maxLength: 700 }}
              {...field}
              disabled={isDisabledByStatus}
              data-tip="De forma sucinta e precisa, descreva o principal <br/>
              produto/serviço e como isto será realizado, com a <br/>
              finalidade de transmitir uma ideia geral sobre seu projeto." 
              data-html={true}
              data-for="tooltip1"
              data-event='click focus'
            />
          )}
        />
        <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
      </div>
      <TextBody>
        O objetivo geral deve destacar qual é o produto principal, o que o projeto
        propõe e qual impacto pretende gerar.
      </TextBody>

      <ContentInputs>
        <InputCounts>
          {/* *Obrigatório */}
          <Controller
            name="goal"
            control={control}
            render={({ field }) => (
              <Input
                width="98%"
                label="Objetivo Geral"
                height="65px"
                multiline
                rows={5}
                error={errors?.goal?.message}
                inputProps={{ maxLength: 300 }}
                {...field}
                required={true}
                margin={90}
                disabled={isDisabledByStatus}
                data-tip="Descreva a ideia central do seu projeto. O objetivo
                geral responde à pergunta: “O que é?” Então ele deve expressar
                de forma clara qual é a intenção do projeto" 
                data-html={true}
                data-for="tooltip1"
                data-event='click focus'
              />
            )}
          />
          <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
          <Counts>
            <SizeInputs>
              {watch('goal') ? watch('goal').length : 0}/300
            </SizeInputs>
          </Counts>
        </InputCounts>
        <TextBody>
        Os objetivos específicos correspondem aos resultados que o projeto pretende alcançar e que contribuirão para alcançar o objetivo geral. É obrigatório indicar ao menos 1 objetivo especifico.
        <InfoIcon data-for="tooltip2" data-tip="Os objetivos específicos respondem <br/> “o que farei para alcançar o objetivo <br/>geral do projeto?”" multiline={true} data-html={true} style={{color: theme.colors.primary, width: '1.35rem', position: 'absolute'}} />

        {errors["specific_goal"] && (Object.entries(props.inputs.specificGoals).length <= 0) && <FormHelperText error>{errors["specific_goal"]?.message}</FormHelperText>}

        </TextBody>
        <ReactTooltip id="tooltip2" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>
        <InputCounts>
          {Object.entries(props.inputs.specificGoals).map((item, index) => (
            <>
              <div style={{ marginTop: 24 }} key={item[0]}>
                <Controller
                  name={`specific_goal_${index}`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      width="98%"
                      label={`Objetivo específico 0${Number(item[0]) +1}`}
                      // placeholder={`Objetivo específico 0${Number(item[0]) +1}`}
                      height="65px"
                      required={ (Number(item[0])+1) == 1 ? true : false}
                      margin={90}
                      multiline
                      rows={5}
                      {...field}
                      onChange={e => {
                        props.setInputs({
                          ...props.inputs,
                          specificGoals: {
                            ...props.inputs.specificGoals,
                            [item[0]]: e.target.value,
                          },
                        });

                        if(index === 0)
                          setValue(`specific_goal`, e.target.value);
                        else
                          setValue(`specific_goal_${index-1}`, e.target.value);

                        if(props.inputs.specificGoals[0].length > 0)
                          clearErrors("specific_goal");
                      }}
                      value={item[1]}
                      disabled={isDisabledByStatus}
                      error={(index === 0) && errors["specific_goal"]?.message}
                    />
                  )}
                />
              </div>
              <ButtonDelete onClick={() => removeSpecificGoal(item[0])} disabled={isDisabledByStatus}>
                <IconDelete />
              </ButtonDelete>
            </>
          ))}
          <Counts>
            <SizeInputs>
              {/* {watch('specific_goal') ? watch('specific_goal').length : 0}/150 */}
            </SizeInputs>
          </Counts>
        </InputCounts>
      </ContentInputs>
      <Footer>
        <ButtonAddCounterPart type="button" onClick={() => setShowInformations(!showInformations)}>
          {showInformations ? (<IconExclude width={16.67} height={16.67} />) : (<IconAdd width={20} height={20} />)}
          <ButtonAddText style={{  }}>
            OUTRAS INFORMAÇÕES
          </ButtonAddText>
        </ButtonAddCounterPart>

        <ButtonAddCounterPart type="button" onClick={addSpecificGoals}>
          <IconAdd width={20} height={20} />
          <ButtonAddText style={{  }}>
            ADICIONAR OBJETIVO
          </ButtonAddText>
        </ButtonAddCounterPart>
      </Footer>


      {showInformations && (
        <>
          <Title>
          Como serão realizadas as ações e programação do projeto? Descreva essas e outras informações importantes.
          </Title>
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Controller
              name="other_informations"
              control={control}
              render={({ field }) => (
                <Input
                  width="98%"
                  label="Outras informações"
                  height="65px"
                  error={errors?.other_informations?.message}
                  multiline
                  rows={10}
                  inputProps={{ maxLength: 6000 }}
                  {...field}
                  disabled={isDisabledByStatus}
                />
              )}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default Step2;
