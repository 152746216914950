import styled from 'styled-components';
import {
    Modal as MuiModal,
    Typography
} from '@material-ui/core';

export const Modal = styled(MuiModal).attrs({
    BackdropProps: {
        timeout: 500,
        style: {
            backgroundColor: `rgba(#050714, 0.6) !important`,
        }
    }
})`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    
    > div[tabindex="-1"] {
        width: 600px;
    }
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 48px 40px;
`;

export const Title = styled(Typography).attrs({
    variant: "h4"
})`
    margin-bottom: 30px !important;
`;

export const Subtitle = styled.p`
    margin-bottom: 20px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 20px;
    gap: 10px;
`;

export const BiddersList = styled.ul`
    background: red;
`;