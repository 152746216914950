import React, { useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import { Container, Content } from './styles';
import { v4 } from 'uuid';
import IndicateToItem from './IndicateToItem';

const IndicateTo = ({ open, handleClose, onChange }) => {
  const [indicateList, setIndicateList] = useState([]);
  const [text, setText] = useState('');

  const addSponsor = useCallback(() => {
    const data = {
      id: v4(),
      name: text,
    };
    setIndicateList(oldValue => [...oldValue, data]);
    setText('');
  }, [text]);

  const deleteUser = useCallback(
    id => {
      const newList = indicateList.filter(item => item.id !== id);
      setIndicateList(newList);
    },
    [indicateList],
  );

  const handleIndicate = useCallback(() => {
    onChange(indicateList.map(indicate => indicate.name).join(', '));
  }, [onChange, indicateList]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Container>
          <Input
            label="Indicar para os patrocinadores"
            onChange={e => setText(e.target.value)}
            value={text}
            onKeyPress={e => {
              if (e.code === 'Enter') {
                addSponsor();
              }
            }}
          />
          <Content>
            {indicateList.map(item => (
              <IndicateToItem
                key={item.id}
                name={item.name}
                onClick={() => deleteUser(item.id)}
              />
            ))}
          </Content>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleIndicate()}
          disabled={indicateList.length === 0}
          color="primary"
        >
          INDICAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IndicateTo;
