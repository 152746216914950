import React, { useState, useCallback } from 'react';
import { Container, Form, Submit, Back } from './styles';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import ProponentCreatedModal from 'components/ProponentCreatedModal';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useTheme } from 'styled-components';
import { ProponentService } from 'services/proponent';
import { UsersService } from 'services/users';
import { toast } from 'react-toastify';
import { createProponentValidationResolver } from 'validations/proponent';
import { removeMask } from 'utils/maskUtils';
import { getColors } from './styles';
import api from 'services/api';
import { validateCnpj, validateCpf } from 'utils/validation';

const RegisterForm = () => {
  const [showNextStep, setShowNextStep] = useState('default');
  const [showStep, setShowStep] = useState(false);
  const form = useForm({
    resolver: createProponentValidationResolver,
  });
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  const onSubmit = useCallback(
    async values => {
      try {
        const cnpj =
          values.cnpj === undefined
            ? null
            : removeMask(values.cnpj);
        const cpf =
          values.cpf === undefined ? null : removeMask(values.cpf);
        const phone = removeMask(values.phone);
        if(cpf){
          const validatedCPF = validateCpf(cpf);
          if(validatedCPF === false){
            toast.error('CPF é inválido');
            return ;
          }
        }

        if(cnpj){
          const validatedCNPJ = validateCnpj(cnpj);
          if(validatedCNPJ === false){
            toast.error('CNPJ é inválido')
            return ;
          }
        }

        const data = {
          ...values,
          cpf,
          cnpj,
          phone,
          cpf_responsible_legal: cpf,
          phone_responsible_legal: phone,
          email_responsible_legal: values.email,
          witness_email: values.email,
          platform: 'web',
        };

        if (showNextStep === 'proponente') {
          const response = await ProponentService.create(data);
          if (response.status === 203) {
            Object.keys(response.data).map(item =>
              toast.error(response.data[item][0]),
            );
          } else {
            setShowModal(true);
          }
        } else {
          const response = await api.post('/sponsors', data);
          if (response.status === 203) {
            Object.keys(response.data).map(item =>
              toast.error(response.data[item][0]),
            );
          } else {
            setShowModal(true);
          }
        }
      } catch (err) {
        alert(err);
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error('Falha ao cadastrar proponente');
        }
      }
    },
    [showNextStep],
  );

  const handleGoBack = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Container>
      <Back onClick={handleGoBack}>
        <p> {'<'} Login </p>
      </Back>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Step1
            setShowNextStep={setShowNextStep}
            showNextStep={showNextStep}
          />
          {showNextStep !== 'default' && (
            <Step2 personTypeChange={() => setShowStep(true)} />
          )}
          {showNextStep !== 'default' && showStep && (
            <>
              <Step3 />
              <Submit>
                <Button
                  variant="secondary"
                  color={getColors(showNextStep).primary}
                  type="button"
                  onClick={handleGoBack}
                >
                  CANCELAR
                </Button>
                <Button color={getColors(showNextStep).primary} type="submit">
                  CADASTRAR
                </Button>
              </Submit>
            </>
          )}
        </Form>
      </FormProvider>
      <ProponentCreatedModal
        open={showModal}
        handleClose={() => {
          setShowModal(false);
          handleGoBack('/');
        }}
      />
    </Container>
  );
};

export default RegisterForm;
