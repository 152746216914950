import React, { useCallback, useRef, useState, useEffect } from 'react';
import { CloudUpload } from '@material-ui/icons';
import { Container, ActionButton, Controller } from './styles';
import Typography from 'components/Typography';
import { FormHelperText, InputLabel } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const FileInput = ({
  onChange,
  buttonText = 'UPLOAD',
  width,
  multiple = false,
  empty = false,
  disabled = false,
  required=false,
  margin=0,
  files = [],
  error,
  deleteName = false,
  isUploading = false,
}) => {
  const [fileSelected, setFileSelected] = useState(
    files.length > 0 ? files.join(', ') : '',
  );
  const [fileSelectedObject, setFileSelectedObject] = useState();
  const inputRef = useRef(null);

  const handleFile = useCallback(() => {
    if (inputRef.current && !disabled) {
      inputRef.current.click();
    }
  }, [disabled]);

  const formatNameFile = useCallback(() => {
    if (fileSelected.includes('https://')) {
      const splitString = fileSelected.split('/');
      return splitString[splitString.length - 1];
    }
    return fileSelected;
  }, [fileSelected]);

  const handleOpenFile = useCallback(() => {
    if (fileSelected.includes('https://')) {
      const links = fileSelected.split(', ');
      links.map(link => {
        return window.open(link);
      });
    } else {
      const files = Array.from(fileSelectedObject);
      files.map(file => {
        const fileUrl = URL.createObjectURL(file);
        return window.open(fileUrl);
      });
    }
  }, [fileSelected, fileSelectedObject]);

  const onChangeFile = useCallback(
    files => {
      if (files.length > 0) {
        const fileList = [...files];
        setFileSelectedObject(fileList);
        setFileSelected(fileList.map(file => file.name).join(', '));
        if (onChange) {
          onChange(files);
        }
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (empty) {
      setFileSelected('');
    }
  }, [empty]);

  return (
    <Controller width={width}>

      {!!(required && (files?.length <= 0 || files == 0)) &&
        <InputLabel 
          style={{color: 'red', 
                  marginLeft: margin+'%', 
                  backgroundColor: '#fff',
                  position: 'absolute',
                  marginTop: '-6px',
                  zIndex: 9,
                  fontSize: '12px',
                }} 
          id="select-label">Obrigatório</InputLabel>
      }

      <Container width={width} error={!!error}>
        {(fileSelected !== '' && !deleteName) ? (
          <Typography
            variant="body1"
            color="blue"
            onClick={handleOpenFile}
            className="open-file"
            style={{ fontSize: '12px' }}
          >
            Arquivo selecionado
          </Typography>
        ) : (
          <Typography variant="body1" style={{ fontSize: '12px' }} color="blueGrayish56">Nenhum arquivo selecionado</Typography>
        )}
        <ActionButton
          type="button"
          onClick={() => handleFile()}
          disabled={disabled}
        >
          {buttonText}
          <CloudUpload />
        </ActionButton>
        <input
          type="file"
          multiple={multiple}
          ref={inputRef}
          onChange={e => onChangeFile(e.target?.files)}
        />
      </Container>
      {isUploading == true && <LinearProgress />}
      {!!error && (
        <FormHelperText style={{ paddingLeft: 16 }} error>
          {error}
        </FormHelperText>
      )}
    </Controller>
  );
};

export default FileInput;
