import ReactSelect from "react-select";

const ReactSelectMain = ({ placeholder, value, options, onChange, style, isDisabled, ...rest }) => (
    <ReactSelect
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        isClearable 
        styles={{
            option: (styles, state) => ({
                ...styles,
                fontFamily: 'Cabin',
                backgroundColor:
                    state.isSelected ? "#C15028" :
                    state.isFocused ? "#EBEBEB" :
                    "#FFFFFF",
                ":hover": {
                    ...styles[":active"],
                    background:
                    state.isSelected ? "#C15028" : "#F5F5F5"
                }
            }),
            control: (styles, state) => ({
                ...styles,
                fontFamily: 'Cabin',
                paddingTop: 4,
                paddingBottom: 4,
                fontSize: '18px',
                height: '55px',
                borderRadius: '30px',
                borderColor: state.isFocused ? "#C15028" : "rgba(0, 0, 0, 0.23)",
                boxShadow: state.isFocused ? "0 0 0 1px #C15028" : "unset",
                ":hover": state.isFocused ? {
                    borderColor: "#C15028",
                    boxShadow: "0 0 0 1px #C15028"
                } : {
                    borderColor: "black"
                }
            }),
            menu: provided => ({ ...provided, zIndex: 9999 }),
            container: (styles) => ({
                ...styles,
                ...style
            }),
            indicatorSeparator: (styles) => ({
                ...styles,
                backgroundColor: 'transparent'
            })
         }}
        {...rest} />
);

export default ReactSelectMain