import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import Button from 'components/Button';
import { Container } from './styles';
import Input from 'components/Input';

const UpdateEmail = ({ open, handleClose, onChange }) => {
  const [email, setEmail] = useState(null);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Container>
          <Input 
            name='email'
            label='Email'
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onChange(email)}>
          ALTERAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateEmail;
