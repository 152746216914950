import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 560px;
  height: 100%;
  width: 100%;

  h4 {
    margin: 0 0 1rem;
  }

  button {
    width: 100%;
  }
`;
