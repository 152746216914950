import styled from 'styled-components';
import { pixelsToRem } from 'utils/styles';
import { rgba } from 'polished';

export const Container = styled.div`
  border-radius: ${pixelsToRem(30)};
  padding: 1.5rem;
  background: ${({ theme }) => rgba(theme.colors.grayLight3, 0.1)};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;

  svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;
