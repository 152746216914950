import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const schema = Yup.object({
  password: Yup.string()
    .matches(/^(?=.*[!$*&@#])/, "Deve conter um caractere especial")
    .matches(/^(?=.*[a-z])/, "Deve conter um caractere minúsculo")
    .matches(/^(?=.*[A-Z])/, "Deve conter um caractere maiúsculo")
    .matches(/^(?=.*[0-9])/, "Deve conter um número")
    .min(6, 'Mínimo de 6 caracteres')
    .max(30, 'Máximo de 30 caracteres')
    .required('Senha é obrigatório'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Confirme a senha'),
})

export const confirmPasswordValidationResolver = yupResolver(schema);