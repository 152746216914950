import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { Link } from './styles';

const ProjectCreatedIncompletedModal = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Dados cadastrais incompletos!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você precisa finalizar seu cadastro de proponente antes de enviar seu projeto para análise. <Link href={'/meus-dados'}>Clique aqui para prosseguir</Link>.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectCreatedIncompletedModal;
