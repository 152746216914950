import React, { useCallback } from 'react';
import { Container } from './styles';

function ButtonIcon({ children, variant = 'primary', ...rest }) {
  const renderButtonType = useCallback(() => {
    switch (variant) {
      case 'secondary':
        return 'outlined';
      case 'tertiary':
        return 'text';
      case 'primary':
      default:
        return 'contained';
    }
  }, [variant]);

  return (
    <Container variant={renderButtonType()} color="primary" {...rest}>
      {children}
    </Container>
  );
}

export default ButtonIcon;
