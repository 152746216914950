import ProponentSidebar from 'components/ProponentProfileForm/ProponentSidebar';
import { useUser } from 'hooks/useUser';
import React, { useState, useCallback } from 'react';
import ProponentFormAdmin from './ProponentFormAdmin';

import { Container } from './styles';

const AdminProponentRegister = ({ proponent, loading, handleCancel, cnds, setCnds, uploadCnd }) => {
  const { user } = useUser();
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const handlePrevious = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <Container>
      <ProponentSidebar
        activeStep={activeStep}
        changeStep={step => setActiveStep(step)}
        isAdmin={user.isAdmin}
      />
      <ProponentFormAdmin
        currentStep={activeStep}
        handleNext={() => handleNext()}
        handlePrevious={() => handlePrevious()}
        loading={loading}
        proponent={proponent}
        handleCancel={handleCancel}
        cnds={cnds}
        setCnds={setCnds}
        uploadCnd={uploadCnd}
      />
    </Container>
  );
};

export default AdminProponentRegister;