import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;

  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;


  .input-email-login {
    margin-bottom: 33px;

    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.blueAqua} !important;
    }

    label.Mui-focused {
      color: ${({ theme }) => theme.colors.blueAqua} !important;
    }

    @media (max-width: 450px){
      margin-bottom: 20px;
    }
  }

  .input-password-login {
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.blueAqua} !important;
    }

    label.Mui-focused {
      color: ${({ theme }) => theme.colors.blueAqua} !important;
    }
  }
`;

export const Enter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2.5vh;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 316px;
    height: 32px;

    background: ${({ theme }) => theme.colors.blueAqua};

    &:hover {
      background: ${({ theme }) => theme.colors.blueAqua};
    }

    border: none;
    border-radius: 30px;

    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;

      color: ${({ theme }) => theme.colors.white};

      text-transform: uppercase;
    }
  }

  @media (max-width: 450px){
    width: 80%;
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 450px){
    text-align: center;
    display: grid;
    justify-content: center;
  }
`;
