import styled from 'styled-components';

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
`;


export const ShowFiles = styled.div`
  .multiple-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: flex-start;
  }

  margin-top: 5px;
`;

export const ImageAndVideos = styled.div`
  display: grid;
  width: 100%;
  margin-top: 22px;
  gap: 22px;
`;

export const ImageAndVideosBox = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 1200px){
    display: grid;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;