import { CircularProgress } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProponentService } from 'services/proponent';
import AdminTabs from 'components/AdminTabs';

import { Container, LoaderContainer, Content, Body, PageHeader } from './styles';
import ProponentProfile from 'components/ProponentProfile';
import AdminProponenteProjects from 'components/AdminProponenteProjects';
import AdminProponentRegister from 'components/AdminProponentRegister';
import AdminProponenteNotifications from 'components/AdminProponenteNotifications';
import NotificationFormAdmin from 'components/NotificationFormAdmin';
import Button from 'components/Button';
import { AddCircle } from '@material-ui/icons';
import { toast } from 'react-toastify';
import uploadHandler from 'services/uploadCdn';

const AdmProponentDetail = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [proponent, setProponent] = useState();
  const [cnds, setCnds] = useState([]);
  const { id } = useParams();
  const tabs = useMemo(() => {
    return ['PERFIL', 'PROJETOS', 'CENTRAL DE NOTIFICAÇÃO', 'CADASTRO'];
  }, []);
  const [openForm, setOpenForm] = useState(false);

  const loadProponent = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ProponentService.getOneProponent(id);
      if (Array.isArray(response.data)) {
        let proponentLaws = response?.data[0]?.incentiveLaws;
        let proponentData = response?.data[0];
        if(proponentLaws){
          if(proponentLaws.length >0){
            let lawArray = proponentLaws.split(",")
            if(lawArray){
              proponentData.incentiveLaws = lawArray
            }
          }
        }else{
          proponentLaws = []
          proponentData.incentiveLaws = proponentLaws
        }
        setProponent(proponentData);
      }
      setProponent(response.data[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const getAllCnds = useCallback(async () => {
      try {
        const { data } = await ProponentService.getCnds(id);
        setCnds(data);
      } catch (err) {
        toast.error('Ocorreu um erro durante a busca das CNDs');
        console.log(err.response);
      }
  }, [id]);

  async function uploadCnd(file, cnd){
    const proponentId = id;
    try{
        // let teste = {
        //     url: "https://incentiv-conecta-sandbox.s3.amazonaws.com/public/conectaV1/cnd/58863e24-a9bb-42fd-9f3a-1fda70734153-wpdf-teste.pdf"
        // };
        let sendS3 = await uploadHandler(file[0]);

        var data = {
            type: cnd.type, 
            url: sendS3.url,
            isPj: cnd.isPj
        }

        let response = await ProponentService.updateOneCnd(proponentId, data);
        console.log("response", response);
        toast.success('Envio da CDN finalizada');
        if(response.data.status == 200){
          setCnds(response.data.data);
        }else{
          toast.error('Erro durante o upload da CND');
        }
    }catch(error){
        toast.error('Erro durante o upload da CND');
    }
};

  const renderComponent = useCallback(() => {
    const variant = {
      0: <ProponentProfile loading={false} proponent={proponent} />,
      1: <AdminProponenteProjects id={id} />,
      2: <AdminProponenteNotifications id={id} />,
      3: <AdminProponentRegister cnds={cnds} setCnds={(e)=>setCnds(e)} uploadCnd={(file, cnd)=>uploadCnd(file, cnd)} proponent={proponent} handleCancel={() => setActiveTab(0)} />
    };
    return variant[activeTab];
  }, [activeTab, proponent, id, cnds]);

  useEffect(() => {
    if (id) {
      loadProponent();
      getAllCnds();
    }
  }, [id, loadProponent, getAllCnds]);

  return (
    <Container>
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <>
          <PageHeader>
            <PageTitle
              title={proponent?.legalPerson === 'Pessoa física' ? proponent?.fullName : (proponent?.name || proponent?.corporateName)}
              subtitle="Confira as informações cadastradas no perfil do proponente."
            />
            {/* {
              activeTab === 2 && (
                <Button
                  variant="primary"
                  onClick={() => setOpenForm(true)}
                  className="squared"
                >
                  <AddCircle />
                  Enviar mensagem
                </Button>
              )
            } */}
          </PageHeader>
          <Content>
            <AdminTabs
              items={tabs}
              activeTab={activeTab}
              onChange={tab => setActiveTab(tab)}
            />
            <Body>{renderComponent()}</Body>
          </Content>
          <NotificationFormAdmin
            open={openForm}
            onClose={() => setOpenForm(false)}
          />
        </>
      )}
    </Container>
  );
};

export default AdmProponentDetail;