import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 540px;
  height: 100%;
  gap: 0.5rem;

  img {
    width: 220px;
  }
`;
