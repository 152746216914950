import React, { useState, useCallback } from 'react';
import Input from 'components/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from 'components/Typography';
import { Container, GridInputs, CheckNews, CheckTerms, ErrorBox, Link, StepDados } from './styles';
import { IconVisibilityOff, IconVisibilityOn } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { register } from 'store/ducks/register/action';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const Step3 = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [checkboxState, setCheckboxState] = useState(false);
  const [checkboxTermsState, setCheckboxTermsState] = useState(false);

  const registerValue = useSelector(state => state.register.profile);
  const dispatch = useDispatch();

  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [visibilityConfirmPassword, setVisibilityConfirmPassword] = useState(
    false,
  );

  const changeVisibilityPassword = useCallback(() => {
    setVisibilityPassword(prevState => !prevState);
  }, []);

  const changeVisibilityConfirmPassword = useCallback(() => {
    setVisibilityConfirmPassword(prevState => !prevState);
  }, []);

  const handleCheck = useCallback((type) => {
    if(type === 'news'){
      setCheckboxState(prevState => !prevState);

      dispatch(
        register({
          profile: {
            ...registerValue.profile,
            receive_news: !checkboxState,
          },
        }),
      );
    } else if(type === 'terms') {
      setCheckboxTermsState(prevState => !prevState);
  
      dispatch(
        register({
          profile: {
            ...registerValue.profile,
            is_terms_accepted: !checkboxTermsState,
          },
        }),
      );
    }

  }, [registerValue.profile, checkboxState, checkboxTermsState, dispatch]);

  return (
    <Container>
      <Typography variant="h6" color="grayDark2" style={{ marginTop: 10 }}>
        Dados de acesso
      </Typography>
      <div style={{ margin: '15px 0 16px' }}>
        <Typography variant="subtitle2" color="grayLight1">
          Defina seu login e senha de acesso à nossa plataforma.
        </Typography>
      </div>
      <GridInputs>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input 
            required={true}
            margin={90}
            label="Email" error={errors?.email?.message} {...field} />
          )}
        />
      </GridInputs>
      <StepDados>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              placeholder="*****"
              label="Senha"
              type={visibilityPassword ? 'text' : 'password'}
              changeIcon={changeVisibilityPassword}
              required={true}
              margin={80}
              icon={visibilityPassword ? IconVisibilityOn : IconVisibilityOff}
              error={errors?.password?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Input
              placeholder="*****"
              label="Confirmar senha"
              error={errors?.confirmPassword?.message}
              type={visibilityConfirmPassword ? 'text' : 'password'}
              changeIcon={changeVisibilityConfirmPassword}
              required={true}
              margin={80}
              icon={
                visibilityConfirmPassword ? IconVisibilityOn : IconVisibilityOff
              }
              {...field}
            />
          )}
        />
      </StepDados>

      <Controller
          name="is_terms_accepted"
          control={control}
          render={({ field }) => (
            <CheckTerms>
              <CheckNews>
                <Checkbox
                  onClick={() => handleCheck('terms')}
                  checked={checkboxTermsState}
                  error={errors?.is_terms_accepted?.message}
                  {...field}
                  // style={{ color: getColors(registerValue.profile.type).primary }}
                />
                <Typography
                  variant="body1"
                  color="black"
                  style={{ marginLeft: 10 }}
                  className="text"
                >
                  Li e estou de acordo com os <Link href={'termos-e-condicoes-de-uso'} rel="noreferrer" target="_blank">termos de uso</Link> e <Link href={'politica-de-privacidade'} rel="noreferrer" target="_blank">privacidade da plataforma</Link>
                </Typography>
              </CheckNews>
              <ErrorBox><ErrorMessage errors={errors} name="is_terms_accepted" /></ErrorBox>
            </CheckTerms>
          )}
        />
        <Controller
          name="receive_news"
          control={control}
          render={({ field }) => (
            <CheckNews>
              <Checkbox
                onClick={() => handleCheck('news')}
                checked={checkboxState}
                {...field}
                // style={{ color: getColors(registerValue.profile.type).primary }}
              />
              <Typography
                variant="body1"
                color="black"
                style={{ marginLeft: 10 }}
                className="text"
              >
                Quero receber novidades da Incentiv.me no meu e-mail
              </Typography>
            </CheckNews>
          )}
        />
    </Container>
  );
};

export default Step3;
