import React from 'react';
import Button from 'components/Button';
import Typography from 'components/Typography';
import noProjectsImage from 'assets/images/pc.svg';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const NoProjects = () => {
  const { push } = useHistory();

  return (
    <Container style={{display: 'block', position: 'relative', margin: '0 auto', textAlign: 'center'}}>
      <img src={noProjectsImage} alt="No Projects" />
      <Typography color="black" variant="h4">
        Cadastre um projeto na sua vitrine
      </Typography>
      <Button onClick={() => push('/projetos-registro')}>
        CADASTRAR PROJETO
      </Button>
    </Container>
  );
};

export default NoProjects;
