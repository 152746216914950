import React from 'react';
import Steppers from 'components/Steppers';
import Typography from 'components/Typography';
import { Container, Content } from './styles';

const SideBar = props => {
  return (
    <Container style={{width: '31%'}}>
      <Content>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="gray">
          Etapas do Cadastro
        </Typography>
        <Typography style={{ width: '83%' }} variant="subtitle2" color="grayDark2">
          Aqui estão as etapas que você vai percorrer para cadastrar o seu
          projeto.
        </Typography>
      </Content>
      <div>
        <Steppers activeStep={props.activeStep} handleStep={props.handleStep} errors={props.errors} />
      </div>
    </Container>
  );
};

export default SideBar;
