import React from 'react';
import {
  Box,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { useStyles, useStylesAdmin } from './materialStyles';
import { Subtitle, Title } from './styles';
import { useUser } from 'hooks/useUser';

const steps = [
  {
    label: 'Dados básicos do projeto',
  },
  {
    label: 'Resumo e objetivos',
  },
  {
    label: 'ODS e Impacto',
  },
  {
    label: 'Vídeo, imagens e documentos',
  },
  {
    label: 'Valor do projeto e incentivos',
  },
  {
    label: 'Contrapartidas',
  },
  
];

const ProjectSteps = props => {
  const { user } = useUser();
  const isHidded = user.adminRole === 'general';
  if(!isHidded && steps.length === 6){
    steps.push({
      label: 'Análise',
    });
  }

  const classesProponent = useStyles();
  const classesAdmin = useStylesAdmin();
  const classes = user?.isAdmin ? classesAdmin : classesProponent;

  return (
    <Box
      sx={{
        maxWidth: 500,
      }}
    >
      <Stepper
        className={classes.root}
        style={{
          borderRadius: 30,
          marginTop: 15,
        }}
        activeStep={6}
        orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step
            className={classes.colorText}
            key={step.label}
            completed={false}
          >
            <StepButton color="inherit" onClick={props.handleStep(index)}>
              <StepLabel>
                <Title active={props.activeStep === index}>
                  {step.label}
                </Title>
              </StepLabel>
            </StepButton>
            <StepContent TransitionProps={{ in: true }}>
              <Typography>
                <Subtitle>{step.description}</Subtitle>
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProjectSteps;
