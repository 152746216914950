import styled from 'styled-components';

export const Card = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 6px;
  margin: 0 5px;
  cursor: pointer;
  filter: ${({ opacity }) => opacity ? `brightness(50%)` : 'none'};
  pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
  /* opacity: ${({ opacity }) => (opacity ? 0.5 : 1)}; */
  `;
