import styled from 'styled-components';

export const ProjectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
`;

export const Complement = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;

  color: ${({ theme }) => theme.colors.black};
`;

export const Container = styled.div`
    display: grid;
    gap: 0.3rem;
`;