import styled from 'styled-components';
import Typography from 'components/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  margin-bottom: 1rem;
`;

export const TextContent = styled.div`
  padding: 1rem;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.lighter};

  &.isEllipsis {
    p {
      max-width: 1517px;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Content = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const ContentBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;
  column-gap: 1rem;
`;

export const GoalTextContent = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.lighter};

  &.isEllipsis {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 735px;
      /* white-space: nowrap; */
    }
  }
`;
