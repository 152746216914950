import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.generalBackground};
  width: 100%;
  min-width: 1000px;
  padding: 1.5rem;
  min-height: 100vh;

  @media (max-width: 1250px){
    width: 77vw;
    min-width: auto;
  }

  @media (max-width: 1150px){
    width: 74vw;
    min-width: auto;
  }

  @media (max-width: 960px){
    width: 100%;
  }
`;

export const ContentPrimaryRoute = styled.div`
  background-color: transparent;
  /* height: 100vh; */
  width: 100%;
  min-width: 1000px;

  @media (max-width: 1000px){
    min-width: auto;
  }
`;

export const App = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.generalBackground};
  width: 100%;
  /* height: 100vh; */
  overflow-x: auto;
  padding: 40px 0 0 0;
`;
