import React from 'react';
import { IconDeleteLink } from 'assets/icons';
import { Container, Text, Link } from './styles';

const Filename = ({ label, handleDelete, link = false, disabled }) => {
  return (
    <Container>
      {link 
        ? <Link
            href={link}
            rel="noreferrer"
            target="_blank"
          >
            <Text>{label} </Text> 
          </Link>
        
        : <Text>{label} </Text>
      }
      {/* {!disabled && (
        <IconDeleteLink
        style={{ marginLeft: 5, cursor: 'pointer' }}
        onClick={handleDelete}
        />
      )} */}
      
    </Container>
  );
};

export default Filename;
