import React, { useCallback, useMemo } from 'react';
import Table from 'components/Table';
import Typography from 'components/Typography';
import { OpenInNew, MarkUnRead, MarkRead } from 'assets/icons';
import NotificationTableDetails from './NotificationTableDetails';

import { Link } from './styles';
import { formatDate } from 'utils/date';
import { NotificationService } from 'services/notifications';

const NotificationsTable = ({
  notifications,
  identification,
  onRefresh,
  isLoading,
  filterComponent: FilterComponent,
  isPaginated,
  currentPage,
  onChangePage,
  totalPages,
}) => {
  const columns = useMemo(() => {
    return [
      {
        identification: 'createdAt',
        name: 'Data',
        showTitleRenderColumn: true,
        renderColumn: ({ createdAt }) => {
          return (
            <Typography variant="manrope" color="brown">
              {formatDate(createdAt, 'dd/MM/yyyy')}
            </Typography>
          );
        },
      },
      {
        identification: 'proponentsName',
        name: 'Nome do proponente',
        showTitle: true,
      },
      {
        identification: 'senderName',
        name: 'Nome do usuário',
        showTitle: true,
      },
      {
        identification: 'projectsName',
        name: 'Nome do projeto',
        showTitle: true,
      },
      {
        identification: 'annex',
        name: 'Arquivo',
        showTitleRenderColumn: true,
        renderColumn: ({ annex }) => {
          return (
            <div>
              {annex 
              ? <Link href={`${annex}`} rel="noreferrer" target="_blank">
                  <OpenInNew />
                  <Typography variant="manrope" color="blue">
                    Documento
                  </Typography>
                </Link>
              : <Typography variant="manrope" color="brown">
                  {'Não informado'}
                </Typography>
              }
              
            </div>
          );
        },
      },
      {
        identification: 'readAt',
        renderColumn: ({ readAt }) => {
          return readAt ? <MarkRead /> : <MarkUnRead />;
        },
      },
    ];
  }, []);

  const handleReadNotification = useCallback(async value => {
    try {
      if (value?.status.toUpperCase() !== 'LIDO') {
        await NotificationService.read(value.id);
      }
    } catch (err){ 
      console.log(err);
    }
  }, []);

  return (
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={notifications}
      tableCollapsed
      filterComponent={FilterComponent}
      collapsedComponent={NotificationTableDetails}
      onRefresh={onRefresh}
      loading={isLoading}
      isPaginated={isPaginated}
      currentPage={currentPage}
      onChangePage={onChangePage}
      totalPages={totalPages}
      onCollapsed={value => handleReadNotification(value)}
    />
  );
};

export default NotificationsTable;
