import { useDispatch, useSelector } from 'react-redux';
import { updateUserAvatar } from 'store/ducks/auth/actions';

export const useUser = () => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const updateAvatar = (file) => {
    if (typeof file === 'string') {
      dispatch(updateUserAvatar(file));
    } else {
      const fileUrl = URL.createObjectURL(file);
      dispatch(updateUserAvatar(fileUrl));
    }
  }

  return { user, updateAvatar };
};
