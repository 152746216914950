import styled, { css } from 'styled-components';

export const EntryList = styled.ul`
    list-style: none;
    margin-top: 8px;
    background: #F8F8F8;
    border-radius: 2px;
    padding: 16px;
    max-height: 450px;
    overflow: auto;

    > li:not(:first-child) {
        margin-top: 16px;
    }
`;

export const SubList = styled.ul`
    list-style: none;
    padding-left: 24px;
    margin-top: 8px;

    > li {
        display: flex;
        align-items: stretch;
        
        &:not(:first-child) {
            margin-top: 8px;
        }
    }
`;

export const TransferButton = styled.button`
    transition: 250ms;
    min-height: 38px;
    flex: 1;
    background: transparent;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 0px 8px;
    cursor: pointer;

    ${({ active }) => !active && css`
        :hover {
            transform: translateX(8px);
        }
    `}

    ${({ active }) => active && css`
        background: #C15028;
        color: #FFFFFF;
    `}
`;

export const SectionTitle = styled.strong``;

export const BatchAutocompleteContainer = styled.div`
    display: flex;
    align-items: center;

    > button {
        width: 40px;
        aspect-ratio: 1/1;
    }
`;

export const TotalLabel = styled.label`
    display: block;
    text-align: right;
    margin-top: 8px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    ${({ partialAccepts, isPartial }) => (!partialAccepts && isPartial) && css`
        color: red;
    `}
`;