import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px !important;
  max-width: 450px !important;
  background: #FEFCFE;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1.5rem 4.25rem;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 388px; */
`;

export const Subtitle = styled.div`
  margin-top: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.54);
`;

export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${({ active }) => active ? '500' : 'normal'};
  font-size: 14px;
  line-height: 16px;
  color: ${({ active }) => active ? '#000000' : '#000000'};
  text-align: left;
`;