import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from 'components/Typography';
import { Divider } from '@material-ui/core';
import { ExitToApp, PersonOutline, ArrowForwardIos } from '@material-ui/icons';
import monitoreLogo from 'assets/images/monitore_black.png';

import {
  Container,
  SideMenu as Menu,
  UserIcon,
  UserInfo,
  MenuItem,
  MenuOptions,
  MonitoreButtonContainer,
  MonitoreButton,
  Link,
  ButtonSend,
} from './styles';
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks/useUser';
import { OpenInNew } from 'assets/icons';
import Input from 'components/Input';
import { IconFilter, IconSearch } from 'assets/icons';
import {
  SendNewsletterModal
} from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { UsersService } from 'services/users';
import {
  AccountCircleOutlined,
} from '@material-ui/icons';

const SideMenu = ({ options }) => {
  const history = useHistory();
  const { user } = useUser();
  const [activatedOption, setActivatedOption] = useState(2);
  const [email, setEmail] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);

  const dispatch = useDispatch();

  function handleClick(path, option) {
    history.replace(`${path}`);
    setActivatedOption(option);
    if (path === '/') {
      dispatch({ type: '@auth/LOGOUT' });
    }
  }

  const goToPage = useCallback(() => {
    window.open('https://www.monitore.incentiv.me/login');
  }, []);

  if(user.adminRole !== 'master'){
    options = options.filter(x => x.label !== 'Usuários');
  }

  if(user.adminRole !== 'evaluator' && user.adminRole !== 'master'){
    options = options.filter(x => x.label !== 'Leis');
  }

  function sendToNewsletter() {
    const data = {
      email: email
    }
    UsersService.sendEmailToNewsletter(data);
    setModalOpen('sendNewsletter')
  }

  const modalRender = useMemo(() => {
    const modal = {
      sendNewsletter: (
        <SendNewsletterModal
          open={modalOpen === 'sendNewsletter'}
          handleClose={() => setModalOpen('')}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen]);

  return (
    <div style={{width: '300px'}}>
      <Container>
        <Menu>
          <UserInfo>
            <UserIcon>
              {user?.avatar && <img src={user.avatar} alt={user?.name} />}
            </UserIcon>
            <Typography variant="body1" color="background">
              Bem-vindo
            </Typography>
            <Typography variant="h4" color="background" style={{ textAlign: 'center', marginTop: '5px' }}>
              {user.name}
            </Typography>
          </UserInfo>
          <MenuOptions>
            <div>
              {!user.sponsorId && (options.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleClick(item.path, index)}
                >
                  {item.icon}
                  <Typography
                    variant="body1"
                    color={activatedOption === index ? 'primary' : 'black'}
                  >
                    {item.label}
                  </Typography>
                </MenuItem>
              )))}
              {user.isAdmin && (<Divider light />)}
              <MenuItem onClick={() => handleClick(user.isAdmin ? '/admin/meus-dados' : '/meus-dados', 5)}>
                <PersonOutline
                  style={{ height: '24px', width: '24px', margin: '0px 10px' }}
                />
                <Typography
                  variant="body1" 
                  color={activatedOption === 5 ? 'primary' : 'black'}
                >
                  Meus Dados
                </Typography>
              </MenuItem>
              {!user.isAdmin && (<Divider light />)}
              <MenuItem onClick={() => handleClick('/', 0)}>
                <ExitToApp
                  style={{ height: '24px', width: '24px', margin: '0px 10px' }}
                />
                <Typography variant="body1" color="black">
                  Sair
                </Typography>
              </MenuItem>
              {/* <Typography variant="manrope" color="black" className="newsletter">
                Assine nossa news
              </Typography>
              <MenuItem >
                <Input
                  label="Insira seu e-mail"
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  value={email}
                  height={'16px'}
                  style={{ paddingRight: '5px' }}
                />
                <ButtonSend onClick={() => sendToNewsletter()}>
                  <ArrowForwardIos style={{ margin: '0px 5px', color: 'white' }}/>
                </ButtonSend> 
              </MenuItem>*/}
              <MonitoreButtonContainer>
                <Typography variant="manrope" color="grayLight2">
                  Ir para
                </Typography>
                <MonitoreButton onClick={() => goToPage()}>
                  <img src={monitoreLogo} alt="Monitor" />
                </MonitoreButton>
              </MonitoreButtonContainer>
              <div style={{ margin: '20px', textAlign: 'center' }}>
                  <Link
                    href={'/politica-de-privacidade'}
                    rel="noreferrer"
                    target="_blank"
                    style={{ marginRight: '4px' }}
                  >
                      Conheça nossa Política de privacidade 
                  </Link>

                  <Link
                    href={'/termos-e-condicoes-de-uso'}
                    rel="noreferrer"
                    target="_blank"
                  >
                      e nossos Termos e condições de uso.
                  </Link>
              </div>
              {modalRender}
            </div>
          </MenuOptions>
        </Menu>
      </Container>
    </div>
  );
};

export default SideMenu;
