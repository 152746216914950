import React, { useEffect, useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import { Container, IconRight } from './styles';
import { ProponentService } from 'services/proponent';
import Select from 'components/Select';
import { useForm } from 'react-hook-form';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { Close } from '@material-ui/icons';

const ChangeProponent = ({ open, handleClose, project_data, onChange, onClose }) => {
  const [proponents, setProponents] = useState([]);
  const [selectedProponent, setSelectedProponent] = useState([]);
  const [isTransfer, setIsTransfer] = useState(false);
  const { setValue } = useForm();

  const loadProponents = useCallback(async () => {
    const response = await ProponentService.getListProponents();    
    setProponents(response.data);
  }, []);

  useEffect(() => {
    loadProponents();
  }, [loadProponents]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <IconRight>
          <Close onClick={() => {
            onClose()
            setIsTransfer(false)
          }} />
        </IconRight>
        <Container>
          <SelectAutocomplete
              options={proponents.map(proponent => proponent.name)}
              label="Proponente"
              onChange={(event, value) => {
                const index = proponents.findIndex(p => p.name === value)
                setValue('proponentId', proponents[index].id)
                setSelectedProponent(proponents[index].id)
              }}
              multiline={false}
              disableCloseOnSelect={false}
            />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          onChange(selectedProponent) 
          setIsTransfer(true)
        }} 
        color="primary">
          {isTransfer ? 'TRANSFERINDO...' : 'TRANSFERIR'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeProponent;
