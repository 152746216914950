import styled from 'styled-components';

export const ProjectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
`;

export const ProgressBar = styled.div`
  height: 18px;
  width: 100%;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.grayLight2};
  margin: 10px 0 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-radius: 30px;
    height: 18px;
    width: ${({ progress }) => progress > 100 ? 100 : progress}%;
    background: ${({ theme }) => theme.colors.primary};
  }
`;

export const FundraisingItem = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-of-type, div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.5px;
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectContainer = styled.div`
  justify-content: flex-start;
  margin-bottom: 20px;
  display: flex;
  gap: 30px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 10px;
`;