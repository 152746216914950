import React, { useCallback, useState  } from 'react';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useUser } from 'hooks/useUser';

import { Container, Actions } from './styles';

const Footer = ({ activeStep, handleNext, handlePrevious, handleSave, projectStatus, proponentId, formHook, setShowChangeProponentModal }) => {
  const { push } = useHistory();
  const [loadingAction, setLoadingAction] = useState(false);
  const { user } = useUser();

  const isGeneral = user.adminRole === 'general';
  const isMonitore = user.adminRole === 'monitore';
  const redirectToProjects = useCallback(() => {
    push('/admin/projetos')
  },[]);

  return (
    <Container>
      <Actions>
        <Button variant="secondary" onClick={() => push('/admin/projetos')}>
          CANCELAR
        </Button>
        {
          activeStep != 0 ? (
            <Button variant="secondary" onClick={handlePrevious}>
              VOLTAR
            </Button>
          ) : <div />
        }
      </Actions>

      <Actions>
        <Button
          variant="primary"
          onClick={handleNext}
          style={{ display: activeStep === 6 && projectStatus === 'Em rascunho' ? 'none' : 'flex' }}
          disabled={isMonitore}
        >
          PRÓXIMO
        </Button>
        {/* <Button variant="secondary" onClick={handleSave}>
          SALVAR
        </Button>
        
        <Button
          variant="primary"
          onClick={handleNext}
          style={{ display: activeStep === 5 && projectStatus === 'Em rascunho' ? 'none' : 'flex' }}
          disabled={isMonitore}
        >
          {activeStep !== 6 || (activeStep !== 5 && isGeneral) ? 'PRÓXIMO' : 'CONFIRMAR ANÁLISE' }
        </Button>
        <Button
          type="submit"
          style={{ display: activeStep === 5 && projectStatus === 'Em rascunho' ? 'flex' : 'none' }}
          onClick={()=>{
            if(!proponentId){
              formHook.setValue('status', 'Em rascunho')
              handleSave();
              setShowChangeProponentModal(true);
            } else {
              formHook.setValue('status', 'Em análise')
              handleSave();
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          }}
        >
          {!proponentId ? 'DELEGAR PROPONENTE' : 'ENVIAR PARA ANÁLISE'}
        </Button> */}
      </Actions>
    </Container>
  );
};

export default Footer;
