import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerModal, Title, Row, Footer, Container } from './styles';
import { Backdrop, Fade, Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import {
  changeStatus as changeModalStatus,
} from 'store/ducks/modals/actions';

const ChangeStatus = () => {
  const [data, setData] = React.useState({
    status: '',
  });

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(data);
    handleClose();
  };

  const dispatch = useDispatch();
  const changeStatus = useSelector(state => state.modals.vitrineModals.changeStatus);
  const handleClose = () => {
    dispatch(changeModalStatus());
  }

  const status = ['Em rascunho', 'Em análise', 'Em ajuste', 'Aguardando regularização', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'];

  return (
    <Container
      open={changeStatus}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: `rgba(#050714, 0.6) !important`,
        },
      }}
      disableAutoFocus
    >
      <ContainerModal>
        <Row>
        <Select
            name="status"
            className="select"
            label="Status"
            options={status}
            value={data.status}
            onChange={handleChange}
          />
        </Row>
        <Footer>
          <Button onClick={handleSubmit}>Alterar</Button>
        </Footer>
      </ContainerModal>
    </Container>
  );
};
export default ChangeStatus;
