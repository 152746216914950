import React, { useState } from 'react';
import { Container } from './styles';
import { InputAdornment, FormControl, InputLabel, IconButton, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

function InputPassword({ label, error, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined">
      {label && <InputLabel>{label}</InputLabel>}
      <Container
        {...rest}
        label={label}
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={50}
      />
      {!!error && (
        <FormHelperText error>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default InputPassword;