import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import PageTitle from 'components/PageTitle';
import ProponenteTable from 'components/ProponenteTable';
import Select from 'components/Select';
import { ProponentService } from 'services/proponent';
import { ProjectService } from 'services/projects';
import { LocationsService } from 'services/locations';
import { LawsService } from 'services/laws';
import Input from 'components/Input';
import { IconSearch } from 'assets/icons';
import SelectAutocomplete from 'components/SelectAutocomplete';
import SelectMultiple from 'components/SelectMultiple';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import { YoutubeSearchedFor } from '@material-ui/icons';
import { Container, Grid, ProjectsContainer, Content, ButtonSearch, Options, ContainerInputs, ContentInput, Label, ButtonValidateCnd } from './styles';
import { CndService } from 'services/cnd';
import { toast } from 'react-toastify';

const AdmProponente = () => {
  const [proponents, setProponents] = useState([]);
  // const [allProponents, setAllProponents] = useState([]);
  const [locationsUf, setLocationsUf] = useState([]);
  const [filters, setFilters] = useState([]);
  const [laws, setLaws] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPaginated, setIsPaginated] = useState(false);
  const [name, setName] = useState(null);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
  });
  let defaultArr = { name: 'Todos', description: 'Todos', state_code: 'Todos' };
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedLaws, setSelectedLaws] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  
  const complianceStatusOptions = useMemo(() => {
    return [
      'Todos',
      'Novo proponente',
      'Aguardando análise',
      'Aguardando ajustes no cadastro',
      'Aguardando regularização de CNDs',
      'Proponente regular',
      'Proponente Inativo'
    ];
  }, []);

  const statusOptions = useMemo(() => {
    return [
      'Todos',
      'Ativo',
      'Inativo'
    ];
  }, []);

  const [selectedComplianceStatus, setSelectedComplianceStatus] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState([]);

  const filtersRef = useRef();

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const loadExternalData = useCallback(async () => {
    const response = await ProjectService.getFormInfo();
    setLocationsUf([defaultArr, ...response.data.locations_uf]);
  }, []);

  const loadProponents = useCallback(async (search, page = 1) => {
    try {
      const filter = {
        name: search
      }
      setName(search);
      setLoading(true);
      const { data } = await ProponentService.listProponentsPagination(filter, page);
      // const allProponents = await ProponentService.listProponents();
      const cities = await LocationsService.list();
      setProponents(data.data);
      // setAllProponents(allProponents.data)
      setCities(cities.data.data)
      setPagination({
        currentPage: data.current_page,
        totalPages: data.last_page,
      });
      setIsPaginated(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadProponentsByFilter = useCallback(async (page = 1) => {
    try {
      setLoading(true);
      const { data } = await ProponentService.searchProponents(filtersRef.current, page);
      // if (Array.isArray(data)) {
        setProponents(data.data);
        setPagination({
          currentPage: data.current_page,
          totalPages: data.last_page,
        });
        setIsPaginated(true);
      // }
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const validateExpiredCnds = useCallback(async () => {
    try {
      const { data } = await CndService.validateExpiredCNDs();
      toast.success('Validação feita com sucesso');
    } catch (err) {
      toast.error('Ocorreu um erro durante a validação');
      console.log(err.response);
    }
  }, []);

  const loadLaws = useCallback(async () => {
    const response = await LawsService.list();

    setLaws([defaultArr, ...response.data]);
  }, []);

  const handleAddFilter = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [setFilters, filters],
  );

  useEffect(() => {
    handleAddFilter('name', name)
  }, [name]);

  useEffect(() => {
    handleAddFilter('state', selectedStates)
  }, [selectedStates]);

  useEffect(() => {
    handleAddFilter('city', selectedCities)
  }, [selectedCities]);

  useEffect(() => {
    handleAddFilter('laws', selectedLaws)
  }, [selectedLaws]);

  useEffect(() => {
    handleAddFilter('analysis_compliance_status', selectedComplianceStatus)
  }, [selectedComplianceStatus]);

  useEffect(() => {
    handleAddFilter('order_by', selectedOrderBy)
  }, [selectedOrderBy]);

  useEffect(() => {
    handleAddFilter('status', selectedStatus)
  }, [selectedStatus]);

  useEffect(() => {
    loadProponents();
    loadExternalData();
    loadLaws();
  }, [loadLaws, loadExternalData, loadProponents]);

  const orderOptions = useMemo(() => {
    return [
      'Data de início do cadastro',
      'Data de envio para análise',
      'Data de atualização do status',
      'Última análise realizada',
      'Última atualização de aporte'
    ];

  }, []);

  return (
    <Container>
      <main>
        <PageTitle
          title="Proponentes"
          subtitle="Lista de proponentes cadastrados"
          type="adm"
        />
        <ProjectsContainer>
          <Content>
            <Input
              label="Buscar por proponente"
              onChange={e => setName(e.target.value)}
            />

            <SelectAutocomplete
              className="select-project-name"
              label="Estado"
              name="state"
              options={locationsUf.map(item => item.state_code)}
              onChange={(event, value) => setSelectedStates(value)}
            />
            <SelectAutocomplete
              className="select-project-name"
              label="Cidade"
              options={cities.map(item => item.name + ' - ' + item.state_code)}
              onChange={(event, value) => setSelectedCities(value)}

              name="city"
            />
            <Select
              className="select-project-name"
              label="Leis"
              options={laws.map(item => item.description)}
              onChange={e => setSelectedLaws(laws[e.target.value].id_string)}
              role="admin"
            />
            <SelectMultiple
              label="Status da análise de conformidade do projeto"
              className="select-multiple"
              role="admin"
              value={selectedComplianceStatus}
              options={complianceStatusOptions.map(status => status)}
              onChange={e => { setSelectedComplianceStatus(e) }}
            />
            <Options>
              <ButtonSearch onClick={() => loadProponentsByFilter()}>
                <IconSearch />
              </ButtonSearch>
            </Options>
          </Content>
          <Content>
            <ContainerInputs>
            <Label>Data de início do cadastro</Label>
            <ContentInput>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('minimum_created_at', dateValue);
                }}
              />
              <span>e</span>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('maximum_created_at', dateValue);
                }}
              />
            </ContentInput>
          </ContainerInputs>

          <ContainerInputs>
            <Label>Data de envio para análise</Label>
            <ContentInput>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('minimum_send_to_analysis_date', dateValue);
                }}
              />
              <span>e</span>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('maximum_send_to_analysis_date', dateValue);
                }}
              />
            </ContentInput>
          </ContainerInputs>

          <ContainerInputs>
            <Label>Data de atualização do status</Label>
            <ContentInput>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('minimum_status_date_changed', dateValue);
                }}
              />
              <span>e</span>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('maximum_status_date_changed', dateValue);
                }}
              />
            </ContentInput>
          </ContainerInputs>

          <ContainerInputs>
            <Label>Última análise realizada</Label>
            <ContentInput>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('minimum_analysis_compliance_status_date', dateValue);
                }}
              />
              <span>e</span>
              <DatePicker
                onChange={value => {
                  const dateValue = value.toISOString().substring(0, 10);
                  handleAddFilter('maximum_analysis_compliance_status_date', dateValue);
                }}
              />
            </ContentInput>
          </ContainerInputs>
          </Content>
          <Content>
            <ContainerInputs>
              <Label>Última atualização de aporte</Label>
              <ContentInput>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('minimum_captured_value_date', dateValue);
                  }}
                />
                <span>e</span>
                <DatePicker
                  onChange={value => {
                    const dateValue = value.toISOString().substring(0, 10);
                    handleAddFilter('maximum_captured_value_date', dateValue);
                  }}
                />
              </ContentInput>
            </ContainerInputs>

            <ContainerInputs>
              <Label>Ordenar por</Label>
              <SelectAutocomplete
                className="select-subcategory-name"
                placeholder="Ordenar por"
                options={orderOptions}
                onChange={(event, value) => setSelectedOrderBy(value)}
                multiline={false}
                disableCloseOnSelect={false}
              />
            </ContainerInputs>

            <ContainerInputs>
              <Label>Status</Label>
              <Select
                options={statusOptions.map(status => status)}
                onChange={e => { setSelectedStatus(e.target.value) }}
                value={selectedStatus}
                role="admin"
              />
            </ContainerInputs>
          </Content>

          {/* <Button variant="primary" onClick={() => validateExpiredCnds()} className="button-validate-cnds">
            <YoutubeSearchedFor />
            Validar CNDs Vencidas
          </Button> */}

          <ProponenteTable
            notifications={proponents}
            isLoading={loading}
            {...pagination}
            onChangePage={page => loadProponentsByFilter(page)}
            isPaginated={isPaginated}
            onRefresh={() => loadProponents()}
          />
        </ProjectsContainer>
      </main>
    </Container>
  );
};

export default AdmProponente;
