import AdminProfileForm from 'components/AdminProfileForm';
import PageTitle from 'components/PageTitle';
import React from 'react';

import { Container } from './styles';

const AdminProfile = () => {
  return (
    <Container>
      <PageTitle title="Meus Dados" subtitle="Informe os dados abaixo." />
      <AdminProfileForm />
    </Container>
  );
}

export default AdminProfile;