import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0 1.5rem;
  padding: 0.5rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > div:first-of-type {
    /* flex: 1; */
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
