
import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  main {
    height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ProjectsContainer = styled.div`
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100% - 229px);
  height: auto;
  border-radius: 30px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const DashboardItem = styled.div`
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.primary}10;
  min-height: calc(100% - 230px);
  height: auto;
  border-radius: 30px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 25%;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  p{
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
  }
`;
export const Dashboard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 50px;
`;