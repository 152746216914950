import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTheme } from 'styled-components';
import Typography from 'components/Typography';
import Input from 'components/Input';
import CounterPartCards from 'components/CounterPartCards';
import Button from 'components/Button';
import { IconAdd } from 'assets/icons';
import formatCurrency, { currencyMask } from 'utils/currency';
import {
  Container,
  ButtonContent,
  ButtonAddCounterPart,
  ButtonAddText,
  DivCotas
} from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { InputLabel } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import ModalInformation from 'components/ModalInformation';
import { CloseCaptureProponentModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { WarningOutlined } from '@material-ui/icons';


const Step6 = ({ setShowCounterpartsInputs, project }) => {
  const { control, setValue, getValues } = useFormContext();
  const [errorDuplicate, setErrorDuplicate] = useState(false);
  const [counterPartList, setCounterPartList] = useState(getValues('quota') ?? []);

  useEffect(()=>{
      setCounterPartList(getValues('quota'));
  }, [getValues('quota')])
  
  const [form, setForm] = useState({
      id: counterPartList[0]?.id ? counterPartList[0].id+1 : 999999,
      quota: '',
      valueOf: '',
      valueUpTo: '',
      counterparts: { '1': '', '2': '', '3': '' },
  });

  const [showRegisterInputs, setShowRegisterInputs] = useState(true);
  const [showDivRegister, setShowDivRegister] = useState(false);

  const theme = useTheme();

  const addCounterPartInputs = () => {
    const length = Object.keys(form.counterparts).length + 1;
    setForm({
      ...form,
      counterparts: { ...form.counterparts, [length]: '', },
    });
  };

  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });

  const modalRender = useMemo(() => {
    const modal = {
      CloseCaptureProponent: (
        <CloseCaptureProponentModal
          open={modalOpen === 'CloseCaptureProponent'}
          handleClose={() => setModalOpen('')}
          project={project}
        />
      ),
    };
    return modal[modalOpen];
  }, [modalOpen, project]);

  const handleChange = item => {
    setShowRegisterInputs(prevState => !prevState);
    setShowDivRegister(prevState => !prevState);
    setShowCounterpartsInputs(prevState => !prevState);

    setForm({
      ...form,
      ...item,
    });

    const updatedArray = counterPartList.filter(value => value.id !== item.id);
    setCounterPartList(updatedArray);
    setValue('counterparts', updatedArray);
    setValue('quota', updatedArray);
  };

  const handleAddCounterPartList = () => {
    if (
      form.counterparts[0] === '' ||
      form.counterparts[1] === '' ||
      form.counterparts[2] === '' ||
      form.quota === '' ||
      form.valueOf === '' ||
      form.valueUpTo === ''
    ){
      toast.error('Atenção: Um dos campos de contrapartidas está em branco. Preencha ou exclua antes de salvar a cota cadastrada.')
      return;
    }
    setCounterPartList([...counterPartList, form]);
    setValue('counterparts', [...counterPartList, form]);
    setValue('quota', [...counterPartList, form]);
    setForm({
      id: form.id + 50,
      counterparts: { '1': '', '2': '', '3': '' },
      quota: '',
      valueOf: '',
      valueUpTo: '',
    });

    setShowRegisterInputs(prevState => !prevState);
    setShowCounterpartsInputs(prevState => !prevState);
    setShowDivRegister(prevState => !prevState);
  };

  const mounted = useRef();

  useEffect(() => {
    if(!mounted.current){
      setShowRegisterInputs(prevState => !prevState);
      mounted.current = true;
    }
  });

  const hasDuplicates = (array) => {
    return (new Set(array)).size !== array.length;
  }

  const existCounterpart = (counterparts, value) => {
    // eslint-disable-next-line array-callback-return
    let count = 0;
    Object.keys(counterparts).forEach((key)=> {
        if(counterparts[key].toLowerCase() === value.toLowerCase()){
            count++;
        }

        else{
            if(hasDuplicates(Object.values(form.counterparts))){
                count++;
            }
        }
    })
    
    if((count !== 0 && value !== '')) {
        setErrorDuplicate(true);
    }

    else{
        setErrorDuplicate(false);
    }
}

  const status = getValues('status');

  const isDisabledByStatus = ['Em análise', 'Em captação', 'Incentivado', 'Não captado', 'Projeto reprovado'].includes(status);
  return (
    <Container>
      <div>
        <Typography style={{ marginBottom: 8 }} variant="h6" color="grayDark2">
          Contrapartidas
        </Typography>
        <Typography variant="subtitle2" color="grayLight1">
          Quais as contrapartidas oferecidas pelo seu projeto aos
          patrocinadores?
        </Typography>
      </div>
      {!showRegisterInputs &&
        counterPartList.map((item, index) => {
          return (
            <div style={{ marginTop: 24 }}>
              <CounterPartCards
                key={item.id}
                title={item.quota}
                cards={item}
                onClick={() => handleChange(item)}
              />
            </div>
          );
        })}
      {showDivRegister && (
        <>
          <div style={{ marginTop: 24 }}>
            {showRegisterInputs && (
              <DivCotas>
                <div style={{ width: '70%' }}>
                  {/* <Controller
                    name="counterpart"
                    control={control}
                    render={({ field }) => (
                      <Input width="660px" label="Cota" {...field} />
                    )}
                  /> */}
                  <Input
                    // width="70%"
                    label="Cota *Obrigatória"
                    onChange={e => setForm({ ...form, quota: e.target.value })}
                    value={form.quota}
                    required={true}
                    margin = {88}
                    disabled={isDisabledByStatus}
                  />
                </div>

                
                <div style={{ width: '15%' }}>

                { (form.valueOf <= 0 || form.valueOf == null || form.valueOf == ''|| form.valueOf == '0,00') &&
                  <InputLabel style={{color: 'red', marginLeft: '3.8%', marginTop: '-5px', backgroundColor: '#fff', position: 'absolute', fontSize: '12px', zIndex: 99}} id="select-label">Obrigatório</InputLabel>
                }
                  <Controller
                    name="minimum_value"
                    control={control}
                    render={({ field }) => (
                      <Input
                        // width="15%"
                        label="Valor de"                                   
                        {...field}
                        onChange={e =>
                          setForm({ ...form, valueOf: currencyMask(e.target.value) })
                        }
                        value={`R$ ${form.valueOf}`}
                        disabled={isDisabledByStatus}
                      />
                    )}
                  />
                </div>

                
                <div style={{ width: '15%' }}>
                { (form.valueUpTo <= 0 || form.valueUpTo == null || form.valueUpTo == '' || form.valueUpTo == '0,00') &&
                  <InputLabel style={{color: 'red', marginLeft: '3.8%', marginTop: '-5px', backgroundColor: '#fff', position: 'absolute', fontSize: '12px', zIndex: 99}} id="select-label">Obrigatório</InputLabel>
                }
                  <Input
                    // width="15%"
                    label="Valor até"    
                    value={`R$ ${form.valueUpTo}`}  
                    required={true}
                    margin = {20}                     
                    onChange={e => setForm({ ...form, valueUpTo: currencyMask(e.target.value) })}
                    disabled={isDisabledByStatus}
                  />
                </div>
              </DivCotas>
            )}

            {showRegisterInputs &&
              Object.entries(form.counterparts).map((item, index) => (
                <div style={{ marginTop: 24 }} key={item[0]}>
                  {item[0] !== 'title' && (
                    <Input
                      height="62px"
                      // placeholder={`Contrapartida ${(parseInt(item[0]))}`}
                      label={`Contrapartida` + ` 0${item[0]}`}
                      value={item[1]}
                      

                      required={index === 0 ? true : false}
                      margin = {92}

                      onChange={e => {
                        setForm({
                          ...form,
                          counterparts: {
                            ...form.counterparts,
                            [item[0]]: e.target.value,
                          },
                        })
                        
                        existCounterpart(form.counterparts, e.target.value);
                      }}
                      disabled={isDisabledByStatus}
                    />
                  )}
                </div>
              ))}
          </div>
        </>
      )}
      {/* <ButtonContent>
        <div style={{display: 'flex', alignItems: 'center'}}>
        {showRegisterInputs && showDivRegister && (
            <Button
              borderColor="transparent"
              variant="secondary"
              color={theme.colors.primary}
              onClick={handleChange}
              disabled={isDisabledByStatus}
            >
              Cancelar Cota
            </Button>
          )}

          {showRegisterInputs && Object.keys(form.counterparts).length > 1 && showDivRegister && (
            <Button
              borderColor="transparent"
              variant="secondary"
              color={theme.colors.primary}
              onClick={() => {
                delete form.counterparts[(Object.keys(form.counterparts).length)];
                setForm({
                  ...form,
                  counterparts: form.counterparts,
                });
              }}
              disabled={isDisabledByStatus}
            >
              Excluir contrapartida
            </Button>
          )}

          <Button
            variant="primary"
            color={errorDuplicate ? 'grey' : theme.colors.primary}
            onClick={showDivRegister ? handleAddCounterPartList : handleChange}
            disabled={isDisabledByStatus || errorDuplicate}
          >
            {showDivRegister ? 'SALVAR COTA' : 'CADASTRAR NOVA COTA'}
          </Button>
          {errorDuplicate && (
                        <Tooltip title="As contrapartidas não podem ter o mesmo nome." placement="top">
                            <WarningOutlined style={{color: 'red', width: '1.35rem', marginLeft: '5px'}} />
                        </Tooltip>
                    )}
        </div>
        {showRegisterInputs && showDivRegister && (
          <ButtonAddCounterPart onClick={addCounterPartInputs} type="button" style={{ cursor: 'pointer' }} disabled={isDisabledByStatus}>
              <IconAdd width={20} height={20} />
              <ButtonAddText style={{color: theme.colors.primary}}>ADICIONAR CONTRAPARTIDA</ButtonAddText>
          </ButtonAddCounterPart>
        )}
      </ButtonContent>    */}

      {/* <div style={{marginTop: '10px'}}>
        <Button type="button"
          onClick={() => setModalOpen('CloseCaptureProponent')}
          >
          Encerrar captação
        </Button>
      </div> */}
      {modalRender}

      <ModalInformation
        open={showModalInformation}
        handleClose={() => setShowModalInformation(false)}
        {...modalInformationData}
        project
      />

    </Container>
  );
};

export default Step6;
