import Button from 'components/Button';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProponentService } from 'services/proponent';
import { formatValueDiligence } from 'utils/diligence';
import { cepMask, cnpjMask, cpfMask, phoneMask } from 'utils/maskUtils';
import AccessDataAdmin from './AccessDataAdmin';
import AnalyzeAdmin from './AnalyzeAdmin';
import Rules from './Rules';
import ImagesAndVideosAdmin from './ImagesAndVideosAdmin';
import RegistrationDataAdmin from './RegistrationDataAdmin';
import Cnds from './CNDs';
import { useHistory } from 'react-router-dom';
import { LoginService } from 'services/login';
import { useUser } from 'hooks/useUser';

import { Footer, Form, Actions } from './styles';
import WitnessAndLocationAdmin from './WitnessAndLocationAdmin';
import { validateCnpj, validateCpf } from 'utils/validation';
import { removeMask } from 'utils/maskUtils';

const ProponentFormAdmin = ({
  currentStep,
  handleNext,
  handlePrevious,
  loading,
  proponent,
  cnds,
  setCnds,
  uploadCnd,
  handleCancel,
}) => {
  const { push } = useHistory();
  const { id } = useParams();
  const [loadingAction, setLoadingAction] = useState(false);
  const [step5IsValid, setStep5IsValid] = useState(false);
  const [cnd, setCnd] = useState([]);
  const { user } = useUser();
  const isNull = val => val == null;
  const keyAccountAnterior = proponent.keyAccountUserId;
  let isDisabled = 
  (proponent?.registerStatus === 'Em análise' && user.adminRole === 'cs_proponent2');
  let isSupport = (proponent?.registerStatus === 'Em análise' && (user.adminRole === 'support'));
  const isGeneral = user.adminRole === 'general';
  const isMonitore = user.adminRole === 'monitore';
  let isCsRelationship = (proponent?.registerStatus !== 'Em andamento' && ((['cs_proponent_relationship', 'cs_proponent1'].includes(user.adminRole))));
  const isRulesAccepted = proponent.ruleDataChange &&
  proponent.ruleIncentivsResponsibilities &&
  proponent.ruleProponentsResponsabilities &&
  proponent.ruleProjectWithdrawal;

  const [isValidData, setIsValidData] = useState();

  let onlySeeCND = user.adminRole === 'cs_proponent_relationship' || user.adminRole === 'cs_proponent2' || user.adminRole === 'key_account2';

  useEffect(() => {
    let validateBlankData = proponent?.legalPerson === 'Pessoa física' ?
                            isNull(proponent.cpf) || isNull(proponent.fullName) || isNull(proponent.state) || isNull(proponent.cep) :
                            isNull(proponent.cpfResponsible) || isNull(proponent.nameResponsible) || isNull(proponent.state) || isNull(proponent.cep) || isNull(proponent.cnpj);
  
    setIsValidData(validateBlankData);
  }, [isValidData, setIsValidData, proponent])


  const formHook = useForm({
    defaultValues: {
      analyze_subject: proponent?.analyzeSubject === "undefined" ? "" : proponent?.analyzeSubject,
      analyze_message: proponent?.analyzeMessage === "undefined" ? "" : proponent?.analyzeMessage,
      status: proponent?.status === "undefined" ? "" : proponent?.status,
      legalPerson: proponent?.legalPerson === "undefined" ? "" : proponent?.legalPerson,
      tax_regime:
        proponent?.taxRegime === 'undefined'
        ? ''
        : proponent?.taxRegime,
      company_size:
        proponent?.companySize === 'undefined'
        ? ''
        : proponent?.companySize,
      name: proponent?.name === "undefined" ? "" : proponent?.name,
      full_name: proponent?.fullName === "undefined" ? "" : proponent?.fullName,
      cpf: cpfMask(proponent?.cpf === "undefined" ? "" : proponent?.cpf || ''),
      email: proponent?.email === "undefined" ? "" : proponent?.email,
      key_account_user_id: proponent?.keyAccountUserId === "undefined" ? "" : proponent?.keyAccountUserId,
      phone: phoneMask(proponent?.phone === "undefined" ? "" : proponent?.phone || ''),
      about: proponent?.about === "undefined" ? "" : proponent?.about,
      years_experience: proponent?.yearsExperience === "undefined" ? "" : proponent?.yearsExperience,
      cnpj: cnpjMask(proponent?.cnpj === "undefined" ? "" : proponent?.cnpj || ''),
      cnd_date: proponent?.cndDate,
      cep: cepMask(proponent?.cep === "undefined" ? "" : proponent?.cep || ''),
      city: proponent?.city === "undefined" ? "" : proponent?.city,
      number: proponent?.number === "undefined" ? "" : proponent?.number,
      public_place: proponent?.publicPlace === "undefined" ? "" : proponent?.publicPlace,
      complement: proponent?.complement === "undefined" ? "" : proponent?.complement,
      country: proponent?.country === "undefined" ? "" : proponent?.country,
      district: proponent?.district === "undefined" ? "" : proponent?.district,
      state: proponent?.state === "undefined" ? "" : proponent?.state,
      photo_profile: proponent?.photoProfile === "undefined" ? "" : proponent?.photoProfile,
      photosCarousel: proponent?.photosCarousel === "undefined" ? "" : proponent?.photosCarousel,
      video: proponent?.video === "undefined" ? "" : proponent?.video,
      site: proponent?.site === "undefined" ? "" : proponent?.site,
      youtube: proponent?.youtube === "undefined" ? "" : proponent?.youtube,
      facebook: proponent?.facebook === "undefined" ? "" : proponent?.facebook,
      instagram: proponent?.instagram === "undefined" ? "" : proponent?.instagram,
      linkedln: proponent?.linkedln === "undefined" ? "" : proponent?.linkedln, 
      value_captured_incentive_laws: proponent?.valueCapturedIncentiveLaws === "undefined" ? "" : proponent?.valueCapturedIncentiveLaws,
      incentive_laws: proponent?.incentiveLaws === "undefined" ? "" : proponent?.incentiveLaws,
      full_name_witness: proponent?.fullNameWitness === "undefined" ? "" : proponent?.fullNameWitness,
      witness_email: proponent?.witnessEmail === "undefined" ? "" : proponent?.witnessEmail,
      corporate_name: proponent?.corporateName === "undefined" ? "" : proponent?.corporateName,
      name_responsible: proponent?.nameResponsible === "undefined" ? "" : proponent?.nameResponsible,
      phone_responsible: proponent?.phoneResponsible === "undefined" ? "" : proponent?.phoneResponsible,
      email_responsible: proponent?.emailResponsible === "undefined" ? "" : proponent?.emailResponsible,
      cpf_responsible: proponent?.cpfResponsible === "undefined" ? "" : proponent?.cpfResponsible,
      social_contract: proponent?.socialContract === "undefined" ? "" : proponent?.socialContract,
      social_status: proponent?.socialStatus === "undefined" ? "" : proponent?.socialStatus,
      last_election: proponent?.lastElection === "undefined" ? "" : proponent?.lastElection,
      company_names: proponent?.companyNames === "undefined" ? "" : proponent?.companyNames,
      register_status: proponent?.registerStatus === "undefined" ? "" : proponent?.registerStatus,
      analysis_compliance_status: proponent?.analysisComplianceStatus === "undefined" ? "" : proponent?.analysisComplianceStatus,
      highlights_and_awards: proponent?.highlightsAndAwards === "undefined" ? "" : proponent?.highlightsAndAwards,
      rule_data_change: proponent.ruleDataChange,
      rule_incentivs_responsibilities: proponent.ruleIncentivsResponsibilities,
      rule_project_withdrawal: proponent.ruleProponentsResponsabilities,
      rule_proponents_responsabilities: proponent.ruleProjectWithdrawal,
      name_register_responsible: proponent.nameRegisterResponsible,
      email_register_responsible: proponent.emailRegisterResponsible,
      phone_register_responsible: proponent.phoneRegisterResponsible,
      auth_name: formatValueDiligence(proponent?.authName),
      auth_corporate_name: formatValueDiligence(proponent?.authCorporateName),
      auth_video: formatValueDiligence(proponent?.authVideo),
      auth_cpf_responsible: formatValueDiligence(proponent?.authCpfResponsible),
      auth_about: formatValueDiligence(proponent?.authAbout),
      auth_value_captured_incentive_laws: formatValueDiligence(proponent?.authValueCapturedIncentiveLaws),
      auth_birth_date: formatValueDiligence(proponent?.authBirthDate),
      auth_cep: formatValueDiligence(proponent?.authCep),
      auth_city: formatValueDiligence(proponent?.authCity),
      auth_cnpj: formatValueDiligence(proponent?.authCnpj),
      auth_complement: formatValueDiligence(proponent?.authComplement),
      auth_country: formatValueDiligence(proponent?.authCountry),
      auth_cpf: formatValueDiligence(proponent?.authCpf),
      auth_date_register: formatValueDiligence(proponent?.authDateRegister),
      auth_district: formatValueDiligence(proponent?.authDistrict),
      auth_email: formatValueDiligence(proponent?.authEmail),
      auth_phone: formatValueDiligence(proponent?.authPhone),
      auth_email_responsible: formatValueDiligence(proponent?.authEmailResponsible),
      auth_experience_incentive_laws: formatValueDiligence(proponent?.authExperienceIncentiveLaws),
      auth_facebook: formatValueDiligence(proponent?.authFacebook),
      auth_full_name: formatValueDiligence(proponent?.authFullName),
      auth_full_name_witness: formatValueDiligence(proponent?.authFullNameWitness),
      auth_instagram: formatValueDiligence(proponent?.authInstagram),
      auth_last_election: formatValueDiligence(proponent?.authLastElection),
      auth_legal_person: formatValueDiligence(proponent?.authLegalPerson),
      auth_linkedln: formatValueDiligence(proponent?.authLinkedln),
      auth_name_responsible: formatValueDiligence(proponent?.authNameResponsible),
      auth_name_register_responsible: formatValueDiligence(proponent?.authNameRegisterResponsible),
      auth_email_register_responsible: formatValueDiligence(proponent?.authEmailRegisterResponsible),
      auth_phone_register_responsible: formatValueDiligence(proponent?.authPhoneRegisterResponsible),
      auth_number: formatValueDiligence(proponent?.authNumber),
      auth_password: formatValueDiligence(proponent?.authPassword),
      auth_phone_responsible: formatValueDiligence(proponent?.authPhoneResponsible),
      auth_photo_profile: formatValueDiligence(proponent?.authPhotoProfile),
      auth_photos_carousel: formatValueDiligence(proponent?.authPhotosCarousel),
      auth_platform: formatValueDiligence(proponent?.authPlatform),
      auth_public_place: formatValueDiligence(proponent?.authPublicPlace),
      auth_site: formatValueDiligence(proponent?.authSite),
      auth_social_contract: formatValueDiligence(proponent?.authSocialContract),
      auth_social_status: formatValueDiligence(proponent?.authSocialStatus),
      auth_state: formatValueDiligence(proponent?.authState),
      auth_witness_email: formatValueDiligence(proponent?.authWitnessEmail),
      auth_years_experience: formatValueDiligence(proponent?.authYearsExperience),
      auth_youtube: formatValueDiligence(proponent?.authYoutube),
      auth_company_names: formatValueDiligence(proponent?.authCompanyNames),
      auth_highlights_and_awards: formatValueDiligence(proponent?.authHighlightsAndAwards),
      auth_tax_regime: formatValueDiligence(proponent?.authTaxRegime),
    }
  });

  const formStepVariant = useMemo(() => {
    return {
      1: (
        <RegistrationDataAdmin
          loading={loading}
          legalPerson={proponent?.legalPerson}
          isDisabled={isDisabled || isSupport || isGeneral || isMonitore || isCsRelationship || (['cs_proponent2'].includes(user.adminRole) && proponent.status === 'Em andamento')}
          isUploading={loadingAction}
        />
      ),
      2: <WitnessAndLocationAdmin legalPerson={proponent?.legalPerson} isDisabled={isDisabled || isSupport || isGeneral || isMonitore || isCsRelationship || (['cs_proponent2'].includes(user.adminRole) && proponent.status === 'Em andamento')} />,
      3: <ImagesAndVideosAdmin 
        isDisabled={isDisabled || isSupport || isGeneral || isMonitore || isCsRelationship || (['cs_proponent2'].includes(user.adminRole) && proponent.status === 'Em andamento')}
        isUploading={loadingAction}
      />,
      4: <Cnds cnds={cnds} hasChangeData = {proponent.hasChangeCnd} handleUpdateCdns={ (e)=>setCnds(e) } isBlankData={isValidData} isDisabled={onlySeeCND} uploadCndProponent={(file, cnd)=>uploadCnd(file, cnd)} proponentId={id}/>,
      5: <AnalyzeAdmin proponentId={id} isDisabled={isDisabled 
        || user.adminRole === 'support' 
        || ['general', 'cs_proponent2', 'cs_proponent_relationship', 'monitore'].includes(user.adminRole)
        || proponent?.registerStatus !== 'Em andamento' && (['cs_proponent1'].includes(user.adminRole))
      }/>
    };
  }, [loading, proponent, id, loadingAction, cnds, isValidData]);

  const renderForm = useCallback(() => {
    return formStepVariant[currentStep];
  }, [formStepVariant, currentStep, loadingAction]);

  const getPayloadCompleted = useCallback((data, isLastStep = false) => {
    const form = new FormData();
    form.append('key_account_anterior', keyAccountAnterior);

    // if (data?.password && (!data?.new_password || !data?.confirm_new_password)) {
    //   toast.error('4. Preencha os campos de nova senha e confirmar nova senha');
    //   return ;
    // }
    // if (data?.new_password !== data?.confirm_new_password) {
    //   toast.error('4. Nova senha e Confirmar nova senha devem ser iguais');
    //   return ;
    // }

    if (data?.legalPerson === 'Pessoa física') {
      form.append('legal_person', data?.legalPerson);
      form.append('name', data?.name);
      form.append('years_experience', data?.years_experience);
      form.append('incentive_laws', data?.incentive_laws);
      form.append('about', data?.about);
      form.append('value_captured_incentive_laws', data?.value_captured_incentive_laws);
      form.append('full_name', data?.full_name);
      form.append('cpf', data?.cpf);
      form.append('email', data?.email);
      form.append('phone', data?.phone);

      if(data?.cpf){
        const cpf = removeMask(data?.cpf);
        const validatedCPF = validateCpf(cpf);

        if(validatedCPF === false){
          toast.error('1. CPF é inválido');
          return ;
        }
      }
    } else {
      if(data?.cpf_responsible){
        const cpf_responsible = removeMask(data?.cpf_responsible);
        const validatedCPF = validateCpf(cpf_responsible);

        if(validatedCPF === false){
          toast.error('1. CPF do responsável legal é inválido');
          return ;
        }
      }
  
      if(data?.cnpj){
        const cnpj = removeMask(data?.cnpj);
        const validatedCNPJ = validateCnpj(data?.cnpj);

        if(validatedCNPJ === false){
          toast.error('1. CNPJ é inválido')
          return ;
        }
      }

      form.append('legal_person', data?.legalPerson);
      form.append('name', data?.name);
      form.append('years_experience', data?.years_experience);
      form.append('incentive_laws', data?.incentive_laws);
      form.append('about', data?.about);
      form.append('cnpj', data?.cnpj);
      form.append('value_captured_incentive_laws', data?.value_captured_incentive_laws);
      form.append('corporate_name', data?.corporate_name);
      form.append('name_responsible', data?.name_responsible);
      form.append('phone_responsible', data?.phone_responsible);
      form.append('email_responsible', data?.email_responsible);
      form.append('cpf_responsible', data?.cpf_responsible);

      if (data?.social_contract && typeof data?.social_contract === 'object') {
        form.append(
          'social_contract',
          data?.social_contract,
          data?.social_contract?.name,
        );
      }
      if (data?.last_election && typeof data?.last_election === 'object') {
        form.append(
          'last_election',
          data?.last_election,
          data?.last_election?.name,
        );
      }
      if (data?.social_status && typeof data?.social_status === 'object') {
        form.append(
          'social_status',
          data?.social_status,
          data?.social_status?.name,
        );
      }
    }
    form.append('company_names', data?.company_names);
    form.append('tax_regime', data?.tax_regime);
    form.append('company_size', data?.company_size);
    form.append('highlights_and_awards', data?.highlights_and_awards);
    form.append('name_responsible', data?.name_responsible);
    form.append('email_responsible', data?.email_responsible);
    form.append('phone_responsible', data?.phone_responsible);
    form.append('full_name_witness', data?.full_name_witness);
    form.append('witness_email', data?.witness_email);
    form.append('cep', data?.cep);
    form.append('city', data?.city);
    form.append('number', data?.number);
    form.append('public_place', data?.public_place);
    form.append('complement', data?.complement);
    form.append('country', data?.country);
    form.append('district', data?.district);
    form.append('state', data?.state);

    if (data?.photo_profile && typeof data?.photo_profile === 'object') {
      form.append(
        'photo_profile',
        data?.photo_profile,
        data?.photo_profile?.name,
      );
    }

    if (data?.imagen1 && typeof data?.imagen1 === 'object') {
      form.append('imagen1', data?.imagen1, data?.imagen1?.name);
    }

    if (data?.imagen2 && typeof data?.imagen2 === 'object') {
      form.append('imagen2', data?.imagen2, data?.imagen2?.name);
    }

    if (data?.imagen3 && typeof data?.imagen3 === 'object') {
      form.append('imagen3', data?.imagen3, data?.imagen3?.name);
    }

    if (data?.imagen4 && typeof data?.imagen4 === 'object') {
      form.append('imagen4', data?.imagen4, data?.imagen4?.name);
    }

    form.append('video', data?.video);
    form.append('key_account_user_id', data?.key_account_user_id);
    form.append('site', data?.site);
    form.append('facebook', data?.facebook);
    form.append('youtube', data?.youtube);
    form.append('linkedln', data?.linkedln);
    form.append('instagram', data?.instagram);
    form.append('status', data?.status);
    form.append('analyze_subject', data?.analyze_subject);
    form.append('analyze_message', data?.analyze_message);
    form.append('register_status', data?.register_status);
    
    if(data?.analysis_compliance_status || data?.analysis_compliance_status == 0){
      form.append('analysis_compliance_status', data?.analysis_compliance_status);
    }

    
    form.append('name_register_responsible', data?.name_register_responsible);
    form.append('email_register_responsible', data?.email_register_responsible);
    form.append('phone_register_responsible', data?.phone_register_responsible);
    if(isLastStep) {
      form.append('is_last_step', isLastStep);
    }
    const keys = Object.keys(data);
    const authKeys = keys.filter(key => key.includes('auth_'));

    authKeys.forEach(item => {
      form.append(item, data[item]);
    });
    return form;
  }, []);

  const updateProponent = useCallback(async payload => {
    try {
      setLoadingAction(true);
      const response = await ProponentService.update(
        id,
        payload,
      );
      if (response.status === 203) {
        Object.keys(response.data).forEach(item => {
          toast.error(response.data[item][0]);
        });
      } else {
        toast.success('Dados salvos com sucesso!');
      }
    } catch (err) {
      toast.error('Falha ao salvar dados!');
    } finally {
      setLoadingAction(false);
    }
  }, [id]);

  const onSubmit = useCallback(
    async data => {
      setLoadingAction(true);
      try {
        const payload = getPayloadCompleted(data);
        if(payload){
          if (currentStep === 4) {
            const payloadCompleted = getPayloadCompleted(data, true);
            await updateProponent(payloadCompleted);
          } else {
            await updateProponent(payload);
          }
        }
        
        const { password, new_password, confirm_new_password } = data;
        if (password && new_password && confirm_new_password) {
          const response = await LoginService.changePassword({ password, new_password, confirm_new_password, id });
          if(response.data.erro){
            toast.error(response.data.erro);
          } else {
            toast.success('Senha alterada com sucesso!');
          }
        }
      } catch (err) {
        console.log(err);
        toast.error('Falha ao salvar dados!');
      } finally {
        setLoadingAction(false);
      }
    },
    [updateProponent, getPayloadCompleted, currentStep],
  );

  return (
    <FormProvider {...formHook}>
      <Form onSubmit={formHook.handleSubmit(onSubmit)}>
        {renderForm()}
        <Footer>
        <Actions>
          <Button variant="secondary" onClick={() => push('/admin/proponentes')}>
            CANCELAR
          </Button>
          {currentStep > 1 ? (
            <Button
              type="button"
              variant="secondary"
              onClick={() => handlePrevious()}
            >
              VOLTAR
            </Button>
          ) : (
            <div />
          )}
        </Actions>
          <Actions>
            {/* <Button 
              variant="secondary"
              type="submit"
              disabled={loadingAction}
            >
              SALVAR
            </Button>
            <Button
              style={{ display: (currentStep === 4 || (currentStep === 3 && isGeneral)) ? 'flex' : 'none' }}
              type="submit"
              disabled={loadingAction || isMonitore}
            >
              CONCLUIR CADASTRO
            </Button> */}
            <Button
              style={{ display: currentStep !== 5 ? 'flex' : 'none' }}
              type="button"
              onClick={() => handleNext()}
            >
              PRÓXIMO
            </Button>
          </Actions>
        </Footer>
      </Form>
    </FormProvider>
  );
};

export default ProponentFormAdmin;