import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 20px 0 24px;

  .body-title{
    font-size: 12px;
    margin-bottom: 6px;
  }

  input, p:not(.subtitle) {
    p:not([color="blue"]){
      color: ${({ theme }) => theme.colors.brown};
    }
    font-size: 12px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  // margin-bottom: 10px;
`;

export const Content = styled.div``;

export const CheckContent = styled.div`
  margin-top: 15px;

  display: flex;
  align-items: flex-start;
`;

export const Terms = styled.div`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.grayDark2};

  p {
    pointer-events: ${({ disabled }) => disabled ? `none` : 'initial'}; 
  }
`;

export const GridLinks = styled.div`
  margin-top: 9px;

  display: flex;
`;

export const ShowFiles = styled.div`
  .multiple-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: flex-start;
  }

  margin-top: 5px;
`;

export const ImageAndVideos = styled.div`
  display: grid;
  width: 100%;
  margin-top: 22px;
  gap: 22px;
`;

export const ImageAndVideosBox = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 1200px){
    display: grid;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;