import React from 'react';

import { v4 } from 'uuid';

import PageTitle from 'components/PageTitle';
import BiddersTable from 'components/BidderSTable';
import { Container, Header, BidderContainer, SelectStyled } from './styles';

const MainPageAdm = () => {
  const defaultBidders = {
    id: v4(),
    urlImage: '',
    nameBidder: 'Vinicius dos Santos',
    local: 'Timbaúba-PE',
    projects: '01',
    email: 'email@email.com',
    status: 'Ativo',
    cnpj: '',
    phone: '99999-9999',
    address: 'Rua de São Pedro',
    projectsCaptation: '02',
  };

  const bidders = new Array(2).fill(defaultBidders);

  return (
    <Container>
      <Header>
        <PageTitle
          title="Proponentes"
          subtitle="Lista de proponentes cadastrados."
        />
      </Header>
      <BidderContainer>
        <div className="selectContainer">
          <SelectStyled
            name="name"
            className="SelectStyled"
            label="Nome"
            options={['Opção nome Example']}
          />
          <SelectStyled
            name="state"
            className="SelectStyled"
            label="Estado"
            options={['Opção nome Example']}
          />
          <SelectStyled
            name="city"
            className="SelectStyled"
            label="Cidade"
            options={['Opção nome Example']}
          />
          <SelectStyled
            name="law"
            className="SelectStyled"
            label="Leis"
            options={['Opção nome Example']}
          />
          <SelectStyled
            name="projectQuantity"
            className="SelectStyled"
            label="Qtd. de projeto"
            options={['Opção nome Example']}
          />
          <SelectStyled
            name="lawQuantity"
            className="select"
            label="Qtd. de leis"
            options={['Opção nome Example']}
          />
        </div>
        <BiddersTable data={bidders} />
      </BidderContainer>
    </Container>
  );
};

export default MainPageAdm;
