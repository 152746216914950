import React, { Fragment } from 'react';
import {
  Container,
  TableContainer,
  TdCollapseDivider,
  LoaderContainer,
} from './styles';
import Pagination from 'components/Pagination';
import TableCell from './TableCell';
import { v4 } from 'uuid';
import { CircularProgress } from '@material-ui/core';
import EmptyTable from './EmptyTable';
import { useUser } from 'hooks/useUser';

function Table({
  identification,
  columns = [],
  data = [],
  tableCollapsed = false,
  collapsedComponent: CollapsedComponent,
  filterComponent: FilterComponent,
  isPaginated = false,
  totalPages = 1,
  currentPage = 1,
  onChangePage,
  onRefresh,
  loading = false,
  collapsedComponentProps,
  onCollapsed,
  className
}) {
  const { user } = useUser();
  return (
    <Container>
      {FilterComponent && <FilterComponent />}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <TableContainer className={className} isCollapsed={false}>
          {data.length > 0 ? (
            <tbody>
              {data.map(item => (
                <Fragment key={v4()} className={'teste'}>
                  <TableCell
                    columns={columns}
                    item={item}
                    onCollapsed={onCollapsed ? value => onCollapsed(value) : null}
                    isCollapsed={tableCollapsed}
                    showCollapsed={!user.isAdmin && item.sponsor_id == null}
                    // isCollapsed={tableCollapsed}
                    collapsedComponent={CollapsedComponent}
                    onRefresh={onRefresh}
                    isAdmin={user.isAdmin}
                    collapsedComponentProps={collapsedComponentProps}
                  />
                  <tr>
                    <TdCollapseDivider colSpan={columns.length + 1} />
                  </tr>
                </Fragment>
              ))}
            </tbody>
          ) : (
            <EmptyTable />
          )}
        </TableContainer>
      )}
      {isPaginated && data.length > 0 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onChange={page => onChangePage(page)}
        />
      )}
    </Container>
  );
}

export default Table;
