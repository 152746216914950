import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PageTitle from 'components/PageTitle';
import CaptureReportTable from 'components/CaptureReportTable';
import ModalInformation from 'components/ModalInformation';
import Select from 'components/Select';
import Input from 'components/Input';
import { v4 } from 'uuid';
import { Container, ProjectsContainer, ButtonSearch, Content, Actions, Text } from './styles';
import { IconSearch } from 'assets/icons';
import DatePicker from 'components/DatePicker';
import { useParams } from 'react-router-dom';
import { ProjectService } from 'services/projects';

import { AddCaptureModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import updateCaptureContributionMap from 'utils/updateCaptureContributionMap';


const CaptureReport = () => {
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [modalOpen, setModalOpen] = useState('');
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });

  const loadReports = useCallback(async () => {
    const response = await ProjectService.loadCaptureReports(id);
    setReports(response.data);
  }, [id]);

  useEffect(() => {
    if (id) {
      loadReports();
    }
  }, [id, loadReports]);

  
  const updateCapturedValueProject = useCallback(async (payload) => {
    const contributions = payload.contribuitions.filter((c)=>c.value !== "");
    let data = {
      ...payload, contribuitions: contributions.length > 0 ? contributions : null
    };
    try {
      const response = await ProjectService.updateCapturedValueProject(id, data);
      const projectContribId = response.data?.capture_id;
      await updateCaptureContributionMap(contributions, projectContribId);
      
      if (response.status === 203) {
        Object.keys(response.data).map(item => (
          toast.error(response.data[item][0])
        ));
      } else {
        setShowModalInformation(false);
        setModalOpen('');
        toast.success('Captação atualizada com sucesso!');
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao atualizar a captação do projeto');
      }
    }
    
    loadReports();

  }, [modalInformationData]);


  const modalRender = useMemo(() => {
    const modal = {
      addCapture: (
        <AddCaptureModal
          open={modalOpen === 'addCapture'}
          handleClose={() => setModalOpen('')}
          onChange={(captured_value, sponsor_id, date, contribuitions, incentiveType, isSendEmail) => {
            updateCapturedValueProject({ captured_value, sponsor_id, date, contribuitions, incentiveType, isSendEmail })
          }}
        />
      )
    };
    return modal[modalOpen];
  }, [modalOpen]);

  return (
    <Container>
      <main>
        <PageTitle
          title="Relatório de captação"
          subtitle="Confira os detalhes sobre a captação de recursos."
          type="adm"
        />
        <ProjectsContainer>
          <div style={{ marginTop: 20 }}>
            <CaptureReportTable notifications={reports} onRefresh={() => loadReports()}/>
          </div>

          {/* <Button style={{width: '300px', alignSelf: 'end'}} onClick={() => setModalOpen('addCapture')}>
            Adicionar captação
          </Button> */}
        </ProjectsContainer>
      </main>

      {modalRender}

      <ModalInformation
        open={showModalInformation}
        handleClose={() => setShowModalInformation(false)}
        {...modalInformationData}
      />
    </Container>
  );
};

export default CaptureReport;
