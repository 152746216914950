import styled from 'styled-components';

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .main > div {
    margin: 20px 0;
    line-height: 2;
    color: #8d8d8d;
  }
  .main > div:not(div:first-child) {
    text-align: justify;
  }
  .center {
      text-align: center;
  }
  .main {
      margin: 0 auto;
      width: 930px;
      background-color: #ffffffde;
      padding: 20px;
      border-radius: 30px;
      font-family: Roboto,sans-serif;

      @media (max-width: 1000px){
        width: 95%;
      }
  }
  table {
      width: 90%;
      border-collapse: collapse;
      margin: 0 auto;
  }
  table, th, td {
      border: 1px solid grey;
  }
  td {
      width: 50%;
      padding: 10px;
  }
  tr> td:first-child{
      text-align: center;
  }
  .table-center {
      text-align: -webkit-center;
  }
  ul > li:not(.rs-nav-item) {
      margin: 10px 0;
  }
`;

export const Container = styled.div`
  border-radius: 6px;
  padding: 2rem 1.5rem;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: 1000px){
    padding: 0rem 1.5rem;
  }
`;

export const Form = styled.form`
  margin-top: 1.25rem;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Content = styled.div`
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Row = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1300px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;
