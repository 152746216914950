import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { pixelsToRem } from 'utils/styles';

export const Container = styled(OutlinedInput)`
  fieldset {
    border-radius: ${pixelsToRem(10)};
  }

  input {
    height: 1.5rem;
    padding: 0.75rem 1rem;
  }
`;