import styled from 'styled-components';
import { StepButton } from '@material-ui/core';

export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${({ active }) => active ? '500' : 'normal'};
  font-size: 14px;
  line-height: 16px;
  color: ${({ active }) => active ? '#000000' : '#000000'};
  text-align: left;
`;

export const Subtitle = styled.div`
  margin-top: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.54);
`;

export const StepButtonStyled = styled(StepButton)`
  svg {
    color: ${({ isCompleted, hasError, theme }) => hasError ? "red !important" : isCompleted ? theme.colors.primary : '#9E9E9E'};
  }
`;