import styled from 'styled-components';

export const Container = styled.div`
  height: 284px;
  border: 1px solid #dadada;
  border-radius: 4px;
`;

export const Content = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  padding: 24px;
`;

export const FilterContent = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  padding: 24px;
  gap: 1.5rem;
`;

export const ButtonContent = styled.div`
  display: flex;

  align-items: center;
  justify-content: end;

  padding-right: 24px;
  gap: 1.5rem;
`;

export const Options = styled.div`
  display: flex;

  margin-left: 16px;
  gap: 24px;
`;

export const ButtonFilter = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  border: 1px solid #c15028;
  border-radius: 4px;

  cursor: pointer;
`;

export const ButtonSearch = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.orange};

  border: 1px solid #c15028;
  border-radius: 4px;

  cursor: pointer;
`;

export const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.brown};
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-notchedOutline {
    border-radius: 30px!important;
  }

  .MuiOutlinedInput-root {
    height: auto;
    min-height: 55px;
    max-height: 94px;
    position: initial;
    overflow-y: auto;    
  }

  .MuiFormControl-root{
    padding-right: 4px;
  }

  .MuiAutocomplete-inputRoot{
    padding: 8px 14px!important;
  }

  .MuiAutocomplete-inputRoot::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  .MuiAutocomplete-inputRoot::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }
  
  .MuiAutocomplete-inputRoot::-webkit-scrollbar-thumb {
    background-color: transparent;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    height: 20px;
  }

  div[class^='MuiOutlinedInput-root-'], div[class*=' MuiOutlinedInput-root-'] {
    border-radius: 30px!important;
    height: auto;
    min-height: 55px;
    max-height: 94px;
  }

  .select-multiple{
    height: auto!important;
    min-height: 55px!important;
    max-height: 94px!important;
  }

  .MuiSelect-select:focus {
    padding: 8px 14px;
    border-radius: 32px;
    min-height: 40px!important;
    max-height: 94px!important;
    display: flex;
    align-items: center;
  }

  // remove o botão de clear padrão do input
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;