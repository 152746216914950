import React, { useEffect, useState } from 'react';
import { IconVisibilityOff, IconVisibilityOn } from 'assets/icons';
import Button from 'components/Button';
import Input from 'components/Input';
import { Container, Enter, Body } from './styles';
import { useForm, Controller } from 'react-hook-form';
import { loginValidationResolver } from 'validations/login';
import { useAuth } from 'hooks/useAuth';
import { toast } from 'react-toastify';

const LoginForm = ({ changeIcon, visibility, persist, children }) => {
  const { onSignIn } = useAuth();
  const [formValid, setFormValid] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ email, password }) => {
    await onSignIn(email, password, persist);
  };

  const onError = async error => {
    alert('E-mail inválido');
    window.location.reload();
  };

  useEffect(() => {
    watch(({ email, password }) => {
      setFormValid(!!email && !!password);
    });
  }, [watch]);

  return (
    <Container onSubmit={handleSubmit(onSubmit, onError)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            className="input-email-login"
            label="Email"
            placeholder="example@email.com"
            error={errors?.email}
            helperText={errors?.email?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => {
          return (
            <Input
              type={visibility ? 'text' : 'password'}
              className="input-password-login"
              label="Senha"
              icon={visibility ? IconVisibilityOn : IconVisibilityOff}
              changeIcon={changeIcon}
              error={errors?.password}
              helperText={errors?.password?.message}
              {...field}
            />
          );
        }}
      />
      <Body>{children}</Body>
      <Enter>
        <Button type="submit" disabled={!formValid}>
          <div>
            <p>Entrar</p>
          </div>
        </Button>
      </Enter>
    </Container>
  );
};

export default LoginForm;
