import React, { useMemo, useCallback, useState } from 'react';
import AvatarTable from 'components/AvatarTable';
import defaulProjectAvatar from 'assets/images/default-project.png';
import Table from 'components/Table';
import { formatDate } from 'utils/date';
import { SendEmail, IconEmail } from 'assets/icons';
import Typography from 'components/Typography';
import formatCurrency, { currencyMask, currencyMaskStore } from 'utils/currency';
import { Container } from './styles';
import CaptureReportDetails from './CaptureReportDetails';
import { useUser } from 'hooks/useUser';
import { Button } from '@material-ui/core';
import { ProjectService } from 'services/projects';
import { EmailOutlined } from '@material-ui/icons';
import { toast } from 'react-toastify';
import './style.css';

import ModalInformation from 'components/ModalInformation';
import { ConfirmSendEmailAportModal } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';
const CaptureReportTable = ({ notifications, identification, onRefresh }) => {
  const { user } = useUser();
  const formatDateColumn = useCallback((reportDate) => {
    var date = new Date(new Date(reportDate).setDate(new Date(reportDate).getDate()));

    return formatDate(date, 'dd/MM/yyyy');
  }, []);

  const showToProponent = user.isAdmin == false && notifications.sponsor_id == null;
  const dateEmail = notifications[0]?.contributions[0]?.date_email;
  const [modalOpen, setModalOpen] = useState('');
  const [idClicked, setIdClicked] = useState();
  const [isReesend, setIsReesend] = useState();
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [modalInformationData, setModalInformationData] = useState({
    title: 'Dados cadastrados com sucesso!',
    description: 'Suas alterações foram salvas.',
  });
  const columns = useMemo(() => {
    return [
      {
        identification: 'created_at',
        name: 'Data',
        showTitle: true,
        renderColumn: ({ created_at }) => {
          return (
            <Container>
              <Typography variant="body2" color="grayDark2">
                Data do registro
              </Typography>
              <Typography variant="manrope" color="brown">
                {created_at ? formatDateColumn(created_at) : 'Sem data'}
              </Typography>
            </Container>
          );
        },
      },
      {
        identification: 'date_email',
        name: 'Data de cobrança do recibo de renuncia',
        showTitle: true,
        renderColumn: ({ date_email }) => {
          return (
            <Container>
              <Typography variant="body2" color="grayDark2">
                Data de cobrança do recibo de renuncia
              </Typography>
              <Typography variant="manrope" color="brown">
                {date_email ? formatDateColumn(date_email) : 'Não enviado'}
              </Typography>
            </Container>
          );
        },
      },
      {
        identification: 'sponsor',
        name: 'Patrocinador',
        showTitle: true,
        renderColumn: ({ name, email, image }) => {
          return (
            <AvatarTable
              logo={image || defaulProjectAvatar}
              title={"Patrocinador"}
              subtitle={name}
              // complement={email}
            />
          );
        },
      },
      {
        identification: 'incentiveType',
        name: 'Tipo de incentivo',
        showTitle: true,
        renderColumn: ({ sponsor_id }) => {
          return (
            <Container>
              <Typography variant="body2" color="grayDark2">
                Tipo de incentivo
              </Typography>
              <Typography variant="manrope" color="brown">
                {sponsor_id ? 'Captado via Incentiv.me' : 'Outros'}
              </Typography>
            </Container>
          );
        },
      },
      {
        identification: 'value',
        name: 'Valor',
        showTitle: true,
        renderColumn: ({ value }) => {
          return (
            <Container>
              <Typography variant="body2" color="grayDark2">
                Valor
              </Typography>
              <Typography variant="manrope" color="brown">
                {formatCurrency(value, true, false, 2)}
              </Typography>
            </Container>
          );
        },
      },
      // {
      //   identification: 'id',
      //   name: 'Enviar Recibo de renuncia',
      //   showTitle: true,
      //   renderColumn: ({ id , date_email}) => {
      //     return (
      //       <Container>
      //         <Typography variant="body2" color="grayDark2">
      //           Enviar Recibo de renuncia
      //         </Typography>
      //         <Button 
      //         onClick={() => {
      //           setIdClicked(id)
      //           setModalOpen('confirmSendEmailAport')
      //           if(date_email){
      //             setIsReesend(true)
      //           }
      //         }}
      //         // onClick={async ()=>{ 
      //         //   setModalOpen('confirmSendEmailAport')
      //         //   // await sendEmail(id) 
      //         // }}
      //         >
      //           {date_email ? <SendEmail width={30} height={30} /> : <IconEmail width={30} height={30}/>}
      //         </Button>
      //       </Container>
      //     );
      //   },
      // },
    ];
  }, []);

  async function sendEmail(id) {
    try{
      const response = await ProjectService.validatedContributionEmail(id);
      toast.success('E-mail enviado com sucesso!');
      setModalOpen('');
      setIsReesend(false);
      onRefresh()
    }catch(err) {
      if (err.response) {
        console.log(err.response);
        toast.error(err.response.data.error);
        setModalOpen('');
      }else{
        toast.error("Erro na aplicação");
      }
    }
  }

  const modalRender = useMemo(() => {
    const modal = {
      confirmSendEmailAport: (
        <ConfirmSendEmailAportModal
          open={modalOpen === 'confirmSendEmailAport'}
          handleClose={() => {setModalOpen(''); setIsReesend(false)}}
          onClick={async () => {
            await sendEmail(idClicked) 
          }}
          isReesend={isReesend}
        />
      )
    };
    return modal[modalOpen];
  }, [modalOpen,idClicked]);


  return (
    <>
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={notifications}
      tableCollapsed={['cs_proponent2', 'support', 'master', 'cs_proponent_relationship'].includes(user.adminRole) || showToProponent}
      // collapsedComponent={CaptureReportDetails}
      // collapsedComponentProps={{ notifications }}
      isPaginated={false}
      className={'tableCapture'}
      onRefresh={onRefresh}
    />
    {modalRender}

      <ModalInformation
        open={showModalInformation}
        handleClose={() => setShowModalInformation(false)}
        {...modalInformationData}
      />
    </>
  );
};

export default CaptureReportTable;
