import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  main {
    height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ProjectsContainer = styled.div`
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100% - 229px);
  height: auto;
  border-radius: 4px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .button-validate-cnds {
    width: fit-content;
    align-self: end;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  margin-bottom: 25px;
  align-items: center;

  .MuiFormControl-root{
    padding-right: 4px;
  }

  .MuiAutocomplete-inputRoot{
    border-radius: 10px!important;
    padding: 8px 14px!important;
    height: auto;
    min-height: 48px;
    max-height: 94px;
    min-width: 275px;
    position: initial;
    overflow-y: auto; 
  }

  // .MuiAutocomplete-inputRoot::-webkit-scrollbar {
  //   width: 6px;               /* width of the entire scrollbar */
  // }
  
  .MuiAutocomplete-inputRoot::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }
  
  .MuiAutocomplete-inputRoot::-webkit-scrollbar-thumb {
    background-color: transparent;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    height: 20px;
  }

  // div[class^='MuiOutlinedInput-root-'], div[class*=' MuiOutlinedInput-root-'] {
  //   border-radius: 30px!important;
  //   height: auto;
  //   min-height: 55px;
  //   max-height: 94px;
  // }

  // remove o botão de clear padrão do input
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const ButtonSearch = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.orange};

  border: 1px solid #c15028;
  border-radius: 4px;

  cursor: pointer;
`;

export const Options = styled.div`
  display: flex;

  margin-left: 16px;
  gap: 24px;
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  height: 55px!important;
  span {
    font-family: Roboto;
    color: ${({ theme }) => theme.colors.brown};
  }
`;

export const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.brown};
`;