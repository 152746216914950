import api from './api';

const PATH = '/projects';

const listProjects = () => api.get(`${PATH}`);
const listProjectsPagination = (params = {}, page) => api.get(`${PATH}-pagination`, { params: { ...params, page } });
const searchProjects = (params = {}, page) => api.get(`${PATH}-search`, { params: { ...params, page } });
const addProject = data => api.post(PATH, data);
const updateProject = (id, data) => api.post(`${PATH}/update/${id}`, data);
const updateStateProject = (id, data) => api.post(`${PATH}/update-status/${id}`, data);
const updateCapturedValueProject = (id, data) => api.post(`${PATH}/update-captured-value/${id}`, data);
const editCapturedValueProject = (id, data) => api.post(`${PATH}/edit-captured-value/${id}`, data);
const editContributionProject = (data) => api.post(`update-contribution`, data);
const validatedContributionEmail = (id) => api.post(`contribution-validated/${id}`);
const findProject = id => api.get(`${PATH}/${id}`);
const showProject = id => api.get(`${PATH}-show/${id}`);
const listProjectsSpecific = () => api.get('/list-projects');
const listGoalsByProject = id => api.get(`/list-goals-by-project/${id}`);
const listLocationsByProject = id =>
  api.get(`/list-locations-by-project/${id}`);
const listProjectsSummary = () => api.get(`${PATH}-resume`);
const deleteProject = id => api.delete(`${PATH}/delete/${id}`);
const deleteCapture = id => api.delete(`${PATH}/delete-capture/${id}`);
const getFormInfo = () => api.get(`${PATH}/get-form-info`);
const getCity = uf => api.get(`${PATH}/get-form-city/${uf}`);
const getProjectsSpecificInformation = () => api.get(`/projects-show/${4}`);
const getProjectsByFilters = () => api.get('/index-project-by-filters');
const loadAllProjectsByProponent = proponentId => api.get(`${PATH}/admin/${proponentId}`);
const updateProjectProponent = (id, data) => api.post(`${PATH}/update-project-proponent/${id}`, data);
const releaseProject = id => api.post(`${PATH}/release-project/${id}`);
const exportToCsv = (params = {}) => api.get(`${PATH}-export`, { params: { ...params } });
const loadCaptureReports = id => api.get(`${PATH}/capture-reports/${id}`);
const subscriptionRoutine = id => api.get(`${PATH}/subscription-routine/${id}`);
const contentUploadedDocument = id => api.get(`${PATH}/content-uploaded-document/${id}`);
const getContractFile = id => api.get(`${PATH}/get-contract-file/${id}`, { responseType: 'blob' });
const endCapture = (id, data) => api.post(`${PATH}/end-capture/${id}`, data);
const sendProjectToOnlyMonitore = id => api.post(`/only-monitore/${id}`);
const listBanks = () => api.get(`/banks`);
const attDeadlineProject = (id, data) => api.post(`${PATH}/update-deadline/${id}`, data);

export const ProjectService = {
  listProjects,
  searchProjects,
  addProject,
  updateProject,
  updateStateProject,
  updateCapturedValueProject,
  editCapturedValueProject,
  validatedContributionEmail,
  findProject,
  showProject,
  listProjectsSpecific,
  listGoalsByProject,
  listLocationsByProject,
  listProjectsSummary,
  deleteProject,
  getFormInfo,
  getCity,
  getProjectsSpecificInformation,
  getProjectsByFilters,
  loadAllProjectsByProponent,
  updateProjectProponent,
  listProjectsPagination,
  releaseProject,
  exportToCsv,
  loadCaptureReports,
  deleteCapture,
  subscriptionRoutine,
  contentUploadedDocument,
  getContractFile,
  endCapture,
  sendProjectToOnlyMonitore,
  listBanks,
  attDeadlineProject,
  editContributionProject
};
