import React, { useState } from 'react';
import ProjectForm from './ProjectForm';
import SideBar from './ProjectForm/SideBar';

import { Container } from './styles';

const AdminProjectForm = ({ project, loadProjectInfo, handleChangeOnlyMonitore, updateProject }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleStep = step => () => {
    setCurrentStep(step);
  };

  return (
    <Container>
      <SideBar activeStep={currentStep} handleStep={handleStep} proponent_id={project.proponent_id} project_status={project.status}/>
      <ProjectForm
        activeStep={currentStep} 
        project={project}
        saveProject={(e)=> updateProject(e)}
        handleNext={() => setCurrentStep(currentStep + 1)}
        handlePrevious={() => setCurrentStep(currentStep - 1)}
        loadProjectInfo={loadProjectInfo}
        handleChangeOnlyMonitore={()=>handleChangeOnlyMonitore()}
      />
    </Container>
  );
};

export default AdminProjectForm;