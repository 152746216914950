/* eslint-disable react/require-default-props */
import React from 'react';

import { IconCheck, IconChecked } from 'assets/icons';

import { Container, Rounded, Square } from './styles';

const Checkbox = ({
  checked,
  rounded = undefined,
  color,
  width,
  height,
  onClick,
  type,
  border,
}) => {
  const renderIcons = () => {
    if (type === 'correct' && checked) {
      return <IconChecked />;
    }
    return;
  };

  const Render = () =>
    checked ? (
      type === 'correct' ? (
        <IconChecked onClick={onClick} />
      ) : (
        <Container onClick={onClick} checked={checked}>
          {checked && <Square color={color} />}
        </Container>
      )
    ) : (
      <Container
        width={width}
        height={height}
        onClick={onClick}
        checked={checked}
      />
    );

  return rounded === undefined ? (
    Render()
  ) : (
    <Container border={border} rounded={rounded} onClick={onClick}>
      {checked && <Rounded color={color} />}
    </Container>
  );
};

export default Checkbox;
