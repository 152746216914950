import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import Typography from 'components/Typography';
import Select from 'components/Select';
import Input from 'components/Input';
import { 
  Container,
  Content,
  Row,
  Body,
  DocumentItem,
  DivAdd,
  DivMargin,
  ContentChip,
  BodyCard,
  Link
} from './styles';
import Cards from '../../../CounterPartCards/cards';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';

const Rules = ({ isValid }) => {
  const { control, setValue, getValues } = useFormContext();
  const [ruleDataChange, setRuleDataChange] = useState(getValues('rule_data_change'));
  const [ruleIncentivsResponsibilities, setRuleIncentivsResponsibilities] = useState(getValues('rule_incentivs_responsibilities'));
  const [ruleProponentsResponsabilities, setRuleProponentsResponsabilities] = useState(getValues('rule_proponents_responsabilities'));
  const [ruleProjectWithdrawal, setRuleProjectWithdrawal] = useState(getValues('rule_project_withdrawal'));
  const isDisabled = getValues('register_status') === 'Em análise' || getValues('register_status') === 'Concluído';


  useEffect(()=> {
    if(ruleDataChange &&
    ruleIncentivsResponsibilities &&
    ruleProponentsResponsabilities &&
    ruleProjectWithdrawal){
      isValid(true);
    } else {
      isValid(false);
    }
  },[ruleDataChange, ruleIncentivsResponsibilities, ruleProponentsResponsabilities, ruleProjectWithdrawal])

  return (
    <Container>
      <Typography variant="h6" color="grayDark2">
        Regras
      </Typography>
      <div style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" color="grayDark1">
          SEU CADASTRO AINDA NÃO FOI CONCLUÍDO!
        </Typography>
      </div>
      <div style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" color="grayLight1">
          Antes de iniciar o cadastro do seu projeto, você precisa validar que está de acordo com nossas regras e Termos de Uso. Você precisa clicar na caixa de seleção para sinalizar que está de acordo com os itens apontados.
          Com todos os itens checados, clique em "Concluir cadastro!" no final da página.
        </Typography>
      </div>
      <div style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" color="grayLight1">
          Relembre nossas regras.
        </Typography>

        <Typography variant="subtitle1" color="grayLight1">
          Antes de publicar, clique nos círculos abaixo para relembrar os <Link href={'termos-e-condicoes-de-uso'} rel="noreferrer" target="_blank">Termos de Uso</Link> e confirmar que você sabe como funciona a Incentiv. Qualquer dúvida, entre em contato!
        </Typography>
      </div>

      <table style={{width: '100%', padding: '0 10px 10px 10px'}}>
        <tbody>
        <tr>
        <Content>
        <Typography variant="body1">
          <Controller
            control={control}
            name="rule_data_change"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={() => {
                  onChange()
                  setValue('rule_data_change', !ruleDataChange);
                  setRuleDataChange(!ruleDataChange)
                }}
                checked={value}
                inputRef={ref}
                defaultChecked={ruleDataChange ? true : false}
                disabled={isDisabled}
              />
            )}
          />
            <span style={{ fontWeight: '500', marginBottom: 2, color: '#757575', padding: '0 12px'}}>1/4  Alteração dos dados</span>
        </Typography>
        <Typography variant="body1" className='card-content'>
          <Cards value={'Após a conclusão do cadastro do proponente ou do projeto, os seguintes dados apenas poderão ser alterados por meio de solicitação à Incentiv: identidade do responsável pelo projeto (Nome / CPF ou Razão Social / CNPJ), o título do projeto, período de captação e valor aprovado para captação. Para isso, será necessário enviar o pedido com os devidos comprovantes para o email: contato@incentiv.me.'} key={0} className="teste"/>
        </Typography>
      </Content>

      <Content>
        <Typography variant="body1">
          <Controller
            control={control}
            name="rule_incentivs_responsibilities"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={() => {
                  onChange()
                  setValue('rule_incentivs_responsibilities', !ruleIncentivsResponsibilities);
                  setRuleIncentivsResponsibilities(!ruleIncentivsResponsibilities)
                }}
                checked={value}
                inputRef={ref}
                disabled={!ruleDataChange || isDisabled}
              />
            )}
          />
            <span style={{ fontWeight: '500', marginBottom: 2, color: '#757575', padding: '0 12px'}}>2/4 Responsabilidades da Incentiv</span>
        </Typography>
        <Typography variant="body1" className='card-content'>
          <Cards value={'A Incentiv é responsável por manter o incentiv.me seguro e suas operações de gerenciamento e dados registrados, respeitando a usabilidade, segurança e navegabilidade, e garantindo um ambiente confiável para processar suas operações. A Incentiv não é responsável pelo financiamento, divulgação e execução e prazos de entrega do projeto, disponibilidade de incentivos e/ou contrapartidas, controle do orçamento, fretes, valores anunciados, assim como não é responsável pela entrega de recompensas dos projetos em captação na plataforma ou outras condições prometidas pelos proponentes. Da mesma forma, a Incentiv não pode ser responsabilizada pelos custos ou riscos do negócio relacionados aos projetos.'} key={1} className="teste"/>
        </Typography>
      </Content>

      <Content>
        <Typography variant="body1">
          <Controller
            control={control}
            name="rule_proponents_responsabilities"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={() => {
                  onChange()
                  setValue('rule_proponents_responsabilities', !ruleProponentsResponsabilities);
                  setRuleProponentsResponsabilities(!ruleProponentsResponsabilities)
                }}
                checked={value}
                inputRef={ref}
                disabled={!ruleIncentivsResponsibilities || isDisabled}
              />
            )}
          />
            <span style={{ fontWeight: '500', marginBottom: 2, color: '#757575', padding: '0 12px'}}>3/4 Responsabilidades do proponente</span>
        </Typography>
        <Typography variant="body1" className='card-content'>
          <Cards value={'Os proponentes com projetos cadastrados na Incentiv devem assegurar que o objeto sendo anunciado seja necessariamente lícito e de acordo com a legislação brasileira; devem realizar a conferência dos dados, conforme constam no projeto aprovado, e estipular contrapartidas lícitas aos Patrocinador/Incentivadores que financiarem o projeto, determinando as condições de sua obtenção e o número de Patrocinador/Incentivador que podem se beneficiar destas contrapartidas. O proponente deve fornecer à Incentiv os dados bancários para a transferência dos recursos captados, além de se comprometer a emitir o recibo de mecenato quando solicitado pela Incentiv. É dever do proponente informar à Incentiv os valores captados por si próprio ou por terceiros, de forma a sempre manter o valor de captação atualizado na plataforma. '} key={2} className="teste"/>
        </Typography>
      </Content>

      <Content>
        <Typography variant="body1">
          <Controller
            control={control}
            name="rule_project_withdrawal"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={() => {
                  onChange()
                  setValue('rule_project_withdrawal', !ruleProjectWithdrawal);
                  setRuleProjectWithdrawal(!ruleProjectWithdrawal)
                }}
                checked={value}
                inputRef={ref}
                disabled={!ruleProponentsResponsabilities || isDisabled}
              />
            )}
          />
            <span style={{ fontWeight: '500', marginBottom: 2, color: '#757575', padding: '0 12px'}}>4/4 Retirada de projeto do ar</span>
        </Typography>
        <Typography variant="body1" className='card-content'>
          <Cards value={'A Incentiv reserva-se o direito de, a seu exclusivo critério, a qualquer momento e independentemente de qualquer comunicação aos usuários ou proponentes, retirar do ar qualquer proponente e projeto que viole nossos Termos de Uso.'} key={4}/>
        </Typography>
      </Content>
        </tr>
        </tbody>
      </table>

      

      <Content>
        <Typography variant="subtitle1" color="grayLight1">
          Ao enviar seu projeto para análise, você está aceitando os <Link href={'termos-e-condicoes-de-uso'} rel="noreferrer" target="_blank">Termos de Uso</Link> e <Link href={'politica-de-privacidade'} rel="noreferrer" target="_blank">Política de Privacidade</Link>
        </Typography>
      </Content>
    </Container>
  );
};

export default Rules;
