import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const ConfirmSendEmailAport = ({ open, handleClose, onClick, isReesend }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Cobrança do recibo de renúncia
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {isReesend ? 'Deseja realmente reenviar o e-mail de cobrança do recibo de renúncia ao proponente?' : 'Deseja realmente enviar o e-mail de cobrança do recibo de renúncia ao proponente?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Não
        </Button>
        <Button onClick={onClick} color="primary">
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmSendEmailAport;