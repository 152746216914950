import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import Table from 'components/Table';
import Typography from 'components/Typography';
import deffaulProjectAvatar from 'assets/images/default-project.png';
import formatCurrency from 'utils/currency';
import VitrineTableDetail from './VitrineTableDetail';

import { ProjectItem, ProgressBar, FundraisingItem } from './styles';

const ProjectsTable = ({ data, identification }) => {
  const { push } = useHistory();

  const calculateProgress = useCallback((maxValue, currentValue) => {
    const progress = Number((Number(currentValue) * 100) / maxValue).toFixed(2);
    return Number(progress);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        identification: 'title',
        name: '',
        renderColumn: ({ project }) => {
          return (
            <ProjectItem>
              <img src={project.logo || deffaulProjectAvatar} alt="Projeto" />
              <div>
                <Typography variant="manrope" color="grayDark2">
                  {project.title}
                </Typography>
                <Typography variant="manrope" color="brown">
                  {project.description}
                </Typography>
                <Typography variant="caption">{project.category}</Typography>
              </div>
            </ProjectItem>
          );
        },
      },
      {
        identification: 'id',
        name: '',
        renderColumn: ({ fundraising }) => {
          const { date, currentValue, maxValue } = fundraising;
          return (
            <FundraisingItem>
              <div>
                <Typography variant="manrope" color="grayDark2">
                  Captação
                </Typography>
                <Typography variant="caption">{date}</Typography>
              </div>
              <ProgressBar
                progress={calculateProgress(maxValue, currentValue)}
              />
              <div>
                <Typography variant="caption">
                  {calculateProgress(maxValue, currentValue)}%
                </Typography>
                <Typography variant="caption">
                  {formatCurrency(currentValue)} de {formatCurrency(maxValue)}
                </Typography>
              </div>
            </FundraisingItem>
          );
        },
      },
      {
        identification: 'title',
        name: '',
        renderColumn: ({ bidder }) => {
          return (
            <ProjectItem>
              <img src={bidder.logo || deffaulProjectAvatar} alt="Projeto" />
              <div>
                <Typography variant="manrope" color="grayDark2">
                  {bidder.title}
                </Typography>
                <Typography variant="manrope" color="brown">
                  {bidder.name}
                </Typography>
                <Typography variant="caption">{bidder.email}</Typography>
              </div>
            </ProjectItem>
          );
        },
      },
      {
        identification: 'id',
        name: 'Área do projeto',
        showTitle: true,
        isDetail: true,
        action: ({ id }) => push(`/projetos/${id}`),
      },
    ];
  }, [calculateProgress, push]);

  return (
    <Table
      identification={identification || 'id'}
      columns={columns}
      data={data}
      isPaginated={false}
    />
  );
};

export default ProjectsTable;
