import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';


const FundingPeriodVanquished = ({ open, handleClose, onClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Prazo de captação vencido
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você possui projetos com prazo de captação vencidos
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ver mais tarde
        </Button>
        <Button onClick={onClick} color="primary">
          Ver projetos
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FundingPeriodVanquished;