import styled, { css } from 'styled-components';

  export const List = styled.ul`{
    margin: 0;

    .open-file {
      cursor: pointer;
    }
  }`;
  export const LabelInput = styled.p`{
    font-size: 14px!important;
      font-family: Roboto,sans-serif;
      color: ${({ theme }) => theme.colors.grayLight1};
      

      ${({ fileSelected, error, theme }) =>
        (fileSelected && !error) &&
          css`
            font-weight: bold;
          `};
  }`;
  
  
  export const Icon = styled.span`{
    font-size: 2rem;
    color: #777;
  }`;
  
  export const Box = styled.div`{
    text-align: center;
    padding: 20px;
    background-color: #fafafa;
    color: #bdbdbd;
    border: 2px dashed ${({ theme, error }) => error ? '#ef5350' : '#eeeeee'};

    .accept {
      border-color: #107c10 !important;
    }

    .reject {
      border-color: #d83b01 !important;
    }
  }`;

  export const ShowFiles = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;

  .multiple-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: flex-start;
  }
  
  margin-top: 5px;
`;