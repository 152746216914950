import ReactSelect from "react-select";

const Autocomplete = ({ placeholder, value, options, onChange, style, ...rest }) => (
    <ReactSelect
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        styles={{
            option: (styles, state) => ({
                ...styles,
                fontFamily: 'Cabin',
                backgroundColor:
                    state.isSelected ? "#C15028" :
                    state.isFocused ? "#EBEBEB" :
                    "#FFFFFF",
                ":hover": {
                    ...styles[":active"],
                    background:
                    state.isSelected ? "#C15028" : "#F5F5F5"
                }
            }),
            control: (styles, state) => ({
                ...styles,
                fontFamily: 'Cabin',
                paddingTop: 4,
                paddingBottom: 4,
                fontSize: '18px',
                borderColor: state.isFocused ? "#C15028" : "#CFCFCF",
                boxShadow: state.isFocused ? "0 0 0 1px #C15028" : "unset",
                ":hover": state.isFocused ? {
                    borderColor: "#C15028",
                    boxShadow: "0 0 0 1px #C15028"
                } : {
                    borderColor: "black"
                }
            }),
            menu: provided => ({ ...provided, zIndex: 9999 }),
            container: (styles) => ({
                ...styles,
                ...style
            })
        }}
        {...rest} />
);

export default Autocomplete