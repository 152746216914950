import styled from 'styled-components';

export const Container = styled.div`
  min-height: 247px;
  padding: 1.5rem;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.grayIce};
  gap: 1rem;
  display: flex;

  img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 767px){
    display: block;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .text-justify {
    text-align: justify;

    @media (max-width: 767px){
      text-align: center;
    }
  }
`;
