import Typography from 'components/Typography';
import styled from 'styled-components';
import { getColors } from '../styles';

export const Container = styled.div`
  /* margin-bottom: 20px; */
`;

export const Main = styled.div`
  margin-top: 40px;
  margin-bottom: 27px;

  @media (max-width: 787px){
    margin-top: 20px;
  }
`;

export const Header = styled.div``;

export const RegisterTitle = styled(Typography).attrs({ variant: 'h3' })`
  background-image: ${({ color }) => getColors(color).linearGradient};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  margin: 0;

  @media (max-width: 787px){
    font-size: 25px;
  }
`;

export const SelectProfile = styled.p`
  margin: 0;
  margin-bottom: 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 142%;

  color: #000000;
`;

export const OptionsContent = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 786px){
    width: 50%;
  }

  @media (max-width: 450px){
    width: 60%;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 19px;

    color: ${({ theme }) => theme.colors.blackGrayish60};

    margin-left: 10px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img{
    width: 80%;
  }

  @media (max-width: 450px){
    img{
      width: 100%;
    }
  }
`;
