import React from 'react';

import { Container, CardContainer, CardTitle, CardContent } from './styles';

const CounterPartCards = ({ value }) => {
  return (
    <CardContainer>
      <CardContent>{value}</CardContent>
    </CardContainer>
  );
};

export default CounterPartCards;
