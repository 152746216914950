import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { LoginService } from 'services/login';
import { Input, ForgotButton } from './styles';

const ForgotPasswordModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(async () => {
    try {
      await LoginService.forgotPassword(email);
      toast.success("Foi enviado um email de recuperação pra você!");
      handleClose();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
      } else {
        toast.error('Falha ao enviar email!');
      }
    }
  }, [email, handleClose]);

  useEffect(() => {
    if (open) {
      setEmail('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        Redefina sua senha
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Um link de redefinição de senha será enviado para o seu e-mail. 
        </DialogContentText>
        <Input label="Digite aqui o seu e-mail de cadastro" value={email} onChange={e => setEmail(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <ForgotButton onClick={handleClose} color="primary" className="forgot-button">
          Cancelar
        </ForgotButton>
        <ForgotButton onClick={() => handleSubmit()} color="primary">
          Enviar
        </ForgotButton>
      </DialogActions>
    </Dialog>
  );
}

export default ForgotPasswordModal;